import { defineMessages } from 'react-intl';

export default defineMessages({
  // main-menu
  dashboard: {
    id: 'Header.config.marketplace.dashboard',
    defaultMessage: 'Dashboard',
    description: 'item dashboard dans la usernav',
  },
  conversations: {
    id: 'Header.config.marketplace.conversations',
    defaultMessage: 'Messages',
    description: 'item conversations dans la usernav',
  },
  appointments: {
    id: 'Header.config.marketplace.appointments',
    defaultMessage: 'Rendez-vous',
    description: 'item appointments dans la usernav',
  },
  drafts: {
    id: 'Header.config.marketplace.drafts',
    defaultMessage: 'Brouillons',
    description: 'item brouillons dans la usernav',
  },
  search: {
    id: 'Header.config.marketplace.search',
    defaultMessage: 'Rechercher',
    description: 'item search dans la usernav',
  },
  favorites: {
    id: 'Header.config.marketplace.wishlist',
    defaultMessage: 'Favoris',
    description: 'item wishlist dans la usernav',
  },

  // usernav
  profile: {
    id: 'Header.config.marketplace.profile',
    defaultMessage: 'Profil',
    description: 'item profil dans la usernav',
  },
  settings: {
    id: 'Header.config.marketplace.settings',
    defaultMessage: 'Paramètres',
    description: 'item setting dans la usernav',
  },
  moderations: {
    id: 'Header.config.marketplace.moderations',
    defaultMessage: 'Modération',
    description: 'item Moderation dans la usernav',
  },
  fdrConfiguration: {
    id: 'Header.config.marketplace.fdrConfiguration',
    defaultMessage: 'Configuration',
    description: 'item Moderation dans la usernav',
  },
  mjgChronos: {
    id: 'Header.config.marketplace.mjgChronos',
    defaultMessage: 'Old BO MJG',
    description: 'item deconnexion dans la usernav',
  },
  mjgNewBO: {
    id: 'Header.config.marketplace.mjgNewBO',
    defaultMessage: 'BO MJG',
    description: 'item deconnexion dans la usernav',
  },
  logout: {
    id: 'Header.config.marketplace.logout',
    defaultMessage: 'Se déconnecter',
    description: 'item deconnexion dans la usernav',
  },
  employeeMode: {
    id: 'Header.config.marketplace.adminMode',
    defaultMessage: 'Accès Admin RH',
    description: 'switch vers le mode admin dans le header',
  },

  schoolCoachMode: {
    id: 'Header.config.marketplace.schoolCoachMode',
    defaultMessage: 'Accès Vocation',
    description: 'switch vers la plateforme vocation dans le header',
  },
});
