import { defineMessages } from 'react-intl';

export default defineMessages({
  dashboard: {
    id: 'Header.config.connect.dashboard',
    defaultMessage: 'Dashboard',
  },
  home: {
    id: 'Header.config.connect.home',
    defaultMessage: 'Accueil',
  },
  ambassador: {
    id: 'Header.config.connect.ambassador',
    defaultMessage: 'Ambassadeurs',
    description: 'item deconnexion dans la usernav',
  },
  appointments: {
    id: 'Header.config.connect.appointments',
    defaultMessage: 'Rendez-vous',
  },
  configuration: {
    id: 'Header.config.connect.configuration',
    defaultMessage: 'Configuration',
    description: 'item config dans la usernav',
  },
  settings: {
    id: 'Header.config.connect.settings',
    defaultMessage: 'Paramètres',
    description: 'item setting dans la usernav',
  },
  logout: {
    id: 'Header.config.connect.logout',
    defaultMessage: 'Se déconnecter',
    description: 'item deconnexion dans la usernav',
  },
  employeeMode: {
    id: 'connect.employeeMode',
    defaultMessage: 'Mode Ambassadeur',
    description: 'label mode Ambassadeur',
  },
  mjgNewBO: {
    id: 'Header.config.connect.mjgNewBO',
    defaultMessage: 'BO MJG',
    description: 'item deconnexion dans la usernav',
  },
});
