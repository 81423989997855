import React from 'react';
import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import { useParams, Navigate, useMatch, generatePath, useNavigate, useLocation } from 'react-router';
import {
  Get_Allowed_Employee_Actions_And_StatusQuery,
  Get_Allowed_Employee_Actions_And_StatusQueryVariables,
  EmployeeStatus,
} from '../../../../../@types/graphql.d';
import Routes from '../../../../../routes';
import EmployeeStatusDisplay from '../employee-status-display/EmployeeStatusDisplay';
import EmployeeManageToolboxActions from './component/employee-manage-toolbox-actions/EmployeeManageToolboxActions';
import EmployeeVisualisationMode from './component/employee-visualisation-mode/EmployeeVisualisationMode';
import { GET_ALLOWED_EMPLOYEE_ACTIONS_AND_STATUS } from './EmployeeManageToolbox.gql';
import styles from './EmployeeManageToolbox.module.scss';
import ArrowLeftIcon from '../../../../../icons/component-icons/ArrowLeftIcon';

function EmployeeManageToolbox() {
  const navigate = useNavigate();
  const isMjgAdmin: boolean = !!useMatch(Routes.mjgAdminBase);
  const { pathname } = useLocation();
  const isEditAmbassador =
    pathname === Routes.companyAdminEditAmbassador || pathname === Routes.mjgAdminCompaniesEditEmployee;

  const { companyid, employeeid } = useParams();
  const { data } = useQuery<
    Get_Allowed_Employee_Actions_And_StatusQuery,
    Get_Allowed_Employee_Actions_And_StatusQueryVariables
  >(GET_ALLOWED_EMPLOYEE_ACTIONS_AND_STATUS, {
    skip: !employeeid,
    variables: {
      employeeId: employeeid || '',
    },
  });

  if (!employeeid || !companyid) {
    return null;
  }

  const userId = data?.companyEmployee?.userId;

  const isEmployeeArchived = data?.companyEmployee?.status === EmployeeStatus.Archived;
  if (isEditAmbassador && isEmployeeArchived) {
    return (
      <Navigate
        to={generatePath(isMjgAdmin ? Routes.mjgAdminCompaniesShowEmployee : Routes.companyAdminShowAmbassador, {
          companyid,
          employeeid,
        })}
      />
    );
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.employeeManageToolboxWrapper}>
      <div
        className={classnames(styles.employeeManageToolbox, {
          [styles.employeeManageToolboxArchived]: isEmployeeArchived,
        })}
      >
        <button
          type="button"
          className={styles.backToCompanyAdminLink}
          onClick={handleGoBack}
        >
          <ArrowLeftIcon />
        </button>
        {data?.companyEmployee?.status !== EmployeeStatus.Archived && (
          <>
            <EmployeeManageToolboxActions
              userId={userId}
              employeeid={employeeid}
              authorizedActions={data?.companyEmployee}
              className={styles.employeeManageToolboxActions}
            />

            <EmployeeVisualisationMode
              userId={userId}
              employeeid={employeeid}
              companyid={companyid}
              className={styles.employeeVisualisationMode}
            />
          </>
        )}

        <EmployeeStatusDisplay
          testid="employee-status"
          employee={data?.companyEmployee || undefined}
          className={styles.employeeStatus}
        />
      </div>
    </div>
  );
}

export default EmployeeManageToolbox;
