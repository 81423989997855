import React, { useContext } from 'react';
import { CurrentUserContext } from '../../../../../../contexts/current-user-context/CurrentUserContext';
import LinkedInFilledIcon from '../../../../../../icons/component-icons/LinkedInFilledIcon';
import { ProfileInformationsTranslations } from '../ProfileInformations.translations';
import '../ProfileInformations.scss';
import { useIntl } from 'react-intl';

export default function LinkedinShare() {
  const intl = useIntl();
  const { currentUser } = useContext(CurrentUserContext);
  if (!currentUser?.professionalId) {
    return <div />;
  }
  const domain = currentUser.restrictedToLaReunion ? 're' : 'com'
  const professionalUrl = encodeURIComponent(
    `https://www.myjobglasses.${domain}/professionals/${currentUser?.professionalId}`,
  );
  const onClick = () => {
    window.open(
      `https://www.linkedin.com/shareArticle/?mini=true&url=${professionalUrl}&title=aaa`,
      'mywin',
      'left=20,top=20,width=500,height=500,toolbar=1',
    );
  };

  return (
    <>
      {currentUser.canShare && (
        <button
          type="button"
          onClick={onClick}
          className="profile-informations__share-my-profil"
        >
          <LinkedInFilledIcon />
          {intl.formatMessage(ProfileInformationsTranslations.shareMyProfile)}
        </button>
      )}
    </>
  );
}
