import classNames from 'classnames';
import PrimaryButton from '../../../../../../../components/primary-button/PrimaryButton';
import SecondaryButton from '../../../../../../../components/secondary-button/SecondaryButton';
import React, { memo, PropsWithChildren } from 'react';

import './AssetBlock.scss';

type Action = {
  label: string;
  onClick: () => void;
  children?: React.ReactNode;
};

type AssetBlockProps = PropsWithChildren<{
  title: string;
  subtitle?: string;
  primaryAction?: Action;
  secondaryAction?: Action;
  accent?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  disabledMsg?: string;
}>;

export const AssetBlock = memo(
  ({ title, subtitle, primaryAction, secondaryAction, accent, children, disabled, disabledMsg }: AssetBlockProps) => (
    <div className={classNames('asset-block', { 'asset-block--accent': accent })}>
      <div className="asset-block__header">
        <div className="asset-block__header__title">{title}</div>
        {subtitle && <div className="asset-block__header__subtitle">{subtitle}</div>}
      </div>

      <main className="asset-block__content">{children}</main>

      <div className="asset-block__buttons">
        {primaryAction && (
          <>
            <PrimaryButton
              label={primaryAction.label}
              onClick={primaryAction.onClick}
              smaller
              disabled={disabled}
            >
              {primaryAction.children}
            </PrimaryButton>
            {disabled && <p>{disabledMsg}</p>}
          </>
        )}
        {secondaryAction && (
          <SecondaryButton
            label={secondaryAction.label}
            onClick={secondaryAction.onClick}
            smaller
            smallerDisabled={disabled}
          />
        )}
      </div>
    </div>
  ),
);
