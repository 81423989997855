import { defineMessages } from 'react-intl';

export default defineMessages({
  seeMessages: {
    id: 'AppointmentListItem.seeMessages',
    defaultMessage: 'Messages',
  },
  updateAppointment: {
    id: 'AppointmentListItem.updateAppointment',
    defaultMessage: 'Modifier',
  },
  deleteAppointment: {
    id: 'AppointmentListItem.deleteAppointment',
    defaultMessage: 'Annuler',
  },
  addToCalendar: {
    id: 'AppointmentListItem.addToCalendar',
    defaultMessage: 'Calendriers',
  },
  reviewAppointment: {
    id: 'AppointmentListItem.reviewAppointment',
    defaultMessage: 'Évaluer le rendez-vous',
  },
  seeReviews: {
    id: 'AppointmentListItem.seeReviews',
    defaultMessage: 'Voir les commentaires',
  },
  noReview: {
    id: 'AppointmentListItem.noReview',
    defaultMessage: "N'a pas laissé de commentaire.",
  },
  currentUserNoReview: {
    id: 'AppointmentListItem.currentUserNoReview',
    defaultMessage: "Vous n'avez pas laissé de commentaire.",
  },
  interlocutorRecommended: {
    id: 'AppointmentListItem.interlocutorRecommended',
    defaultMessage: 'Vous avez recommandé ce Membre',
  },
  recommendedByInterlocutor: {
    id: 'AppointmentListItem.recommendedByInterlocutor',
    defaultMessage: 'Vous avez été recommandé',
  },
});
