import React from 'react';

export type ArrowLeftProps = {
  color?: string | '#09193b';
};

export default function ArrowLeft({ color }: ArrowLeftProps) {
  return (
    <svg
      width="17px"
      height="30px"
      viewBox="0 0 17 30"
      strokeWidth="3"
    >
      <defs></defs>
      <g
        id="UI"
        stroke="none"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="home-pro"
          transform="translate(-59.000000, -3471.000000)"
          strokeWidth="3"
          stroke={color}
        >
          <g
            id="icn_arrow_left_blue"
            transform="translate(61.000000, 3472.000000)"
          >
            <polyline
              id="Path-2-Copy-6"
              transform="translate(14.142136, 14.142136) scale(-1, 1) rotate(45.000000) translate(-14.142136, -14.142136) "
              points="4.14213562 4.14213562 24.1421356 4.14213562 24.1421356 24.1421356"
            ></polyline>
          </g>
        </g>
      </g>
    </svg>
  );
}
