import React, { useEffect } from 'react';
import {
  AddCompanyRecruitingWorkingAreaMutation,
  AddCompanyRecruitingWorkingAreaMutationVariables,
  RemoveCompanyRecruitingWorkingAreaMutation,
  RemoveCompanyRecruitingWorkingAreaMutationVariables,
} from '../../../../../../../../@types/graphql.d';
import { Field, Formik } from 'formik';
import FormikFieldError from '../../../../../../../common/formik/components/formik-field-error/FormikFieldError';
import useFormikMutation from '../../../../../../../../utils/hooks/useFormikMutation';

import './RecruitingWorkingAreasUpdate.scss';
import {
  ADD_COMPANY_RECRUITING_WORKING_AREA_MUTATION,
  REMOVE_COMPANY_RECRUITING_WORKING_AREA_MUTATION,
} from './RecruitingWorkingAreasUpdate.gql';
import { useMutation } from '@apollo/client';
import { TextField } from '@myjobglasses/dsm';
import PrimaryButton from '../../../../../../../../components/primary-button/PrimaryButton';
import translations, { errorTranslation } from './RecruitingWorkingAreas.translations';
import { useIntl } from 'react-intl';

interface RecruitingWorkingAreasWriteProps {
  companyId: string;
  workingAreas: Array<string>;
  setWorkingAreas: React.Dispatch<React.SetStateAction<string[]>>;
  finish: () => void;
}

function RecruitingWorkingAreasUpdate({
  companyId,
  workingAreas,
  setWorkingAreas,
  finish,
}: RecruitingWorkingAreasWriteProps) {
  const intl = useIntl();
  const [onAdd] = useFormikMutation<
    AddCompanyRecruitingWorkingAreaMutation,
    AddCompanyRecruitingWorkingAreaMutationVariables
  >(ADD_COMPANY_RECRUITING_WORKING_AREA_MUTATION, {
    refetchQueries: ['GetCompanyRecruitingWorkingAreas'],
  });

  const onAddComplete = (
    res: {
      data: AddCompanyRecruitingWorkingAreaMutation;
    },
    resetForm: () => void,
  ) => {
    if (!res?.data?.addCompanyRecruitingWorkArea?.workingAreas) return;
    setWorkingAreas(res.data.addCompanyRecruitingWorkArea.workingAreas);
    resetForm();
  };

  const [removeArea, { data: removeAreaData }] = useMutation<
    RemoveCompanyRecruitingWorkingAreaMutation,
    RemoveCompanyRecruitingWorkingAreaMutationVariables
  >(REMOVE_COMPANY_RECRUITING_WORKING_AREA_MUTATION, {
    refetchQueries: ['GetCompanyRecruitingWorkingAreas'],
  });

  const handleRemoveArea = (workingArea: string) => {
    removeArea({
      variables: {
        companyId,
        workingArea,
      },
    });
  };

  useEffect(() => {
    if (!removeAreaData?.removeCompanyRecruitingWorkArea?.workingAreas) return;
    setWorkingAreas(removeAreaData.removeCompanyRecruitingWorkArea.workingAreas);
  }, [removeAreaData?.removeCompanyRecruitingWorkArea?.workingAreas]);

  return (
    <>
      <Formik
        initialValues={{
          companyId,
          workingArea: '',
        }}
        onSubmit={(values, formikHelpers) =>
          onAdd(values, formikHelpers).then((res) => onAddComplete(res, formikHelpers.resetForm))
        }
      >
        {({ handleSubmit, isSubmitting }) => (
          <form
            onSubmit={handleSubmit}
            className="form-edit"
          >
            <h2 className="form-edit__title">{intl.formatMessage(translations.listValues)}</h2>
            <div className="form-edit__samples">
              <span className="form-edit__samples--label">{intl.formatMessage(translations.sample)}</span>
              <span className="form-edit__samples--sample">{intl.formatMessage(translations.marketing)}</span>
              <span className="form-edit__samples--sample">{intl.formatMessage(translations.finance)}</span>
              <span className="form-edit__samples--sample">{intl.formatMessage(translations.hr)}</span>
            </div>
            <Field
              name="workingArea"
              component={TextField}
              label={intl.formatMessage(translations.labelName)}
              maxLength={30}
              helper={intl.formatMessage(translations.helper)}
              disabled={isSubmitting}
              maxLengthDisplay={(current: number, max: number) =>
                intl.formatMessage(translations.counter, { current, max })
              }
            />
            <FormikFieldError
              name="workingArea"
              asyncFieldErrorTranslations={errorTranslation}
            />
          </form>
        )}
      </Formik>
      <div className="working-areas">
        {workingAreas.map((workingArea) => (
          <div
            className="working-areas__list"
            key={workingArea}
          >
            <div
              className="working-areas__list--remove"
              onClick={() => {
                handleRemoveArea(workingArea);
              }}
              aria-hidden="true"
            >
              x
            </div>
            <span
              key={workingArea}
              className="working-areas__list--item"
            >
              {workingArea}
            </span>
          </div>
        ))}
      </div>
      <div className="actions">
        <PrimaryButton
          label={intl.formatMessage(translations.finishButton)}
          onClick={finish}
          smaller
        />
      </div>
    </>
  );
}

export default RecruitingWorkingAreasUpdate;
