import { gql } from '@apollo/client';

export const GET_COMPANY_EMPLOYEE_PACK = gql`
  query GET_COMPANY_EMPLOYEE_PACK($companyId: ID!) {
    company(companyId: $companyId) {
      id
      name
      publishedEmployeesCount
      employeePackSize
      isPremiumSchool
    }
  }
`;
