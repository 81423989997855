import React, { useContext } from 'react';
import classnames from 'classnames';
import AccessibleTooltip from '../../../../../../common/accessible-tooltip/AccessibleTooltip';
import translations from './InterlocutorReportButton.translations';
import styles from './InterlocutorReportButton.module.scss';
import useModal from '../../../../../../../components/modal/Modal.hook';
import MisconductReportModalTranslations from '../../../report-student-behavior-modal/MisconductReportModal.translations';
import MisconductReportModal from '../../../report-student-behavior-modal/MisconductReportModal';
import { ConversationViewContext } from '../../../../../../../scenes/interactions/conversations/context/ConversationViewContext';
import { useIntl } from 'react-intl';

interface InterlocutorReportButtonProps {
  className?: string;
}
function InterlocutorReportButton({ className }: InterlocutorReportButtonProps) {
  const [openModal] = useModal();
  const intl = useIntl();
  const { conversation } = useContext(ConversationViewContext);

  function handleClickReportButton() {
    openModal({
      title: intl.formatMessage(MisconductReportModalTranslations.title),
      content: <MisconductReportModal conversation={conversation} />,
    });
  }

  return (
    <AccessibleTooltip
      name="interlocutor-report-tooltip"
      onClick={handleClickReportButton}
      className={classnames(className, styles.interlocutorReportButton)}
      tooltip={intl.formatMessage(translations.tooltip)}
    >
      !
    </AccessibleTooltip>
  );
}

export default InterlocutorReportButton;
