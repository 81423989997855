import React, { useState, useLayoutEffect } from 'react';
import { ValuesType } from 'utility-types';
import moment from 'moment';
import MessageNotificationCircleIcon from '../../../../../icons/component-icons/MessageNotificationCircleIcon';
import Routes from '../../../../../routes';
import MessageAlertCircleIcon from '../../../../../icons/component-icons/MessageAlertCircle';
import { generatePath, useNavigate } from 'react-router';
import BuildingIcon from '../../../../../icons/component-icons/BuildingIcon';
import classNames from 'classnames';
import BriefcaseIcon from '../../../../../icons/component-icons/BriefcaseIcon';
import GraduateHatIcon from '../../../../../icons/component-icons/GraduateHatIcon';
import LegendTranslations from '../../../components/Legend.translations';
import {
  AcknowledgeConversationMutation,
  AcknowledgeConversationMutationVariables,
  Get_Conversations_ListQuery,
  PrimarySituationEnum,
} from '../../../../../@types/graphql.d';

import './ConversationListItem.scss';
import { useMutation } from '@apollo/client';
import { ACKNOWLEDGE_CONVERSATION_MUTATION } from './ConversationListItem.gql';
import { useIntl } from 'react-intl';
import { useLanguage } from '../../../../../hooks/useLanguages';

type ConversationListItemProps = {
  conversation: ValuesType<NonNullable<Get_Conversations_ListQuery['conversations']>['nodes']>;
  animationId: number;
};

export default function ConversationListItem({ conversation, animationId }: ConversationListItemProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const [isActionNeeded, setActionNeeded] = useState(false);

  const [acknowledgeConversation] = useMutation<
    AcknowledgeConversationMutation,
    AcknowledgeConversationMutationVariables
  >(ACKNOWLEDGE_CONVERSATION_MUTATION, {
    variables: {
      conversationId: conversation.id,
    },
  });

  const getStatusDetailsFromInterlocutor = () => {
    switch (conversation.interlocutor?.primarySituation?.key) {
      case PrimarySituationEnum.Student:
        return (
          <div>
            <GraduateHatIcon />
            {conversation.interlocutor?.currentSchoolName}
          </div>
        );
      case PrimarySituationEnum.Worker:
        return (
          <>
            <div>
              <BuildingIcon />
              {conversation.interlocutor?.companyName}
            </div>
            <div>
              <BriefcaseIcon />
              {conversation.interlocutor?.jobTitle}
            </div>
          </>
        );
      case PrimarySituationEnum.JobSeeker:
      case PrimarySituationEnum.Retired:
        return conversation.interlocutor?.previousCompanyName ? (
          <>
            <div>
              <BuildingIcon />
              {'ex-' + conversation.interlocutor?.previousCompanyName}
            </div>
            <div>
              <BriefcaseIcon />
              {'ex-' + conversation.interlocutor?.previousPosition}
            </div>
          </>
        ) : null;
      default:
        return null;
    }
  };

  useLayoutEffect(() => {
    setTimeout(
      () => {
        setActionNeeded(conversation.currentUserAcknowledged === false || conversation.currentUserAnswered === false);
      },
      250 + animationId * 50,
    );
  }, [conversation.currentUserAnswered, conversation.currentUserAcknowledged]);

  function ActionNeededIcon() {
    if (conversation.currentUserAcknowledged === false && conversation.currentUserAnswered === true) {
      return (
        <>
          <MessageNotificationCircleIcon className="conversation-list-item__status-icon__icon" />
          <span className="conversation-list-item__status-icon__text">
            {intl.formatMessage(LegendTranslations.unseen)}
          </span>
        </>
      );
    }
    if (conversation.currentUserAnswered === false) {
      return (
        <>
          <MessageAlertCircleIcon className="conversation-list-item__status-icon__icon" />
          <span className="conversation-list-item__status-icon__text">
            {intl.formatMessage(LegendTranslations.needAction)}
          </span>
        </>
      );
    }
    return null;
  }

  function onClick() {
    if (isActionNeeded) {
      acknowledgeConversation();
    }

    navigate(generatePath(Routes.conversation, { id: conversation.id }));
  }

  const { language } = useLanguage();

  moment.locale(language);

  const lastMessageDate = moment(conversation.lastMessage?.createdAt).fromNow();

  return (
    <div
      onClick={onClick}
      className={classNames(
        'conversation-list-item',
        { 'conversation-list-item--need-action': isActionNeeded },
        {
          'conversation-list-item--as-member': conversation.currentUserIsInitiator,
        },
      )}
      data-cy="conversation-list-item"
    >
      {isActionNeeded && (
        <div
          className="conversation-list-item__status-icon"
          data-cy="conversation-list-item__action-needed"
        >
          <ActionNeededIcon />
        </div>
      )}
      <img
        className="conversation-list-item__avatar"
        src={conversation.interlocutor?.avatars.url!}
        alt=""
      />
      <div className="conversation-list-item__infos">
        <div className="conversation-list-item__infos__head">
          <div className="conversation-list-item__infos__head__interlocutor">
            <div className="conversation-list-item__infos__head__interlocutor__name">
              {conversation.interlocutor?.firstName}
            </div>
            <div className="conversation-list-item__infos__head__interlocutor__status">
              {getStatusDetailsFromInterlocutor()}
            </div>
          </div>
          <div className="conversation-list-item__infos__head__since">{lastMessageDate}</div>
        </div>
        <div className="conversation-list-item__infos__last-message">{conversation.lastMessage!.text}</div>
      </div>
    </div>
  );
}
