import { defineMessages } from 'react-intl';

export default defineMessages({
  faq: {
    id: 'Footer.faq',
    defaultMessage: 'FAQ',
  },
  privacy: {
    id: 'Footer.privacy',
    defaultMessage: 'Confidentialité',
  },
  cgu: {
    id: 'Footer.cgu',
    defaultMessage: 'Conditions générales d’utilisation',
  },
  minorcgu: {
    id: 'Footer.minorcgu',
    defaultMessage: 'Charte de confidentialité',
  },
  legal: {
    id: 'Footer.legal',
    defaultMessage: 'Mentions légales',
  },
});
