import React from 'react';
import { IconProps } from './Icon';

function BellIcon({ className }: IconProps) {
  return (
    <svg
      className={className}
      viewBox="0 0 16 16"
    >
      <path d="M13.5,9.945V7.26c0-2.251-1.299-4.303-3.265-5.209C10.105,0.897,9.152,0,8,0C6.866,0,5.926,0.869,5.771,2.047C3.803,2.952,2.5,5.007,2.5,7.26v2.633c0,0.018,0,0.035,0,0.053C1.638,10.175,1,10.982,1,11.94C1,13.076,1.898,14,3.002,14h2.177C5.598,15.178,6.721,16,8,16c1.28,0,2.403-0.822,2.822-2h2.175C14.102,14,15,13.076,15,11.94C15,10.982,14.361,10.175,13.5,9.945z M8,15c-0.726,0-1.377-0.398-1.727-1h3.456C9.378,14.602,8.727,15,8,15z M12.997,13H3.002C2.449,13,2,12.524,2,11.94c0-0.585,0.448-1.061,1-1.061c0.276,0,0.5-0.224,0.5-0.5c0-0.003,0-0.006,0-0.01c-0.001-0.137,0-3.11,0-3.11c0-1.955,1.178-3.726,2.931-4.406C6.623,2.779,6.75,2.524,6.75,2.318C6.75,1.592,7.311,1,8,1s1.25,0.592,1.25,1.388c0,0.206,0.127,0.392,0.319,0.466C11.322,3.534,12.5,5.305,12.5,7.26v3.12c0,0.276,0.224,0.5,0.5,0.5c0.552,0,1,0.476,1,1.061C14,12.524,13.55,13,12.997,13z" />
    </svg>
  );
}

export default BellIcon;
