import { defineMessages } from 'react-intl';

export const PremiumOfferPack0Translations = defineMessages({
  title: {
    id: 'PremiumOfferPack0Translations.title',
    defaultMessage: 'Découverte',
  },
  subtitle: {
    id: 'PremiumOfferPack0Translations.subtitle',
    defaultMessage: 'Découvrez l’impact des rencontres My Job Glasses',
  },
  mention1: {
    id: 'PremiumOfferPack0Translations.mention1',
    defaultMessage: 'Tous vos collaborateurs disponibles sur la plateforme (web et mobile)',
  },
  mention2: {
    id: 'PremiumOfferPack0Translations.mention2',
    defaultMessage: 'Rendez-vous avec les membres My Job Glasses',
  },
  mention3: {
    id: 'PremiumOfferPack0Translations.mention3',
    defaultMessage: 'Votre logo sur les profils de vos collaborateurs',
  },
  mention4: {
    id: 'PremiumOfferPack0Translations.mention4',
    defaultMessage: "Kit de communication standard et QR code d'inscription à partager",
  },
});

export const PremiumOfferPack1Translations = defineMessages({
  title: {
    id: 'PremiumOfferPack1Translations.title',
    defaultMessage: 'Attractivité',
  },
  subtitle: {
    id: 'PremiumOfferPack1Translations.subtitle',
    defaultMessage: 'Décuplez la visibilité de votre entreprise et de vos métiers',
  },
  mention5: {
    id: 'PremiumOfferPack1Translations.mention5',
    defaultMessage: 'Les fonctionnalités de l’offre Découverte',
  },
  mention6: {
    id: 'PremiumOfferPack1Translations.mention6',
    defaultMessage: 'Boost de visibilité de niveau 1 dans le moteur de recherche',
  },
  mention7: {
    id: 'PremiumOfferPack1Translations.mention7',
    defaultMessage: 'Données macro d’activité de votre compte',
  },
  mention8: {
    id: 'PremiumOfferPack1Translations.mention8',
    defaultMessage: 'Kit de communication personnalisé',
  },
  mention9: {
    id: 'PremiumOfferPack1Translations.mention9',
    defaultMessage: 'Accompagnement standard et accès à notre support technique en ligne',
  },
});

export const PremiumOfferPack2Translations = defineMessages({
  title: {
    id: 'PremiumOfferPack2Translations.title',
    defaultMessage: 'Communauté',
  },
  subtitle: {
    id: 'PremiumOfferPack2Translations.subtitle',
    defaultMessage: 'Fédérez vos collaborateurs autour d’un projet RH à fort impact sociétal',
  },
  mention10: {
    id: 'PremiumOfferPack2Translations.mention10',
    defaultMessage: 'Les fonctionnalités de l’offre Attractivité',
  },
  mention11: {
    id: 'PremiumOfferPack2Translations.mention11',
    defaultMessage: 'Boost de visibilité de niveau 2 dans le moteur de recherche',
  },
  mention12: {
    id: 'PremiumOfferPack2Translations.mention12',
    defaultMessage: 'Accès au module pilotage des ambassadeurs',
  },
  mention13: {
    id: 'PremiumOfferPack2Translations.mention13',
    defaultMessage: 'Données micro sur l’activité de vos Ambassadeurs',
  },
  mention14: {
    id: 'PremiumOfferPack2Translations.mention14',
    defaultMessage: 'Accompagnement par un chargé de projet dédié',
  },
});

export const PremiumOfferPack3Translations = defineMessages({
  title: {
    id: 'PremiumOfferPack3Translations.title',
    defaultMessage: 'Talents',
  },
  subtitle: {
    id: 'PremiumOfferPack3Translations.subtitle',
    defaultMessage: 'Développez un vivier de candidats présélectionnés par vos ambassadeurs',
  },
  mention15: {
    id: 'PremiumOfferPack3Translations.mention15',
    defaultMessage: 'Les fonctionnalités de l’offre Communauté',
  },
  mention16: {
    id: 'PremiumOfferPack3Translations.mention16',
    defaultMessage: 'Boost de visibilité de niveau 3 dans le moteur de recherche',
  },
  mention17: {
    id: 'PremiumOfferPack3Translations.mention17',
    defaultMessage: 'Accès au module de rendez-vous',
  },
  mention18: {
    id: 'PremiumOfferPack3Translations.mention18',
    defaultMessage: 'Données sur les membres rencontrés et sur leur projection dans l’entreprise',
  },
  mention19: {
    id: 'PremiumOfferPack3Translations.mention19',
    defaultMessage: 'Le widget de recherche de vos Ambassadeurs My Job Glasses sur le site de votre entreprise',
  },
});
