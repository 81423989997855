import React from 'react';
import { AppointmentInterlocutor } from '../../../../../../../@types/graphql.d';
import translations from './SidebarStudentDescriptionSection.translations';

import styles from './SidebarStudentDescriptionSection.module.scss';
import { useIntl } from 'react-intl';

interface SidebarStudentDescriptionSectionProps {
  publicProfile?: AppointmentInterlocutor;
  isPremiumSchool?: boolean;
}
function SidebarStudentDescriptionSection({ publicProfile, isPremiumSchool }: SidebarStudentDescriptionSectionProps) {
  const intl = useIntl();
  if (isPremiumSchool) return null;
  return (
    <section className={styles.SidebarStudentDescriptionSection}>
      <h2 className={styles.title}>{intl.formatMessage(translations.title)}</h2>
      <p className={styles.description}>
        {publicProfile?.memberPresentation ?? intl.formatMessage(translations.noDescription)}
      </p>
    </section>
  );
}

export default SidebarStudentDescriptionSection;
