import { useMutation, ApolloError } from '@apollo/client';
import { useSnackbar } from 'notistack';
import classNames from 'classnames';
import ProgressBar from '../../../../../../components/progress-bar/ProgressBar';
import { ContactStateTranslations } from '../../../../../../i18n/contact-state.translations';
import MailIcon from '../../../../../../icons/component-icons/MailIcon';
import moment from 'moment';
import React, { useMemo } from 'react';
import { GetProgramsQuery, HermesContactStateEnum } from '../../../../../../@types/graphql.d';
import { ClaimCertificateMutation, ClaimCertificateMutationVariables } from '../../../../../../@types/backend-api';
import { CLAIM_CERTIFICATE_MUTATION } from './Program.gql-backend-api';
import './Program.scss';
import { ProgramCertificateTranslations, ProgramTranslations } from './Program.translations';
import { FormattedMessage, useIntl } from 'react-intl';

type QueryPrograms = NonNullable<GetProgramsQuery['programs']>;
type QueryParticipations = NonNullable<QueryPrograms['participations']>;
type QueryParticipation = QueryParticipations[0];
type QueryContacts = NonNullable<QueryParticipation['contacts']>;
type QueryContact = QueryContacts[0];

type ProgramProps = {
  participation: QueryParticipation;
};

const filterStates = [
  HermesContactStateEnum.PastAndConfirmed,
  HermesContactStateEnum.PastAwaitingConfirmation,
  HermesContactStateEnum.Upcoming,
  HermesContactStateEnum.InvalidatedAppointment,
];

export default function Program({ participation }: ProgramProps) {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const { program, confirmedMeetingCount, certificationStatus, contacts } = participation;

  const appointments = useMemo(() => {
    return contacts?.filter((contact) => contact.state && filterStates.includes(contact.state));
  }, [contacts, filterStates]);

  const progressionPercentage = useMemo(() => {
    if (!program?.meetingsQuantity || !confirmedMeetingCount) return 0;
    return (confirmedMeetingCount / program.meetingsQuantity) * 100;
  }, [confirmedMeetingCount, program?.meetingsQuantity]);

  const [claimCertificate] = useMutation<ClaimCertificateMutation, ClaimCertificateMutationVariables>(
    CLAIM_CERTIFICATE_MUTATION,
    {
      context: {
        clientName: 'backend-api',
      },
      variables: {
        participationId: participation.id,
      },
    },
  );

  const sendCertificate = async () => {
    await claimCertificate()
      .then(() => {
        enqueueSnackbar(intl.formatMessage(ProgramTranslations.certificateClaimSuccess), {
          variant: 'success',
          autoHideDuration: 5000,
        });
      })
      .catch((error: ApolloError) => {
        const errorCode = error?.graphQLErrors?.[0]?.extensions?.code;
        if (errorCode) {
          enqueueSnackbar(errorCode),
            {
              autoHideDuration: 5000,
              variant: 'warning',
            };
        }
      });
  };

  return (
    <div className="dashboard-view-program">
      <strong className="dashboard-view-program__title">{participation.program?.name}</strong>

      <div className="dashboard-view-program__progression">
        <ProgressBar
          percentage={progressionPercentage}
          color="#09193b"
        />
        <strong>
          {intl.formatMessage(ProgramTranslations.appointmentsMade, {
            made: confirmedMeetingCount,
            total: program?.meetingsQuantity,
          })}
        </strong>
      </div>

      <div className="dashboard-view-program__period">
        <FormattedMessage
          id="DashboardView.Program.period"
          defaultMessage="Du {start} au {end}"
          values={{
            start: <strong>{moment(program?.startAt).format('DD/MM/YYYY')}</strong>,
            end: <strong>{moment(program?.endAt).format('DD/MM/YYYY')}</strong>,
          }}
        />
      </div>

      <div className="dashboard-view-program__info">
        <strong>{intl.formatMessage(ProgramTranslations.organization)}</strong>
        <p>{program?.organizationName}</p>
      </div>

      <div className="dashboard-view-program__info">
        <strong>{intl.formatMessage(ProgramTranslations.programManager)}</strong>
        <p>
          {program?.author?.firstName} {program?.author?.lastName}
        </p>
      </div>

      <div className="dashboard-view-program__info">
        <strong>{intl.formatMessage(ProgramTranslations.programDescription)}</strong>
        <p>{program?.description}</p>
      </div>

      <div className="dashboard-view-program__info">
        <strong>{intl.formatMessage(ProgramTranslations.certificate)}</strong>
        {certificationStatus && (
          <>
            <div
              className={classNames(
                'dashboard-view-program__info__status',
                `dashboard-view-program__info__status--${certificationStatus}`,
              )}
            >
              {intl.formatMessage(ProgramCertificateTranslations[certificationStatus])}
            </div>

            {certificationStatus === 'certificate_delivered' ? (
              <div
                className="dashboard-view-program__info__download-certificate"
                onClick={sendCertificate}
              >
                <MailIcon />
                {intl.formatMessage(ProgramTranslations.receiveMyCertificate)}
              </div>
            ) : (
              <div>{intl.formatMessage(ProgramTranslations.certificate_failedReason)}</div>
            )}
          </>
        )}
      </div>

      <div className="dashboard-view-program__info">
        <strong>{intl.formatMessage(ProgramTranslations.ambassadorsMet)}</strong>
        <div className="dashboard-view-program__info__contacts">
          {appointments?.map((appointment) => (
            <AmbassadorMet
              key={appointment.conversationId || ''}
              contact={appointment}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

type AmbassadorMetProps = { contact: QueryContact };

function AmbassadorMet({ contact }: AmbassadorMetProps) {
  const intl = useIntl();
  return (
    <div className="dashboard-view-program__info__contacts__contact">
      <img
        className="dashboard-view-program__info__contacts__contact__avatar"
        src={contact.avatarUrl || 'https://assets.myjobglasses.com/images/avatar-placeholder.png'}
        onError={(e) => {
          // avatarUrl is always a string but it's not always a valid URL so that's why we need to handle the error
          const target = e.target as HTMLImageElement;
          target.onerror = null;
          target.src = 'https://assets.myjobglasses.com/images/avatar-placeholder.png';
        }}
      />
      <div className="dashboard-view-program__info__contacts__contact__infos">
        <div className="dashboard-view-program__info__contacts__contact__infos__first-name">{contact.firstName}</div>
        {contact.state && (
          <div
            className={classNames(
              'dashboard-view-program__info__contacts__contact__infos__status',
              `dashboard-view-program__info__contacts__contact__infos__status--${contact.state}`,
            )}
          >
            {intl.formatMessage(ContactStateTranslations[contact.state])}
          </div>
        )}
      </div>
    </div>
  );
}
