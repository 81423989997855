import { gql } from '@apollo/client';
import { Get_Appointment_To_ReviewQuery, GetClaimToReviewQuery } from '../../../@types/graphql';

export type Appointment = NonNullable<Get_Appointment_To_ReviewQuery['appointment']>;
export type Claim = NonNullable<GetClaimToReviewQuery['claim']>;
export type ReviewInterlocutor = NonNullable<Appointment['interlocutor']>;

export const GET_APPOINTMENT_TO_REVIEW = gql`
  query GET_APPOINTMENT_TO_REVIEW($id: ID!) {
    appointment: appointmentReviewV2(id: $id) {
      id
      from
      appointmentType
      conversationId

      currentUserIsAmbassador
      currentUserIsMember
      currentUserCanSnooze

      interlocutor {
        id
        avatar {
          url
        }
        firstName
        lastName
        companyName
        currentSchoolName
        position
        primarySituation {
          key
        }
      }

      ambassador {
        profileType
      }

      memberReview {
        reviewQuestion1
        reviewQuestion2
        reviewQuestion3
        reviewQuestion4

        schoolContactPreferenceQuestion
        schoolInterestLevelQuestion
        schoolInterestYearQuestion

        companyContactPreferenceQuestion
        jobTypeQuestion
        domainsQuestion
        startDateQuestion
        postalCodeQuestion
        availabilityQuestion
        durationQuestion

        canFillCompanyAspiration
        canFillSchoolAspiration

        availableWorkingAreas
      }

      ambassadorReview {
        reviewQuestion1
        reviewQuestion2
        reviewQuestion3
        messageForHrRequired
      }
    }
  }
`;

export const REVIEW_APPOINTMENT_AS_MEMBER = gql`
  mutation ReviewAppointmentAsMember(
    $id: ID!
    $question1: Int!
    $question2: Int!
    $question3: Int!
    $question4: Boolean!
    $message: String!
    $companyAspiration: AspirationsInput
    $schoolAspiration: SchoolAspirationInput
  ) {
    memberReviewV2(
      id: $id
      question1: $question1
      question2: $question2
      question3: $question3
      question4: $question4
      message: $message
      companyAspiration: $companyAspiration
      schoolAspiration: $schoolAspiration
    ) {
      id
    }
  }
`;

export const REVIEW_APPOINTMENT_AS_AMBASSADOR = gql`
  mutation ReviewAppointmentAsAmbassador(
    $id: ID!
    $question1: Int!
    $question2: Int
    $question3: Int
    $messageForHr: String
    $message: String!
  ) {
    ambassadorReviewV2(
      id: $id
      question1: $question1
      question2: $question2
      question3: $question3
      messageForHr: $messageForHr
      message: $message
    ) {
      id
    }
  }
`;

export const REPORT_APPOINTMENT_DID_NOT_TOOK_PLACE = gql`
  mutation ReportAppointmentDidNotTookPlace(
    $id: ID!
    $reason: AppointmentDidNotTookPlaceReasonEnum!
    $description: String
  ) {
    reportAppointmentDidNotTookPlace(
      appointmentId: $id
      reason: $reason
      description: $description
    ) {
      id
    }
  }
`;

export const SNOOZE_REVIEW = gql`
  mutation SnoozeReview($id: ID!) {
    snoozeReview(appointmentId: $id) {
      id
    }
  }
`;

export const SNOOZE_CLAIM = gql`
  mutation SnoozeClaim($id: ID!) {
    snoozeAppointmentClaimReview(appointmentClaimId: $id) {
      id
    }
  }
`;

export const GET_ASPIRATIONS_VALUES = gql`
  query GetAspirationsValues {
    settings {
      franceLevel1AdministrativeAreas {
          key
          name
        }
        internshipTypes {
          key
          name
        }
        degreeLevels {
          key
          name
        }
        academicYears {
          key
          name
        }
    }
  }
`;

export const GET_CLAIM_TO_REVIEW = gql`
  query GetClaimToReview($id: ID!) {
    claim: appointmentClaim(id: $id) {
      id
      from
      currentUserCanSnooze

      interlocutor {
        avatar {
          url
        }
        firstName
        lastName
        currentSchoolName
        primarySituation {
          key
        }
      }

      appointment {
        id
      }
    }
  }
`;

export const APPROVE_CLAIM = gql`
  mutation ApproveClaim($id: ID!) {
    approveAppointmentClaim(appointmentClaimId: $id) {
      appointment {
        id
      }
    }
  }
`;

export const DENY_CLAIM = gql`
  mutation denyAppointmentClaim(
    $id: ID!
    $reason: AppointmentDidNotTookPlaceReasonEnum!
    $description: String
  ) {
    denyAppointmentClaim(
      appointmentClaimId: $id
      reason: $reason
      description: $description
    ) {
      id
    }
  }
`;

export const GET_PERCEPTION_QUESTION_FOR_REVIEW = gql`
  query GetPerceptionQuestionForReview($professionalId: ID!) {
    perceptionQuestions(professionalId: $professionalId) {
      id
      label
      isActive
      interactionType
      questionAttributes {
        id
        labelFr
        labelEn
        identifier
        order
        isActive
        description
      }
    }
  }
`;

export const CREATE_PERCEPTION_ANSWERS = gql`
  mutation CreatePostAppointmentPerceptionAnswers(
    $conversationId: ID!,
    $appointmentId: ID,
    $perceptionQuestionId: ID!,
    $answers: [PerceptionAnswerInput!]!
  ) {
    createPerceptionAnswers(
      conversationId: $conversationId,
      appointmentId: $appointmentId,
      perceptionQuestionId: $perceptionQuestionId,
      answers: $answers
    ) {
      id
      conversation {
        id
      }
    }
  }
`;