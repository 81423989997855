export enum Month {
  'Month1' = 'Month1',
  'Month2' = 'Month2',
  'Month3' = 'Month3',
  'Month4' = 'Month4',
  'Month5' = 'Month5',
  'Month6' = 'Month6',
  'Month7' = 'Month7',
  'Month8' = 'Month8',
  'Month9' = 'Month9',
  'Month10' = 'Month10',
  'Month11' = 'Month11',
  'Month12' = 'Month12',
}
