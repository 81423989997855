import React, { useMemo, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import classnames from 'classnames';
import { useSnackbar } from 'notistack';
import ButtonsGroup from '../../../../../components/buttons-group/ButtonsGroup';
import PrimaryButton from '../../../../../components/primary-button/PrimaryButton';
import SecondaryButton from '../../../../../components/secondary-button/SecondaryButton';
import useModal from '../../../../../components/modal/Modal.hook';
import {
  Verify_Employees_ListMutation,
  Verify_Employees_ListMutationVariables,
  Import_Employees_ListMutation,
  Import_Employees_ListMutationVariables,
} from '../../../../../@types/graphql.d';
import { Loader } from '../../../../../components/loader/Loader';

import { VERIFY_EMPLOYEES_LIST, IMPORT_EMPLOYEES_LIST } from './ImportEmployeesModal.gql';

import useDownloadAction from '../../../../../utils/hooks/useDownloadAction';

import translations from './ImportEmployeesModal.translations';

import styles from './ImportEmployeesModal.module.scss';
import { useIntl } from 'react-intl';

function DisplayChangesStep({ changes }: { changes: Verify_Employees_ListMutation['verifyEmployeesList'] }) {
  const intl = useIntl();
  if (!changes) {
    return <div className={styles.noDocumentHint}>{intl.formatMessage(translations.noDocument)}</div>;
  }
  if (changes.changes.length === 0 && changes.invitations.length === 0) {
    return <div className={styles.noDocumentHint}>{intl.formatMessage(translations.noChange)}</div>;
  }

  return (
    <div className={styles.changeWindow}>
      {changes?.changes.map((change) => (
        <div key={change.employee.id}>
          <span className={styles.employeeName}>{`${change.employee.firstName} ${change.employee.lastName} :`}</span>
          <ul>
            {change.changes.map((attrChange) => (
              <li key={attrChange.attr}>
                <span>
                  {(attrChange.attr === 'first_name' && intl.formatMessage(translations.firstName)) ||
                    (attrChange.attr === 'last_name' && intl.formatMessage(translations.lastName)) ||
                    (attrChange.attr === 'position' && intl.formatMessage(translations.position)) ||
                    attrChange.attr}
                </span>
                <span> : </span>
                <span>{attrChange.to}</span>
              </li>
            ))}
          </ul>
        </div>
      ))}
      {changes?.invitations.map((invitation) => (
        <div key={invitation.id}>
          <span className={styles.employeeName}>{`${invitation.firstName} ${invitation.lastName} : `}</span>
          <span>invitation</span>
          <ul>
            <li>{invitation.position}</li>
            <li>{invitation.email}</li>
          </ul>
        </div>
      ))}
    </div>
  );
}

function UploadStep({ file, onFileChange }: { file?: File; onFileChange: (file?: File) => void }) {
  const intl = useIntl();
  const handleSubmission = async ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) =>
    onFileChange(files?.[0]);

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const handleUploadClick = () => {
    if (!hiddenFileInput?.current?.click) {
      return;
    }
    hiddenFileInput.current.click();
  };

  return (
    <div>
      <div
        className={styles.downloadEmployeesList}
        onClick={handleUploadClick}
        aria-hidden
      >
        {file?.name || intl.formatMessage(translations.selectFile)}
      </div>
      <input
        type="file"
        accept=".xls,.xlsx"
        name="file"
        onChange={handleSubmission}
        ref={hiddenFileInput}
        style={{ display: 'none' }}
      />
    </div>
  );
}

function DownloadStep({ companyid, onFail }: { companyid: string; onFail: () => void }): JSX.Element {
  const intl = useIntl();
  const filename = useMemo(() => {
    const date = intl
      .formatDate(Date(), {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })
      .replace(/\//g, '-');
    return `${date}-liste-ambassadeurs`;
  }, []);

  const [loading, setLoading] = useState<boolean>(false);

  const download = useDownloadAction(
    `/companies/${companyid}/export_employees_list`,
    filename,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    () => {
      setLoading(true);
    },
    () => {
      setLoading(false);
    },
    () => {
      setLoading(false);
      onFail();
    },
  );

  return (
    <div>
      <div style={{ display: 'flex', columnGap: 5 }}>
        {loading && <div className={classnames(styles.loadingCircle)} />}
        <div
          className={classnames({
            [styles.downloadEmployeesList]: true,
            [styles.downloadEmployeesListDisabled]: loading,
          })}
          onClick={loading ? undefined : download}
          aria-hidden
        >
          {filename}
        </div>
      </div>
    </div>
  );
}

function ImportEmployeesModal({ companyid }: { companyid: string }) {
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const [, closeModal] = useModal();

  const [file, setFile] = useState<File>();
  const [verifyEmployeesListMutate, { error: verifyError, data: verifyData, loading: verifyLoading }] = useMutation<
    Verify_Employees_ListMutation,
    Verify_Employees_ListMutationVariables
  >(VERIFY_EMPLOYEES_LIST, {
    onError: () =>
      enqueueSnackbar(intl.formatMessage(translations.uploadError), {
        autoHideDuration: 5000,
        variant: 'error',
      }),
  });

  const [mutate, { loading }] = useMutation<Import_Employees_ListMutation, Import_Employees_ListMutationVariables>(
    IMPORT_EMPLOYEES_LIST,
    {
      onError: () =>
        enqueueSnackbar(intl.formatMessage(translations.confirmError), {
          autoHideDuration: 5000,
          variant: 'error',
        }),
      onCompleted: () => {
        enqueueSnackbar(intl.formatMessage(translations.importSuccess), {
          autoHideDuration: 5000,
          variant: 'success',
        });
      },
    },
  );

  if (!companyid || companyid === '') {
    return <Loader />;
  }

  const onFileChange = (newFile?: File) => {
    if (!newFile) {
      return;
    }
    setFile(newFile);
    verifyEmployeesListMutate({
      variables: { companyId: companyid, file: newFile },
    });
  };

  const onSubmit = () => {
    if (!file) {
      return;
    }
    mutate({ variables: { companyId: companyid, file } }).then(() => onFileChange(file));
    closeModal();
  };

  const submitDisabled =
    (!verifyData?.verifyEmployeesList?.changes.length && !verifyData?.verifyEmployeesList?.invitations.length) ||
    !!verifyError;

  return (
    <div>
      <div className={styles.description}>{intl.formatMessage(translations.description)}</div>
      <ol className={styles.steps}>
        <li className={styles.step}>
          {intl.formatMessage(translations.downloadEmployeesList)}
          <DownloadStep
            companyid={companyid}
            onFail={() =>
              enqueueSnackbar(intl.formatMessage(translations.downloadError), {
                autoHideDuration: 5000,
                variant: 'error',
              })
            }
          />
        </li>
        <li className={styles.step}>{intl.formatMessage(translations.updateEmployees)}</li>
        <li className={styles.step}>
          {intl.formatMessage(translations.sendEmployeesList)}
          <UploadStep
            file={file}
            onFileChange={onFileChange}
          />
        </li>
        <li className={classnames(styles.step, !file && styles.stepDisabled)}>
          {intl.formatMessage(translations.displayChangesStep)}
          {(verifyLoading && <Loader />) || <DisplayChangesStep changes={verifyData?.verifyEmployeesList} />}
        </li>
        <li className={classnames(styles.step, submitDisabled && styles.stepDisabled)}>
          {intl.formatMessage(translations.confirmEdition)}
          {loading && <Loader />}
        </li>
      </ol>
      <ButtonsGroup>
        <SecondaryButton
          label={intl.formatMessage(translations.cancelText)}
          onClick={closeModal}
        />
        <PrimaryButton
          label={intl.formatMessage(translations.submitText)}
          disabled={submitDisabled}
          onClick={onSubmit}
        />
      </ButtonsGroup>
    </div>
  );
}

export default ImportEmployeesModal;
