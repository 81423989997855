import { defineMessages } from 'react-intl';
import { Month } from '../../../@types/enum.d';

export default defineMessages({
  [Month.Month1]: {
    id: 'CommonTranslations.MonthFromNumber.january',
    defaultMessage: 'janvier',
    description: 'mois de janvier',
  },
  [Month.Month2]: {
    id: 'CommonTranslations.MonthFromNumber.february',
    defaultMessage: 'février',
    description: 'mois de février',
  },
  [Month.Month3]: {
    id: 'CommonTranslations.MonthFromNumber.march',
    defaultMessage: 'mars',
    description: 'mois de mars',
  },
  [Month.Month4]: {
    id: 'CommonTranslations.MonthFromNumber.april',
    defaultMessage: 'avril',
    description: 'mois de avril',
  },
  [Month.Month5]: {
    id: 'CommonTranslations.MonthFromNumber.may',
    defaultMessage: 'mai',
    description: 'mois de mai',
  },
  [Month.Month6]: {
    id: 'CommonTranslations.MonthFromNumber.june',
    defaultMessage: 'juin',
    description: 'mois de juin',
  },
  [Month.Month7]: {
    id: 'CommonTranslations.MonthFromNumber.july',
    defaultMessage: 'juillet',
    description: 'mois de juillet',
  },
  [Month.Month8]: {
    id: 'CommonTranslations.MonthFromNumber.august',
    defaultMessage: 'aout',
    description: 'mois de aout',
  },
  [Month.Month9]: {
    id: 'CommonTranslations.MonthFromNumber.september',
    defaultMessage: 'septembre',
    description: 'mois de septembre',
  },
  [Month.Month10]: {
    id: 'CommonTranslations.MonthFromNumber.october',
    defaultMessage: 'octobre',
    description: 'mois de octobre',
  },
  [Month.Month11]: {
    id: 'CommonTranslations.MonthFromNumber.november',
    defaultMessage: 'novembre',
    description: 'mois de novembre',
  },
  [Month.Month12]: {
    id: 'CommonTranslations.MonthFromNumber.december',
    defaultMessage: 'décembre',
    description: 'mois de décembre',
  },
});
