import React from 'react';
import CompanyAdminAppointmentsHeader from './components/company-admin-appointments-header/CompanyAdminAppointmentsHeader';
import CompanyAdminAppointmentsList from './components/company-admin-appointments-list/CompanyAdminAppointmentsList';

import styles from './CompanyAdminAppointmentsView.module.scss';

function CompanyAdminAppointmentsView() {
  return (
    <main className={styles.companyAdminAppointmentsView}>
      <div className={styles.container}>
        <CompanyAdminAppointmentsHeader />
        <CompanyAdminAppointmentsList />
      </div>
    </main>
  );
}

export const CompanyAdminAppointmentsViewForMJG = CompanyAdminAppointmentsView;
export default CompanyAdminAppointmentsView;
