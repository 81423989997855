import { gql } from '@apollo/client';

export const GET_CONVERSATION_CREATED_AT = gql`
  query GET_CONVERSATION_CREATED_AT($conversationId: ID!) {
    conversation(conversationId: $conversationId) {
      id
      createdAt
    }
  }
`;

export const GET_CONVERSATION_TYPE = gql`
  query GET_CONVERSATION_TYPE($conversationId: ID!) {
    conversation(conversationId: $conversationId) {
      id
      conversationType
    }
  }
`;

export const GET_PARTICIPANTS_INFORMATIONS = gql`
  query GET_PARTICIPANTS_INFORMATIONS($conversationId: ID!) {
    conversation(conversationId: $conversationId) {
      id
      recipient {
        id
        birthdate
      }
      initiator {
        id
        birthdate
      }
    }
  }
`;

export const GET_APPOINTMENT_QUERY = gql`
  query GetAppointment($id: ID!) {
    appointment(id: $id) {
      id
      from
      meetingPlace
      videoconferenceLink
      type
      currentUserCanMove
    }
  }
`;

export const BOOK_APPOINTMENT_MUTATION = gql`
  mutation BookAppointment(
    $userId: ID!
    $startAt: ISO8601DateTime!
    $type: AppointmentTypesEnum!
    $meetingPlace: String
    $videoconferenceLink: String
  ) {
    bookAppointment(
      userId: $userId
      startAt: $startAt
      type: $type
      meetingPlace: $meetingPlace
      videoconferenceLink: $videoconferenceLink
    ) {
      id
    }
  }
`;

export const MOVE_APPOINTMENT_MUTATION = gql`
  mutation MoveAppointment(
    $id: ID!
    $startAt: ISO8601DateTime!
    $type: AppointmentTypesEnum!
    $meetingPlace: String
    $videoconferenceLink: String
  ) {
    moveAppointment(
      id: $id
      startAt: $startAt
      type: $type
      meetingPlace: $meetingPlace
      videoconferenceLink: $videoconferenceLink
    ) {
      id
    }
  }
`;

export const CLAIM_APPOINTMENT_MUTATION = gql`
  mutation ClaimAppointment(
    $userId: ID!
    $conversationId: ID!
    $startAt: ISO8601DateTime!
    $type: AppointmentTypesEnum!
    $meetingPlace: String
  ) {
    claimAppointment(
      userId: $userId
      conversationId: $conversationId
      startAt: $startAt
      type: $type
      meetingPlace: $meetingPlace
    ) {
      id
    }
  }
`;
