import { defineMessages } from 'react-intl';

export default defineMessages({
  yourAttachmentCannotBeRetrieved: {
    id: 'Message.yourAttachmentCannotBeRetrieved',
    defaultMessage: "Votre pièce jointe n'a pas pu être récupérée",
    description: 'Item message failed - Message d avertissement de pièce jointe non récupèrable',
  },
  attachmentRejected: {
    id: 'Message.attachmentFileRejected',
    defaultMessage:
      'Notre antivirus a détecté que ce fichier était dangereux. Par mesure de sécurité, vous ne pouvez pas le télécharger.',
    description: "La pièce jointe a été refusé par l'antivirus",
  },
  scanInProgress: {
    id: 'Message.scanInProgress',
    defaultMessage: "Ce fichier est en cours d'analyse par notre antivirus. Réessayez de le télécharger plus tard.",
    description: "La pièce jointe est en cour d'analyse",
  },
  seen: {
    id: 'Message.seen',
    defaultMessage: 'Lu',
  },
});
