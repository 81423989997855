import { defineMessages } from 'react-intl';
import { GraphqlGeneralErrors } from '../../../../../@types/graphql-errors.d';

export default defineMessages({
  [GraphqlGeneralErrors.GRAPHQL_NO_NETWORK]: {
    id: 'FormikStatusError.GRAPHQL_NO_NETWORK',
    defaultMessage: "Oups, il semblerait qu'on ait perdu le contact !",
  },
  [GraphqlGeneralErrors.GRAPHQL_UNKNOWN]: {
    id: 'FormikStatusError.GRAPHQL_UNKNOWN',
    defaultMessage: 'Erreur inconnue. Contactez-nous.',
  },
});
