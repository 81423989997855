import React from 'react';
import { TableHeaderProps } from 'react-virtualized';
import SortHeaderRenderer from '../sort-header-renderer/SortHeaderRenderer';

import styles from './DateHeaderRenderer.module.scss';

function DateHeaderRenderer({ label, dataKey, sortBy, sortDirection }: TableHeaderProps) {
  return (
    <div className={styles.dateHeaderRenderer}>
      {label}
      <SortHeaderRenderer
        dataKey={dataKey}
        sortBy={sortBy}
        sortDirection={sortDirection}
      />
    </div>
  );
}

export default DateHeaderRenderer;
