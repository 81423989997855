import React, { useContext } from 'react';
import classnames from 'classnames';
import { WaterfallProps } from '../../../../@types/HeaderConfig';
import useWindowSize from '../../../../../../../utils/hooks/useWindowSize';
import { breakpoints } from '../../../../../../../variables';
import UsernavContext from '../../../../context/UsernavContext';
import translations from './UsernavItemSwitchAccount.translations';
import styles from './UsernavItemSwitchAccount.module.scss';
import { useIntl } from 'react-intl';
import SwitchIcon from '../../../../../../../icons/component-icons/SwitchIcon';

interface UsernavItemSwitchExternalAccountProps extends WaterfallProps {}

function UsernavItemSwitchExternalAccount({ to, className, label }: UsernavItemSwitchExternalAccountProps) {
  const { closeUsernavMenu } = useContext(UsernavContext);
  const intl = useIntl();
  function onClick() {
    closeUsernavMenu();
  }
  const { width } = useWindowSize();
  if (!to) {
    return null;
  }
  const disabled = width < breakpoints.sm;
  return (
    <a
      href={to}
      className={classnames(className, styles.usernavItemSwitchAccount, {
        [styles.disabled]: disabled,
      })}
      onClick={onClick}
    >
      <SwitchIcon className={styles.icon} />
      <span className={styles.label}>
        {label}
        {disabled && (
          <>
            <br />
            <span className={styles.onlyDesktop}>{`(${intl.formatMessage(translations.onlyDesktop)})`}</span>
          </>
        )}
      </span>
    </a>
  );
}

export default UsernavItemSwitchExternalAccount;
