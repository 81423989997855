import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import {
  Get_Company_Information_For_DashboardQuery,
  Get_Company_Information_For_DashboardQueryVariables,
} from '../../../../../@types/graphql.d';
import useCompanyUnique from '../../../hooks/useCompanyUnique/useCompanyUnique';
import CompanyReportDownload from './components/company-report-download/CompanyReportDownload';
import { GET_COMPANY_INFORMATION_FOR_DASHBOARD } from './CompanyAdminDashboardHeader.gql';
import { CurrentUserContext } from '../../../../../contexts/current-user-context/CurrentUserContext';
import translations from './CompanyAdminDashboardHeader.translations';

import styles from './CompanyAdminDashboardHeader.module.scss';
import { useIntl } from 'react-intl';

interface CompanyAdminDashboardHeaderProps {
  className?: string;
}
function CompanyAdminDashboardHeader({ className }: CompanyAdminDashboardHeaderProps) {
  const intl = useIntl();
  const { currentUser } = useContext(CurrentUserContext);
  const companyId = useCompanyUnique();
  const { data } = useQuery<
    Get_Company_Information_For_DashboardQuery,
    Get_Company_Information_For_DashboardQueryVariables
  >(GET_COMPANY_INFORMATION_FOR_DASHBOARD, {
    skip: !companyId,
    variables: {
      companyId: companyId || '',
    },
    fetchPolicy: 'network-only',
  });

  return (
    <section
      data-testid="dashboard-header"
      className={classnames(className, styles.companyAdminDashboardHeader)}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.companyLogoWrapper}>
          <img
            src={data?.company?.companyLogo || 'https://assets.myjobglasses.com/images/fallback-company.png'}
            className={styles.companyLogo}
            alt=""
          />
        </div>
        <div className={styles.companyInformations}>
          <p className={styles.hello}>
            {data?.company?.isPremiumSchool
              ? intl.formatMessage(translations.hello, {
                  firstName: currentUser?.firstName,
                })
              : intl.formatMessage(translations.dashboard)}
          </p>
          <p className={styles.employeesData}>
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(
                  (data?.company?.publishedEmployeesCount ?? 0) <= 1
                    ? translations.employeesDataSingular
                    : translations.employeesDataPlural,
                  {
                    publishedEmployeesCount: data?.company?.publishedEmployeesCount || 0,
                  },
                ),
              }}
            />
          </p>
        </div>

        <CompanyReportDownload className={styles.companyReportDownload} />
      </div>
    </section>
  );
}

export default CompanyAdminDashboardHeader;
