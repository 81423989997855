import { defineMessages } from 'react-intl';
import { AppointmentStatus } from '../../../../../../../@types/graphql.d';

export default defineMessages({
  liked: {
    id: 'PastAppointmentReviewStudent.liked',
    defaultMessage: "Vous avez recommandé l'ambassadeur",
    description: 'Pro recommandé label',
  },
  disliked: {
    id: 'PastAppointmentReviewStudent.disliked',
    defaultMessage: "Vous n'avez pas recommandé l'ambassadeur",
    description: 'Pro non recommandé label',
  },
  reviewAppointmentButton: {
    id: 'PastAppointmentNoReviewStudent.buttonLabel',
    defaultMessage: 'évaluer rendez-vous',
    description: 'Label du bouton évaluer',
  },
  studentPositiveReview: {
    id: 'PastAppointmentReviewedProfessional.studentPositiveReview',
    defaultMessage: 'Le Membre vous a recommandé.',
    description: "Affichage de la recommandation de l étudiant dans la messagerie pour l'Ambassadeur sur un RDV passé",
  },
  upcoming: {
    id: 'AppointmentDisplay.upcoming',
    defaultMessage: 'à venir',
    description: 'label à venir',
  },
  waitingClaimConfirmation: {
    id: 'AppointmentDisplay.waitingClaimConfirmation',
    defaultMessage: 'Demande de confirmation',
    description: 'status en attente de confirmation',
  },
  at: {
    id: 'AppointmentDisplay.at',
    defaultMessage: 'à {hour}',
    description: 'affiche l heure du RDV',
  },
  newAppointmentButton: {
    id: 'PastAppointmentReviewedProfessional.newAppointmentButton',
    defaultMessage: 'nouveau rendez-vous',
    description: 'Bouton nouveau RDV dans la messagerie sur un RDV passé',
  },
});

export const pastAppointmentStatusTranslations = defineMessages<string>({
  [AppointmentStatus.TookPlace]: {
    id: 'PastAppointmentDisplay.done',
    defaultMessage: 'effectué',
    description: 'label quand le RDV a été effectué',
  },
  [AppointmentStatus.Rescheduled]: {
    id: 'PastAppointmentDisplay.rescheduled',
    defaultMessage: 'reprogrammé',
    description: 'label quand le RDV a été reporté',
  },
  [AppointmentStatus.DidNotShowUp]: {
    id: 'PastAppointmentDisplay.did_not_show_up',
    defaultMessage: 'non présenté',
    description: "label quand l'interlocuteur ne s'est pas présenté",
  },
  [AppointmentStatus.Cancelled]: {
    id: 'PastAppointmentDisplay.cancelled',
    defaultMessage: 'annulé',
    description: 'label quand le RDV a été annulé',
  },
  [AppointmentStatus.WasNotScheduled]: {
    id: 'PastAppointmentDisplay.was_not_scheduled',
    defaultMessage: 'non planifié',
    description: "label quand le RDV n'a pas été planifié",
  },
  [AppointmentStatus.ForgotToGo]: {
    id: 'PastAppointmentDisplay.both_forgot_to_go',
    defaultMessage: 'non effectué',
    description: 'label quand le RDV a été oublié par les deux parties',
  },
  [AppointmentStatus.AwaitingReview]: {
    id: 'PastAppointmentDisplay.awaiting_review',
    defaultMessage: 'non évalué',
    description: 'label quand le RDV est en attente de retour',
  },
  [AppointmentStatus.Other]: {
    id: 'PastAppointmentDisplay.other',
    defaultMessage: 'autre',
    description: 'label pour les autres cas',
  },
  [AppointmentStatus.Unbooked]: {
    id: 'PastAppointmentDisplay.unbooked',
    defaultMessage: 'non réservé',
    description: "label quand le RDV n'a pas été réservé",
  },
});

export const pastAppointmentTranslations = defineMessages({
  studentPositiveReview: {
    id: 'PastAppointmentReviewedProfessional.studentPositiveReview',
    defaultMessage: 'Le Membre vous a recommandé.',
    description: "Affichage de la recommandation de l étudiant dans la messagerie pour l'Ambassadeur sur un RDV passé",
  },
  softDeletedStudent: {
    id: 'PastAppointmentReviewedProfessional.softDeletedStudent',
    defaultMessage: 'Profil supprimé',
    description: 'label profil supprimé',
  },
  softDeletedStudentDescription: {
    id: 'PastAppointmentReviewedProfessional.softDeletedStudentDescription',
    defaultMessage: 'Vous ne pouvez plus prévoir de rendez-vous.',
    description: 'Description lorsque étudiant supprimé',
  },
});

export const pastAppointmentNoReviewStudentTranslations = defineMessages({
  clickLinkToReview: {
    id: 'PastAppointmentNoReviewStudent.clickLinkToReview',
    defaultMessage: 'Cliquez ci-dessous pour dire au professionnel ce que vous avez pensé de votre échange.',
    description: 'description pour cliquer sur le bouton de notation',
  },
  buttonLabel: {
    id: 'PastAppointmentNoReviewStudent.buttonLabel',
    defaultMessage: 'évaluer rendez-vous',
    description: 'Label du bouton évaluer',
  },
  at: {
    id: 'PastAppointmentNoReviewStudent.at',
    defaultMessage: 'à {hour}',
    description: 'affiche l heure du RDV',
  },
});

export const pastAppointmentNotReviewedProfessionalTranslations = defineMessages({
  clickLinkToReview: {
    id: 'PastAppointmentNotReviewedProfessional.clickLinkToReview',
    defaultMessage:
      'Votre évaluation permet aux Membres de progresser et savoir ce que vous avez pensé de votre échange. ',
    description: 'description pour cliquer sur le bouton de notation',
  },
  buttonLabel: {
    id: 'PastAppointmentNotReviewedProfessional.buttonLabel',
    defaultMessage: 'évaluer rendez-vous',
    description: 'Label du bouton évaluer',
  },
  at: {
    id: 'PastAppointmentNotReviewedProfessional.at',
    defaultMessage: 'à {hour}',
    description: 'affiche l heure du RDV',
  },
});

export const pastAppointmentReviewedProfessionalTranslations = defineMessages({
  studentPositiveReview: {
    id: 'PastAppointmentReviewedProfessional.studentPositiveReview',
    defaultMessage: 'Le Membre vous a recommandé.',
    description: "Affichage de la recommandation de l étudiant dans la messagerie pour l'Ambassadeur sur un RDV passé",
  },
  softDeletedStudent: {
    id: 'PastAppointmentReviewedProfessional.softDeletedStudent',
    defaultMessage: 'Profil supprimé',
    description: 'label profil supprimé',
  },
  softDeletedStudentDescription: {
    id: 'PastAppointmentReviewedProfessional.softDeletedStudentDescription',
    defaultMessage: 'Vous ne pouvez plus prévoir de rendez-vous.',
    description: 'Description lorsque étudiant supprimé',
  },
});
