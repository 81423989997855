import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'CompanyAdminAppointmentSidebar.description.title',
    defaultMessage: 'Présentation',
  },
  noDescription: {
    id: 'CompanyAdminAppointmentSidebar.description.noDescription',
    defaultMessage: 'Aucune présentation ajoutée',
  },
});
