import React from 'react';
import classNames from 'classnames';

export type SendMessageFieldProps = {
  field: {
    name: string;
  };
  form: {
    errors: {
      [key: string]: string;
    };
  };
  className?: string;
  rows?: number;
  placeholder?: string;
  disabled?: boolean;
};

/* eslint-disable react/jsx-props-no-spreading */
const SendMessageField = ({
  field,
  form,
  className = undefined,
  rows = 4,
  placeholder = undefined,
  disabled = false,
}: SendMessageFieldProps) => (
  <textarea
    placeholder={placeholder}
    className={classNames({
      [className || '']: !!className,
      'send-message-field': true,
      'send-message-field-error': form?.errors?.[field.name],
    })}
    rows={rows}
    disabled={disabled}
    {...field}
  />
);

export default SendMessageField;
