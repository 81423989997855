import { defineMessages } from 'react-intl';

export default defineMessages({
  deletedUser: {
    id: 'CompanyAdminAppointmentsList.deletedUser',
    defaultMessage: 'Utilisateur supprimé',
  },
  memberMeeting: {
    id: 'CompanyAdminAppointmentsList.memberMeeting',
    defaultMessage: 'Membre Rencontré',
  },
});
