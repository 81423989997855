import { gql } from '@apollo/client';

export const GET_COMPANY_ACTIVITY_REPORT = gql`
  query GET_COMPANY_ACTIVITY_REPORT($range: CompanyReportRange!, $companyId: ID!) {
    companyReport(range: $range, companyId: $companyId) {
      company {
        name
      }
      companyId
      newConversationCount
      contactedEmployeesCount
      responseRate
      appointmentCount
      publishedEmployeeCount
      publishedEmployeesWithAppointmentRate
      isPremiumSchool
    }
  }
`;

export const GET_COMPANY_AUTHORIZATION_DASHBOARD_QUERY = gql`
  query GetCompanyAuthorizationDashboard($companyId: ID!) {
    company(companyId: $companyId) {
      canShowFullDashboardStats
      canShowPartialDashboardStats
    }
  }
`;
