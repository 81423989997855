import React from 'react';
import { IconProps } from './Icon';

export default function GraduateHatIcon({ className }: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="graduation-hat-01">
        <path
          id="Icon"
          d="M4.1665 8.33331V13.3426C4.1665 13.6417 4.1665 13.7913 4.21205 13.9233C4.25233 14.0401 4.31806 14.1465 4.40449 14.2347C4.50222 14.3345 4.63601 14.4014 4.90355 14.5352L9.40355 16.7852C9.62218 16.8945 9.73151 16.9491 9.84617 16.9707C9.94773 16.9897 10.0519 16.9897 10.1535 16.9707C10.2682 16.9491 10.3775 16.8945 10.5961 16.7852L15.0961 14.5352C15.3637 14.4014 15.4974 14.3345 15.5952 14.2347C15.6816 14.1465 15.7473 14.0401 15.7876 13.9233C15.8332 13.7913 15.8332 13.6417 15.8332 13.3426V8.33331M1.6665 7.08331L9.7017 3.06571C9.81101 3.01105 9.86567 2.98372 9.923 2.97297C9.97378 2.96344 10.0259 2.96344 10.0767 2.97297C10.134 2.98372 10.1887 3.01105 10.298 3.06571L18.3332 7.08331L10.298 11.1009C10.1887 11.1556 10.134 11.1829 10.0767 11.1936C10.0259 11.2032 9.97378 11.2032 9.923 11.1936C9.86567 11.1829 9.81101 11.1556 9.7017 11.1009L1.6665 7.08331Z"
          stroke="inherit"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
