import { gql } from '@apollo/client';

export const GET_STUDENT_WISHLIST = gql`
  query GET_STUDENT_WISHLIST($after: String) {
    wishlist(first: 16, after: $after) {
      nodes {
        id
        firstName
        lastName
        avatar
        banner {
          url
        }
        background: avatar(version: BLURRED)
        companyName
        jobTitle
        companyLogo
        status
        profileType
        likeCount
        unavailableUntil
        userId
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
