import React from 'react';

function ExclamationMarkIcon() {
  return (
    <svg viewBox="0 0 24 24">
      <path
        fill="#0E0E11"
        d="M12,15.5c0.276,0,0.5-0.224,0.5-0.5V5.5C12.5,5.224,12.276,5,12,5s-0.5,0.224-0.5,0.5V15C11.5,15.276,11.724,15.5,12,15.5z"
      />
      <circle
        fill="#0E0E11"
        cx="12"
        cy="18"
        r="1"
      />
    </svg>
  );
}

export default ExclamationMarkIcon;
