import { defineMessages } from 'react-intl';

const commonErrorTranslations = defineMessages({
  unknown: {
    id: 'errors.unknown',
    defaultMessage: 'Une erreur est survenue, merci de réessayer',
  },
  inputEmpty: {
    id: 'errors.inputEmpty',
    defaultMessage: 'Ce champs est requis.',
  },
  inputNotNumeric: {
    id: 'errors.inputNotNumeric',
    defaultMessage: 'Ce champs ne doit contenir que des chiffres.',
  },
  minimalExperience: {
    id: 'errors.minimalExperience',
    defaultMessage: 'Ce champs doit être supérieur à 0.',
  },
  maximalExperience: {
    id: 'errors.maximalExperience',
    defaultMessage: 'Ce champs doit être inférieur à 100.',
  },
});

export default commonErrorTranslations;
