import React from 'react';
import classnames from 'classnames';
import Help from '../../../../../../common/help/Help';

import styles from './CompanyAdminDashboardCountBlue.module.scss';

interface CompanyAdminDashboardCountBlueProps {
  title: string;
  tooltip?: string;
  value: number | '-';
  className?: string;
  blurred?: boolean;
}
function CompanyAdminDashboardCountBlue({
  title,
  tooltip,
  value,
  className,
  blurred,
}: CompanyAdminDashboardCountBlueProps): JSX.Element {
  return (
    <div className={classnames(className, styles.companyAdminDashboardBlue)}>
      <p className={classnames(styles.value, { blurred })}>{value}</p>
      <div className={styles.titleWithTooltip}>
        <span className={styles.title}>{title}</span>
        {tooltip && (
          <Help
            text={tooltip}
            variant="blue-dark"
          />
        )}
      </div>
    </div>
  );
}

export default CompanyAdminDashboardCountBlue;
