import { gql } from '@apollo/client';

export const GET_COMPANY_PRESENTATION = gql`
  query GET_COMPANY_PRESENTATION($companyId: ID!) {
    company(companyId: $companyId) {
      id
      description
      isPremiumSchool
    }
  }
`;
