import React from 'react';
import classnames from 'classnames';
import { TableHeaderProps } from 'react-virtualized';

import styles from './SortHeaderRenderer.module.scss';

function SortHeaderRenderer({ dataKey, sortBy, sortDirection }: TableHeaderProps) {
  if (!sortBy || !sortDirection) {
    return null;
  }
  return (
    <div className={styles.sortArrows}>
      <span
        className={classnames(styles.arrow, {
          [styles.active]: dataKey === sortBy && sortDirection === 'ASC',
        })}
      >
        &#9650;
      </span>
      <span
        className={classnames(styles.arrow, {
          [styles.active]: dataKey === sortBy && sortDirection === 'DESC',
        })}
      >
        &#9660;
      </span>
    </div>
  );
}

export default SortHeaderRenderer;
