import React, { memo } from 'react';

import { StepProps } from '../Step';
import { PerceptionSection } from './MessagePerceptionSection';
import { MessagePreviewSection } from './MessagePreviewSection';

export const MessagePreviewWithPerceptionSection: React.FC<StepProps> = memo(({ formik, perception, setAnswers }) => (
  <div className="new-conversation_preview">
    <MessagePreviewSection formik={formik} />
    {perception && setAnswers && (
      <PerceptionSection
        perceptionQuestion={perception}
        onAnswersChange={setAnswers}
      />
    )}
  </div>
));
