import React from 'react';
import { useQuery } from '@apollo/client';
import {
  Get_Company_Report_Date_RangeQuery,
  Get_Company_Report_Date_RangeQueryVariables,
} from '../../../../../../../@types/graphql.d';
import useCompanyUnique from '../../../../../hooks/useCompanyUnique/useCompanyUnique';
import { useCurrentRange } from '../../../../../hooks/useCurrentRange';
import translations from './CompanyReportRangeDisplay.translations';
import { GET_COMPANY_REPORT_DATE_RANGE } from './CompanyReportRangeDisplay.gql';

import styles from './CompanyReportRangeDisplay.module.scss';
import { useIntl } from 'react-intl';

function CompanyReportRangeDisplay() {
  const intl = useIntl();
  const companyId = useCompanyUnique();
  const currentRange = useCurrentRange();
  const { data } = useQuery<Get_Company_Report_Date_RangeQuery, Get_Company_Report_Date_RangeQueryVariables>(
    GET_COMPANY_REPORT_DATE_RANGE,
    {
      skip: !companyId,
      variables: {
        companyId: companyId || '',
        range: currentRange,
      },
      fetchPolicy: 'network-only',
    },
  );
  return (
    <div className={styles.companyReportDisplay}>
      {intl.formatMessage(translations.fromTo, {
        from: data?.companyReport?.from
          ? intl.formatDate(data?.companyReport?.from, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
          : '-',
        to: data?.companyReport?.to
          ? intl.formatDate(data?.companyReport?.to, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
          : '-',
      })}
    </div>
  );
}

export default CompanyReportRangeDisplay;
