import { defineMessages } from 'react-intl';

export default defineMessages({
  description: {
    id: 'CompanyPresentationEmpty.description',
    defaultMessage: 'Aucune présentation ajoutée',
  },
  addButton: {
    id: 'CompanyPresentationEmpty.addButton',
    defaultMessage: 'Ajouter',
  },
});
