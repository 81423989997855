import React, { useContext } from 'react';
import { generatePath, Link } from 'react-router';
import UsernavContext from '../../../../context/UsernavContext';
import { UsernavItemCompanyLinkProps } from '../../../../../@types/Usernav';
import { GET_COMPANY_NAVBAR_AUTHORIZATIONS_QUERY } from '../../../../config/connect/connectAuthorizations.gql';
import { useQuery } from '@apollo/client';
import {
  GetCompanyNavbarAuthorizationsQuery,
  GetCompanyNavbarAuthorizationsQueryVariables,
} from '../../../../../../../@types/graphql.d';
import useMatchedRoute from '../../../../../../../hooks/useMatchedRoute.hook';
import Routes, { CompanyAdminRoutes, MJGAdminRoutes } from '../../../../../../../routes';

function UsernavItemCompanyLink({ to, companyId, label, className }: UsernavItemCompanyLinkProps) {
  const match = useMatchedRoute(CompanyAdminRoutes as unknown as Routes) || useMatchedRoute(MJGAdminRoutes as unknown as Routes);
  const id = match?.params.companyid || match?.params.id;
  const { closeUsernavMenu } = useContext(UsernavContext);
  function onClick() {
    closeUsernavMenu();
  }

  const { data } = useQuery<GetCompanyNavbarAuthorizationsQuery, GetCompanyNavbarAuthorizationsQueryVariables>(
    GET_COMPANY_NAVBAR_AUTHORIZATIONS_QUERY,
    {
      variables: { companyId: id! },
      skip: !id,
      fetchPolicy: 'network-only',
    },
  );

  if (!data?.company?.isPremiumSchool && label === 'Configuration') {
    return null;
  }
  return (
    <Link
      to={generatePath(to, { companyid: id || '' })}
      className={className}
      onClick={onClick}
    >
      {label}
    </Link>
  );
}

export default UsernavItemCompanyLink;
