import { defineMessages } from 'react-intl';

const translations = defineMessages({
  oneStarFeedback: {
    id: 'starRadioInput.oneStarFeedback',
    defaultMessage: "Pas du tout d'accord",
    description: 'feedback réponse 1 étoile',
  },
  twoStarFeedback: {
    id: 'starRadioInput.twoStarFeedback',
    defaultMessage: 'Pas d\'accord',
    description: 'feedback réponse 2 étoiles',
  },
  threeStarFeedback: {
    id: 'starRadioInput.threeStarFeedback',
    defaultMessage: "Ni d’accord, ni pas d’accord (je ne sais pas)",
    description: 'feedback réponse 3 étoiles',
  },
  fourStarFeedback: {
    id: 'starRadioInput.fourStarFeedback',
    defaultMessage: 'D\'accord',
    description: 'feedback réponse 4 étoiles',
  },
  fiveStarFeedback: {
    id: 'starRadioInput.fiveStarFeedback',
    defaultMessage: 'Tout à fait d’accord',
    description: 'feedback réponse 5 étoiles',
  },
});

export default translations;
