import { defineMessages } from 'react-intl';

export const ProgramsTranslations = defineMessages({
  title: {
    id: 'Programs.title',
    defaultMessage: 'Programmes',
  },
  noProgramSubtitle: {
    id: 'Programs.noProgram',
    defaultMessage: 'Rejoindre un programme d’orientation',
  },
  noProgramText: {
    id: 'Programs.noProgramText',
    defaultMessage:
      'Les partenaires de My Job Glasses mettent en place des programmes pour accompagner des Membres dans leur orientation. Pour y participer, renseignez le code qui vous a été transmis.',
  },
  vocationAccessText: {
    id: 'Programs.vocationAccessText',
    defaultMessage: 'Cliquez ici pour accéder à votre espace Vocation',
  },
  vocationAccessButton: {
    id: 'Programs.vocationAccessButton',
    defaultMessage: 'Accéder à mon espace vocation',
  },
  joinProgramButton: {
    id: 'joinProgramButton.participateButton',
    defaultMessage: 'Rejoindre un programme',
  },
  joinNewProgramButton: {
    id: 'joinProgramButton.joinNewProgramButton',
    defaultMessage: 'Rejoindre un nouveau programme',
  },
  joinProgramModaltitle: {
    id: 'Programs.joinProgramModaltitle',
    defaultMessage: 'Participer à un programme',
  },
});
