import React from 'react';

function PauseIcon() {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M9.5,7C9.224,7,9,7.224,9,7.5v9C9,16.776,9.224,17,9.5,17s0.5-0.224,0.5-0.5v-9C10,7.224,9.776,7,9.5,7z" />
      <path d="M14.5,7C14.224,7,14,7.224,14,7.5v9c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5v-9C15,7.224,14.776,7,14.5,7z" />
    </svg>
  );
}

export default PauseIcon;
