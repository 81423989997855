import React from 'react';
import qs from 'querystring';
import { useQuery } from '@apollo/client';
import BuildingIcon from '../../../../../icons/component-icons/BuildingIcon';
import {
  AmbassadorCompanyPresentationQuery,
  AmbassadorCompanyPresentationQueryVariables,
  SearchTypeEnum
} from '../../../../../@types/graphql.d';
import { COMPANY_PRESENTATION_QUERY } from './CompanyPresentation.gql';
import { AmbassadorViewCompanyPresentationTranslations } from './CompanyPresentation.translations';

import Card, { CardLoader } from '../../../../../components/card/Card';
import Skeleton from 'react-loading-skeleton';
import { useIntl } from 'react-intl';
import { Link } from 'react-router';
import Routes from '../../../../../routes';

type PresentationProps = {
  id: string;
};

export default function CompanyPresentation({ id }: PresentationProps) {
  const intl = useIntl();
  const { data, loading } = useQuery<AmbassadorCompanyPresentationQuery, AmbassadorCompanyPresentationQueryVariables>(
    COMPANY_PRESENTATION_QUERY,
    {
      variables: { id },
      fetchPolicy: 'network-only',
    },
  );

  if (loading) return <CompanyPresentationLoader />;
  if (!data?.getUser) return null;

  const { companyName, companyDescription, isPremiumAmbassador, premiumSchoolAmbassador } = data.getUser;

  if (!isPremiumAmbassador || !companyDescription) return null;

  return (
    <section>
      <Card
        icon={BuildingIcon}
        title={intl.formatMessage(AmbassadorViewCompanyPresentationTranslations.title, {
          companyName,
        })}
      >
        <p style={{ whiteSpace: 'pre-line' }}>{companyDescription}</p>
        {premiumSchoolAmbassador ? (
          <div className="ambassador-view__see-employees-wrapper">
            <Link
              to={`${Routes.search}?${qs.stringify({
                'filters[companies]': companyName,
              })}`}
              onClick={() => localStorage.setItem('search_type', SearchTypeEnum.OnlyStudents)}
            >
              {intl.formatMessage(AmbassadorViewCompanyPresentationTranslations.seeEmployees)}
            </Link>
          </div>
        ) : (
          <div className="ambassador-view__see-employees-wrapper">
            <Link
              to={`${Routes.search}?${qs.stringify({
                'filters[companies]': companyName,
                'filters[professional_types]': 'company/employee',
              })}`}
              onClick={() => localStorage.setItem('search_type', SearchTypeEnum.OnlyProfessionals)}
            >
              {intl.formatMessage(AmbassadorViewCompanyPresentationTranslations.seeEmployees)}
            </Link>
          </div>
        )}
      </Card>
    </section>
  );
}

const CompanyPresentationLoader = () => (
  <section>
    <CardLoader>
      <Skeleton count={6} />
    </CardLoader>
  </section>
);
