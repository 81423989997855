import React, { useContext } from 'react';
import CalendarCheckedIcon from '../../../../../../../icons/component-icons/CalendarCheckedIcon';
import { useIntl } from 'react-intl';
import translations from './NoAppointment.translations';
import { ConversationViewContext } from '../../../../context/ConversationViewContext';
import { AppointmentBookingErrorEnum } from '../../../../../../../@types/graphql.d';
import AmbassadorPleaseAnswer from './components/AmbassadorPleaseAnswer';
import './NoAppointment.scss';

type AmbassadorPleaseAnswerProps = {
  firstName?: string;
};

function NoAppointment() {
  const intl = useIntl();
  const { conversation } = useContext(ConversationViewContext);

  if (
    !conversation.currentUserCanBookAppointmentErrors.includes(
      AppointmentBookingErrorEnum.MustBeTheConversationRecipient,
    ) &&
    conversation.currentUserCanBookAppointmentErrors.includes(AppointmentBookingErrorEnum.AmbassadorMustHaveAReply)
  ) {
    return <AmbassadorPleaseAnswer firstName={conversation.interlocutorV2?.firstName} />;
  }

  return (
    <div className="conversation-no-appointment">
      <CalendarCheckedIcon className="conversation-no-appointment-picto" />
      {!conversation.currentUserIsInitiator ? (
        <>
          <h4 className="conversation-no-appointment-title">{intl.formatMessage(translations.haveYouAMeeting)}</h4>
          <p className="appointment-confirm-it-description">{intl.formatMessage(translations.confirmIt)}</p>
        </>
      ) : (
        <>
          <h4 className="conversation-no-appointment-title">
            {intl.formatMessage(translations.doYouWantToMeetThePro)}
          </h4>
          <p className="conversation-no-appointment-information">
            {intl.formatMessage(translations.professionalCanRegisterAppointment)}
          </p>
        </>
      )}
    </div>
  );
}

export default NoAppointment;
