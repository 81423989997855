import React, { useContext } from 'react';
import translations from './LastInterlocutorEducationDisplay.translations';
import { ConversationViewContext } from '../../context/ConversationViewContext';

import './LastInterlocutorEducationDisplay.scss';
import { useIntl } from 'react-intl';

function LastInterlocutorEducationDisplay() {
  const intl = useIntl();
  const { conversation } = useContext(ConversationViewContext);
  const interlocutor = conversation.interlocutorV2;

  if (!interlocutor?.acquiredDiplomaLevel?.name) {
    return (
      <p className="last-interlocutor-education-display no-education">{intl.formatMessage(translations.noEducation)}</p>
    );
  }
  return (
    <div className="last-interlocutor-education-display">
      <header className="education-dates">
        {interlocutor?.acquiredDiplomaBeginYear ? (
          <>
            <span className="date">{interlocutor?.acquiredDiplomaBeginYear}</span>-
            <span className="date">{interlocutor?.acquiredDiplomaAcquisitionYear}</span>
          </>
        ) : null}
      </header>
      {interlocutor?.acquiredDiplomaLevel?.name && (
        <p className="education-diploma-type">{interlocutor?.acquiredDiplomaLevel?.name}</p>
      )}
      {interlocutor?.acquiredDiplomaTitle && (
        <p className="education-diploma-title">{interlocutor?.acquiredDiplomaTitle}</p>
      )}
      <p className="education-school">
        {intl.formatMessage(translations.at)}
        <span className="schoolname">{interlocutor?.acquiredDiplomaSchoolName}</span>
      </p>
    </div>
  );
}

export default LastInterlocutorEducationDisplay;
