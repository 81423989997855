import { useEffect, useMemo, useState } from 'react';

export default function useIsTablet() {
  const [width, setWidth] = useState(window.innerWidth);
  const isTablet = useMemo(() => width <= 992, [width]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  return isTablet;
}
