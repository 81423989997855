import { SettingsContext } from '../../../../../contexts/settings-context/SettingsContext';
import React, { useContext } from 'react';
import { SearchFilter } from '../../../../../scenes/search/Search.model';
import { FacetKeyEnum, SearchTypeEnum } from '../../../../../@types/graphql.d';
import FiltersDropdownPanel from '../FiltersDropdownPanel';

type CompanySizesFiltersDropdownPanelProps = {
  filters?: SearchFilter;
  onFilterChange: (filter: SearchFilter) => void;
  onClose: () => void;
};

export default function SecondarySituationsFiltersDropdownPanel({
  filters,
  onFilterChange: onFilterChangeCallback,
  onClose: onCloseCallback,
}: CompanySizesFiltersDropdownPanelProps) {
  const settingsContext = useContext(SettingsContext);

  return (
    <FiltersDropdownPanel
      facetType={FacetKeyEnum.SecondarySituations}
      buckets={(settingsContext?.settings?.secondarySituations || []).map(({ key, name }) => ({
        key: key.toLowerCase(),
        label: name,
      }))}
      searchType={SearchTypeEnum.OnlyStudents}
      filters={filters}
      onFilterChange={onFilterChangeCallback}
      onClose={onCloseCallback}
    />
  );
}
