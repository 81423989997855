import { defineMessages } from 'react-intl';
import { IntlShape } from 'react-intl';

const sampleExamples = defineMessages({
  presentation: {
    id: 'enums.conversationInitiateSamples.sample.presentation',
    defaultMessage:
      "Exemple: Je m'appelle ... j'ai 24 ans et et j'ai étudié à l'université ... avant d'entamer ma carrière avec un poste de ... chez ...",
    description: 'Premiere partie du message par default pour l initiation de conversation',
  },
  explanation: {
    id: 'enums.conversationInitiateSamples.sample.explanation',
    defaultMessage:
      "Exemple: Pendant mes précédentes expériences j'ai pu apprendre davantage à propos de ... J'ai également effectué un stage/CDI/CDD en tant que ... au sein de l'entreprise ... (énumérez vos autres expériences). Je me pose des questions sur le secteur...",
    description: 'Premiere partie du message par default pour l initiation de conversation',
  },
  pitch: {
    id: 'enums.conversationInitiateSamples.sample.pitch',
    defaultMessage:
      "Exemple: J'ai pu découvrir sur votre profil que vous aviez une expertise dans les domaines de ... Mon intérêt s'est notamment porté sur votre poste en tant que ... Vous avez également mentionné vos compétences en termes de ... ce qui a attiré ma curiosité. Je souhaiterais avoir vos retours d'expérience et des conseils pour me lancer à mon tour\n\nSi l'Ambassadeur n'est pas issu.e du domaine duquel tu viens, précise-lui toutefois dans ce cas que tu le contactes dans une logique de découverte.es\nExemple: J'espère vous rencontrer, car je souhaite élargir mes horizons et savoir si le domaine ... pourrait me convenir.",
    description: 'Premiere partie du message par default pour l initiation de conversation',
  },
  valediction: {
    id: 'enums.conversationInitiateSamples.sample.valediction',
    defaultMessage:
      "Exemple: J'espère avoir l'opportunité de vous rencontrer très prochainement. Je reste à votre disposition pour fixer un rendez-vous à l'horaire de votre convenance.\nÀ très vite,",
    description: 'Premiere partie du message par default pour l initiation de conversation',
  },
  presentationHighSchoolStudent: {
    id: 'enums.conversationInitiateSamples.sample.HighSchoolStudent.presentation',
    defaultMessage:
      "Exemple: Je m'appelle ..., j'ai 24 ans et et j'ai étudié à l'université ... avant d'entamer ma carrière avec un poste de ... chez ...",
    description: 'Premiere partie du message par default pour l initiation de conversation',
  },
  explanationHighSchoolStudent: {
    id: 'enums.conversationInitiateSamples.sample.HighSchoolStudent.explanation',
    defaultMessage:
      "Exemple: Pendant mes précédentes expériences j'ai pu apprendre davantage à propos de ... J'ai également effectué un stage/CDI/CDD en tant que ... au sein de l'entreprise ... (énumérez vos autres expériences). Je me pose des questions sur le secteur...",
    description: 'Premiere partie du message par default pour l initiation de conversation',
  },
  pitchHighSchoolStudent: {
    id: 'enums.conversationInitiateSamples.sample.HighSchoolStudent.pitch',
    defaultMessage:
      "Exemple: J'ai pu découvrir sur votre profil que vous aviez une expertise dans les domaines de ... Mon intérêt s'est notamment porté sur votre poste en tant que ... Vous avez également mentionné vos compétences en termes de ... ce qui a attiré ma curiosité. Je souhaiterais avoir vos retours d'expérience et des conseils pour me lancer à mon tour\n\nSi l'Ambassadeur n'est pas issu.e du domaine duquel tu viens, précise-lui toutefois dans ce cas que tu le contactes dans une logique de découverte.es\nExemple: J'espère vous rencontrer, car je souhaite élargir mes horizons et savoir si le domaine ... pourrait me convenir.",
    description: 'Premiere partie du message par default pour l initiation de conversation',
  },
  valedictionHighSchoolStudent: {
    id: 'enums.conversationInitiateSamples.sample.HighSchoolStudent.valediction',
    defaultMessage:
      "Exemple: J'espère avoir l'opportunité de vous rencontrer très prochainement. Je reste à votre disposition pour fixer un rendez-vous à l'horaire de votre convenance.\nÀ très vite,",
    description: 'Premiere partie du message par default pour l initiation de conversation',
  },
  presentationSchoolStudentSupport: {
    id: 'enums.conversationInitiateSamples.sample.SchoolStudentSupport.presentation',
    defaultMessage:
      "Exemple: Bonjour, je m'appelle Mathieu et suis professeur de mathématiques au lycée Emile Dubois à Paris 14e. Je suis aussi professeur principal d'une classe de seconde",
    description: 'Premiere partie du message par default pour l initiation de conversation',
  },
  explanationSchoolStudentSupport: {
    id: 'enums.conversationInitiateSamples.sample.SchoolStudentSupport.explanation',
    defaultMessage:
      "Exemple: Dans le cadre des heures d'orientation, je cherche à présenter des métiers aux élèves de ma classe de seconde et je souhaite privilégier des témoignages de vive voix afin de mieux capter leur attention et surtout de leur permettre d'obtenir des réponses à leurs questions.",
    description: 'Premiere partie du message par default pour l initiation de conversation',
  },
  pitchSchoolStudentSupport: {
    id: 'enums.conversationInitiateSamples.sample.SchoolStudentSupport.pitch',
    defaultMessage:
      "Exemple: Votre parcours académique a particulièrement retenu mon attention, vous vous êtes en effet réorienté au bout d'une première année de fac et exercez aujourd'hui un métier qui n'est pas directement en lien avec votre formation d'ingénieur. Les élèves ont souvent beaucoup de mal à percevoir toutes ces possibilités. Au delà de leur présenter votre métier actuel de gestionnaire d'un restaurant j'aimerais que vous puissiez leur parler de votre parcours et de comment vous avez rebondi et évolué tout au long de votre carrière.",
    description: 'Premiere partie du message par default pour l initiation de conversation',
  },
  valedictionSchoolStudentSupport: {
    id: 'enums.conversationInitiateSamples.sample.SchoolStudentSupport.valediction',
    defaultMessage:
      'Exemple: Ce serait une véritable chance pour nos élèves de pouvoir vous écouter. Nous avons une heure de vie de classe chaque mercredi entre 11h et midi, est ce que cet horaire pourrait vous convenir pour intervenir directement en classe ou à distance ? Par avance, merci !',
    description: 'Premiere partie du message par default pour l initiation de conversation',
  },
  presentationJobSeekerSupport: {
    id: 'enums.conversationInitiateSamples.sample.JobSeekerSupport.presentation',
    defaultMessage:
      "Exemple: Je m'appelle {senderFirstName}, je suis conseiller à l'agence France Travail de Pontivy et j'accompagne des jeunes en contrat d'engagement jeune.",
    description: 'Premiere partie du message par default pour l initiation de conversation',
  },
  explanationJobSeekerSupport: {
    id: 'enums.conversationInitiateSamples.sample.JobSeekerSupport.explanation',
    defaultMessage:
      "Exemple: Afin d'aider les jeunes que j'accompagne à comprendre les codes du monde professionnel, j'organise régulièrement des rencontres collectives en faisant intervenir des professionnels. Ces rencontres ont  pour objectif de leur faire découvrir des métiers qui suscitent régulièrement des vocations chez une partie des participants.",
    description: 'Premiere partie du message par default pour l initiation de conversation',
  },
  pitchJobSeekerSupport: {
    id: 'enums.conversationInitiateSamples.sample.JobSeekerSupport.pitch',
    defaultMessage:
      "Exemple: Je vous contacte car je cherche actuellement à présenter plusieurs métiers de l'artisanat. J'ai récemment eu la chance d'organiser une rencontre avec un artisan peintre auto-entrepreneur et j'aimerais leur faire découvrir à présent un profil de salarié afin de leur expliquer quelles sont les différences entre ces deux statuts. Votre métier de menuisier intéresse aussi tout particulièrement quelques-un des jeunes que j'accompagne et ils seraient ravis de pouvoir vous entendre.",
    description: 'Premiere partie du message par default pour l initiation de conversation',
  },
  valedictionJobSeekerSupport: {
    id: 'enums.conversationInitiateSamples.sample.JobSeekerSupport.valediction',
    defaultMessage:
      "Exemple: Seriez-vous disponible dans les semaines à venir sur un créneau d'une heure maximum et en visioconférence afin de leur partager votre profession ? Je vous remercie par avance.",
    description: 'Premiere partie du message par default pour l initiation de conversation',
  },
});

const sampleQuestions = defineMessages({
  presentation: {
    id: 'enums.conversationInitiateSamples.question.sample.presentation',
    defaultMessage:
      'Présentez vous rapidement, vous pouvez notamment commencer par votre prénom, âge, et votre dernière expérience ou formation.',
  },
  explanation: {
    id: 'enums.conversationInitiateSamples.question.sample.explanation',
    defaultMessage:
      "Apportez des précisions sur votre parcours, vos expériences passées et votre projet professionnel (s'il est défini !)",
  },
  pitch: {
    id: 'enums.conversationInitiateSamples.question.sample.pitch',
    defaultMessage:
      "Précisez les raisons qui vous poussent à contacter l'Ambassadeur et formalisez votre demande de rendez-vous.",
  },
  valediction: {
    id: 'enums.conversationInitiateSamples.question.sample.valediction',
    defaultMessage: 'Terminez par une formule de politesse et proposez un rendez-vous.',
  },
  presentationHighSchoolStudent: {
    id: 'enums.conversationInitiateSamples.question.sample.HighSchoolStudent.presentation',
    defaultMessage:
      'Présentez vous rapidement, vous pouvez notamment commencer par votre prénom, âge, et votre dernière expérience ou formation.',
  },
  explanationHighSchoolStudent: {
    id: 'enums.conversationInitiateSamples.question.sample.HighSchoolStudent.explanation',
    defaultMessage:
      "Apportez des précisions sur votre parcours, vos expériences passées et votre projet professionnel (s'il est défini !)",
  },
  pitchHighSchoolStudent: {
    id: 'enums.conversationInitiateSamples.question.sample.HighSchoolStudent.pitch',
    defaultMessage:
      "Précisez les raisons qui vous poussent à contacter l'Ambassadeur et formalisez votre demande de rendez-vous.",
  },
  valedictionHighSchoolStudent: {
    id: 'enums.conversationInitiateSamples.question.sample.HighSchoolStudent.valediction',
    defaultMessage: 'Terminez par une formule de politesse et proposez un rendez-vous.',
  },
  presentationSchoolStudentSupport: {
    id: 'enums.conversationInitiateSamples.question.sample.SchoolStudentSupport.presentation',
    defaultMessage:
      'Présentez vous rapidement, vous pouvez notamment commencer par votre prénom, votre poste, et l’établissement dans lequel vous travaillez.',
  },
  explanationSchoolStudentSupport: {
    id: 'enums.conversationInitiateSamples.question.sample.SchoolStudentSupport.explanation',
    defaultMessage:
      "Apportez des précisions sur les classes dans lesquelles vous enseignez et sur le projet d'orientation que vous souhaitez mettre en place.",
  },
  pitchSchoolStudentSupport: {
    id: 'enums.conversationInitiateSamples.question.sample.SchoolStudentSupport.pitch',
    defaultMessage:
      "Présentez les raisons qui vous poussent à contacter l'Ambassadeur et formalisez votre demande de rendez-vous.",
  },
  valedictionSchoolStudentSupport: {
    id: 'enums.conversationInitiateSamples.question.sample.SchoolStudentSupport.valediction',
    defaultMessage: 'Terminez par une formule de politesse et proposez un rendez-vous.',
  },
  presentationJobSeekerSupport: {
    id: 'enums.conversationInitiateSamples.question.sample.JobSeekerSupport.presentation',
    defaultMessage:
      "Présentez-vous rapidement. Vous pouvez notamment commencer par votre prénom, votre poste et l'établissement dans lequel vous travaillez.",
  },
  explanationJobSeekerSupport: {
    id: 'enums.conversationInitiateSamples.question.sample.JobSeekerSupport.explanation',
    defaultMessage:
      "Apportez des précisions sur les groupes dans lesquelles vous enseignez et sur le projet d'orientation que vous souhaitez mettre en place.",
  },
  pitchJobSeekerSupport: {
    id: 'enums.conversationInitiateSamples.question.sample.JobSeekerSupport.pitch',
    defaultMessage:
      "Présentez les raisons qui vous poussent à contacter l'Ambassadeur et formalisez votre demande de rendez-vous.",
  },
  valedictionJobSeekerSupport: {
    id: 'enums.conversationInitiateSamples.question.sample.JobSeekerSupport.valediction',
    defaultMessage: 'Terminez par une formule de politesse et proposez un rendez-vous.',
  },
});

/**
 * Return a sample message
 * @param {String} senderFirstName
 * @param {String} recipientFirstname
 */
export const getSampleExamples = (intl: IntlShape, senderFirstName: string, recipientFirstname: string) => {
  const sample = sampleExamples;

  return Object.keys(sample).reduce(
    (acc, item) => ({
      ...acc,
      [item]: intl.formatMessage(sample[item], { senderFirstName, recipientFirstname }),
    }),
    {},
  );
};

/**
 * Return a sample message
 * @param {String} senderFirstName
 * @param {String} recipientFirstname
 */
export const getSampleQuestions = (intl: IntlShape, senderFirstName: string, recipientFirstname: string) => {
  const sample = sampleQuestions;

  return Object.keys(sample).reduce(
    (acc, item) => ({
      ...acc,
      [item]: intl.formatMessage(sample[item], { senderFirstName, recipientFirstname }),
    }),
    {},
  );
};
