import React, { memo, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { SectionCard } from '../../components/section-card/SectionCard';
import { useConnectCompanyContext } from '../../ConnectCompanyContext';
import './GeneralInformations.scss';
import { companySizeTranslations } from '../../../../../../../enums/companySizes';

import { GET_COMPANY_GENERAL_INFORMATIONS_QUERY, UPDATE_COMPANY_LOGO_MUTATION } from './GeneralInformations.gql';
import { useMutation, useQuery } from '@apollo/client';
import {
  CompanySize,
  GetCompanyGeneralInformationsQuery,
  GetCompanyGeneralInformationsQueryVariables,
  UpdateCompanyLogoMutation,
  UpdateCompanyLogoMutationVariables,
} from '../../../../../../../@types/graphql.d';
import UploadLogoButton from '../../../../../../../scenes/common/upload-logo-button/UploadLogoButton';
import { useSnackbar } from 'notistack';

const translations = defineMessages({
  title: {
    id: 'ConnectCompany.GeneralInformations.title',
    defaultMessage: '📄 Informations générales',
  },
  subtitle: {
    id: 'ConnectCompany.GeneralInformations.subtitle',
    defaultMessage:
      'Gérez vous même le logo de votre entreprise. Celui-ci apparaîtra sur les cartes et les profils de vos Ambassadeurs.',
  },
  titleCompanyName: {
    id: 'ConnectCompany.GeneralInformations.titleCompanyName',
    defaultMessage: "Nom de l'entreprise",
  },
  titleCompanySector: {
    id: 'ConnectCompany.GeneralInformations.titleCompanySector',
    defaultMessage: "Secteur d'activité",
  },
  titleCompanySize: {
    id: 'ConnectCompany.GeneralInformations.titleCompanySize',
    defaultMessage: "Taille de l'entreprise",
  },
  valueCompanySector: {
    id: 'ConnectCompany.GeneralInformations.valueCompanySector',
    defaultMessage: ' employés',
  },
  uploadLogoError: {
    id: 'ConnectCompany.GeneralInformations.uploadLogoError',
    defaultMessage: 'Erreur lors de la mise à jour du logo',
  },
  uploadLogoSuccess: {
    id: 'ConnectCompany.GeneralInformations.uploadLogoSuccess',
    defaultMessage: 'Logo mis à jour avec succès',
  },
});

export const GeneralInformations = memo(() => {
  const intl = useIntl();
  const { id } = useConnectCompanyContext();
  const snackbar = useSnackbar();

  const { data } = useQuery<GetCompanyGeneralInformationsQuery, GetCompanyGeneralInformationsQueryVariables>(
    GET_COMPANY_GENERAL_INFORMATIONS_QUERY,
    {
      variables: {
        companyId: id || '',
      },
      fetchPolicy: 'network-only',
    },
  );

  const [uploadLogo] = useMutation<UpdateCompanyLogoMutation, UpdateCompanyLogoMutationVariables>(
    UPDATE_COMPANY_LOGO_MUTATION,
    {
      refetchQueries: [{ query: GET_COMPANY_GENERAL_INFORMATIONS_QUERY, variables: { companyId: id || '' } }],
      onCompleted: () => {
        snackbar.enqueueSnackbar(intl.formatMessage(translations.uploadLogoSuccess), {
          autoHideDuration: 2500,
          variant: 'success',
        });
      },
    },
  );

  const handleLogoChange = useCallback(
    async (uploadId: string) => {
      try {
        await uploadLogo({
          variables: {
            companyId: id || '',
            companyLogoUploadId: uploadId,
          },
        });
      } catch (error) {
        snackbar.enqueueSnackbar(intl.formatMessage(translations.uploadLogoError), { variant: 'error' });
      }
    },
    [id, uploadLogo],
  );

  return (
    <SectionCard
      title={intl.formatMessage(translations.title)}
      subtitle={intl.formatMessage(translations.subtitle)}
    >
      <div className="general-information">
        <div className="general-information__logo-container">
          <div className="general-information__logo-container__logo-wrapper">
            <img
              src={data?.company?.companyLogo || 'https://assets.myjobglasses.com/images/fallback-company.png'}
              className="general-information__logo-container__logo-wrapper__logo"
              alt=""
            />
          </div>
          <UploadLogoButton onChange={handleLogoChange} />
        </div>
        <div className="general-information__infos">
          <div className="general-information__infos__item">
            <h2>{intl.formatMessage(translations.titleCompanyName)}</h2>
            <p>{data?.company?.name}</p>
          </div>
          <div className="general-information__infos__item">
            <h2>{intl.formatMessage(translations.titleCompanySector)}</h2>
            <p>{data?.company?.companySector}</p>
          </div>
          <div className="general-information__infos__item">
            <h2>{intl.formatMessage(translations.titleCompanySize)}</h2>
            <p>
              {' '}
              {data?.company?.companySize
                ? intl.formatMessage(companySizeTranslations[data?.company?.companySize])
                : intl.formatMessage(companySizeTranslations[CompanySize.Unknown])}
            </p>
          </div>
        </div>
      </div>
    </SectionCard>
  );
});
