import { gql } from '@apollo/client';

export const GET_COMPANY_EMPLOYEE_CUSTOM_ATTRIBUTES_DISTRIBUTION = gql`
  query GET_COMPANY_EMPLOYEE_CUSTOM_ATTRIBUTES_DISTRIBUTION($companyId: ID!) {
    company(companyId: $companyId) {
      id

      employeesCustomAttribute1Distribution {
        name
        total
        distribution {
          key
          count
          percentage
        }
      }

      employeesCustomAttribute2Distribution {
        name
        total
        distribution {
          key
          count
          percentage
        }
      }

      employeesCustomAttribute3Distribution {
        name
        total
        distribution {
          key
          count
          percentage
        }
      }
    }
  }
`;
