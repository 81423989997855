import React, { memo, useMemo } from 'react';
import { InputValue } from '../types/BaseInputValue';
import { motion } from 'motion/react';

import './SwitchSelector.scss';
import classNames from 'classnames';

type Props<T> = {
  value: T;
  values: InputValue<T>[];
  onSwitchChange: (value: T) => void;
};

export const SwitchSelector = memo(<T extends string>({ value, values, onSwitchChange }: Props<T>) => {
  const blockWidth = useMemo(() => 100 / values.length, [values]);
  const activeIndex = useMemo(() => values.findIndex((v) => v.value === value), [value, values]);

  return (
    <div className="switch-selector">
      <motion.div
        className="switch-selector__indicator"
        animate={{ left: `${activeIndex * blockWidth}%`, width: `${blockWidth}%` }}
      />

      {values.map((v) => (
        <button
          key={v.value}
          className={classNames('switch-selector__selector', {
            'switch-selector__selector--selected': v.value === value,
          })}
          onClick={() => onSwitchChange(v.value)}
        >
          {v.translation}
        </button>
      ))}
    </div>
  );
});
