import React from 'react';
import { DonutChart } from '@myjobglasses/dsm';
import classnames from 'classnames';
import Help from '../../../../common/help/Help';
import LockIcon from '../../../../../icons/component-icons/LockIcon';

import CompanyAdminDashboardViewTranslations from '../../CompanyAdminDashboardView.translations';
import styles from './CompanyAdminDashboardDonut.module.scss';
import { useIntl } from 'react-intl';

interface CompanyAdminDashboardDonutProps {
  value: number;
  title: string;
  tooltip?: string;
  className?: string;
  blurred?: boolean;
}
function CompanyAdminDashboardDonut({ value, title, tooltip, className, blurred }: CompanyAdminDashboardDonutProps) {
  const intl = useIntl();
  return (
    <div className={classnames(className, styles.companyAdminDashboardDonut)}>
      {blurred ? (
        <div className={styles.lockedDonut}>
          <LockIcon />
          <p>{intl.formatMessage(CompanyAdminDashboardViewTranslations.lockedData)}</p>
        </div>
      ) : (
        <DonutChart
          segments={[
            {
              key: title,
              percentage: value,
              color: '#004680',
            },
          ]}
          className={styles.donut}
          centralText={value ? `${value}%` : '-'}
        />
      )}
      <div className={styles.titleWithTooltip}>
        <span className={styles.title}>{title}</span>
        {tooltip && (
          <Help
            text={tooltip}
            variant="blue-dark"
          />
        )}
      </div>
    </div>
  );
}

export default CompanyAdminDashboardDonut;
