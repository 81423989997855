import React from 'react';

import styles from './TagComponent.module.scss';

interface TagComponentProps {
  value: string;
}
function TagComponent({ value }: TagComponentProps) {
  return <span className={styles.tagComponent}>{value}</span>;
}

export default TagComponent;
