import React from 'react';
import MarkerIcon from '../../../../../../../icons/component-icons/MarkerIcon';
import PhoneIcon from '../../../../../../../icons/component-icons/PhoneIcon';
import VideoIcon from '../../../../../../../icons/component-icons/VideoIcon';
import translations from './AppointmentDisplayType.translations';

import './AppointmentDisplayType.scss';
import { AppointmentTypesEnum } from '../../../../../../../@types/graphql.d';
import { useIntl } from 'react-intl';

interface AppointmentDisplayTypeProps {
  type?: AppointmentTypesEnum;
  meetingPlace?: string | null;
  videoconferenceLink?: string | null;
}

function AppointmentDisplayType({ type, meetingPlace, videoconferenceLink }: AppointmentDisplayTypeProps) {
  const intl = useIntl();
  switch (type) {
    case AppointmentTypesEnum.Physical:
      return (
        <>
          <p className="appointment-display-type appointment-display-type-physical">
            <MarkerIcon className="picto" />
            {intl.formatMessage(translations.appointmentTypePhysical)}
          </p>
          <p className="appointment-display-type-meeting-place">{meetingPlace}</p>
        </>
      );
    case AppointmentTypesEnum.Phone:
      return (
        <p className="appointment-display-type appointment-display-type-phone">
          <PhoneIcon className="picto" />
          {intl.formatMessage(translations.appointmentTypePhone)}
        </p>
      );
    case AppointmentTypesEnum.Video:
      return (
        <p className="appointment-display-type appointment-display-type-video">
          <VideoIcon className="picto" />
          {videoconferenceLink ? (
            <a
              href={videoconferenceLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage(translations.appointmentTypeVideo)}
            </a>
          ) : (
            intl.formatMessage(translations.appointmentTypeVideo)
          )}
        </p>
      );
    default:
      return null;
  }
}

export default AppointmentDisplayType;
