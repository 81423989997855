import * as Yup from 'yup';
import { errorTranslation } from './ProgramJoinModal.translations';
import { IntlShape } from 'react-intl';

export const ProgramJoinModalValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    participationCode: Yup.string()
      .ensure()
      .min(7, intl.formatMessage(errorTranslation.invalidCode))
      .max(7, intl.formatMessage(errorTranslation.invalidCode))
      .required(),
  });
