import React from 'react';

import BuildingIcon from '../../../../../icons/component-icons/BuildingIcon';
import { AmbassadorViewCompanyPresentationTranslations } from './CompanyPresentation.translations';

import Card from '../../../../../components/card/Card';
import { useIntl } from 'react-intl';

type StaticCompanyPresentationProps = {
  companyName: string;
  presentation: string;
};

export default function StaticCompanyPresentation({ companyName, presentation }: StaticCompanyPresentationProps) {
  const intl = useIntl();
  return (
    <section style={{ flex: 2 }}>
      <Card
        icon={BuildingIcon}
        title={intl.formatMessage(AmbassadorViewCompanyPresentationTranslations.title, {
          companyName,
        })}
      >
        <p>{presentation}</p>
      </Card>
    </section>
  );
}
