import { gql } from '@apollo/client';

export const GALLERY_QUERY = gql`
  query AmbassadorGallery($id: ID!) {
    getUser(id: $id) {
      galleryImages {
        imageUrl
      }
    }
  }
`;
