import { gql } from '@apollo/client';

export const GET_CUSTOM_ATTRIBUTES_FIELDS = gql`
  query GET_CUSTOM_ATTRIBUTES_FIELDS($companyId: ID!) {
    company(companyId: $companyId) {
      id
      customAttributesSet {
        customEmployeeAttribute1
        companyAttributeValues1
        customEmployeeAttribute2
        companyAttributeValues2
        customEmployeeAttribute3
        companyAttributeValues3
      }
    }
  }
`;

export const ADD_EMPLOYEE = gql`
  mutation ADD_EMPLOYEE(
    $companyId: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $companyAttribute1: String
    $companyAttribute2: String
    $companyAttribute3: String
  ) {
    inviteEmployee(
      companyId: $companyId
      firstName: $firstName
      lastName: $lastName
      email: $email
      companyAttribute1: $companyAttribute1
      companyAttribute2: $companyAttribute2
      companyAttribute3: $companyAttribute3
    )
  }
`;
