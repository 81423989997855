import { ComponentType } from 'react';
import useIsMobile from './useIsMobile';

type ResponsiveComponentOptions<T> = {
  mobile: ComponentType<T>;
  desktop: ComponentType<T>;
};

export const useResponsiveComponent = <T>({ mobile, desktop }: ResponsiveComponentOptions<T>): ComponentType<T> => {
  const isMobile = useIsMobile();
  return isMobile ? mobile : desktop;
};
