import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import {
  Get_Company_General_InformationsQuery,
  Get_Company_General_InformationsQueryVariables,
} from '../../../../../@types/graphql.d';
import ConfigurationBloc from '../configuration-bloc/ConfigurationBloc';
import CompanyGeneralInformationsRead from './components/company-general-informations-read/CompanyGeneralInformationsRead';
import CompanyGeneralInformationsWrite from './components/company-general-informations-write/CompanyGeneralInformationsWrite';
import { GET_COMPANY_GENERAL_INFORMATIONS } from './CompanyGeneralInformationsHub.gql';
import translations from './CompanyGeneralInformationsHub.translations';
import { useIntl } from 'react-intl';

interface CompanyGeneralInformationsHubProps {
  className?: string;
}
function CompanyGeneralInformationsHub({ className }: CompanyGeneralInformationsHubProps) {
  const intl = useIntl();
  const { companyid } = useParams();
  const { data } = useQuery<Get_Company_General_InformationsQuery, Get_Company_General_InformationsQueryVariables>(
    GET_COMPANY_GENERAL_INFORMATIONS,
    {
      skip: !companyid,
      variables: {
        companyId: companyid || '',
      },
    },
  );

  return (
    <ConfigurationBloc
      title={intl.formatMessage(translations.generalInformationTitle)}
      description={
        data?.company?.isPremiumSchool
          ? intl.formatMessage(translations.generalInformationSchoolDescription)
          : intl.formatMessage(translations.generalInformationDescription)
      }
      className={className}
      readComponent={CompanyGeneralInformationsRead}
      writeComponent={CompanyGeneralInformationsWrite}
      props={{
        company: data?.company || undefined,
      }}
    />
  );
}

export default CompanyGeneralInformationsHub;
