import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { UnpublishMutation, UnpublishMutationVariables } from '../../../../../../../@types/graphql.d';
import translations from './useUnpublishAction.translations';
import { UNPUBLISH } from './useUnpublishAction.gql';
import { useIntl } from 'react-intl';

export default function useUnpublishAction(userId: string) {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  // handle publish employee mutation
  const [unpublish] = useMutation<UnpublishMutation, UnpublishMutationVariables>(UNPUBLISH, {
    refetchQueries: ['GET_ALLOWED_EMPLOYEE_ACTIONS_AND_STATUS'],
  });

  // submit function
  function unpublishEmployees() {
    return unpublish({ variables: { userId } })
      .then(() => {
        enqueueSnackbar(intl.formatMessage(translations.success), {
          variant: 'success',
          autoHideDuration: 10000,
        });
      })
      .catch(() => {
        enqueueSnackbar(intl.formatMessage(translations.oneProfileCannotUnpublished), {
          variant: 'error',
          autoHideDuration: 10000,
        });
      });
  }
  return [unpublishEmployees];
}
