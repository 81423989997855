import React, { useContext, useEffect } from 'react';
import classnames from 'classnames';
import { Field, Formik } from 'formik';
import MessageTemplatesContext from '../../../../../../context/MessageTemplatesContext';
import ErrorableTextField from '../../../../../errorable-text-field/ErrorableTextField';
import SendMessageContext, {
  SendMessageContextInsertTemplateFunctionParams,
} from '../../../../context/SendMessageContext';
import UnsavedMessageTemplateChangesWarning from '../confirm-closing-without-save/UnsavedMessageTemplateChangesWarning';
import translations from './EditTemplateForm.translations';
import { EditTemplateFormValidationSchema } from './EditTemplateForm.validations';

import './EditTemplateForm.scss';
import {
  Create_Message_TemplateMutation,
  Create_Message_TemplateMutationVariables,
  Edit_Message_TemplateMutation,
  Edit_Message_TemplateMutationVariables,
} from '../../../../../../../../../@types/graphql.d';
import { useMutation } from '@apollo/client';
import {
  CREATE_MESSAGE_TEMPLATE,
  EDIT_MESSAGE_TEMPLATE,
  ExtendedMessageTemplate,
  GET_USER_TEMPLATE_LIST,
} from '../../TemplateManager.gql';
import { CurrentUserContext } from '../../../../../../../../../contexts/current-user-context/CurrentUserContext';
import MaximizeIcon from '../../../../../../../../../icons/component-icons/MaximizeIcon';
import MinimizeIcon from '../../../../../../../../../icons/component-icons/MinimizeIcon';
import { useIntl } from 'react-intl';

interface EditTemplateFormProps {
  messageTemplateIdInEdition?: string;
  insertTemplate: (params: SendMessageContextInsertTemplateFunctionParams) => void;
  selectedTemplate?: ExtendedMessageTemplate;
  setEditingItem: (id: string) => void;
}
function EditTemplateForm({
  messageTemplateIdInEdition,
  insertTemplate,
  selectedTemplate,
  setEditingItem,
}: EditTemplateFormProps) {
  const intl = useIntl();
  const { currentUser } = useContext(CurrentUserContext);

  const [editTemplate] = useMutation<Edit_Message_TemplateMutation, Edit_Message_TemplateMutationVariables>(
    EDIT_MESSAGE_TEMPLATE,
    {
      refetchQueries: [
        {
          query: GET_USER_TEMPLATE_LIST,
          variables: { userId: currentUser!.id },
        },
      ],
    },
  );

  const [createNewTemplate] = useMutation<Create_Message_TemplateMutation, Create_Message_TemplateMutationVariables>(
    CREATE_MESSAGE_TEMPLATE,
    {
      refetchQueries: [
        {
          query: GET_USER_TEMPLATE_LIST,
          variables: { userId: currentUser!.id },
        },
      ],
    },
  );

  const { isFullscreenForm, toggleFullscreen, setDynamicTitle } = useContext(MessageTemplatesContext);
  const { setRequestNewTemplate } = useContext(SendMessageContext);

  async function onSubmit(values: { id: string | null; title: string; body: string }): Promise<void> {
    try {
      if (!values.id) {
        const { data } = await createNewTemplate({
          variables: {
            userId: currentUser!.id,
            title: values.title,
            body: values.body,
          },
        });

        if (data?.createMessageTemplate) {
          setEditingItem(data.createMessageTemplate.id || '');
        }
      } else {
        const { data } = await editTemplate({
          variables: {
            id: values.id || '',
            title: values.title,
            body: values.body,
          },
        });

        if (data?.updateMessageTemplate) {
          setEditingItem(data.updateMessageTemplate.id || '');
        }
      }
    } catch (error) {
      console.error('Error updating message template:', error);
    }
  }

  function handleFormUpdate(e: React.ChangeEvent<HTMLFormElement>) {
    if (e.target.name === 'title') {
      if (e.target.value) {
        setDynamicTitle({
          id: selectedTemplate?.id,
          title: e.target.value,
        });
      } else {
        setDynamicTitle(undefined);
      }
    }
  }
  function handleResetForm() {
    setDynamicTitle(undefined);
  }
  function onMobileShowTemplateListButtonClick() {
    setRequestNewTemplate(true);
  }

  useEffect(() => {
    if (!selectedTemplate) {
      handleResetForm();
    }
  }, [selectedTemplate]);

  return (
    <Formik
      initialValues={{
        id: selectedTemplate?.id ?? null,
        title: selectedTemplate?.title ?? '',
        body: selectedTemplate?.body ?? '',
      }}
      onSubmit={onSubmit}
      onReset={handleResetForm}
      validationSchema={EditTemplateFormValidationSchema(intl)}
      enableReinitialize
      validateOnMount
    >
      {({ handleSubmit, isValid, isSubmitting, dirty, values }) => (
        <form
          className={classnames({
            'edit-template-form': true,
          })}
          onSubmit={handleSubmit}
          onChange={handleFormUpdate}
        >
          <div className="title-input-wrapper">
            <Field
              name="title"
              component={ErrorableTextField}
              placeholder={intl.formatMessage(translations.titlePlaceholder)}
              autoComplete="off"
            />
            <button
              type="button"
              className="fullscreen-button"
              onClick={toggleFullscreen}
            >
              {isFullscreenForm ? <MinimizeIcon /> : <MaximizeIcon />}
            </button>
            <button
              type="button"
              className="show-template-list-mobile-button"
              onClick={onMobileShowTemplateListButtonClick}
            ></button>
          </div>
          <Field
            name="body"
            component={ErrorableTextField}
            type="textarea"
            placeholder={intl.formatMessage(translations.descriptionPlaceholder)}
            rows={10}
          />
          <div className="edit-template-actions">
            <button
              type="submit"
              className="btn btn-primary save-button"
              disabled={isSubmitting || !dirty || selectedTemplate?.cannotBeDeleted}
            >
              {intl.formatMessage(translations.saveButton)}
            </button>
            <button
              type="button"
              className="insert-button"
              onClick={() =>
                insertTemplate({
                  id: messageTemplateIdInEdition,
                  text: values.body,
                })
              }
              disabled={!isValid || (!dirty && !selectedTemplate)}
            >
              {intl.formatMessage(translations.insertButton)}
            </button>
          </div>
          <UnsavedMessageTemplateChangesWarning />
        </form>
      )}
    </Formik>
  );
}

export default EditTemplateForm;
