import { defineMessages } from 'react-intl';

export default defineMessages({
  hello: {
    id: 'CompanyAdminDashboardHeader.hello',
    defaultMessage: 'Bonjour {firstName}',
  },
  employeesDataSingular: {
    id: 'CompanyAdminDashboardHeader.employeesDataSingular',
    defaultMessage: '{publishedEmployeesCount} ambassadeur publié',
  },
  employeesDataPlural: {
    id: 'CompanyAdminDashboardHeader.employeesDataPlural',
    defaultMessage: '{publishedEmployeesCount} ambassadeurs publiés',
  },
  dashboard: {
    id: 'CompanyAdminDashboardHeader.dashboard',
    defaultMessage: 'Dashboard',
  },
});
