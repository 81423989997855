import { defineMessages } from 'react-intl';

export default defineMessages({
  appointment: {
    id: 'AccordionMenu.appointment',
    defaultMessage: 'Rendez-vous',
    description: 'titre de la section rendez-vous côté étudiant',
  },
  presentation: {
    id: 'AccordionMenu.presentation',
    defaultMessage: 'Présentation',
    description: 'titre de la section Présentation côté étudiant',
  },
  educations: {
    id: 'AccordionMenu.educations',
    defaultMessage: 'Formation',
    description: 'titre de la section Formation côté étudiant',
  },
  studentProject: {
    id: 'AccordionMenu.studentProject',
    defaultMessage: 'Projet professionnel',
    description: 'titre de la section Projet professionnel côté étudiant',
  },
  feedbacks: {
    id: 'AccordionMenu.feedbacks',
    defaultMessage: 'Avis',
    description: 'titre de la section Avis côté étudiant',
  },
  availability: {
    id: 'AccordionMenu.availability',
    defaultMessage: 'Disponibilité',
    description: 'titre de la section disponibilité côté étudiant',
  },
});
