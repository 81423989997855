import React from 'react';
import { useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';

import { useQuery } from '@apollo/client';

import './MeetingLimit.scss';

import { GetMeetingLimitQuery } from '../../../../../@types/graphql';
import ProgressBar from '../../../../../components/progress-bar/ProgressBar';
import { getAccountUrl } from '../../../../../utils/redirection.util';
import { GET_MEETING_LIMIT_QUERY } from '../../DashboardView.gql';
import { DashboardMode } from '../../DashboardView.types';
import DashboardCard, { DashboardCardLoader } from '../../components/dashboard-card/DashboardCard';
import { MeetingLimitTranslations } from './MeetingLimit.translations';
import CalendarPlusIconAlt from '../../../../../icons/component-icons/CalendarPlusIconAlt';

type MeetingLimitProps = {
  dashboardMode: DashboardMode;
};

export default function MeetingLimit({ dashboardMode }: MeetingLimitProps) {
  const intl = useIntl();
  const { data, loading } = useQuery<GetMeetingLimitQuery>(GET_MEETING_LIMIT_QUERY, {
    fetchPolicy: 'network-only',
  });

  const {
    maxMeetingsAsAmbassadorPerMonth,
    maxContactsPerWeek,
    remainingContactsCount,
    remainingMeetingsAsAmbassadorThisMonth,
  } = data?.meetingLimit || {};

  const isAmbassadorMode = dashboardMode === 'ambassador';

  const remaining = isAmbassadorMode ? (remainingMeetingsAsAmbassadorThisMonth ?? 0) : (remainingContactsCount ?? 0);

  const total = isAmbassadorMode ? (maxMeetingsAsAmbassadorPerMonth ?? 0) : (maxContactsPerWeek ?? 0);

  const percentage = total > 0 ? (remaining / total) * 100 : 0;

  const title = isAmbassadorMode
    ? intl.formatMessage(MeetingLimitTranslations.title)
    : intl.formatMessage(MeetingLimitTranslations.titleMember);

  if (loading) return <MeetingLimitLoader />;

  return (
    <DashboardCard
      icon={CalendarPlusIconAlt}
      title={title}
    >
      <div className="dashbard-meeting-limit">
        {isAmbassadorMode ? (
          <p>{intl.formatMessage(MeetingLimitTranslations.remainingAppointments)}</p>
        ) : (
          <p>
            <strong>{remaining}</strong>/{total} {intl.formatMessage(MeetingLimitTranslations.remaining)}
          </p>
        )}
        <div className="dashbard-meeting-limit__progress">
          <ProgressBar percentage={percentage} />
          {isAmbassadorMode && (
            <span>
              {remaining}/{total}
            </span>
          )}
        </div>
      </div>
      {isAmbassadorMode && (
        <a
          href={getAccountUrl('/profile/max-meetings-per-month')}
          className="dashbard-meeting-limit__link"
        >
          {intl.formatMessage(MeetingLimitTranslations.changeMeetingLimit)}
        </a>
      )}
    </DashboardCard>
  );
}

export const MeetingLimitLoader = () => (
  <DashboardCardLoader>
    <div className="dashbard-meeting-limit">
      <Skeleton width="30%" />
      <div className="dashbard-meeting-limit__progress">
        <Skeleton
          containerClassName="skeleton-container-flex-1"
          height={8}
          borderRadius={8}
        />
      </div>
    </div>
  </DashboardCardLoader>
);
