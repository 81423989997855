import React, { memo, useMemo } from 'react';
import { PanelProps } from './Panel';
import { defineMessages, useIntl } from 'react-intl';
import { Layout } from './components/layout/Layout';
import { useQuery } from '@apollo/client';
import { GetAspirationsValuesQuery, InternshipDuration, InternshipTypeEnum } from '../../../../@types/graphql.d';
import { GET_ASPIRATIONS_VALUES } from '../Review.gql';
import MonthPicker from './components/month-picker/MonthPicker';
import BadgeRadioInput from './components/badge-radio-input/BadgeRadioInput';
import TextInput from '../../../../components/text-input/TextInput';
import SelectInput from '../../../../components/select-input/SelectInput';
import ReviewRadioInput from './components/review-radio-input/ReviewRadioInput';
import { InternshipDurationTranslations } from '../../../../i18n/internship.translations';
import { useReviewContext } from '../ReviewContext';
import useIsMobile from '../../../../hooks/useIsMobile';
import moment from 'moment';

const translations = defineMessages({
  title: {
    id: 'Review.CompanyAspirationPanel.title',
    defaultMessage: 'Dîtes-nous en plus sur ce qui vous intéresse',
  },
  yes: {
    id: 'Review.CompanyAspirationPanel.yes',
    defaultMessage: 'Oui',
  },
  no: {
    id: 'Review.CompanyAspirationPanel.no',
    defaultMessage: 'Non',
  },
});

export const CompanyAspirationPanel: React.FC<PanelProps> = memo(({ appointment }) => {
  const isMobile = useIsMobile();
  const { formatMessage } = useIntl();
  const { memberReview } = appointment;
  const { reviewFormik } = useReviewContext();

  const today = useMemo(() => moment(), []);
  const availabilityValue = useMemo(() => {
    if (typeof reviewFormik.values.availability !== 'boolean') return undefined;
    return reviewFormik.values.availability ? 'y' : 'n';
  }, [reviewFormik.values.availability]);

  const { data } = useQuery<GetAspirationsValuesQuery>(GET_ASPIRATIONS_VALUES);

  const internshipValues = useMemo(
    () =>
      (data?.settings?.internshipTypes || []).map(({ key, name }) => ({
        value: key,
        translation: name,
      })),
    [data?.settings?.internshipTypes],
  );

  const durations = useMemo(
    () =>
      Object.values(InternshipDuration).map((duration) => ({
        value: duration,
        translation: formatMessage(InternshipDurationTranslations[duration]),
      })),
    [InternshipDuration],
  );

  const workingAreas = useMemo(
    () => (appointment.memberReview?.availableWorkingAreas || []).map((area) => ({ value: area, translation: area })),
    [appointment.memberReview?.availableWorkingAreas],
  );

  const administrativeAreas = useMemo(
    () =>
      (data?.settings?.franceLevel1AdministrativeAreas || []).map(({ key, name }) => ({
        value: key,
        translation: name,
      })),
    [data?.settings?.franceLevel1AdministrativeAreas],
  );

  return (
    <Layout
      title={formatMessage(translations.title)}
      style={{ paddingTop: 80 }}
    >
      {memberReview?.jobTypeQuestion && (
        <BadgeRadioInput
          name="job-type"
          label={memberReview.jobTypeQuestion}
          value={reviewFormik.values.jobType}
          values={internshipValues}
          onSelection={(v) => reviewFormik.setFieldValue('jobType', v)}
        />
      )}
      {memberReview?.durationQuestion && !reviewFormik.values.jobType?.every((jt) => jt === InternshipTypeEnum.Cdi) && (
        <SelectInput
          name="duration"
          label={memberReview.durationQuestion}
          value={reviewFormik.values.duration}
          values={durations}
          onChange={(v) => reviewFormik.setFieldValue('duration', v)}
          bigLabel
        />
      )}
      {memberReview?.domainsQuestion && workingAreas.length > 0 && (
        <BadgeRadioInput
          name="domains-question"
          label={memberReview.domainsQuestion}
          value={reviewFormik.values.workingArea}
          values={workingAreas}
          onSelection={(v) => reviewFormik.setFieldValue('workingArea', v)}
        />
      )}
      {memberReview?.startDateQuestion && (
        <MonthPicker
          label={memberReview.startDateQuestion}
          initMonth={moment(reviewFormik.values.startDate).month()}
          initYear={moment(reviewFormik.values.startDate).year()}
          onMonthSelection={(v) => reviewFormik.setFieldValue('startDate', v)}
          minMonth={today.month()}
          minYear={today.year()}
        />
      )}
      {memberReview?.postalCodeQuestion && (
        <TextInput
          name="postalCode"
          label={memberReview.postalCodeQuestion}
          value={reviewFormik.values.postalCode}
          onChange={(v) => reviewFormik.setFieldValue('postalCode', v.target.value)}
          bigLabel
          maxLength={6}
        />
      )}
      {memberReview?.availabilityQuestion && (
        <>
          <p style={{ fontSize: 18, fontWeight: 500, marginBottom: 0 }}>{memberReview.availabilityQuestion}</p>
          <div style={{ display: 'flex', columnGap: 32, rowGap: 16, flexDirection: isMobile ? 'column' : 'row' }}>
            <ReviewRadioInput
              name="availability"
              value={availabilityValue}
              values={[
                { value: 'y', translation: formatMessage(translations.yes) },
                { value: 'n', translation: formatMessage(translations.no) },
              ]}
              onSelection={(v) => {
                reviewFormik.setFieldValue('availability', v === 'y');
              }}
              inline
            />
            {reviewFormik.values.availability && (
              <SelectInput
                name="administrative-area"
                value={reviewFormik.values.administrativeArea}
                values={administrativeAreas}
                onChange={(v) => reviewFormik.setFieldValue('administrativeArea', v)}
              />
            )}
          </div>
        </>
      )}
    </Layout>
  );
});
