import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import generatedIntrospection from './introspection.json';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react'

/**
 * Use email and authenticationToken from credential key in localStorage
 * to add them to every GraphQL request
 * Add the email as a X-User-Email header
 * Add the authenticationToken as a X-User-Token header
 *
 * If the localStorage cannot be used, do nothing
 *
 * Every GraphQL request will execute this code,
 * so no need to do things on signin to handle localStorage update
 */
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      Sentry.captureException(new Error(`[GraphQL error] ${message}`), {
        extra: {
          operationName: operation.operationName,
          locations,
          path,
        },
      });
    });
  }

  if (networkError) {
    Sentry.captureException(new Error(`[Network error] ${networkError}`));
  }
});

const authLink = setContext(() => {
  const language = window.localStorage?.getItem('language') || 'fr';
  const headers: { [key: string]: any } = {
    'X-User-Language': language,
  };
  if (typeof window.localStorage?.getItem === 'function') {
    const credentials = JSON.parse(window.localStorage.getItem('credentials') || '{}');
    const email = credentials?.[0]?.attributes?.email;
    const token = credentials?.[0]?.attributes?.authenticationToken;
    if (email && token) {
      headers['X-User-Email'] = email;
      headers['X-User-Token'] = token;
    }
  }
  return { headers };
});

const opts = {
  uri: `${import.meta.env.REACT_APP_GRAPHQL_ENDPOINT}`,
  credentials: 'include',
};

const BackendApiHTTPLink = new HttpLink({
  uri: `${import.meta.env.REACT_APP_BACKEND_API_URL || ''}/graphql`,
  credentials: 'include',
});

const uploadAndBatchHTTPLink = ApolloLink.split(
  (operation) =>
    operation.variables &&
    Object.values(operation.variables).some(
      (value) => value instanceof File || (Array.isArray(value) && value.some((v) => v instanceof File)),
    ),
  createUploadLink(opts),
  new BatchHttpLink(opts)
);

const client = new ApolloClient({
  connectToDevTools: import.meta.env.NODE_ENV !== 'production',
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === 'backend-api',
    BackendApiHTTPLink,
    ApolloLink.from([authLink, errorLink, uploadAndBatchHTTPLink])
  ),
  cache: new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
  }),
});

export default client;