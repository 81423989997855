import React from 'react';
import { PageInfo } from '../../../@types/graphql.d';
import translations from './GraphqlCursorPagination.translations';

import styles from './GraphqlCursorPagination.module.scss';
import { useIntl } from 'react-intl';

interface GraphqlCursorPaginationProps {
  pageInfo?: Partial<PageInfo>;
  onNextCursor: (params: { before?: string | null; after?: string | null }) => void;
  onPreviousCursor: (params: { before?: string | null; after?: string | null }) => void;
}
function GraphqlCursorPagination({
  pageInfo: { startCursor, endCursor, hasPreviousPage, hasNextPage } = {},
  onNextCursor,
  onPreviousCursor,
}: GraphqlCursorPaginationProps) {
  const intl = useIntl();
  function handlePreviousPage() {
    onPreviousCursor({
      before: startCursor,
      after: undefined,
    });
  }
  function handleNextPage() {
    onNextCursor({
      before: undefined,
      after: endCursor,
    });
  }
  return (
    <div className={styles.graphqlCursorPagination}>
      <button
        type="button"
        disabled={!hasPreviousPage}
        onClick={handlePreviousPage}
        className={styles.previousButton}
      >
        {intl.formatMessage(translations.previousButton)}
      </button>
      <button
        type="button"
        disabled={!hasNextPage}
        onClick={handleNextPage}
        className={styles.nextButton}
      >
        {intl.formatMessage(translations.nextButton)}
      </button>
    </div>
  );
}

export default GraphqlCursorPagination;
