import React, { useContext } from 'react';
import AccordionSection from './components/accordion-section/AccordionSection';
import AppointmentHub from '../../components/appointment-hub/AppointmentHub';
import ProfessionalAvailabilitySwitch from '../../components/professional-availability-switch/ProfessionalAvailabilitySwitch';
import LastInterlocutorEducationDisplay from '../../components/last-interlocutor-education-display/LastInterlocutorEducationDisplay';
import PresentationDisplay from '../../components/presentation-display/PresentationDisplay';
import StudentFeedbacksHub from '../student-feedbacks-hub/StudentFeedbacksHub';
import { ConversationViewContext } from '../../context/ConversationViewContext';
import translations from './AccordionMenu.translations';
import { useIntl } from 'react-intl';

function AccordionMenu() {
  const intl = useIntl();
  const { conversation } = useContext(ConversationViewContext);
  const interlocutor = conversation.interlocutorV2;

  return (
    <>
      <section className="accordion-menu">
        {conversation.currentUserIsInitiator && (
          <AccordionSection
            title={intl.formatMessage(translations.availability)}
            defaultOpen={true}
          >
            <ProfessionalAvailabilitySwitch />
          </AccordionSection>
        )}
        <AccordionSection
          title={intl.formatMessage(translations.appointment)}
          defaultOpen={true}
        >
          <AppointmentHub />
        </AccordionSection>
        <AccordionSection title={intl.formatMessage(translations.presentation)}>
          <PresentationDisplay
            text={
              conversation.currentUserIsInitiator
                ? interlocutor?.ambassadorPresentation
                : interlocutor?.memberPresentation
            }
          />
        </AccordionSection>
        {!conversation.currentUserIsInitiator && (
          <AccordionSection title={intl.formatMessage(translations.educations)}>
            <LastInterlocutorEducationDisplay />
          </AccordionSection>
        )}
        {!conversation.currentUserIsInitiator && (
          <AccordionSection title={intl.formatMessage(translations.feedbacks)}>
            <StudentFeedbacksHub />
          </AccordionSection>
        )}
      </section>
    </>
  );
}

export default AccordionMenu;
