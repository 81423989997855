import React, { useContext } from 'react';
import {
  Delete_Message_TemplateMutation,
  Delete_Message_TemplateMutationVariables,
} from '../../../../../../../../../../../@types/graphql.d';
import translations from './TemplateListItemConfirmDeletion.translations';
import { useMutation } from '@apollo/client';
import {
  DELETE_MESSAGE_TEMPLATE,
  ExtendedMessageTemplate,
  GET_USER_TEMPLATE_LIST,
} from '../../../../TemplateManager.gql';
import { CurrentUserContext } from '../../../../../../../../../../../contexts/current-user-context/CurrentUserContext';
import { useSnackbar } from 'notistack';
import commonErrorTranslations from '../../../../../../../../../../../scenes/common/common-translations/errors.translations';
import { useIntl } from 'react-intl';

interface TemplateListItemConfirmDeletionProps {
  template: ExtendedMessageTemplate;
  cancel: () => void;
}
function TemplateListItemConfirmDeletion({ template, cancel }: TemplateListItemConfirmDeletionProps) {
  const intl = useIntl();
  const { currentUser } = useContext(CurrentUserContext);
  const { enqueueSnackbar } = useSnackbar();

  const [destroyTemplate] = useMutation<Delete_Message_TemplateMutation, Delete_Message_TemplateMutationVariables>(
    DELETE_MESSAGE_TEMPLATE,
    {
      variables: {
        id: template.id || '',
      },
      refetchQueries: [
        {
          query: GET_USER_TEMPLATE_LIST,
          variables: { userId: currentUser!.id },
        },
      ],
    },
  );

  const handleDeleteClick = async () => {
    try {
      await destroyTemplate();
    } catch (error) {
      enqueueSnackbar(intl.formatMessage(commonErrorTranslations.unknown), {
        autoHideDuration: 5000,
        variant: 'error',
      });
    }
  };

  return (
    <div className="template-list-item-waiting-for-deletion-confirmation">
      <p>{intl.formatMessage(translations.label)}</p>

      <div className="deletion-actions">
        <button
          type="button"
          className="confirm-deletion-button"
          onClick={handleDeleteClick}
        >
          {intl.formatMessage(translations.confirmButton)}
        </button>
        <button
          type="button"
          className="cancel-deletion-button"
          onClick={cancel}
        >
          {intl.formatMessage(translations.cancelButton)}
        </button>
      </div>
    </div>
  );
}

export default TemplateListItemConfirmDeletion;
