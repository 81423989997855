import React from 'react';
import { useParams } from 'react-router';
import { ProfessionalViewParams } from '../../@types/routes-params';
import PageLoader from '../common/page-loader/PageLoader';
import AmbassadorView from './ambassador-view/AmbassadorView';

const PublicProfessionalView = () => {
  const { id } = useParams<ProfessionalViewParams>();

  if (!id || id.length === 0) {
    return (
      <>
        <PageLoader />
      </>
    );
  }

  return (
    <>
      <AmbassadorView professionalId={id} />
    </>
  );
};

export default PublicProfessionalView;
