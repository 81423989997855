import PrimaryButton from '../../../../../../../components/primary-button/PrimaryButton';
import React, { memo, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { SectionCard } from '../../components/section-card/SectionCard';
import QRCode from 'qrcode';
import TextInput from '../../../../../../../components/text-input/TextInput';

import './EmployeesSignup.scss';
import CopyIcon from '../../../../../../../icons/component-icons/CopyIcon';
import CheckIcon from '../../../../../../../icons/component-icons/CheckIcon';
import classNames from 'classnames';
import { useQuery } from '@apollo/client';
import {
  GetCompanyRegistrationTokenQuery,
  GetCompanyRegistrationTokenQueryVariables,
} from '../../../../../../../@types/graphql.d';
import { useConnectCompanyContext } from '../../ConnectCompanyContext';
import { GET_COMPANY_REGISTRATION_TOKEN_QUERY } from './EmployeeSignup.gql';
import { getInvitationLink } from '../../../../../../../utils/redirection.util';
import ahoy from 'ahoy.js';

const translations = defineMessages({
  title: {
    id: 'ConnectCompany.EmployeesSignup.title',
    defaultMessage: '🔗 Lien et QR code d’inscription',
  },
  subtitle: {
    id: 'ConnectCompany.EmployeesSignup.subtitle',
    defaultMessage:
      'Ce lien et ce QR code permettront à vos collaborateurs de s’inscrire simplement sous les couleurs de votre entreprise.',
  },
  separator: {
    id: 'ConnectCompany.EmployeesSignup.separator',
    defaultMessage: 'ou',
  },
});

const qrCodeSectionTranslations = defineMessages({
  title: {
    id: 'ConnectCompany.EmployeesSignup.QRCodeSection.title',
    defaultMessage: 'QR code d’inscription',
  },
  subtitle: {
    id: 'ConnectCompany.EmployeesSignup.QRCodeSection.subtitle',
    defaultMessage: 'Téléchargez et partagez ce QR code',
  },
  download: {
    id: 'ConnectCompany.EmployeesSignup.QRCodeSection.download',
    defaultMessage: 'Télécharger',
  },
});

const linkSectionTranslations = defineMessages({
  title: {
    id: 'ConnectCompany.EmployeesSignup.LinkSection.title',
    defaultMessage: 'Lien d’inscription',
  },
  subtitle: {
    id: 'ConnectCompany.EmployeesSignup.LinkSection.subtitle',
    defaultMessage: 'Partagez le lien ci-dessous à vos collaborateurs',
  },
});

type SubSectionProps = PropsWithChildren<{
  title: string;
  subtitle: string;
  className?: string;
}>;

const SubSection = memo(({ title, subtitle, className, children }: SubSectionProps) => (
  <section className="employees-signup__sub-section">
    <header>
      <h4>{title}</h4>
      <p>{subtitle}</p>
    </header>

    <main className={className}>{children}</main>
  </section>
));

type SectionWithLinkProps = { link: string };

const SignupByQRCode = memo(({ link }: SectionWithLinkProps) => {
  const intl = useIntl();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { id } = useConnectCompanyContext();

  useEffect(() => {
    if (!canvasRef.current) return;
    QRCode.toCanvas(canvasRef.current, link, {
      width: 128,
    });
  }, [link, canvasRef]);

  const downloadQRCode = useCallback(async () => {
    const qrcode = await QRCode.toDataURL(link, {
      width: 512,
      errorCorrectionLevel: 'H',
    });

    const el = document.createElement('a');
    el.href = qrcode;
    el.download = `qrcode-myjobglasses-invitation-employee.png`;
    el.click();
    el.remove();
    ahoy.track('b2b_connect_downloaded_ambassador_signup_qrcode', {companyId: id});
  }, [link]);

  return (
    <SubSection
      title={intl.formatMessage(qrCodeSectionTranslations.title)}
      subtitle={intl.formatMessage(qrCodeSectionTranslations.subtitle)}
      className="employees-signup-qrcode-section"
    >
      <PrimaryButton
        label={intl.formatMessage(qrCodeSectionTranslations.download)}
        onClick={downloadQRCode}
        smaller
      />
      <canvas ref={canvasRef} />
    </SubSection>
  );
});

const SignupByLink = memo(({ link }: SectionWithLinkProps) => {
  const intl = useIntl();
  const [copied, setCopied] = useState(false);
  const { id } = useConnectCompanyContext();

  const copyToClipboard = useCallback(() => {
    if (copied) return;
    navigator.clipboard.writeText(link);
    setCopied(true);
    ahoy.track('b2b_connect_copied_ambassador_signup_link', {companyId: id});
    setTimeout(() => setCopied(false), 2500);
  }, [link, copied]);

  return (
    <SubSection
      title={intl.formatMessage(linkSectionTranslations.title)}
      subtitle={intl.formatMessage(linkSectionTranslations.subtitle)}
      className="employees-signup-link-section"
    >
      <TextInput
        className="employees-signup-link-section__link"
        value={link}
        disabled
      />
      <button
        className={classNames('employees-signup-link-section__copy-button', {
          'employees-signup-link-section__copy-button--copied': copied,
        })}
        onClick={copyToClipboard}
      >
        {copied ? <CheckIcon /> : <CopyIcon />}
      </button>
    </SubSection>
  );
});

export const EmployeesSignup = memo(() => {
  const intl = useIntl();
  // TODO pretending that the invitation link will look like that.
  const { id } = useConnectCompanyContext();
  const { data } = useQuery<GetCompanyRegistrationTokenQuery, GetCompanyRegistrationTokenQueryVariables>(
    GET_COMPANY_REGISTRATION_TOKEN_QUERY,
    {
      variables: {
        companyId: id,
      },
      skip: !id,
      fetchPolicy: 'network-only',
    },
  );

  const invitationLink = getInvitationLink(data?.company?.registrationToken ?? '');

  return (
    <SectionCard
      title={intl.formatMessage(translations.title)}
      subtitle={intl.formatMessage(translations.subtitle)}
    >
      <SignupByQRCode link={invitationLink} />
      <div className="employees-signup__separator">{intl.formatMessage(translations.separator)}</div>
      <SignupByLink link={invitationLink} />
    </SectionCard>
  );
});
