import { defineMessages } from 'react-intl';

export default defineMessages({
  success: {
    id: 'employeeShow.useUnpublishAction.success',
    defaultMessage: '1 profil dépublié',
    description: 'success à la depublication',
  },
  oneProfileCannotUnpublished: {
    id: 'employeeShow.useUnpublishAction.oneProfileCannotUnpublished',
    defaultMessage: "Désolé, le profil n'a pas pu être dépublié",
    description: 'Un profil n a pas pu être dépublié',
  },
});
