import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'CompanyAdminAppointmentSidebar.title',
    defaultMessage: 'Détails du RDV',
  },
  close: {
    id: 'CompanyAdminAppointmentSidebar.close',
    defaultMessage: 'Fermer',
  },
  previousAppointment: {
    id: 'CompanyAdminAppointmentSidebar.previousAppointment',
    defaultMessage: 'Voir les détails du RDV précédent',
  },
  nextAppointment: {
    id: 'CompanyAdminAppointmentSidebar.nextAppointment',
    defaultMessage: 'Voir les détails du RDV suivant',
  },
});
