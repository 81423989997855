import { defineMessages } from 'react-intl';
import { StudentProfessionalProjectType } from '../../../@types/graphql.d';

export default defineMessages({
  [StudentProfessionalProjectType.Apprenticeship]: {
    id: 'CommonTranslations.internshipsTypes.apprenticeship',
    defaultMessage: 'Apprentissage',
  },
  [StudentProfessionalProjectType.Cdd]: {
    id: 'CommonTranslations.internshipsTypes.cdd',
    defaultMessage: 'CDD',
  },
  [StudentProfessionalProjectType.Cdi]: {
    id: 'CommonTranslations.internshipsTypes.cdi',
    defaultMessage: 'CDI',
  },
  [StudentProfessionalProjectType.Internship]: {
    id: 'CommonTranslations.internshipsTypes.internship',
    defaultMessage: 'Stage',
  },
  [StudentProfessionalProjectType.Via]: {
    id: 'CommonTranslations.internshipsTypes.via',
    defaultMessage: 'VIA',
  },
  [StudentProfessionalProjectType.Vie]: {
    id: 'CommonTranslations.internshipsTypes.vie',
    defaultMessage: 'VIE',
  },
});
