import useModal from '../../../../../../../components/modal/Modal.hook';
import React, { memo, useCallback, useRef } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import StaticAmbassadorCard from '../../../../../../../scenes/search/ambassador-card/StaticAmbassadorCard';
import { AssetBlock } from '../../components/asset-block/AssetBlock';
import { Callout } from '../../components/callout/Callout';
import { SectionCard } from '../../components/section-card/SectionCard';
import { useMutation, useQuery } from '@apollo/client';
import { COMPANY_STYLING_QUERY, REMOVE_COMPANY_BANNER_MUTATION, UPDATE_COMPANY_BANNERS_MUTATION } from './CompanyStyling.gql';
import { CompanyStylingQuery, CompanyStylingQueryVariables, RemoveCompanyBannerMutation, RemoveCompanyBannerMutationVariables, UpdateCompanyBannersMutation, UpdateCompanyBannersMutationVariables, UploadLinkKindEnum } from '../../../../../../../@types/graphql.d';
import { useConnectCompanyContext } from '../../ConnectCompanyContext';
import StaticIdentity from '../../../../../../../scenes/public/ambassador-view/sections/identity/StaticIdentity';
import StaticContact from '../../../../../../../scenes/public/ambassador-view/sections/contact/StaticContact';
import { useRestrictedPackModal } from '../../components/restricted-pack-modal/RestrictedPackModal.hook';
import FileUploader from '../../../../../../../scenes/common/file-uploader/FileUploader';
import { useSnackbar } from 'notistack';
import DeleteBannerModal from '../../components/delete-banner-modal/DeleteBannerModal';

import './CompanyStyling.scss';

const translations = defineMessages({
  title: {
    id: 'ConnectCompany.CompanyStyling.title',
    defaultMessage:
      '🎨 Personnalisation des cartes de la recherche et des bandeaux sur les profils de vos Ambassadeurs',
  },
  subtitle: {
    id: 'ConnectCompany.CompanyStyling.subtitle',
    defaultMessage:
      'Personnalisez les cartes de la recherche et les profils de vos ambassadeurs avec vos propres visuels.',
  },
  instructionsTitle: {
    id: 'ConnectCompany.CompanyStyling.instructionsTitle',
    defaultMessage: '⚠️ Instructions',
  },
  instructionsDesc: {
    id: 'ConnectCompany.CompanyStyling.instructionsDesc',
    defaultMessage:
      '<span>Afin de bénéficier d\'un affichage optimal de vos visuels, <strong>veuillez respecter la taille et le type de fichier attendu (PNG ou JPEG)</strong>.{br} Pour les bannières de profil vous devez importer les 2 formats d’image pour que la modification soit prise en compte.</span>',
  },
  defaultImageTitle: {
    id: 'ConnectCompany.CompanyStyling.defaultImageTitle',
    defaultMessage: '🖼️ Images par défaut',
  },
  defaultImageDesc: {
    id: 'ConnectCompany.CompanyStyling.defaultImageDesc',
    defaultMessage:
      'Si aucune image n’est téléchargée, un visuel de couleur or s’affichera à la place sur les différents éléments.',
  },
});

const assetsTranslations = defineMessages({
  ambassadorCardsTitle: {
    id: 'ConnectCompany.CompanyStyling.ambassadorCardsTitle',
    defaultMessage: 'Cartes de vos Ambassadeurs dans le moteur de recherche',
  },
  ambassadorCardsFormat: {
    id: 'ConnectCompany.CompanyStyling.ambassadorCardsFormat',
    defaultMessage: 'Format JPEG ou PNG Taille : 264x132 px',
  },
  desktopBannerTitle: {
    id: 'ConnectCompany.CompanyStyling.desktopBannerTitle',
    defaultMessage: 'Bannière de profil de vos Ambassadeurs - Vue desktop (grand écran)',
  },
  desktopBannerFormat: {
    id: 'ConnectCompany.CompanyStyling.desktopBannerFormat',
    defaultMessage: 'Format JPEG ou PNG Taille : 1536x328 px',
  },
  mobileBannerTitle: {
    id: 'ConnectCompany.CompanyStyling.mobileBannerTitle',
    defaultMessage: 'Bannière de profil de vos Ambassadeurs - Vue mobile',
  },
  mobileBannerFormat: {
    id: 'ConnectCompany.CompanyStyling.mobileBannerFormat',
    defaultMessage: 'Format JPEG ou PNG Taille : 764x328 px',
  },
  importImage: {
    id: 'ConnectCompany.CompanyStyling.importImage',
    defaultMessage: 'Importer une image',
  },
  preview: {
    id: 'ConnectCompany.CompanyStyling.preview',
    defaultMessage: 'Prévisualiser',
  },
  importedImage: {
    id: 'ConnectCompany.CompanyStyling.importedImage',
    defaultMessage: '✅ Image importée',
  },
  notImportedImage: {
    id: 'ConnectCompany.CompanyStyling.notImportedImage',
    defaultMessage: '❌ Aucune image importée',
  },
  successUpload: {
    id: 'ConnectCompany.CompanyStyling.successUpload',
    defaultMessage: 'Votre image a été modifiée avec succès.',
  },
  successDelete: {
    id: 'ConnectCompany.CompanyStyling.successDelete',
    defaultMessage: 'Votre image a été supprimée avec succès.',
  },
  deleteImage: {
    id: 'ConnectCompany.CompanyStyling.deleteImage',
    defaultMessage: 'Supprimer l’image',
  },
});

export const CompanyStyling = memo(() => {
  const intl = useIntl();
  const { id } = useConnectCompanyContext();
  const [openModal, closeModal] = useModal();
  const openRestrictedPackageModal = useRestrictedPackModal();
  const snackbar = useSnackbar();

  const { data } = useQuery<CompanyStylingQuery, CompanyStylingQueryVariables>(COMPANY_STYLING_QUERY, {
    variables: { id },
  });

  const [uploadBanners] = useMutation<UpdateCompanyBannersMutation, UpdateCompanyBannersMutationVariables>(
    UPDATE_COMPANY_BANNERS_MUTATION, {
      refetchQueries: [{ query: COMPANY_STYLING_QUERY, variables: { id } }],
      onCompleted: () => {
        snackbar.enqueueSnackbar(intl.formatMessage(assetsTranslations.successUpload), {
          autoHideDuration: 2500,
          variant: 'success',
        });
      },
    },
  );

  const [removeBanners] = useMutation<RemoveCompanyBannerMutation, RemoveCompanyBannerMutationVariables>(
    REMOVE_COMPANY_BANNER_MUTATION, {
      refetchQueries: [{ query: COMPANY_STYLING_QUERY, variables: { id } }],
      onCompleted: () => {
        snackbar.enqueueSnackbar(intl.formatMessage(assetsTranslations.successDelete), {
          autoHideDuration: 2500,
          variant: 'success',
        });
      },
    },
  );

  const canCustomBanners = data?.company?.canCustomBanners;

  const openAmbassadorCardPreview = useCallback(() => {
    if (!data?.company) return;

    openModal({
      title: intl.formatMessage(assetsTranslations.ambassadorCardsTitle),
      options: {
        centeredContent: true,
        greyBackground: true,
        accentHeader: true,
      },
      content: (
        <StaticAmbassadorCard
          companyLogoUrl={data.company.companyLogo}
          companyName={data.company.name}
          bannerUrl={data.company.cardBanner?.url}
        />
      ),
    });
  }, [data?.company]);

  const openDesktopBannerPreview = useCallback(() => {
    if (!data?.company) return;

    openModal({
      title: intl.formatMessage(assetsTranslations.desktopBannerTitle),
      options: {
        centeredContent: true,
        greyBackground: true,
        accentHeader: true,
        maxWidth: 1200,
      },
      content: (
        <div style={{ width: '100%', display: 'flex', columnGap: 16, stroke: '#333', strokeWidth: 2 }}>
          <StaticIdentity
            companyLogoUrl={data.company.companyLogo}
            companyName={data.company.name}
            desktopBannerUrl={data.company.desktopBanner?.url}
          />
          <StaticContact />
        </div>
      ),
    });
  }, [data?.company]);

  const openMobileBannerPreview = useCallback(() => {
    if (!data?.company) return;

    openModal({
      title: intl.formatMessage(assetsTranslations.mobileBannerTitle),
      options: {
        centeredContent: true,
        greyBackground: true,
        accentHeader: true,
      },
      content: (
        <div style={{ maxWidth: 375, stroke: '#333', strokeWidth: 2 }}>
          <StaticIdentity
            companyLogoUrl={data.company.companyLogo}
            companyName={data.company.name}
            mobileBannerUrl={data.company.mobileBanner?.url}
            isMobile
          />
        </div>
      ),
    });
  }, [data?.company]);

  const openDeleteImageModal = useCallback((bannerType: 'card' | 'desktop' | 'mobile') => {
    openModal({
      title: intl.formatMessage(assetsTranslations.deleteImage),
      content: (
       <DeleteBannerModal 
        onConfirm={() => {
          removeBanners({
            variables: {
              companyId: id || '',
              bannerType,
            },
          });
          closeModal();
        }}
        />
      ),
    });
  }, [id]);

  const mobileBannerRef = useRef<HTMLInputElement>(null);
  const desktopBannerRef = useRef<HTMLInputElement>(null);
  const cardBannerRef = useRef<HTMLInputElement>(null);

  function onUploadClick(ref: React.RefObject<HTMLInputElement>) {
    ref.current?.click();
  }

  return (
    <SectionCard
      title={intl.formatMessage(translations.title)}
      subtitle={intl.formatMessage(translations.subtitle)}
      className="company-styling"
    >
      <div className="company-styling__callouts">
        <Callout
          title={intl.formatMessage(translations.instructionsTitle)}
          variant="warning"
        >
        <FormattedMessage  
            {...translations.instructionsDesc}
            values={{
                span: (chunks) => <span className="highlight">{chunks}</span>,
                strong: (chunks) => <strong>{chunks}</strong>,
                br: <br />
            }}      
          />
        </Callout>

        <Callout title={intl.formatMessage(translations.defaultImageTitle)}>{intl.formatMessage(translations.defaultImageDesc)}</Callout>
      </div>

      <div className="company-styling__assets">
        <AssetBlock
          title={intl.formatMessage(assetsTranslations.ambassadorCardsTitle)}
          primaryAction={{
            label: intl.formatMessage(assetsTranslations.importImage),
            onClick: canCustomBanners ? () => onUploadClick(cardBannerRef) : openRestrictedPackageModal,
            children: (
              <FileUploader
                kind={UploadLinkKindEnum.CardBannerUpload}
                inputRef={cardBannerRef}
                onUploadSuccess={(uploadId) =>
                  {
                    uploadBanners({
                      variables: {
                        companyId: id || '',
                        backgroundCardUploadId: uploadId,
                      },
                    })
                  }

                }
              />
            ),
          }}
          secondaryAction={{ label: intl.formatMessage(assetsTranslations.preview), onClick: openAmbassadorCardPreview }}
          accent
        >
          <div className="company-styling__assets__format">{intl.formatMessage(assetsTranslations.ambassadorCardsFormat)}</div>
          {data?.company?.cardBanner?.url ? (
            <div style={{ display: 'flex', gap: 26}}>
              <p>{intl.formatMessage(assetsTranslations.importedImage)}</p>
              <a onClick={() => openDeleteImageModal('card')} className="delete-link">
                {intl.formatMessage(assetsTranslations.deleteImage)}
              </a>
            </div>
          ) : (
            <p>{intl.formatMessage(assetsTranslations.notImportedImage)}</p>
          )}
        </AssetBlock>

        <AssetBlock
          title={intl.formatMessage(assetsTranslations.desktopBannerTitle)}
          primaryAction={{
            label: intl.formatMessage(assetsTranslations.importImage),
            onClick: canCustomBanners ? () => onUploadClick(desktopBannerRef) : openRestrictedPackageModal,
            children: (
              <FileUploader
                kind={UploadLinkKindEnum.CompanyDesktopBanner}
                inputRef={desktopBannerRef}
                onUploadSuccess={(uploadId) => {
                  uploadBanners({
                    variables: {
                      companyId: id || '',
                      profileBannerDesktopUploadId: uploadId,
                    },
                  })
                }
                }
              />
            ),
          }}
          secondaryAction={{ label: intl.formatMessage(assetsTranslations.preview), onClick: openDesktopBannerPreview }}
          accent
        >
          <div className="company-styling__assets__format">{intl.formatMessage(assetsTranslations.desktopBannerFormat)}</div>
          {data?.company?.desktopBanner?.url ? (
            <div style={{ display: 'flex', gap: 26}}>
              <p>{intl.formatMessage(assetsTranslations.importedImage)}</p>
              <a onClick={() => openDeleteImageModal('desktop')} className="delete-link">
                {intl.formatMessage(assetsTranslations.deleteImage)}
              </a>
            </div>
          ) : (
            <p>{intl.formatMessage(assetsTranslations.notImportedImage)}</p>
          )}
        </AssetBlock>

        <AssetBlock
          title={intl.formatMessage(assetsTranslations.mobileBannerTitle)}
          primaryAction={{
            label: intl.formatMessage(assetsTranslations.importImage),
            onClick: canCustomBanners ? () => onUploadClick(mobileBannerRef) : openRestrictedPackageModal,
            children: (
              <FileUploader
                kind={UploadLinkKindEnum.CompanyMobileBanner}
                inputRef={mobileBannerRef}
                onUploadSuccess={(uploadId) =>
                  {
                    uploadBanners({
                      variables: {
                        companyId: id || '',
                        profileBannerMobileUploadId: uploadId,
                      },
                    })
                  }
                }
              />
            ),
          }}
          secondaryAction={{ label: intl.formatMessage(assetsTranslations.preview), onClick: openMobileBannerPreview }}
          accent
        >
          <div className="company-styling__assets__format">{intl.formatMessage(assetsTranslations.mobileBannerFormat)}</div>
          {data?.company?.mobileBanner?.url ? (
            <div style={{ display: 'flex', gap: 26}}>
              <p>{intl.formatMessage(assetsTranslations.importedImage)}</p>
              <a onClick={() => openDeleteImageModal('mobile')} className="delete-link">
                {intl.formatMessage(assetsTranslations.deleteImage)}
              </a>
            </div>
          ) : (
            <p>{intl.formatMessage(assetsTranslations.notImportedImage)}</p>
          )}
        </AssetBlock>
      </div>
    </SectionCard>
  );
});