import React, { PropsWithChildren } from 'react';
import { generatePath, matchPath, useLocation, useNavigate, Location } from 'react-router';
import Routes from '../../routes';
import { GET_FIRST_APPOINTMENT_TO_FORCE_REVIEW_QUERY } from './AppointmentsToReview.gql';
import { useQuery } from '@apollo/client';
import Loader from '../../components/loader/Loader';
import { CompanyAdminRoutes, MJGAdminRoutes } from '../../routes';
import {
  GetFirstAppointmentClaimToForceReviewQuery,
  GetFirstAppointmentToForceReviewQuery,
} from '../../@types/graphql.d';
import { GET_FIRST_APPOINTMENT_CLAIM_TO_FORCE_REVIEW_QUERY } from './AppointmentClaimsToReview.gql';

const ForceAppointmentReview = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { data, loading: appoinmentsToReviewLoading } = useQuery<GetFirstAppointmentToForceReviewQuery>(
    GET_FIRST_APPOINTMENT_TO_FORCE_REVIEW_QUERY,
  );
  const { data: claimsToReviewQuery, loading: appoinmentClaimsToReviewLoading } =
    useQuery<GetFirstAppointmentClaimToForceReviewQuery>(GET_FIRST_APPOINTMENT_CLAIM_TO_FORCE_REVIEW_QUERY);

  const matchReviewAppointmentPath = matchPath({ path: Routes.review, end: true }, location.pathname);
  const matchReviewAppointmentClaimPath = matchPath({ path: Routes.claimReview, end: true }, location.pathname);

  if (matchReviewAppointmentPath || matchReviewAppointmentClaimPath || shouldNotForceAppointment(location)) {
    return <div>{children}</div>;
  }

  const appointmentToReview = data?.appointments?.nodes?.[0];
  const appointmentClaimToReview = claimsToReviewQuery?.appointmentClaims?.nodes?.[0];
  if (appoinmentClaimsToReviewLoading || appoinmentsToReviewLoading) {
    return <Loader />;
  }

  if (appointmentToReview) {
    navigate(generatePath(Routes.review, { appointmentId: appointmentToReview.id }), { state: { upcomingReviewCount: data?.appointments?.total || 1 }});
  }

  if (appointmentClaimToReview) {
    navigate(generatePath(Routes.claimReview, { claimId: appointmentClaimToReview.id }));
  }

  return <div>{children}</div>;
};

export default ForceAppointmentReview;

const EXCLUDED_ROUTES = [Routes.settings, ...Object.values(CompanyAdminRoutes), ...Object.values(MJGAdminRoutes)];

const shouldNotForceAppointment = (location: Location<any>) => {
  return EXCLUDED_ROUTES.some((route) => matchPath({ path: route, end: true }, location.pathname));
}