import React from 'react';
import classnames from 'classnames';
import { CompanyReportTopEmployee } from '../../../../../../../@types/graphql.d';
import PublicProfileAvatarDisplay from '../../../../../../common/public-profile-avatar-display/PublicProfileAvatarDisplay';
import translations from './TopEmployeeItem.translations';

import styles from './TopEmployeeItem.module.scss';
import { useIntl } from 'react-intl';

interface TopEmployeeItemProps {
  profile?: CompanyReportTopEmployee;
  skeleton?: boolean;
  placeholder?: boolean;
  index?: number;
  blurred?: boolean;
}
function TopEmployeeItem({ profile, skeleton, placeholder, index, blurred }: TopEmployeeItemProps) {
  const intl = useIntl();

  const imageUrls = [
    'https://upload.wikimedia.org/wikipedia/commons/e/e6/Timothee_Chalamet_-_2024_%2853_of_65%29_-_54213772308_%28cropped%29.jpg',
    'https://upload.wikimedia.org/wikipedia/commons/2/28/Zendaya_-_2019_by_Glenn_Francis.jpg',
    'https://upload.wikimedia.org/wikipedia/commons/3/3c/Tom_Holland_by_Gage_Skidmore.jpg',
  ];

  
  return (
    <div
      className={classnames(styles.item, {
        [styles.skeleton]: skeleton,
        [styles.placeholder]: placeholder,
      })}
    >
      {blurred ? (
        <div
          className={styles.avatarBlurred}
          style={{
            backgroundImage: `url(${imageUrls[index]})`,
          }}
        />
      ) : (
        <PublicProfileAvatarDisplay
          publicProfile={profile}
          className={styles.avatar}
        />
      )}
      <div className={styles.informations}>
        {profile || skeleton ? (
          <>
            <p className={styles.fullname}>{`${profile?.firstName || ''} ${profile?.lastName || ''}`}</p>
            <p className={styles.jobTitle}>{profile?.jobTitle}</p>
          </>
        ) : (
          <p className={classnames(styles.employeePlaceholder, { blurred })}>
            {intl.formatMessage(translations.employeePlaceholder, { index: (index || 0) + 1 })}
          </p>
        )}
      </div>
      <div className={styles.medal}>
        {profile?.medal && profile.medal >= 1 && profile.medal <= 3 ? (
          <img
            src={`https://assets.myjobglasses.com/images/medals/medal${profile?.medal}.png`}
            alt=""
          />
        ) : (
          <img
            src={`https://assets.myjobglasses.com/images/medals/medal${index + 1}.png`}
            alt=""
          />
        )}
      </div>
      <div className={styles.appointmentCount}>
        {profile?.appointmentCount || <span className={classnames({ blurred })}>-</span>}
        {intl.formatMessage(translations.meeting)}
      </div>
    </div>
  );
}

export default TopEmployeeItem;
