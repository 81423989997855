import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    id: 'CompanyAdminDashboardConversationRate.label',
    defaultMessage: 'Taux de réponse des Ambassadeurs (moyenne)',
  },
  tooltip: {
    id: 'CompanyAdminDashboardConversationRate.tooltip',
    defaultMessage:
      "Nombre d'Ambassadeurs ayant au moins une conversation démarrée sur la période et % de ces conversations ayant reçu une réponse",
  },
  downloadTooltip: {
    id: 'CompanyAdminDashboardConversationRate.downloadTooltip',
    defaultMessage: 'Télécharger la liste des conversations en attente de réponse',
  },
  subTitle: {
    id: 'CompanyAdminDashboardConversationRate.subTitle',
    defaultMessage: 'Calculé sur les {count} Ambassadeurs contactés',
  },
});
