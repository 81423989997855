import { defineMessages } from 'react-intl';

export const NewConversationErrorsTranslations = defineMessages({
  meetingPreferences: {
    id: 'NewConversationErrorsTranslations.meetingPreferences',
    defaultMessage:
      'Malheureusement cet Ambassadeur ne souhaite pas organiser de rendez-vous avec des {primarySituation}. Explorez des profils similaires ou faites une nouvelle recherche.',
  },
  unavailable: {
    id: 'NewConversationErrorsTranslations.unavailable',
    defaultMessage:
      'Malheureusement cet Ambassadeur n’est plus disponible. Il sera de nouveau possible de lui envoyer votre message le {unavailableUntil}. Pour cela, rendez-vous dans “Messages” et “Brouillons”.',
  },
});
