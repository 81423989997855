import { GenderEnum } from '../../../@types/graphql.d';
import { defineMessages } from 'react-intl';

export default defineMessages({
  [GenderEnum.Male]: {
    id: 'GenderEnum.Male',
    defaultMessage: 'Homme',
  },
  [GenderEnum.Female]: {
    id: 'GenderEnum.Female',
    defaultMessage: 'Femme',
  },
  [GenderEnum.Unspecified]: {
    id: 'GenderEnum.Unspecified',
    defaultMessage: 'Non spécifié',
  },
});
