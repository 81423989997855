import { defineMessages } from 'react-intl';

export default defineMessages({
  approveAction: {
    id: 'EmployeeManageToolboxActions.approveAction',
    defaultMessage: 'Approuver',
    description: 'Bouton Approuver',
  },
  approveConfirmationMessage: {
    id: 'EmployeeManageToolboxActions.approveConfirmationMessage',
    defaultMessage:
      'En cliquant sur "Confirmer", les profils sélectionnés seront marqués comme approuvés et prêts à être publiés. Les Ambassadeurs ne seront pas notifiés.',
    description: 'Confirmation message pour approuver',
  },
  publishAction: {
    id: 'EmployeeManageToolboxActions.publishAction',
    defaultMessage: 'Publier',
    description: 'Bouton Publier',
  },
  publishConfirmationMessage: {
    id: 'EmployeeManageToolboxActions.publishConfirmationMessage',
    defaultMessage:
      'En cliquant sur "Confirmer", les profils sélectionnés seront visibles dans le moteur de recherche et les Ambassadeurs seront notifiés par email.',
    description: 'Confirmation message pour publier',
  },
  unpublishAction: {
    id: 'EmployeeManageToolboxActions.unpublishAction',
    defaultMessage: 'Dépublier',
    description: 'Bouton Dépublier',
  },
  unpublishConfirmationMessage: {
    id: 'EmployeeManageToolboxActions.unpublishConfirmationMessage',
    defaultMessage:
      'En cliquant sur "Confirmer", les profils sélectionnés ne seront plus visibles dans le moteur de recherche et les Ambassadeurs seront notifiés par email.',
    description: 'Confirmation message pour dépublier',
  },
  archiveAction: {
    id: 'EmployeeManageToolboxActions.archiveAction',
    defaultMessage: 'Archiver',
    description: 'Bouton Archiver',
  },
  archiveConfirmationMessage: {
    id: 'EmployeeManageToolboxActions.archiveConfirmationMessage',
    defaultMessage:
      'En cliquant sur "Confirmer", les profils sélectionnés seront archivés. Les Ambassadeurs n\'auront plus accès à leur compte My Job Glasses.',
    description: 'Confirmation message pour archiver',
  },
});
