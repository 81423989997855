import { defineMessages } from 'react-intl';
import { InternshipDuration } from '../@types/graphql.d';

export const InternshipDurationTranslations = defineMessages({
  [InternshipDuration.InternshipDuration_1Months]: {
    id: 'ReviewForm.InternshipDuration_1Months',
    defaultMessage: '1 mois',
  },
  [InternshipDuration.InternshipDuration_2Months]: {
    id: 'ReviewForm.InternshipDuration_2Months',
    defaultMessage: '2 mois',
  },
  [InternshipDuration.InternshipDuration_3Months]: {
    id: 'ReviewForm.InternshipDuration_3Months',
    defaultMessage: '3 mois',
  },
  [InternshipDuration.InternshipDuration_4Months]: {
    id: 'ReviewForm.InternshipDuration_4Months',
    defaultMessage: '4 mois',
  },
  [InternshipDuration.InternshipDuration_5Months]: {
    id: 'ReviewForm.InternshipDuration_5Months',
    defaultMessage: '5 mois',
  },
  [InternshipDuration.InternshipDuration_6Months]: {
    id: 'ReviewForm.InternshipDuration_6Months',
    defaultMessage: '6 mois',
  },
  [InternshipDuration.InternshipDuration_7Months]: {
    id: 'ReviewForm.InternshipDuration_7Months',
    defaultMessage: '7 mois',
  },
  [InternshipDuration.InternshipDuration_8Months]: {
    id: 'ReviewForm.InternshipDuration_8Months',
    defaultMessage: '8 mois',
  },
  [InternshipDuration.InternshipDuration_9Months]: {
    id: 'ReviewForm.InternshipDuration_9Months',
    defaultMessage: '9 mois',
  },
  [InternshipDuration.InternshipDuration_10Months]: {
    id: 'ReviewForm.InternshipDuration_10Months',
    defaultMessage: '10 mois',
  },
  [InternshipDuration.InternshipDuration_11Months]: {
    id: 'ReviewForm.InternshipDuration_11Months',
    defaultMessage: '11 mois',
  },
  [InternshipDuration.InternshipDuration_12Months]: {
    id: 'ReviewForm.InternshipDuration_12Months',
    defaultMessage: '12 mois',
  },
  [InternshipDuration.InternshipDuration_13Months]: {
    id: 'ReviewForm.InternshipDuration_13Months',
    defaultMessage: '13 mois',
  },
  [InternshipDuration.InternshipDuration_14Months]: {
    id: 'ReviewForm.InternshipDuration_14Months',
    defaultMessage: '14 mois',
  },
  [InternshipDuration.InternshipDuration_15Months]: {
    id: 'ReviewForm.InternshipDuration_15Months',
    defaultMessage: '15 mois',
  },
  [InternshipDuration.InternshipDuration_16Months]: {
    id: 'ReviewForm.InternshipDuration_16Months',
    defaultMessage: '16 mois',
  },
  [InternshipDuration.InternshipDuration_17Months]: {
    id: 'ReviewForm.InternshipDuration_17Months',
    defaultMessage: '17 mois',
  },
  [InternshipDuration.InternshipDuration_18Months]: {
    id: 'ReviewForm.InternshipDuration_18Months',
    defaultMessage: '18 mois',
  },
  [InternshipDuration.InternshipDuration_19Months]: {
    id: 'ReviewForm.InternshipDuration_19Months',
    defaultMessage: '19 mois',
  },
  [InternshipDuration.InternshipDuration_20Months]: {
    id: 'ReviewForm.InternshipDuration_20Months',
    defaultMessage: '20 mois',
  },
  [InternshipDuration.InternshipDuration_21Months]: {
    id: 'ReviewForm.InternshipDuration_21Months',
    defaultMessage: '21 mois',
  },
  [InternshipDuration.InternshipDuration_22Months]: {
    id: 'ReviewForm.InternshipDuration_22Months',
    defaultMessage: '22 mois',
  },
  [InternshipDuration.InternshipDuration_23Months]: {
    id: 'ReviewForm.InternshipDuration_23Months',
    defaultMessage: '23 mois',
  },
  [InternshipDuration.InternshipDuration_24Months]: {
    id: 'ReviewForm.InternshipDuration_24Months',
    defaultMessage: '24 mois',
  },
  [InternshipDuration.InternshipDurationUndetermined]: {
    id: 'ReviewForm.InternshipDurationUndetermined',
    defaultMessage: 'Durée indéterminée',
  },
});
