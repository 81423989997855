import React from 'react';
import ProfessionalAvailable from './components/professional-available/ProfessionalAvailable';
import ProfessionalFrozen from './components/professional-frozen/ProfessionalFrozen';
import ProfessionalHibernated from './components/professional-hibernated/ProfessionalHibernated';
import ProfessionalQuotaReached from './components/professional-quota-reached/ProfessionalQuotaReached';
import ProfessionalSoftDeleted from './components/professional-soft-deleted/ProfessionalSoftDeleted';
import useConversationIdFromRoute from '../../hooks/useConversationIdFromRoute';
import { useQuery } from '@apollo/client';
import {
  Get_Conversation_Pro_ProfileQuery,
  Get_Conversation_Pro_ProfileQueryVariables,
  PublicProfessionalProfileStatus,
} from '../../../../../@types/graphql.d';
import { GET_CONVERSATION_PRO_PROFILE } from './ProfessionalAvailabilitySwitch.gql';

function ProfessionalAvailabilitySwitch() {
  const conversationId = useConversationIdFromRoute();

  const { data } = useQuery<Get_Conversation_Pro_ProfileQuery, Get_Conversation_Pro_ProfileQueryVariables>(
    GET_CONVERSATION_PRO_PROFILE,
    {
      skip: !conversationId,
      variables: {
        conversationId: conversationId || '',
      },
    },
  );

  switch (data?.conversation?.professional?.status) {
    case PublicProfessionalProfileStatus.IcedUpOrRestricted:
      return <ProfessionalFrozen />;
    case PublicProfessionalProfileStatus.SoftDeleted:
      return <ProfessionalSoftDeleted />;
    case PublicProfessionalProfileStatus.Hibernated:
      return <ProfessionalHibernated interlocutor={data?.conversation?.professional} />;
    case PublicProfessionalProfileStatus.Unavailable:
      return <ProfessionalQuotaReached interlocutor={data?.conversation?.professional} />;
    default:
      return <ProfessionalAvailable interlocutor={data?.conversation?.professional} />;
  }
}

export default ProfessionalAvailabilitySwitch;
