import { defineMessages } from 'react-intl';

export default defineMessages({
  editTheProfile: {
    id: 'EmployeeVisualisationMode.editTheProfile',
    defaultMessage: 'Editer le profil',
    description: 'Lien Editer le profil sur le profil public',
  },
  previewTheProfile: {
    id: 'EmployeeVisualisationMode.previewTheProfile',
    defaultMessage: 'Prévisualiser le profil',
    description: 'Lien Prévisualiser le profil sur le profil privé',
  },
});
