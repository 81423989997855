import { defineMessages } from 'react-intl';

export default defineMessages({
  viewCountLabel: {
    id: 'CompanyAdminDashboardAttractivenessBloc.viewCountLabel',
    defaultMessage: 'Vues de profil',
  },
  viewCountTooltip: {
    id: 'CompanyAdminDashboardAttractivenessBloc.viewCountTooltip',
    defaultMessage:
      "Somme du nombre de vues de profil sur l'ensemble de vos Ambassadeurs (incluant les vues par des utilisateurs non connectés et les vues multiples par un même utilisateur)",
  },
  withUniqueStudent: {
    id: 'CompanyAdminDashboardAttractivenessBloc.withUniqueStudent',
    defaultMessage: 'dont Membres uniques',
  },
  withUniqueStudentTooltip: {
    id: 'CompanyAdminDashboardAttractivenessBloc.withUniqueStudentTooltip',
    defaultMessage: "Nombre de Membres différents ayant visité le profil d'au moins 1 de vos Ambassadeurs",
  },
  keyFigures: {
    id: 'CompanyAdminDashboardAttractivenessBloc.keyFigures',
    defaultMessage: 'Chiffres clés',
  },
  wishLabel: {
    id: 'CompanyAdminDashboardAttractivenessBloc.wishLabel',
    defaultMessage: 'Favoris',
  },
  wishTooltip: {
    id: 'CompanyAdminDashboardAttractivenessBloc.wishTooltip',
    defaultMessage: "Nombre de fois où le profil d'un de vos Ambassadeurs a été ajouté en favori par un Membre",
  },
  likeLabel: {
    id: 'CompanyAdminDashboardAttractivenessBloc.likeLabel',
    defaultMessage: 'Likes',
  },
  likeTooltip: {
    id: 'CompanyAdminDashboardAttractivenessBloc.likeTooltip',
    defaultMessage: "Nombre de RDV où le Membre a liké le profil de l'Ambassadeur(e)",
  },
  activitySectorLabel: {
    id: 'CompanyAdminDashboardAttractivenessBloc.activitySectorLabel',
    defaultMessage: 'Projection dans le secteur',
  },
  activitySchoolTypeLabel: {
    id: 'CompanyAdminDashboardAttractivenessBloc.activitySchoolTypeLabel',
    defaultMessage: 'Projection dans le même type d’établissement',
  },
  activitySectorTooltip: {
    id: 'CompanyAdminDashboardAttractivenessBloc.activitySectorTooltip',
    defaultMessage:
      'Nombre de fois où le Membre a attribué la note de 4 ou 5 (sur 5) au critère "Est-ce que tu te vois dans ce secteur d\'activité ?" après le RDV',
  },
  activitySchoolTypeTooltip: {
    id: 'CompanyAdminDashboardAttractivenessBloc.activitySchoolTypeTooltip',
    defaultMessage:
      'Nombre de fois où le Membre a attribué la note de 4 ou 5 (sur 5) au critère "Est ce que vous vous voyez dans le même type d’établissement ?" après le RDV',
  },
});
