const ACCOUNT_URL = `${import.meta.env.REACT_APP_ACCOUNT_FRONTEND_URL}`;

export function getAccountUrl(path: string) {
  return `${ACCOUNT_URL}${path}`;
}

export function getAccountUrlWithRedirection(
  path: string,
  options?: {
    redirectionUrl?: string;
    searchParams?: { key: string; value: string }[];
  },
) {
  let url = `${ACCOUNT_URL}${path}?marketplace_redirect_url=${encodeURIComponent(options?.redirectionUrl || window.location.href)}`;
  options?.searchParams?.forEach(({ key, value }) => {
    url += `&${key}=${value}`;
  });
  return url;
}

export function redirectToAccountWithRedirection(
  path: string,
  options?: {
    redirectionUrl?: string;
    searchParams?: { key: string; value: string }[];
  },
) {
  window.location.href = getAccountUrlWithRedirection(path, options);
}

export function getInvitationLink(token: string) {
  return `${ACCOUNT_URL}/signup?registration_token=${token}`;
}

export function goToConnectWebsite() {
  window.open('https://www.myjobglasses.com/my-job-glasses-connect', '_blank');
}

export function goToSalesCalendar() {
  window.open(`${import.meta.env.MJG_SALES_CONTACT}`, '_blank');
}

export function isExternalUrl(url: string) {
  return url.startsWith('http');
}
