import React from 'react';
import XCircleIcon from '../../../../../icons/component-icons/XCircleIcon';
import translations from './ProfessionalUnpublished.translations';

import './ProfessionalUnpublished.scss';
import { useIntl } from 'react-intl';

function ProfessionalUnpublished() {
  const intl = useIntl();
  return (
    <div className={'professional-unpublished'}>
      <XCircleIcon className="professional-unpublished__icon" />
      <span className={'professional-unpublished__text'}>{intl.formatMessage(translations.unpublished)}</span>
    </div>
  );
}

export default ProfessionalUnpublished;
