import React from 'react';
import { useQuery } from '@apollo/client';
import {
  Get_Company_Created_AtQuery,
  Get_Company_Created_AtQueryVariables,
  Get_Company_Employee_Appointments_TotalQuery,
  Get_Company_Employee_Appointments_TotalQueryVariables,
  Get_Company_TypeQuery,
  Get_Company_TypeQueryVariables,
} from '../../../../../@types/graphql.d';
import useCompanyUnique from '../../../hooks/useCompanyUnique/useCompanyUnique';
import DownloadAppointmentReport from './components/download-appointment-report/DownloadAppointmentReport';
import { GET_COMPANY_EMPLOYEE_APPOINTMENTS_TOTAL, GET_COMPANY_CREATED_AT } from './CompanyAdminAppointmentsHeader.gql';
import translations from './CompanyAdminAppointmentsHeader.translations';

import styles from './CompanyAdminAppointmentsHeader.module.scss';
import { GET_COMPANY_TYPE } from '../../CompanyAdminAppointmentsView.gql';
import { useIntl } from 'react-intl';

function CompanyAdminAppointmentsHeader() {
  const intl = useIntl();
  const companyId = useCompanyUnique();

  const { data: companyInfo } = useQuery<Get_Company_Created_AtQuery, Get_Company_Created_AtQueryVariables>(
    GET_COMPANY_CREATED_AT,
    {
      skip: !companyId,
      variables: {
        companyId: companyId || '',
      },
    },
  );

  const { data: typeOfCompany } = useQuery<Get_Company_TypeQuery, Get_Company_TypeQueryVariables>(GET_COMPANY_TYPE, {
    skip: !companyId,
    variables: {
      companyId: companyId || '',
    },
  });

  const { data } = useQuery<
    Get_Company_Employee_Appointments_TotalQuery,
    Get_Company_Employee_Appointments_TotalQueryVariables
  >(GET_COMPANY_EMPLOYEE_APPOINTMENTS_TOTAL, {
    skip: !companyId,
    variables: {
      companyId: companyId || '',
    },
  });

  return (
    <div className={styles.companyAdminAppointmentsHeader}>
      <div className={styles.titlesContainer}>
        <h1 className={styles.title}>
          <span className={styles.name}>
            {intl.formatMessage(translations.title, {
              total: data?.companyEmployeeAppointments?.totalCount || '-',
            })}
          </span>
        </h1>
        <h2 className={styles.description}>{intl.formatMessage(translations.subtitle)}</h2>
      </div>
      <div className={styles.appointmentsPeriodContainer}>
        <div className={styles.appointmentsPeriod}>
          {intl.formatMessage(translations.fromTo, {
            from: companyInfo?.company?.createdAt
              ? intl.formatDate(companyInfo?.company?.createdAt, {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })
              : '-',
            to: intl.formatDate(new Date(), {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }),
          })}
        </div>
        <DownloadAppointmentReport />
      </div>
    </div>
  );
}

export default CompanyAdminAppointmentsHeader;
