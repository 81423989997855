import React from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import classnames from 'classnames';
import CompanyLogoDisplay from '../../../company-logo-display/CompanyLogoDisplay';
import PublicProfileAvatarDisplay from '../../../public-profile-avatar-display/PublicProfileAvatarDisplay';
import translations from './ProfessionalCardSoftDeleted.translations';

import parentStyles from '../../ProfessionalCard.module.scss';
import { UNWISH_AMBASSADOR } from './ProfessionalCardSoftDeleted.gql';
import styles from './ProfessionalCardSoftDeleted.module.scss';
import { useIntl } from 'react-intl';

interface ProfessionalCardSoftDeletedProps {
  className?: string;
  userId: string;
}
function ProfessionalCardSoftDeleted({ className, userId }: ProfessionalCardSoftDeletedProps) {
  const intl = useIntl();
  const apolloClient = useApolloClient();

  const [unwishMut] = useMutation(UNWISH_AMBASSADOR);

  function unwish() {
    unwishMut({ variables: { userId } }).then(() => {
      // reset cached wishlist query to handle wish/unwish
      apolloClient.cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'wishlist',
      });
    });
  }
  return (
    <div className={className}>
      <header className={parentStyles.header}>
        <div className={classnames(parentStyles.avatarBackgroundWrapper, styles.avatarBackground)} />
        <div className={classnames(parentStyles.headerContent)}>
          <PublicProfileAvatarDisplay className={parentStyles.avatar} />
          <div className={styles.deletedUser}>{intl.formatMessage(translations.deletedUser)}</div>
        </div>
      </header>
      <div className={parentStyles.content}>
        <CompanyLogoDisplay className={parentStyles.companyLogo} />
      </div>
      <div className={parentStyles.footer}>
        <button
          type="button"
          onClick={unwish}
          className={styles.removeButton}
        >
          {intl.formatMessage(translations.removeButton)}
        </button>
      </div>
    </div>
  );
}

export default ProfessionalCardSoftDeleted;
