import { SearchFilter } from '../../../../scenes/search/Search.model';
import { CompanySizesFilterButtonTranslations } from './CompanySizesFilterButton.translations';
import { CompanySize, FacetKeyEnum } from '../../../../@types/graphql.d';

export const COMPANY_SIZES_BUCKETS_KEYS = [
  {
    label: CompanySizesFilterButtonTranslations[CompanySize.Under_50],
    keys: [CompanySize.OnlyOne.toLowerCase(), CompanySize.Under_10.toLowerCase(), CompanySize.Under_50.toLowerCase()],
  },
  {
    label: CompanySizesFilterButtonTranslations[CompanySize.Under_1000],
    keys: [
      CompanySize.Under_200.toLowerCase(),
      CompanySize.Under_500.toLowerCase(),
      CompanySize.Under_1000.toLowerCase(),
    ],
  },
  {
    label: CompanySizesFilterButtonTranslations[CompanySize.Over_10000],
    keys: [
      CompanySize.Under_5000.toLowerCase(),
      CompanySize.Under_10000.toLowerCase(),
      CompanySize.Over_10000.toLowerCase(),
    ],
  },
];

export function composeCompanySizesFilter(filter: SearchFilter) {
  const composedFilter: SearchFilter = {
    facetType: FacetKeyEnum.CompanySizes,
    buckets: [],
  };

  filter.buckets.forEach((bucket) => {
    COMPANY_SIZES_BUCKETS_KEYS.forEach((b) => {
      if (b.keys.includes(bucket as CompanySize)) {
        composedFilter.buckets.push(...b.keys);
      }
    });
  });

  return composedFilter;
}

export function uncomposeCompanySizesFilter(filter: SearchFilter) {
  const composedFilter: SearchFilter = {
    facetType: FacetKeyEnum.CompanySizes,
    buckets: [],
  };

  COMPANY_SIZES_BUCKETS_KEYS.forEach((b) => {
    b.keys.forEach((bb) => {
      if (filter.buckets.includes(bb)) {
        if (!composedFilter.buckets.includes(b.keys[b.keys.length - 1])) {
          composedFilter.buckets.push(b.keys[b.keys.length - 1]);
        }
      }
    });
  });

  return composedFilter;
}
