import { gql } from '@apollo/client';

export const UPDATE_COMPANY_CUSTOM_ATTRIBUTES = gql`
  mutation UPDATE_COMPANY_CUSTOM_ATTRIBUTES(
    $companyId: ID!
    $number: Int!
    $customEmployeeAttribute: String!
    $companyAttributeValues: [String!]!
  ) {
    updateCustomAttribute(
      companyId: $companyId
      customAttributeNumber: $number
      customEmployeeAttribute: $customEmployeeAttribute
      companyAttributeValues: $companyAttributeValues
    )
  }
`;
