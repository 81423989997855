import * as Yup from 'yup';
import translations from './SendMessage.translations';
import { IntlShape } from 'react-intl';

export const SendMessageValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    text: Yup.string()
      .ensure()
      .typeError(intl.formatMessage(translations.validateTextRequired))
      .trim()
      .min(1, intl.formatMessage(translations.validateTextRequired))
      .required(intl.formatMessage(translations.validateTextRequired)),
  });
