import { useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router';
import { Get_Company_PresentationQuery, Get_Company_PresentationQueryVariables } from '../../../../../@types/graphql.d';
import ConfigurationBloc from '../configuration-bloc/ConfigurationBloc';
import CompanyPresentationEmpty from './components/company-presentation-empty/CompanyPresentationEmpty';
import CompanyPresentationRead from './components/company-presentation-read/CompanyPresentationRead';
import CompanyPresentationWrite from './components/company-presentation-write/CompanyPresentationWrite';
import { GET_COMPANY_PRESENTATION } from './CompanyPresentationHub.gql';
import translations from './CompanyPresentationHub.translations';
import { useIntl } from 'react-intl';

interface CompanyPresentationHubProps {
  className?: string;
}
function CompanyPresentationHub({ className }: CompanyPresentationHubProps) {
  const intl = useIntl();
  const { companyid } = useParams();
  const { data } = useQuery<Get_Company_PresentationQuery, Get_Company_PresentationQueryVariables>(
    GET_COMPANY_PRESENTATION,
    {
      fetchPolicy: 'cache-only',
      skip: !companyid,
      variables: {
        companyId: companyid || '',
      },
    },
  );
  return (
    <ConfigurationBloc
      title={
        data?.company?.isPremiumSchool
          ? intl.formatMessage(translations.presentationSchoolTitle)
          : intl.formatMessage(translations.presentationTitle)
      }
      description={intl.formatMessage(translations.presentationDescription)}
      className={className}
      readComponent={CompanyPresentationRead}
      writeComponent={CompanyPresentationWrite}
      emptyComponent={CompanyPresentationEmpty}
      props={{
        company: data?.company,
      }}
      isEmpty={!data?.company?.description}
    />
  );
}

export default CompanyPresentationHub;
