import classnames from 'classnames';
import React from 'react';
import styles from './AppointmentReviewDots.module.scss';
import { AppointmentReviewDotsTranslations } from './AppointmentReviewDots.translations';
import { useIntl } from 'react-intl';

interface AppointmentReviewDotsProps {
  score?: number | null;
  maxRate?: number;
}
function AppointmentReviewDots({ score, maxRate = 5 }: AppointmentReviewDotsProps) {
  const intl = useIntl();
  if (score === null || score === undefined) {
    return (
      <div className={styles.appointmentReview}>
        <strong data-tooltip={intl.formatMessage(AppointmentReviewDotsTranslations.naTooltip)}>
          {intl.formatMessage(AppointmentReviewDotsTranslations.na)}
        </strong>
      </div>
    );
  }

  return (
    <div className={styles.appointmentReview}>
      {Array.from(Array(maxRate), (_, index) => index).map((_, i) => (
        <span
          key={_}
          className={classnames(styles.dot, {
            [styles.blue]: i < (score || 0),
          })}
        />
      ))}
    </div>
  );
}

export default AppointmentReviewDots;
