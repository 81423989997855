import * as Sentry from '@sentry/react';
import React, { Suspense } from 'react';
import { IntlProvider } from 'react-intl';
import { Route, BrowserRouter, Routes, Navigate } from 'react-router';

import { ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from 'notistack';

import './App.scss';

import { PageLoader } from './components/loader/Loader';
import Account from './containers/account/Account';
import Layout from './containers/layout/Layout';
import NotFound from './containers/not-found/NotFound';
import RedCarpets from './containers/red-carpets/RedCarpets';
import client from './graphql';
import { LandingSnackbarCaller } from './hooks/landing-snackbar';
import { useLanguage } from './hooks/useLanguages';
import './i18n/moment-fr';
import { ConnectedRouterWrapper } from './middlewares/ConnectedRouterWrapper';
import { UnconnectedRouterWrapper } from './middlewares/UnconnectedRouterWrapper';
import MarketplaceRoutes from './routes';
import CompanyView, { CompanyViewForMjgAdmin } from './scenes/company-admin/ambassadors/CompanyView';
import EmployeeProfileView, {
  EmployeeProfileViewForMjgAdmin,
} from './scenes/company-admin/ambassadors/components/employee-profile-view/EmployeeProfileView';
import CompanyAdminAppointmentsView, {
  CompanyAdminAppointmentsViewForMJG,
} from './scenes/company-admin/appointments/CompanyAdminAppointmentsView';
import CompanyAdminConfigurationView, {
  CompanyAdminConfigurationViewForMjgAdmin,
} from './scenes/company-admin/configuration/CompanyAdminConfigurationView';
import CompanyAdminDashboardView from './scenes/company-admin/dashboard/CompanyAdminDashboardView';
import EmployeeEditionView, {
  EmployeeEditionViewForMjgAdmin,
} from './scenes/company-admin/employee-edition/EmployeeEditionView';
import ConnectCompanyHome from './scenes/connect/pages/company/home/ConnectCompanyHome';
import AppointmentList from './scenes/interactions/appointments/AppointmentList';
import ConversationList from './scenes/interactions/conversations/ConversationList';
import ConversationView from './scenes/interactions/conversations/ConversationView';
import DraftList from './scenes/interactions/conversations/DraftList';
import { NewConversation } from './scenes/interactions/new-conversation/NewConversation';
import Claim from './scenes/interactions/reviews/Claim';
import Review from './scenes/interactions/reviews/Review';
import PublicProfessionalView from './scenes/public/PublicProfessionalView';
import SchoolAssignmentsRouter from './scenes/school-assignments/SchoolAssignmentsRouter';
import Search from './scenes/search/Search';
import StudentAffiliationList from './scenes/student/affiliation/StudentAffiliationList';
import Wishlist from './scenes/student/wishlist/Wishlist';
import DashboardView from './scenes/user/dashboard/DashboardView';
import AccountRestrictedView from './scenes/user/profile/components/account-restricted-view/AccountRestrictedView';

const App = () => {
  const { language, translations } = useLanguage();

  const SentryRoutes = Sentry.withSentryReactRouterV7Routing(Routes);

  return (
    <ApolloProvider client={client}>
      <IntlProvider
        messages={translations}
        locale={language}
        defaultLocale="fr"
      >
        <Suspense fallback={<PageLoader />}>
          <Sentry.ErrorBoundary fallback={<p>Une erreur a eu lieu</p>}>
            <BrowserRouter>
              <SnackbarProvider
                maxSnack={5}
                hideIconVariant
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
              >
                <LandingSnackbarCaller />
                <SentryRoutes>
                  {/* Public routes */}
                  <Route element={<UnconnectedRouterWrapper />}>
                    <Route
                      path={MarketplaceRoutes.widgetConversationCreation}
                      element={<NewConversation fromWidget />}
                    />
                  </Route>

                  {/* Protected routes */}
                  <Route element={<ConnectedRouterWrapper />}>
                    {/* Company admin routes */}
                    <Route
                      path={MarketplaceRoutes.companyAdminBase}
                      element={
                        <Navigate
                          to={MarketplaceRoutes.companyAdminDashboard}
                          replace
                        />
                      }
                    />
                    <Route
                      path={MarketplaceRoutes.companyAdminDashboardWithId}
                      element={<CompanyAdminDashboardView />}
                    />
                    <Route
                      path={MarketplaceRoutes.companyAdminDashboard}
                      element={<CompanyAdminDashboardView />}
                    />
                    {[
                      MarketplaceRoutes.companyAdminEditDescription,
                      MarketplaceRoutes.companyAdminAmbassadorsArchived,
                      MarketplaceRoutes.companyAdminAmbassadorsAdd,
                      MarketplaceRoutes.companyAdminAmbassadors,
                      MarketplaceRoutes.companyAdminImportEmployees,
                    ].map((path) => (
                      <Route
                        key={path}
                        path={path}
                        element={<CompanyView />}
                      />
                    ))}
                    <Route
                      path={MarketplaceRoutes.companyAdminShowAmbassador}
                      element={<EmployeeProfileView />}
                    />
                    <Route
                      path={MarketplaceRoutes.companyAdminConfiguration}
                      element={<CompanyAdminConfigurationView />}
                    />
                    <Route
                      path={MarketplaceRoutes.companyAdminEditAmbassador}
                      element={<EmployeeEditionView />}
                    />
                    <Route
                      path={MarketplaceRoutes.companyAdminEditAmbassadorAvatar}
                      element={<EmployeeEditionView />}
                    />
                    {[
                      MarketplaceRoutes.companyAdminAppointments,
                      MarketplaceRoutes.companyAdminAppointmentsWithId,
                      MarketplaceRoutes.companyAdminAppointmentsDetails,
                    ].map((path) => (
                      <Route
                        key={path}
                        path={path}
                        element={<CompanyAdminAppointmentsView />}
                      />
                    ))}
                    {/* Company Admin */}
                    <Route
                      path={MarketplaceRoutes.mjgAdminCompaniesAppointmentsWithId}
                      element={<CompanyAdminAppointmentsViewForMJG />}
                    />
                    <Route
                      path={MarketplaceRoutes.mjgAdminCompaniesAppointmentsDetails}
                      element={<CompanyAdminAppointmentsViewForMJG />}
                    />
                    {[
                      MarketplaceRoutes.mjgAdminCompaniesShow,
                      MarketplaceRoutes.mjgAdminCompaniesArchived,
                      MarketplaceRoutes.mjgAdminCompaniesAddEmployee,
                      MarketplaceRoutes.mjgAdminCompaniesEditDescription,
                      MarketplaceRoutes.mjgAdminCompaniesImportEmployees,
                    ].map((path) => (
                      <Route
                        key={path}
                        path={path}
                        element={<CompanyViewForMjgAdmin />}
                      />
                    ))}
                    <Route
                      path={MarketplaceRoutes.mjgAdminCompaniesConfiguration}
                      element={<CompanyAdminConfigurationViewForMjgAdmin />}
                    />
                    <Route
                      path={MarketplaceRoutes.mjgAdminCompaniesShowEmployee}
                      element={<EmployeeProfileViewForMjgAdmin />}
                    />
                    <Route
                      path={MarketplaceRoutes.mjgAdminCompaniesEditEmployeeAvatar}
                      element={<EmployeeEditionViewForMjgAdmin />}
                    />
                    <Route
                      path={MarketplaceRoutes.mjgAdminCompaniesEditEmployee}
                      element={<EmployeeEditionViewForMjgAdmin />}
                    />
                    {/* Connect routes */}
                    <Route
                      path={MarketplaceRoutes.connectCompanyHome}
                      element={<ConnectCompanyHome />}
                    />
                    <Route
                      path={MarketplaceRoutes.connectCompanyHomeWithId}
                      element={<ConnectCompanyHome />}
                    />
                    <Route
                      path={MarketplaceRoutes.connect}
                      element={
                        <Navigate
                          to={MarketplaceRoutes.connectCompanyHome}
                          replace
                        />
                      }
                    />
                    {/* Vocation routes */}
                    <Route
                      path={MarketplaceRoutes.schoolAssignmentsBase}
                      element={<SchoolAssignmentsRouter />}
                    />
                    <Route
                      path={MarketplaceRoutes.schoolAssignmentsBase}
                      element={<SchoolAssignmentsRouter />}
                    />
                    {/* Regular routes */}
                    <Route
                      path={MarketplaceRoutes.home}
                      element={
                        <Navigate
                          to={MarketplaceRoutes.dashboard}
                          replace
                        />
                      }
                    />
                    <Route
                      path={MarketplaceRoutes.professional}
                      element={<PublicProfessionalView />}
                    />
                    <Route
                      path={MarketplaceRoutes.conversationCreation}
                      element={<NewConversation />}
                    />
                    <Route
                      path={MarketplaceRoutes.studentWishlist}
                      element={<Wishlist />}
                    />
                    <Route
                      path={MarketplaceRoutes.studentAffiliationList}
                      element={<StudentAffiliationList />}
                    />
                    <Route
                      path={MarketplaceRoutes.search}
                      element={<Search />}
                    />
                    {/* TODO: Add back? broken on production as of 2024-12-24 */}
                    {/* <ConditionnedRoute
                            redirectRoute={MarketplaceRoutes.profile}
                            redirectCondition={
                              currentUser.premiumAmbassador &&
                              !currentUser.premiumSchoolAmbassador &&
                              !isPublishedAtLeastOnce(currentUser)
                            }
                            redirectMessage={t(RedirectionsTranslations.noPublishedPremiumAmbassador)}
                            path={MarketplaceRoutes.search}
                            element={<Search />}
                          /> */}
                    <Route
                      path={MarketplaceRoutes.appointments}
                      element={<AppointmentList />}
                    />
                    <Route
                      path={MarketplaceRoutes.conversation}
                      element={<ConversationView />}
                    />
                    <Route
                      path={MarketplaceRoutes.downloadConversationAppointmentIcalendar}
                      element={<ConversationView />}
                    />
                    <Route
                      path={MarketplaceRoutes.conversations}
                      element={<ConversationList />}
                    />
                    <Route
                      path={MarketplaceRoutes.drafts}
                      element={<DraftList />}
                    />
                    <Route
                      path={MarketplaceRoutes.review}
                      element={<Review />}
                    />
                    <Route
                      path={MarketplaceRoutes.claimReview}
                      element={<Claim />}
                    />
                    <Route
                      path={MarketplaceRoutes.settings}
                      element={<Account />}
                    />
                    <Route
                      path={MarketplaceRoutes.account}
                      element={<Account />}
                    />
                    <Route
                      path={MarketplaceRoutes.dashboard}
                      element={<DashboardView />}
                    />
                    <Route
                      path={MarketplaceRoutes.dashboardAcceptProgramInvitation}
                      element={<DashboardView />}
                    />
                    <Route
                      path={MarketplaceRoutes.redCarpets}
                      element={<RedCarpets />}
                    />
                    <Route
                      path={MarketplaceRoutes.accountRestrictedPage}
                      element={<AccountRestrictedView />}
                    />
                    <Route element={<NotFound />} />
                  </Route>
                </SentryRoutes>
              </SnackbarProvider>
            </BrowserRouter>
          </Sentry.ErrorBoundary>
        </Suspense>
      </IntlProvider>
    </ApolloProvider>
  );
};

export default App;
