import React from 'react';
import classnames from 'classnames';

import './MainMenuMobileToggle.scss';
import MenuIcon from '../../../../../icons/component-icons/MenuIcon';

interface MainMenuMobileToggleProps {
  onClick: () => void;
  className?: string;
}
function MainMenuMobileToggle({ onClick, className }: MainMenuMobileToggleProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classnames('main-menu-mobile-toggle', className)}
    >
      <MenuIcon />
    </button>
  );
}

export default MainMenuMobileToggle;
