import { gql } from '@apollo/client';

export const CONNECT_COMPANY_CONTEXT_QUERY = gql`
  query ConnectCompanyContext($id: ID!) {
    company(companyId: $id) {
      id
      premiumPack
      name
    }
  }
`;
