import React, { useRef, useState } from 'react';
import CalendarIcon from '../../../../../../../icons/component-icons/CalendarIcon';
import { FieldProps } from 'formik';
import momentVanilla from 'moment';
import moment from 'moment-timezone';
import { DayPickerSingleDateController } from 'react-dates';
import { isOutsideRange } from '../../../../../../../utils/date';
import { useOutsideClickEffect } from '../../../../../../../utils/hooks/useOutsideClickEffect';
import ContactDateButton from '../contact-date-button/ContactDateButton';

import styles from './ContactDatepicker.module.scss';

interface ContactDatepickerProps extends FieldProps {
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
}
function ContactDatepicker({ field, minDate, maxDate }: ContactDatepickerProps) {
  const [focused, setFocused] = useState(true);
  const [isOpened, setIsOpened] = useState(false);
  function handleDateChange(newDate: momentVanilla.Moment | null) {
    setIsOpened(false);
    field.onChange({
      target: {
        name: field.name,
        value: newDate?.hours(field.value.hours()).minutes(field.value.minutes()),
      },
    });
    field.onBlur({ target: { name: field.name } });
  }
  function handleFocused({ focused: newFocused }: { focused: boolean | null }) {
    setIsOpened(focused);
    setFocused(!!newFocused);
  }
  function toggleCalendar() {
    setFocused(!isOpened);
    setIsOpened(!isOpened);
  }
  const popperRef = useRef(null);
  useOutsideClickEffect(() => {
    setIsOpened(false);
  }, popperRef);
  return (
    <div
      data-cy="contact-date-picker"
      className={styles.contactDatePicker}
      ref={popperRef}
    >
      <ContactDateButton
        datetime={field.value}
        icon={CalendarIcon}
        format="day month year"
        title="Date"
        onClick={toggleCalendar}
      />
      {isOpened && (
        <div className={styles.contactPopover}>
          <DayPickerSingleDateController
            date={field.value}
            numberOfMonths={1}
            onDateChange={handleDateChange}
            onFocusChange={handleFocused}
            focused={focused}
            isOutsideRange={(diffDate) => isOutsideRange(diffDate, minDate, maxDate)}
          />
        </div>
      )}
    </div>
  );
}

export default ContactDatepicker;
