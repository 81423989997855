import React, { useState } from 'react';

import './ErrorableTextField.scss';

export type ErrorableTextFieldProps = {
  placeholder?: string;
  type?: string;
  field: {
    name: string;
    onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    value: string;
  };
  form: {
    touched: { [key: string]: boolean };
    errors: { [key: string]: string };
  };
  autoComplete?: string;
  rows?: number;
};

const ErrorableTextField = ({
  field: { name, onBlur, onChange, value },
  form,
  type = 'text',
  placeholder,
  autoComplete,
  rows,
}: ErrorableTextFieldProps) => {
  const [isPreventingFromDisplayError, preventFromDisplayError] = useState(false);
  function onClickInError() {
    preventFromDisplayError(true);
  }
  function onBlurField(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    preventFromDisplayError(false);
    onBlur(e);
  }
  return (
    <div className="errorable-text-field">
      {type === 'textarea' ? (
        <textarea
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlurField}
          placeholder={placeholder}
          autoComplete={autoComplete}
          rows={rows}
        />
      ) : (
        <input
          type="text"
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlurField}
          placeholder={placeholder}
          autoComplete={autoComplete}
        />
      )}
      {form?.touched?.[name] && form?.errors?.[name] && !isPreventingFromDisplayError ? (
        <p
          role="presentation"
          className="error-message"
          onClick={onClickInError}
          onKeyDown={onClickInError}
        >
          {form?.errors?.[name]}
        </p>
      ) : null}
    </div>
  );
};

export default ErrorableTextField;
