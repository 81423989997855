import React, { useContext } from 'react';
import ArrowRightIcon from '../../../../../../../icons/component-icons/ArrowRightIcon';
import WithTooltip from '../../../../../../common/with-tooltip/WithTooltip';
import AppointmentContext from '../../../../context/context';
import translations from './SidebarHeader.translations';

import styles from './SidebarHeader.module.scss';
import { useIntl } from 'react-intl';
import ArrowLeftIcon from '../../../../../../../icons/component-icons/ArrowLeftIcon';

interface SidebarHeaderProps {
  close: () => void;
}
function SidebarHeader({ close }: SidebarHeaderProps) {
  const intl = useIntl();
  const { canGoBack, canGoFurther, tableNavigation } = useContext(AppointmentContext);

  function next() {
    tableNavigation(1);
  }

  function before() {
    tableNavigation(-1);
  }

  return (
    <header className={styles.sidebarHeader}>
      <div className={styles.top}>
        <h1 className={styles.title}>{intl.formatMessage(translations.title)}</h1>
        <button
          data-testid="close-button"
          type="button"
          className={styles.closeButton}
          onClick={close}
        >
          {intl.formatMessage(translations.close)}
        </button>
      </div>

      <div className={styles.bottom}>
        <WithTooltip
          text={intl.formatMessage(translations.previousAppointment)}
          tooltipClassName={styles.leftTooltip}
        >
          {({ closeTooltip, openTooltip }) => (
            <button
              type="button"
              onClick={before}
              onMouseOver={openTooltip}
              onFocus={openTooltip}
              onMouseOut={closeTooltip}
              onBlur={closeTooltip}
              className={styles.arrowButton}
              data-testid="previous-appointment"
              disabled={!canGoBack}
            >
              <ArrowLeftIcon className={styles.arrow} />
            </button>
          )}
        </WithTooltip>

        <WithTooltip text={intl.formatMessage(translations.nextAppointment)}>
          {({ closeTooltip, openTooltip }) => (
            <button
              type="button"
              onClick={next}
              onMouseOver={openTooltip}
              onFocus={openTooltip}
              onMouseOut={closeTooltip}
              onBlur={closeTooltip}
              className={styles.arrowButton}
              data-testid="next-appointment"
              disabled={!canGoFurther}
            >
              <ArrowRightIcon className={styles.arrow} />
            </button>
          )}
        </WithTooltip>
      </div>
    </header>
  );
}

export default SidebarHeader;
