import { gql } from '@apollo/client';

export const CHANGE_EMAIL_MUTATION = gql`
  mutation ChangeEmail($userId: ID!, $email: String!, $currentPassword: String!) {
    updateUser(userId: $userId, email: $email, currentPassword: $currentPassword) {
      email
    }
  }
`;

export const REQUEST_NEW_PASSWORD_MUTATION = gql`
  mutation RequestNewPassword($email: Email!) {
    requestNewPassword(email: $email)
  }
`;

export const CREATE_HIBERNATION_MUTATION = gql`
  mutation CreateHibernation($startAt: ISO8601Date!, $endAt: ISO8601Date!) {
    createHibernation(startAt: $startAt, endAt: $endAt) {
      id
    }
  }
`;

export const DESTROY_HIBERNATION_MUTATION = gql`
  mutation DestroyHibernation($id: ID!) {
    destroyHibernation(id: $id)
  }
`;
