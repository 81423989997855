import { gql } from '@apollo/client';
import { DefaultMessageTemplate, UserMessageTemplate } from '../../../../../../../@types/graphql.d';

export const GET_DEFAULT_TEMPLATE_LIST = gql`
  query GET_DEFAULT_TEMPLATE_LIST($userId: ID!) {
    defaultMessageTemplates(userId: $userId) {
      id
      title
      body
    }
  }
`;

export const GET_USER_TEMPLATE_LIST = gql`
  query GET_USER_TEMPLATE_LIST($userId: ID!) {
    userMessageTemplates(userId: $userId) {
      id
      title
      body
    }
  }
`;

export const CREATE_MESSAGE_TEMPLATE = gql`
  mutation CREATE_MESSAGE_TEMPLATE($userId: ID!, $title: String!, $body: String!) {
    createMessageTemplate(userId: $userId, title: $title, body: $body) {
      id
    }
  }
`;

export const EDIT_MESSAGE_TEMPLATE = gql`
  mutation EDIT_MESSAGE_TEMPLATE($id: ID!, $title: String!, $body: String!) {
    updateMessageTemplate(id: $id, title: $title, body: $body) {
      id
    }
  }
`;

export const DELETE_MESSAGE_TEMPLATE = gql`
  mutation DELETE_MESSAGE_TEMPLATE($id: ID!) {
    destroyMessageTemplate(id: $id)
  }
`;

export type ExtendedMessageTemplate = (DefaultMessageTemplate | UserMessageTemplate) & { cannotBeDeleted: boolean };
