import { defineMessages } from 'react-intl';
import { HermesContactStateEnum } from '../@types/graphql.d';

export const ContactStateTranslations = defineMessages({
  [HermesContactStateEnum.PastAndConfirmed]: {
    id: 'HermesContactStateEnum.PastAndConfirmed',
    defaultMessage: 'Confirmé',
  },
  [HermesContactStateEnum.PastAwaitingConfirmation]: {
    id: 'HermesContactStateEnum.PastAwaitingConfirmation',
    defaultMessage: "En attente d'évaluation",
  },
  [HermesContactStateEnum.NoScheduledAppointment]: {
    id: 'HermesContactStateEnum.NoScheduledAppointment',
    defaultMessage: 'Aucun rendez-vous prévu',
  },
  [HermesContactStateEnum.InvalidatedAppointment]: {
    id: 'HermesContactStateEnum.InvalidatedAppointment',
    defaultMessage: 'Non confirmé',
  },
  [HermesContactStateEnum.ProfileReportedByTheProfessional]: {
    id: 'HermesContactStateEnum.ProfileReportedByTheProfessional',
    defaultMessage: 'Signalé par le professionnel',
  },
  [HermesContactStateEnum.Upcoming]: {
    id: 'HermesContactStateEnum.Upcoming',
    defaultMessage: 'À venir',
  },
  [HermesContactStateEnum.UnfollowedConversationWithThisProfessional]: {
    id: 'HermesContactStateEnum.UnfollowedConversationWithThisProfessional',
    defaultMessage: 'Conversation non suivie',
  },
});
