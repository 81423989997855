import { defineMessages } from 'react-intl';

export const MeetingLimitTranslations = defineMessages({
  title: {
    id: 'MeetingLimit.title',
    defaultMessage: 'Limite de rendez-vous',
  },
  titleMember: {
    id: 'MeetingLimit.titleMember',
    defaultMessage: 'Prises de contact restantes',
  },
  remainingAppointments: {
    id: 'MeetingLimit.remainingAppointments',
    defaultMessage: 'Rendez-vous restants',
  },
  remaining: {
    id: 'MeetingLimit.remaining',
    defaultMessage: 'restants',
  },
  changeMeetingLimit: {
    id: 'MeetingLimit.changeMeetingLimit',
    defaultMessage: 'Modifier ma limite de rendez-vous',
  },
});
