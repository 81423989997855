import { defineMessages } from 'react-intl';

export default defineMessages({
  showMore: {
    id: 'PresentationDisplay.showMore',
    defaultMessage: 'En savoir plus',
    description: 'lien vers le profil du pro',
  },
  noStudentPresentation: {
    id: 'PresentationDisplay.noStudentPresentation',
    defaultMessage: "Le Membre n'a pas encore renseigné de présentation.",
    description: 'lien vers le profil du pro',
  },
});
