import { defineMessages } from 'react-intl';

export default defineMessages({
  presentationTitle: {
    id: 'CompanyPresentationHub.presentationTitle',
    defaultMessage: "Présentation de l'entreprise",
  },
  presentationSchoolTitle: {
    id: 'CompanyPresentationHub.presentationSchoolTitle',
    defaultMessage: "Présentation de l'établisement",
  },
  presentationDescription: {
    id: 'CompanyPresentationHub.presentationDescription',
    defaultMessage: 'Ceci apparaîtra automatiquement sur le profil public de vos Ambassadeurs.',
  },
});
