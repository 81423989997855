import React, { ChangeEvent } from 'react';
import { FieldProps } from 'formik';
import classnames from 'classnames';

import styles from './FormikRadioField.module.scss';

interface FormikRadioFieldProps extends FieldProps {
  label: string;
  value: string;
  className?: string;
  disabled?: boolean;
}
function FormikRadioField({
  field: { name, onChange, onBlur, value: fieldValue },
  label,
  value,
  className,
  disabled,
}: FormikRadioFieldProps) {
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange({
      target: {
        name,
        value: e.target.checked ? value : undefined,
      },
    });
  }
  return (
    <label
      className={classnames(styles.field, className, {
        [styles.disabled]: disabled,
      })}
    >
      <input
        type="radio"
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={handleChange}
        checked={value === fieldValue}
        disabled={disabled}
      />
      {label}
    </label>
  );
}

export default FormikRadioField;
