import React from 'react';
import { TableCellProps } from 'react-virtualized';
import classnames from 'classnames';
import translations from './wantToBeRecontactedCellRenderer.translations';

import styles from './wantToBeRecontactedCellRenderer.module.scss';
import { useIntl } from 'react-intl';

type WantToBeRecontactedCellRendererProps = TableCellProps & {
  isPremiumSchool?: boolean;
};

function WantToBeRecontactedCellRenderer({ cellData, isPremiumSchool }: WantToBeRecontactedCellRendererProps) {
  const result = isPremiumSchool ? cellData?.wantsToBeRecontactedBySchool : cellData?.wantToBeRecontacted;
  const intl = useIntl();
  if (typeof result === 'undefined' || result === null) {
    return <div data-testid="opportunity">-</div>;
  }

  return (
    <div
      data-testid="opportunity"
      className={classnames(styles.wantToBeRecontacted, {
        [styles.green]: cellData?.wantToBeRecontacted,
        [styles.grey]: !cellData?.wantToBeRecontacted,
      })}
    >
      {result ? intl.formatMessage(translations.yes) : intl.formatMessage(translations.no)}
    </div>
  );
}

export default WantToBeRecontactedCellRenderer;
