import React, { memo } from 'react';
import PrimaryButton from '../../../../../components/primary-button/PrimaryButton';
import { useIntl } from 'react-intl';
import buttonCommonTranslations from '../../../../common/common-translations/button.translations';
import { motion, AnimatePresence } from 'motion/react';
import ChevronRightIcon from '../../../../../icons/component-icons/ChevronRightIcon';
import ChevronLeftIcon from '../../../../../icons/component-icons/ChevronLeftIcon';

import './Footer.scss';
import useIsMobile from '../../../../../hooks/useIsMobile';

type Props = {
  onBack: () => void;
  onNext: () => void;
  canGoNext: boolean;
  progression: number;
  isVisible: boolean;
  isLastPanel: boolean;
}

export const Footer: React.FC<Props> = memo(({ onBack, onNext, canGoNext, progression, isVisible, isLastPanel }) => {
  const { formatMessage } = useIntl();
  const isMobile = useIsMobile();

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.footer
          className="review-view-footer"
          initial={{ opacity: 0, translateY: 50 }}
          animate={{ opacity: 1, translateY: 0 }}
          exit={{ opacity: 0, translateY: 50 }}
          transition={{ duration: 0.5 }}
        >
          <div className="review-view-footer__progress-bar" style={{ width: `${progression}%`}} />

          <div className="review-view-footer__container">
            <PrimaryButton
              icon={ChevronLeftIcon}
              label={formatMessage(buttonCommonTranslations.back)}
              outlined
              leftIcon
              onClick={onBack}
              smaller={isMobile}
            />
            <PrimaryButton
              icon={!isLastPanel ? ChevronRightIcon : undefined}
              label={formatMessage(isLastPanel ? buttonCommonTranslations.finish : buttonCommonTranslations.next)}
              onClick={onNext}
              disabled={!canGoNext}
              smaller={isMobile}
            />
          </div>
        </motion.footer>
      )}
    </AnimatePresence>
  );
});
