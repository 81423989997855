import React from 'react';
import BellIcon from '../../../../../../../icons/component-icons/BellIcon';
import ClockIcon from '../../../../../../../icons/component-icons/ClockIcon';
import CrossIcon from '../../../../../../../icons/component-icons/CrossIcon';
import PencilIcon from '../../../../../../../icons/component-icons/PencilIcon';
import AppointmentDisplayType from '../appointment-display-type/AppointmentDisplayType';
import translations, { pastAppointmentStatusTranslations } from './AppointmentDisplay.translations';

import './AppointmentDisplay.scss';
import { Appointment } from '../../hooks/useConversationAppointments';
import { AppointmentClaim } from '../../hooks/useConversationAppointmentClaims';
import ConversationAddAppointmentToCalendar from '../../component/conversation-add-appointment-to-calendar/ConversationAddAppointmentToCalendar';
import { AppointmentStatus } from '../../../../../../../@types/graphql.d';
import Routes from '../../../../../../../routes';
import { Link, generatePath } from 'react-router';
import ThumbIcon from '../../../../../../../icons/component-icons/ThumbIcon';
import useModal from '../../../../../../../components/modal/Modal.hook';
import { AppointmentUpdateModalTranslations } from '../../../../../../../scenes/interactions/hooks/components/appointment-modal/AppointmentModal.translations';
import { MoveAppointmentModal } from '../../../../../../../scenes/interactions/hooks/components/appointment-modal/AppointmentModal';
import DeleteAppointmentModalTranslations from '../../../../../../../scenes/interactions/hooks/components/delete-appointment-modal/DeleteAppointmentModal.translations';
import DeleteAppointmentModal from '../../../../../../../scenes/interactions/hooks/components/delete-appointment-modal/DeleteAppointmentModal';
import { useIntl } from 'react-intl';

export function AppointmentClaimDisplay({ claim }: { claim: AppointmentClaim }) {
  const intl = useIntl();
  return (
    <div className="conversation-appointment-display">
      <header className="appointment-display-header">
        <span className="appointment-claim-label">
          <BellIcon />
          {intl.formatMessage(translations.waitingClaimConfirmation)}
        </span>
      </header>
      <AppointmentStartDisplay startAt={new Date(claim.startAt)} />
      <AppointmentDisplayType
        type={claim.type}
        meetingPlace={claim.meetingPlace}
      />
      {claim.currentUserCanReview && (
        <footer className="past-appointment-not-reviewed-professional-actions">
          <Link
            to={generatePath(Routes.claimReview, { claimId: claim.id })}
            className="past-appointment-not-reviewed-professional-button"
          >
            {intl.formatMessage(translations.reviewAppointmentButton)}
          </Link>
        </footer>
      )}
    </div>
  );
}

function AppointmentStartDisplay({ startAt }: { startAt: Date }) {
  const intl = useIntl();
  return (
    <>
      <p className="appointment-display-from-date">
        {intl.formatDate(startAt, {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        })}
      </p>
      <p className="appointment-display-from-hour">
        <ClockIcon className="picto" />
        {intl.formatMessage(translations.at, {
          hour: intl.formatTime(startAt, {
            hour: '2-digit',
            minute: '2-digit',
          }),
        })}
      </p>
    </>
  );
}

export function FutureAppointmentDisplay({ appointment }: { appointment: Appointment }) {
  const intl = useIntl();
  const [openModal] = useModal();

  if (!appointment) {
    return null;
  }

  const openMoveAppointmentModal = () =>
    openModal({
      title: intl.formatMessage(AppointmentUpdateModalTranslations.title),
      content: <MoveAppointmentModal appointment={appointment} />,
    });

  const openCancelAppointmentModal = () =>
    openModal({
      title: intl.formatMessage(DeleteAppointmentModalTranslations.title),
      content: <DeleteAppointmentModal appointment={appointment} />,
    });
  return (
    <div className="conversation-appointment-display">
      <header className="appointment-display-header">
        <span className="appointment-label">{intl.formatMessage(translations.upcoming)}</span>
      </header>
      <AppointmentStartDisplay startAt={new Date(appointment.startAt)} />
      <AppointmentDisplayType
        type={appointment.type}
        meetingPlace={appointment.meetingPlace}
        videoconferenceLink={appointment.videoconferenceLink}
      />
      <ConversationAddAppointmentToCalendar appointment={appointment} />
      <aside className="actions">
        {appointment.currentUserCanMove && (
          <button
            type="button"
            onClick={openMoveAppointmentModal}
            className="edit-button"
          >
            <PencilIcon />
          </button>
        )}
        {appointment.currentUserCanCancel && (
          <button
            type="button"
            onClick={openCancelAppointmentModal}
            className="cancel-button"
          >
            <CrossIcon />
          </button>
        )}
      </aside>
    </div>
  );
}

export function PastAppointmentDisplay({ appointment }: { appointment: Appointment }) {
  const intl = useIntl();
  const currentUserReportedStatus = appointment.currentUserIsAmbassador
    ? appointment.ambassadorReview?.appointmentStatus
    : appointment.memberReview?.appointmentStatus;

  const appointmentStatus = appointment.status || currentUserReportedStatus;

  const appointmentStatusTranslation =
    pastAppointmentStatusTranslations[appointmentStatus || AppointmentStatus.Cancelled];

  return (
    <div className="conversation-appointment-display">
      <header className="past-appointment-display-header">
        <span className="past-appointment-status-label">{intl.formatMessage(appointmentStatusTranslation)}</span>
      </header>
      <p className="past-appointment-from-date">
        {intl.formatDate(appointment.startAt, {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        })}
      </p>
      {!appointment.currentUserIsAmbassador && appointment.memberReview && appointment.memberReview.liked !== null && (
        <p className="past-appointment-review-student">
          <ThumbIcon
            reverse={!appointment.memberReview.liked}
            className="picto"
          />
          {appointment.memberReview.liked
            ? intl.formatMessage(translations.liked)
            : intl.formatMessage(translations.disliked)}
        </p>
      )}
      {appointment.currentUserIsAmbassador && appointment.currentUserRecommendedByInterlocutor && (
        <div className="past-appointment-reviewed-professional">
          <p className="student-review">{intl.formatMessage(translations.studentPositiveReview)}</p>
        </div>
      )}
    </div>
  );
}
