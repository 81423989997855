import { gql } from '@apollo/client';

export const GET_COMPANY_CREATED_AT = gql`
  query GET_COMPANY_CREATED_AT($companyId: ID!) {
    company(companyId: $companyId) {
      createdAt
      name
    }
  }
`;

export const GET_COMPANY_EMPLOYEE_APPOINTMENTS_TOTAL = gql`
  query GET_COMPANY_EMPLOYEE_APPOINTMENTS_TOTAL($companyId: ID!) {
    companyEmployeeAppointments(companyId: $companyId) {
      totalCount
    }
  }
`;
