import React from 'react';
import { useQuery } from '@apollo/client';
import {
  Get_Last_Generated_DatetimeQuery,
  Get_Last_Generated_DatetimeQueryVariables,
} from '../../../../../@types/graphql.d';
import useCompanyUnique from '../../../hooks/useCompanyUnique/useCompanyUnique';
import { useCurrentRange } from '../../../hooks/useCurrentRange';
import CompanyReportRangeDisplay from './components/company-report-range-display/CompanyReportRangeDisplay';
import CompanyReportRangeSelector from './components/company-report-range-selector/CompanyReportRangeSelector';
import { GET_LAST_GENERATED_DATETIME } from './CompanyAdminDashboardDriver.gql';
import translations from './CompanyAdminDashboardDriver.translations';

import styles from './CompanyAdminDashboardDriver.module.scss';
import { useIntl } from 'react-intl';

function CompanyAdminDashboardDriver() {
  const intl = useIntl();
  const companyId = useCompanyUnique();
  const currentRange = useCurrentRange();
  const { data } = useQuery<Get_Last_Generated_DatetimeQuery, Get_Last_Generated_DatetimeQueryVariables>(
    GET_LAST_GENERATED_DATETIME,
    {
      skip: !companyId,
      variables: {
        companyId: companyId || '',
        range: currentRange,
      },
      fetchPolicy: 'cache-only',
    },
  );
  return (
    <section className={styles.driver}>
      <div className={styles.reportData}>
        <div className={styles.title}>{intl.formatMessage(translations.dashboard)}</div>
        <p className={styles.lastGeneratedDateTime}>
          {intl.formatMessage(translations.generatedDateTime, {
            date: data?.companyReport?.lastUpdatedAt
              ? intl.formatDate(data?.companyReport?.lastUpdatedAt, {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })
              : '-',
            hour: data?.companyReport?.lastUpdatedAt ? intl.formatTime(data?.companyReport?.lastUpdatedAt) : '-',
          })}
        </p>
      </div>
      <div className={styles.reportRange}>
        <CompanyReportRangeSelector />
        <CompanyReportRangeDisplay />
      </div>
    </section>
  );
}

export default CompanyAdminDashboardDriver;
