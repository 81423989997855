import React, { memo, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import buttonCommonTranslations from '../../../../../../common/common-translations/button.translations';
import { AssetBlock } from '../../components/asset-block/AssetBlock';
import { SectionCard } from '../../components/section-card/SectionCard';
import { useQuery } from '@apollo/client';
import { GET_COMPANY_COMMUNICATION_TOOL_QUERY } from './MarketingCommunication.gql';
import {
  GetCompanyCommunicationToolQuery,
  GetCompanyCommunicationToolQueryVariables,
} from '../../../../../../../@types/graphql.d';
import { useConnectCompanyContext } from '../../ConnectCompanyContext';
import { useRestrictedPackModal } from '../../components/restricted-pack-modal/RestrictedPackModal.hook';
import ahoy from 'ahoy.js';

const translations = defineMessages({
  title: {
    id: 'ConnectCompany.MarketingCommunication.title',
    defaultMessage: '📢 Kit de communication',
  },
  subtitle: {
    id: 'ConnectCompany.MarketingCommunication.subtitle',
    defaultMessage:
      'Téléchargez, imprimez et partagez un kit de communication pour inciter vos collaborateurs à devenir Ambassadeurs. ',
  },
});

const firstAssetTranslations = defineMessages({
  titleStandard: {
    id: 'ConnectCompany.MarketingCommunication.FirstAsset.titleStandard',
    defaultMessage: 'Affiche standard à imprimer',
  },
  subtitleStandard: {
    id: 'ConnectCompany.MarketingCommunication.FirstAsset.subtitleStandard',
    defaultMessage: 'Téléchargez une affiche A4 à diffuser dans votre entreprise',
  },
  titlePremium: {
    id: 'ConnectCompany.MarketingCommunication.FirstAsset.titlePremium',
    defaultMessage: 'Kit de communication complet',
  },
  subtitlePremium: {
    id: 'ConnectCompany.MarketingCommunication.FirstAsset.subtitlePremium',
    defaultMessage: 'Téléchargez un kit complet à l’image de votre entreprise',
  },
  notAvailableKit: {
    id: 'ConnectCompany.MarketingCommunication.FirstAsset.notAvailableKit',
    defaultMessage: '❌ Kit de communication indisponible',
  },
});

export const MarketingCommunication = memo(() => {
  const intl = useIntl();
  const { id } = useConnectCompanyContext();
  const openRestrictedPackageModal = useRestrictedPackModal();

  const { data } = useQuery<GetCompanyCommunicationToolQuery, GetCompanyCommunicationToolQueryVariables>(
    GET_COMPANY_COMMUNICATION_TOOL_QUERY,
    {
      variables: {
        companyId: id || '',
      },
      fetchPolicy: 'network-only',
    },
  );

  const downloadFile = useCallback(async (url: string, filename: string) => {
    if (!url) return;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.statusText}`);
      }

      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  }, []);

  const downloadBasicPoster = useCallback(() => {
    const url = data?.company?.abyssaleDynamicUrl;
    if (!url) return;
    downloadFile(url, `${data?.company?.name} - Affiche standard My Job Glasses.png`);
    ahoy.track('b2b_connect_downloaded_ambassador_signup_standard_image', {companyId: id});
  }, [data, downloadFile]);

  const downloadCompletKit = useCallback(() => {
    const url = data?.company?.communicationKitUrl;
    if (!url) return;
    downloadFile(url, `${data?.company?.name} - Kit de communication My Job Glasses.zip`);
    ahoy.track('b2b_connect_downloaded_communication_kit', {companyId: id});
  }, [data, downloadFile]);

  return (
    <SectionCard
      title={intl.formatMessage(translations.title)}
      subtitle={intl.formatMessage(translations.subtitle)}
    >
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
        <AssetBlock
          title={intl.formatMessage(firstAssetTranslations.titleStandard)}
          subtitle={intl.formatMessage(firstAssetTranslations.subtitleStandard)}
          primaryAction={{ label: intl.formatMessage(buttonCommonTranslations.download), onClick: downloadBasicPoster }}
        />
        {data?.company?.canCustomCommunicationKit ? (
          <AssetBlock
            title={intl.formatMessage(firstAssetTranslations.titlePremium)}
            subtitle={intl.formatMessage(firstAssetTranslations.subtitlePremium)}
            primaryAction={{ label: intl.formatMessage(buttonCommonTranslations.download), onClick: downloadCompletKit }}
            accent
            disabled={!data?.company?.communicationKitUrl}
            disabledMsg={intl.formatMessage(firstAssetTranslations.notAvailableKit)}
          />
        ) : (
          <AssetBlock
            title={intl.formatMessage(firstAssetTranslations.titlePremium)}
            subtitle={intl.formatMessage(firstAssetTranslations.subtitlePremium)}
            secondaryAction={{ label: intl.formatMessage(buttonCommonTranslations.download), onClick: openRestrictedPackageModal }}
            accent
            disabled
          />
        )}
      </div>
    </SectionCard>
  );
});
