import React from 'react';
import PublicProfileAvatarDisplay from '../../../../../../common/public-profile-avatar-display/PublicProfileAvatarDisplay';
import translations from './ConversationInterlocutorDisplayAnonymized.translations';
import styles from '../../ConversationInterlocutorDisplay.module.scss';
import { useIntl } from 'react-intl';

function ConversationInterlocutorDisplayAnonymized() {
  const intl = useIntl();
  return (
    <header className={styles.conversationInterlocutorDisplay}>
      <PublicProfileAvatarDisplay className={styles.interlocutorAvatar} />
      <p className={styles.interlocutorFirstname}>{intl.formatMessage(translations.anonymizedUser)}</p>
    </header>
  );
}

export default ConversationInterlocutorDisplayAnonymized;
