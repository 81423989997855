import { useLocation } from 'react-router';
import { CompanyReportRange } from '../../../@types/graphql.d';
import { CompanyDashboardSearchParams } from '../../../@types/routes-params';
import { extractParamsFromUrlSearch } from '../../../utils/typed/router';

export function useCurrentRange() {
  const currentLocation = useLocation();
  const currentValue = extractParamsFromUrlSearch<CompanyDashboardSearchParams>(currentLocation.search);
  const currentRange =
    currentValue?.range && Object.values(CompanyReportRange).includes(currentValue?.range)
      ? currentValue.range
      : CompanyReportRange.ThisMonth;
  return currentRange;
}
