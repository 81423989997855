import { useContext } from 'react';
import uniqid from 'uniqid';
import { useQuery } from '@apollo/client';
import {
  Get_Company_Employee_Custom_Attributes_DistributionQuery,
  Get_Company_Employee_Custom_Attributes_DistributionQueryVariables,
} from '../../../../../../../@types/graphql.d';
import CompanyContext from '../../../../context/CompanyContext';

import { GET_COMPANY_EMPLOYEE_CUSTOM_ATTRIBUTES_DISTRIBUTION } from './useCompanyAttributeSegment.gql';
import styles from './useCompanyAttributeSegment.module.scss';
import translations from './useCompanyAttributeSegment.translations';
import { useIntl } from 'react-intl';

type UseCompanyAttributeSegmentTypeSegment = {
  key: string;
  percentage?: number;
  color?: string;
  tooltip?: string;
  className?: string;
};

type UseCompanyAttributeSegmentType = {
  name: string;
  segments: Array<UseCompanyAttributeSegmentTypeSegment>;
};

export default function useCompanyAttributeSegment(index: number): UseCompanyAttributeSegmentType | undefined {
  const intl = useIntl();
  const { companyid } = useContext(CompanyContext);
  const { data } = useQuery<
    Get_Company_Employee_Custom_Attributes_DistributionQuery,
    Get_Company_Employee_Custom_Attributes_DistributionQueryVariables
  >(GET_COMPANY_EMPLOYEE_CUSTOM_ATTRIBUTES_DISTRIBUTION, {
    variables: {
      companyId: companyid || '',
    },
  });

  if (!data?.company) {
    return undefined;
  }

  let customAttributeDistribution;
  if (index === 1) {
    customAttributeDistribution = data.company.employeesCustomAttribute1Distribution;
  } else if (index === 2) {
    customAttributeDistribution = data.company.employeesCustomAttribute2Distribution;
  } else if (index === 3) {
    customAttributeDistribution = data.company.employeesCustomAttribute3Distribution;
  } else {
    return undefined;
  }

  if (!customAttributeDistribution) {
    return undefined;
  }

  const distribution = [...customAttributeDistribution.distribution].sort((a, b) => b.count - a.count);
  const result = distribution.slice(0, 6).map((item, i) => ({
    key: `${item.key}_${uniqid()}`,
    percentage: item.percentage,
    tooltip: `${Math.round(item.percentage)}% (${item.count}) ${item.key}`,
    className: styles[`segment${i + 1}`],
  }));

  const others = distribution.slice(6);

  if (others.length !== 0) {
    const othersTotal = others.map((item) => item.count).reduce((a, b) => a + b);
    const othersRatio = Math.round((100 * othersTotal) / customAttributeDistribution.total);

    result.unshift({
      key: `others_${uniqid()}`,
      percentage: othersRatio,
      tooltip: `${othersRatio}% (${othersTotal}) ${intl.formatMessage(translations.others, { rate: othersRatio, count: othersTotal })}`,
      className: styles.segment7,
    });
  }

  if (result.length !== 0) {
    let totalPercentageDiff = 100 - result.map((item) => item.percentage).reduce((a, b) => a + b);

    if (totalPercentageDiff < result.length) {
      for (let i = 0; totalPercentageDiff > 0; i += 1) {
        const add = Math.max(totalPercentageDiff, 1);
        totalPercentageDiff -= add;
        result[i % result.length].percentage += add;
      }
    }
  }

  return {
    name: customAttributeDistribution.name,
    segments: result,
  };
}
