import { defineMessages } from 'react-intl';
import { BadgeEnum } from '../@types/graphql.d';

export const BadgesTranslations = defineMessages<string>({
  [BadgeEnum.Registration]: {
    id: 'Badges.Registration',
    defaultMessage: 'Compte<br/>créé',
    description: 'compte utilisateur créé, 1ere ligne',
  },
  [BadgeEnum.AccountCreated]: {
    id: 'Badges.AccountCreated',
    defaultMessage: 'Compte<br/>créé',
    description: 'compte utilisateur créé, 1ere ligne',
  },
  [BadgeEnum.ProfileCompletedForTheFirstTime]: {
    id: 'Badges.ProfileCompletedForTheFirstTime',
    defaultMessage: 'Profil<br/>complet',
    description: 'profil complet pour la première fois, 1ere ligne',
  },
  [BadgeEnum.ProfileComplete]: {
    id: 'Badges.ProfileComplete',
    defaultMessage: 'Profil<br/>complet',
    description: 'profil complet pour la première fois, 1ere ligne',
  },
  [BadgeEnum.ProfilePublishedForTheFirstTime]: {
    id: 'Badges.ProfilePublishedForTheFirstTime',
    defaultMessage: 'Profil<br/>publié',
    description: 'profil publié, 1ere ligne',
  },
  [BadgeEnum.FirstMessageSent]: {
    id: 'Badges.FirstMessageSent',
    defaultMessage: '1er message<br/>envoyé',
  },
  [BadgeEnum.FirstConversationReceived]: {
    id: 'Badges.FirstConversationReceived',
    defaultMessage: '1er contact<br/>Membre',
    description: '1er message recu, 1ere ligne',
  },
  [BadgeEnum.FirstConversationReplied]: {
    id: 'Badges.FirstConversationReplied',
    defaultMessage: '1ère réponse<br/>Membre',
    description: '1er conversation répondu, 1ere ligne',
  },
  [BadgeEnum.FirstAppointmentOccurred]: {
    id: 'Badges.FirstAppointmentOccurred',
    defaultMessage: '1er RDV<br/>effectué',
  },
  [BadgeEnum.AppointmentNo_1Occurred]: {
    id: 'Badges.AppointmentNo_1Occurred',
    defaultMessage: '1er RDV<br/>effectué',
    description: '1 rdv effectué, 1ere ligne',
  },
  [BadgeEnum.FirstAppointmentReview]: {
    id: 'Badges.FirstAppointmentReview',
    defaultMessage: '1er RDV<br/>évalué',
    description: '1er rdv evalué, 1ere ligne',
  },
  [BadgeEnum.AppointmentNo_1Reviewed]: {
    id: 'Badges.AppointmentNo_1Reviewed',
    defaultMessage: '1er RDV<br/>évalué',
    description: '1er rdv evalué, 1ere ligne',
  },
  [BadgeEnum.AppointmentNo_3Occurred]: {
    id: 'Badges.AppointmentNo_3Occurred',
    defaultMessage: '3e RDV<br/>effectué',
    description: '3 rdv efféctué, 1ere ligne',
  },
  [BadgeEnum.ThirdAppointmentOccurred]: {
    id: 'Badges.ThirdAppointmentOccurred',
    defaultMessage: '3e RDV<br/>effectué',
    description: '3 rdv efféctué, 1ere ligne',
  },
  [BadgeEnum.AppointmentNo_5Occurred]: {
    id: 'Badges.AppointmentNo_5Occurred',
    defaultMessage: '5e RDV<br/>effectué',
    description: '5e RDV effectué, 1ere ligne',
  },
  [BadgeEnum.FifthAppointmentOccurred]: {
    id: 'Badges.FifthAppointmentOccurred',
    defaultMessage: '5e RDV<br/>effectué',
  },
  [BadgeEnum.AppointmentNo_10Occurred]: {
    id: 'Badges.AppointmentNo_10Occurred',
    defaultMessage: '10e RDV<br/>effectué',
    description: '10e RDV effectué, 1ere ligne',
  },
  [BadgeEnum.TenthAppointmentOccurred]: {
    id: 'Badges.TenthAppointmentOccurred',
    defaultMessage: '10e RDV<br/>effectué',
  },
  [BadgeEnum.AppointmentNo_15Occurred]: {
    id: 'Badges.AppointmentNo_15Occurred',
    defaultMessage: '15e RDV<br/>effectué',
    description: '15e RDV effectué, 1ere ligne',
  },
  [BadgeEnum.FifteenthAppointmentOccurred]: {
    id: 'Badges.FifteenthAppointmentOccurred',
    defaultMessage: '15e RDV<br/>effectué',
    description: '15e RDV effectué, 1ere ligne',
  },
  [BadgeEnum.AppointmentNo_20Occurred]: {
    id: 'Badges.AppointmentNo_20Occurred',
    defaultMessage: '20e RDV<br/>effectué',
    description: '20e RDV effectué, 1ere ligne',
  },
  [BadgeEnum.TwentiethAppointmentOccurred]: {
    id: 'Badges.TwentiethAppointmentOccurred',
    defaultMessage: '20e RDV<br/>effectué',
  },
  [BadgeEnum.AppointmentNo_30Occurred]: {
    id: 'Badges.AppointmentNo_30Occurred',
    defaultMessage: '30e RDV<br/>effectué',
    description: '30e RDV effectué, 1ere ligne',
  },
  [BadgeEnum.ThirthiethAppointmentOccurred]: {
    id: 'Badges.ThirthiethAppointmentOccurred',
    defaultMessage: '30e RDV<br/>effectué',
  },
  [BadgeEnum.AppointmentNo_50Occurred]: {
    id: 'Badges.AppointmentNo_50Occurred',
    defaultMessage: '50e RDV<br/>effectué',
    description: '50e RDV effectué, 1ere ligne',
  },
  [BadgeEnum.FiftiethAppointmentOccurred]: {
    id: 'Badges.FiftiethAppointmentOccurred',
    defaultMessage: '50e RDV<br/>effectué',
  },
  [BadgeEnum.AppointmentNo_75Occurred]: {
    id: 'Badges.AppointmentNo_75Occurred',
    defaultMessage: '75e RDV<br/>effectué',
    description: '75e RDV effectué, 1ere ligne',
  },
  [BadgeEnum.SeventyFifthAppointmentOccurred]: {
    id: 'Badges.SeventyFifthAppointmentOccurred',
    defaultMessage: '75e RDV<br/>effectué',
    description: '75e RDV effectué, 1ere ligne',
  },
  [BadgeEnum.AppointmentNo_100Occurred]: {
    id: 'Badges.AppointmentNo_100Occurred',
    defaultMessage: '100e RDV<br/>effectué',
    description: '100e RDV effectué, 1ere ligne',
  },
  [BadgeEnum.HundredthAppointmentOccurred]: {
    id: 'Badges.HundredthAppointmentOccurred',
    defaultMessage: '100e RDV<br/>effectué',
  },
  [BadgeEnum.AppointmentNo_150Occurred]: {
    id: 'Badges.AppointmentNo_150Occurred',
    defaultMessage: '150e RDV <br/>effectué',
    description: '150e RDV effectué, 1ere ligne',
  },
  [BadgeEnum.AppointmentNo_200Occurred]: {
    id: 'Badges.AppointmentNo_200Occurred',
    defaultMessage: '200e RDV<br/>effectué',
    description: '200e RDV effectué, 1ere ligne',
  },
  [BadgeEnum.AppointmentNo_300Occurred]: {
    id: 'Badges.AppointmentNo_300Occurred',
    defaultMessage: '300e RDV<br/>effectué',
    description: '300e RDV effectué, 1ere ligne',
  },
  [BadgeEnum.AppointmentNo_500Occurred]: {
    id: 'Badges.AppointmentNo_500Occurred',
    defaultMessage: '500e RDV<br/>effectué',
    description: '500e RDV effectué, 1ere ligne',
  },
});
