import React, { useMemo } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router';

import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import moment from 'moment';

import './Availability.scss';

import { GetAvailabilityQuery, PublicationStatuses } from '../../../../../@types/graphql.d';
import { MJGFormattedMessage } from '../../../../../components/mjg-formatted-message/MJGFormattedMessage';
import ClockIcon from '../../../../../icons/component-icons/ClockIcon';
import Routes from '../../../../../routes';
import { getAccountUrl } from '../../../../../utils/redirection.util';
import { GET_AVAILABILITY_QUERY } from '../../DashboardView.gql';
import DashboardCard, { DashboardCardLoader } from '../../components/dashboard-card/DashboardCard';
import { AvailabilityTranslations } from './Availability.translations';

const REDIRECTION_LINK =
  'https://help.myjobglasses.com/fr/articles/109074-comment-fonctionne-la-disponibilite-des-ambassadeurs';

const STATUS_CONFIG: Record<
  string,
  { availability: MessageDescriptor; description?: MessageDescriptor; link?: string; linkText?: MessageDescriptor }
> = {
  available: {
    availability: AvailabilityTranslations.available,
    description: AvailabilityTranslations.availableDescription,
    link: REDIRECTION_LINK,
    linkText: AvailabilityTranslations.learnMore,
  },
  inModeration: {
    availability: AvailabilityTranslations.underModeration,
    description: AvailabilityTranslations.underModerationDescription,
  },
  inHibernation: {
    availability: AvailabilityTranslations.unavailable,
    description: AvailabilityTranslations.inHibernationDescription,
    link: getAccountUrl('/settings/hibernation'),
    linkText: AvailabilityTranslations.editHibernation,
  },
  lowResponseRate: {
    availability: AvailabilityTranslations.unavailable,
    description: AvailabilityTranslations.lowResponseRateDescription,
    link: Routes.conversations,
    linkText: AvailabilityTranslations.answerMessages,
  },
  noMeetingsLeft: {
    availability: AvailabilityTranslations.unavailable,
    description: AvailabilityTranslations.noMeetingsLeftDescription,
    link: REDIRECTION_LINK,
    linkText: AvailabilityTranslations.learnMore,
  },
  noContactsLeft: {
    availability: AvailabilityTranslations.unavailable,
    description: AvailabilityTranslations.noContactsLeftDescription,
    link: REDIRECTION_LINK,
    linkText: AvailabilityTranslations.learnMore,
  },
  unpublished: {
    availability: AvailabilityTranslations.unpublished,
    description: AvailabilityTranslations.unpublishedDescription,
  },
};

export default function Availability() {
  const intl = useIntl();
  const { data, loading } = useQuery<GetAvailabilityQuery>(GET_AVAILABILITY_QUERY, {
    fetchPolicy: 'network-only',
  });

  const isAvailable = useMemo(
    () =>
      data?.availability?.publicationStatus === PublicationStatuses.Published && !data?.availability?.unavailableUntil,
    [data?.availability],
  );

  const unavailableUntil = data?.availability?.unavailableUntil;

  const availabilityStatusMap = [
    { key: 'available', condition: isAvailable },
    { key: 'inModeration', condition: data?.availability?.isInModeration },
    { key: 'inHibernation', condition: data?.availability?.currentHibernation?.id },
    {
      key: 'lowResponseRate',
      condition: data?.availability?.responseRateAsAmbassador && data?.availability?.responseRateAsAmbassador < 0.5,
    },
    { key: 'noMeetingsLeft', condition: data?.availability?.remainingMeetingsAsAmbassadorThisMonth === 0 },
    { key: 'noContactsLeft', condition: data?.availability?.ambassadorReachedConversationQuota },
    { key: 'unpublished', condition: data?.availability?.publicationStatus === PublicationStatuses.Unpublished },
  ];

  const publicationStatus = availabilityStatusMap.find(({ condition }) => condition)?.key;

  if (loading) return <AvailabilityLoader />;

  return (
    <DashboardCard
      icon={ClockIcon}
      title={intl.formatMessage(AvailabilityTranslations.title)}
      style={{ backgroundColor: isAvailable ? '#F4FFF3' : '#fff0f0', padding: '16px 16px 8px' }}
    >
      <div
        className={classNames('dashboard-availability', {
          'dashboard-availability--unavailable': !isAvailable,
        })}
      >
        {publicationStatus && (
          <PublicationStatus
            publicationStatus={publicationStatus}
            unavailableUntil={unavailableUntil}
          />
        )}
      </div>
    </DashboardCard>
  );
}

function PublicationStatus({
  publicationStatus,
  unavailableUntil,
}: {
  publicationStatus: string;
  unavailableUntil: Date;
}) {
  const intl = useIntl();

  const status = STATUS_CONFIG[publicationStatus];

  if (!status) return null;

  return (
    <>
      <p
        className={`dashboard-availability__status ${publicationStatus !== 'available' ? 'dashboard-availability__status--unavailable' : ''}`}
      >
        {intl.formatMessage(status.availability)}
      </p>
      {status.description && (
        <p className="dashboard-availability__description">
          <MJGFormattedMessage
            translation={status.description}
            values={unavailableUntil ? { date: moment(unavailableUntil).format('DD/MM') } : {}}
          />
        </p>
      )}
      {status.linkText && (
        <a
          href={status.link}
          className="dashboard-availability__link"
        >
          {intl.formatMessage(status.linkText)}
        </a>
      )}
    </>
  );
}

export const AvailabilityLoader = () => (
  <DashboardCardLoader>
    <Skeleton width="30%" />
    <Skeleton
      width="100%"
      count={4}
    />
  </DashboardCardLoader>
);
