import { gql } from '@apollo/client';

export const GET_CONVERSATION_MESSAGES_AND_INTERLOCUTORS = gql`
  query GET_CONVERSATION_MESSAGES_AND_INTERLOCUTORS($conversationId: ID!) {
    conversation(conversationId: $conversationId) {
      id
      professional {
        id
      }
      student {
        id
      }
      messages {
        id
        text
        createdAt
        sender {
          id
          firstName
          lastName
          avatar
        }
      }
    }
  }
`;
