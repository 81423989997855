import { defineMessages } from 'react-intl';
import { InviteEmployeeErrors } from '../../../../../@types/graphql-errors.d';

export default defineMessages({
  title: {
    id: 'AddEmployeeModal.title',
    defaultMessage: 'Ajouter un Ambassadeur',
  },
  // label
  firstnameLabel: {
    id: 'AddEmployeeModal.firstnameLabel',
    defaultMessage: 'Prénom',
    description: 'Label Prénom dans l ajout du Membre',
  },
  nameLabel: {
    id: 'AddEmployeeModal.nameLabel',
    defaultMessage: 'Nom',
    description: 'Label Nom dans l ajout du Membre',
  },
  emailLabel: {
    id: 'AddEmployeeModal.emailLabel',
    defaultMessage: 'Email',
    description: 'Label Email dans l ajout du Membre',
  },
  companyAttribute1Label: {
    id: 'AddEmployeeModal.companyAttribute1Label',
    defaultMessage: 'Libellé n°1',
    description: 'Label Libellé n°1 dans l ajout du Membre',
  },
  companyAttribute2Label: {
    id: 'AddEmployeeModal.companyAttribute2Label',
    defaultMessage: 'Libellé n°2',
    description: 'Label Libellé n°2 dans l ajout du Membre',
  },
  companyAttribute3Label: {
    id: 'AddEmployeeModal.companyAttribute3Label',
    defaultMessage: 'Libellé n°3',
    description: 'Label Libellé n°3 dans l ajout du Membre',
  },
  // sync errors
  missingFirstname: {
    id: 'AddEmployeeModal.missingFirstname',
    defaultMessage: 'Veuillez saisir un prénom',
    description: 'Erreur lorsque le RH n a pas mis de prénom',
  },
  missingLastname: {
    id: 'AddEmployeeModal.missingLastname',
    defaultMessage: 'Veuillez saisir un nom',
    description: 'Erreur lorsque le RH n a pas mis de nom de famille',
  },
  badEmail: {
    id: 'AddEmployeeModal.badEmail',
    defaultMessage: 'Veuillez saisir une adresse e-mail valide',
    description: 'Erreur lorsque le mail saisie par le/la RH n est pas bon',
  },
  missingCompanyAttribute: {
    id: 'AddEmployeeModal.missingCompanyAttribute',
    defaultMessage: 'Veuillez ajouter un libellé',
    description: 'Veuillez ajouter un libellé',
  },
  // description/buttons
  description: {
    id: 'AddEmployeeModal.description',
    defaultMessage:
      'En cliquant sur "Inviter", un email d\'invitation sera automatiquement envoyé à l\'Ambassadeur invité(e).',
    description: 'description sur le formulaire d ajout d Ambassadeur',
  },
  inviteButton: {
    id: 'AddEmployeeModal.inviteButton',
    defaultMessage: 'Inviter',
    description: 'Bouton Inviter sur le formulaire d ajout du Membre',
  },
  // toastr
  invitationSent: {
    id: 'AddEmployeeModal.invitationSent',
    defaultMessage: 'Invitation envoyée !',
    description: 'Message Invitation envoyée ! dans l Admin RH',
  },
  invitationNotSent: {
    id: 'AddEmployeeModal.invitationNotSent',
    defaultMessage: "Désolé, l'invitation n'a pas pu être envoyée. Réessayez.",
    description: 'Erreur à l ajout d un Ambassadeur',
  },
});

export const asyncErrorsTranslations = defineMessages({
  [InviteEmployeeErrors.EMAIL_ALREADY_INVITED]: {
    id: 'AddEmployeeModal.EMAIL_ALREADY_INVITED',
    defaultMessage: 'Cette adresse email est déjà utilisée',
    description: 'Cette adresse email est déjà utilisée',
  },
  [InviteEmployeeErrors.EMAIL_ALREADY_INVITED_WITH_OTHER_NAME]: {
    id: 'AddEmployeeModal.EMAIL_ALREADY_INVITED_WITH_OTHER_NAME',
    defaultMessage: 'Cette adresse email est déjà utilisée',
    description: 'Cette adresse email est déjà utilisée',
  },
  [InviteEmployeeErrors.EMAIL_ALREADY_USED]: {
    id: 'AddEmployeeModal.EMAIL_ALREADY_USED',
    defaultMessage: 'Cette adresse email est déjà utilisée',
    description: 'Cette adresse email est déjà utilisée',
  },
  [InviteEmployeeErrors.EMAIL_BLANK]: {
    id: 'AddEmployeeModal.EMAIL_BLANK',
    defaultMessage: 'Veuillez saisir une adresse e-mail valide',
    description: 'Veuillez saisir une adresse e-mail valide',
  },
  [InviteEmployeeErrors.EMAIL_INVALID]: {
    id: 'AddEmployeeModal.EMAIL_INVALID',
    defaultMessage: 'Veuillez saisir une adresse e-mail valide',
    description: 'Veuillez saisir une adresse e-mail valide',
  },
  [InviteEmployeeErrors.COMPANY_ATTRIBUTE_BLANK]: {
    id: 'AddEmployeeModal.COMPANY_ATTRIBUTE_BLANK',
    defaultMessage: 'Veuillez ajouter un libellé',
    description: 'Veuillez ajouter un libellé',
  },
  [InviteEmployeeErrors.INVITE_EMPLOYEE_COMPANY_NOT_FOUND]: {
    id: 'AddEmployeeModal.COMPANY_NOT_FOUND',
    defaultMessage: 'Entreprise introuvable : contactez-nous',
    description: 'Entreprise introuvable : contactez-nous',
  },
  [InviteEmployeeErrors.INVITE_EMPLOYEE_UNAUTHORIZED]: {
    id: 'AddEmployeeModal.COMPANY_NOT_FOUND',
    defaultMessage: 'Entreprise introuvable : contactez-nous',
    description: 'Entreprise introuvable : contactez-nous',
  },
  [InviteEmployeeErrors.NOT_ADMIN_OF_COMPANY]: {
    id: 'AddEmployeeModal.NOT_ADMIN_OF_COMPANY',
    defaultMessage: "Désolé, vous n'avez pas les droits pour gérer cette entreprise",
    description: "Désolé, vous n'avez pas les droits pour gérer cette entreprise",
  },
});
