import { defineMessages } from 'react-intl';

export default defineMessages({
  nextAppointment: {
    id: 'ProfessionalHibernated.nextAppointment',
    defaultMessage: 'Prochain rendez-vous',
    description: 'label prochain RDV',
  },
  datePartial: {
    id: 'ProfessionalHibernated.datePartial',
    defaultMessage: 'à partir du {date}',
    description: 'à partir du ... placeholder pro hiberné',
  },
  temporarlyUnavailable: {
    id: 'ProfessionalHibernated.temporarlyUnavailable',
    defaultMessage: 'Absent temporairement',
    description: 'Absent temporairement label',
  },
});
