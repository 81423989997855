import { SettingsContext } from '../../../../contexts/settings-context/SettingsContext';
import React, { useContext } from 'react';
import { SearchFilter } from '../../../../scenes/search/Search.model';
import { FacetKeyEnum } from '../../../../@types/graphql.d';
import FilterButton from '../FilterButton';

type CompanySizesFilterButtonProps = {
  filters?: SearchFilter;
  onFilterChange: (filter: SearchFilter) => void;
};

export default function CompanySizesFilterButton({
  filters,
  onFilterChange: onFilterChangeCallback,
}: CompanySizesFilterButtonProps) {
  const settingsContext = useContext(SettingsContext);

  return (
    <FilterButton
      facetType={FacetKeyEnum.SecondarySituations}
      buckets={(settingsContext?.settings?.secondarySituations || []).map(({ key, name }) => ({
        key: key.toLowerCase(),
        label: name,
      }))}
      filters={filters}
      onFilterChange={onFilterChangeCallback}
    />
  );
}
