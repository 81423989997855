import { gql } from '@apollo/client';

export const GET_INTERLOCUTOR_SIMILAR_SEARCH_DATA_QUERY = gql`
  query GetInterlocutorSimilarSearchData($userId: ID!) {
    user(id: $userId) {
      professionalId
      premiumAmbassador
      premiumSchoolAmbassador
      companyName
      position
    }
  }
`;
