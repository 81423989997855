import { defineMessages } from 'react-intl';

export default defineMessages({
  reportDate: {
    id: 'CompanyReportDownload.reportDate',
    defaultMessage: 'Rapport complet de <span>{date}</span>',
  },
  noReport: {
    id: 'CompanyReportDownload.noReport',
    defaultMessage: 'Rapport complet non disponible',
  },
  downloadButton: {
    id: 'CompanyReportDownload.downloadButton',
    defaultMessage: 'Télécharger',
  },
  tooltip: {
    id: 'CompanyReportDownload.tooltip',
    defaultMessage:
      'Les rapports sont générés le 1er du mois : leurs données sont arrêtées au dernier jour du mois précédent',
  },
  error: {
    id: 'CompanyReportDownload.error',
    defaultMessage: "Erreur : le rapport n'a pas pu être téléchargé. Réessayez.",
  },
});
