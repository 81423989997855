import React, { Component, ComponentType, CSSProperties, PropsWithChildren, ReactElement, ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';

import classNames from 'classnames';

import './Card.scss';

import { IconProps } from '../../icons/component-icons/Icon';

type CardProps = PropsWithChildren<{
  icon?: ComponentType<IconProps>;
  title?: string;
  noPadding?: boolean;
  highlight?: boolean;
  fullHeight?: boolean;
  className?: string;
  style?: CSSProperties;
  header?: ComponentType;
}>;

export default function Card({
  icon: Icon,
  title,
  noPadding,
  highlight,
  fullHeight,
  className,
  style,
  header: Header,
  children,
}: CardProps) {
  return (
    <div
      className={classNames('mjg-card', {
        'mjg-card--no-padding': noPadding,
        'mjg-card--highlight': highlight,
        'mjg-card--full-height': fullHeight,
      })}
      style={style}
    >
      <div className={className}>
        {Header && <Header />}
        {(Icon || title) && (
          <h3 className="mjg-card__title">
            {Icon && <Icon className="mjg-card__title__icon" />}
            {title}
          </h3>
        )}
        {children}
      </div>
    </div>
  );
}

type CardLoaderProps = Omit<CardProps, 'title' | 'icon' | 'highlight'> & {
  noHeader?: boolean;
};

export const CardLoader = ({ className, children, noPadding, fullHeight, noHeader }: CardLoaderProps) => (
  <div
    className={classNames('mjg-card', {
      'mjg-card--no-padding': noPadding,
      'mjg-card--full-height': fullHeight,
    })}
  >
    <div className={className}>
      {!noHeader && (
        <h3 className="mjg-card__title">
          <Skeleton
            width={24}
            height={24}
            circle
          />
          <Skeleton containerClassName="skeleton-container-flex-1" />
        </h3>
      )}
      {children}
    </div>
  </div>
);
