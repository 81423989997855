import React from 'react';

import { useQuery } from '@apollo/client';
import { getMonthTranslationIdFromEnumIndex } from '../../../../../i18n/months.translations';
import CalendarPlusIcon from '../../../../../icons/component-icons/CalendarPlusIcon';
import moment from 'moment';
import { generatePath, useNavigate } from 'react-router';
import Routes from '../../../../../routes';
import {
  AmbassadorContactQuery,
  AmbassadorContactQueryVariables,
  InteractionServiceErrorEnum,
} from '../../../../../@types/graphql.d';
import CompleteProfileModal from '../complete-profile-modal/CompleteProfileModal';
import Card, { CardLoader } from '../../../../../components/card/Card';
import { CONTACT_QUERY } from './Contact.gql';
import './Contact.scss';
import { AmbassadorViewContactTranslations } from './Contact.translations';
import { getResponseTime } from '../../../../../utils/date';
import Skeleton from 'react-loading-skeleton';
import useModal from '../../../../../components/modal/Modal.hook';
import { CompleteProfileModalTranslations } from '../complete-profile-modal/CompleteProfileModal.translations';
import FrenchRestrictionModal from './FrenchRestrictionModal';
import { useIntl } from 'react-intl';
import { MJGFormattedMessage } from '../../../../../components/mjg-formatted-message/MJGFormattedMessage';

type ContactProps = {
  id: string;
};

export default function Contact({ id }: ContactProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const [openModal, closeModal] = useModal();

  const { data, loading } = useQuery<AmbassadorContactQuery, AmbassadorContactQueryVariables>(CONTACT_QUERY, {
    variables: { id },
    fetchPolicy: 'network-only',
  });

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  if (loading) return <ContactLoader />;
  if (!data?.getUser) return null;

  const {
    remainingMeetingsAsAmbassadorThisMonth,
    unavailableUntil,
    responseRateAsAmbassador,
    responseTimeAsAmbassador,
    currentUserCanContact,
    currentUserCanContactErrors,
    onlyFrenchWarning,
    onlyFrenchWarningConfirmText,
  } = data.getUser;

  const ambassadorUnavailable = currentUserCanContactErrors.includes(InteractionServiceErrorEnum.AmbassadorUnavailable);
  const profileIncomplete = currentUserCanContactErrors.includes(InteractionServiceErrorEnum.MemberProfileNotComplete);
  const noRemainingContact = currentUserCanContactErrors.includes(InteractionServiceErrorEnum.NoRemainingContact);

  const goToConversation = () => {
    closeModal();
    navigate(generatePath(Routes.conversationCreation, { id }));
  };

  const openCompleteYourProfileModal = () =>
    openModal({
      title: intl.formatMessage(CompleteProfileModalTranslations.title),
      content: <CompleteProfileModal />,
    });

  const openFrenchRestrictionModal = () => {
    if (!onlyFrenchWarningConfirmText) return;

    openModal({
      title: intl.formatMessage(CompleteProfileModalTranslations.title),
      content: (
        <FrenchRestrictionModal
          restrictionContent={onlyFrenchWarningConfirmText}
          onSubmit={goToConversation}
        />
      ),
    });
  };

  return (
    <section className="ambassador-view-contact">
      <Card
        icon={CalendarPlusIcon}
        title={intl.formatMessage(AmbassadorViewContactTranslations.title)}
        className="ambassador-view-contact__container"
        fullHeight
      >
        {profileIncomplete || currentUserCanContact ? (
          <div className="ambassador-view-contact__container__remaining">
            <div className="ambassador-view-contact__container__remaining__count">
              {remainingMeetingsAsAmbassadorThisMonth}
            </div>
            <div>
              <MJGFormattedMessage
                translation={AmbassadorViewContactTranslations.remaining}
                values={{ month: intl.formatMessage(getMonthTranslationIdFromEnumIndex(moment().month())) }}
              />
            </div>
          </div>
        ) : (
          <div className="ambassador-view-contact__container__reached-contact-limit">
            {ambassadorUnavailable && intl.formatMessage(AmbassadorViewContactTranslations.ambassadorUnavailable)}
            {noRemainingContact && intl.formatMessage(AmbassadorViewContactTranslations.noRemainingContact)}
          </div>
        )}
        <div>
          {ambassadorUnavailable && unavailableUntil && (
            <div
              className="ambassador-view-contact__container__unavailable-until"
            >
              <MJGFormattedMessage
                translation={AmbassadorViewContactTranslations.unavailableUntil}
                values={{ unavailableUntil: moment(unavailableUntil).format('DD/MM/YYYY') }}
              />
            </div>
          )}
          <div className="ambassador-view-contact__container__response-stats">
            <div className="ambassador-view-contact__container__response-stats__col">
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage(AmbassadorViewContactTranslations.responseRate),
                }}
              />
              <div className="ambassador-view-contact__container__response-stats__col__value">
                {responseRateAsAmbassador ? (responseRateAsAmbassador * 100).toFixed() + '%' : '-'}
              </div>
            </div>
            <div className="ambassador-view-contact__container__response-stats__col">
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage(AmbassadorViewContactTranslations.responseTime),
                }}
              />
              <div className="ambassador-view-contact__container__response-stats__col__value">
                {getResponseTime(responseTimeAsAmbassador) || '-'}
              </div>
            </div>
          </div>
        </div>
        {!ambassadorUnavailable ? (
          profileIncomplete ? (
            <button
              className="ambassador-view-contact__container__contact-button"
              onClick={openCompleteYourProfileModal}
            >
              {intl.formatMessage(AmbassadorViewContactTranslations.contact)}
            </button>
          ) : (
            <button
              data-cy="ambassador-view-contact__contact-button"
              className="ambassador-view-contact__container__contact-button"
              onClick={onlyFrenchWarning ? openFrenchRestrictionModal : goToConversation}
              disabled={!currentUserCanContact}
            >
              {intl.formatMessage(AmbassadorViewContactTranslations.contact)}
            </button>
          )
        ) : (
          <button
            className="ambassador-view-contact__container__contact-button"
            onClick={scrollToBottom}
          >
            {intl.formatMessage(AmbassadorViewContactTranslations.similarPros)}
          </button>
        )}
      </Card>
    </section>
  );
}

const ContactLoader = () => (
  <section className="ambassador-view-contact">
    <CardLoader
      fullHeight
      className="ambassador-view-contact__container"
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton
          width={64}
          height={76}
        />
      </div>
      <Skeleton count={2} />
      <Skeleton
        height={100}
        borderRadius={8}
      />
      <Skeleton
        height={32}
        borderRadius={50}
      />
    </CardLoader>
  </section>
);
