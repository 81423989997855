import { CompanyReportRange } from './graphql';
import { CheckTokenJsonApiErrors } from './jsonapi';
import { SignupFlow } from './signup';

export enum ProfessionalCardReferrer {
  Search = 'SEARCH',
  Wishlist = 'WISHLIST',
}

export interface SignupRouteParams {
  flow: SignupFlow | 'employee';
}

export interface SchoolAssignmentsDashboardParams {
  id?: string;
}

export interface SignupSearchParams {
  signup?: SignupFlow | 'employee';
  scroll?: 'form' | undefined;
  invitation_code?: string | undefined;
}

export interface CompanyViewParams {
  employeeid?: string;
  companyid?: string;
  id?: string;
}

export interface CompanyDashboardSearchParams {
  range?: CompanyReportRange;
}

export interface DashboardProgramAcceptInvitationParams {
  invitationId?: string;
}

export interface CompanyAdminAppointmentDetailsParams {
  companyid?: string;
  appointmentid?: string;
}

export interface FDRRouteParams {
  assignment_affiliation_token?: string;
}

export interface ProfessionalViewParams extends Record<string, string | undefined> {
  id: string;
}

export interface SearchParams extends SignupSearchParams, FDRRouteParams {}

export interface FDRConfigurationFormParams {
  school_name?: string;
  diploma_level?: string;
  campus_name?: string;
  custom_identifier?: string;
  supervisor_email?: string;
  starts_at?: string;
  ends_at?: string;
  academic_year?: string;
  number_of_mandatory_appointments_per_student?: string;
  appointments_bonus_threshold?: string;
  email_reminder_activated?: string;
  conversation_bonus_activated?: string;
  conversation_penalty_activated?: string;
  description?: string;
  appointment_bonus_threshold1?: string;
  appointment_bonus_threshold2?: string;
  email_reminder_weekly_rate?: string;
  email_reminder_language?: string;
  conversation_bonus_message_count?: string;
  conversation_bonus_deadline?: string;
}

export interface CompanyContactFormParams {
  email?: string;
}
export interface ConversationParams {
  id?: string;
}
export interface DownloadConversationAppointmentIcalendarParams extends ConversationParams {
  appointment_id?: string;
}
export interface ConversationAppointmentBookerParams extends ConversationParams {
  appointment_id?: string;
}

export interface SigninRouteParams {
  passwordChanged?: boolean;
  signUpTokenError: keyof typeof CheckTokenJsonApiErrors;
}

export interface MentorModerationParams {
  id?: string;
}
