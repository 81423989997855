import React from 'react';
import FilterButton from '../FilterButton';
import { FacetKeyEnum } from '../../../../@types/graphql.d';
import { SearchFilter } from '../../../../scenes/search/Search.model';
import {
  COMPANY_SIZES_BUCKETS_KEYS,
  composeCompanySizesFilter,
  uncomposeCompanySizesFilter,
} from './CompanySizesFilterButton.utils';
import { useIntl } from 'react-intl';

type CompanySizesFilterButtonProps = {
  filters?: SearchFilter;
  onFilterChange: (filter: SearchFilter) => void;
};

export default function CompanySizesFilterButton({
  filters,
  onFilterChange: onFilterChangeCallback,
}: CompanySizesFilterButtonProps) {
  const intl = useIntl();
  return (
    <FilterButton
      facetType={FacetKeyEnum.CompanySizes}
      buckets={COMPANY_SIZES_BUCKETS_KEYS.map((b) => ({
        label: intl.formatMessage(b.label),
        key: b.keys[b.keys.length - 1],
      }))}
      filters={filters && uncomposeCompanySizesFilter(filters)}
      onFilterChange={(filter: SearchFilter) => {
        onFilterChangeCallback(composeCompanySizesFilter(filter));
      }}
    />
  );
}
