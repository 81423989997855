import { gql } from '@apollo/client';

export const GET_COMPANY_CUSTOM_ATTRIBUTES = gql`
  query GET_COMPANY_CUSTOM_ATTRIBUTES($companyId: ID!) {
    company(companyId: $companyId) {
      id
      isPremiumSchool
      customAttributesSet {
        customEmployeeAttribute1
        companyAttributeValues1
        customEmployeeAttribute2
        companyAttributeValues2
        customEmployeeAttribute3
        companyAttributeValues3
      }
    }
  }
`;
