import { gql } from '@apollo/client';

export const GET_COMPANY_EMPLOYEE_APPOINTMENTS_TOTAL = gql`
  query GET_COMPANY_EMPLOYEE_APPOINTMENTS_TOTAL($companyId: ID!) {
    companyEmployeeAppointments(companyId: $companyId) {
      totalCount
    }
  }
`;

export const GET_COMPANY_TYPE = gql`
  query GET_COMPANY_TYPE($companyId: ID!) {
    company(companyId: $companyId) {
      isPremiumSchool
      name
    }
  }
`;

export const GET_COMPANY_EMPLOYEE_APPOINTMENTS = gql`
  query GET_COMPANY_EMPLOYEE_APPOINTMENTS($companyId: ID!, $page: Int, $perPage: Int) {
    companyEmployeeAppointments(companyId: $companyId, page: $page, perPage: $perPage) {
      appointments {
        id
        from
        professional {
          id
          firstName
          lastName
          email
          avatar
        }
        member {
          firstName
          lastName
        }
        reviewFromProfessional {
          appointmentStatus
          answer1
          answer2
          answer3
          messageForHr
        }
        reviewFromStudent {
          appointmentStatus
          answer1
          answer2
          answer3
          wantToBeRecontacted
          wantsToBeRecontactedBySchool
          aspiration {
            type
            duration
            startsAtMonth
            startsAtYear
            postalCode
          }
        }
      }
    }
  }
`;
