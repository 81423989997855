import { useQuery } from '@apollo/client';
import React from 'react';
import CheckCircleBrokenIcon from '../../../../../icons/component-icons/CheckCircleBrokenIcon';
import { GetActivityStatsQuery } from '../../../../../@types/graphql.d';
import DashboardCard, { DashboardCardLoader } from '../../components/dashboard-card/DashboardCard';
import { GET_ACTIVITY_STATS_QUERY } from '../../DashboardView.gql';
import { ActivityTranslations } from './Activity.translations';
import { DashboardMode } from '../../DashboardView.types';
import { Statistic, getStatisticsByMode } from './activityConfig';
import './Activity.scss';
import Skeleton from 'react-loading-skeleton';
import { useIntl } from 'react-intl';

type ActivityProps = {
  dashboardMode: DashboardMode;
};

export default function Activity({ dashboardMode }: ActivityProps) {
  const intl = useIntl();
  const { data, loading } = useQuery<GetActivityStatsQuery>(GET_ACTIVITY_STATS_QUERY, { fetchPolicy: 'network-only' });

  if (loading) return <ActivityLoader />;
  if (!data?.statistics) return null;

  const primarySituationKey = data?.situation?.primarySituation?.key;

  const stats = getStatisticsByMode(intl, dashboardMode, data?.statistics).filter(
    stat => !stat.primarySituation || (primarySituationKey && stat.primarySituation.includes(primarySituationKey))
  );
  
  return (
    <DashboardCard
      icon={CheckCircleBrokenIcon}
      title={intl.formatMessage(ActivityTranslations.title)}
    >
      <div className="dashboard-activity">
        {stats.map((stat, index) => (
          <CountTag
            key={index}
            {...stat}
          />
        ))}
      </div>
    </DashboardCard>
  );
}

type CountTagProps = Statistic;

const CountTag = ({ icon: Icon, count, text, color, background }: CountTagProps) => (
  <div
    className="dashboard-activity__count-tag"
    style={{
      backgroundColor: `${background}66`,
      stroke: `${background}EE`,
      color,
    }}
  >
    <Icon />
    <div className="dashboard-activity__count-tag__content">
      <div className="dashboard-activity__count-tag__content__count">{count === '0%' ? '-' : count}</div>
      <div className="dashboard-activity__count-tag__content__text">{text}</div>
    </div>
  </div>
);

const ActivityLoader = () => (
  <DashboardCardLoader>
    <div className="dashboard-activity">
      <CountTagLoader />
      <CountTagLoader />
      <CountTagLoader />
      <CountTagLoader />
      <CountTagLoader />
    </div>
  </DashboardCardLoader>
);

const CountTagLoader = () => (
  <div
    className="dashboard-activity__count-tag"
    style={{
      backgroundColor: '#fafafa',
    }}
  >
    <div className="dashboard-activity__count-tag__content">
      <div className="dashboard-activity__count-tag__content__count">
        <Skeleton width={24} />
      </div>
      <div className="dashboard-activity__count-tag__content__text">
        <Skeleton width={64} />
      </div>
    </div>
  </div>
);
