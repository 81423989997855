import React from 'react';
import { IntercomProvider } from 'react-use-intercom';

const INTERCOM_APP_ID = import.meta.env.REACT_APP_INTERCOM_APP_ID;
const INTERCOM_API_BASE =
  import.meta.env.REACT_APP_INTERCOM_EU === 'true' ? 'https://api-iam.eu.intercom.io' : 'https://api-iam.intercom.io';

interface IntercomContainer {
  children: React.ReactNode;
}

const OptionalIntercomProvider = ({ children }: IntercomContainer) => {
  if (!INTERCOM_APP_ID) {
    return <>{children}</>;
  }

  return (
    <IntercomProvider
      appId={INTERCOM_APP_ID}
      apiBase={INTERCOM_API_BASE}
      initializeDelay={1000}
    >
      {children}
    </IntercomProvider>
  );
};

export default OptionalIntercomProvider;
