import { Area } from 'react-easy-crop';

export async function getCroppedImage(imageSrc: string, pixelCrop: Area): Promise<Blob | null> {
  const image = new Image();
  image.src = imageSrc;
  if (image.width === 0 || image.height === 0) return null;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) return null;

  canvas.width = image.width;
  canvas.height = image.height;

  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement('canvas');
  const croppedCtx = croppedCanvas.getContext('2d');
  if (!croppedCtx) return null;

  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  applyBackground(croppedCanvas, croppedCtx);
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  );

  return new Promise((resolve) => {
    croppedCanvas.toBlob((file) => {
      resolve(file);
    }, 'image/jpeg');
  });
}

// Usefull for cropping images with transparent background
function applyBackground(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, color: string = '#fff') {
  ctx.fillStyle = '#fff';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
}

export function fillSpaceToMakeImageSquare(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, image: HTMLImageElement): string {
  const size = Math.max(image.width, image.height);
  canvas.width = size;
  canvas.height = size;
  const { x, y } = getCenteredPosition(size, image.width, image.height);
  ctx.clearRect(0, 0, size, size);
  ctx.drawImage(image, x, y);
  return canvas.toDataURL('image/png');
}

function getCenteredPosition(size: number, imageWidth: number, imageHeight: number): { x: number, y: number } {
  const x = size / 2 - imageWidth / 2;
  const y = size / 2 - imageHeight / 2;
  return { x, y };
}