import React, { ChangeEvent, useState } from 'react';
import { acceptedImageTypes, MAX_FILE_SIZE } from '../../../utils/upload';
import {
  CreateUploadFileLinkMutation,
  CreateUploadFileLinkMutationVariables,
  UploadFormatEnum,
  UploadLinkKindEnum,
} from '../../../@types/graphql.d';
import { useMutation } from '@apollo/client';
import { CREATE_UPLOAD_FILE_LINK_MUTATION } from './FileUploader.gql';
import { defineMessages, useIntl } from 'react-intl';

type FileUploaderProps = {
  onUploadSuccess: (uploadId: string) => void;
  kind: UploadLinkKindEnum;
  inputRef: React.RefObject<HTMLInputElement>;
};

const translations = defineMessages({
  invalidType: {
    id: 'FileUploader.invalidType',
    defaultMessage: 'Type de fichier invalide',
  },
  fileTooLarge: {
    id: 'FileUploader.fileTooLarge',
    defaultMessage: 'Fichier trop volumineux',
  },
  failedToGetLink: {
    id: 'FileUploader.failedToGetLink',
    defaultMessage: 'Echec de récupération du lien',
  },
  uploadError: {
    id: 'FileUploader.error',
    defaultMessage: "Erreur lors de l'upload du fichier",
  },
});

const FileUploader = ({ onUploadSuccess, kind, inputRef }: FileUploaderProps) => {
  const intl = useIntl();
  const [createUploadLink] = useMutation<CreateUploadFileLinkMutation, CreateUploadFileLinkMutationVariables>(
    CREATE_UPLOAD_FILE_LINK_MUTATION,
  );
  const [error, setError] = useState<string | null>(null);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];
    if (!file) return;

    const validImageTypes = acceptedImageTypes();
    if (!validImageTypes.includes(file.type)) {
      setError(intl.formatMessage(translations.invalidType));
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      setError(intl.formatMessage(translations.fileTooLarge));
      return;
    }

    const fileType = file.type.split('/')[1].toUpperCase();
    try {
      const { data } = await createUploadLink({
        variables: {
          kind,
          format: fileType as UploadFormatEnum,
        },
      });

      if (!data?.createUploadLink?.awsUrl) {
        throw new Error(intl.formatMessage(translations.failedToGetLink));
      }

      await fetch(data.createUploadLink.awsUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      });

      onUploadSuccess(data.createUploadLink.id);
      setError(null);
    } catch (err) {
      setError(intl.formatMessage(translations.uploadError));
    }
    event.target.value = '';
  };

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        accept={acceptedImageTypes().join(', ')}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      {error && <span className="select-input__error">{error}</span>}
    </>
  );
};

export default FileUploader;
