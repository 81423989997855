import React from 'react';

import './NoResult.scss';
import { NoResultTranslations } from './NoResult.translations';
import { useIntl } from 'react-intl';

export default function NoResult() {
  const intl = useIntl();
  return (
    <div className="no-result">
      <div className="no-result__title">{intl.formatMessage(NoResultTranslations.title)}</div>
      <div className="no-result__subtitle">{intl.formatMessage(NoResultTranslations.subtitle)}</div>
      <div className="no-result__tips">
        <label>{intl.formatMessage(NoResultTranslations.tipsLabel)}</label>
        <ul>
          <li>{intl.formatMessage(NoResultTranslations.tip1)}</li>
          <li>{intl.formatMessage(NoResultTranslations.tip2)}</li>
          <li>{intl.formatMessage(NoResultTranslations.tip3)}</li>
          <li>{intl.formatMessage(NoResultTranslations.tip4)}</li>
        </ul>
      </div>
    </div>
  );
}
