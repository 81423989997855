import React, { ComponentType, CSSProperties, DetailedHTMLProps, useMemo, useState } from 'react';

import classNames from 'classnames';

import './TextInput.scss';

import { IconProps } from '../../icons/component-icons/Icon';

type DefaultInputProps = Pick<
  DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'value' | 'onFocus' | 'onBlur' | 'placeholder' | 'type' | 'maxLength'
>;
type TextInputProps = DefaultInputProps & {
  icon?: ComponentType<IconProps>;
  name?: string;
  label?: string;
  error?: string;
  disabled?: boolean;
  className?: string;
  bigLabel?: boolean;
  style?: CSSProperties;
};

export default function TextInput({
  icon: Icon,
  name,
  label,
  value,
  onChange,
  onFocus,
  onBlur,
  placeholder,
  error,
  type,
  disabled,
  className,
  bigLabel,
  maxLength,
  style,
}: TextInputProps) {
  const [isFocused, setIsFocused] = useState(false);

  const maxLengthError = useMemo(() => {
    if (!maxLength || !value) return false;
    if (typeof value !== 'string')
      throw new Error('Value is not a string and cannot be computed with the maxLength props.');
    return value.length >= maxLength;
  }, [maxLength, value]);

  function handleFocus(event: any) {
    setIsFocused(true);
    onFocus?.(event);
  }

  function handleBlur(event: any) {
    setIsFocused(false);
    onBlur?.(event);
  }

  return (
    <div className={classNames(bigLabel ? 'text-input--big-label' : 'text-input', className)}>
      {label && (
        <label
          htmlFor={name}
          className={classNames({ error })}
        >
          {label}
        </label>
      )}

      <div
        className={classNames(
          'text-input__main',
          { 'text-input__main--focused': isFocused },
          { 'text-input__main--error': error || maxLengthError },
          { 'text-input__main--disabled': disabled },
        )}
        style={style}
      >
        {Icon && <Icon className="text-input__main__icon" />}
        <input
          id={name}
          type={type || 'text'}
          {...{
            name,
            value,
            onChange,
            placeholder,
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
        />
      </div>

      {error && <span className="text-input__error">{error}</span>}
    </div>
  );
}
