import { gql } from '@apollo/client';

export const GET_COMPANIES_LIST = gql`
  query GET_COMPANIES_LIST($asAdmin: Boolean) {
    companies(asAdmin: $asAdmin) {
      id
      name
    }
  }
`;
