import React, { CSSProperties, memo, PropsWithChildren } from 'react';
import classNames from 'classnames';

import './Layout.scss';

type Props = PropsWithChildren<{
  title?: string;
  subQuestion?: string;
  alignment?: 'center' | 'left';
  style?: CSSProperties;
}>;

export const Layout: React.FC<Props> = memo(({ title, subQuestion, alignment = 'left', style, children }) => (
  <div className={classNames('review-pane-layout', `review-pane-layout--alignment-${alignment}`)} style={style}>
    {title && <h1>{title}</h1>}
    {subQuestion && <p className="review-pane-layout__sub-question">{subQuestion}</p>}

    {children}
  </div>
));
