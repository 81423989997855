import React, { memo } from 'react';
import { Layout } from './components/layout/Layout';
import StarRadioInput from '../../../../components/star-radio-input/StarRadioInput';
import { PanelProps } from './Panel';
import { useReviewContext } from '../ReviewContext';
import {  useQuery } from '@apollo/client';
import { GetPerceptionQuestionForReviewQuery, GetPerceptionQuestionForReviewQueryVariables, GetPerceptionQuestionQuery, GetPerceptionQuestionQueryVariables } from '../../../../@types/graphql';
import { GET_PERCEPTION_QUESTION_FOR_REVIEW } from '../Review.gql';
import { useLanguage } from '../../../../hooks/useLanguages';

export const PerceptionPanel: React.FC<PanelProps> = memo(({ appointment }) => {
  const { reviewFormik } = useReviewContext();
  const { language } = useLanguage();

  const { data: perceptionData } = useQuery<GetPerceptionQuestionForReviewQuery, GetPerceptionQuestionForReviewQueryVariables>(
    GET_PERCEPTION_QUESTION_FOR_REVIEW, {
      variables: { professionalId: appointment.interlocutor.id || '' }, 
      onCompleted: (data) => { 
        if (!data) return;
        if (reviewFormik.values.perception) return;
        reviewFormik.setFieldValue('perception', {id: data.perceptionQuestions?.id, answers: {}});
      }
    }
  );

  if (!perceptionData) return null;
  const sortedAttributes = perceptionData?.perceptionQuestions?.questionAttributes?.slice().sort((a, b) => (a?.order || 0) - (b?.order || 0));

  return (
    <Layout
      title={perceptionData?.perceptionQuestions?.label || ''}
      style={{ paddingTop: 80 }}
    >
      {sortedAttributes?.map((attribute) => (
        <StarRadioInput
          key={attribute.id}
          name={`perception_question_${attribute.identifier}`}
          label={language === 'en' ? (attribute.labelEn || '') : (attribute.labelFr || '')}
          value={reviewFormik.values.perception?.answers[attribute.id]?.toString() || ''}
          onSelection={(value) => {
            const answers = reviewFormik.values.perception?.answers || {};
            answers[attribute.id] = Number(value);
            reviewFormik.setFieldValue('perception.answers',answers);
          }}
          inline
      />
      ))}
    </Layout>
  );
});
