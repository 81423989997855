import { defineMessages } from 'react-intl';
import { UpdateCustomAttributeMutationErrors } from '../../../../../../../@types/graphql-errors.d';

export const errorTranslation = defineMessages({
  [UpdateCustomAttributeMutationErrors.UPDATE_CUSTOM_ATTRIBUTES_CUSTOM_EMPLOYEE_ATTRIBUTE_TOO_LONG]: {
    id: 'CompanyAttributesWrite.UPDATE_CUSTOM_ATTRIBUTES_CUSTOM_EMPLOYEE_ATTRIBUTE_TOO_LONG',
    defaultMessage: 'Le nom du libellé ne peut excéder 30 caractères',
  },

  [UpdateCustomAttributeMutationErrors.CUSTOM_EMPLOYEE_ATTRIBUTE_ALREADY_USED]: {
    id: 'CompanyAttributesWrite.CUSTOM_EMPLOYEE_ATTRIBUTE_ALREADY_USED',
    defaultMessage: 'Nom invalide : ce libellé existe déjà',
  },

  [UpdateCustomAttributeMutationErrors.UPDATE_CUSTOM_ATTRIBUTE_CUSTOM_EMPLOYEE_ATTRIBUTE_NOT_FOUND]: {
    id: 'CompanyAttributesWrite.UPDATE_CUSTOM_ATTRIBUTE_CUSTOM_EMPLOYEE_ATTRIBUTE_NOT_FOUND',
    defaultMessage: 'Erreur inconnue. Contactez-nous.',
  },
  [UpdateCustomAttributeMutationErrors.UPDATE_CUSTOM_ATTRIBUTE_COMPANY_NOT_FOUND]: {
    id: 'CompanyAttributesWrite.UPDATE_COMPANY_COMPANY_NOT_FOUND',
    defaultMessage: 'Entreprise introuvable. Contactez-nous.',
  },
});

export default defineMessages({
  labelName: {
    id: 'CompanyAttributesWrite.labelName',
    defaultMessage: 'Nom du libellé',
  },
  counter: {
    id: 'CompanyAttributesWrite.counter',
    defaultMessage: '<span class="highlight">{current}</span>/{max} caractères',
  },
  listValues: {
    id: 'CompanyAttributesWrite.listValues',
    defaultMessage: 'Listez les valeurs que ce libellé peut avoir.',
  },
  sample: {
    id: 'CompanyAttributesWrite.sample',
    defaultMessage: 'Ex :',
  },
  marketing: {
    id: 'CompanyAttributesWrite.marketing',
    defaultMessage: 'marketing',
  },
  finance: {
    id: 'CompanyAttributesWrite.finance',
    defaultMessage: 'finance',
  },
  hr: {
    id: 'CompanyAttributesWrite.hr',
    defaultMessage: 'RH',
  },
  possibleValue: {
    id: 'CompanyAttributesWrite.possibleValue',
    defaultMessage: 'Valeur possible',
  },
  helper: {
    id: 'CompanyAttributesWrite.helper',
    defaultMessage: 'Pour ajouter cette valeur, appuyez sur "Entrée" (⏎)',
  },
  cancelButton: {
    id: 'CompanyAttributesWrite.cancelButton',
    defaultMessage: 'Annuler',
  },
  submitButton: {
    id: 'CompanyAttributesWrite.submitButton',
    defaultMessage: 'Valider',
  },
});
