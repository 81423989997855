import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    id: 'StudentClaimAppointmentButton.label',
    defaultMessage: 'Rendez-vous passé',
    description: 'Label du bouton pour réclamer un RDV',
  },
  labelHover: {
    id: 'StudentClaimAppointmentButton.label.hover',
    defaultMessage: 'Demander une confirmation de rendez-vous passé',
    description: 'Label du bouton pour réclamer un RDV (hover)',
  },
});
