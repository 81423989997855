import React from 'react';
import classnames from 'classnames';
import { useFormikContext } from 'formik';
import { GraphqlGeneralErrors } from '../../../../../@types/graphql-errors.d';
import localTranslations from './FormikStatusError.translations';
import styles from './FormikStatusError.module.scss';
import { useIntl } from 'react-intl';

interface FormikStatusErrorProps {
  translations?: ReactIntl.Messages;
  className?: string;
}

function FormikStatusError({ translations, className }: FormikStatusErrorProps) {
  const intl = useIntl();
  const { status } = useFormikContext();
  if (status === GraphqlGeneralErrors.GRAPHQL_NO_NETWORK) {
    return (
      <p className={classnames(styles.error, className)}>
        {intl.formatMessage(localTranslations[GraphqlGeneralErrors.GRAPHQL_NO_NETWORK])}
      </p>
    );
  }
  if (status && translations?.[status]) {
    return <p className={classnames(styles.error, className)}>{intl.formatMessage(translations[status])}</p>;
  }
  if (status) {
    return (
      <p className={classnames(styles.error, className)}>
        {intl.formatMessage(localTranslations[GraphqlGeneralErrors.GRAPHQL_UNKNOWN])}
      </p>
    );
  }
  return null;
}

export default FormikStatusError;
