import { gql } from '@apollo/client';

export const GET_APPOINTMENT_LIST = gql`
  query GET_APPOINTMENT_LIST($after: String, $filters: AppointmentsFilter, $sort: [AppointmentsSortFieldsEnum!]) {
    appointments(first: 10, after: $after, filters: $filters, sort: $sort) {
      edges {
        node {
          id
          type
          from
          startAt
          conversationId
          googleCalendarUrl
          yahooCalendarUrl
          meetingPlace
          currentUserCanReview
          currentUserReviewMessage
          currentUserInterlocutorReviewMessage
          currentUserRecommendInterlocutor
          currentUserRecommendedByInterlocutor
          conversation {
            id
            currentUserIsInitiator
          }
          currentUserInterlocutor {
            firstName
            companyName
            jobTitle
            schoolName
            previousCompanyName
            previousPosition
            primarySituation {
              key
            }
            normalizedPhone
            avatars {
              small {
                url
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
