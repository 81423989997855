import { defineMessages } from 'react-intl';

export default defineMessages({
  activeRate: {
    id: 'useStatusSegments.activeRate',
    defaultMessage: '{rate}% ({count}) profils actifs',
  },
  atRikRate: {
    id: 'useStatusSegments.atRikRate',
    defaultMessage: '{rate}% ({count}) profils à risques',
  },
  inactiveRate: {
    id: 'useStatusSegments.inactiveRate',
    defaultMessage: '{rate}% ({count}) profil inactifs',
  },
  hibernatedRate: {
    id: 'useStatusSegments.hibernatedRate',
    defaultMessage: '{rate}% ({count}) profils hibernés',
  },
  approvedRate: {
    id: 'useStatusSegments.approvedRate',
    defaultMessage: '{rate}% ({count}) profils approuvés',
  },
  completeRate: {
    id: 'useStatusSegments.completeRate',
    defaultMessage: '{rate}% ({count}) profils complets',
  },
  incompleteRate: {
    id: 'useStatusSegments.incompleteRate',
    defaultMessage: '{rate}% ({count}) profils incomplets',
  },
  invitedRate: {
    id: 'useStatusSegments.invitedRate',
    defaultMessage: '{rate}% ({count}) profils invité(e)s',
  },
  unpublishedRate: {
    id: 'useStatusSegments.unpublishedRate',
    defaultMessage: '{rate}% ({count}) profils dépubliés',
  },
  availablePlacesRate: {
    id: 'useStatusSegments.availablePlacesRate',
    defaultMessage: '{rate}% ({count}) places disponibles',
  },
});
