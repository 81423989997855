import React from 'react';
import classnames from 'classnames';
import WithTooltip from '../with-tooltip/WithTooltip';

import styles from './Help.module.scss';

interface HelpProps {
  text: string;
  buttonClassName?: string;
  position?: 'bottom' | 'bottom-left' | 'bottom-right';
  variant?: 'blue' | 'blue-dark';
}
function Help({ text, buttonClassName, position = 'bottom', variant = 'blue' }: HelpProps): JSX.Element {
  return (
    <WithTooltip
      text={text}
      className={styles.tooltipWrapper}
      tooltipClassName={classnames(styles.tooltip, {
        [styles.tooltipBottomLeft]: position === 'bottom-left',
        [styles.tooltipBottom]: position === 'bottom',
        [styles.tooltipBottomRight]: position === 'bottom-right',
        [styles.blueVariant]: variant === 'blue',
        [styles.blueDarkVariant]: variant === 'blue-dark',
      })}
    >
      {({ openTooltip, closeTooltip }) => (
        <button
          data-testid="help-tooltip"
          type="button"
          onMouseEnter={openTooltip}
          onFocus={openTooltip}
          onMouseLeave={closeTooltip}
          onBlur={closeTooltip}
          className={classnames(styles.helpButton, buttonClassName)}
        >
          ?
        </button>
      )}
    </WithTooltip>
  );
}

export default Help;
