import { defineMessages } from "react-intl";
import { AppointmentDidNotTookPlaceReasonEnum } from "../@types/graphql.d";

export const AppointmentDidNotTookPlaceReasonTranslations = defineMessages({
  [AppointmentDidNotTookPlaceReasonEnum.DidNotShowUp]: {
    id: 'AppointmentDidNotTookPlaceReason.DidNotShowUp',
    defaultMessage: "{asMember, select, true {L'Ambassadeur} other {Le Membre}} n'est pas venu au rendez-vous"
  },
  [AppointmentDidNotTookPlaceReasonEnum.Cancelled]: {
    id: 'AppointmentDidNotTookPlaceReason.Cancelled',
    defaultMessage: "{asMember, select, true {L'Ambassadeur} other {Le Membre}} m'a prévenu de son absence"
  },
  [AppointmentDidNotTookPlaceReasonEnum.Rescheduled]: {
    id: 'AppointmentDidNotTookPlaceReason.Rescheduled',
    defaultMessage: "Nous avons décalé le rendez-vous"
  },
  [AppointmentDidNotTookPlaceReasonEnum.WasNotScheduled]: {
    id: 'AppointmentDidNotTookPlaceReason.WasNotScheduled',
    defaultMessage: "Nous n'avions pas planifié de rendez-vous"
  },
  [AppointmentDidNotTookPlaceReasonEnum.ForgotToGo]: {
    id: 'AppointmentDidNotTookPlaceReason.ForgotToGo',
    defaultMessage: "J'ai oublié le rendez-vous"
  },
  [AppointmentDidNotTookPlaceReasonEnum.Other]: {
    id: 'AppointmentDidNotTookPlaceReason.Other',
    defaultMessage: "Autre"
  }
});