import { gql } from '@apollo/client';

export const CONTACT_QUERY = gql`
  query AmbassadorContact($id: ID!) {
    getUser(id: $id) {
      remainingMeetingsAsAmbassadorThisMonth
      responseRateAsAmbassador
      responseTimeAsAmbassador
      currentUserCanContact
      currentUserCanContactErrors
      unavailableUntil
      onlyFrenchWarning
      onlyFrenchWarningConfirmText
    }
  }
`;
