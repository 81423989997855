import React from 'react';
import { getAccountUrl } from '../../../../../utils/redirection.util';
import { CompleteProfileModalTranslations } from './CompleteProfileModal.translations';
import useModal from '../../../../../components/modal/Modal.hook';
import ButtonsGroup from '../../../../../components/buttons-group/ButtonsGroup';
import SecondaryButton from '../../../../../components/secondary-button/SecondaryButton';
import PrimaryButton from '../../../../../components/primary-button/PrimaryButton';
import { useIntl } from 'react-intl';

export default function CompleteProfileModal() {
  const intl = useIntl();
  const [, closeModal] = useModal();

  const redirectToAccount = () => {
    window.location.href = getAccountUrl('/?context=contact');
    closeModal();
  };

  return (
    <div>
      <p>{intl.formatMessage(CompleteProfileModalTranslations.body)}</p>

      <ButtonsGroup>
        <SecondaryButton
          label={intl.formatMessage(CompleteProfileModalTranslations.closeButton)}
          onClick={closeModal}
        />
        <PrimaryButton
          label={intl.formatMessage(CompleteProfileModalTranslations.primaryButton)}
          onClick={redirectToAccount}
        />
      </ButtonsGroup>
    </div>
  );
}
