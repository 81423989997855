import React, { useState } from 'react';
import classnames from 'classnames';
import styles from './WithTooltip.module.scss';

interface WithTooltipProps {
  text: string;
  children: (params: { closeTooltip: () => void; openTooltip: () => void }) => JSX.Element;
  className?: string;
  tooltipClassName?: string;
}

function WithTooltip({ text, children, className, tooltipClassName }: WithTooltipProps) {
  const [isVisibleTooltip, setIsVisibleTooltip] = useState(false);
  const closeTooltip = () => setIsVisibleTooltip(false);
  const openTooltip = () => setIsVisibleTooltip(true);
  return (
    <div
      data-testid="with-tooltip"
      className={classnames(styles.wrapper, className)}
    >
      {children({ closeTooltip, openTooltip })}
      {isVisibleTooltip && (
        <div
          data-testid="with-tooltip-text"
          className={classnames(styles.tooltip, tooltipClassName)}
        >
          {text}
        </div>
      )}
    </div>
  );
}

export default WithTooltip;
