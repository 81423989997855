import React, { useContext } from 'react';
import CalendarPlusIcon from '../../../../../icons/component-icons/CalendarPlusIcon';
import classnames from 'classnames';
import PublicProfileAvatarDisplay from '../../../../common/public-profile-avatar-display/PublicProfileAvatarDisplay';
import { ConversationViewContext } from '../../context/ConversationViewContext';
import BackToConversationsListButton from '../back-to-conversations-list-button/BackToConversationsListButton';
import translations from './InterlocutorMobileOverview.translations';

import styles from './InterlocutorMobileOverview.module.scss';
import useModal from '../../../../../components/modal/Modal.hook';
import { AppointmentCreateModalTranslations } from '../../../../../scenes/interactions/hooks/components/appointment-modal/AppointmentModal.translations';
import { BookAppointmentModal } from '../../../../../scenes/interactions/hooks/components/appointment-modal/AppointmentModal';
import { useIntl } from 'react-intl';

function InterlocutorMobileOverview() {
  const intl = useIntl();
  const { openMobileInterlocutorPanel, conversation } = useContext(ConversationViewContext);
  const [openModal] = useModal();

  const interlocutor = conversation.interlocutorV2;
  if (!interlocutor) {
    return null;
  }

  const openBookAppointmentModal = () =>
    openModal({
      title: intl.formatMessage(AppointmentCreateModalTranslations.title),
      content: <BookAppointmentModal conversation={conversation} />,
    });

  return (
    <header className={styles.interlocutorMobileOverview}>
      <BackToConversationsListButton />
      <PublicProfileAvatarDisplay
        publicProfile={interlocutor.softDeleted ? undefined : interlocutor}
        className={styles.avatar}
      />
      <h2 className={styles.interlocutorFirstname}>
        {interlocutor.softDeleted ? intl.formatMessage(translations.deletedUser) : interlocutor?.firstName}
      </h2>
      <aside className={styles.actions}>
        {conversation.currentUserCanBookAppointment && (
          <button
            type="button"
            onClick={openBookAppointmentModal}
            className={styles.bookAppointmentButton}
          >
            <CalendarPlusIcon />
          </button>
        )}
        <button
          type="button"
          className={classnames(styles.interlocutorMoreInformations, {
            [styles.interlocutorMoreInformationsSoftDeleted]: interlocutor.softDeleted,
          })}
          onClick={openMobileInterlocutorPanel}
        >
          i
        </button>
      </aside>
    </header>
  );
}

export default InterlocutorMobileOverview;
