import React, { useContext } from 'react';
import moment from 'moment';
import {
  FutureAppointmentDisplay,
  AppointmentClaimDisplay,
  PastAppointmentDisplay,
} from './component/appointment-display/AppointmentDisplay';
import NoAppointment from './component/no-appointment/NoAppointment';
import { ConversationViewContext } from '../../context/ConversationViewContext';
import Loader from '../../../../../components/loader/Loader';
import { useConversationAppointments, Appointment } from './hooks/useConversationAppointments';
import { useConversationAppointmentClaims } from './hooks/useConversationAppointmentClaims';
import translations from './AppointmentHub.translations';

import './AppointmentHub.scss';
import useModal from '../../../../../components/modal/Modal.hook';
import { AppointmentCreateModalTranslations } from '../../../../../scenes/interactions/hooks/components/appointment-modal/AppointmentModal.translations';
import { BookAppointmentModal } from '../../../../../scenes/interactions/hooks/components/appointment-modal/AppointmentModal';
import { generatePath, useNavigate } from 'react-router';
import Routes from '../../../../../routes';
import { useIntl } from 'react-intl';

function BookAppointmentButton() {
  const intl = useIntl();
  const [openModal] = useModal();
  const { conversation } = useContext(ConversationViewContext);

  const openBookAppointmentModal = () => {
    openModal({
      title: intl.formatMessage(AppointmentCreateModalTranslations.title),
      content: <BookAppointmentModal conversation={conversation} />,
    });
  };

  return (
    <div className="actions">
      <button
        data-cy="book-appointment-button"
        className="new-appointment-button"
        onClick={openBookAppointmentModal}
      >
        {intl.formatMessage(translations.newAppointmentButton)}
      </button>
    </div>
  );
}

function FutureAppointmentsList({ appointments }: { appointments: Array<Appointment> }) {
  return (
    <div className="conversation-view-appointment-hub-future-appointments">
      {appointments.map((appointment) => (
        <FutureAppointmentDisplay
          appointment={appointment}
          key={appointment.id}
        />
      ))}
    </div>
  );
}

function PastAppointmentsList({ appointments }: { appointments: Array<Appointment> }) {
  return (
    <div className="conversation-view-appointment-hub-past-appointments">
      {appointments.map((appointment) => (
        <PastAppointmentDisplay
          appointment={appointment}
          key={appointment.id}
        />
      ))}
    </div>
  );
}

function AppointmentHub() {
  const intl = useIntl();
  const navigate = useNavigate();

  const { conversation } = useContext(ConversationViewContext);

  const { appointments, loading: appointmentLoading } = useConversationAppointments({
    conversationId: conversation.id,
  });
  const { claims, loading: claimsLoading } = useConversationAppointmentClaims({ conversationId: conversation.id });

  if (appointmentLoading || claimsLoading) {
    return <Loader />;
  }
  if (!appointments || !claims) {
    return null;
  }

  const pastAppointments = appointments.filter((appointment) => moment(appointment.startAt) < moment());
  const futureAppointments = appointments.filter((appointment) => moment(appointment.startAt) >= moment());
  const pendingAppointmentClaim = claims.find(({ resolvedAt }) => !resolvedAt);
  const firstMustReviewAppointment = pastAppointments.find((appt) => appt.currentUserCanReview);

  function goToReview() {
    if (!firstMustReviewAppointment) return;
    navigate(generatePath(Routes.review, { appointmentId: firstMustReviewAppointment.id }));
  }

  return (
    <div className="conversation-view-appointment-hub">
      {appointments.length == 0 && !pendingAppointmentClaim && <NoAppointment />}
      {futureAppointments.length !== 0 && <FutureAppointmentsList appointments={futureAppointments} />}
      {pendingAppointmentClaim && <AppointmentClaimDisplay claim={pendingAppointmentClaim} />}
      {pastAppointments.length !== 0 && <PastAppointmentsList appointments={pastAppointments} />}
      {conversation.currentUserCanBookAppointment && <BookAppointmentButton />}
      {firstMustReviewAppointment && (
        <div className="actions">
          <button
            data-cy="book-appointment-button"
            className="review-appointment-button"
            onClick={goToReview}
          >
            {intl.formatMessage(translations.evaluateAppointment)}
          </button>
        </div>
      )}
    </div>
  );
}

export default AppointmentHub;
