import CalendarIcon from '../../../../../icons/component-icons/CalendarIcon';
import EyeIcon from '../../../../../icons/component-icons/EyeIcon';
import UsersIcon from '../../../../../icons/component-icons/UsersIcon';
import { IconProps } from '../../../../../icons/component-icons/Icon';
import MessageChatCircleIcon from '../../../../../icons/component-icons/MessageChatCircleIcon';
import MessageCheckCircleIcon from '../../../../../icons/component-icons/MessageCheckCircleIcon';
import StarIcon from '../../../../../icons/component-icons/StarIcon';
import ThumbsUpIcon from '../../../../../icons/component-icons/ThumbsUpIcon';
import { ComponentType } from 'react';
import { GetActivityStatsQuery, PrimarySituationEnum } from '../../../../../@types/graphql.d';
import { DashboardMode } from '../../DashboardView.types';
import { ActivityTranslations } from './Activity.translations';
import { IntlShape } from 'react-intl';

export type Statistic = {
  icon: ComponentType<IconProps>;
  text: string;
  count: number | string;
  color: string;
  background: string;
  primarySituation?: PrimarySituationEnum[] | undefined;
};

export const getStatisticsByMode = (
  intl: IntlShape,
  dashboardMode: DashboardMode,
  stats: GetActivityStatsQuery['statistics'],
): Statistic[] => {
  if (!stats) return [];
  const baseStatistics: Record<string, Statistic[]> = {
    ambassador: [
      {
        icon: MessageChatCircleIcon,
        text: intl.formatMessage(ActivityTranslations.conversations, {
          count: stats.conversationsAsAmbassadorCount,
        }),
        count: stats.conversationsAsAmbassadorCount,
        color: '#00630F',
        background: '#C7E9CC',
      },
      {
        icon: EyeIcon,
        text: intl.formatMessage(ActivityTranslations.profileViews, {
          count: stats.ambassadorProfileViewsCount,
        }),
        count: stats.ambassadorProfileViewsCount,
        color: '#C5254C',
        background: '#FEDEDE',
      },
      {
        icon: StarIcon,
        text: intl.formatMessage(ActivityTranslations.wishesReceived, {
          count: stats.wishesReceivedCount,
        }),
        count: stats.wishesReceivedCount,
        color: '#B57A09',
        background: '#F8ECCF',
      },
      {
        icon: CalendarIcon,
        text: intl.formatMessage(ActivityTranslations.individualAppointments, {
          count: stats.meetingsAsAmbassadorCount,
        }),
        count: stats.meetingsAsAmbassadorCount,
        color: '#6A0583',
        background: '#D5D2F0',
      },
      {
        icon: CalendarIcon,
        text: intl.formatMessage(ActivityTranslations.collectiveAppointments, {
          count: stats.collectiveMeetingsAsAmbassadorCount,
        }),
        count: stats.collectiveMeetingsAsAmbassadorCount,
        color: '#BD0875',
        background: '#F5CDF3',
      },
      {
        icon: UsersIcon,
        text: intl.formatMessage(ActivityTranslations.impactedMembers, {
          count: stats.impactedMembersAsAmbassadorCount,
        }),
        count: stats.impactedMembersAsAmbassadorCount,
        color: '#A40080',
        background: '#FFC7EC',
      },
      {
        icon: ThumbsUpIcon,
        text: intl.formatMessage(ActivityTranslations.likes, {
          count: stats.likesReceivedCount,
        }),
        count: stats.likesReceivedCount,
        color: '#A1014E',
        background: '#FFE2F2',
      },
      {
        icon: MessageCheckCircleIcon,
        text: intl.formatMessage(ActivityTranslations.responseRate),
        count: `${Math.ceil((stats.responseRateAsAmbassador || 0) * 100)}%`,
        color: '#02467A',
        background: '#B9DCF6',
      },
    ],
    member: [
      {
        icon: EyeIcon,
        text: intl.formatMessage(ActivityTranslations.profileVisited, {
          count: stats.seenProfilesCount,
        }),
        count: stats.seenProfilesCount,
        color: '#C5254C',
        background: '#FEDEDE',
      },
      {
        icon: StarIcon,
        text: intl.formatMessage(ActivityTranslations.wishes, { count: stats.wishesCount }),
        count: stats.wishesCount,
        color: '#B57A09',
        background: '#F8ECCF',
      },
      {
        icon: MessageChatCircleIcon,
        text: intl.formatMessage(ActivityTranslations.conversations, {
          count: stats.conversationsAsMemberCount,
        }),
        count: stats.conversationsAsMemberCount,
        color: '#00630F',
        background: '#C7E9CC',
      },
      {
        icon: CalendarIcon,
        text: intl.formatMessage(ActivityTranslations.individualAppointments, {
          count: stats.meetingsAsMemberCount,
        }),
        count: stats.meetingsAsMemberCount,
        color: '#6A0583',
        background: '#D5D2F0',
      },
      {
        icon: CalendarIcon,
        text: intl.formatMessage(ActivityTranslations.collectiveAppointments, {
          count: stats.collectiveMeetingsAsMemberCount,
        }),
        count: stats.collectiveMeetingsAsMemberCount,
        color: '#BD0875',
        background: '#F5CDF3',
        primarySituation: [PrimarySituationEnum.JobSeekerSupport, PrimarySituationEnum.ProfessionalAndSecondarySchoolStudentSupport],
      },
      {
        icon: MessageCheckCircleIcon,
        text: intl.formatMessage(ActivityTranslations.responseRate),
        count: `${Math.floor((stats.responseRateAsMember || 0) * 100)}%`,
        color: '#02467A',
        background: '#B9DCF6',
      },
    ],
  };

  return baseStatistics[dashboardMode];
};
