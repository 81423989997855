import React from 'react';
import {
  AppointmentReviewFromProfessional,
  AppointmentReviewFromStudent,
  CompanyEmployee,
  CompanyEmployeeAppointment,
  Maybe,
  EmployeeStatus,
} from '../../../../../../../@types/graphql.d';
import PublicProfileAvatarDisplay from '../../../../../../common/public-profile-avatar-display/PublicProfileAvatarDisplay';
import anonymizedTranslation from '../../../../../../common/common-translations/anonymized.translations';
import SidebarAppointmentProfessionalFeedback from './components/sidebar-appointment-professional-feedback/SidebarAppointmentProfessionalFeedback';
import SidebarAppointmentStudentFeedback from './components/sidebar-appointment-student-feedback/SidebarAppointmentStudentFeedback';
import SidebarAppointmentStudentOpportunity from './components/sidebar-appointment-student-opportunity/SidebarAppointmentStudentOpportunity';
import translations from './SidebarAppointmentSection.translations';

import styles from './SidebarAppointmentSection.module.scss';
import { useIntl } from 'react-intl';

interface SidebarAppointmentSectionProps {
  companyEmployeeAppointment?:
    | (Pick<CompanyEmployeeAppointment, 'from'> & {
        professional?: Pick<
          CompanyEmployee,
          | 'id'
          | 'avatar'
          | 'status'
          | 'firstName'
          | 'lastName'
          | 'email'
          | 'jobTitle'
          | 'companyAttribute1'
          | 'companyAttribute2'
          | 'companyAttribute3'
        > | null;
        reviewFromProfessional?: Maybe<AppointmentReviewFromProfessional>;
        reviewFromStudent?: Maybe<AppointmentReviewFromStudent>;
      })
    | null;
  isPremiumSchool?: boolean;
}
function SidebarAppointmentSection({ companyEmployeeAppointment, isPremiumSchool }: SidebarAppointmentSectionProps) {
  const intl = useIntl();
  const companyAttributes = [
    companyEmployeeAppointment?.professional?.companyAttribute1,
    companyEmployeeAppointment?.professional?.companyAttribute2,
    companyEmployeeAppointment?.professional?.companyAttribute3,
  ];

  const isProfessionalAnonimyzed =
    companyEmployeeAppointment?.professional?.status &&
    [EmployeeStatus.Anonymized, EmployeeStatus.Archived].includes(companyEmployeeAppointment.professional.status);

  return (
    <section className={styles.sidebarAppointmentSection}>
      <h2 className={styles.title}>{intl.formatMessage(translations.title)}</h2>

      <div className={styles.appointment}>
        <p className={styles.tookPlace}>
          {intl.formatMessage(translations.tookPlace, {
            date: intl.formatDate(companyEmployeeAppointment?.from, {
              day: '2-digit',
              month: 'long',
              year: 'numeric',
            }),
            hour: intl.formatDate(companyEmployeeAppointment?.from, {
              hour: '2-digit',
              minute: '2-digit',
            }),
          })}
        </p>

        <div className={styles.appointmentDetails}>
          <div className={styles.professional}>
            <PublicProfileAvatarDisplay
              publicProfile={companyEmployeeAppointment?.professional}
              className={styles.avatar}
            />
            <div className={styles.professionalInformations}>
              {isProfessionalAnonimyzed ? (
                <p className={styles.userDeleted}>{intl.formatMessage(anonymizedTranslation.userDeleted)}</p>
              ) : (
                <p className={styles.name}>
                  {companyEmployeeAppointment?.professional?.firstName}
                  &nbsp;
                  {companyEmployeeAppointment?.professional?.lastName}
                </p>
              )}
              <p className={styles.position}>{companyEmployeeAppointment?.professional?.jobTitle}</p>
            </div>
          </div>

          <div className={styles.companyAttributes}>
            <div className={styles.companyAttributesWrapper}>
              {companyAttributes.map(
                (attribute) =>
                  attribute && (
                    <span
                      key={attribute}
                      className={styles.attribute}
                    >
                      {attribute}
                    </span>
                  ),
              )}
            </div>
          </div>

          <SidebarAppointmentProfessionalFeedback
            isPremiumSchool={isPremiumSchool}
            reviewFromProfessional={companyEmployeeAppointment?.reviewFromProfessional}
          />

          <SidebarAppointmentStudentFeedback
            isPremiumSchool={isPremiumSchool}
            reviewFromStudent={companyEmployeeAppointment?.reviewFromStudent}
          />

          <SidebarAppointmentStudentOpportunity
            isPremiumSchool={isPremiumSchool}
            reviewFromStudent={companyEmployeeAppointment?.reviewFromStudent}
          />
        </div>
      </div>
    </section>
  );
}

export default SidebarAppointmentSection;
