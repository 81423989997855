import React from 'react';
import { useIntl } from 'react-intl';
import { ExternalLinkOpenModalTranslations } from './ExternalLinkOpenModal.translations';
import ButtonsGroup from '../../components/buttons-group/ButtonsGroup';
import SecondaryButton from '../../components/secondary-button/SecondaryButton';
import useModal from '../../components/modal/Modal.hook';
import PrimaryButton from '../../components/primary-button/PrimaryButton';
import { MJGFormattedMessage } from '../mjg-formatted-message/MJGFormattedMessage';

type ExternalLinkOpenModalProps = {
  onConfirm: () => void;
};

export default function ExternalLinkOpenModal({ onConfirm }: ExternalLinkOpenModalProps) {
  const [, closeModal] = useModal();
  const intl = useIntl();

  return (
    <div>
      <MJGFormattedMessage translation={ExternalLinkOpenModalTranslations.externalLinkModalSubtitle} />
      <ButtonsGroup>
        <SecondaryButton
          label={intl.formatMessage(ExternalLinkOpenModalTranslations.externalLinkModalCancelButton)}
          onClick={closeModal}
        />
        <PrimaryButton
          label={intl.formatMessage(ExternalLinkOpenModalTranslations.externalLinkModalContinueButton)}
          onClick={onConfirm}
        />
      </ButtonsGroup>
    </div>
  );
}
