import React from 'react';
import DayMonthYearFormat from '../day-month-year-format/DayMonthYearFormat';
import { useIntl } from 'react-intl';

interface FormatDateTimeProps {
  datetime: string;
  format?: 'hour minute' | 'day month year';
}
function FormatDateTime({ datetime, format }: FormatDateTimeProps): JSX.Element {
  const intl = useIntl();
  if (format === 'hour minute') {
    return <>{intl.formatTime(datetime)}</>;
  }

  if (format === 'day month year') {
    return <DayMonthYearFormat datetime={datetime} />;
  }
  return <>{datetime}</>;
}

export default FormatDateTime;
