import { useMutation, ApolloError, MutationHookOptions, MutationResult } from '@apollo/client';
import * as Sentry from "@sentry/react";
import { FormikHelpers, FormikValues } from 'formik';
import { DocumentNode } from 'graphql';
import { GraphqlGeneralErrors } from '../../@types/graphql-errors.d';
import { useEffect } from 'react';

type SubmitFunctionType<TData, TVariables> = (
  values: FormikValues,
  formikHelpers: FormikHelpers<FormikValues>,
  mutateOptions?: MutationHookOptions<TData, FormikValues>,
) => Promise<any>;

type UseFormikMutationReturn<TData, TVariables> = [SubmitFunctionType<TData, TVariables>, MutationResult<TData>];

export default function useFormikMutation<TData, TVariables>(
  query: DocumentNode,
  options?: MutationHookOptions<TData, FormikValues> | undefined,
): UseFormikMutationReturn<TData, TVariables> {
  const [mutate, data] = useMutation<TData, FormikValues>(query, options);
  const onSubmit: SubmitFunctionType<TData, TVariables> = (values, formikHelpers, mutateOptions = {}) =>
    mutate({ variables: values, ...mutateOptions }).catch((errors: ApolloError) => {
      if (errors.networkError) {
        formikHelpers.setStatus(GraphqlGeneralErrors.GRAPHQL_NO_NETWORK);
      }
      errors.graphQLErrors?.forEach((error) => {
        if (error.extensions?.code === 'BAD_USER_INPUT') {
          Object.keys(error.extensions.exception as object).forEach((fieldName) => {
            if (['stacktrace'].indexOf(fieldName) === -1) {
              formikHelpers.setFieldTouched(fieldName, true, false);
              formikHelpers.setFieldError(
                fieldName,
                (error.extensions?.exception as Record<string, any>)[fieldName]?.[0]?.code,
              );
            }
          });
        } else if (error.extensions?.code) {
          formikHelpers.setStatus(error.extensions?.code);
        } else {
          formikHelpers.setStatus(GraphqlGeneralErrors.GRAPHQL_UNKNOWN);
          useEffect(() => {Sentry.captureException(error)}, [error]);
        }
      });
    });
  return [onSubmit, data];
}
