import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmMessage: {
    id: 'UnsavedMessageTemplateChangesWarning.confirmMessage',
    defaultMessage: 'Enregistrer ce template avant de quitter ?',
    description: 'Message beform closing message templates',
  },
  yes: {
    id: 'UnsavedMessageTemplateChangesWarning.yes',
    defaultMessage: 'Oui',
    description: 'Oui je veux supprimer ce template',
  },
  no: {
    id: 'UnsavedMessageTemplateChangesWarning.no',
    defaultMessage: 'Non',
    description: 'Non je ne veux pas supprimer ce template',
  },
});
