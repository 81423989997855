import React from 'react';
import { useMatch, useParams } from 'react-router';
import { getDisplayName } from 'recompose';
import { Get_CompaniesQuery, Get_CompaniesQueryVariables } from '../../../../../@types/graphql.d';
import { CompaniesErrors } from '../../../../../@types/graphql-errors.d';
import { useQueryWithError } from '../../../../../utils/graphql';
import NotFound from '../../../../../containers/not-found/NotFound';
import PageLoader from '../../../../common/page-loader/PageLoader';
import NoNetwork from '../../../../common/no-network/NoNetwork';
import Routes from '../../../../../routes';
import { GET_COMPANIES } from './withCompaniesData.gql';

export default function withCompaniesData<P extends object>(WrappedComponent: React.ComponentType<P>) {
  function WithCompaniesData(props: P): React.ReactElement {
    const isMjgAdmin = !!useMatch(Routes.mjgAdminBase);
    const { companyid } = useParams<{ companyid: string }>();
    const { data, loading, error, refetch } = useQueryWithError<
      Get_CompaniesQuery,
      Get_CompaniesQueryVariables,
      CompaniesErrors
    >(GET_COMPANIES, {
      variables: {
        asAdmin: isMjgAdmin,
      },
    });

    if (loading && !companyid) {
      return <PageLoader />;
    }

    if (error?.networkError) {
      return <NoNetwork refetch={refetch} />;
    }

    if ((error?.graphQLErrors?.length || 0) > 0) {
      return <NotFound />;
    }

    return (
      <WrappedComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        companies={data?.companies}
      />
    );
  }
  WithCompaniesData.displayName = `WithCompaniesData(${getDisplayName(WrappedComponent)})`;
  return WithCompaniesData;
}
