import { defineMessages } from 'react-intl';

const buttonCommonTranslations = defineMessages({
  confirm: {
    id: 'CommonTranslations.button.confirm',
    defaultMessage: 'Confirmer',
  },
  retry: {
    id: 'CommonTranslations.button.retry',
    defaultMessage: 'Recommencer',
  },
  submit: {
    id: 'CommonTranslations.button.submit',
    defaultMessage: 'Valider',
  },
  later: {
    id: 'CommonTranslations.button.later',
    defaultMessage: 'Plus tard',
  },
  next: {
    id: 'CommonTranslations.button.next',
    defaultMessage: 'Suivant',
  },
  back: {
    id: 'CommonTranslations.button.back',
    defaultMessage: 'Précédent',
  },
  cancel: {
    id: 'CommonTranslations.button.cancel',
    defaultMessage: 'Annuler',
  },
  delete: {
    id: 'CommonTranslations.button.delete',
    defaultMessage: 'Supprimer',
  },
  download: {
    id: 'CommonTranslations.button.download',
    defaultMessage: 'Télécharger',
  },
  finish: {
    id: 'CommonTranslations.button.finish',
    defaultMessage: 'Terminer',
  },
  seeMore: {
    id: 'CommonTranslations.button.seeMore',
    defaultMessage: 'Voir plus',
  },
});

export default buttonCommonTranslations;
