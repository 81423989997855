import React, { memo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useQuery } from '@apollo/client';
import { FormikProps } from 'formik';
import moment from 'moment';

import {
  GetConversationsDraftsQuery,
  GetConversationsDraftsQueryVariables,
  InteractionServiceErrorEnum,
} from '../../../../@types/graphql.d';
import Card from '../../../../components/card/Card';
import TextAreaInput from '../../../../components/text-area-input/TextAreaInput';
import { GET_DRAFTS } from '../NewConversation.gql';
import { NewConversationErrorsTranslations } from '../NewConversationErrors.translations';
import { FormikValues } from '../Step';

type Props = {
  formik: FormikProps<FormikValues>;
};

const TRANSLATIONS = defineMessages({
  title: {
    id: 'NewConversation.MessagePreviewSection.title',
    defaultMessage: 'Votre message',
  },
  subtitle: {
    id: 'NewConversation.MessagePreviewSection.subtitle',
    defaultMessage: 'Vérifiez que le texte vous convient et modifiez-le si nécessaire',
  },
});

export const MessagePreviewSection: React.FC<Props> = memo(({ formik }) => {
  const { formatMessage } = useIntl();

  useQuery<GetConversationsDraftsQuery, GetConversationsDraftsQueryVariables>(GET_DRAFTS, {
    variables: { interlocutorId: formik.values.interlocutorId },
    onCompleted: ({ conversationsDrafts }) => {
      if (!conversationsDrafts?.nodes) return;
      const draft = conversationsDrafts.nodes[0];
      if (draft.recipient.currentUserCanContact) {
        formik.setFieldValue('message', draft.message);
        return;
      }

      if (
        draft.recipient.currentUserCanContactErrors.includes(
          InteractionServiceErrorEnum.AmbassadorMeetingPreferencesPreventsContact,
        )
      )
        formik.setFieldValue(
          'cantContactError',
          formatMessage(NewConversationErrorsTranslations.meetingPreferences, {
            primarySituation: draft.initiator.primarySituation?.name,
          }),
        );
      else if (draft.recipient.currentUserCanContactErrors.includes(InteractionServiceErrorEnum.AmbassadorUnavailable))
        formik.setFieldValue(
          'cantContactError',
          formatMessage(NewConversationErrorsTranslations.unavailable, {
            unavailableUntil: moment(draft.recipient.unavailableUntil).format('DD/MM/YYYY'),
          }),
        );
    },
  });

  return (
    <Card title={formatMessage(TRANSLATIONS.title)}>
      <p style={{ fontSize: 12, marginBottom: 16 }}>{formatMessage(TRANSLATIONS.subtitle)}</p>

      <TextAreaInput
        name="message"
        value={formik.values.message}
        onChange={formik.handleChange}
        inputStyle={{ height: 'max(50vh, 300px)' }}
      />
    </Card>
  );
});
