import { AddCompanyRecruitingWorkAreaMutationErrors } from '../../../../../../../../@types/graphql-errors.d';
import { defineMessages } from 'react-intl';

export default defineMessages({
  listValues: {
    id: 'RecruitingWorkingAreasUpdate.listValues',
    defaultMessage: 'Listez des domaines d’emplois à promouvoir',
  },
  labelName: {
    id: 'RecruitingWorkingAreasUpdate.labelName',
    defaultMessage: "Nom du domaine d'emploi",
  },
  helper: {
    id: 'RecruitingWorkingAreasUpdate.helper',
    defaultMessage: 'Pour ajouter cette valeur, appuyez sur "Entrée" (⏎)',
  },
  cancelButton: {
    id: 'RecruitingWorkingAreasUpdate.cancelButton',
    defaultMessage: 'Annuler',
  },
  sample: {
    id: 'RecruitingWorkingAreasUpdate.sample',
    defaultMessage: 'Ex :',
  },
  marketing: {
    id: 'RecruitingWorkingAreasUpdate.marketing',
    defaultMessage: 'marketing',
  },
  finance: {
    id: 'RecruitingWorkingAreasUpdate.finance',
    defaultMessage: 'finance',
  },
  hr: {
    id: 'RecruitingWorkingAreasUpdate.hr',
    defaultMessage: 'RH',
  },
  counter: {
    id: 'RecruitingWorkingAreasUpdate.counter',
    defaultMessage: '{current}/{max} caractères',
  },
  finishButton: {
    id: 'RecruitingWorkingAreasUpdate.finishButton',
    defaultMessage: 'Terminer',
  },
});

export const errorTranslation = defineMessages({
  [AddCompanyRecruitingWorkAreaMutationErrors.WORKING_AREA_ALREADY_EXISTS]: {
    id: 'RecruitingWorkingAreasUpdate.WORKING_AREA_ALREADY_EXISTS',
    defaultMessage: 'Nom invalide : ce domaine existe déjà',
  },
  [AddCompanyRecruitingWorkAreaMutationErrors.WORKING_AREA_EMPTY]: {
    id: 'RecruitingWorkingAreasUpdate.WORKING_AREA_EMPTY',
    defaultMessage: 'Nom invalide : doit être rempli',
  },
});
