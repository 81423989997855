import { useQuery } from '@apollo/client';
import CalendarIcon from '../../../../../icons/component-icons/CalendarIcon';
import ClockIcon from '../../../../../icons/component-icons/ClockIcon';
import moment from 'moment';
import React, { useMemo } from 'react';
import {
  GetUpcomingAppointmentsQuery,
  GetUpcomingAppointmentsQueryVariables,
  UserProfile,
} from '../../../../../@types/graphql.d';
import DashboardCard, { DashboardCardLoader } from '../../components/dashboard-card/DashboardCard';
import { GET_UPCOMING_APPOINTMENTS_QUERY } from '../../DashboardView.gql';
import './UpcomingAppointments.scss';
import { UpcomingAppointmentsTranslations } from './UpcomingAppointments.translations';
import { Link, generatePath } from 'react-router';
import Routes from '../../../../../routes';
import Skeleton from 'react-loading-skeleton';
import { useIntl } from 'react-intl';

export default function UpcomingAppointments() {
  const intl = useIntl();
  const now = useMemo(() => new Date().toISOString(), []);
  const { data, loading } = useQuery<GetUpcomingAppointmentsQuery, GetUpcomingAppointmentsQueryVariables>(
    GET_UPCOMING_APPOINTMENTS_QUERY,
    {
      variables: { now },
    },
  );

  if (loading) return <UpcomingAppointmentsLoader />;
  if (!data?.upcomingAppointments || data.upcomingAppointments.nodes.length === 0) return <NoUpcommingAppointment />;

  const { upcomingAppointments } = data;

  return (
    <DashboardCard
      icon={CalendarIcon}
      title={intl.formatMessage(UpcomingAppointmentsTranslations.title)}
    >
      <div className="upcoming-appointments">
        {upcomingAppointments.nodes.map((appointment) => (
          <UpcomingAppointmentItem
            key={appointment.startAt}
            appointment={appointment}
          />
        ))}
      </div>
    </DashboardCard>
  );
}

type UpcomingAppointmentItemProps = {
  appointment: NonNullable<GetUpcomingAppointmentsQuery['upcomingAppointments']>['nodes'][0];
};

function UpcomingAppointmentItem({ appointment }: UpcomingAppointmentItemProps) {
  const { interlocutor, startAt, conversation } = appointment;

  const establishment = useMemo(() => {
    switch (interlocutor.profileType) {
      case UserProfile.InOffice:
        return interlocutor.companyName;
      case UserProfile.InSchool:
        return interlocutor.currentSchoolName;
      default:
        return undefined;
    }
  }, [interlocutor]);

  return (
    <Link
      className="upcoming-appointments__appointment"
      to={generatePath(Routes.conversation, { id: conversation.id })}
    >
      <header className="upcoming-appointments__appointment__header">
        <div>
          <CalendarIcon />
          {moment(startAt).format('dddd DD MMM')}
        </div>
        <div>
          <ClockIcon />
          {moment(startAt).format('LT')}
        </div>
      </header>

      <main className="upcoming-appointments__appointment__informations">
        <img
          className="upcoming-appointments__appointment__informations__avatar"
          src={interlocutor.avatars.url || ''}
        />

        <div className="upcoming-appointments__appointment__informations__details">
          <div className="upcoming-appointments__appointment__informations__details__name">
            {interlocutor.firstName}
          </div>
          {establishment && (
            <div className="upcoming-appointments__appointment__informations__details__company">
              {interlocutor.companyLogo?.url && <img src={interlocutor.companyLogo.url} />}
              {establishment}
            </div>
          )}
        </div>
      </main>
    </Link>
  );
}

function NoUpcommingAppointment() {
  const intl = useIntl();
  return (
    <DashboardCard
      icon={CalendarIcon}
      title={intl.formatMessage(UpcomingAppointmentsTranslations.title)}
    >
      <div className="upcoming-appointments__appointment__informations__details__name">
        {intl.formatMessage(UpcomingAppointmentsTranslations.noAppointment)}
      </div>
    </DashboardCard>
  );
}

function UpcomingAppointmentsLoader() {
  return (
    <DashboardCardLoader>
      <div className="upcoming-appointments">
        <Skeleton
          height={96}
          borderRadius={8}
        />
      </div>
    </DashboardCardLoader>
  );
}
