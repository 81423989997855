import { gql } from '@apollo/client';

export const GET_CONVERSATION_APPOINTMENTS = gql`
  query GET_CONVERSATION_APPOINTMENTS($filters: AppointmentsFilter, $sort: [AppointmentsSortFieldsEnum!]) {
    appointments(filters: $filters, sort: $sort) {
      nodes {
        id
        type
        meetingPlace
        videoconferenceLink
        googleCalendarUrl
        yahooCalendarUrl
        conversationId

        status
        startAt
        currentUserIsMember
        currentUserIsAmbassador
        currentUserCanReview
        currentUserCanCancel
        currentUserCanMove
        currentUserRecommendedByInterlocutor
        ambassadorReview {
          appointmentStatus
        }
        memberReview {
          appointmentStatus
          liked
        }
      }
    }
  }
`;
