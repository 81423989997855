import { FacetKeyEnum, SearchTypeEnum } from '../../@types/graphql.d';
import { SearchFilter } from './Search.model';
import FilterButton from './filter-button/FilterButton';
import CompanySizesFilterButton from './filter-button/company-sizes-filter-button/CompanySizesFilterButton';
import { uncomposeCompanySizesFilter } from './filter-button/company-sizes-filter-button/CompanySizesFilterButton.utils';
import LocationFilterButton from './filter-button/location-filter-button/LocationFilterButton';
import SecondarySituationsFilterButton from './filter-button/secondary-situations-filter-button/SecondarySituationsFilterButton';
import SchoolNamesFilterButton from './filter-button/school-names-filter-button/SchoolNamesFilterButton'
import FiltersDropdownPanel from './filters-dropdown/filters-dropdown-panel/FiltersDropdownPanel';
import CompanySizesFiltersDropdownPanel from './filters-dropdown/filters-dropdown-panel/company-sizes-filters-dropdown-panel/CompanySizesFiltersDropdownPanel';
import LocationFiltersDropdownPanel from './filters-dropdown/filters-dropdown-panel/location-filters-dropdown-panel/LocationFiltersDropdownPanel';
import SecondarySituationsFiltersDropdownPanel from './filters-dropdown/filters-dropdown-panel/secondary-situations-filters-dropdown-panel/SecondarySituationsFiltersDropdownPanel';
import SchoolNamesFilterDowndownPanel from './filters-dropdown/filters-dropdown-panel/school-names-filter-dropdown-panel/SchoolNamesDropdownPanel'

type SearchFacetsMapType = {
  [key: string]: {
    type: FacetKeyEnum;
    desktopComponent: React.ComponentType<any>;
    mobileComponent: React.ComponentType<any>;
    withSearch?: boolean;
    uncomposeFilterFunction?: (filters: SearchFilter) => SearchFilter;
    sort?: boolean;
  }[];
};

export const SEARCH_FACETS_MAP: SearchFacetsMapType = {
  [SearchTypeEnum.OnlyProfessionals]: [
    {
      type: FacetKeyEnum.Sectors,
      desktopComponent: FilterButton,
      mobileComponent: FiltersDropdownPanel,
      withSearch: true,
      sort: true,
    },
    {
      type: FacetKeyEnum.Companies,
      desktopComponent: FilterButton,
      mobileComponent: FiltersDropdownPanel,
      withSearch: true,
      sort: true,
    },
    {
      type: FacetKeyEnum.CompanySizes,
      desktopComponent: CompanySizesFilterButton,
      mobileComponent: CompanySizesFiltersDropdownPanel,
      uncomposeFilterFunction: (filter) => uncomposeCompanySizesFilter(filter),
      sort: true,
    },
    {
      type: FacetKeyEnum.LevelOfExperience,
      desktopComponent: FilterButton,
      mobileComponent: FiltersDropdownPanel,
    },
    {
      type: FacetKeyEnum.Location,
      desktopComponent: LocationFilterButton,
      mobileComponent: LocationFiltersDropdownPanel,
      sort: true,
    },
    {
      type: FacetKeyEnum.SchoolNames,
      desktopComponent: SchoolNamesFilterButton,
      mobileComponent: SchoolNamesFilterDowndownPanel,
      withSearch: true,
      sort: true,
    },
    {
      type: FacetKeyEnum.Languages,
      desktopComponent: FilterButton,
      mobileComponent: FiltersDropdownPanel,
      withSearch: true,
      sort: true,
    },
  ],
  [SearchTypeEnum.OnlyStudents]: [
    {
      type: FacetKeyEnum.SecondarySituations,
      desktopComponent: SecondarySituationsFilterButton,
      mobileComponent: SecondarySituationsFiltersDropdownPanel,
      withSearch: true,
    },
    {
      type: FacetKeyEnum.CurrentSchoolName,
      desktopComponent: FilterButton,
      mobileComponent: FiltersDropdownPanel,
      withSearch: true,
      sort: true,
    },
    {
      type: FacetKeyEnum.PreparedDiplomaTitle,
      desktopComponent: FilterButton,
      mobileComponent: FiltersDropdownPanel,
      withSearch: true,
      sort: true,
    },
    {
      type: FacetKeyEnum.Location,
      desktopComponent: LocationFilterButton,
      mobileComponent: LocationFiltersDropdownPanel,
      sort: true,
    },
    {
      type: FacetKeyEnum.Languages,
      desktopComponent: FilterButton,
      mobileComponent: FiltersDropdownPanel,
      withSearch: true,
      sort: true,
    },
  ],
};
