import { defineMessages } from 'react-intl';
import { CompanyEmployeesQueryFiltersHrTableStatusEnum } from '../../../../../@types/graphql.d';

export const EmployeeListTranslations = defineMessages({
  myEmployees: {
    id: 'EmployeeList.myEmployees',
    defaultMessage: 'Mes Ambassadeurs',
  },
  archivedEmployees: {
    id: 'EmployeeList.archivedEmployees',
    defaultMessage: 'Archives',
  },
  name: {
    id: 'EmployeeList.name',
    defaultMessage: 'Nom',
  },
  status: {
    id: 'EmployeeList.status',
    defaultMessage: 'État',
  },
  defaultCompanyAttribute: {
    id: 'EmployeeList.defaultCompanyAttribute',
    defaultMessage: 'Libellé n°{number}',
  },
  next: {
    id: 'EmployeeList.next',
    defaultMessage: 'Suivant',
  },
  previous: {
    id: 'EmployeeList.previous',
    defaultMessage: 'Précédent',
  },
  modifyUser: {
    id: 'EmployeeList.modifyUser',
    defaultMessage: "Modifier l'Ambassadeur",
  },
  seeUser: {
    id: 'EmployeeList.seeUser',
    defaultMessage: "Voir l'Ambassadeur",
  },
  archiveAction: {
    id: 'EmployeeList.archiveAction',
    defaultMessage: 'Archiver',
  },
  publishAction: {
    id: 'EmployeeList.publishAction',
    defaultMessage: 'Publier',
  },
  unpublishAction: {
    id: 'EmployeeList.unpublishAction',
    defaultMessage: 'Dépublier',
  },
  approveAction: {
    id: 'EmployeeList.approveAction',
    defaultMessage: 'Approuver',
  },
  resendAction: {
    id: 'EmployeeList.resendAction',
    defaultMessage: 'Relancer',
  },
  successfullyArchived: {
    id: 'EmployeeList.successfulyArchived',
    defaultMessage: '{count} Ambassadeurs a/ont été archivé(s)',
  },
  successfullyPublished: {
    id: 'EmployeeList.successfullyPublished',
    defaultMessage: '{count} Ambassadeurs a/ont été publié(s).',
  },
  successfullyUnpublished: {
    id: 'EmployeeList.successfullyUnpublished',
    defaultMessage: '{count} Ambassadeurs a/ont été dépublié(s).',
  },
  successfullyApproved: {
    id: 'EmployeeList.successfullyApproved',
    defaultMessage: '{count} Ambassadeurs a/ont été approuvé(s).',
  },
  successfullyResent: {
    id: 'EmployeeList.successfullyResent',
    defaultMessage: '{count} Ambassadeurs a/ont été relancé(s).',
  },
});

export const CompanyEmployeesQueryFiltersHrTableStatusEnumTranslations = defineMessages({
  [CompanyEmployeesQueryFiltersHrTableStatusEnum.Invited]: {
    id: 'CompanyEmployeesQueryFiltersHrTableStatusEnum.Invited',
    defaultMessage: 'Invité(e)',
    description: 'Status Profil invité dans l Admin RH',
  },
  [CompanyEmployeesQueryFiltersHrTableStatusEnum.InvitedMultipleTimes]: {
    id: 'CompanyEmployeesQueryFiltersHrTableStatusEnum.InvitedMultipleTimes',
    defaultMessage: 'Relancé(e)',
    description: 'Status Profil invité dans l Admin RH',
  },
  [CompanyEmployeesQueryFiltersHrTableStatusEnum.IncompleteProfile]: {
    id: 'CompanyEmployeesQueryFiltersHrTableStatusEnum.IncompleteProfile',
    defaultMessage: 'Profil incomplet',
    description: 'Status Profil incomplet dans l Admin RH',
  },
  [CompanyEmployeesQueryFiltersHrTableStatusEnum.CompleteProfile]: {
    id: 'CompanyEmployeesQueryFiltersHrTableStatusEnum.CompleteProfile',
    defaultMessage: 'Profil complet',
    description: 'Status Profil complet dans l Admin RH',
  },
  [CompanyEmployeesQueryFiltersHrTableStatusEnum.Approved]: {
    id: 'CompanyEmployeesQueryFiltersHrTableStatusEnum.Approved',
    defaultMessage: 'Profil approuvé',
    description: 'Status Profil approuvé dans l Admin RH',
  },
  [CompanyEmployeesQueryFiltersHrTableStatusEnum.Active]: {
    id: 'CompanyEmployeesQueryFiltersHrTableStatusEnum.Active',
    defaultMessage: 'Profil actif',
    description: 'Status Profil actif dans l Admin RH',
  },
  [CompanyEmployeesQueryFiltersHrTableStatusEnum.AtRisk]: {
    id: 'CompanyEmployeesQueryFiltersHrTableStatusEnum.AtRisk',
    defaultMessage: 'Profil à risque',
    description: 'Status Profil à risque dans l Admin RH',
  },
  [CompanyEmployeesQueryFiltersHrTableStatusEnum.Inactive]: {
    id: 'CompanyEmployeesQueryFiltersHrTableStatusEnum.Inactive',
    defaultMessage: 'Profil inactif',
    description: 'Status Profil inactif dans l Admin RH',
  },
  [CompanyEmployeesQueryFiltersHrTableStatusEnum.Hibernated]: {
    id: 'CompanyEmployeesQueryFiltersHrTableStatusEnum.Hibernated',
    defaultMessage: 'Profil hiberné',
    description: 'Status Profil hiberné dans l Admin RH',
  },
  [CompanyEmployeesQueryFiltersHrTableStatusEnum.Unpublished]: {
    id: 'CompanyEmployeesQueryFiltersHrTableStatusEnum.Unpublished',
    defaultMessage: 'Profil dépublié',
    description: 'Status Profil dépublié dans l Admin RH',
  },
});
