import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'MisconductReportSuccess.title',
    defaultMessage: 'Signaler un utilisateur',
  },
  message: {
    id: 'MisconductReportSuccess.message',
    defaultMessage:
      "Merci d'avoir pris le temps de signaler ce compte. Nos équipes étudieront votre signalement et prendront les mesures appropriées.",
  },
  submitButton: {
    id: 'MisconductReportSuccess.submitButton',
    defaultMessage: 'Ok, merci',
  },
});
