import React from 'react';
import translations from './NoStudentFeedbacks.translations';

import './NoStudentFeedbacks.scss';
import { useIntl } from 'react-intl';

function NoStudentFeedbacks() {
  const intl = useIntl();
  return (
    <p className="no-student-feedbacks">
      {intl.formatMessage(translations.beFirst)}

      <span className="callout">{intl.formatMessage(translations.purposeAppointment)}</span>
    </p>
  );
}

export default NoStudentFeedbacks;
