import { gql } from '@apollo/client';

export const GET_COMPANY_REPORT_TOP_EMPLOYEES = gql`
  query GET_COMPANY_REPORT_TOP_EMPLOYEES($companyId: ID!, $range: CompanyReportRange!) {
    companyReport(range: $range, companyId: $companyId) {
    company {
      canShowDashboardEmployeesRanking
    }
      bestProfiles {
        id
        avatar
        firstName
        lastName
        jobTitle
        appointmentCount
        medal
      }
    }
  }
`;
