import { gql } from '@apollo/client';

export const COMPANY_PRESENTATION_QUERY = gql`
  query AmbassadorCompanyPresentation($id: ID!) {
    getUser(id: $id) {
      isPremiumAmbassador
      premiumSchoolAmbassador
      companyName
      companyDescription
    }
  }
`;
