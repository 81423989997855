import React from 'react';
import {
  Company,
  Update_Company_DescriptionMutation,
  Update_Company_DescriptionMutationVariables,
} from '../../../../../../../../@types/graphql.d';
import { UPDATE_COMPANY_DESCRIPTION_MUTATION } from '../CompanyPresentation.gql';
import { Field, Formik } from 'formik';
import { CompanyPresentationUpdateValidationSchema } from './CompanyPresentationUpdate.validations';
import FormikTextareaField from '../../../../../../../common/formik/components/formik-textarea-field/FormikTextareaField';
import FormikFieldError from '../../../../../../../common/formik/components/formik-field-error/FormikFieldError';
import FormikStatusError from '../../../../../../../common/formik/components/formik-status-error/FormikStatusError';
import translations, { errorStatusTranslations } from './CompanyPresentationUpdate.translations';
import useFormikMutation from '../../../../../../../../utils/hooks/useFormikMutation';

import './CompanyPresentationUpdate.scss';
import SecondaryButton from '../../../../../../../../components/secondary-button/SecondaryButton';
import PrimaryButton from '../../../../../../../../components/primary-button/PrimaryButton';
import { useIntl } from 'react-intl';

interface CompanyPresentationWriteProps {
  company?: Pick<Company, 'id' | 'description'> | null;
  finish: () => void;
}

function CompanyPresentationUpdate({ company, finish }: CompanyPresentationWriteProps) {
  const intl = useIntl();
  const [onSubmit] = useFormikMutation<Update_Company_DescriptionMutation, Update_Company_DescriptionMutationVariables>(
    UPDATE_COMPANY_DESCRIPTION_MUTATION,
    {
      refetchQueries: ['GetCompanyPresentation'],
      onCompleted: (successData) => {
        if (successData?.updateCompany) finish();
      },
    },
  );

  return (
    <Formik
      initialValues={{
        companyId: company?.id,
        description: company?.description || '',
      }}
      onSubmit={onSubmit}
      validateOnMount
    >
      {({ handleSubmit, isSubmitting }) => (
        <form
          onSubmit={handleSubmit}
          className="form-wrapper"
        >
          <Field
            name="description"
            component={FormikTextareaField}
            rows={10}
          />
          <FormikFieldError
            name="description"
            asyncFieldErrorTranslations={errorStatusTranslations}
            className="form-wrapper__field-error"
            reserveSpace
          />
          <FormikStatusError translations={errorStatusTranslations} />
          <div className="form-wrapper__actions">
            <SecondaryButton
              label={intl.formatMessage(translations.cancelButton)}
              onClick={finish}
              smaller
            />
            <PrimaryButton
              label={intl.formatMessage(translations.submitButton)}
              disabled={isSubmitting}
              onClick={handleSubmit}
              smaller
            />
          </div>
        </form>
      )}
    </Formik>
  );
}

export default CompanyPresentationUpdate;
