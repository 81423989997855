import React from 'react';
import CompanyPackGauge from '../company-pack-gauge/CompanyPackGauge';
import CompanyPackStatistics from '../company-pack-statistics/CompanyPackStatistics';

import styles from './CompanyOverview.module.scss';

function CompanyOverview({ isPremiumSchool }: { isPremiumSchool: boolean }) {
  return (
    <header className={styles.header}>
      <CompanyPackStatistics
        className={styles.companyPackStatistics}
        isPremiumSchool={isPremiumSchool}
      />
      <CompanyPackGauge className={styles.companyOverviewPackGauge} />
    </header>
  );
}

export default CompanyOverview;
