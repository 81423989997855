import React from 'react';
import PublicProfileAvatarDisplay from '../../../../common/public-profile-avatar-display/PublicProfileAvatarDisplay';
import InterlocutorMeetingPlace from './components/interlocutor-meeting-place/InterlocutorMeetingPlace';
import InterlocutorReportButton from './components/interlocutor-report-button/InterlocutorReportButton';
import InterlocutorPhoneDisplay from './components/interlocutor-phone-display/InterlocutorPhoneDisplay';
import ConversationInterlocutorDisplayAnonymized from './components/conversation-interlocutor-display-anonymized/ConversationInterlocutorDisplayAnonymized';

import styles from './ConversationInterlocutorDisplay.module.scss';

import { ConversationViewContextDef } from '../../context/ConversationViewContext';

interface ConversationInterlocutorDisplayProps {
  interlocutor: ConversationViewContextDef['conversation']['interlocutorV2'];
  disabled?: boolean;
  showPhoneNumber: boolean;
}

const getInterlocutorDetails = (interlocutor: ConversationInterlocutorDisplayProps['interlocutor']) => {
  const details = {
    position: '',
    companyName: '',
  };

  switch (interlocutor?.profileType) {
    case 'IN_OFFICE':
      details.position = interlocutor.position ?? '';
      details.companyName = interlocutor.companyName ?? '';
      break;
    case 'IN_SCHOOL':
      details.position = interlocutor.currentGradeOrDiploma ?? '';
      details.companyName = interlocutor.currentSchoolName ?? '';
      break;
    case 'PREVIOUSLY_IN_OFFICE':
    case 'EMPTY':
      details.position = interlocutor.previousPosition ?? '';
      details.companyName = interlocutor.previousCompanyName ?? '';
      break;
  }

  return details;
};

function ConversationInterlocutorDisplay({
  disabled,
  interlocutor,
  showPhoneNumber,
}: ConversationInterlocutorDisplayProps) {
  if (!interlocutor || disabled) {
    return <ConversationInterlocutorDisplayAnonymized />;
  }

  const { position, companyName } = getInterlocutorDetails(interlocutor);

  return (
    <header className={styles.conversationInterlocutorDisplay}>
      <PublicProfileAvatarDisplay
        interlocutor={interlocutor}
        className={styles.interlocutorAvatar}
      />
      <p className={styles.interlocutorFirstname}>{`${interlocutor?.firstName} ${interlocutor?.lastName || ''}`}</p>
      {showPhoneNumber && <InterlocutorPhoneDisplay phone={interlocutor?.normalizedPhone} />}
      <p className={styles.interlocutorPosition}>{position}</p>
      <p className={styles.interlocutorCompanyName}>{companyName}</p>

      <InterlocutorMeetingPlace interlocutor={interlocutor} />

      <InterlocutorReportButton className={styles.interlocutorReportButton} />
    </header>
  );
}

export default ConversationInterlocutorDisplay;
