import { defineMessages } from 'react-intl';

export const CompleteProfileModalTranslations = defineMessages({
  title: {
    id: 'CompleteProfileModal.title',
    defaultMessage: 'Contacter un Ambassadeur',
  },
  primaryButton: {
    id: 'CompleteProfileModal.primaryButton',
    defaultMessage: 'Compléter mon profil',
  },
  closeButton: {
    id: 'CompleteProfileModal.closeButton',
    defaultMessage: 'Annuler',
  },
  body: {
    id: 'CompleteProfileModal.body',
    defaultMessage:
      "Afin de pouvoir entrer en contact avec cet Ambassadeur, vous devez compléter votre profil. Cela lui permettra d'en savoir plus sur vous et de connaître votre démarche.",
  },
});
