import React, { useRef } from 'react';
import { useIntl } from 'react-intl';

import './SimilarPros.scss';

import Card from '../../../../../components/card/Card';
import { SimilarProsCarousel } from '../../../../../components/similar-pros-carousel/SimilarProsCarousel';
import UsersIcon from '../../../../../icons/component-icons/UsersIcon';
import { SimilarProsTranslations } from './SimilarPros.translations';

type SimilarProsProps = {
  userId: string;
};

export default function SimilarPros({ userId }: SimilarProsProps) {
  const intl = useIntl();

  return (
    <section className="similar-pros">
      <Card
        icon={UsersIcon}
        title={intl.formatMessage(SimilarProsTranslations.title)}
      >
        <SimilarProsCarousel
          interlocutorUserId={userId}
          companySimilar
        />
      </Card>
    </section>
  );
}
