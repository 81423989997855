import { gql } from '@apollo/client';

export const SEND_MESSAGE = gql`
  mutation SEND_MESSAGE($conversationId: ID!, $text: String!, $fromMessageTemplateId: ID, $attachment: Upload) {
    sendMessage(
      conversationId: $conversationId
      text: $text
      fromMessageTemplateId: $fromMessageTemplateId
      attachment: $attachment
    ) {
      id
      text
      createdAt
      sender {
        id
        avatar
        firstName
        lastName
        ... on PublicStudentProfile {
          studentStatus: status
        }
        ... on PublicProfessionalProfile {
          professionalStatus: status
        }
      }
      attachment {
        name
        url
        scannedByAntivirus
      }
    }
  }
`;
