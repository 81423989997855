import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router';
import Routes from '../../../../../routes';

import './LogoZone.scss';

export type LogoZoneProps = {
  className?: string;
  variant?: 'connect' | 'defaultLogo';
};

const LogoZone = ({ className, variant }: LogoZoneProps) => (
  <div className={classnames('logo-zone', className)}>
    <Link to={Routes.home}>
      {variant === 'connect' ? (
        <img
          className={classnames('white-logo', className)}
          src="https://assets.myjobglasses.com/logo/mjg-logo-v3-rectangle.svg"
          alt=""
        />
      ) : (
        <img
          src="https://assets.myjobglasses.com/logo/mjg-logo-v3-rectangle.svg"
          alt=""
        />
      )}
    </Link>
  </div>
);

export default LogoZone;
