import { defineMessages } from 'react-intl';

export const ScoreTranslations = defineMessages({
  title: {
    id: 'Score.title',
    defaultMessage: 'Points',
  },
  rankingUnavailable: {
    id: 'Score.rankingUnavailable',
    defaultMessage: 'Devenez le meilleur Ambassadeur',
  },
  rankingStatus: {
    id: 'Score.rankingStatus',
    defaultMessage: 'Vous êtes dans le top {rank}% avec',
  },
  points: {
    id: 'Score.points',
    defaultMessage: 'points',
  },
  seeDetail: {
    id: 'Score.seeDetail',
    defaultMessage: 'Voir le détail de mes points',
  },
});
