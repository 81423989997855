import React, { useState, useContext } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import NoStudentFeedbacks from './components/no-student-feedbacks/NoStudentFeedbacks';
import StudentFeedbacksCount from './components/student-feedbacks-count/StudentFeedbacksCount';
import StudentFeedback from './components/student-feedback/StudentFeedback';
import { ConversationViewContext } from '../../context/ConversationViewContext';
import { GET_INTERLOCUTOR_RECEIVED_REVIEW_AS_MEMBERS_QUERY } from './StudentFeedbacksHub.gql';
import {
  Get_Interlocutor_Received_Review_As_Members_QueryQuery,
  Get_Interlocutor_Received_Review_As_Members_QueryQueryVariables,
} from '../../../../../@types/graphql.d';

function StudentFeedbacksHub() {
  const { conversation } = useContext(ConversationViewContext);

  const { data } = useQuery<
    Get_Interlocutor_Received_Review_As_Members_QueryQuery,
    Get_Interlocutor_Received_Review_As_Members_QueryQueryVariables
  >(GET_INTERLOCUTOR_RECEIVED_REVIEW_AS_MEMBERS_QUERY, {
    variables: {
      id: conversation.id,
    },
  });

  const feedbacks = data?.conversation?.interlocutorV2?.receivedReviewAsMembers || [];

  if (feedbacks.length <= 0) {
    return (
      <div className="student-feedbacks-hub">
        <NoStudentFeedbacks />
      </div>
    );
  }
  return (
    <div className="student-feedbacks-hub">
      <StudentFeedbacksCount feedbacksCount={feedbacks.length} />
      {feedbacks.map((feedback, i) => (
        <StudentFeedback
          key={i}
          reviewerOrganizationName={feedback.reviewerOrganizationName}
          reviewerTitle={feedback.reviewerTitle}
          feedback={feedback.feedback}
        />
      ))}
    </div>
  );
}

export default StudentFeedbacksHub;
