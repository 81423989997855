import { defineMessages } from 'react-intl';

export default defineMessages({
  fromColumnLabel: {
    id: 'CompanyAdminAppointmentsList.fromColumnLabel',
    defaultMessage: 'Date',
  },
  employeeColumnLabel: {
    id: 'CompanyAdminAppointmentsList.employeeColumnLabel',
    defaultMessage: 'Ambassadeur',
  },
  studentColumnLabel: {
    id: 'CompanyAdminAppointmentsList.studentColumnLabel',
    defaultMessage: 'Membre',
  },
  wantToBeRecontactedColumnLabel: {
    id: 'CompanyAdminAppointmentsList.wantToBeRecontacted',
    defaultMessage: 'Opportunité',
  },
  wantToBeRecontactedSchoolColumnLabel: {
    id: 'CompanyAdminAppointmentsList.wantToBeRecontactedSchoolColumnLabel',
    defaultMessage: 'Intérêt',
  },
  wantToBeRecontactedTooltip: {
    id: 'CompanyAdminAppointmentsList.wantToBeRecontactedTooltip',
    defaultMessage:
      "Intérêt exprimé par le Membre pour recevoir des propositions d'opportunités professionnelles de votre entreprise",
  },
  wantToBeRecontactedSchoolTooltip: {
    id: 'CompanyAdminAppointmentsList.wantToBeRecontactedSchoolTooltip',
    defaultMessage:
      'Intérêt exprimé par le membre pour être recontacté par l’établissement dans l’optique d’une proposition d’admission',
  },
  referenceColumnLabel: {
    id: 'CompanyAdminAppointmentsList.referenceColumnLabel',
    defaultMessage: 'Recommandation',
  },
  industryColumnLabel: {
    id: 'CompanyAdminAppointmentsList.industryColumnLabel',
    defaultMessage: 'Secteur',
  },
  studyColumnLabel: {
    id: 'CompanyAdminAppointmentsList.studyColumnLabel',
    defaultMessage: "Domaine d'études",
  },
  jobMatchEmployeeColumnLabel: {
    id: 'CompanyAdminAppointmentsList.jobMatchEmployeeColumnLabel',
    defaultMessage: 'Match métier',
  },
  studyMatchColumnLabel: {
    id: 'CompanyAdminAppointmentsList.studyMatchColumnLabel',
    defaultMessage: 'Match Domaine d’études',
  },
  jobInterestStudentColumnLabel: {
    id: 'CompanyAdminAppointmentsList.jobInterestStudentColumnLabel',
    defaultMessage: 'Intérêt métier',
  },
  schoolInterestStudentColumnLabel: {
    id: 'CompanyAdminAppointmentsList.schoolInterestStudentColumnLabel',
    defaultMessage: 'Intérêt établissement',
  },

  employeeThirdCriterionTooltip: {
    id: 'CompanyAdminAppointmentsList.employeeThirdCriterionTooltip',
    defaultMessage: "Note (sur 5) laissée par l'Ambassadeur au critère n°3 dans son évaluation de RDV",
  },
  studentThirdCriterionTooltip: {
    id: 'CompanyAdminAppointmentsList.studentThirdCriterionTooltip',
    defaultMessage:
      'Note (sur 5) laissée par le Membre au critère "Est-ce que vous vous voyez dans ce secteur d\'activité ?" dans son évaluation de RDV',
  },
  studentThirdCriterionSchoolTooltip: {
    id: 'CompanyAdminAppointmentsList.studentThirdCriterionSchoolTooltip',
    defaultMessage:
      'Note (sur 5) laissée par le Membre au critère "Recommanderiez-vous ce membre à votre établissement ?" dans son évaluation de RDV',
  },
  employeeSecondCriterionTooltip: {
    id: 'CompanyAdminAppointmentsList.employeeSecondCriterionTooltip',
    defaultMessage:
      'Note (sur 5) laissée par l\'Ambassadeur au critère "Projetez-vous ce Membre dans votre métier ?" dans son évaluation de RDV',
  },
  schoolAmbassadorSecondCriterionTooltip: {
    id: 'CompanyAdminAppointmentsList.schoolAmbassadorSecondCriterionTooltip',
    defaultMessage:
      'Note (sur 5) laissée par l\'Ambassadeur au critère "Projetez-vous ce Membre dans votre domaine d’études ?" dans son évaluation de RDV',
  },
  studentSecondCriterionTooltip: {
    id: 'CompanyAdminAppointmentsList.studentSecondCriterionTooltip',
    defaultMessage:
      'Note (sur 5) laissée par le Membre au critère "Est-ce que vous vous projettez dans ce métier ?" dans son évaluation de RDV',
  },
  memberSchoolSecondCriterionTooltip: {
    id: 'CompanyAdminAppointmentsList.memberSchoolSecondCriterionTooltip',
    defaultMessage:
      'Note (sur 5) laissée par le Membre au critère "Est ce que vous vous voyez dans le même type d’établissement ?" dans son évaluation de RDV',
  },
  previousButton: {
    id: 'GraphqlCursorPaginationBis.previousButton',
    defaultMessage: 'Précédent',
    description: 'Bouton précédent sur la pagination GraphQL',
  },
  nextButton: {
    id: 'GraphqlCursorPaginationBis.nextButton',
    defaultMessage: 'Suivant',
    description: 'Bouton suivant sur la pagination GraphQL',
  },
});
