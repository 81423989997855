import { defineMessages } from 'react-intl';
import { FacetKeyEnum } from '../../@types/graphql.d';

export const FacetTypeTranslations = defineMessages<string>({
  [FacetKeyEnum.Companies]: {
    id: 'FacetType.companies',
    defaultMessage: 'Entreprise',
  },
  [FacetKeyEnum.Sectors]: {
    id: 'FacetType.sectors',
    defaultMessage: 'Secteur',
  },
  [FacetKeyEnum.CompanySizes]: {
    id: 'FacetType.company_sizes',
    defaultMessage: "Taille d'entreprise",
  },
  [FacetKeyEnum.ProfessionalTypes]: {
    id: 'FacetType.professional_types',
    defaultMessage: 'Types de professionnel',
  },
  [FacetKeyEnum.LevelOfExperience]: {
    id: 'FacetType.level_of_experience',
    defaultMessage: 'Expérience',
  },
  [FacetKeyEnum.RecommendedQualifications]: {
    id: 'FacetType.recommended_qualifications',
    defaultMessage: 'Étude recommandée',
  },
  [FacetKeyEnum.Languages]: {
    id: 'FacetType.languages',
    defaultMessage: 'Langue',
  },
  [FacetKeyEnum.Location]: {
    id: 'FacetType.location',
    defaultMessage: 'Lieu',
  },
  [FacetKeyEnum.TagNames]: {
    id: 'FacetType.tag_names',
    defaultMessage: 'Tags',
  },
  [FacetKeyEnum.SecondarySituations]: {
    id: 'FacetType.secondary_situations',
    defaultMessage: "Type d'établissement",
  },
  [FacetKeyEnum.PreparedDiplomaTitle]: {
    id: 'FacetType.prepared_diploma_title',
    defaultMessage: 'Nom du diplôme préparé',
  },
  [FacetKeyEnum.SchoolNames]: {
    id: 'FacetType.school_names',
    defaultMessage: 'Écoles',
  },
  [FacetKeyEnum.CurrentGrade]: {
    id: 'FacetType.current_grade',
    defaultMessage: "Niveau d'études",
  },
  [FacetKeyEnum.CurrentSchoolName]: {
    id: 'FacetType.current_school_name',
    defaultMessage: 'Établissement',
  },
});

export const ProfessionalTypeTranslations = defineMessages<string>({
  'premium_schools/school_ambassador': {
    id: 'ProfessionalType.company_employee',
    defaultMessage: 'Ambassadeur premium',
  },
  'company/employee': {
    id: 'ProfessionalType.company_employee',
    defaultMessage: 'Ambassadeur premium',
  },
  mentor: {
    id: 'ProfessionalType.mentor',
    defaultMessage: 'Ambassadeur freemium',
  },
});

export const SearchTranslations = defineMessages({
  reset: {
    id: 'Search.reset',
    defaultMessage: 'Réinitialiser',
  },
  resultsCount: {
    id: 'Search.resultsCount',
    defaultMessage: '{count, plural, one { résultat} other { résultats}}',
  },
  showOnlyAvailable: {
    id: 'Search.showOnlyAvailable',
    defaultMessage: 'Disponibilité immédiate',
  },
  blurredOverlayStudentTitle: {
    id: 'Search.blurredOverlayStudentTitle',
    defaultMessage: 'Nouveau : échangez avec des lycéens et des étudiants !',
  },
  blurredOverlayStudentContent: {
    id: 'Search.blurredOverlayStudentContent',
    defaultMessage: `Vous vous interrogez sur votre orientation ou encore sur vos choix d’options ?<br/>
    Vous pourrez très prochainement rencontrer des lycéens ou des étudiants plus avancés que vous dans leur parcours. Ils pourront vous aider en partageant leur expérience et le quotidien de leurs études.<br/><br/>
    Encore un peu de patience... Nous sommes en train de rassembler suffisamment de profils pour que vous trouviez les bonnes personnes avec qui échanger.<br/><br/>
    Nous reviendrons vers vous dans quelques jours, dès que le service sera disponible.`,
  },
});
