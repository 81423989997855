import React from 'react';
import { AppointmentInterlocutor } from '../../../../../../../@types/graphql.d';
import PublicProfileAvatarDisplay from '../../../../../../common/public-profile-avatar-display/PublicProfileAvatarDisplay';
import anonymizedTranslation from '../../../../../../common/common-translations/anonymized.translations';

import styles from './SidebarStudentContactInformationsSection.module.scss';
import genderTranslations from '../../../../../../../scenes/common/common-translations/gender.translations';
import translations from './SidebarStudentContactInformationsSection.translations';
import { useIntl } from 'react-intl';

interface SidebarStudentContactInformationsSectionProps {
  publicProfile: AppointmentInterlocutor;
  isPremiumSchool?: boolean;
}
function SidebarStudentContactInformationsSection({
  publicProfile,
  isPremiumSchool,
}: SidebarStudentContactInformationsSectionProps) {
  const intl = useIntl();

  if (publicProfile?.anonymizedOrDeleted) {
    return (
      <div className={styles.sidebarStudentContactInformationsSection}>
        <p className={styles.userDeleted}>{intl.formatMessage(anonymizedTranslation.userDeleted)}</p>
      </div>
    );
  }

  return (
    <div className={styles.sidebarStudentContactInformationsSection}>
      <PublicProfileAvatarDisplay
        appointmentInterlocutor={publicProfile}
        className={styles.avatar}
        placeholder={isPremiumSchool}
      />
      <div className={styles.studentInformations}>
        <p className={styles.name}>
          {!isPremiumSchool ? (
            <>
              {publicProfile?.firstName}
              &nbsp;
              {publicProfile?.lastName}
            </>
          ) : (
            intl.formatMessage(translations.memberMeeting)
          )}
        </p>
        <p className={styles.contact}>
          {isPremiumSchool ? (
            intl.formatMessage(genderTranslations[publicProfile?.gender])
          ) : (
            <>
              {publicProfile?.email} <br />
              {publicProfile?.phoneNumber}
            </>
          )}
        </p>

        <p className={styles.location}>{publicProfile?.meetingPlace}</p>
      </div>
    </div>
  );
}

export default SidebarStudentContactInformationsSection;
