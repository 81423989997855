import { EmployeeStatus } from '../../../@types/graphql.d';

export default function getStatusColor(status: EmployeeStatus): string {
  switch (status) {
    case EmployeeStatus.Invited:
    case EmployeeStatus.InvitedMultipleTimes:
      return '#FFFFFF';
    case EmployeeStatus.IncompleteProfile:
      return '#F5F5F5';
    case EmployeeStatus.CompleteProfile:
      return '#C5C5C5';
    case EmployeeStatus.Approved:
      return '#979797';
    case EmployeeStatus.Hibernated:
      return '#09193b';
    case EmployeeStatus.Inactive:
      return '#E02020';
    case EmployeeStatus.AtRisk:
      return '#83f4b0';
    case EmployeeStatus.Active:
      return '#31A55F';
    case EmployeeStatus.Unpublished:
      return '#f5f6f7';
    case EmployeeStatus.Archived:
    case EmployeeStatus.Anonymized:
    default:
      return '#000000';
  }
}
