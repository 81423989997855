import React, { memo, useCallback, useState } from 'react';
import DashboardCard, { DashboardCardLoader } from '../../components/dashboard-card/DashboardCard';
import TrophyIcon from '../../../../../icons/component-icons/TrophyIcon';
import { ScoreTranslations } from './Score.translations';
import './Score.scss';
import { useQuery } from '@apollo/client';
import { GET_SCORE_QUERY } from '../../DashboardView.gql';
import { GetScoreQuery } from '../../../../../@types/graphql.d';
import ChevronDownIcon from '../../../../../icons/component-icons/ChevronDownIcon';
import classNames from 'classnames';
import { GamificationScoreNameEnunTranslations } from '../../../../../i18n/gamification_score_name_enum.translations';
import Skeleton from 'react-loading-skeleton';
import { IntlShape, useIntl } from 'react-intl';

export default function Score() {
  const intl = useIntl();
  const { data, loading } = useQuery<GetScoreQuery>(GET_SCORE_QUERY, {
    fetchPolicy: 'network-only',
  });

  const [showDetail, setShowDetail] = useState(false);
  const toggleDetail = useCallback(() => setShowDetail((p) => !p), [showDetail]);

  if (loading) return <ScoreLoader />;
  if (!data?.score) return null;

  const { gamificationRank, gamificationScore, gamificationScoreDetails } = data.score;

  return (
    <DashboardCard
      icon={TrophyIcon}
      title={intl.formatMessage(ScoreTranslations.title)}
    >
      <div className="dashboard-score">
        <div className="dashboard-score__count-tag">
          <TrophyIcon />
          <div className="dashboard-score__percentage">
            {!gamificationRank
              ? intl.formatMessage(ScoreTranslations.rankingUnavailable)
              : intl.formatMessage(ScoreTranslations.rankingStatus, {
                  rank: gamificationRank,
                })}
          </div>
          <div className="dashboard-score__points">{gamificationScore.toLocaleString()}</div>
          <div className="dashboard-score__text">{intl.formatMessage(ScoreTranslations.points)}</div>
        </div>
      </div>

      <button
        className="dashboard-score__see-detail"
        onClick={toggleDetail}
      >
        <ChevronDownIcon className={classNames({ active: showDetail })} />
        {intl.formatMessage(ScoreTranslations.seeDetail)}
      </button>

      {showDetail &&
        gamificationScoreDetails.map(({ name, total }) => (
          <Detail
            key={name}
            {...{ name, total, intl }}
          />
        ))}
    </DashboardCard>
  );
}

type DetailProps = {
  name: string;
  total: number;
  intl: IntlShape;
};

const Detail = memo(({ name, total, intl }: DetailProps) => (
  <div className="dashboard-score__detail">
    {intl.formatMessage(GamificationScoreNameEnunTranslations[name])}

    <div className="dashboard-score__detail__points">
      <strong>{total}</strong>
      points
    </div>
  </div>
));

const ScoreLoader = () => (
  <DashboardCardLoader>
    <div
      className="dashboard-score"
      style={{ borderColor: '#e3e3e3' }}
    >
      <div className="dashboard-score__count-tag">
        <div className="dashboard-score__percentage">
          <Skeleton width={100} />
        </div>
        <div className="dashboard-score__points">
          <Skeleton width={100} />
        </div>
        <div className="dashboard-score__text">
          <Skeleton width={100} />
        </div>
      </div>
    </div>
  </DashboardCardLoader>
);
