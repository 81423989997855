import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'AccountSoftDeletion.title',
    defaultMessage: 'Suppression de votre profil',
    description: 'Titre du bloc de suppression de profil',
  },
  label: {
    id: 'AccountSoftDeletion.label',
    defaultMessage: 'Je souhaite supprimer mon compte',
    description: 'Label du champ pour supprimer son profil',
  },
  appointmentReminderModalTitle: {
    id: 'AccountSoftDeletion.appointmentReminderModalTitle',
    defaultMessage: 'Vous ne pouvez pas supprimer votre compte pour le moment',
  },
  hibernationReminderModalTitle: {
    id: 'AccountSoftDeletion.hibernationReminderModalTitle',
    defaultMessage: 'Supprimer le compte ?',
  },
});
