import * as Yup from 'yup';
import translations from './EditTemplateForm.translations';
import { IntlShape } from 'react-intl';

export const EditTemplateFormValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    title: Yup.string()
      .typeError(intl.formatMessage(translations.requiredTitle))
      .min(2, intl.formatMessage(translations.requiredTitle))
      .required(intl.formatMessage(translations.requiredTitle)),
    body: Yup.string()
      .typeError(intl.formatMessage(translations.requiredDescription))
      .min(1, intl.formatMessage(translations.requiredDescription))
      .required(intl.formatMessage(translations.requiredDescription)),
  });
