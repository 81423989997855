import React from 'react';
import { IconProps } from './Icon';

function CalendarCheckedIcon({ className }: IconProps) {
  return (
    <svg
      viewBox="0 0 16 16"
      className={className}
    >
      <path d="M14.848,2.5H13C13,1.673,12.327,1,11.5,1S10,1.673,10,2.5H6C6,1.673,5.327,1,4.5,1S3,1.673,3,2.5H1.152C0.517,2.5,0,3.068,0,3.767v9.967C0,14.432,0.517,15,1.152,15h13.695C15.483,15,16,14.432,16,13.733V3.767C16,3.068,15.483,2.5,14.848,2.5z M11,2.5C11,2.225,11.225,2,11.5,2S12,2.225,12,2.5v2C12,4.775,11.775,5,11.5,5S11,4.775,11,4.5V2.5z M4,2.5C4,2.225,4.225,2,4.5,2S5,2.225,5,2.5v2C5,4.775,4.775,5,4.5,5S4,4.775,4,4.5V2.5z M15,13.733C15,13.898,14.901,14,14.848,14H1.152C1.099,14,1,13.898,1,13.733V3.767C1,3.602,1.099,3.5,1.152,3.5H3v1C3,5.327,3.673,6,4.5,6S6,5.327,6,4.5v-1h4v1C10,5.327,10.673,6,11.5,6S13,5.327,13,4.5v-1h1.848C14.901,3.5,15,3.602,15,3.767V13.733z" />
      <path d="M11.13,7.164l-3.21,3.531L6.913,9.219C6.757,8.989,6.444,8.933,6.219,9.087C5.99,9.242,5.932,9.554,6.087,9.781l1.363,2c0.086,0.127,0.226,0.207,0.378,0.218C7.84,12,7.852,12,7.863,12c0.141,0,0.274-0.059,0.37-0.164l3.637-4c0.186-0.204,0.171-0.521-0.034-0.706C11.633,6.945,11.316,6.959,11.13,7.164z" />
    </svg>
  );
}

export default CalendarCheckedIcon;
