import React from 'react';
import translations from './StudentFeedbacksCount.translations';

import './StudentFeedbacksCount.scss';
import { useIntl } from 'react-intl';

export type StudentFeedbacksCountProps = {
  feedbacksCount?: number;
};

function StudentFeedbacksCount({ feedbacksCount = 0 }: StudentFeedbacksCountProps) {
  const intl = useIntl();

  return <p className="student-feedbacks-count">{intl.formatMessage(translations.count, { count: feedbacksCount })}</p>;
}

export default StudentFeedbacksCount;
