import { useCallback, useEffect } from 'react';
import filedownload from 'js-file-download';
import * as Sentry from "@sentry/react";
import { errorTracker } from '../../error-tracker/error-tracker';

export default function useDownloadAction(
  uri: string,
  fileName: string,
  mimeType = 'text/plain',
  onLoading: () => void,
  resolve?: () => void,
  reject?: () => void,
): () => void {
  const onSubmit = useCallback(() => {
    const headers = new Headers({
      'X-User-Language': window.localStorage?.getItem('language') || 'fr',
    });
    if (onLoading) {
      onLoading();
    }

    fetch(`${import.meta.env.REACT_APP_DOWNLOAD_HOST}${uri}`, {
      credentials: 'include',
      headers,
    })
      .then((response) => {
        if (response.ok) {
          if (typeof resolve === 'function') {
            resolve();
          }
          return response.blob();
        }
        throw new Error('Network response was not ok.');
      })
      .then((blob) => filedownload(blob, fileName, mimeType))
      .catch((e) => {
        if (typeof reject === 'function') {
          reject();
        }
        const error = new Error(`DownloadAction hook: Error during perform download ${fileName} request`);
        errorTracker.sendError('DownloadAction hook', `Error during perform download ${fileName} request`, {
          namespace: 'Frontend',
          payload: {
            filename: fileName,
          },
          tags: {
            frontendError: JSON.stringify(error),
          },
        });
        useEffect(() => { Sentry.captureException(error) }, [error]);
      });
  }, [uri, fileName]);
  return onSubmit;
}
