import React from 'react';
import { Element } from 'react-scroll';
import TemplateListItem from './components/template-list-item/TemplateListItem';

import styles from './TemplateList.module.scss';
import { ExtendedMessageTemplate } from '../../TemplateManager.gql';
interface TemplateListProps {
  onTemplateClick: (templateId: string) => void;
  messageTemplateIdInEdition?: string | null;
  templates?: ExtendedMessageTemplate[];
}

function TemplateList({ onTemplateClick, messageTemplateIdInEdition, templates }: TemplateListProps) {
  return (
    <ul
      id="message-template-list"
      className={styles.templateList}
    >
      {templates?.map((template) => (
        <Element
          key={template.id || ''}
          name={template.id || ''}
        >
          <TemplateListItem
            template={template}
            active={template.id === messageTemplateIdInEdition}
            onTemplateClick={(templateId) => onTemplateClick(templateId)}
          />
        </Element>
      ))}
    </ul>
  );
}

export default TemplateList;
