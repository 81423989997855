import React from 'react';
import {
  COMPANY_SIZES_BUCKETS_KEYS,
  composeCompanySizesFilter,
  uncomposeCompanySizesFilter,
} from '../../../../../scenes/search/filter-button/company-sizes-filter-button/CompanySizesFilterButton.utils';
import { SearchFilter } from '../../../../../scenes/search/Search.model';
import FiltersDropdownPanel from '../FiltersDropdownPanel';
import { FacetKeyEnum } from '../../../../../@types/graphql.d';
import { useIntl } from 'react-intl';

type CompanySizesFiltersDropdownPanelProps = {
  filters?: SearchFilter;
  onFilterChange: (filter: SearchFilter) => void;
  onClose: () => void;
};

export default function CompanySizesFiltersDropdownPanel({
  filters,
  onFilterChange: onFilterChangeCallback,
  onClose: onCloseCallback,
}: CompanySizesFiltersDropdownPanelProps) {
  const intl = useIntl();
  return (
    <FiltersDropdownPanel
      facetType={FacetKeyEnum.CompanySizes}
      buckets={COMPANY_SIZES_BUCKETS_KEYS.map((b) => ({
        label: intl.formatMessage(b.label),
        key: b.keys[b.keys.length - 1],
      }))}
      filters={filters && uncomposeCompanySizesFilter(filters)}
      onFilterChange={(filter: SearchFilter) => {
        onFilterChangeCallback(composeCompanySizesFilter(filter));
      }}
      onClose={onCloseCallback}
    />
  );
}
