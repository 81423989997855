import { defineMessages } from 'react-intl';

export default defineMessages({
  error: {
    id: 'DownloadAppointmentReport.error',
    defaultMessage: "Erreur : le rapport n'a pas pu être téléchargé. Réessayez.",
  },
  exportButton: {
    id: 'DownloadAppointmentReport.exportButton',
    defaultMessage: 'Exporter',
  },
  dispatchedViaEmail: {
    id: 'DownloadAppointmentReport.dispatchedViaEmail',
    defaultMessage:
      'Votre demande de téléchargement a bien été prise en compte et le rapport vous sera envoyé sur votre email My Job Glasses',
    description: 'Demande de téléchargement envoyée par email',
  },
});
