import React, { memo, useMemo } from 'react';
import { Layout } from './components/layout/Layout';
import StarRadioInput from '../../../../components/star-radio-input/StarRadioInput';
import { PanelProps } from './Panel';
import { useReviewContext } from '../ReviewContext';
import { defineMessages, useIntl } from 'react-intl';
import { BadgeToggleButton } from './components/badge-toggle-button/BadgeToggleButton';
import { getQuestion } from '.';
import { ConversationTypeKeyEnum, UserProfile } from '../../../../@types/graphql.d';

const translations = defineMessages({
  memberTitle: {
    id: 'Review.SecondThirdQuestionsPanel.memberTitle',
    defaultMessage: 'Dites-nous si cet échange vous a permis de vous projeter',
  },
  ambassadorTitle: {
    id: 'Review.SecondThirdQuestionsPanel.ambassadorTitle',
    defaultMessage:
      'Donnez-nous votre première impression {isIndividualAppointment, select, true {sur ce membre suite à votre rencontre} other {suite à cette rencontre collective}}',
  },
  memberIsTooYoung: {
    id: 'Review.SecondThirdQuestionsPanel.memberIsTooYoung',
    defaultMessage: 'Le membre est trop jeune pour se prononcer',
  },
});

export const SecondThirdQuestionsPanel: React.FC<PanelProps> = memo(({ appointment }) => {
  const { reviewFormik } = useReviewContext();
  const { formatMessage } = useIntl();

  const isIndividualAppointment = useMemo(
    () => appointment.appointmentType === ConversationTypeKeyEnum.Individual,
    [appointment],
  );
  const shouldShowMemberIsTooYoungButton = useMemo(() => {
    const {
      currentUserIsAmbassador,
      ambassador: { profileType },
    } = appointment;
    return currentUserIsAmbassador && isIndividualAppointment && profileType !== UserProfile.InSchool;
  }, [isIndividualAppointment, appointment.ambassador.profileType, appointment.currentUserIsAmbassador]);

  return (
    <Layout
      title={formatMessage(appointment.currentUserIsMember ? translations.memberTitle : translations.ambassadorTitle, {
        isIndividualAppointment,
      })}
      style={{ paddingTop: 80 }}
    >
      <StarRadioInput
        style={{ marginTop: 32 }}
        name="question2"
        value={reviewFormik.values.question2?.toString()}
        label={getQuestion(appointment, 2)}
        onSelection={(v) => {
          reviewFormik.setFieldValue('question2', parseInt(v));
          if (reviewFormik.values.tooYoungToAnswer) reviewFormik.setFieldValue('tooYoungToAnswer', false);
        }}
        inline
      />
      <StarRadioInput
        name="question3"
        value={reviewFormik.values.question3?.toString()}
        label={getQuestion(appointment, 3)}
        onSelection={(v) => {
          reviewFormik.setFieldValue('question3', parseInt(v));
          if (reviewFormik.values.tooYoungToAnswer) reviewFormik.setFieldValue('tooYoungToAnswer', false);
        }}
        inline
      />

      {shouldShowMemberIsTooYoungButton && (
        <BadgeToggleButton
          label={formatMessage(translations.memberIsTooYoung)}
          style={{ alignSelf: 'center' }}
          toggled={reviewFormik.values.tooYoungToAnswer}
          onToggleChange={() => {
            if (!reviewFormik.values.tooYoungToAnswer) {
              reviewFormik.setFieldValue('question2', undefined);
              reviewFormik.setFieldValue('question3', undefined);
              reviewFormik.setFieldValue('tooYoungToAnswer', true);
            } else {
              reviewFormik.setFieldValue('tooYoungToAnswer', false);
            }
          }}
        />
      )}
    </Layout>
  );
});
