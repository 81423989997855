import { gql } from '@apollo/client';

export const GET_COMPANY_PRESENTATION_QUERY = gql`
  query GetCompanyPresentation($companyId: ID!) {
    company(companyId: $companyId) {
      id
      description
      name
      canUpdateCompanyDescription
    }
  }
`;

export const UPDATE_COMPANY_DESCRIPTION_MUTATION = gql`
  mutation UpdateCompanyPresentation($companyId: ID!, $description: String!) {
    updateCompany(companyId: $companyId, description: $description)
  }
`;
