import ahoy from 'ahoy.js';

export const PERFORM_SEARCH = '@@search/PERFORM_SEARCH';
export const TRACK_SEARCH = '@@search/TRACK_SEARCH';
export const REQUEST_NEXT_SEARCH_PAGE = '@@search/REQUEST_NEXT_SEARCH_PAGE';
export const SET_SEARCH_FACETS_INDEX = '@@search/SET_SEARCH_FACETS_INDEX';
export const SET_SEARCH_LOADED_STATE = '@@search/SET_SEARCH_LOADED_STATE';
export const RUN_SEARCH_BY_FILTER = '@@search/RUN_SEARCH_BY_FILTER';
export const PULLUP_NOT_FOUND_INFORMATION = '@@search/PULLUP_NOT_FOUND_INFORMATION';

/**
 * Run a search with filters
 * @param {Object} params
 * @param {String} params.name
 * @param {String} params.values
 */
export const runSearchByFilter = (filtersValues) => ({
  type: RUN_SEARCH_BY_FILTER,
  filtersValues,
});

/**
 * Set if search view page is loaded or not
 * @param {Boolean} state
 */
export const setSearchLoadedState = (state) => ({
  type: SET_SEARCH_LOADED_STATE,
  state,
});

/**
 * Track search params to be push to Ahoy
 * @param {Object} search
 *    @param {String} [query]
 *    @param {Object} [filters]
 *    @param {Integer} [page]
 *    @param {Integer} [size = 12]
 *    @param {String} [topSearch]
 */
export const trackSearch = ({ q, filters, page, size = 12 }) => ({
  type: TRACK_SEARCH,
  query: q || undefined, // prevent false value
  filters,
  page,
  size,
});

export async function trackSearchWithFilters(searchQuery, searchMode, filters, searchFacets, currentUserId) {
  const eventProperties = {
    search_query: searchQuery,
    search_mode: searchMode,
    search_filters: filters,
    search_facets: searchFacets,
    user_id: currentUserId.toString(),
  };

  ahoy.track('User searched ambassadors', eventProperties);
}

/**
 * Dispatch an action to request loading of next page
 */
export const goToNextSearchPage = () => ({
  type: REQUEST_NEXT_SEARCH_PAGE,
});

/**
 * Custom index for facets
 * @param {String} hash - hash of current search
 * @param {Object} facets - Facets as object to store
 */
export const setSearchFacetsIndex = (hash, facets) => ({
  type: SET_SEARCH_FACETS_INDEX,
  hash,
  facets,
});

/**
 * @returns {Object}
 */
export const pullupNotFoundInformation = () => ({
  type: PULLUP_NOT_FOUND_INFORMATION,
});
