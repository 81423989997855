import { defineMessages } from 'react-intl';

export default defineMessages({
  previousButton: {
    id: 'GraphqlCursorPagination.previousButton',
    defaultMessage: 'Précédent',
    description: 'Bouton précédent sur la pagination GraphQL',
  },
  nextButton: {
    id: 'GraphqlCursorPagination.nextButton',
    defaultMessage: 'Suivant',
    description: 'Bouton suivant sur la pagination GraphQL',
  },
});
