import { RefObject, useCallback, useEffect } from 'react';

type InputGestureOptions = {
  onSubmit?: () => void;
  onExit?: () => void;
};

export default function useInputGesture(componentRef: RefObject<HTMLElement>, options: InputGestureOptions) {
  const onMouseDown = useCallback(
    (e: MouseEvent) => {
      if (componentRef.current && !componentRef.current.contains(e.target as Node)) {
        options.onExit?.();
      }
    },
    [componentRef, options],
  );

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case 'Enter':
          e.preventDefault();
          options.onSubmit?.();
          break;
        case 'Escape':
          e.preventDefault();
          options.onExit?.();
          break;
        default:
          break;
      }
    },
    [componentRef, options],
  );

  useEffect(() => {
    if (!componentRef.current) return () => {};

    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [componentRef]);
}
