import React from 'react';
import translations from './MisconductReportSuccess.translations';

import useModal from '../../../../../../../components/modal/Modal.hook';
import ButtonsGroup from '../../../../../../../components/buttons-group/ButtonsGroup';
import PrimaryButton from '../../../../../../../components/primary-button/PrimaryButton';
import { useIntl } from 'react-intl';

function MisconductReportSuccess() {
  const intl = useIntl();
  const [, closeModal] = useModal();

  return (
    <div>
      <p>{intl.formatMessage(translations.message)}</p>
      <ButtonsGroup>
        <PrimaryButton
          label={intl.formatMessage(translations.submitButton)}
          onClick={closeModal}
        />
      </ButtonsGroup>
    </div>
  );
}

export default MisconductReportSuccess;
