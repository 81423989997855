import { defineMessages } from 'react-intl';
import {
  UpdateCompanyDescriptionMutationErrors,
  UpdateCompanyMutationErrors,
} from '../../../../../../../@types/graphql-errors.d';

export const errorStatusTranslations = defineMessages({
  [UpdateCompanyDescriptionMutationErrors.DESCRIPTION_TOO_LONG]: {
    id: 'CompanyPresentationWrite.DESCRIPTION_TOO_LONG',
    defaultMessage: 'La description est trop longue, réduisez le nombre de caractères.',
  },
  [UpdateCompanyMutationErrors.UPDATE_COMPANY_NOT_AUTHORIZED]: {
    id: 'CompanyPresentationWrite.UPDATE_COMPANY_NOT_AUTHORIZED',
    defaultMessage: "Vous n'êtes pas autorisé(e) à modifier cette information.",
  },
  [UpdateCompanyMutationErrors.UPDATE_COMPANY_COMPANY_NOT_FOUND]: {
    id: 'CompanyPresentationWrite.UPDATE_COMPANY_COMPANY_NOT_FOUND',
    defaultMessage: 'Entreprise introuvable. Contactez-nous.',
  },
});

export const errorFieldTranslations = defineMessages({
  descriptionTooShort: {
    id: 'CompanyPresentationWrite.descriptionTooShort',
    defaultMessage: 'La description est trop courte, ajoutez plus de caractères.',
  },
});

export default defineMessages({
  cancelButton: {
    id: 'CompanyPresentationWrite.cancelButton',
    defaultMessage: 'Annuler',
  },
  submitButton: {
    id: 'CompanyPresentationWrite.submitButton',
    defaultMessage: 'Valider',
  },
});
