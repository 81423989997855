import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import SelectInput from '../../../components/select-input/SelectInput';
import useDebounce from '../../../hooks/debounce.hook';
import useInputGesture from '../../../hooks/input-gesture.hook';
import BuildingIcon from '../../../icons/component-icons/BuildingIcon';
import GraduateHatIcon from '../../../icons/component-icons/GraduateHatIcon';
import PieChartIcon from '../../../icons/component-icons/PieChartIcon';
import SearchRefractionIcon from '../../../icons/component-icons/SearchRefractionIcon';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  AutocompleteAmbassadorsQuery,
  AutocompleteAmbassadorsQueryVariables,
  FacetKeyEnum,
  SearchFacet,
  SearchTypeEnum,
} from '../../../@types/graphql.d';
import { AUTOCOMPLETE_AMBASSADORS_QUERY } from '../search.gql';
import { FacetTypeTranslations } from '../search.translations';
import { SearchInputTranslations } from './SearchInput.translations';

import { SettingsContext } from '../../../contexts/settings-context/SettingsContext';
import Loader from '../../../icons/ambivalence-icons/loader/Loader';
import './SearchInput.scss';
import { useIntl } from 'react-intl';

const JOB_TITLES_RESULTS_LIMIT = 5;

type SearchInputProps = {
  searchMode: SearchTypeEnum;
  onSelection: (text: string, facetType?: string) => void;
  term: string;
  onTermChange: (term: string) => void;
  featuredFacet?: SearchFacet;
};

export default function SearchInput({
  term,
  searchMode,
  onSelection: onSelectionCallback,
  onTermChange,
  featuredFacet,
}: SearchInputProps) {
  const intl = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setOpenState] = useState(false);

  const settingsContext = useContext(SettingsContext);

  const onFocus = useCallback(() => {
    if (!isOpen) setOpenState(true);
  }, [isOpen]);

  const [debouncedTerm] = useDebounce(term);
  const [featuredFacetPlaceholderIndex, setFeaturedFaetPlaceholderIndex] = useState(0);

  const { data, loading } = useQuery<AutocompleteAmbassadorsQuery, AutocompleteAmbassadorsQueryVariables>(
    AUTOCOMPLETE_AMBASSADORS_QUERY,
    {
      variables: {
        term: [debouncedTerm],
        searchMode,
      },
    },
  );

  const completionJobTitles = useMemo(() => {
    if (searchMode === SearchTypeEnum.OnlyStudents)
      return (
        data?.autocompleteAmbassadors
          .find(({ type }) => type === 'prepared_diploma_titles')
          ?.matchingResults.slice(0, JOB_TITLES_RESULTS_LIMIT) || []
      );
    else
      return (
        data?.autocompleteAmbassadors
          .find(({ type }) => type === 'job_titles')
          ?.matchingResults.slice(0, JOB_TITLES_RESULTS_LIMIT) || []
      );
  }, [data, searchMode]);

  useEffect(() => {
    if (!featuredFacet) return;
    function incrementFeaturedFacetPlaceholderIndex() {
      if (!featuredFacet) return;
      setFeaturedFaetPlaceholderIndex((prev) => (prev + 1) % featuredFacet.buckets.length);
    }

    setInterval(incrementFeaturedFacetPlaceholderIndex, 2500);
  }, [featuredFacet]);

  function onSelection(text: string, facetType: string | undefined = undefined) {
    onSelectionCallback(text, facetType);
    if (!facetType) {
      onTermChange(text);
    }
    setOpenState(false);
  }

  useInputGesture(ref, {
    onExit: () => setOpenState(false),
  });

  return (
    <div
      ref={ref}
      className={classNames('search-input', {
        'search-input--active': isOpen,
      })}
    >
      <input
        data-cy="search-input"
        type="text"
        value={term}
        onChange={(e) => onTermChange(e.target.value)}
        onFocus={onFocus}
        placeholder={intl.formatMessage(
          searchMode === SearchTypeEnum.OnlyProfessionals
            ? SearchInputTranslations.placeholderProfessional
            : SearchInputTranslations.placeholderStudent,
        )}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSelection(term);
          }
        }}
      />

      <button onClick={() => onSelection(term)}>
        <SearchRefractionIcon />
      </button>

      {isOpen && (
        <div
          className={classNames('search-input__dropdown', {
            'search-input__dropdown--active': isOpen,
          })}
        >
          {loading ? (
            <Loader />
          ) : (
            <div className="search-input__dropdown__category">
              {completionJobTitles.length > 0 ? (
                <div className="search-input__dropdown__category__items">
                  {completionJobTitles.map(({ text, bold }) => (
                    <button
                      key={text}
                      type="button"
                      className="search-input__dropdown__category__items__item"
                      onClick={() =>
                        onSelection(
                          text,
                          searchMode === SearchTypeEnum.OnlyProfessionals
                            ? FacetKeyEnum.JobTitle
                            : FacetKeyEnum.PreparedDiplomaTitle,
                        )
                      }
                    >
                      {text.split('').map((char, index) => {
                        if (
                          bold.reduce((acc, { offset, length }) => {
                            if (index >= offset && index < offset + length) return true;
                            return acc;
                          }, false)
                        )
                          return <b key={index}>{char}</b>;
                        return char;
                      })}
                    </button>
                  ))}
                </div>
              ) : (
                featuredFacet &&
                featuredFacet.buckets.length > 0 && (
                  <div className="search-input__dropdown__category__featured-facet">
                    {intl.formatMessage(SearchInputTranslations.featuredFacet)}

                    <SelectInput
                      name="tags"
                      values={featuredFacet.buckets.map(({ key, label }) => ({
                        value: key,
                        translation: label || key,
                      }))}
                      placeholder={
                        featuredFacet.buckets[featuredFacetPlaceholderIndex].label ||
                        featuredFacet.buckets[featuredFacetPlaceholderIndex].key
                      }
                      onChange={(value) => onSelection(value, featuredFacet.key)}
                    />
                  </div>
                )
              )}
            </div>
          )}

          {searchMode === SearchTypeEnum.OnlyStudents
            ? data?.autocompleteAmbassadors
                .filter(({ type, matchingResults }) => type !== 'prepared_diploma_titles' && matchingResults.length > 0)
                .map(({ type, matchingResults }) => (
                  <div
                    key={type}
                    className="search-input__dropdown__category search-input__dropdown__category--border-top"
                  >
                    <div className="search-input__dropdown__category__name">
                      {type.toUpperCase() === FacetKeyEnum.SecondarySituations && (
                        <GraduateHatIcon className="search-input__dropdown__category__name__icon" />
                      )}
                      {type.toUpperCase() === FacetKeyEnum.CurrentSchoolName && (
                        <BuildingIcon className="search-input__dropdown__category__name__icon" />
                      )}
                      {FacetTypeTranslations[type.toUpperCase()]
                        ? intl.formatMessage(FacetTypeTranslations[type.toUpperCase()])
                        : type}
                    </div>

                    <div className="search-input__dropdown__category__items">
                      {matchingResults.map(({ text, bold }) => (
                        <button
                          key={text}
                          type="button"
                          className="search-input__dropdown__category__items__item"
                          onClick={() =>
                            onSelection(
                              settingsContext?.settings?.secondarySituations
                                ?.find(({ name }) => name === text)
                                ?.key.toLowerCase() || text,
                              type.toUpperCase(),
                            )
                          }
                        >
                          {text.split('').map((char, index) => {
                            if (
                              bold.reduce((acc, { offset, length }) => {
                                if (index >= offset && index < offset + length) return true;
                                return acc;
                              }, false)
                            )
                              return <b key={index}>{char}</b>;
                            return char;
                          })}
                        </button>
                      ))}
                    </div>
                  </div>
                ))
            : data?.autocompleteAmbassadors
                .filter(({ type, matchingResults }) => type !== 'job_titles' && matchingResults.length > 0)
                .map(({ type, matchingResults }) => (
                  <div
                    key={type}
                    className="search-input__dropdown__category search-input__dropdown__category--border-top"
                  >
                    <div className="search-input__dropdown__category__name">
                      {type.toUpperCase() === FacetKeyEnum.Sectors && (
                        <PieChartIcon className="search-input__dropdown__category__name__icon" />
                      )}
                      {type.toUpperCase() === FacetKeyEnum.Companies && (
                        <BuildingIcon className="search-input__dropdown__category__name__icon" />
                      )}
                      {type.toUpperCase() === FacetKeyEnum.SchoolNames && (
                        <GraduateHatIcon className="search-input__dropdown__category__name__icon" />
                      )}
                      {FacetTypeTranslations[type.toUpperCase()]
                        ? intl.formatMessage(FacetTypeTranslations[type.toUpperCase()])
                        : type}
                    </div>

                    <div className="search-input__dropdown__category__items">
                      {matchingResults.map(({ text, bold }) => (
                        <button
                          key={text}
                          type="button"
                          className="search-input__dropdown__category__items__item"
                          onClick={() => onSelection(text, type.toUpperCase())}
                        >
                          {text.split('').map((char, index) => {
                            if (
                              bold.reduce((acc, { offset, length }) => {
                                if (index >= offset && index < offset + length) return true;
                                return acc;
                              }, false)
                            )
                              return <b key={index}>{char}</b>;
                            return char;
                          })}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
        </div>
      )}
    </div>
  );
}
