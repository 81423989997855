import React from 'react';
import MosaicImage from '../../../../../assets/images/mjg-mosaic.png';
import translations from './FollowUs.translations';

import './FollowUs.scss';
import { useIntl } from 'react-intl';

const NEWS_LINK = import.meta.env.REACT_APP_MJG_NEWS_LINK || '';

export default function FollowUs() {
  const intl = useIntl();
  return (
    <div className="dashboard-student-follow-us">
      <a
        href={NEWS_LINK}
        target="_blank"
        rel="noopener noreferrer"
        className="dashboard-student-follow-us__link"
      >
        <img
          className="dashboard-student-follow-us__link__mosaic"
          src={MosaicImage}
          alt="My Job Glasses mosaic of personas"
        />
        <div
          className="dashboard-student-follow-us__link__text"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(translations.text),
          }}
        />
      </a>
    </div>
  );
}
