import React from 'react';
import classnames from 'classnames';
import moment from 'moment-timezone';
import FormatDateTime from './components/format-date-time/FormatDateTime';

import styles from './ContactDateButton.module.scss';

interface ContactDateButtonProps {
  title: string;
  datetime: moment.Moment | string;
  icon: (props: { className: string }) => JSX.Element;
  onClick: () => void;
  format?: 'hour minute' | 'day month year';
}
function ContactDateButton({ title, datetime, icon: Icon, format, onClick }: ContactDateButtonProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classnames(styles.contactDateButton, 'contact-date-button')}
      data-cy="contact-date-button"
    >
      <Icon className={styles.icon} />
      <div className={styles.value}>
        {datetime ? (
          <FormatDateTime
            datetime={moment(datetime).toISOString()}
            format={format}
          />
        ) : (
          title
        )}
      </div>
    </button>
  );
}

export default ContactDateButton;
