import React from 'react';
import { PropsWithChildren, useEffect } from 'react';
import './Modal.scss';
import useModal from './Modal.hook';
import XCloseIcon from '../../icons/component-icons/XCloseIcon';
import classNames from 'classnames';

export type ModalOptions = {
  centeredContent?: boolean;
  greyBackground?: boolean;
  accentHeader?: boolean;
  maxWidth?: number;
};

type ModalProps = PropsWithChildren<{
  title?: string;
  options?: ModalOptions;
}>;

export default function Modal({ title, options, children }: ModalProps) {
  const [, closeModal] = useModal();

  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <div className="appfrontend-modal">
      <button
        className="appfrontend-modal__overlay"
        onClick={closeModal}
      />

      <div
        className={classNames('appfrontend-modal__container', {
          'appfrontend-modal__container--grey-background': options?.greyBackground,
        })}
        style={options?.maxWidth ? { width: `min(90%, ${options.maxWidth}px)` } : {}}
      >
        <header
          className={classNames('appfrontend-modal__container__header', {
            'appfrontend-modal__container__header--accent-header': options?.accentHeader,
          })}
        >
          {title && <h2>{title}</h2>}
          <button
            className="appfrontend-modal__container__header__close-button"
            onClick={closeModal}
          >
            <XCloseIcon />
          </button>
        </header>
        <div
          className={classNames('appfrontend-modal__container__content', {
            'appfrontend-modal__container__content--centered': options?.centeredContent,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
