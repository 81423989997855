import React, { useEffect } from 'react';
import PageLoader from '../common/page-loader/PageLoader';

function SchoolAssignmentsRouter() {
  useEffect(() => {
    if (import.meta.env.REACT_APP_SELF_SERVICE_FRONTEND_URL) {
      window.location.replace(import.meta.env.REACT_APP_SELF_SERVICE_FRONTEND_URL);
    }
  }, []);

  return <PageLoader />;
}

export default SchoolAssignmentsRouter;
