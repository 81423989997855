import React, { useEffect, useLayoutEffect } from 'react';
import { useCallback, useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import ButtonsGroup from '../../../components/buttons-group/ButtonsGroup';
import useModal from '../../../components/modal/Modal.hook';
import './AvatarCropModal.scss';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import SecondaryButton from '../../../components/secondary-button/SecondaryButton';
import { GeneralTranslations } from '../../../i18n/general.translations';
import { fillSpaceToMakeImageSquare, getCroppedImage } from './AvatarInput.util';
import { useIntl } from 'react-intl';
import BounceLoader from 'react-spinners/BounceLoader';
import classNames from 'classnames';

type Point = { x: number, y: number };
const INITIAL_CROP_VALUE = { x: 0, y: 0 };
const INITIAL_CROPPED_AREA_PERCENTAGES = {
  x: 0,
  y: 0,
  width: 100,
  height: 100,
};

type AvatarCropModalProps = {
  value: string;
  onCropComplete: (file: File) => void;
};

export default function AvatarCropModal({ value, onCropComplete }: AvatarCropModalProps) {
  const intl = useIntl();
  const [, closeModal] = useModal();

  const [v, setV] = useState<string>();
  const [crop, setCrop] = useState<Point>(INITIAL_CROP_VALUE);
  const [zoom, setZoom] = useState(1);
  const [cropAreaPixels, setCropAreaPixels] = useState<Area>();

  useLayoutEffect(() => {
    if (value) {
      initImage();
    }
  }, [value]);

  const initImage = () => {
    const image = new Image();
    image.src = value;
    // Wait for image to load in HTMLImageElement
    setTimeout(() => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) return;
      setV(fillSpaceToMakeImageSquare(canvas, ctx, image));
    }, 50);
  };

  const handleCrop = useCallback(
    (_: Area, cropAreaPixels: Area) => {
      if (!value) return;
      setCropAreaPixels(cropAreaPixels);
    },
    [value],
  );

  const submitCrop = useCallback(async () => {
    if (!cropAreaPixels || !v) return;
    const blob = await getCroppedImage(v, cropAreaPixels);
    window.open(URL.createObjectURL(blob!), '_blank');
    if (blob) onCropComplete(new File([blob], 'avatar.jpg', { type: 'image/jpeg' }));
  }, [cropAreaPixels, v, onCropComplete]);

  return (
    <div className="avatar-crop-modal">
      <div className={classNames("avatar-crop-modal__container", { 'avatar-crop-modal__container--loading': !v })}>
        {v ? (
          <Cropper
            image={v}
            crop={crop}
            zoom={zoom}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            minZoom={1}
            aspect={1}
            initialCroppedAreaPercentages={INITIAL_CROPPED_AREA_PERCENTAGES}
            onCropComplete={handleCrop}
            cropShape='round'
          />
        ) : (
          <BounceLoader color='#09193b' />
        )}
      </div>

      <ButtonsGroup>
        <SecondaryButton
          label={intl.formatMessage(GeneralTranslations.cancel)}
          onClick={closeModal}
        />
        <PrimaryButton
          label={intl.formatMessage(GeneralTranslations.confirm)}
          onClick={submitCrop}
        />
      </ButtonsGroup>
    </div>
  );
}
