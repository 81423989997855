import React, { ComponentType, memo, useCallback, useContext, useMemo, useState } from 'react';
import { Appointment, Claim, ReviewInterlocutor, SNOOZE_CLAIM, SNOOZE_REVIEW } from '../../Review.gql';
import getMarketplaceConfig from '../../../../layout/header/config/marketplace/marketplace';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import {
  PrimarySituationEnum,
  SnoozeClaimMutation,
  SnoozeClaimMutationVariables,
  SnoozeReviewMutation,
  SnoozeReviewMutationVariables,
} from '../../../../../@types/graphql.d';
import { AnimatePresence, motion } from 'motion/react';
import PrimaryButton from '../../../../../components/primary-button/PrimaryButton';
import { useMutation } from '@apollo/client';

import './Header.scss';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { CurrentUserContext } from '../../../../../contexts/current-user-context/CurrentUserContext';
import { IconProps } from '../../../../../icons/component-icons/Icon';
import SwitchIcon from '../../../../../icons/component-icons/SwitchIcon';
import { generatePath, useNavigate } from 'react-router';
import useModal from '../../../../../components/modal/Modal.hook';
import ButtonsGroup from '../../../../../components/buttons-group/ButtonsGroup';
import SecondaryButton from '../../../../../components/secondary-button/SecondaryButton';
import buttonCommonTranslations from '../../../../common/common-translations/button.translations';
import classNames from 'classnames';
import Routes from '../../../../../routes';
import { isExternalUrl } from '../../../../../utils/redirection.util';
import { MJGFormattedMessage } from '../../../../../components/mjg-formatted-message/MJGFormattedMessage';

const translations = defineMessages({
  userDetails: {
    id: 'Review.Header.userDetails',
    defaultMessage: 'Évaluez votre rencontre avec {firstName}',
  },
  snoozeReview: {
    id: 'Review.Header.snoozeReview',
    defaultMessage: 'Évaluer plus tard',
  },
  upcomingNotif: {
    id: 'Review.Header.upcomingNotif',
    defaultMessage: 'Vous avez <strong>{count} rendez-vous</strong> à évaluer',
  }
});

type Props = {
  appointment: Appointment | Claim;
  interlocutor?: ReviewInterlocutor;
  upcomingReviewCount: number;
  isClaim?: boolean;
};

export const Header: React.FC<Props> = memo(({ appointment, interlocutor, upcomingReviewCount, isClaim }) => {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const isStudent = useMemo(
    () => interlocutor?.primarySituation?.key === PrimarySituationEnum.Student,
    [interlocutor?.primarySituation?.key],
  );

  const [snoozeReview] = useMutation<SnoozeReviewMutation, SnoozeReviewMutationVariables>(SNOOZE_REVIEW, {
    variables: {
      id: appointment.id,
    },
    refetchQueries: ['GetFirstAppointmentToForceReview'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      navigate(Routes.home);
    },
  });

  const [snoozeClaim] = useMutation<SnoozeClaimMutation, SnoozeClaimMutationVariables>(SNOOZE_CLAIM, {
    variables: {
      id: appointment.id,
    },
    refetchQueries: ['GetFirstAppointmentClaimToForceReview'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      navigate(Routes.home);
    },
  });

  return (
    <header className="review-view-header">
      <div className="review-view-header__container">
        <img
          className="review-view-header__container__logo"
          src="https://assets.myjobglasses.com/logo/mjg-logo-v3-rectangle.svg"
        />

        {!isMobile && (
          <AnimatePresence>
            {interlocutor && (
              <motion.div
                initial={{ opacity: 0, translateY: -50 }}
                animate={{ opacity: 1, translateY: 0 }}
                exit={{ opacity: 0, translateY: -50 }}
                transition={{ duration: 0.5 }}
                className="review-view-header__container__interlocutor"
              >
                <img
                  src={interlocutor.avatar.url || ''}
                  className="review-view-header__container__interlocutor__avatar"
                />
                <div>
                  {intl.formatMessage(translations.userDetails, { firstName: interlocutor.firstName })} (
                  {isStudent ? interlocutor.currentSchoolName : interlocutor.companyName})
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        )}

        <div className="review-view-header__container__right">
          {appointment.currentUserCanSnooze && (
            <PrimaryButton
              label={intl.formatMessage(translations.snoozeReview)}
              onClick={isClaim ? snoozeClaim : snoozeReview}
              outlined
              smaller
            />
          )}
          {!isMobile && <HeaderMenu />}
        </div>
      </div>

      <AnimatePresence>
        {!interlocutor && upcomingReviewCount > 1 && (
          <motion.div
            className="review-view-header__upcoming-notif"
            initial={{ translateX: '-50%', translateY: isMobile ? '-300%' : '-100%', opacity: 0, scale: 0.8 }}
            animate={{ translateX: '-50%', translateY: 0, opacity: 1, scale: 1 }}
            exit={{ translateX: '-50%', translateY: isMobile ? '-300%' : '-100%', opacity: 0, scale: 0.8, transition: { delay: 0 }}}
            transition={{ duration: 0.5, delay: 1, type: 'spring' }}
          >
            <MJGFormattedMessage translation={translations.upcomingNotif} values={{ count: upcomingReviewCount }} />
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
});

const HeaderMenu = memo(() => {
  const intl = useIntl();
  const { currentUser } = useContext(CurrentUserContext);

  const menuItems = useMemo(() => {
    let config = getMarketplaceConfig(intl).connected;

    if (currentUser?.companyAdminProfiles && currentUser.companyAdminProfiles.length > 0)
      config = config.concat(currentUser.companyAdminProfiles.map(
        (profile) =>
          ({
            type: 'switch-account',
            key: profile.company.id || '',
            to: generatePath(Routes.connectCompanyHomeWithId, {
              id: profile.company.id,
            }),
            label: profile.company.name,
          }) as any,
      ));

    if (currentUser?.schoolAdminProfiles && currentUser.schoolAdminProfiles.length > 0)
      config = config.concat(currentUser.schoolAdminProfiles.map(
        (profile) =>
          ({
            type: 'switch-account',
            key: profile.company.id || '',
            to: generatePath(Routes.companyAdminDashboardWithId, {
              companyid: profile.company.id,
            }),
            label: profile.company.name,
          }) as any,
      ));
    
    return config;
  }, [currentUser?.companyAdminProfiles, currentUser?.schoolAdminProfiles]);

  const [open, setOpen] = useState(false);

  return (
    <div className="review-view-header-menu">
      <img
        className="review-view-header-menu__avatar"
        src={currentUser?.avatars.url || ''}
        onClick={() => setOpen(!open)}
      />
      <AnimatePresence>
        {open && (
          <motion.menu
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
            className="review-view-header-menu__menu"
          >
            {menuItems.map(({ key, ...rest }) => (
              <HeaderMenuItem
                key={key}
                {...rest}
              />
            ))}
          </motion.menu>
        )}
      </AnimatePresence>
    </div>
  );
});

type HeaderMenuItemProps = {
  type: string;
  label: string;
  icon: ComponentType<IconProps>;
  to: string;
};

const confirmModalTranslations = defineMessages({
  title: {
    id: 'Review.Header.confirmModal.title',
    defaultMessage: "Quitter l'évaluation en cours",
  },
  content: {
    id: 'Review.Header.confirmModal.content',
    defaultMessage: 'Si vous arrêtez votre évaluation, vous devrez la reprendre depuis le début.',
  },
});

const HeaderMenuItem: React.FC<HeaderMenuItemProps> = memo(({ type, label, icon, to }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [openModal, closeModal] = useModal();

  const isSwitch = type.includes('switch');
  const Icon = isSwitch ? SwitchIcon : icon;

  const onConfirm = useCallback(() => {
    if (isExternalUrl(to)) {
      window.location.href = to;
    } else {
      navigate(to);
      closeModal();
    }
  }, [to]);

  const onItemClick = useCallback(() => {
    openModal({
      title: formatMessage(confirmModalTranslations.title),
      content: (
        <div>
          <p>{formatMessage(confirmModalTranslations.content)}</p>
          <ButtonsGroup>
            <PrimaryButton
              label={formatMessage(buttonCommonTranslations.confirm)}
              onClick={onConfirm}
            />
            <SecondaryButton
              label={formatMessage(buttonCommonTranslations.cancel)}
              onClick={closeModal}
            />
          </ButtonsGroup>
        </div>
      ),
    });
  }, [onConfirm, closeModal]);

  return (
    <button
      className={classNames('review-view-header-menu__menu__item', {
        'review-view-header-menu__menu__item--switch': isSwitch,
      })}
      onClick={onItemClick}
    >
      <Icon />
      <label>{label}</label>
    </button>
  );
});
