import { defineMessages } from 'react-intl';

export const AmbassadorViewCompanyPresentationTranslations = defineMessages({
  title: {
    id: 'AmbassadorViewCompanyPresentation.title',
    defaultMessage: 'Présentation de {companyName}',
  },
  seeEmployees: {
    id: 'AmbassadorViewCompanyPresentation.seeEmployees',
    defaultMessage: 'Voir tous les Ambassadeurs',
  },
});
