import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import { useOutsideClickEffect } from '../../../utils/hooks/useOutsideClickEffect';
import translations from './WithConfirm.translations';
import styles from './WithConfirm.module.scss';
import { useIntl } from 'react-intl';

interface WithConfirmProps {
  title: string;
  text: string;
  children: (params: { closeConfirmationBox: () => void; openConfirmationBox: () => void }) => JSX.Element;
  className?: string;
  onConfirm: () => void;
}

function WithConfirm({ title, text, children, className, onConfirm }: WithConfirmProps) {
  const intl = useIntl();
  const componentRef = useRef(null);
  const [isVisibleConfirmationBox, setIsVisibleConfirmationBox] = useState(false);
  const closeConfirmationBox = () => setIsVisibleConfirmationBox(false);
  const openConfirmationBox = () => setIsVisibleConfirmationBox(true);
  useOutsideClickEffect(closeConfirmationBox, componentRef);
  function handleConfirmation() {
    onConfirm();
    closeConfirmationBox();
  }
  return (
    <div
      className={classnames(styles.wrapper, className)}
      ref={componentRef}
    >
      {children({ closeConfirmationBox, openConfirmationBox })}
      {isVisibleConfirmationBox && (
        <div className={styles.confirmationBox}>
          <h5 className={styles.title}>{title}</h5>
          <p className={styles.content}>{text}</p>
          <div className={styles.actions}>
            <button
              type="button"
              onClick={closeConfirmationBox}
              className={styles.button}
              data-testid="with-confirm-cancel-button"
            >
              {intl.formatMessage(translations.cancelButton)}
            </button>
            <button
              type="button"
              onClick={handleConfirmation}
              className={styles.button}
              data-testid="with-confirm-confirm-button"
            >
              {intl.formatMessage(translations.confirmButton)}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default WithConfirm;
