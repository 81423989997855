import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'CompanyAdminAppointmentSidebar.appointment.title',
    defaultMessage: 'Rendez-vous',
  },
  tookPlace: {
    id: 'CompanyAdminAppointmentSidebar.appointment.tookPlace',
    defaultMessage: 'Réalisé le {date} à {hour}',
  },
});
