import React from 'react';
import classnames from 'classnames';
import translations from './CompanyAdminDashboardFeedback.translations';

import styles from './CompanyAdminDashboardFeedback.module.scss';
import { useIntl } from 'react-intl';

interface CompanyAdminDashboardFeedbackProps {
  className?: string;
}
function CompanyAdminDashboardFeedback({ className }: CompanyAdminDashboardFeedbackProps) {
  const intl = useIntl();
  return (
    <div className={classnames(className, styles.feedback)}>
      <p className={styles.content}>
        <span className={styles.title}>{intl.formatMessage(translations.title)}</span>
        <br />
        <span className={styles.description}>{intl.formatMessage(translations.description)}</span>
        <a
          href={`mailto:${import.meta.env.REACT_APP_FEEDBACK_EMAIL}`}
          className={styles.email}
        >
          {import.meta.env.REACT_APP_FEEDBACK_EMAIL}
        </a>
      </p>
    </div>
  );
}

export default CompanyAdminDashboardFeedback;
