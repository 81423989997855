import { defineMessages } from 'react-intl';

export default defineMessages({
  success: {
    id: 'employeeShow.usePublishAction.success',
    defaultMessage: '1 profil publié',
    description: 'succes profiles publiés',
  },
  oneProfileCannotPublished: {
    id: 'employeeShow.usePublishAction.oneProfileCannotPublished',
    defaultMessage: "Désolé, le profil n'a pas pu être publié",
    description: 'un profil non publiable',
  },
});
