import { AnimatePresence, motion } from 'motion/react';
import React, { memo, useCallback, useContext, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { generatePath, Navigate, useNavigate, useParams, useSearchParams } from 'react-router';

import { useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { mixed, object, string, number, array } from 'yup';

import './NewConversation.scss';

import {
  ConversationTypeKeyEnum,
  CreateConversationMutation,
  CreateConversationMutationVariables,
  CreatePerceptionAnswersMutation,
  CreatePerceptionAnswersMutationVariables,
  GetMessageCreationInterlocutorDetailsQuery,
  GetMessageCreationInterlocutorDetailsQueryVariables,
  GetPerceptionQuestionQuery,
  GetPerceptionQuestionQueryVariables,
} from '../../../@types/graphql.d';
import Card from '../../../components/card/Card';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { SimilarProsCarousel } from '../../../components/similar-pros-carousel/SimilarProsCarousel';
import { Stepper } from '../../../components/stepper/Stepper';
import { CurrentUserContext } from '../../../contexts/current-user-context/CurrentUserContext';
import useIsMobile from '../../../hooks/useIsMobile';
import Routes from '../../../routes';
import {
  CREATE_CONVERSATION,
  CREATE_PERCEPTION_ANSWERS,
  GET_MESSAGE_CREATION_INTERLOCUTOR_DETAILS,
  GET_PERCEPTION_QUESTION,
} from './NewConversation.gql';
import { FormikValues, STEPS } from './Step';
import { InterlocutorSection } from './sections/interlocutor-section/InterlocutorSection';

export const TRANSLATIONS = defineMessages({
  sentSuccessfuly: {
    id: 'NewConversation.sentSuccessfuly',
    defaultMessage: 'Votre message a bien été envoyé !',
  },
  notSent: {
    id: 'NewConversation.notSent',
    defaultMessage: 'Une erreur est survenue, veuillez contacter notre support.',
  },
  suggestedProfiles: {
    id: 'NewConversation.suggestedProfiles',
    defaultMessage: 'Profils suggérés',
  },
  newSearch: {
    id: 'NewConversation.newSearch',
    defaultMessage: 'Faire une nouvelle recherche',
  },
});

const VALIDATION_SCHEMA = object().shape({
  message: string()
    .required()
    .test('len', 'Le message doit contenir au moins 10 caractères', (val) => val.length >= 10),
  group: object().shape({
    appointmentType: mixed<ConversationTypeKeyEnum>().oneOf(Object.values(ConversationTypeKeyEnum)),
    girlCount: number().when('appointmentType', {
      is: ConversationTypeKeyEnum.ClassGroup || ConversationTypeKeyEnum.ProgramGroup,
      then: (schema) => schema.required().positive(),
      otherwise: (schema) => schema.nullable(),
    }),
    boyCount: number().when('appointmentType', {
      is: ConversationTypeKeyEnum.ClassGroup || ConversationTypeKeyEnum.ProgramGroup,
      then: (schema) => schema.required().positive(),
      otherwise: (schema) => schema.nullable(),
    }),
    accompaniedStudyLevels: array().when('appointmentType', {
      is: ConversationTypeKeyEnum.ClassGroup,
      then: (schema) => schema.required().min(1),
    }),
    supportPrograms: array().when('appointmentType', {
      is: ConversationTypeKeyEnum.ProgramGroup,
      then: (schema) => schema.required().min(1),
    }),
  }),
});

type Props = {
  fromWidget?: boolean;
};

export const NewConversation: React.FC<Props> = memo(({ fromWidget }) => {
  const isMobile = useIsMobile();
  const { formatMessage } = useIntl();
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id: userId } = useParams<{ id?: string }>();
  const currentUserContext = useContext(CurrentUserContext);
  const [searchParams] = useSearchParams();

  const steps = useMemo(() => (fromWidget ? STEPS.widget : STEPS.normal), [fromWidget, STEPS]);
  const [step, setStep] = useState(parseInt(searchParams.get('step') || '0') || 0);
  const [answers, setAnswers] = useState<{ [key: string]: number }>({});

  const { data, loading } = useQuery<
    GetMessageCreationInterlocutorDetailsQuery,
    GetMessageCreationInterlocutorDetailsQueryVariables
  >(GET_MESSAGE_CREATION_INTERLOCUTOR_DETAILS, { variables: { id: userId! }, skip: !userId });

  const { data: perceptionData } = useQuery<GetPerceptionQuestionQuery, GetPerceptionQuestionQueryVariables>(
    GET_PERCEPTION_QUESTION,
    { variables: { professionalId: userId || '' } },
  );

  const perceptionQuestionId = perceptionData?.perceptionQuestions?.id || null;

  const [createPerceptionAnswers] = useMutation<
    CreatePerceptionAnswersMutation,
    CreatePerceptionAnswersMutationVariables
  >(CREATE_PERCEPTION_ANSWERS, {
    onCompleted: (data) => {
      if (!data.createPerceptionAnswers || !data.createPerceptionAnswers[0].conversation?.id) return;
      navigate(generatePath(Routes.conversation, { id: data.createPerceptionAnswers[0].conversation?.id }));
    },
  });
  const [createConversation] = useMutation<CreateConversationMutation, CreateConversationMutationVariables>(
    CREATE_CONVERSATION,
    {
      onCompleted: async ({ createConversation: data }) => {
        if (!data?.id) return;

        if (perceptionQuestionId) {
          const formattedAnswers = Object.entries(answers).map(([attributeId, rating]) => ({
            perceptionQuestionAttributeId: attributeId,
            rating,
          }));

          await createPerceptionAnswers({
            variables: {
              conversationId: data.id,
              perceptionQuestionId: perceptionQuestionId || '',
              answers: formattedAnswers,
            },
          });
        }

        snackbar(formatMessage(TRANSLATIONS.sentSuccessfuly), { variant: 'success' });
        if (!perceptionQuestionId) navigate(generatePath(Routes.conversation, { id: data.id }));
      },
      onError: () => {
        snackbar(formatMessage(TRANSLATIONS.notSent), { variant: 'error' });
      },
    },
  );

  const formik = useFormik<FormikValues>({
    initialValues: {
      interlocutorId: userId || '',
      messageParts:
        currentUserContext?.currentUser?.memberPresentationDraft &&
        currentUserContext?.currentUser?.memberExplanationDraft
          ? [
              currentUserContext.currentUser.memberPresentationDraft,
              currentUserContext.currentUser.memberExplanationDraft,
            ]
          : [],
      message: '',
    },
    validationSchema: VALIDATION_SCHEMA,
    validateOnMount: true,
    onSubmit: ({ message, group, cantContactError }) => {
      if (cantContactError) return;

      const shouldSaveDrafts = formik.values.messageParts[0] && formik.values.messageParts[1];
      if (group && group.appointmentType !== ConversationTypeKeyEnum.Individual) {
        createConversation({
          variables: {
            userId: userId!,
            message,
            draftInput: shouldSaveDrafts
              ? {
                  presentationMessage: formik.values.messageParts[0],
                  explanationMessage: formik.values.messageParts[1],
                }
              : undefined,
            conversationType: group.appointmentType,
            groupSessionInput: {
              femaleCount: group.girlCount || 0,
              maleCount: group.boyCount || 0,
              classes: group.accompaniedStudyLevels,
              programs: group.supportPrograms,
            },
          },
        });
      } else {
        createConversation({
          variables: {
            userId: userId!,
            message,
            draftInput: shouldSaveDrafts
              ? {
                  presentationMessage: formik.values.messageParts[0],
                  explanationMessage: formik.values.messageParts[1],
                }
              : undefined,
          },
        });
      }
    },
  });

  const isLastStep = useMemo(() => step === steps.length - 1, [step, steps]);

  const allAnswersSelected = perceptionData?.perceptionQuestions?.questionAttributes?.every(
    (attribute) => answers[attribute.id] !== undefined,
  );

  const cantContactDueToGroupPreference = useMemo(() => {
    const groupConversationTypes = [ConversationTypeKeyEnum.ClassGroup, ConversationTypeKeyEnum.ProgramGroup];
    return (
      data?.interlocutor.acceptGroupConversations === false &&
      groupConversationTypes.includes(formik.values.group?.appointmentType || ConversationTypeKeyEnum.Individual)
    );
  }, [formik, data?.interlocutor.acceptGroupConversations]);

  const perceptionValidation = useMemo(() => {
    if (!isLastStep) return false;
    return perceptionQuestionId && !allAnswersSelected;
  }, [isLastStep, perceptionQuestionId, allAnswersSelected]);

  const nextStep = useCallback(() => {
    if (step < steps.length - 1) setStep((s) => s + 1);
    else formik.submitForm();
  }, [step]);

  if (!userId) return <Navigate to={Routes.dashboard} />;
  if (loading) return null;
  if (!data?.interlocutor) return <Navigate to={generatePath(Routes.professional, { id: userId })} />;

  const Step = steps[step].component;

  return (
    <div
      className="new-conversation"
      style={isMobile ? { marginTop: 192 } : undefined}
    >
      <div className="new-conversation__header">
        <AnimatePresence>
          {/* {step > 0 && (
            <motion.div
              initial={{ width: 0, marginRight: 0 }}
              animate={{ width: 'auto', marginRight: 16 }}
              exit={{ width: 0, marginRight: 0 }}
            >
              <Card>
                <IconButtonWithText
                  label={formatMessage(buttonCommonTranslations.back)}
                  icon={ChevronLeftIcon}
                  onClick={() => setStep((p) => p - 1)}
                />
              </Card>
            </motion.div>
          )} */}
        </AnimatePresence>
        <Stepper
          style={{ gridArea: 'stepper' }}
          currentStep={step}
          steps={steps.map(({ title }) => formatMessage(title))}
        />
      </div>

      <div className="new-conversation__message">
        <AnimatePresence mode="popLayout">
          <motion.div
            key={step}
            initial={step > 0 && { opacity: 0, translateX: '25%', scale: 0.5 }}
            animate={step > 0 && { opacity: 1, translateX: 0, scale: 1 }}
            exit={{ opacity: 0, translateX: '-50%', scale: 0.5 }}
            transition={{ duration: 0.5, type: 'spring' }}
          >
            {formik.values.cantContactError ? (
              <>
                <p className="new-conversation__message__error">{formik.values.cantContactError}</p>
                <Card title={formatMessage(TRANSLATIONS.suggestedProfiles)}>
                  <SimilarProsCarousel interlocutorUserId={data.interlocutor.id} />
                  <PrimaryButton
                    style={{ margin: '32px auto 8px' }}
                    label={formatMessage(TRANSLATIONS.newSearch)}
                    onClick={() => navigate(Routes.search)}
                  />
                </Card>
              </>
            ) : (
              <Step
                step={step}
                formik={formik}
                cantContactDueToGroupPreference={cantContactDueToGroupPreference}
                fromWidget={fromWidget}
                interlocutor={data.interlocutor}
                perception={perceptionData?.perceptionQuestions}
                setAnswers={setAnswers}
                nextStep={nextStep}
              />
            )}
          </motion.div>
        </AnimatePresence>
      </div>

      <InterlocutorSection
        interlocutor={data.interlocutor}
        className="new-conversation__interlocutor"
        userId={userId}
        submitDisabled={loading || !formik.isValid || perceptionValidation || false}
        onSubmit={nextStep}
        confirm={isLastStep}
        fromWidget={fromWidget}
      />
    </div>
  );
});
