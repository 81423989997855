import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useMatch, useParams } from 'react-router';

import { CompanyViewParams } from '../../../../../@types/routes-params.d';
import {
  Get_Company_Recruiting_Working_AreasQuery,
  Get_Company_Recruiting_Working_AreasQueryVariables,
} from '../../../../../@types/graphql.d';
import Routes from '../../../../../routes';

import RecruitingWorkingAreasRead from './components/recruiting-working-areas-read/RecruitingWorkingAreasRead';
import ConfigurationBloc from '../configuration-bloc/ConfigurationBloc';
import RecruitingWorkingAreasWrite from './components/recruiting-working-areas-write/RecruitingWorkingAreasWrite';
import RecruitingWorkingAreasEmpty from './components/recruiting-working-areas-empty/RecruitingWorkingAreasEmpty';
import { GET_COMPANY_RECRUITING_WORKING_AREAS } from './RecruitingWorkingAreas.gql';
import translations from './RecruitingWorkingAreas.translations';
import { useIntl } from 'react-intl';

type RecruitingWorkingAreasProps = {
  className?: string;
};

function RecruitingWorkingAreas({ className }: RecruitingWorkingAreasProps) {
  const intl = useIntl();
  const { companyid } = useParams();
  const { data } = useQuery<
    Get_Company_Recruiting_Working_AreasQuery,
    Get_Company_Recruiting_Working_AreasQueryVariables
  >(GET_COMPANY_RECRUITING_WORKING_AREAS, {
    fetchPolicy: 'cache-and-network',
    skip: !companyid,
    variables: {
      companyId: companyid || '',
    },
  });

  const [workingAreas, setWorkingAreas] = useState<string[]>([]);

  useEffect(() => {
    if (data?.company?.recruitingWorkingAreas) {
      setWorkingAreas(data.company.recruitingWorkingAreas);
    }
  }, [data?.company?.recruitingWorkingAreas]);

  const isPremiumSchool = data?.company?.isPremiumSchool;

  return (
    <ConfigurationBloc
      title={isPremiumSchool ? intl.formatMessage(translations.schoolTitle) : intl.formatMessage(translations.title)}
      description={
        isPremiumSchool
          ? intl.formatMessage(translations.schoolDescription)
          : intl.formatMessage(translations.description)
      }
      className={className}
      readComponent={RecruitingWorkingAreasRead}
      writeComponent={RecruitingWorkingAreasWrite}
      emptyComponent={RecruitingWorkingAreasEmpty}
      props={{
        workingAreas,
        setWorkingAreas,
        companyId: data?.company?.id || '',
        company: data?.company || undefined,
      }}
      isEmpty={!data?.company?.recruitingWorkingAreas}
    />
  );
}

export default RecruitingWorkingAreas;
