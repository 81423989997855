import { defineMessages } from 'react-intl';

export default defineMessages({
  placeholder: {
    id: 'InterlocutorPhoneDisplay.placeholder',
    defaultMessage: 'Numéro de téléphone',
    description: 'plaeholder lorsque le numéro de téléphone n est pas disponible',
  },
  tooltip: {
    id: 'InterlocutorPhoneDisplay.tooltip',
    defaultMessage: 'Ce numéro sera visible une fois le premier rendez-vous validé',
    description: 'Tooltip numéro de téléphone',
  },
});
