import React from 'react';
import { Link } from 'react-router';
import Routes from '../../../../../routes';
import translations from './NoMessages.translations';

import styles from './NoMessages.module.scss';
import { useIntl } from 'react-intl';

export default function NoMessages() {
  const intl = useIntl();
  return (
    <div className={styles.noMessages}>
      <img
        src="https://assets.myjobglasses.com/images/no-messages.png"
        className={styles.noMessagesIllustration}
        alt="Aucun message"
      />
      <Link
        to={Routes.search}
        className={styles.button}
      >
        {intl.formatMessage(translations.seeProfiles)}
      </Link>
    </div>
  );
}
