import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'CompanyAdminDashboardContact.title',
    defaultMessage: 'Mesurez <span>l\'activité</span> et <span>l\'attractivité</span> de votre entreprise en souscrivant à nos offres de niveau supérieur.',
  },
  link: {
    id: 'CompanyAdminDashboardContact.link',
    defaultMessage: 'Demander une démo',
  },
});
