import moment from 'moment-timezone';
import { InternshipDuration } from '../@types/graphql';

const hour = 3600;
const day = hour * 24;
const week = day * 7;

export type MonthYear = {
  month: number;
  year: number;
};

export enum Month {
  JANUARY = 'January',
  FEBRUARY = 'February',
  MARCH = 'March',
  APRIL = 'April',
  MAY = 'May',
  JUNE = 'June',
  JULY = 'July',
  AUGUST = 'August',
  SEPTEMBER = 'September',
  OCTOBER = 'October',
  NOVEMBER = 'November',
  DECEMBER = 'December',
}

export function getMonthCountFromEnum(duration: InternshipDuration) {
  const count = duration.replace('INTERNSHIP_DURATION_', '').replace('_MONTHS', '');
  return parseInt(count, 10);
}

export function isOutsideRange(date: moment.Moment, minDate?: moment.Moment, maxDate?: moment.Moment): boolean {
  return !!((minDate && date.isBefore(minDate)) || (maxDate && date.isAfter(maxDate)));
}

export function getCurrentMonth() {
  return new Date().getMonth();
}

export function getCurrentYear() {
  return new Date().getFullYear();
}

export function computeMonthCountBetween(start: MonthYear, end: MonthYear) {
  let count = (end.year - start.year) * 12;
  count += end.month - start.month;
  return count;
}

export function getEndMonthYearFromDuration(start: MonthYear, months: number): MonthYear {
  const endDate = moment([start.year, start.month]).add(months, 'months');
  return {
    month: endDate.month(),
    year: endDate.year(),
  };
}

export function getResponseTime(responseTime?: number | null) {
  if (!responseTime) return '-';
  if (responseTime > week) return '> 7 j';
  if (responseTime > day) return '< 7 j';
  return '< 24 h';
}
