import React, { memo, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import Card from '../../../../components/card/Card';
import StarRadioInput from '../../../../components/star-radio-input/StarRadioInput';
import { useLanguage } from '../../../../hooks/useLanguages';
import { PerceptionQuestion } from '../NewConversation.gql';

type Props = {
  perceptionQuestion: PerceptionQuestion;
  onAnswersChange: (answers: { [key: string]: number }) => void;
};

const TRANSLATIONS = defineMessages({
  mention: {
    id: 'PerceptionQuestion.mention',
    defaultMessage:
      'Vos réponses ne seront pas envoyées à l’Ambassadeur. Elles nous permettent de mesurer l’impact des rendez-vous effectués sur la plateforme.',
  },
});

export const PerceptionSection: React.FC<Props> = memo(({ perceptionQuestion, onAnswersChange }) => {
  const { formatMessage } = useIntl();
  const [answers, setAnswers] = useState<{ [key: string]: number }>({});
  const { language } = useLanguage();

  useEffect(() => {
    if (Object.keys(answers).length > 0) {
      onAnswersChange(answers);
    }
  }, [answers]);

  const sortedAttributes = perceptionQuestion?.questionAttributes
    ?.slice()
    .sort((a, b) => (a?.order || 0) - (b?.order || 0));

  return (
    <Card
      title={perceptionQuestion.label}
      style={{ marginTop: 22 }}
    >
      <div style={{ fontSize: 12, fontWeight: 300, marginBottom: 16 }}>{formatMessage(TRANSLATIONS.mention)}</div>
      {sortedAttributes?.map((attribute) => (
        <StarRadioInput
          key={attribute.id}
          name={`perception_question_${attribute.identifier}`}
          label={language === 'en' ? attribute.labelEn || '' : attribute.labelFr || ''}
          value={answers[attribute.id]?.toString() || ''}
          onSelection={(value) => setAnswers((prev) => ({ ...prev, [attribute.id]: Number(value) }))}
          inline
          small
        />
      ))}
    </Card>
  );
});
