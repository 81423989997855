import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useQuery } from '@apollo/client';

import './ConversationList.scss';

import { Get_Conversations_ListQuery, Get_Conversations_ListQueryVariables } from '../../../@types/graphql.d';
import MessageChatCircleIcon from '../../../icons/component-icons/MessageChatCircleIcon';
import GraphqlCursorPagination from '../../common/graphql-cursor-pagination/GraphqlCursorPagination';
import PageLoader from '../../common/page-loader/PageLoader';
import Legend from '../components/Legend';
import { GET_CONVERSATIONS_LIST } from './ConversationList.gql';
import translations from './ConversationList.translations';
import ConversationListItem from './components/conversations-list-item/ConversationListItem';
import NoMessages from './components/no-messages/NoMessages';

function ConversationList() {
  const intl = useIntl();

  const [{ after, before }, setCurrentCursor] = useState<{
    after?: string | null;
    before?: string | null;
  }>({
    after: undefined,
    before: undefined,
  });
  const { data, loading } = useQuery<Get_Conversations_ListQuery, Get_Conversations_ListQueryVariables>(
    GET_CONVERSATIONS_LIST,
    {
      variables: {
        before,
        after,
      },
    },
  );

  if (loading) {
    return <PageLoader />;
  }

  return (
    <main className="conversations">
      <h2 className="conversations__title">
        <MessageChatCircleIcon className="conversations__title__icon" />
        {intl.formatMessage(translations.conversationsTitle)}
      </h2>
      <Legend type="conversations" />
      <div className="conversations__items">
        {(data?.conversations?.nodes?.length || 0) > 0 ? (
          data?.conversations?.nodes?.map((conversation, i) => (
            <ConversationListItem
              key={conversation.id}
              conversation={conversation}
              animationId={i}
            />
          ))
        ) : (
          <NoMessages />
        )}
      </div>
      {(data?.conversations?.nodes?.length || 0) > 0 && (
        <GraphqlCursorPagination
          pageInfo={data?.conversations?.pageInfo}
          onNextCursor={setCurrentCursor}
          onPreviousCursor={setCurrentCursor}
        />
      )}
    </main>
  );
}

export default ConversationList;
