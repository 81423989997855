import { gql } from '@apollo/client';

export const GET_FIRST_APPOINTMENT_TO_FORCE_REVIEW_QUERY = gql`
  query GetFirstAppointmentToForceReview {
    appointments(first: 1, filters: { currentUserMustReview: true }) {
      nodes {
        id
        currentUserCanReview
        currentUserMustReview
        currentUserIsAmbassador
        currentUserIsMember
        currentUserCanSnooze
      }
      total
    }
  }
`;
