import React, { useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import MessageTemplatesContext from '../../../../context/MessageTemplatesContext';
import SendMessageContext from '../../context/SendMessageContext';
import EditTemplateForm from './components/edit-template-form/EditTemplateForm';
import TemplateList from './components/template-list/TemplateList';
import translations from './TemplatesManager.translations';

import { useQuery } from '@apollo/client';
import {
  Get_Default_Template_ListQuery,
  Get_Default_Template_ListQueryVariables,
  Get_User_Template_ListQuery,
  Get_User_Template_ListQueryVariables,
} from '../../../../../../../@types/graphql.d';
import { CurrentUserContext } from '../../../../../../../contexts/current-user-context/CurrentUserContext';
import { ExtendedMessageTemplate, GET_DEFAULT_TEMPLATE_LIST, GET_USER_TEMPLATE_LIST } from './TemplateManager.gql';

import './TemplatesManager.scss';
import XCircleIcon from '../../../../../../../icons/component-icons/XCircleIcon';
import { useIntl } from 'react-intl';

function TemplatesManager() {
  const intl = useIntl();
  const { currentUser } = useContext(CurrentUserContext);

  const { insertTemplate } = useContext(SendMessageContext);
  const { closeMessageTemplatesManager, setRequestNewTemplate, messageTemplateCannotBeClosed } =
    useContext(SendMessageContext);
  const [dynamicTitle, setDynamicTitle] = useState(undefined);
  // edition modde
  const [messageTemplateIdInEdition, setEditingItem] = useState<string>();
  const [templates, setTemplates] = useState<ExtendedMessageTemplate[]>([]);

  function editMessageTemplate(messageTemplateId: string) {
    setEditingItem(messageTemplateId);
  }
  function onClickCreateNewMessageTemplate() {
    if (messageTemplateCannotBeClosed) {
      setRequestNewTemplate(true);
    } else {
      setEditingItem('');
      setTemplates(templates.map((template) => ({ ...template, cannotBeDeleted: true })));
    }
  }

  // fullscreen mode
  const [isFullscreenForm, setIsFullscreenForm] = useState(false);
  function toggleFullscreen() {
    setIsFullscreenForm(!isFullscreenForm);
  }

  const { data: dataDefaultTemplates } = useQuery<
    Get_Default_Template_ListQuery,
    Get_Default_Template_ListQueryVariables
  >(GET_DEFAULT_TEMPLATE_LIST, {
    variables: {
      userId: currentUser!.id,
    },
  });

  const { data: dataUserTemplates } = useQuery<Get_User_Template_ListQuery, Get_User_Template_ListQueryVariables>(
    GET_USER_TEMPLATE_LIST,
    {
      variables: {
        userId: currentUser!.id,
      },
    },
  );

  useEffect(() => {
    if (dataDefaultTemplates && dataUserTemplates) {
      const allTemplates: ExtendedMessageTemplate[] = [
        ...dataUserTemplates.userMessageTemplates.map((template) => ({
          ...template,
          cannotBeDeleted: false,
        })),
        ...dataDefaultTemplates.defaultMessageTemplates.map((template) => ({
          ...template,
          cannotBeDeleted: true,
        })),
      ];
      setTemplates(allTemplates);
    }
  }, [dataDefaultTemplates, dataUserTemplates]);

  const selectedTemplate = templates.find((template) => template.id === messageTemplateIdInEdition);

  return (
    <MessageTemplatesContext.Provider
      value={{
        // edit
        messageTemplateIdInEdition,
        setEditingItem,
        editMessageTemplate,
        // fullscreen
        toggleFullscreen,
        isFullscreenForm,
        // dynamyc title
        dynamicTitle,
        setDynamicTitle,
      }}
    >
      <div className="manage-templates">
        <header className="manage-templates-header">
          <h3
            className="manage-templates-title"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(translations.title),
            }}
          />
          <button
            type="button"
            className="close-button"
            onClick={closeMessageTemplatesManager}
          >
            {intl.formatMessage(translations.closeButton)}
            <XCircleIcon className="close-button__icon" />
          </button>
        </header>
        <p className="manage-templates-description">{intl.formatMessage(translations.description)}</p>
        <div
          className={classnames('manage-templates-content', {
            'manage-templates-content-mobile-editing': messageTemplateIdInEdition,
          })}
        >
          {!isFullscreenForm && (
            <aside className="templates-list-manager">
              <button
                type="button"
                className="create-new-template-button"
                onClick={onClickCreateNewMessageTemplate}
              >
                {intl.formatMessage(translations.createButton)}
              </button>
              <TemplateList
                onTemplateClick={editMessageTemplate}
                messageTemplateIdInEdition={messageTemplateIdInEdition}
                templates={templates}
              />
            </aside>
          )}
          <EditTemplateForm
            insertTemplate={insertTemplate}
            messageTemplateIdInEdition={messageTemplateIdInEdition}
            selectedTemplate={selectedTemplate}
            setEditingItem={setEditingItem}
          />
        </div>
      </div>
    </MessageTemplatesContext.Provider>
  );
}

export default TemplatesManager;
