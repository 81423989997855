import { defineMessages } from 'react-intl';

export default defineMessages({
  deletedUser: {
    id: 'ProfessionalCardSoftDeleted.deletedUser',
    defaultMessage: 'Utilisateur supprimé',
  },
  removeButton: {
    id: 'ProfessionalCardSoftDeleted.removeButton',
    defaultMessage: 'Retirer des favoris',
  },
});
