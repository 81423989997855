import React from 'react';
import classnames from 'classnames';
import { AppointmentReviewFromStudent } from '../../../../../../../../../@types/graphql.d';
import { Month } from '../../../../../../../../../@types/enum.d';
import internshipsTypesTranslations from '../../../../../../../../common/common-translations/internshipsTypes.translations';
import monthFromNumberTranslations from '../../../../../../../../common/common-translations/monthFromNumber.translations';
import { getMonth } from '../../../../../../../../user/profile/components/internships/internships-display-list-item/InternshipsDisplayListItem.utils';
import translations from './SidebarAppointmentStudentOpportunity.translations';

import sidebarStyles from '../../SidebarAppointmentSection.module.scss';
import styles from './SidebarAppointmentStudentOpportunity.module.scss';
import { useIntl } from 'react-intl';

interface SidebarAppointmentStudentOpportunityProps {
  reviewFromStudent?: Pick<
    AppointmentReviewFromStudent,
    'wantToBeRecontacted' | 'aspiration' | 'wantsToBeRecontactedBySchool'
  > | null;
  isPremiumSchool?: boolean;
}
function SidebarAppointmentStudentOpportunity({
  reviewFromStudent,
  isPremiumSchool,
}: SidebarAppointmentStudentOpportunityProps) {
  const intl = useIntl();
  const result = isPremiumSchool
    ? reviewFromStudent?.wantsToBeRecontactedBySchool
    : reviewFromStudent?.wantToBeRecontacted;
  if (!result) {
    return null;
  }

  return (
    <div className={sidebarStyles.appointmentFeedback}>
      <h4 className={sidebarStyles.feedback}>
        {isPremiumSchool
          ? intl.formatMessage(translations.interestTitle)
          : intl.formatMessage(translations.opportunityTitle)}
      </h4>
      <p
        data-testid="opportunity"
        className={classnames(styles.opportunity, {
          [styles.green]: result,
          [styles.grey]: !result,
        })}
      >
        {result ? intl.formatMessage(translations.yes) : intl.formatMessage(translations.no)}
      </p>
      {reviewFromStudent?.aspiration && (
        <p className={styles.opportunityAspiration}>
          {intl.formatMessage(translations.opportunity, {
            type: intl.formatMessage(internshipsTypesTranslations[reviewFromStudent.aspiration.type]),
            duration: getMonth(reviewFromStudent?.aspiration?.duration),
            month: intl.formatMessage(
              monthFromNumberTranslations[
                Month[`Month${reviewFromStudent.aspiration.startsAtMonth}` as keyof typeof Month]
              ],
            ),
            year: reviewFromStudent?.aspiration?.startsAtYear,
            zipCode: reviewFromStudent?.aspiration?.postalCode,
          })}
        </p>
      )}
    </div>
  );
}

export default SidebarAppointmentStudentOpportunity;
