import { useQuery } from '@apollo/client';
import qs from 'querystring';
import { trackSearchWithFilters } from '../../actions/search';
import classNames from 'classnames';
import CheckboxInput from '../../components/checkbox-input/CheckboxInput';
import SelectInput from '../../components/select-input/SelectInput';
import { CurrentUserContext } from '../../contexts/current-user-context/CurrentUserContext';
import useInfiniteScrollLoading from '../../hooks/infinite-scroll-loading.hook';
import useIsTablet from '../../hooks/useIsTablet';
import FiltersIcon from '../../icons/component-icons/FiltersIcon';
import RefreshIcon from '../../icons/component-icons/RefreshIcon';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import {
  FacetKeyEnum,
  GetAmbassadorsQuery,
  GetAmbassadorsQueryVariables,
  GetSearchFacetsQuery,
  GetSearchFacetsQueryVariables,
  PrimarySituationEnum,
  SearchFacetInput,
  SearchTypeEnum,
} from '../../@types/graphql.d';
import { SEARCH_FACETS_MAP } from './Search.constants';
import {
  SearchFilter,
  getInitialSearchValueFromSearchParams,
  getSearchValueFromSearchParams,
  initFiltersBasedOnSearchParams,
} from './Search.model';
import './Search.scss';
import AmbassadorCardSkeleton from './ambassador-card-skeleton/AmbassadorCardSkeleton';
import AmbassadorCard from './ambassador-card/AmbassadorCard';
import FiltersDropdown from './filters-dropdown/FiltersDropdown';
import NoResult from './no-result/NoResult';
import SearchInput from './search-input/SearchInput';
import SearchTypeSwitch from './search-type-switch/SearchTypeSwitch';
import { GET_AMBASSADORS_QUERY, GET_SEARCH_FACETS_QUERY } from './search.gql';
import { ProfessionalTypeTranslations, SearchTranslations } from './search.translations';
import { useIntl } from 'react-intl';

const FIRST_PAGE = 1;
const LS_SEARCH_TYPE_KEY = 'search_type';

const SITUATIONS_WITH_SWITCH = [
  PrimarySituationEnum.Student,
  PrimarySituationEnum.JobSeekerSupport,
  PrimarySituationEnum.ProfessionalAndSecondarySchoolStudentSupport,
];

export default function Search() {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const { currentUser } = useContext(CurrentUserContext);

  const isTablet = useIsTablet();

  const shouldShowSearchTypeSwitch = useMemo(
    () =>
      (currentUser?.isMjgAdmin && currentUser?.bypassInteractionRules) ||
      (currentUser?.primarySituation?.key && SITUATIONS_WITH_SWITCH.includes(currentUser.primarySituation.key)),
    [currentUser?.isMjgAdmin, currentUser?.bypassInteractionRules, currentUser?.primarySituation],
  );

  const [searchType, setSearchType] = useState<SearchTypeEnum>(
    (localStorage.getItem(LS_SEARCH_TYPE_KEY) as SearchTypeEnum) || SearchTypeEnum.OnlyProfessionals,
  );

  useEffect(() => {
    if (!shouldShowSearchTypeSwitch) {
      setSearchType(SearchTypeEnum.OnlyProfessionals);
      localStorage.removeItem(LS_SEARCH_TYPE_KEY);
    }
  }, [shouldShowSearchTypeSwitch]);

  const [searchValue, setSearchValue] = useState(getSearchValueFromSearchParams());
  const [term, setTerm] = useState(getInitialSearchValueFromSearchParams());
  const [page, setPage] = useState(FIRST_PAGE);
  const [showOnlyAvailableAmbassadors, setShowOnlyAvailableAmbassadors] = useState(false);

  const [isFiltersDropdownOpen, setFiltersDropdownOpen] = useState(false);

  const [filters, setFilters] = useState<Map<FacetKeyEnum, SearchFilter>>(initFiltersBasedOnSearchParams());
  const facets = useMemo<SearchFacetInput[]>(() => {
    const fs: SearchFacetInput[] = [];
    filters.forEach(({ facetType, buckets }) => {
      fs.push({
        key: facetType,
        values: buckets,
      });
    });
    return fs;
  }, [filters]);
  const locationFacet = useMemo<{ placeId: string; description: string } | undefined>(() => {
    const locationFilter = filters.get(FacetKeyEnum.Location);
    if (!locationFilter || !locationFilter.buckets[0]) return undefined;
    return JSON.parse(locationFilter.buckets[0]);
  }, [filters]);

  const setFacetFilters = useCallback(({ facetType, buckets }: SearchFilter) => {
    setFilters((prev) => {
      const newFilters = new Map(prev);
      newFilters.set(facetType, { facetType, buckets });
      return newFilters;
    });
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.size === 0 && currentUser?.locatedInLaReunion) {
      addFilter(
        FacetKeyEnum.Location,
        JSON.stringify({
          placeId: 'ChIJO-S4EIF3eCER-erb7ImOf0o',
          description: 'La Réunion',
        }),
      );
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (searchValue) params.set('q', searchValue);
    else params.delete('q');
    navigate(`${window.location.pathname}?${params.toString()}`);
  }, [searchValue]);

  useEffect(() => {
    const lastParams = new URLSearchParams(location.search);
    const params = new URLSearchParams();
    if (lastParams.has('q')) params.set('q', lastParams.get('q')!);
    filters.forEach(({ facetType, buckets }) => {
      buckets.forEach((b) => {
        params.append(`filters[${facetType.toLowerCase()}]`, b);
      });
    });
    navigate(`${window.location.pathname}?${params.toString()}`);
  }, [filters]);

  const [ambassadors, setAmbassadors] = useState<GetAmbassadorsQuery['searchAmbassadors']['results']>([]);
  const [ambassadorsCount, setAmbassadorsCount] = useState(0);

  const { data: searchFacets } = useQuery<GetSearchFacetsQuery, GetSearchFacetsQueryVariables>(
    GET_SEARCH_FACETS_QUERY,
    {
      variables: {
        searchMode: searchType,
      },
      fetchPolicy: 'no-cache',
    },
  );

  const professionalTypeFacet = useMemo(() => {
    if (!currentUser?.isMjgAdmin) return undefined;
    return searchFacets?.searchFacets.find((facet) => facet.key === FacetKeyEnum.ProfessionalTypes);
  }, [searchFacets?.searchFacets]);

  const { loading } = useQuery<GetAmbassadorsQuery, GetAmbassadorsQueryVariables>(GET_AMBASSADORS_QUERY, {
    variables: {
      searchQuery: searchValue,
      searchMode: searchType,
      searchFacets: facets.filter((facet) => facet.key != FacetKeyEnum.Location),
      searchGooglePlaceId: locationFacet?.placeId,
      availableAmbassadorsOnly: showOnlyAvailableAmbassadors,
      availableFirst: true,
      page,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (ambs) => {
      setAmbassadorsCount(ambs.searchAmbassadors.total);
      if (page === FIRST_PAGE) setAmbassadors(ambs.searchAmbassadors.results);
      else setAmbassadors((prev) => [...prev, ...ambs.searchAmbassadors.results]);
    },
  });

  useInfiniteScrollLoading({
    canLoadMore: ambassadors.length < ambassadorsCount,
    isLoading: loading,
    callback: () => setPage((p) => p + 1),
  });

  function addFilter(facetType: FacetKeyEnum, bucket: string) {
    const copy = new Map(filters);
    if (copy.has(facetType) && facetType !== FacetKeyEnum.JobTitle) {
      copy.get(facetType)!.buckets.push(bucket);
    } else {
      copy.set(facetType, { facetType, buckets: [bucket] });
    }
    setFilters(copy);
  }

  const removeFilter = useCallback(
    (facetType: FacetKeyEnum) => {
      const copy = new Map(filters);
      copy.delete(facetType);
      setFilters(copy);
    },
    [filters],
  );

  const onSearchSelection = useCallback(
    (text: string, facetType?: string) => {
      if (facetType) {
        setSearchValue('');
        setTimeout(() => addFilter(facetType as FacetKeyEnum, text), 250);
      } else {
        removeFilter(FacetKeyEnum.JobTitle);
        setTimeout(() => setSearchValue(text), 250);
      }
    },
    [searchValue, filters],
  );

  useEffect(() => {
    setPage(FIRST_PAGE);
  }, [searchValue, searchType, filters, showOnlyAvailableAmbassadors]);

  const onReinitializeFilters = useCallback(() => {
    setSearchValue('');
    setTerm('');
    if (filters.size === 0) return;
    setFilters(new Map());
  }, [filters, searchValue, term]);

  useEffect(() => {
    if (!loading) {
      trackSearchWithFilters(searchValue, searchType, filters, facets, currentUser?.id);
    }
  }, [searchValue, searchType, filters, facets, loading]);

  return (
    <main className="search-page">
      <header className="search-page__header">
        {shouldShowSearchTypeSwitch && (
          <SearchTypeSwitch
            searchType={searchType}
            onStudentClick={() => {
              setSearchType(SearchTypeEnum.OnlyStudents);
              setFilters(new Map());
              localStorage.setItem(LS_SEARCH_TYPE_KEY, SearchTypeEnum.OnlyStudents);
            }}
            onProfessionalClick={() => {
              setSearchType(SearchTypeEnum.OnlyProfessionals);
              setFilters(new Map());
              localStorage.setItem(LS_SEARCH_TYPE_KEY, SearchTypeEnum.OnlyProfessionals);
            }}
          />
        )}
        <div className="search-page__header__search">
          <div className="search-page__header__search__container">
            <SearchInput
              term={term}
              searchMode={searchType}
              onSelection={onSearchSelection}
              onTermChange={setTerm}
              featuredFacet={searchFacets?.searchFacets.find(({ key }) => key === FacetKeyEnum.TagNames)}
            />

            {isTablet ? (
              <>
                <button
                  type="button"
                  className="search-page__header__search__container__open-filters-button"
                  onClick={() => setFiltersDropdownOpen((prev) => !prev)}
                >
                  <FiltersIcon className="search-page__header__search__container__open-filters-button__icon" />
                </button>

                {isFiltersDropdownOpen && (
                  <FiltersDropdown
                    searchType={searchType}
                    searchFacets={searchFacets?.searchFacets || []}
                    filters={filters}
                    setFacetFilters={setFacetFilters}
                    onClose={() => setFiltersDropdownOpen(false)}
                  />
                )}
              </>
            ) : (
              <div
                className={classNames('search-page__header__search__container__filters', {
                  'search-page__header__search__container__filters--open': isFiltersDropdownOpen,
                })}
              >
                {SEARCH_FACETS_MAP[searchType].map(({ type, desktopComponent: Component, withSearch, sort }) => {
                  return (
                    <Component
                      key={type}
                      searchType={searchType}
                      facetType={type}
                      buckets={searchFacets?.searchFacets.find((f) => f.key === type)?.buckets || []}
                      withSearch={withSearch}
                      filters={filters.get(type)}
                      onFilterChange={setFacetFilters}
                      sort={sort}
                    />
                  );
                })}
              </div>
            )}
          </div>

          <button
            className="search-page__header__search__reset-button"
            onClick={onReinitializeFilters}
          >
            <RefreshIcon />
            {intl.formatMessage(SearchTranslations.reset)}
          </button>
        </div>
      </header>

      <div className="search-page__cards">
        {ambassadors.length === 0 ? (
          loading ? (
            <>
              {new Array(12).fill(undefined).map((_, key) => (
                <AmbassadorCardSkeleton key={`skeleton-${key}`} />
              ))}
            </>
          ) : (
            <NoResult />
          )
        ) : (
          <>
            <div className="search-page__cards__header">
              <div>
                {!loading &&
                  (ambassadorsCount > 500 ? '+500' : ambassadorsCount) +
                    intl.formatMessage(SearchTranslations.resultsCount, {
                      count: ambassadorsCount,
                    })}
              </div>
              <div className="search-page__cards__header__filters">
                {professionalTypeFacet && (
                  <SelectInput
                    name="showOnlyFilter"
                    placeholder="Filtrer par"
                    value={
                      filters.has(FacetKeyEnum.ProfessionalTypes) &&
                      filters.get(FacetKeyEnum.ProfessionalTypes)!.buckets[0]
                        ? filters.get(FacetKeyEnum.ProfessionalTypes)!.buckets[0]
                        : undefined
                    }
                    values={[
                      ...professionalTypeFacet.buckets.map((b) => ({
                        translation: intl.formatMessage(ProfessionalTypeTranslations[b.key]),
                        value: b.key,
                      })),
                      { translation: 'Tous', value: '' },
                    ]}
                    onChange={(e) => {
                      if (e === '') {
                        onReinitializeFilters();
                        return;
                      }
                      setFacetFilters({
                        facetType: FacetKeyEnum.ProfessionalTypes,
                        buckets: [e],
                      });
                    }}
                  />
                )}
                <CheckboxInput
                  label={intl.formatMessage(SearchTranslations.showOnlyAvailable)}
                  name="onlyAvailable"
                  checked={showOnlyAvailableAmbassadors}
                  onChange={(e) => setShowOnlyAvailableAmbassadors(e.target.checked)}
                />
              </div>
            </div>
            {ambassadors.map((ambassador) => (
              <AmbassadorCard
                key={ambassador.professionalId || ''}
                ambassador={ambassador}
              />
            ))}
          </>
        )}
      </div>
    </main>
  );
}
