import React from 'react';
import translations from './RecruitingWorkingAreasRead.translations';

import styles from './RecruitingWorkingAreasRead.module.scss';
import { useIntl } from 'react-intl';

interface RecruitingWorkingAreasReadProps {
  workingAreas: Array<string>;
  company?: { isPremiumSchool: boolean };
}

function RecruitingWorkingAreasRead({ workingAreas, company }: RecruitingWorkingAreasReadProps) {
  const intl = useIntl();
  return (
    <div>
      <h2 className={styles.title}>
        {company?.isPremiumSchool
          ? intl.formatMessage(translations.formationsList)
          : intl.formatMessage(translations.workingAreas)}
      </h2>
      {workingAreas.length !== 0 && (
        <div className={styles.workingAreas}>
          {workingAreas.map((workingArea) => (
            <span
              key={workingArea}
              className={styles.workingArea}
            >
              {workingArea}
            </span>
          ))}
        </div>
      )}
      {workingAreas.length === 0 && (
        <p className={styles.noWorkingArea}>
          {company?.isPremiumSchool
            ? intl.formatMessage(translations.emptyFormationAreas)
            : intl.formatMessage(translations.emptyWorkingAreas)}
        </p>
      )}
    </div>
  );
}

export default RecruitingWorkingAreasRead;
