import { defineMessages } from 'react-intl';

export default defineMessages({
  pleaseAnwser: {
    id: 'NoAppointment.pleaseAnwser',
    defaultMessage: 'Répondez à {firstName} afin de pouvoir valider un rendez-vous sur la plateforme.',
    description: 'Information demandant au pro de répondre avant de prendre RDV',
  },
  // professional
  haveYouAMeeting: {
    id: 'NoAppointment.haveYouAMeeting',
    defaultMessage: 'Vous avez prévu un rendez-vous ?',
    description: 'Message demandant si un RDV est prévu',
  },
  confirmIt: {
    id: 'NoAppointment.confirmIt',
    defaultMessage:
      'Validez-le sur la plateforme pour aider le Membre à lister les Ambassadeurs avec lesquels il a pu construire son parcours professionnel.',
    description: 'information sur le fait d enregistrer un RDV',
  },
  createAppointmentButton: {
    id: 'NoAppointment.createAppointmentButton',
    defaultMessage: 'Valider rendez-vous',
    description: 'bouton pour créer RDV dans la conversation',
  },
  // student
  doYouWantToMeetThePro: {
    id: 'NoAppointment.doYouWantToMeetThePro',
    defaultMessage: 'Vous souhaitez rencontrer cet Ambassadeur ?',
    description: 'Message demandant si un RDV est prévu',
  },
  professionalCanRegisterAppointment: {
    id: 'NoAppointment.professionalCanRegisterAppointment',
    defaultMessage: "Convenez ensemble d'un horaire afin qu'il puisse enregistrer le rendez-vous sur la plateforme.",
    description: "message informant que seul l'Ambassadeur peut créer un RDV",
  },
});
