import { gql } from '@apollo/client';

export const VERIFY_EMPLOYEES_LIST = gql`
  mutation VERIFY_EMPLOYEES_LIST($companyId: ID!, $file: Upload!) {
    verifyEmployeesList(companyId: $companyId, file: $file) {
      changes {
        employee {
          id
          firstName
          lastName
          email
        }
        changes {
          attr
          from
          to
        }
      }
      invitations {
        id
        firstName
        lastName
        email
        position
      }
    }
  }
`;

export const IMPORT_EMPLOYEES_LIST = gql`
  mutation IMPORT_EMPLOYEES_LIST($companyId: ID!, $file: Upload!) {
    importEmployeesList(companyId: $companyId, file: $file)
  }
`;
