import React, { memo } from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';

import './AppointmentDate.scss';

type Props = {
  date: any; // GraphQL type
}

export const AppointmentDate = memo(({ date }: Props) => (
  <div className="appointment-date">
    <FormattedDate
      value={date}
      day="numeric"
      month="long"
      year="numeric"
    />
    {' | '}
    <FormattedTime value={date} />
  </div>
));
