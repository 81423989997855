import { gql } from '@apollo/client';

export const CURRENT_USER_WISHED_AMBASSADOR_QUERY = gql`
  query currentUserWishedAmbassador($ambassadorId: ID!) {
    getUser(id: $ambassadorId) {
      currentUserWishedAmbassador
    }
  }
`;

export const WISH_AMBASSADOR = gql`
  mutation wishAmbassador($ambassadorId: ID!) {
    wishAmbassador(userId: $ambassadorId)
  }
`;

export const UNWISH_AMBASSADOR = gql`
  mutation unwishAmbassador($ambassadorId: ID!) {
    unwishAmbassador(userId: $ambassadorId)
  }
`;
