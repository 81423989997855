import React from 'react';
import { TableCellProps } from 'react-virtualized';
import WithTooltip from '../../../../../../common/with-tooltip/WithTooltip';
import translations from './ViewDetailCellRenderer.translation';

import styles from './ViewDetailCellRenderer.module.scss';
import { IntlShape } from 'react-intl';
import SearchRefractionIcon from '../../../../../../../icons/component-icons/SearchRefractionIcon';

interface ViewDetailCellRendererProps extends TableCellProps {
  intl: IntlShape;
}

function ViewDetailCellRenderer({ cellData, intl }: ViewDetailCellRendererProps) {
  if (!cellData) {
    return <p>-</p>;
  }
  return (
    <WithTooltip
      text={intl.formatMessage(translations.viewDetailColumnLabel)}
      className={styles.tooltipWrapper}
    >
      {({ closeTooltip, openTooltip }) => (
        <button
          type="button"
          className={styles.viewDetailButton}
          onMouseOver={openTooltip}
          onFocus={openTooltip}
          onMouseOut={closeTooltip}
          onBlur={closeTooltip}
        >
          <SearchRefractionIcon className={styles.detailIcon} />
        </button>
      )}
    </WithTooltip>
  );
}

export default ViewDetailCellRenderer;
