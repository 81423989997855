import { matchRoutes, useLocation } from 'react-router';
import Routes from '../routes';

const routes = [
  { path: Routes.companyAdminDashboard },
  { path: Routes.companyAdminAppointments },
  { path: Routes.companyAdminDashboardWithId },
  { path: Routes.companyAdminAmbassadors },
  { path: Routes.companyAdminAmbassadorsArchived },
  { path: Routes.companyAdminAmbassadorsAdd },
  { path: Routes.companyAdminImportEmployees },
  { path: Routes.companyAdminConfiguration },
  { path: Routes.companyAdminShowAmbassador },
  { path: Routes.companyAdminEditAmbassador },
  { path: Routes.companyAdminEditAmbassadorAvatar },
  { path: Routes.companyAdminEditDescription },
  { path: Routes.companyAdminAppointmentsWithId },
  { path: Routes.companyAdminAppointmentsDetails },
  { path: Routes.connectCompanyHome },
];

export default function useCurrentPath() {
  const location = useLocation();
  const matchedRoutes = matchRoutes(routes, location);
  const route = matchedRoutes ? matchedRoutes[0].route : null;

  return route?.path;
}
