import React from 'react';
import moment from 'moment-timezone';
import ExclamationMarkIcon from '../../../../../../../icons/component-icons/ExclamationMarkIcon';
import translations from './ProfessionalHibernated.translations';
import { useIntl } from 'react-intl';

import './ProfessionalHibernated.scss';

export type ProfessionalHibernatedProps = {
  interlocutor: {
    unavailableUntil: string;
  };
};

function ProfessionalHibernated({ interlocutor }: ProfessionalHibernatedProps) {
  const intl = useIntl();
  return (
    <div className="professional-hibernated">
      <aside className="professional-hibernated-mark">
        <div className="icon-wrapper">
          <ExclamationMarkIcon />
        </div>
      </aside>
      <div className="professional-hibernated-content">
        <span className="professional-hibernated-status">{intl.formatMessage(translations.nextAppointment)}</span>
        <span className="professional-hibernated-date">
          {intl.formatMessage(translations.datePartial, {
            date: intl.formatDate(
              // @ts-ignore
              moment(interlocutor.unavailableUntil),
              { month: '2-digit', day: '2-digit' },
            ),
          })}
        </span>
      </div>
      <footer className="professional-hibernated-footer">
        {intl.formatMessage(translations.temporarlyUnavailable)}
      </footer>
    </div>
  );
}

export default ProfessionalHibernated;
