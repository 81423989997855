import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import { CurrentUserContext } from '../../../contexts/current-user-context/CurrentUserContext';
import LanguageSelectorSimple from './components/language-selector-simple/LanguageSelectorSimple';
import translations from './Footer.translations';
import SocialMediaLink from './components/social-media-link/SocialMediaLink';

import styles from './Footer.module.scss';
import { useIntl } from 'react-intl';

export default function Footer() {
  const intl = useIntl();
  const { currentUser } = useContext(CurrentUserContext);
  const isMinor = useMemo(() => moment().diff(currentUser?.birthdate, 'years', false) < 15, []);

  return (
    <footer className={styles.footer}>
      <LanguageSelectorSimple />
      <div className={styles.linkSection}>
        <Link
          to="https://help.myjobglasses.com/fr/"
          className={styles.link}
        >
          {intl.formatMessage(translations.faq)}
        </Link>
        {isMinor ? (
          <Link
            to="https://assets.myjobglasses.com/cgu/2024-04-confidentialite-mineurs-numeriques.pdf"
            className={styles.link}
          >
            {intl.formatMessage(translations.privacy)}
          </Link>
        ) : (
          <Link
            to="https://www.myjobglasses.com/confidentialite-communaute-connect"
            className={styles.link}
          >
            {intl.formatMessage(translations.privacy)}
          </Link>
        )}
        <Link
          to="https://assets.myjobglasses.com/cgu/2025-05-28-cgu-v10.0.pdf"
          className={styles.link}
        >
          {intl.formatMessage(translations.cgu)}
        </Link>
        <Link
          to="https://www.myjobglasses.com/mentions-legales"
          target="_blank"
          className={styles.link}
        >
          {intl.formatMessage(translations.legal)}
        </Link>
        <p className={styles.branding}>
          Tous droits réservés &copy;&#32;MyJobGlasses&#32;&nbsp;
          {new Date().getFullYear()}
        </p>
      </div>
      <SocialMediaLink />
    </footer>
  );
}
