import { defineMessages } from 'react-intl';

export default defineMessages({
  companyAttribute1: {
    id: 'CompanyAttributeHub.companyAttribute1',
    defaultMessage: 'Libellé n°1',
  },
  companyAttribute2: {
    id: 'CompanyAttributeHub.companyAttribute2',
    defaultMessage: 'Libellé n°2',
  },
  companyAttribute3: {
    id: 'CompanyAttributeHub.companyAttribute3',
    defaultMessage: 'Libellé n°3',
  },
  companyAttributeDescription: {
    id: 'CompanyAttributeHub.companyAttributeDescription',
    defaultMessage: 'Ceci vous aidera à catégoriser et/ou trier vos experts dans le tableau "Mes experts"',
  },
});
