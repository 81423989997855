import React, { useState } from 'react';
import classnames from 'classnames';
import PencilIcon from '../../../../../icons/component-icons/PencilIcon';

import styles from './ConfigurationBloc.module.scss';

interface ChildrenProps {
  edit: () => void;
  finish: () => void;
  [key: string]: any;
}
interface ConfigurationBlocProps<T extends { [key: string]: any }> {
  title: string;
  description: string;
  className?: string;
  readComponent: React.FunctionComponent<T & ChildrenProps>;
  writeComponent: React.FunctionComponent<T & ChildrenProps>;
  emptyComponent?: React.FunctionComponent<T & ChildrenProps>;
  isEmpty?: boolean;
  props: T;
}
function ConfigurationBloc<C extends { [key: string]: any }>({
  title,
  description,
  className,
  readComponent,
  writeComponent,
  emptyComponent,
  isEmpty = false,
  props,
}: ConfigurationBlocProps<C>) {
  const [isEditing, setIsEditing] = useState(false);
  function edit() {
    setIsEditing(true);
  }
  function finish() {
    setIsEditing(false);
  }
  let Children;
  if (isEditing) {
    Children = writeComponent;
  } else if (isEmpty && emptyComponent) {
    Children = emptyComponent;
  } else {
    Children = readComponent;
  }
  return (
    <article className={classnames(styles.companyConfigurationBloc, className)}>
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.description}>{description}</p>
      {!isEditing && !isEmpty && (
        <button
          type="button"
          onClick={edit}
          className={styles.editButton}
        >
          <PencilIcon className={styles.icon} />
        </button>
      )}
      <Children
        edit={edit}
        finish={finish}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </article>
  );
}

export default ConfigurationBloc;
