import { defineMessages } from 'react-intl';

export default defineMessages({
  newAppointmentButton: {
    id: 'AppointmentHub.newAppointmentButton',
    defaultMessage: 'nouveau rendez-vous',
    description: 'Bouton nouveau RDV dans la messagerie sur un RDV passé',
  },
  evaluateAppointment: {
    id: 'AppointmentHub.evaluateAppointmentButton',
    defaultMessage: 'évaluer le rendez-vous',
    description: 'Bouton nouveau RDV dans la messagerie sur un RDV passé',
  },
});
