import * as Yup from 'yup';
import translations from './AddEmployeeModal.translations';
import { IntlShape } from 'react-intl';

interface ValidationOption {
  companyAttribute1Required?: boolean;
  companyAttribute2Required?: boolean;
  companyAttribute3Required?: boolean;
}

export default (
  { companyAttribute1Required, companyAttribute2Required, companyAttribute3Required }: ValidationOption = {},
  intl: IntlShape,
) =>
  Yup.object().shape({
    firstName: Yup.string().ensure().required(intl.formatMessage(translations.missingFirstname)),
    lastName: Yup.string().ensure().required(intl.formatMessage(translations.missingLastname)),
    email: Yup.string()
      .ensure()
      .email(intl.formatMessage(translations.badEmail))
      .required(intl.formatMessage(translations.badEmail)),
    companyAttribute1: Yup.string()
      .ensure()
      [
        companyAttribute1Required ? 'required' : 'notRequired'
      ](intl.formatMessage(translations.missingCompanyAttribute)),
    companyAttribute2: Yup.string()
      .ensure()
      [
        companyAttribute2Required ? 'required' : 'notRequired'
      ](intl.formatMessage(translations.missingCompanyAttribute)),
    companyAttribute3: Yup.string()
      .ensure()
      [
        companyAttribute3Required ? 'required' : 'notRequired'
      ](intl.formatMessage(translations.missingCompanyAttribute)),
  });
