import { IntlShape } from 'react-intl';

import BarChartIcon from '../../../../../icons/component-icons/BarChartIcon';
import CalendarIcon from '../../../../../icons/component-icons/CalendarIcon';
import HomeIcon from '../../../../../icons/component-icons/HomeIcon';
import LockIcon from '../../../../../icons/component-icons/LockIcon';
import PowerIcon from '../../../../../icons/component-icons/PowerIcon';
import SettingsIcon from '../../../../../icons/component-icons/SettingsIcon';
import UsersIcon from '../../../../../icons/component-icons/UsersIcon';
import Routes from '../../../../../routes';
import MainMenuItem from '../../components/main-menu-item/MainMenuItem';
import UsernavItem from '../../components/usernav-item/UsernavItem';
import translations from './connect.translations';

const accountEndpoint = import.meta.env.REACT_APP_ACCOUNT_FRONTEND_URL || '';
const hermesEndpoint = import.meta.env.REACT_APP_MJG_ADMIN_BACKOFFICE_URL || '';

export const getConnectConfig = (intl: IntlShape): { main: any[]; connected: any[] } => ({
  main: [
    {
      component: MainMenuItem,
      type: 'company-link',
      key: 'home',
      className: 'main-menu-item-link-white',
      to: Routes.connectCompanyHomeWithId,
      label: intl.formatMessage(translations.home),
      icon: HomeIcon,
      authorizationKey: 'canHomeAccess',
    },
    {
      component: MainMenuItem,
      type: 'company-link',
      key: 'dashboard',
      className: 'main-menu-item-link-white',
      to: Routes.companyAdminDashboardWithId,
      label: intl.formatMessage(translations.dashboard),
      icon: BarChartIcon,
    },
    {
      component: MainMenuItem,
      type: 'company-link',
      key: 'employees',
      className: 'main-menu-item-link-white',
      to: Routes.companyAdminAmbassadors,
      label: intl.formatMessage(translations.ambassador),
      icon: UsersIcon,
      authorizationKey: 'canAmbassadorsListAccess',
    },
    {
      component: MainMenuItem,
      type: 'company-link',
      key: 'appointments',
      className: 'main-menu-item-link-white',
      to: Routes.companyAdminAppointmentsWithId,
      label: intl.formatMessage(translations.appointments),
      icon: CalendarIcon,
      authorizationKey: 'canAppointmentsListAccess',
    },
  ],
  connected: [
    {
      component: UsernavItem,
      type: 'company-link',
      key: 'configuration',
      to: Routes.companyAdminConfiguration,
      label: intl.formatMessage(translations.configuration),
      condition: ({ currentUser }) => !!currentUser && !currentUser.isMjgAdmin,
    },
    {
      component: UsernavItem,
      type: 'company-link',
      key: 'configuration',
      to: Routes.mjgAdminCompaniesConfiguration,
      label: intl.formatMessage(translations.configuration),
      condition: ({ currentUser }) => !!currentUser && currentUser.isMjgAdmin,
    },
    {
      component: UsernavItem,
      type: 'external-link',
      key: 'mjg-admin-new-bo',
      to: `${hermesEndpoint}/admin`,
      label: intl.formatMessage(translations.mjgNewBO),
      condition: ({ currentUser }) => !!currentUser && currentUser.isMjgAdmin,
      icon: LockIcon,
    },
    {
      component: UsernavItem,
      type: 'link',
      key: 'settings',
      to: Routes.settings,
      label: intl.formatMessage(translations.settings),
      icon: SettingsIcon,
    },
    {
      component: UsernavItem,
      type: 'external-link',
      key: 'logout',
      to: `${accountEndpoint}/signout`,
      label: intl.formatMessage(translations.logout),
      icon: PowerIcon,
    },
    {
      component: UsernavItem,
      type: 'company-switch',
      key: 'company-switch',
    },
    {
      component: UsernavItem,
      type: 'switch-account',
      key: 'switch-account',
      to: Routes.dashboard,
      label: intl.formatMessage(translations.employeeMode),
    },
  ],
});

export default getConnectConfig;
