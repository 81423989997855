import React from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import Help from '../../../../common/help/Help';
import classNames from 'classnames';
import DownloadWithTooltip, {
  DownloadWithTooltipProps,
} from '../../../../common/download-with-tooltip/DownloadWithTooltip';
import LockIcon from '../../../../../icons/component-icons/LockIcon';

import CompanyAdminDashboardViewTranslations from '../../CompanyAdminDashboardView.translations';
import styles from './CompanyAdminDashboardRate.module.scss';

interface CompanyAdminDashboardRateProps {
  title: string;
  subTitleTranslation?: FormattedMessage.MessageDescriptor | undefined;
  tooltip?: string;
  value: number | '-';
  rate: number;
  className?: string;
  download?: DownloadWithTooltipProps;
  blurred?: boolean;
}
function CompanyAdminDashboardRate({
  title,
  subTitleTranslation,
  tooltip,
  value,
  rate,
  download,
  className,
  blurred,
}: CompanyAdminDashboardRateProps): JSX.Element {
  const intl = useIntl();
  return (
    <div className={classnames(className, styles.companyAdminDashboardRate)}>
      <div className={styles.titleWithTooltip}>
        <p className={styles.title}>{title}</p>
        {tooltip && (
          <Help
            text={tooltip}
            variant="blue-dark"
          />
        )}
        {download && (
          <DownloadWithTooltip
            text={download.text}
            url={download.url}
            filename={download.filename}
            mimeType={download.mimeType}
            disabled={download.disabled}
            variant="blue-dark"
          />
        )}
      </div>
      {subTitleTranslation ? (
        <p className={styles.subtitle}>{intl.formatMessage(subTitleTranslation, { count: value })}</p>
      ) : (
        <div className={styles.noSubtitle} />
      )}
      <div className={styles.progressbarWithDisplayedValue}>
        {blurred ? (
          <div className={styles.lockedData}>
            <LockIcon />
            <p>{intl.formatMessage(CompanyAdminDashboardViewTranslations.lockedData)}</p>
          </div>
        ) : (
          <div className={styles.progressbar}>
            <div
              className={styles.innerprogressbar}
              style={{ width: rate ? `${rate}%` : 0 }}
            />
          </div>
        )}
        <div className={classNames(styles.progressbarValue, { blurred })}>{rate ? `${rate}` : '-'}</div>
        <span>%</span>
      </div>
    </div>
  );
}

export default CompanyAdminDashboardRate;
