import React from 'react';
import classNames from 'classnames';
import BriefcaseIcon from '../../../icons/component-icons/BriefcaseIcon';
import GraduateHatIcon from '../../../icons/component-icons/GraduateHatIcon';
import useIsMobile from '../../../hooks/useIsMobile';
import { SearchTypeEnum } from '../../../@types/graphql.d';
import { MeetTypeTranslations } from './SearchType.translations';

import './SearchTypeSwitch.scss';
import { useIntl } from 'react-intl';

type SearchTypeSwitchProps = {
  searchType: SearchTypeEnum;
  onStudentClick: () => void;
  onProfessionalClick: () => void;
};

export default function SearchTypeSwitch({ searchType, onStudentClick, onProfessionalClick }: SearchTypeSwitchProps) {
  const intl = useIntl();
  const isMobile = useIsMobile();

  return (
    <div className="search-type-switch">
      <div className="search-type-switch__buttons">
        <button
          data-cy="search-type-professional"
          type="button"
          onClick={onProfessionalClick}
          className={classNames({
            active: searchType === SearchTypeEnum.OnlyProfessionals,
          })}
        >
          <BriefcaseIcon />
          {intl.formatMessage(isMobile ? MeetTypeTranslations.mobileProfessionals : MeetTypeTranslations.professionals)}
        </button>

        <div className="search-type-switch__buttons__separator" />

        <button
          data-cy="search-type-student"
          type="button"
          onClick={onStudentClick}
          className={classNames({
            active: searchType === SearchTypeEnum.OnlyStudents,
          })}
        >
          <GraduateHatIcon />
          {intl.formatMessage(isMobile ? MeetTypeTranslations.mobileStudents : MeetTypeTranslations.students)}
        </button>
      </div>
    </div>
  );
}
