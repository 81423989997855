import classNames from 'classnames';
import React, { useContext } from 'react';
import { DashboardMode } from '../../DashboardView.types';
import './DashboardTabs.scss';
import { DashboardTabsTranslations } from './DashboardTabs.translations';
import { CurrentUserContext } from '../../../../../contexts/current-user-context/CurrentUserContext';
import { useIntl } from 'react-intl';

type DashboardTabsProps = {
  dashboardMode: DashboardMode;
  setDashboardMode: (dashboardMode: DashboardMode) => void;
};

export default function DashboardTabs({ dashboardMode, setDashboardMode }: DashboardTabsProps) {
  const intl = useIntl();
  const { currentUser } = useContext(CurrentUserContext);
  const isAmbassador = currentUser?.isAmbassador;
  if (!isAmbassador) setDashboardMode('member');

  return (
    <div className="dashboard-tabs">
      {isAmbassador && (
        <button
          onClick={() => setDashboardMode('ambassador')}
          className={classNames('dashboard-tabs__tab', {
            'dashboard-tabs__tab--current': dashboardMode === 'ambassador',
          })}
        >
          {intl.formatMessage(DashboardTabsTranslations.ambassador)}
        </button>
      )}
      <button
        onClick={() => setDashboardMode('member')}
        className={classNames('dashboard-tabs__tab', {
          'dashboard-tabs__tab--current': dashboardMode === 'member',
        })}
      >
        {intl.formatMessage(DashboardTabsTranslations.member)}
      </button>
    </div>
  );
}
