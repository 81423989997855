import React, { memo, useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { SectionCard } from '../../components/section-card/SectionCard';
import { useConnectCompanyContext } from '../../ConnectCompanyContext';
import { useQuery } from '@apollo/client';
import { GET_COMPANY_PRESENTATION_QUERY } from './CompanyPresentation.gql';
import {
  GetCompanyPresentationQuery,
  GetCompanyPresentationQueryVariables,
} from '../../../../../../../@types/graphql.d';
import PrimaryButton from '../../../../../../../components/primary-button/PrimaryButton';
import SecondaryButton from '../../../../../../../components/secondary-button/SecondaryButton';
import './CompanyPresentation.scss';
import useModal from '../../../../../../../components/modal/Modal.hook';
import StaticCompanyPresentation from '../../../../../../../scenes/public/ambassador-view/sections/company-presentation/StaticCompanyPresentation';
import StaticFurtherInformations from '../../../../../../../scenes/public/ambassador-view/sections/further-informations/StaticFurtherInformations';
import CompanyPresentationUpdate from './company-presentation-update/CompanyPresentationUpdate';
import { useRestrictedPackModal } from '../../components/restricted-pack-modal/RestrictedPackModal.hook';

const translations = defineMessages({
  title: {
    id: 'ConnectCompany.CompanyPresentation.title',
    defaultMessage: "💬 Présentation de l'entreprise",
  },
  subtitle: {
    id: 'ConnectCompany.CompanyPresentation.subtitle',
    defaultMessage: 'Ceci apparaîtra automatiquement sur le profil public de vos Ambassadeurs.',
  },
  noDescription: {
    id: 'ConnectCompany.CompanyPresentation.noDescription',
    defaultMessage: 'Aucune présentation ajoutée',
  },
  modifyPresentation: {
    id: 'ConnectCompany.CompanyPresentation.modifyPresentation',
    defaultMessage: 'Modifier la présentation',
  },
  preview: {
    id: 'ConnectCompany.CompanyPresentation.preview',
    defaultMessage: 'Prévisualiser',
  },
  addPresentation: {
    id: 'ConnectCompany.CompanyPresentation.addPresentation',
    defaultMessage: 'Ajouter une présentation',
  },
  previewModalTitle: {
    id: 'ConnectCompany.CompanyPresentation.previewModalTitle',
    defaultMessage: 'Prévisualisation de la vue de profil de vos Ambassadeurs',
  },
});

export const CompanyPresentation = memo(() => {
  const intl = useIntl();
  const [openModal] = useModal();
  const openRestrictedPackageModal = useRestrictedPackModal();
  const { id } = useConnectCompanyContext();
  const [isEditing, setIsEditing] = useState(false);

  const { data } = useQuery<GetCompanyPresentationQuery, GetCompanyPresentationQueryVariables>(
    GET_COMPANY_PRESENTATION_QUERY,
    {
      variables: {
        companyId: id || '',
      },
      fetchPolicy: 'network-only',
    },
  );

  const openPresentationPreviewModal = useCallback(() => {
    if (!data?.company?.description || !data?.company?.name) return;
    openModal({
      title: intl.formatMessage(translations.previewModalTitle),
      options: {
        centeredContent: true,
        greyBackground: true,
        accentHeader: true,
        maxWidth: 1200,
      },
      content: (
        <div style={{ display: 'flex', columnGap: 24 }}>
          <StaticCompanyPresentation
            presentation={data.company.description.substring(0, 2000)}
            companyName={data.company.name}
          />
          <StaticFurtherInformations />
        </div>
      ),
    });
  }, [data?.company?.description, data?.company?.name]);

  if (isEditing) {
    return (
      <CompanyPresentationUpdate
        company={{ id: id, description: data?.company?.description }}
        finish={() => setIsEditing(false)}
      />
    );
  }

  return (
    <SectionCard
      title={intl.formatMessage(translations.title)}
      subtitle={intl.formatMessage(translations.subtitle)}
    >
      {data?.company?.canUpdateCompanyDescription ? (
        <div className="company-decription">
          <p>{data?.company?.description || intl.formatMessage(translations.noDescription)}</p>
          <div
            className={data?.company?.description ? 'company-decription__btn-full' : 'company-decription__btn-empty'}
          >
            <PrimaryButton
              label={data?.company?.description ? intl.formatMessage(translations.modifyPresentation) : intl.formatMessage(translations.addPresentation)}
              onClick={() => setIsEditing(true)}
              smaller
            />
            <SecondaryButton
              label={intl.formatMessage(translations.preview)}
              onClick={data?.company?.description ? openPresentationPreviewModal : () => {}}
              smaller
              disabled={!data?.company?.description}
            />
          </div>
        </div>
      ) : (
        <div className="company-decription">
          <p>{intl.formatMessage(translations.noDescription)}</p>
          <div className="company-decription__btn-empty">
            <SecondaryButton
              label={intl.formatMessage(translations.addPresentation)}
              onClick={openRestrictedPackageModal}
              smaller
              smallerDisabled
            />
            <SecondaryButton
              label={intl.formatMessage(translations.preview)}
              onClick={openRestrictedPackageModal}
              smaller
              smallerDisabled
            />
          </div>
        </div>
      )}
    </SectionCard>
  );
});
