import React, { CSSProperties, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { ComponentType, DetailedHTMLProps, useState } from 'react';
import './TextAreaInput.scss';
import { IconProps } from '../../icons/component-icons/Icon';

// eslint-disable-next-line max-len
type DefaultInputProps = Pick<
  DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
  'onChange' | 'value' | 'onFocus' | 'onBlur' | 'placeholder' | 'maxLength'
>;
type TextAreaInputProps = DefaultInputProps & {
  icon?: ComponentType<IconProps>;
  name?: string;
  label?: string;
  error?: string;
  disabled?: boolean;
  info?: string;
  dataCy?: string;
  style?: CSSProperties;
  inputStyle?: CSSProperties;
  autoFocus?: boolean;
};

export default function TextAreaInput({
  icon: Icon,
  name,
  label,
  value,
  onChange,
  onFocus,
  onBlur,
  placeholder,
  error,
  disabled,
  info,
  maxLength,
  dataCy,
  style,
  inputStyle,
  autoFocus,
}: TextAreaInputProps) {
  const [isFocused, setIsFocused] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const maxLengthError = useMemo(() => {
    if (!maxLength || !value) return false;
    if (typeof value !== 'string') 
      throw new Error("Value is not a string and cannot be computed with the maxLength props.");
    return value.length >= maxLength;
  }, [maxLength, value]);

  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; 
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [value]);

  function handleFocus(event: any) {
    setIsFocused(true);
    onFocus?.(event);
  }

  function handleBlur(event: any) {
    setIsFocused(false);
    onBlur?.(event);
  }

  return (
    <div
      className="text-area-input"
      style={style}
    >
      {label && (
        <label
          htmlFor={name}
          className={classNames({ error: error || maxLengthError })}
        >
          {label}
        </label>
      )}

      <div
        className={classNames(
          'text-area-input__main',
          { 'text-area-input__main--focused': isFocused },
          { 'text-area-input__main--error': error || maxLengthError },
          { 'text-area-input__main--disabled': disabled },
        )}
      >
        {Icon && <Icon className="text-area-input__main__icon" />}
        <textarea
          ref={textareaRef}
          data-cy={dataCy}
          id={name}
          {...{
            name,
            value,
            placeholder,
            onChange: (e) => {
              adjustHeight();
              onChange?.(e);
            },
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
          style={inputStyle}
          autoFocus={autoFocus}
        />
      </div>

      {maxLength && (
        <span
          className={classNames('text-area-input__max-length', {
            'text-area-input__max-length--error': maxLengthError,
          })}
        >
          {typeof value === 'string' ? value.length : 0}/{maxLength}
        </span>
      )}

      {info && <span className="text-area-input__info">{info}</span>}

      {error && <span className="text-area-input__error">{error}</span>}
    </div>
  );
}
