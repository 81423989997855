import { gql } from '@apollo/client';

export const ADD_COMPANY_RECRUITING_WORKING_AREA = gql`
  mutation ADD_COMPANY_RECRUITING_AREA($companyId: ID!, $workingArea: String!) {
    addCompanyRecruitingWorkArea(companyId: $companyId, workingArea: $workingArea) {
      workingAreas
    }
  }
`;

export const REMOVE_COMPANY_RECRUITING_WORKING_AREA = gql`
  mutation REMOVE_COMPANY_RECRUITING_AREA($companyId: ID!, $workingArea: String!) {
    removeCompanyRecruitingWorkArea(companyId: $companyId, workingArea: $workingArea) {
      workingAreas
    }
  }
`;
