import React, { useState } from 'react';
import SelectInput from '../../../../../components/select-input/SelectInput';
import { Field, Formik } from 'formik';
import buttonCommonTranslations from '../../../../common/common-translations/button.translations';
import InlineLoader from '../../../../common/inline-loader/InlineLoader';
import FormikFieldError from '../../../../common/formik/components/formik-field-error/FormikFieldError';
import FormikStatusError from '../../../../common/formik/components/formik-status-error/FormikStatusError';
import FormikTextareaField from '../../../../common/formik/components/formik-textarea-field/FormikTextareaField';
import MisconductReportSuccess from './components/misconduct-report-success/MisconductReportSuccess';
import {
  Report_MemberMutation,
  MutationReportMemberArgs,
  ReportReason,
  Get_ConversationQuery,
} from '../../../../../@types/graphql.d';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { REPORT_MEMBER } from './ReportMember.gql';
import translations, { errorTranslations, defineReportSelection } from './MisconductReportModal.translations';
import { MisconducReportModalValidationSchema } from './MisconductReportModal.validation';

import styles from './MisconductReportModal.module.scss';
import ButtonsGroup from '../../../../../components/buttons-group/ButtonsGroup';
import SecondaryButton from '../../../../../components/secondary-button/SecondaryButton';
import useModal from '../../../../../components/modal/Modal.hook';
import PrimaryButton from '../../../../../components/primary-button/PrimaryButton';
import { useIntl } from 'react-intl';

type MisconductReportModalProps = {
  conversation: NonNullable<Get_ConversationQuery['conversation']>;
};

function MisconductReportModal({ conversation }: MisconductReportModalProps) {
  const intl = useIntl();
  const [, closeModal] = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [succeded, setSucceded] = useState(false);
  const interlocutor = conversation.interlocutorV2;

  if (!interlocutor) {
    return <InlineLoader />;
  }

  const [reportMember] = useMutation<Report_MemberMutation, MutationReportMemberArgs>(REPORT_MEMBER, {
    onCompleted: () => {
      setSucceded(true);
      enqueueSnackbar(intl.formatMessage(errorTranslations.success), { variant: 'success' });
    },
    onError: () => {
      setSucceded(false);
      enqueueSnackbar(intl.formatMessage(errorTranslations.default), { variant: 'error' });
    },
  });

  const onSubmit: (arg0: { explanation: string; reason: ReportReason }) => void = (values) => {
    reportMember({
      variables: {
        reportedId: interlocutor.id,
        ...values,
      },
    });
  };

  return (
    <Formik
      initialValues={{
        explanation: '',
        reason: ReportReason.DidNotShow,
      }}
      validationSchema={MisconducReportModalValidationSchema(intl)}
      onSubmit={onSubmit}
      validateOnMount
    >
      {({ handleSubmit, values, isValid, setFieldValue }) =>
        !succeded ? (
          <form
            onSubmit={handleSubmit}
            className={styles.form}
          >
            <p
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(translations.description, {
                  firstName: interlocutor?.firstName,
                }),
              }}
            />
            <Field
              name="reason"
              component={SelectInput}
              label={`${intl.formatMessage(translations.title)}`}
              className={styles.field}
              value={values.reason}
              values={Object.values(ReportReason).map((reason) => ({
                value: reason,
                translation: intl.formatMessage(defineReportSelection[reason]),
              }))}
              onChange={(reason: ReportReason) => setFieldValue('reason', reason)}
            />
            <FormikFieldError
              name="reason"
              className={styles.textareaError}
              asyncFieldErrorTranslations={errorTranslations}
            />
            <Field
              name="explanation"
              maxCharCount={500}
              rows={3}
              label={intl.formatMessage(translations.label)}
              component={FormikTextareaField}
              className={styles.textareaWrapper}
            />
            <FormikFieldError
              name="explanation"
              className={styles.textareaError}
              asyncFieldErrorTranslations={errorTranslations}
            />
            <FormikStatusError translations={errorTranslations} />

            <ButtonsGroup>
              <SecondaryButton
                label={intl.formatMessage(buttonCommonTranslations.cancel)}
                onClick={closeModal}
              />
              <PrimaryButton
                label={intl.formatMessage(buttonCommonTranslations.submit)}
                submit
                disabled={!isValid}
              />
            </ButtonsGroup>
          </form>
        ) : (
          <MisconductReportSuccess />
        )
      }
    </Formik>
  );
}

export default MisconductReportModal;
