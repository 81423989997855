import React, { useContext } from 'react';
import { CurrentUserContext } from '../../../../../../../contexts/current-user-context/CurrentUserContext';
import ExternalLinkOpenModal from '../../../../../../../components/modal-external-link/ExternalLinkOpenModal';
import Avatar from '../../../../../../../components/avatar/Avatar';
import TextWithExternalLink from '../../../../../../../components/external-link-in-text/TextWithExternalLink';
import MessageFooter from './components/message-footer/MessageFooter';

import styles from './MessageDisplay.module.scss';
import useModal from '../../../../../../../components/modal/Modal.hook';
import { ExternalLinkOpenModalTranslations } from '../../../../../../../components/modal-external-link/ExternalLinkOpenModal.translations';
import { Get_Conversation_MessagesQuery } from '../../../../../../../@types/graphql.d';
import { useIntl } from 'react-intl';

interface MessageDisplayProps {
  message: NonNullable<Get_Conversation_MessagesQuery['conversation']>['messages'][0];
  isLastSentMessage: boolean;
}

function MessageRow({ message }: Pick<MessageDisplayProps, 'message'>): JSX.Element {
  const { user } = message;

  return (
    <div className={styles.messageSender}>
      <Avatar
        className={styles.avatar}
        avatar={user.avatar.thumb}
        size="small"
      />
    </div>
  );
}

function MessageDisplay({ message, isLastSentMessage }: MessageDisplayProps): JSX.Element {
  const intl = useIntl();
  const [openModal, closeModal] = useModal();
  const { currentUser } = useContext(CurrentUserContext);
  const { user } = message;

  const isSenderCurrentUser = user.id === currentUser?.id;

  const handleExternalLinkClick = (href: string) =>
    openModal({
      title: intl.formatMessage(ExternalLinkOpenModalTranslations.externalLinkModalTitle),
      content: (
        <ExternalLinkOpenModal
          onConfirm={() => {
            window.open(href, '_blank');
            closeModal();
          }}
        />
      ),
    });

  return (
    <div className={styles.message}>
      {!isSenderCurrentUser && <MessageRow message={message} />}
      <div className={styles.messageContainer}>
        <div className={styles.text}>
          <TextWithExternalLink
            text={message.text}
            onExternalLinkClick={handleExternalLinkClick}
          />
        </div>
        <MessageFooter
          createdAt={message.createdAt}
          attachment={message.attachment}
          seenAt={message.seenAt}
          isLastSentMessage={isLastSentMessage}
        />
      </div>
      {isSenderCurrentUser && <MessageRow message={message} />}
    </div>
  );
}

export default MessageDisplay;
