import React from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import { PageLoader } from '../../../components/loader/Loader';
import { Get_Employee_User_IdQuery, Get_Employee_User_IdQueryVariables } from '../../../@types/graphql';
import type { CompanyViewParams } from '../../../@types/routes-params';
import EmployeeManageToolbox from '../ambassadors/components/employee-manage-toolbox/EmployeeManageToolbox';
import { GET_EMPLOYEE_USER_ID } from './getEmployeeUserId.gql';

function EmployeeEditionView({ employeeid }: { employeeid: string }) {
  const { data, loading } = useQuery<Get_Employee_User_IdQuery, Get_Employee_User_IdQueryVariables>(
    GET_EMPLOYEE_USER_ID,
    {
      variables: { id: employeeid },
    },
  );

  if (loading) {
    return (
      <>
        <PageLoader />
      </>
    );
  }

  const ambivalentUserId = data?.companyEmployee?.ambivalent && data?.companyEmployee?.userId;
  if (!ambivalentUserId) {
    return (
      <>
        <PageLoader />
      </>
    );
  }

  return (
    <div>
      <EmployeeManageToolbox />
    </div>
  );
}

function EmployeeEditionViewWrapper() {
  const { employeeid } = useParams<CompanyViewParams>();

  if (!employeeid || !employeeid.length) {
    return <></>;
  }
  return (
    <div>
      <EmployeeEditionView employeeid={employeeid} />
    </div>
  );
}

export const EmployeeEditionViewForMjgAdmin = EmployeeEditionViewWrapper;
export default EmployeeEditionViewWrapper;
