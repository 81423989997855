import React, { useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import UsersIcon from '../../../icons/component-icons/UsersIcon';
import { PageLoader } from '../../../components/loader/Loader';
import classNames from 'classnames';
import { GeneralTranslations } from '../../../i18n/general.translations';
import {
  AppointmentsSortFieldsEnum,
  Get_Appointment_ListQuery,
  Get_Appointment_ListQueryVariables,
} from '../../../@types/graphql.d';
import Legend from '../components/Legend';

import './AppointmentList.scss';
import NoAppointment from './components/no-appointment/NoAppointment';
import { GET_APPOINTMENT_LIST } from './AppointmentList.gql';
import AppointmentListItem from './components/appointment-list-item/AppointmentListItem';
import { AppointmentListTranslations } from './AppointmentList.translations';
import { useIntl } from 'react-intl';

export default function AppointmentList() {
  const intl = useIntl();
  const [past, setPast] = useState(false);
  const [cursors, setCursors] = useState<string[]>([]);
  const lastCursor: string | undefined = useMemo(() => cursors[cursors.length - 1], [cursors]);

  const { data, loading } = useQuery<Get_Appointment_ListQuery, Get_Appointment_ListQueryVariables>(
    GET_APPOINTMENT_LIST,
    {
      variables: {
        after: lastCursor,
        filters: { past, cancelled: false },
        sort: [past ? AppointmentsSortFieldsEnum.FromDesc : AppointmentsSortFieldsEnum.FromAsc],
      },
      fetchPolicy: 'network-only',
    },
  );

  if (loading) {
    return <PageLoader />;
  }

  return (
    <main className="appointments">
      <h1 className="appointments__title">
        <UsersIcon className="appointments__title__icon" />
        {intl.formatMessage(AppointmentListTranslations.title)}
      </h1>
      <Legend type="appointments" />

      <div className="appointments__filters">
        <button
          type="button"
          className={classNames({ selected: !past })}
          onClick={() => setPast(false)}
        >
          {intl.formatMessage(AppointmentListTranslations.upcoming)}
        </button>
        <button
          type="button"
          className={classNames({ selected: past })}
          onClick={() => setPast(true)}
        >
          {intl.formatMessage(AppointmentListTranslations.past)}
        </button>
      </div>

      {data?.appointments?.edges && data.appointments.edges.length > 0 ? (
        <div className="appointments__items">
          {data.appointments.edges.map((edge) => (
            <AppointmentListItem
              key={edge.node!.id}
              appointment={edge.node!}
              isPast={past}
            />
          ))}
        </div>
      ) : (
        <NoAppointment />
      )}

      {!(cursors.length === 0 && !data?.appointments?.pageInfo?.hasNextPage) && (
        <div className="appointments__paging-buttons">
          <button
            type="button"
            className="appointments__paging-buttons__button"
            onClick={() => {
              setCursors((prevCursors) => {
                const copy = [...prevCursors];
                copy.pop();
                return copy;
              });
            }}
            disabled={cursors.length === 0}
          >
            {intl.formatMessage(GeneralTranslations.previous)}
          </button>
          <button
            type="button"
            className="appointments__paging-buttons__button"
            onClick={() => {
              setCursors((prevCursors) => [...prevCursors, data?.appointments?.pageInfo?.endCursor || '']);
            }}
            disabled={!data?.appointments?.pageInfo?.hasNextPage}
          >
            {intl.formatMessage(GeneralTranslations.next)}
          </button>
        </div>
      )}
    </main>
  );
}
