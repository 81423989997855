import React, { memo } from 'react';
import { Layout } from './components/layout/Layout';
import { defineMessages, useIntl } from 'react-intl';
import TextAreaInput from '../../../../components/text-area-input/TextAreaInput';
import { useReviewContext } from '../ReviewContext';
import { PanelProps } from './Panel';
import { ConversationTypeKeyEnum, UserProfile } from '../../../../@types/graphql.d';
import { MESSAGES_MAX_LENGTH } from './validations';

const translations = defineMessages({
  title: {
    id: 'Review.RecommendHRPanel.title',
    defaultMessage:
      'Ajoutez un commentaire pour votre administrateur {isStudent, select, true {établissement} other {entreprise}}',
  },
  individualQuestion: {
    id: 'Review.RecommendHRPanel.individualQuestion',
    defaultMessage: 'Indiquez si vous recommandez de rester en contact avec le Membre et pourquoi.',
  },
  groupQuestion: {
    id: 'Review.RecommendHRPanel.groupQuestion',
    defaultMessage:
      'Ajoutez un commentaire sur le rendez-vous de groupe et partagez vos impressions sur les échanges collectifs.',
  },
  placeholder: {
    id: 'Review.RecommendHRPanel.placeholder',
    defaultMessage: 'Votre message...',
  },
});

export const RecommendHRPanel: React.FC<PanelProps> = memo(({ appointment }) => {
  const { reviewFormik } = useReviewContext();
  const { formatMessage } = useIntl();
  console.log(appointment.ambassador.profileType);
  return (
    <Layout
      title={formatMessage(translations.title, {
        isStudent: appointment.ambassador.profileType === UserProfile.InSchool,
      })}
      subQuestion={formatMessage(
        appointment.appointmentType === ConversationTypeKeyEnum.Individual
          ? translations.individualQuestion
          : translations.groupQuestion,
      )}
      style={{ paddingTop: 88, maxWidth: 720, margin: '0 auto' }}
    >
      <TextAreaInput
        name="message-for-hr"
        style={{ width: '100%' }}
        placeholder={formatMessage(translations.placeholder)}
        value={reviewFormik.values.messageForHR}
        onChange={(e) => reviewFormik.setFieldValue('messageForHR', e.target.value)}
        maxLength={MESSAGES_MAX_LENGTH}
      />
    </Layout>
  );
});
