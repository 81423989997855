import { gql } from '@apollo/client';

export const GET_COMPANY_COMMUNICATION_TOOL_QUERY = gql`
  query GetCompanyCommunicationTool($companyId: ID!) {
    company(companyId: $companyId) {
      name
      canCustomCommunicationKit
      abyssaleDynamicUrl
      communicationKitUrl
    }
  }
`;
