import React, { PropsWithChildren } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import 'react-loading-skeleton/dist/skeleton.css';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Layout.scss';

import Footer from '../../scenes/layout/footer/Footer';
import Header from '../../scenes/layout/header/Header';

const SHOW_INFORMATION_BANNER = false;

const Layout = ({ connected, children }: PropsWithChildren<{ connected: boolean }>) => (
  <Container
    className="layout"
    fluid
  >
    <Header connected={connected} />
    <Row
      className={classNames('view', {
        'view--with-banner': SHOW_INFORMATION_BANNER,
      })}
    >
      <Col md={12}>{children}</Col>
    </Row>
    <Footer />
  </Container>
);

Layout.propTypes = {
  flow: PropTypes.string,
  isFooterVisible: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default Layout;
