import React, { memo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import ButtonsGroup from '../../../../components/buttons-group/ButtonsGroup';
import PrimaryButton from '../../../../components/primary-button/PrimaryButton';
import { InterlocutorDetails } from './components/interlocutor-details/InterlocutorDetails';
import { AppointmentDate } from './components/appointment-date/AppointmentDate';
import { Layout } from './components/layout/Layout';
import useIsMobile from '../../../../hooks/useIsMobile';
import {
  AppointmentDidNotTookPlaceReasonEnum,
  ApproveClaimMutation,
  ApproveClaimMutationVariables,
  DenyAppointmentClaimMutation,
  DenyAppointmentClaimMutationVariables,
} from '../../../../@types/graphql.d';
import { Claim, ReviewInterlocutor } from '../Review.gql';
import ReviewRadioInput from './components/review-radio-input/ReviewRadioInput';
import { AppointmentDidNotTookPlaceReasonTranslations } from '../../../../i18n/appointment-did-not-took-place-reason.translations';
import TextAreaInput from '../../../../components/text-area-input/TextAreaInput';
import { ClaimFormikType } from '../Claim';

const translations = defineMessages({
  title: {
    id: 'Review.ClaimPanel.title',
    defaultMessage: '{firstName} déclare avoir eu un rendez-vous avec vous',
  },
  didTookPlace: {
    id: 'Review.ClaimPanel.didTookPlace',
    defaultMessage: 'Il a eu lieu',
  },
  didNotTookPlace: {
    id: 'Review.ClaimPanel.didNotTookPlace',
    defaultMessage: "Il n'a pas eu lieu",
  },
});

type LandingPanelProps = {
  interlocutor: ReviewInterlocutor;
  claim: Claim;
  formik: ClaimFormikType;
};

export const ClaimLandingPanel: React.FC<LandingPanelProps> = memo(({ interlocutor, claim, formik }) => {
  const { formatMessage } = useIntl();
  const isMobile = useIsMobile();

  const didTookPlace = () => {
    formik.setFieldValue('isDenied', false);
    formik.handleSubmit();
  };

  const didNotTookPlace = () => {
    formik.setFieldValue('isDenied', true);
  };

  return (
    <Layout
      title={formatMessage(translations.title, { firstName: interlocutor.firstName })}
      alignment="center"
      style={{ paddingTop: 100 }}
    >
      <InterlocutorDetails interlocutor={interlocutor} />
      <AppointmentDate date={claim?.from} />

      <ButtonsGroup
        style={{ marginTop: isMobile ? 32 : 64, columnGap: 23, width: isMobile ? undefined : 512 }}
        column={isMobile}
      >
        <PrimaryButton
          label={formatMessage(translations.didTookPlace)}
          onClick={didTookPlace}
        />
        <PrimaryButton
          label={formatMessage(translations.didNotTookPlace)}
          outlined
          onClick={didNotTookPlace}
        />
      </ButtonsGroup>
    </Layout>
  );
});

const ORDERED_DID_NOT_TOOK_PLACE_REASON_ENUM = [
  AppointmentDidNotTookPlaceReasonEnum.DidNotShowUp,
  AppointmentDidNotTookPlaceReasonEnum.Cancelled,
  AppointmentDidNotTookPlaceReasonEnum.Rescheduled,
  AppointmentDidNotTookPlaceReasonEnum.WasNotScheduled,
  AppointmentDidNotTookPlaceReasonEnum.ForgotToGo,
  AppointmentDidNotTookPlaceReasonEnum.Other,
];

const denyTranslations = defineMessages({
  title: {
    id: 'Review.DenyClaimReasonPanel.title',
    defaultMessage: 'Dîtes-nous pourquoi le rendez-vous n’a pas eu lieu',
  },
  reasonDescription: {
    id: 'Review.DenyClaimReasonPanel.reasonDescription',
    defaultMessage: 'Indiquez la raison',
  },
});

export const DenyClaimReasonPanel: React.FC<{ formik: ClaimFormikType }> = memo(({ formik }) => {
  const { formatMessage } = useIntl();

  return (
    <Layout
      title={formatMessage(denyTranslations.title)}
      style={{ paddingTop: 88 }}
    >
      <ReviewRadioInput
        name="reason"
        value={formik.values.reason}
        values={ORDERED_DID_NOT_TOOK_PLACE_REASON_ENUM.map((e) => ({
          value: e,
          translation: formatMessage(AppointmentDidNotTookPlaceReasonTranslations[e], { asMember: false }),
        }))}
        onSelection={(v) => {
          formik.setFieldValue('reason', v);
        }}
      />

      {formik.values.reason === AppointmentDidNotTookPlaceReasonEnum.Other && (
        <TextAreaInput
          label={formatMessage(denyTranslations.reasonDescription)}
          style={{ width: '100%', maxHeight: 100 }}
          value={formik.values.description}
          onChange={(e) => formik.setFieldValue('description', e.target.value)}
        />
      )}
    </Layout>
  );
});
