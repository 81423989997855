import { useQuery } from '@apollo/client';
import { generatePath, useNavigate, useParams } from 'react-router';
import useCurrentPath from '../../../../hooks/useCurrentPath.hook';
import { CompanyAdminRoutes } from '../../../../routes';
import { Get_Companies_ListQuery, Get_Companies_ListQueryVariables } from '../../../../@types/graphql.d';
import { GET_COMPANIES_LIST } from './useCompanyUnique.gql';

export default function useCompanyUnique(): string | undefined {
  const navigate = useNavigate();
  const currentPath = useCurrentPath();
  const params = useParams();
  const companyId = params.companyid || params.id;

  const { data } = useQuery<Get_Companies_ListQuery, Get_Companies_ListQueryVariables>(GET_COMPANIES_LIST, {
    skip: !!companyId,
    fetchPolicy: 'network-only',
  });

  if (companyId) {
    return companyId;
  }
  if (data) {
    switch (currentPath) {
      case CompanyAdminRoutes.companyAdminDashboard:
        navigate(
          generatePath(CompanyAdminRoutes.companyAdminDashboardWithId, {
            companyid: data?.companies?.[0].id ?? null,
          }),
        );
        break;
      case CompanyAdminRoutes.companyAdminAppointments:
        navigate(
          generatePath(CompanyAdminRoutes.companyAdminAppointmentsWithId, {
            companyid: data?.companies?.[0].id ?? null,
          }),
        );
        break;
      case CompanyAdminRoutes.connectCompanyHome:
        navigate(
          generatePath(CompanyAdminRoutes.connectCompanyHomeWithId, {
            id: data?.companies?.[0].id ?? null,
          }), 
        );
        break;
      default:
        break;
    }
  }
  return undefined;
}
