import { defineMessages } from 'react-intl';
import { CompanyReportRange } from '../../../../../../../@types/graphql.d';

export default defineMessages({
  [CompanyReportRange.ThisMonth]: {
    id: 'CompanyReportRangeSelector.ThisMonth',
    defaultMessage: 'Ce mois-ci',
  },
  [CompanyReportRange.LastMonth]: {
    id: 'CompanyReportRangeSelector.LastMonth',
    defaultMessage: 'Le mois dernier',
  },
  [CompanyReportRange.FromTheBeginning]: {
    id: 'CompanyReportRangeSelector.FromTheBeginning',
    defaultMessage: 'Depuis le début',
  },
});
