import React, { useEffect, useState } from 'react';
import { AutoSizer, Table, Column, RowMouseEventHandlerParams, Index } from 'react-virtualized';
import { generatePath, useNavigate, useMatch, useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import {
  Get_Company_Employee_Appointments_TotalQuery,
  Get_Company_Employee_Appointments_TotalQueryVariables,
  Get_Company_Employee_AppointmentsQuery,
  Get_Company_Employee_AppointmentsQueryVariables,
  Get_Company_TypeQuery,
  Get_Company_TypeQueryVariables,
} from '../../../../../@types/graphql.d';
import { PageLoader } from '../../../../../components/loader/Loader';
import useCompanyUnique from '../../../hooks/useCompanyUnique/useCompanyUnique';
import Routes from '../../../../../routes';
import {
  GET_COMPANY_EMPLOYEE_APPOINTMENTS_TOTAL,
  GET_COMPANY_EMPLOYEE_APPOINTMENTS,
  GET_COMPANY_TYPE,
} from '../../CompanyAdminAppointmentsView.gql';
import AppointmentContext from '../../context/context';
import CompanyAdminAppointmentSidebar from '../company-admin-appointment-sidebar/CompanyAdminAppointmentSidebar';
import HeaderCellRendererWithTooltip from './components/header-cell-renderer-with-tooltip/HeaderCellRendererWithTooltip';
import DateCellRenderer from './components/date-cell-renderer/DateCellRenderer';
import ViewDetailCellRenderer from './components/view-detail-cell-renderer/ViewDetailCellRenderer';
import IdentityCellRenderer from './components/identity-cell-renderer/IdentityCellRenderer';
import ReviewCellRenderer from './components/review-cell-render/ReviewCellRenderer';
import DateHeaderRenderer from './components/date-header-renderer/DateHeaderRenderer';
import WantToBeRecontactedCellRenderer from './components/want-to-be-recontacted-cell-renderer/wantToBeRecontactedCellRenderer';
import translations from './CompanyAdminAppointmentsList.translations';

import styles from './CompanyAdminAppointmentsList.module.scss';
import ahoy from 'ahoy.js';
import { useIntl } from 'react-intl';

function CompanyAdminAppointmentsList() {
  const intl = useIntl();
  const companyid = useCompanyUnique() || '';
  const isMjgAdmin = !!useMatch(Routes.mjgAdminCompaniesAppointmentsWithId);
  const params = useParams();
  const navigate = useNavigate();
  const perPage = 25;

  const [page, setPage] = useState(0);

  const { data, loading } = useQuery<
    Get_Company_Employee_AppointmentsQuery,
    Get_Company_Employee_AppointmentsQueryVariables
  >(GET_COMPANY_EMPLOYEE_APPOINTMENTS, {
    skip: !companyid,
    variables: {
      companyId: companyid || '',
      perPage,
      page,
    },
  });

  const { data: typeOfCompany } = useQuery<Get_Company_TypeQuery, Get_Company_TypeQueryVariables>(GET_COMPANY_TYPE, {
    skip: !companyid,
    variables: {
      companyId: companyid || '',
    },
  });

  useEffect(() => {
    if (companyid && typeOfCompany?.company?.name) {
      ahoy.track('b2b_connect_viewed_appointments_page', {
        companyId: companyid,
        companyName: typeOfCompany.company.name,
      });
    }
  }, [companyid, typeOfCompany?.company?.name]);

  const isPremiumSchool = typeOfCompany?.company?.isPremiumSchool;

  const { data: totalData, loading: totalLoading } = useQuery<
    Get_Company_Employee_Appointments_TotalQuery,
    Get_Company_Employee_Appointments_TotalQueryVariables
  >(GET_COMPANY_EMPLOYEE_APPOINTMENTS_TOTAL, {
    skip: !companyid,
    variables: {
      companyId: companyid || '',
    },
  });

  const [rowIndex, setRowIndex] = useState<number | undefined>(undefined);
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoFurther, setCanGoFurther] = useState(false);

  const appointments = data?.companyEmployeeAppointments?.appointments || [];
  const count = totalData?.companyEmployeeAppointments.totalCount || 0;

  useEffect(() => {
    if (params?.appointmentid) {
      const index = appointments.findIndex((item) => item.id === params.appointmentid);
      setRowIndex(index);
      setCanGoBack(index > 0);
      setCanGoFurther(index < count);
    } else {
      setRowIndex(undefined);
      setCanGoBack(false);
      setCanGoFurther(false);
    }
  }, [params.appointmentid]);

  const shouldLoad = loading || !data || !data.companyEmployeeAppointments || totalLoading || !totalData;

  if (shouldLoad) {
    return <PageLoader />;
  }

  function displayDetails(id: string, index: number) {
    if (!id) {
      return;
    }
    setRowIndex(index);
    navigate(
      generatePath(isMjgAdmin ? Routes.mjgAdminCompaniesAppointmentsDetails : Routes.companyAdminAppointmentsDetails, {
        companyid,
        appointmentid: id,
      }),
    );
  }

  function onRowClick({ rowData }: RowMouseEventHandlerParams) {
    if (rowData.id) {
      const index = appointments.findIndex((item) => item.id === rowData.id);
      displayDetails(rowData.id, index);
    }
  }

  function tableNavigation(operand: number) {
    const appointment = appointments[(rowIndex ?? 0) + operand];
    if (appointment?.id) {
      displayDetails(appointment.id, (rowIndex ?? 0) + operand);
    }
  }

  function getRowClassName({ index }: Index) {
    if (index === rowIndex) {
      return classnames(styles.rowRendererSelected, styles.rowRenderer);
    }
    return styles.rowRenderer;
  }

  const NUMBER_OF_COLS = 7;
  const missingLines = Math.max(perPage - (appointments?.length || 0), 0);

  return (
    <AppointmentContext.Provider
      value={{
        canGoBack,
        canGoFurther,
        tableNavigation,
      }}
    >
      <CompanyAdminAppointmentSidebar />
      <div
        id="test"
        className={styles.companyAdminAppointmentsList}
      >
        <AutoSizer disableHeight>
          {({ width }) => (
            <Table
              width={width}
              height={(appointments.length + missingLines) * 80 + 50}
              rowHeight={80}
              headerHeight={50}
              rowCount={appointments.length + missingLines}
              rowGetter={({ index }) => appointments[index] || {}}
              rowClassName={getRowClassName}
              gridClassName={styles.gridRenderer}
              headerClassName={styles.headerCell}
              onRowClick={onRowClick}
            >
              <Column
                label={intl.formatMessage(translations.fromColumnLabel)}
                dataKey="from"
                headerRenderer={DateHeaderRenderer}
                cellRenderer={DateCellRenderer}
                width={(width / NUMBER_OF_COLS) * 0.5}
                disableSort
              />

              <Column
                label={intl.formatMessage(translations.employeeColumnLabel)}
                dataKey="professional"
                cellRenderer={(data) => (
                  <IdentityCellRenderer
                    intl={intl}
                    {...data}
                  />
                )}
                width={(width / NUMBER_OF_COLS) * 1.2}
                disableSort
              />

              <Column
                label={intl.formatMessage(translations.studentColumnLabel)}
                dataKey="member"
                cellRenderer={(data) => (
                  <IdentityCellRenderer
                    isPremiumSchool={isPremiumSchool}
                    intl={intl}
                    {...data}
                  />
                )}
                width={(width / NUMBER_OF_COLS) * 1.2}
                disableSort
              />

              <Column
                label={
                  isPremiumSchool
                    ? intl.formatMessage(translations.wantToBeRecontactedSchoolColumnLabel)
                    : intl.formatMessage(translations.wantToBeRecontactedColumnLabel)
                }
                dataKey="reviewFromStudent"
                headerRenderer={(props) =>
                  HeaderCellRendererWithTooltip({
                    ...props,
                    tooltipText: isPremiumSchool
                      ? intl.formatMessage(translations.wantToBeRecontactedSchoolTooltip)
                      : intl.formatMessage(translations.wantToBeRecontactedTooltip),
                  })
                }
                cellRenderer={(data) => (
                  <WantToBeRecontactedCellRenderer
                    isPremiumSchool={isPremiumSchool}
                    {...data}
                  />
                )}
                width={(width / NUMBER_OF_COLS) * 0.8}
                disableSort
              />

              <Column
                label={intl.formatMessage(translations.referenceColumnLabel)}
                dataKey="reviewFromProfessional"
                headerRenderer={(props) =>
                  HeaderCellRendererWithTooltip({
                    ...props,
                    tooltipText: isPremiumSchool
                      ? intl.formatMessage(translations.studentThirdCriterionSchoolTooltip)
                      : intl.formatMessage(translations.employeeThirdCriterionTooltip),
                  })
                }
                cellRenderer={(props) =>
                  ReviewCellRenderer({
                    ...props,
                    answer: 'answer3',
                  })
                }
                width={(width / NUMBER_OF_COLS) * 1.2}
                disableSort
              />

              <Column
                label={
                  isPremiumSchool
                    ? intl.formatMessage(translations.studyColumnLabel)
                    : intl.formatMessage(translations.industryColumnLabel)
                }
                dataKey="reviewFromStudent"
                headerRenderer={(props) =>
                  HeaderCellRendererWithTooltip({
                    ...props,
                    tooltipText: isPremiumSchool
                      ? intl.formatMessage(translations.schoolAmbassadorSecondCriterionTooltip)
                      : intl.formatMessage(translations.studentThirdCriterionTooltip),
                  })
                }
                cellRenderer={(props) =>
                  ReviewCellRenderer({
                    ...props,
                    answer: 'answer3',
                  })
                }
                width={(width / NUMBER_OF_COLS) * 0.8}
                disableSort
              />

              <Column
                label={
                  isPremiumSchool
                    ? intl.formatMessage(translations.studyMatchColumnLabel)
                    : intl.formatMessage(translations.jobMatchEmployeeColumnLabel)
                }
                dataKey="reviewFromProfessional"
                headerRenderer={(props) =>
                  HeaderCellRendererWithTooltip({
                    ...props,
                    tooltipText: isPremiumSchool
                      ? intl.formatMessage(translations.schoolAmbassadorSecondCriterionTooltip)
                      : intl.formatMessage(translations.employeeSecondCriterionTooltip),
                  })
                }
                cellRenderer={(props) =>
                  ReviewCellRenderer({
                    ...props,
                    answer: 'answer2',
                  })
                }
                width={(width / NUMBER_OF_COLS) * 1}
                disableSort
              />

              <Column
                label={
                  isPremiumSchool
                    ? intl.formatMessage(translations.schoolInterestStudentColumnLabel)
                    : intl.formatMessage(translations.jobInterestStudentColumnLabel)
                }
                dataKey="reviewFromStudent"
                headerRenderer={(props) =>
                  HeaderCellRendererWithTooltip({
                    ...props,
                    tooltipText: isPremiumSchool
                      ? intl.formatMessage(translations.memberSchoolSecondCriterionTooltip)
                      : intl.formatMessage(translations.studentSecondCriterionTooltip),
                  })
                }
                cellRenderer={(props) =>
                  ReviewCellRenderer({
                    ...props,
                    answer: 'answer2',
                  })
                }
                width={(width / NUMBER_OF_COLS) * 1}
                disableSort
              />

              <Column
                label=""
                dataKey="id"
                className={styles.viewDetailCell}
                width={(width / NUMBER_OF_COLS) * 0.3}
                cellRenderer={(props) =>
                  ViewDetailCellRenderer({
                    ...props,
                    intl: intl,
                  })
                }
                disableSort
              />
            </Table>
          )}
        </AutoSizer>

        <div className={styles.pagination}>
          <button
            type="button"
            disabled={page === 0}
            onClick={() => setPage(page - 1)}
            className={styles.previousButton}
          >
            {intl.formatMessage(translations.previousButton)}
          </button>
          <button
            type="button"
            disabled={(1 + page) * perPage > totalData.companyEmployeeAppointments.totalCount}
            onClick={() => setPage(page + 1)}
            className={styles.nextButton}
          >
            {intl.formatMessage(translations.nextButton)}
          </button>
        </div>
      </div>
    </AppointmentContext.Provider>
  );
}

export default CompanyAdminAppointmentsList;
