import React, { useState } from 'react';
import classNames from 'classnames';
import Icon from '../../../../../../../../../../../components/icon/Icon';
import TemplateListItemConfirmDeletion from '../template-list-item-confirm-deletion/TemplateListItemConfirmDeletion';
import { ExtendedMessageTemplate } from '../../../../TemplateManager.gql';

import './TemplateListItem.scss';
import XCircleIcon from '../../../../../../../../../../../icons/component-icons/XCircleIcon';
import XCloseIcon from '../../../../../../../../../../../icons/component-icons/XCloseIcon';

interface TemplateListItemProps {
  active: boolean;
  onTemplateClick: (templateId: string) => void;
  template: ExtendedMessageTemplate;
}
function TemplateListItem({ active, template, onTemplateClick }: TemplateListItemProps) {
  const [isWaitingForConfirmationDeletion, setWaitingForConfirmationDeletion] = useState(false);
  function editTemplate() {
    onTemplateClick(template?.id || '');
  }
  function removeTemplate() {
    setWaitingForConfirmationDeletion(true);
  }
  function cancelDeleteMessageTemplate() {
    setWaitingForConfirmationDeletion(false);
  }
  if (isWaitingForConfirmationDeletion) {
    return (
      <TemplateListItemConfirmDeletion
        template={template}
        cancel={cancelDeleteMessageTemplate}
      />
    );
  }

  return (
    <li
      className={classNames({
        'template-list-item': true,
        'template-list-item-active': active,
      })}
    >
      <button
        type="button"
        className="template-label"
        onClick={editTemplate}
      >
        {template?.title}
      </button>
      <div className="template-actions">
        {!template.cannotBeDeleted && (
          <button
            type="button"
            className="remove-button"
            onClick={removeTemplate}
          >
            <XCloseIcon
              className={classNames({
                'remove-button-icon': true,
                active: active,
              })}
            />
          </button>
        )}
      </div>
    </li>
  );
}

export default TemplateListItem;
