import * as Yup from 'yup';
import { errorTranslations } from './MisconductReportModal.translations';
import { ReportReason } from '../../../../../@types/graphql.d';
import { IntlShape } from 'react-intl';

export const MisconducReportModalValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    explanation: Yup.string()
      .ensure()
      .min(1, intl.formatMessage(errorTranslations.too_short))
      .max(500, intl.formatMessage(errorTranslations.too_long))
      .required(intl.formatMessage(errorTranslations.blank)),
    reason: Yup.string()
      .oneOf(Object.values(ReportReason))
      .min(1, intl.formatMessage(errorTranslations.invalidReportReason))
      .required(intl.formatMessage(errorTranslations.invalidReportReason)),
  });
