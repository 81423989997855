import React, { ComponentType, CSSProperties, memo } from 'react';
import { IconProps } from '../../icons/component-icons/Icon';

import './Tag.scss';

type Type = 'success' | 'error';

type Props = {
  icon?: ComponentType<IconProps>;
  label: string;
  type?: Type;
  style?: CSSProperties;
}

export const Tag: React.FC<Props> = memo(({ icon: Icon, label, type = 'success', style}) => (
  <div className={`tag tag--${type}`} style={style}>
    {Icon && <Icon />}
    <span>{label}</span>
  </div>  
))