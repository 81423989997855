import React from 'react';
import moment from 'moment-timezone';
import PauseIcon from '../../../../../../../icons/component-icons/PauseIcon';
import translations from './ProfessionalQuotaReached.translations';

import './ProfessionalQuotaReached.scss';
import { useIntl } from 'react-intl';

export type ProfessionalQuotaReachedProps = {
  interlocutor: {
    unavailableUntil: string;
  };
};

function ProfessionalQuotaReached({ interlocutor }: ProfessionalQuotaReachedProps) {
  const intl = useIntl();
  return (
    <div className="professional-quota-reached">
      <aside className="professional-quota-reached-mark">
        <div className="icon-wrapper">
          <PauseIcon />
        </div>
      </aside>
      <div className="professional-quota-reached-content">
        <span className="professional-quota-reached-status">{intl.formatMessage(translations.nextAppointment)}</span>
        <span className="professional-quota-reached-date">
          {intl.formatMessage(translations.datePartial, {
            date: intl.formatDate(moment(interlocutor.unavailableUntil), { month: '2-digit', day: '2-digit' }),
          })}
        </span>
      </div>
      <footer className="professional-quota-reached-footer">
        {intl.formatMessage(translations.temporarlyUnavailable)}
      </footer>
    </div>
  );
}

export default ProfessionalQuotaReached;
