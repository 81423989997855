import { PremiumPackEnum } from '../../../../../@types/graphql.d';
import { createContext, useContext } from 'react';

type ConnectCompanyContextValues = {
  id: string;
  premiumPack?: PremiumPackEnum | null;
};

export const ConnectCompanyContext = createContext<ConnectCompanyContextValues | undefined | null>(undefined);

export function useConnectCompanyContext(): ConnectCompanyContextValues {
  const ctx = useContext(ConnectCompanyContext);

  if (!ctx) throw new Error('useConnectCompanyContext can only be used in a ConnectCompanyContext.Provider tree');
  return ctx;
}
