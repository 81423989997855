import React, { useState, useRef } from 'react';
import { useOutsideClickEffect } from '../../../utils/hooks/useOutsideClickEffect';

import './Tooltip.scss';

export type TooltipProps = {
  text: string;
  disabled?: boolean;
};

const Tooltip = ({ text, disabled = false }: TooltipProps) => {
  const [tooltipVisibility, setTooltipVisibility] = useState(false);
  function toogleTooltipBubbleVisibility() {
    setTooltipVisibility(!tooltipVisibility);
  }

  const tooltipBubble = useRef(null);
  useOutsideClickEffect(() => {
    if (tooltipVisibility) {
      setTooltipVisibility(!tooltipVisibility);
    }
  }, tooltipBubble);

  return (
    <div
      ref={tooltipBubble}
      className="tooltip-component"
    >
      <button
        type="button"
        className="tooltip-button"
        onClick={toogleTooltipBubbleVisibility}
        disabled={disabled}
      >
        {tooltipVisibility ? 'i' : 'i'}
      </button>
      {tooltipVisibility && (
        <div
          className="tooltip-bubble"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
    </div>
  );
};

export default Tooltip;
