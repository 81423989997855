import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Archive_EmployeeMutation, Archive_EmployeeMutationVariables } from '../../../../../../../@types/graphql.d';
import translations from './useArchiveAction.translations';
import { ARCHIVE_EMPLOYEE } from './useArchiveAction.gql';
import { useIntl } from 'react-intl';

export default function useArchiveAction(employeeId: string) {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  // handle publish employee mutation
  const [archive] = useMutation<Archive_EmployeeMutation, Archive_EmployeeMutationVariables>(ARCHIVE_EMPLOYEE, {
    refetchQueries: ['GET_ALLOWED_EMPLOYEE_ACTIONS_AND_STATUS'],
  });

  // submit function
  function archiveEmployees() {
    return archive({ variables: { employeeId } })
      .then(() => {
        enqueueSnackbar(intl.formatMessage(translations.success), {
          variant: 'success',
          autoHideDuration: 10000,
        });
      })
      .catch(() => {
        enqueueSnackbar(intl.formatMessage(translations.oneProfileCannotArchived), {
          variant: 'error',
          autoHideDuration: 10000,
        });
      });
  }
  return [archiveEmployees];
}
