import React from 'react';

import { getMonthTranslationIdFromEnumIndex } from '../../../../../i18n/months.translations';
import CalendarPlusIcon from '../../../../../icons/component-icons/CalendarPlusIcon';
import moment from 'moment';
import Card from '../../../../../components/card/Card';
import './Contact.scss';
import { AmbassadorViewContactTranslations } from './Contact.translations';
import { useIntl } from 'react-intl';

export default function StaticContact() {
  const intl = useIntl();
  return (
    <section
      className="ambassador-view-contact"
      style={{ flex: 1 }}
    >
      <Card
        icon={CalendarPlusIcon}
        title={intl.formatMessage(AmbassadorViewContactTranslations.title)}
        className="ambassador-view-contact__container"
        fullHeight
      >
        <div className="ambassador-view-contact__container__remaining">
          <div className="ambassador-view-contact__container__remaining__count blurred">-</div>
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(AmbassadorViewContactTranslations.remaining, {
                month: intl.formatMessage(getMonthTranslationIdFromEnumIndex(moment().month())),
              }),
            }}
          ></p>
        </div>
        <div>
          <div className="ambassador-view-contact__container__response-stats">
            <div className="ambassador-view-contact__container__response-stats__col">
              <div
                dangerouslySetInnerHTML={{ __html: intl.formatMessage(AmbassadorViewContactTranslations.responseRate) }}
              />
              <div className="ambassador-view-contact__container__response-stats__col__value blurred">50%</div>
            </div>
            <div className="ambassador-view-contact__container__response-stats__col">
              <div
                dangerouslySetInnerHTML={{ __html: intl.formatMessage(AmbassadorViewContactTranslations.responseTime) }}
              />
              <div className="ambassador-view-contact__container__response-stats__col__value blurred">{'>24h'}</div>
            </div>
          </div>
        </div>
        <button
          data-cy="ambassador-view-contact__contact-button"
          className="ambassador-view-contact__container__contact-button"
          disabled
        >
          {intl.formatMessage(AmbassadorViewContactTranslations.contact)}
        </button>
      </Card>
    </section>
  );
}
