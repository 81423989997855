import React from 'react';
import { AppointmentReviewFromStudent, AppointmentStatus } from '../../../../../../../../../@types/graphql.d';
import AppointmentReviewDots from '../../../../../appointment-review-dots/AppointmentReviewDots';
import translations from './SidebarAppointmentStudentFeedback.translations';

import styles from '../../SidebarAppointmentSection.module.scss';
import { useIntl } from 'react-intl';

interface SidebarAppointmentStudentFeedbackProps {
  reviewFromStudent?: Pick<
    AppointmentReviewFromStudent,
    'appointmentStatus' | 'answer1' | 'answer2' | 'answer3'
  > | null;
  isPremiumSchool?: boolean;
}
function SidebarAppointmentStudentFeedback({
  reviewFromStudent,
  isPremiumSchool,
}: SidebarAppointmentStudentFeedbackProps) {
  const intl = useIntl();
  function content() {
    switch (reviewFromStudent?.appointmentStatus) {
      case AppointmentStatus.TookPlace:
        return (
          <>
            <h4 className={styles.feedback}>{intl.formatMessage(translations.appointmentUsefulness)}</h4>
            <AppointmentReviewDots score={reviewFromStudent?.answer1} />

            <h4 className={styles.feedback}>
              {isPremiumSchool
                ? intl.formatMessage(translations.studyMatch)
                : intl.formatMessage(translations.jobMatch)}
            </h4>
            <AppointmentReviewDots score={reviewFromStudent?.answer2} />

            <h4 className={styles.feedback}>
              {isPremiumSchool
                ? intl.formatMessage(translations.studyProjection)
                : intl.formatMessage(translations.industryProjection)}
            </h4>
            <AppointmentReviewDots score={reviewFromStudent?.answer3} />
          </>
        );
        break;
      case AppointmentStatus.AwaitingReview:
        return (
          <p className={styles.appointmentNotReviewed}>{intl.formatMessage(translations.appointmentNotReviewed)}</p>
        );
      default:
        return (
          <p className={styles.appointmentDidNotHappen}>{intl.formatMessage(translations.appointmentDidNotHappen)}</p>
        );
        break;
    }
  }
  return (
    <div
      data-testid="student-appointment-feedback"
      className={styles.appointmentFeedback}
    >
      <h3 className={styles.subtitle}>{intl.formatMessage(translations.studentFeedback)}</h3>
      {content()}
    </div>
  );
}

export default SidebarAppointmentStudentFeedback;
