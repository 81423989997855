import React from 'react';
import { useQuery } from '@apollo/client';
import ButtonsGroup from '../../../../../components/buttons-group/ButtonsGroup';
import PrimaryButton from '../../../../../components/primary-button/PrimaryButton';
import SecondaryButton from '../../../../../components/secondary-button/SecondaryButton';
import useModal from '../../../../../components/modal/Modal.hook';
import { Formik, Field } from 'formik';
import { useSnackbar } from 'notistack';
import { useMatch } from 'react-router';
import {
  Add_EmployeeMutation,
  Add_EmployeeMutationVariables,
  Get_Custom_Attributes_FieldsQuery,
  Get_Custom_Attributes_FieldsQueryVariables,
} from '../../../../../@types/graphql.d';
import { InviteEmployeeErrors } from '../../../../../@types/graphql-errors.d';
import FormikFieldError from '../../../../common/formik/components/formik-field-error/FormikFieldError';
import FormikStatusError from '../../../../common/formik/components/formik-status-error/FormikStatusError';
import { Loader } from '../../../../../components/loader/Loader';
import Routes from '../../../../../routes';
import useFormikMutation from '../../../../../utils/hooks/useFormikMutation';
import buttonCommonTranslations from '../../../../common/common-translations/button.translations';
import useOrderedOptions from './hooks/useOrderedOptions';

import validationSchema from './AddEmployeeModal.validations';
import translations, { asyncErrorsTranslations } from './AddEmployeeModal.translations';
import { ADD_EMPLOYEE, GET_CUSTOM_ATTRIBUTES_FIELDS } from './AddEmployeeModal.gql';

import styles from './AddEmployeeModal.module.scss';
import { useIntl } from 'react-intl';
import TextInput from '../../../../../components/text-input/TextInput';
import SelectInput from '../../../../../components/select-input/SelectInput';

interface AddEmployeeModalProps {
  companyid: string;
}

function AddEmployeeModal({ companyid }: AddEmployeeModalProps) {
  const intl = useIntl();
  const [, closeModal] = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const isMjgAdmin = !!useMatch(Routes.mjgAdminBase);

  // Invite employee action
  const [onSubmit] = useFormikMutation<Add_EmployeeMutation, Add_EmployeeMutationVariables>(ADD_EMPLOYEE, {
    refetchQueries: ['GetEmployeeList'],
    onCompleted: (successData) => {
      if (successData?.inviteEmployee) {
        enqueueSnackbar(intl.formatMessage(translations.invitationSent), {
          autoHideDuration: 3000,
          variant: 'success',
        });
        closeModal();
      }
    },
  });

  // Retrieve custom attributes
  const { data, loading } = useQuery<Get_Custom_Attributes_FieldsQuery, Get_Custom_Attributes_FieldsQueryVariables>(
    GET_CUSTOM_ATTRIBUTES_FIELDS,
    {
      skip: !companyid,
      variables: {
        companyId: companyid || '',
      },
    },
  );
  const [companyAttribute1Options, companyAttribute2Options, companyAttribute3Options] = useOrderedOptions(
    data?.company?.customAttributesSet,
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <Formik
      initialValues={{
        companyId: companyid,
        firstName: '',
        lastName: '',
        email: '',
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema(
        {
          companyAttribute1Required: (companyAttribute1Options?.length || 0) > 0,
          companyAttribute2Required: (companyAttribute2Options?.length || 0) > 0,
          companyAttribute3Required: (companyAttribute3Options?.length || 0) > 0,
        },
        intl,
      )}
      validateOnMount
    >
      {({ handleSubmit, values, setFieldValue, isValid, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
          <FormikStatusError translations={asyncErrorsTranslations} />
          <div className={styles.fieldGroup}>
            <div className={styles.fieldWrapper}>
              <Field
                name="firstName"
                component={TextInput}
                placeholder={intl.formatMessage(translations.firstnameLabel)}
                classes={styles.field}
                onChange={(e) => setFieldValue('firstName', e.target.value)}
              />
              <FormikFieldError<Add_EmployeeMutationVariables, InviteEmployeeErrors>
                name="firstName"
                reserveSpace
                asyncFieldErrorTranslations={asyncErrorsTranslations}
              />
            </div>
            <div className={styles.fieldWrapper}>
              <Field
                name="lastName"
                component={TextInput}
                placeholder={intl.formatMessage(translations.nameLabel)}
                classes={styles.field}
                onChange={(e) => setFieldValue('lastName', e.target.value)}
              />
              <FormikFieldError<Add_EmployeeMutationVariables, InviteEmployeeErrors>
                name="lastName"
                reserveSpace
                asyncFieldErrorTranslations={asyncErrorsTranslations}
              />
            </div>
          </div>
          <div className={styles.fieldGroup}>
            <div className={styles.fieldWrapper}>
              <Field
                name="email"
                type="email"
                component={TextInput}
                placeholder={intl.formatMessage(translations.emailLabel)}
                classes={styles.field}
                onChange={(e) => setFieldValue('email', e.target.value)}
              />
              <FormikFieldError<Add_EmployeeMutationVariables, InviteEmployeeErrors>
                name="email"
                reserveSpace
                asyncFieldErrorTranslations={asyncErrorsTranslations}
              />
            </div>
            <div className={styles.fieldWrapper}>
              <Field
                name="companyAttribute1"
                component={SelectInput}
                placeholder={
                  data?.company?.customAttributesSet?.customEmployeeAttribute1 ||
                  intl.formatMessage(translations.companyAttribute1Label)
                }
                value={values.companyAttribute1}
                values={companyAttribute1Options}
                onChange={(attribute1) => setFieldValue('companyAttribute1', attribute1)}
                className={styles.formikSelectField}
                disabled={(companyAttribute1Options?.length || 0) === 0}
              />
              <FormikFieldError<Add_EmployeeMutationVariables, InviteEmployeeErrors>
                name="companyAttribute1"
                reserveSpace
                asyncFieldErrorTranslations={asyncErrorsTranslations}
              />
            </div>
          </div>
          <div className={styles.fieldGroup}>
            <div className={styles.fieldWrapper}>
              <Field
                name="companyAttribute2"
                component={SelectInput}
                placeholder={
                  data?.company?.customAttributesSet?.customEmployeeAttribute2 ||
                  intl.formatMessage(translations.companyAttribute2Label)
                }
                value={values.companyAttribute2}
                values={companyAttribute2Options}
                onChange={(attribute2) => setFieldValue('companyAttribute2', attribute2)}
                className={styles.formikSelectField}
                disabled={(companyAttribute2Options?.length || 0) === 0}
              />
              <FormikFieldError<Add_EmployeeMutationVariables, InviteEmployeeErrors>
                name="companyAttribute2"
                reserveSpace
                asyncFieldErrorTranslations={asyncErrorsTranslations}
              />
            </div>
            <div className={styles.fieldWrapper}>
              <Field
                name="companyAttribute3"
                component={SelectInput}
                placeholder={
                  data?.company?.customAttributesSet?.customEmployeeAttribute3 ||
                  intl.formatMessage(translations.companyAttribute3Label)
                }
                value={values.companyAttribute3}
                values={companyAttribute3Options}
                onChange={(attribute3) => setFieldValue('companyAttribute3', attribute3)}
                className={styles.formikSelectField}
                disabled={(companyAttribute3Options?.length || 0) === 0}
              />
              <FormikFieldError<Add_EmployeeMutationVariables, InviteEmployeeErrors>
                name="companyAttribute3"
                reserveSpace
                asyncFieldErrorTranslations={asyncErrorsTranslations}
              />
            </div>
          </div>
          <ButtonsGroup>
            <SecondaryButton
              label={intl.formatMessage(buttonCommonTranslations.cancel)}
              onClick={closeModal}
            />
            <PrimaryButton
              label={intl.formatMessage(translations.inviteButton)}
              disabled={!isValid || isSubmitting}
              onClick={handleSubmit}
            />
          </ButtonsGroup>
        </form>
      )}
    </Formik>
  );
}

export default AddEmployeeModal;
