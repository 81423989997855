import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';

import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import moment from 'moment';

import './ProfileInformations.scss';

import {
  GetProfileInformationsQuery,
  PublicationStatuses,
  SecondarySituationEnum,
} from '../../../../../@types/graphql.d';
import CheckCircleBrokenIcon from '../../../../../icons/component-icons/CheckCircleBrokenIcon';
import EyeIcon from '../../../../../icons/component-icons/EyeIcon';
import HourglassIcon from '../../../../../icons/component-icons/HourglassIcon';
import UserIcon from '../../../../../icons/component-icons/UserIcon';
import XCircleIcon from '../../../../../icons/component-icons/XCircleIcon';
import { getAccountUrl } from '../../../../../utils/redirection.util';
import { GET_PROFILE_INFORMATIONS_QUERY } from '../../DashboardView.gql';
import DashboardCard, { DashboardCardLoader } from '../../components/dashboard-card/DashboardCard';
import { ProfileInformationsTranslations } from './ProfileInformations.translations';
import LinkedinShare from './component/LinkedinShare';

export default function ProfileInformations() {
  const intl = useIntl();
  const { data, loading } = useQuery<GetProfileInformationsQuery>(GET_PROFILE_INFORMATIONS_QUERY, {
    fetchPolicy: 'network-only',
  });

  const professionalId = data?.currentUserV2?.professionalId;
  const previewUrl = `/professionals/` + professionalId;

  if (loading) return <ProfileInformationsLoader />;
  if (!data?.currentUserV2) return <div>error</div>;

  const { avatars, firstName, lastName, isAmbassador, createdAt, secondarySituation, likesReceivedCount } =
    data.currentUserV2;

  return (
    <DashboardCard
      icon={UserIcon}
      title={intl.formatMessage(ProfileInformationsTranslations.title)}
    >
      <div className="profile-informations">
        <main className="profile-informations__profil">
          <img
            className="profile-informations__profil__avatar"
            src={avatars.url || ''}
          />
          <aside className="profile-informations__profil__basic-informations">
            <div className="profile-informations__profil__basic-informations__name">
              {firstName} {lastName}
            </div>
            <div className="profile-informations__profil__basic-informations__category">
              {isAmbassador
                ? intl.formatMessage(ProfileInformationsTranslations.ambassador)
                : intl.formatMessage(ProfileInformationsTranslations.member)}
            </div>
            <footer className="profile-informations__profil__basic-informations__footer">
              {isAmbassador && (
                <div className="profile-informations__profil__basic-informations__footer__likes">
                  {intl.formatMessage(ProfileInformationsTranslations.likesReceivedCount, {
                    count: likesReceivedCount,
                  })}
                </div>
              )}
            </footer>
          </aside>
        </main>

        <div>
          {intl.formatMessage(ProfileInformationsTranslations.signedUpSince, {
            since: moment(createdAt).format('DD/MM/YYYY'),
          })}
        </div>

        {isAmbassador ? (
          <>
            <a
              href={previewUrl}
              target="_blank"
              rel="noreferrer"
              className="profile-informations__see-my-profil"
            >
              <EyeIcon />
              {intl.formatMessage(ProfileInformationsTranslations.seeMyProfile)}
            </a>
          </>
        ) : (
          secondarySituation &&
          secondarySituation.key !== SecondarySituationEnum.College && (
            <a
              href={getAccountUrl('/profile')}
              className="profile-informations__button"
            >
              {intl.formatMessage(ProfileInformationsTranslations.becomeAmbassador)}
            </a>
          )
        )}

        <LinkedinShare />
      </div>
    </DashboardCard>
  );
}

function ProfileInformationsLoader() {
  return (
    <DashboardCardLoader>
      <div className="profile-informations">
        <main className="profile-informations__profil">
          <Skeleton
            width={64}
            height={64}
            circle
          />

          <aside className="profile-informations__profil__basic-informations skeleton-container-flex-1">
            <Skeleton width={'50%'} />
            <Skeleton width={'50%'} />
            <Skeleton width={'50%'} />
          </aside>
        </main>

        <div>
          <Skeleton width="60%" />
        </div>

        <Skeleton width="50%" />
        <Skeleton width="50%" />

        <Skeleton
          height={32}
          borderRadius={4}
        />
      </div>
    </DashboardCardLoader>
  );
}
