import React, { useMemo } from 'react';
import CompanyAdminDashboardRate from '../company-admin-dashboard-rate/CompanyAdminDashboardRate';

import translations from './CompanyAdminDashboardAppointmentRate.translations';
import { DownloadWithTooltipProps } from '../../../../common/download-with-tooltip/DownloadWithTooltip';
import { useIntl } from 'react-intl';

interface CompanyAdminDashboardAppointmentRateProps {
  value: number | '-';
  rate: number;
  companyName: string;
  companyId: string;
  className?: string;
  blurred?: boolean;
}

function CompanyAdminDashboardAppointmentRate({
  value,
  rate,
  companyName,
  companyId,
  className,
  blurred
}: CompanyAdminDashboardAppointmentRateProps): JSX.Element {
  const intl = useIntl();
  const downloadProps = useMemo((): DownloadWithTooltipProps => {
    const formatedDate = intl
      .formatDate(Date(), {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })
      .replace(/\//g, '_');

    return {
      text: intl.formatMessage(translations.downloadTooltip),
      url: `${import.meta.env.REACT_APP_DOWNLOAD_HOST}/companies/${companyId}/appointment_list`,
      filename: `${companyName.replace(/[ '/()&,’]+/gi, '-')}_${formatedDate}_appointments.xlsx`,
      mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      variant: 'blue-dark',
      disabled: companyId === '' || companyName === '',
    };
  }, [companyId, companyName]);

  return (
    <CompanyAdminDashboardRate
      title={intl.formatMessage(translations.label)}
      tooltip={intl.formatMessage(translations.tooltip)}
      value={value}
      rate={rate}
      className={className}
      download={downloadProps}
      blurred={blurred}
    />
  );
}

export default CompanyAdminDashboardAppointmentRate;
