import React from 'react';
import classNames from 'classnames';
import FileIcon from '../../../../../icons/component-icons/FileIcon';
import translations from './SendMessageActions.translations';

import './SendMessageActions.scss';
import { useIntl } from 'react-intl';

type SendMessageActionsProps = {
  isManagingTemplates: boolean;
  toggleMessageTemplatesManager: () => void;
  isAmbassador: boolean;
  disabled: boolean;
};

export default function SendMessageActions({
  isManagingTemplates,
  toggleMessageTemplatesManager,
  isAmbassador,
  disabled,
}: SendMessageActionsProps) {
  const intl = useIntl();
  return (
    <div className="send-message-actions">
      {isAmbassador && (
        <button
          type="button"
          className={classNames({
            'use-template-button': true,
            'button-active': isManagingTemplates,
          })}
          onClick={toggleMessageTemplatesManager}
        >
          <FileIcon
            className={classNames({
              'send-message-actions__icon': true,
              active: isManagingTemplates,
            })}
          />
        </button>
      )}
      <button
        type="submit"
        className="message-text-submit"
        disabled={disabled}
      >
        {intl.formatMessage(translations.sendMessageButton)}
      </button>
    </div>
  );
}
