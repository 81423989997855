import { defineMessages } from 'react-intl';

export const errorTranslations = defineMessages({});

export default defineMessages({
  companyNameLabel: {
    id: 'CompanyGeneralInformationsWrite.companyNameLabel',
    defaultMessage: "Nom de l'entreprise",
  },
  companySectorLabel: {
    id: 'CompanyGeneralInformationsWrite.companySectorLabel',
    defaultMessage: "Secteur d'activité",
  },
  companySizeLabel: {
    id: 'CompanyGeneralInformationsWrite.companySizeLabel',
    defaultMessage: "Taille d'entreprise",
  },
  submitButton: {
    id: 'CompanyGeneralInformationsWrite.submitButton',
    defaultMessage: 'Valider',
  },
  cancelButton: {
    id: 'CompanyGeneralInformationsWrite.cancelButton',
    defaultMessage: 'Annuler',
  },
  establishmentType: {
    id: 'CompanyGeneralInformationsWrite.establishmentType',
    defaultMessage: "Type d'établissement",
  },
});
