import { gql } from '@apollo/client';

export const GET_COMPANY = gql`
  query GET_COMPANY($companyId: ID!) {
    company(companyId: $companyId) {
      id
      name
      publishedEmployeesCount
      employeePackSize
      customAttributesSet {
        customEmployeeAttribute1
        companyAttributeValues1
        customEmployeeAttribute2
        companyAttributeValues2
        customEmployeeAttribute3
        companyAttributeValues3
      }
      isPremiumSchool
    }
  }
`;
