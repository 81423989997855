import { AnimatePresence, motion } from 'motion/react';
import React, { memo, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import classNames from 'classnames';

import './ExpandableCompletionBlock.scss';

import PrimaryButton from '../../../../../components/primary-button/PrimaryButton';
import TextAreaInput from '../../../../../components/text-area-input/TextAreaInput';
import useIsMobile from '../../../../../hooks/useIsMobile';
import CheckIcon from '../../../../../icons/component-icons/CheckIcon';
import ChevronDownIcon from '../../../../../icons/component-icons/ChevronDownIcon';
import buttonCommonTranslations from '../../../../common/common-translations/button.translations';

type Props = {
  checked: boolean;
  label: string;
  expanded: boolean;
  example: string;
  initialValue?: string;
  disabled: boolean;
  onExpandClick: () => void;
  onSubmit: (value: string) => void;
};

const TRANSLATIONS = defineMessages({
  seeExample: {
    id: 'ExpandableCompletionBlock.seeExample',
    defaultMessage: "Voir l'exemple",
  },
});

export const ExpandableCompletionBlock: React.FC<Props> = memo(
  ({ checked, label, expanded, initialValue, example, disabled, onExpandClick, onSubmit }) => {
    const { formatMessage } = useIntl();
    const isMobile = useIsMobile();

    const [value, setValue] = useState(initialValue || '');
    const [showExample, setShowExample] = useState(false);

    return (
      <div
        className={classNames(
          'expandable-completion-block',
          { 'expandable-completion-block--disabled': disabled },
          { 'expandable-completion-block--expanded': expanded },
          { 'expandable-completion-block--checked': checked },
        )}
      >
        <button
          className="expandable-completion-block__header"
          onClick={onExpandClick}
          disabled={disabled || expanded}
        >
          <div className="expandable-completion-block__header__check">
            <CheckIcon />
          </div>
          <label>{label}</label>
          <ChevronDownIcon className="expandable-completion-block__header__chevron" />
        </button>

        <AnimatePresence>
          {expanded && (
            <motion.div
              style={{ display: 'flex', flexDirection: 'column', rowGap: 16, overflow: 'hidden' }}
              initial={{ marginTop: 0, height: 0 }}
              animate={{ marginTop: 16, height: 'auto' }}
              exit={{ marginTop: 0, height: 0 }}
            >
              <div className="expandable-completion-block__content">
                <TextAreaInput
                  value={value}
                  inputStyle={{ height: isMobile ? 128 : 64 }}
                  onChange={(e) => setValue(e.target.value)}
                  placeholder={example}
                  autoFocus
                />
              </div>
              <div className="expandable-completion-block__footer">
                <div className="expandable-completion-block__footer__actions">
                  <button
                    className={classNames('expandable-completion-block__footer__actions__see-example', {
                      'expandable-completion-block__footer__actions__see-example--active': showExample,
                    })}
                    onClick={() => setShowExample(!showExample)}
                  >
                    <ChevronDownIcon />
                    {formatMessage(TRANSLATIONS.seeExample)}
                  </button>
                  <PrimaryButton
                    label={formatMessage(buttonCommonTranslations.submit)}
                    onClick={() => onSubmit(value)}
                    smaller
                    disabled={value.length < 3}
                  />
                </div>

                <AnimatePresence>
                  {showExample && (
                    <motion.div
                      style={{ overflow: 'hidden' }}
                      initial={{ height: 0 }}
                      animate={{ height: 'auto' }}
                      exit={{ height: 0 }}
                    >
                      <p className="expandable-completion-block__footer__example">{example}</p>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  },
);
