import React, { memo } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

type Props = {
  translation: MessageDescriptor;
  values?: object;
}

export const MJGFormattedMessage: React.FC<Props> = memo(({ translation, values }) => (
  <FormattedMessage
    {...translation}
    values={{
      span: (chunks) => <span>{chunks}</span>,
      strong: (chunks) => <strong>{chunks}</strong>,
      br: <br />,
      ...values,
    }}
  />
))