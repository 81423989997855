import { defineMessages } from 'react-intl';
import { ReportReason } from '../../../../../@types/graphql.d';

export const errorTranslations = defineMessages({
  blank: {
    id: 'MisconductReportModal.explanationRequired',
    defaultMessage: 'Merci de préciser la nature du problème que vous souhaitez signaler',
    description: 'Signaler un étudiant - explication obligatoire',
  },
  too_short: {
    id: 'MisconductReportModal.tooShort',
    defaultMessage: 'Merci de décrire plus en détails le problème',
    description: 'Signaler un étudiant - explication trop courte',
  },
  too_long: {
    id: 'MisconductReportModal.tooLong',
    defaultMessage: 'Merci de décrire le problème en moins de 500 caractères',
    description: 'Signaler un étudiant - explication trop longue',
  },
  default: {
    id: 'MisconductReportModal.default',
    defaultMessage: 'Une erreur est survenue, merci de réessayer',
    description: 'Signaler un étudiant - defaut',
  },
  invalidReportReason: {
    id: 'MisconductReportModal.invalidReportReason',
    defaultMessage: 'Vous devez sélectionner une raison de signalement.',
    description: 'Signaler un étudiant - selectionner une raison',
  },
  success: {
    id: 'MisconductReportModal.success',
    defaultMessage: 'Signalement pris en compte',
  },
});

export const MisconductReportModalTranslations = defineMessages({
  title: {
    id: 'MisconductReportModal.title',
    defaultMessage: 'Signaler un utilisateur',
  },
  description: {
    id: 'MisconductReportModal.description',
    defaultMessage: 'Aidez-nous à comprendre : quel problème souhaitez-vous signaler à propos de {firstName} ?',
  },
  label: {
    id: 'MisconductReportModal.label',
    defaultMessage: 'Décrivez le problème',
  },
  titleDefineReport: {
    id: 'MisconductReportModal.titleDefineReport',
    defaultMessage: 'Selectionner la raison du signalement',
  },
});

export const defineReportSelection = defineMessages({
  [ReportReason.DidNotShow]: {
    id: 'MisconductReportModal.DidNotShowUp',
    defaultMessage: "Ne s'est pas présenté au rendez-vous",
  },
  [ReportReason.Behavior]: {
    id: 'MisconductReportModal.Behavior',
    defaultMessage: 'Comportement irrespectueux',
  },
  [ReportReason.NotResponding]: {
    id: 'MisconductReportModal.NotResponding',
    defaultMessage: 'Ne répond pas aux messages',
  },
  [ReportReason.IsRobot]: {
    id: 'MisconductReportModal.IsRobot',
    defaultMessage: "Ce compte n'est pas une personne réelle",
  },
  [ReportReason.IsSpam]: {
    id: 'MisconductReportModal.IsSpam',
    defaultMessage: 'Spam',
  },
  [ReportReason.Other]: {
    id: 'MisconductReportModal.Other',
    defaultMessage: 'Autre',
  },
});

export default MisconductReportModalTranslations;
