import { defineMessages } from 'react-intl';

export default defineMessages({
  at: {
    id: 'LastInterlocutorEducationDisplay.at',
    defaultMessage: 'à',
    description: 'Affiche le nom de l école de l education',
  },
  endYearOnly: {
    id: 'LastInterlocutorEducationDisplay.endYearOnly',
    defaultMessage: 'Termine ses études en {year}',
    description: 'Placeholder lorsque pas de date de début',
  },
  noEducation: {
    id: 'LastInterlocutorEducationDisplay.noEducation',
    defaultMessage: "Le Membre n'a pas encore renseigné de formation.",
    description: 'placeholder lorsque l etudiant n a pas renseigné d educations',
  },
});
