import { defineMessages } from 'react-intl';

export default defineMessages({
  impossibleAppointment: {
    id: 'ProfessionalFrozen.impossibleAppointment',
    defaultMessage: 'rendez-vous impossible',
    description: 'status rdv impossible',
  },
  inactiveProfile: {
    id: 'ProfessionalFrozen.inactiveProfile',
    defaultMessage: 'Profil inactif',
    description: 'profile inactif car gelé',
  },
});
