import { defineMessages } from 'react-intl';

export const ProfileInformationsTranslations = defineMessages({
  title: {
    id: 'ProfileInformations.title',
    defaultMessage: 'Votre profil',
  },
  ambassador: {
    id: 'ProfileInformations.ambassador',
    defaultMessage: 'Ambassadeur',
  },
  member: {
    id: 'ProfileInformations.member',
    defaultMessage: 'Membre',
  },
  signedUpSince: {
    id: 'ProfileInformations.signedUpSince',
    defaultMessage: 'Inscrit depuis le {since}',
  },
  seeMyProfile: {
    id: 'ProfileInformations.seeMyProfile',
    defaultMessage: 'Voir mon profil',
  },
  shareMyProfile: {
    id: 'ProfileInformations.shareMyProfile',
    defaultMessage: 'Partager mon profil',
  },
  becomeAmbassador: {
    id: 'ProfileInformations.becomeAmbassador',
    defaultMessage: 'Devenir ambassadeur',
  },
  likesReceivedCount: {
    id: 'ProfileInformations.likesReceivedCount',
    defaultMessage: '👍 {count, plural, =0 {# likes} =1 {# like} other {# likes}}',
  },
});
