import React from 'react';
import AlertCircleIcon from '../../../../../icons/component-icons/AlertCircleIcon';
import Routes from '../../../../../routes';
import { Link, generatePath } from 'react-router';
import translations from './NoAppointment.translations';

import './NoAppointment.scss';
import { useIntl } from 'react-intl';

export default function NoAppointment() {
  const intl = useIntl();
  return (
    <div className="no-appointment">
      <div className="no-appointment__content">
        <AlertCircleIcon className="no-appointment__content__icon" />
        <div className="no-appointment__content__message">
          <h3>{intl.formatMessage(translations.mainText)}</h3>
          <p>{intl.formatMessage(translations.subText)}</p>
        </div>
        <Link
          to={generatePath(Routes.search)}
          className="no-appointment__content__button"
        >
          {intl.formatMessage(translations.button)}
        </Link>
      </div>
    </div>
  );
}
