import { defineMessages } from 'react-intl';
import {
  BookAppointmentMutationErrorEnum,
  MoveAppointmentMutationErrorEnum,
  ClaimAppointmentMutationErrorEnum,
} from '../../../../../@types/graphql.d';

export const AppointmentClaimModalTranslations = defineMessages({
  title: {
    id: 'BookAppointmentModal.claim.title',
    defaultMessage: 'Rendez-vous passé',
  },
  description: {
    id: 'BookAppointmentModal.claim.description',
    defaultMessage:
      "Si vous avez eu un rendez-vous avec un professionnel sans que ce dernier ne l'ait inscrit sur la plateforme, vous pouvez maintenant lui demander de le confirmer",
  },
});

export const AppointmentCreateModalTranslations = defineMessages({
  title: {
    id: 'BookAppointmentModal.create.title',
    defaultMessage: 'Nouveau rendez-vous',
  },
  description: {
    id: 'BookAppointmentModal.create.description',
    defaultMessage: "Sélectionnez la date et l'heure du rendez-vous",
  },
});

export const AppointmentUpdateModalTranslations = defineMessages({
  title: {
    id: 'BookAppointmentModal.update.title',
    defaultMessage: 'Modifier le rendez-vous',
  },
  description: {
    id: 'BookAppointmentModal.update.description',
    defaultMessage:
      'En cliquant sur "Valider", les modifications seront enregistrées et les participants du rendez-vous recevront un email de confirmation automatique',
  },
});

export default defineMessages({
  physicalAppointment: {
    id: 'BookAppointmentModal.physicalAppointment',
    defaultMessage: 'RDV Physique',
  },
  phoneAppointment: {
    id: 'BookAppointmentModal.phoneAppointment',
    defaultMessage: 'RDV Téléphonique',
  },
  videoAppointment: {
    id: 'BookAppointmentModal.videoAppointment',
    defaultMessage: 'RDV Vidéo',
  },
  appointmentPlace: {
    id: 'BookAppointmentModal.appointmentPlace',
    defaultMessage: 'Lieu de rencontre',
  },
  videoLink: {
    id: 'BookAppointmentModal.videoLink',
    defaultMessage: 'Lien de vidéoconférence',
  },
  validateFromDateInvalide: {
    id: 'BookAppointmentModal.validateFromDateInvalide',
    defaultMessage: 'Invalide',
  },
  validateFromHourInvalide: {
    id: 'BookAppointmentModal.validateFromHourInvalide',
    defaultMessage: 'Invalide',
  },
});

export const BookAppointmentErrors = {
  [BookAppointmentMutationErrorEnum.AmbassadorMustHaveAReply]: {
    id: 'BookAppointmentMutationErrorEnum.AmbassadorMustHaveAReply',
    defaultMessage: "L'Ambassadeur doit avoir répondu à la conversation pour prendre un rendez-vous",
  },
  [BookAppointmentMutationErrorEnum.AppointmentAlreadyScheduled]: {
    id: 'BookAppointmentMutationErrorEnum.AmbassadorMustHaveAReply',
    defaultMessage: 'Ce rendez-vous est déjà planifié',
  },
  [BookAppointmentMutationErrorEnum.BookAppointmentUnauthorized]: {
    id: 'BookAppointmentMutationErrorEnum.BookAppointmentUnauthorized',
    defaultMessage: "Vous n'êtes pas autorisé à prendre un rendez-vous",
  },
  [BookAppointmentMutationErrorEnum.ConversationIsDisabled]: {
    id: 'BookAppointmentMutationErrorEnum.ConversationIsDisabled',
    defaultMessage: 'La conversation est désactivée',
  },
  [BookAppointmentMutationErrorEnum.MeetingPlaceRequiredForPhysicalAppointment]: {
    id: 'BookAppointmentMutationErrorEnum.MeetingPlaceRequiredForPhysicalAppointment',
    defaultMessage: 'Le lieu de rencontre est requis pour un rendez-vous physique',
  },
  [BookAppointmentMutationErrorEnum.MustBeTheConversationRecipient]: {
    id: 'BookAppointmentMutationErrorEnum.MustBeTheConversationRecipient',
    defaultMessage: 'Vous devez être le destinataire de la conversation pour prendre un rendez-vous',
  },
  [BookAppointmentMutationErrorEnum.MustHaveConversationTogether]: {
    id: 'BookAppointmentMutationErrorEnum.MustHaveConversationTogether',
    defaultMessage: 'Vous devez avoir une conversation ensemble pour prendre un rendez-vous',
  },
  [BookAppointmentMutationErrorEnum.NotFound]: {
    id: 'BookAppointmentMutationErrorEnum.default',
    defaultMessage: 'Une erreur a eu lieu',
  },
  [BookAppointmentMutationErrorEnum.Unauthorized]: {
    id: 'BookAppointmentMutationErrorEnum.default',
    defaultMessage: 'Une erreur a eu lieu',
  },
  [BookAppointmentMutationErrorEnum.UnexpectedValidationErrors]: {
    id: 'BookAppointmentMutationErrorEnum.default',
    defaultMessage: 'Une erreur a eu lieu',
  },
  default: {
    id: 'BookAppointmentMutationErrorEnum.default',
    defaultMessage: 'Une erreur a eu lieu',
  },
};

export const MoveAppointmentErrors = {
  [MoveAppointmentMutationErrorEnum.CannotMoveInThePast]: {
    id: 'MoveAppointmentMutationErrorEnum.AmbassadorMustHaveAReply',
    defaultMessage: 'Vous ne pouvez pas déplacer un rendez-vous dans le passé',
  },
  [MoveAppointmentMutationErrorEnum.CannotMovePastAppointment]: {
    id: 'MoveAppointmentMutationErrorEnum.CannotMovePastAppointment',
    defaultMessage: 'Vous ne pouvez pas déplacer un rendez-vous passé',
  },
  [MoveAppointmentMutationErrorEnum.MustBeTheAmbassador]: {
    id: 'MoveAppointmentMutationErrorEnum.AmbassadorMustHaveAReply',
    defaultMessage: "Vous devez être l'Ambassadeur pour déplacer un rendez-vous",
  },
  default: {
    id: 'MoveAppointmentMutationErrorEnum.default',
    defaultMessage: 'Une erreur a eu lieu',
  },
};

export const ClaimAppointmentErrors = {
  [ClaimAppointmentMutationErrorEnum.AlreadyExists]: {
    id: 'ClaimAppointmentMutationErrorEnum.AlreadyExists',
    defaultMessage: 'Ce rendez-vous existe déjà',
  },
  [ClaimAppointmentMutationErrorEnum.AmbassadorMustHaveAReply]: {
    id: 'ClaimAppointmentMutationErrorEnum.AmbassadorMustHaveAReply',
    defaultMessage: "L'Ambassadeur doit avoir répondu à la conversation pour enregistrer un rendez-vous",
  },
  [ClaimAppointmentMutationErrorEnum.AmbassadorSoftDeleted]: {
    id: 'ClaimAppointmentMutationErrorEnum.AmbassadorSoftDeleted',
    defaultMessage: "L'Ambassadeur a été archivé",
  },
  [ClaimAppointmentMutationErrorEnum.ConversationIsDisabled]: {
    id: 'ClaimAppointmentMutationErrorEnum.ConversationIsDisabled',
    defaultMessage: 'La conversation est désactivée',
  },
  [ClaimAppointmentMutationErrorEnum.InitiatorSoftDeleted]: {
    id: 'ClaimAppointmentMutationErrorEnum.InitiatorSoftDeleted',
    defaultMessage: "L'initiateur a été archivé",
  },
  [ClaimAppointmentMutationErrorEnum.MeetingPlaceRequired]: {
    id: 'ClaimAppointmentMutationErrorEnum.MeetingPlaceRequired',
    defaultMessage: 'Le lieu de rencontre est requis',
  },
  [ClaimAppointmentMutationErrorEnum.MustInitiateAConversation]: {
    id: 'ClaimAppointmentMutationErrorEnum.MustInitiateAConversation',
    defaultMessage: 'Vous devez initier une conversation pour enregistrer un rendez-vous',
  },
  [ClaimAppointmentMutationErrorEnum.StartedAtMustBePast]: {
    id: 'ClaimAppointmentMutationErrorEnum.StartedAtMustBePast',
    defaultMessage: 'La date de début doit être dans le passé',
  },
  default: {
    id: 'MoveAppointmentMutationErrorEnum.default',
    defaultMessage: 'Une erreur a eu lieu',
  },
};
