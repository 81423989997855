import { AnimatePresence, motion } from 'motion/react';
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { FormikProps } from 'formik';

import { ConversationTypeKeyEnum, Grade, GradeKeyEnum, PrimarySituationEnum } from '../../../../@types/graphql.d';
import Card from '../../../../components/card/Card';
import { SimilarProsCarousel } from '../../../../components/similar-pros-carousel/SimilarProsCarousel';
import StaticAutocompleteMultiSelectInput from '../../../../components/static-autocomplete-multi-select-input/StaticAutocompleteMultiSelectInput';
import { SwitchSelector } from '../../../../components/switch-selector/SwitchSelector';
import TextInput from '../../../../components/text-input/TextInput';
import { CurrentUserContext } from '../../../../contexts/current-user-context/CurrentUserContext';
import useIsMobile from '../../../../hooks/useIsMobile';
import { getSampleExamples, getSampleQuestions } from '../../../../i18n/conversation-creation-examples.translations';
import { JobSeekerSupportProgramKeyEnumTranslations } from '../../../../i18n/job-seeker-support-program.translations';
import { FormikValues, StepProps } from '../Step';
import { ExpandableCompletionBlock } from '../components/expandable-completion-block/ExpandableCompletionBlock';
import { SigninZone } from '../components/signin-zone/SigninZone';

const TRANSLATIONS = defineMessages({
  messageCreationCardTitle: {
    id: 'NewConversation.messageCreationCardTitle',
    defaultMessage: 'Rédigez votre message',
  },
  messageBuilderLabel1: {
    id: 'NewConversation.messageBuilderLabel1',
    defaultMessage:
      'Présentez-vous rapidement, vous pouvez notamment commencer par votre prénom, âge, et votre dernière expérience ou formation.',
  },
  messageBuilderLabel2: {
    id: 'NewConversation.messageBuilderLabel2',
    defaultMessage:
      "Apportez des précisions sur votre parcours, vos expériences passées et votre projet professionnel (s'il est défini !)",
  },
  messageBuilderLabel3: {
    id: 'NewConversation.messageBuilderLabel3',
    defaultMessage:
      "Précisez les raisons qui vous poussent à contacter l'Ambassadeur et formalisez votre demande de rendez-vous.",
  },
  messageBuilderLabel4: {
    id: 'NewConversation.messageBuilderLabel4',
    defaultMessage: 'Terminez par une formule de politesse et proposez un rendez-vous.',
  },
  notAcceptGroup: {
    id: 'NewConversation.notAcceptGroup',
    defaultMessage: 'Ce professionnel n’accepte pas de rencontrer des groupes',
  },
  suggestedProfiles: {
    id: 'NewConversation.suggestedProfiles',
    defaultMessage: 'Profils suggérés',
  },
});

const MESSAGE_BLOCKS = [
  TRANSLATIONS.messageBuilderLabel1,
  TRANSLATIONS.messageBuilderLabel2,
  TRANSLATIONS.messageBuilderLabel3,
  TRANSLATIONS.messageBuilderLabel4,
];

export const MessageCreationSection: React.FC<StepProps> = memo(
  ({ fromWidget, interlocutor, cantContactDueToGroupPreference, formik, nextStep }) => {
    const intl = useIntl();
    const { currentUser } = useContext(CurrentUserContext);

    const [currentEditingBlockIndex, setCurrentEditingBlockIndex] = useState(0);

    const canCreateGroupConversation = useMemo(
      () =>
        currentUser?.availableConversationTypes.some((type) =>
          [ConversationTypeKeyEnum.ClassGroup, ConversationTypeKeyEnum.ProgramGroup].includes(type.key),
        ) || false,
      [currentUser?.availableConversationTypes],
    );

    const situationRelativeToConversationType = useMemo(() => {
      const groupSituations = [
        PrimarySituationEnum.JobSeekerSupport,
        PrimarySituationEnum.PostBacStudentSupport,
        PrimarySituationEnum.ProfessionalAndSecondarySchoolStudentSupport,
      ];
      if (
        groupSituations.includes(currentUser?.primarySituation?.key || PrimarySituationEnum.Other) &&
        formik.values.group?.appointmentType === ConversationTypeKeyEnum.Individual
      )
        return PrimarySituationEnum.Other;
      return currentUser?.primarySituation?.key;
    }, [currentUser?.primarySituation?.key, formik.values.group?.appointmentType]);

    const questions = useMemo(
      () =>
        getRelativeSample(
          getSampleQuestions(intl, currentUser?.firstName || '', interlocutor.firstName || ''),
          situationRelativeToConversationType || PrimarySituationEnum.Other,
        ),
      [interlocutor.firstName, currentUser?.firstName, situationRelativeToConversationType],
    );

    const examples = useMemo(
      () =>
        getRelativeSample(
          getSampleExamples(intl, currentUser?.firstName || '', interlocutor.firstName || ''),
          situationRelativeToConversationType || PrimarySituationEnum.Other,
        ),
      [interlocutor.firstName, currentUser?.firstName, situationRelativeToConversationType],
    );

    const handleSubmit = useCallback(
      (value: string) => {
        const msgParts = formik.values.messageParts;
        msgParts[currentEditingBlockIndex] = value;
        formik.setFieldValue(`messageParts`, msgParts);

        setCurrentEditingBlockIndex((i) => {
          const newI = i + 1;
          if (newI >= MESSAGE_BLOCKS.length) {
            formik.setFieldValue('message', buildMessage(formik.values.messageParts, interlocutor.firstName));
            if (!fromWidget) nextStep();
          }
          return newI;
        });
      },
      [currentEditingBlockIndex, formik.values.messageParts, MESSAGE_BLOCKS],
    );

    if (canCreateGroupConversation)
      return (
        <GroupMessageCreation
          fromWidget={fromWidget}
          formik={formik}
          cantContactDueToGroupPreference={cantContactDueToGroupPreference}
          questions={questions}
          examples={examples}
          currentEditingBlockIndex={currentEditingBlockIndex}
          onExpandClick={setCurrentEditingBlockIndex}
          onSubmit={handleSubmit}
        />
      );
    else
      return (
        <MemberMessageCreation
          fromWidget={fromWidget}
          formik={formik}
          questions={questions}
          examples={examples}
          currentEditingBlockIndex={currentEditingBlockIndex}
          onExpandClick={setCurrentEditingBlockIndex}
          onSubmit={handleSubmit}
        />
      );
  },
);

type MemberMessageCreationProps = {
  formik: FormikProps<FormikValues>;
  questions: string[];
  examples: string[];
  currentEditingBlockIndex: number;
  fromWidget?: boolean;
  onExpandClick: (index: number) => void;
  onSubmit: (message: string) => void;
};

const MemberMessageCreation: React.FC<MemberMessageCreationProps> = memo(
  ({ fromWidget, formik, questions, examples, currentEditingBlockIndex, onExpandClick, onSubmit }) => {
    const { formatMessage } = useIntl();

    return (
      <Card
        title={formatMessage(TRANSLATIONS.messageCreationCardTitle)}
        header={fromWidget ? SigninZone : undefined}
      >
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
          {MESSAGE_BLOCKS.map((label, index) => (
            <ExpandableCompletionBlock
              key={label.id}
              label={formatMessage(label)}
              checked={index < currentEditingBlockIndex}
              expanded={index === currentEditingBlockIndex}
              disabled={index > currentEditingBlockIndex}
              initialValue={formik.values.messageParts[index]}
              example={examples[index]}
              onExpandClick={() => onExpandClick(index)}
              onSubmit={onSubmit}
            />
          ))}
        </div>
      </Card>
    );
  },
);

const GROUP_TRANSLATIONS = defineMessages({
  appointmentType: {
    id: 'NewConversation.appointmentType',
    defaultMessage: 'Sélectionnez le type de rendez-vous souhaité',
  },
  collectiveAppointment: {
    id: 'NewConversation.collectiveAppointment',
    defaultMessage: 'RDV collectif, avec {isSchoolTeacher, select, true {ma classe} other {mon groupe}}',
  },
  individualAppointment: {
    id: 'NewConversation.individualAppointment',
    defaultMessage: 'RDV individuel, à titre personnel',
  },
  mobileCollectiveAppointment: {
    id: 'NewConversation.mobileCollectiveAppointment',
    defaultMessage: 'RDV collectif',
  },
  mobileIndividualAppointment: {
    id: 'NewConversation.mobileIndividualAppointment',
    defaultMessage: 'RDV individuel',
  },
  participants: {
    id: 'NewConversation.participants',
    defaultMessage: 'Participants',
  },
  girlCount: {
    id: 'NewConversation.girlCount',
    defaultMessage: 'Nombre de {isSchoolTeacher, select, true {filles} other {femmes}}',
  },
  boyCount: {
    id: 'NewConversation.boyCount',
    defaultMessage: 'Nombre de {isSchoolTeacher, select, true {garçons} other {hommes}}',
  },
  level: {
    id: 'NewConversation.level',
    defaultMessage: '{isSchoolTeacher, select, true {Niveaux concernés} other {Programmes}} par la rencontre',
  },
});

type GroupMessageCreationProps = MemberMessageCreationProps & {
  formik: FormikProps<FormikValues>;
  cantContactDueToGroupPreference: boolean;
};

const GroupMessageCreation: React.FC<GroupMessageCreationProps> = memo(
  ({
    formik,
    questions,
    examples,
    cantContactDueToGroupPreference,
    currentEditingBlockIndex,
    onExpandClick,
    onSubmit,
  }) => {
    const { currentUser } = useContext(CurrentUserContext);
    const isMobile = useIsMobile();
    const { formatMessage } = useIntl();

    const [animationOverflow, setAnimationOverflow] = useState('hidden');

    const isSchoolTeacher = useMemo(
      () => currentUser?.availableConversationTypes.some((type) => type.key === ConversationTypeKeyEnum.ClassGroup),
      [currentUser?.availableConversationTypes],
    );
    const group = useMemo(
      () => (isSchoolTeacher ? ConversationTypeKeyEnum.ClassGroup : ConversationTypeKeyEnum.ProgramGroup),
      [isSchoolTeacher],
    );

    useEffect(() => {
      if (!formik.values.group) {
        formik.setFieldValue('group', {
          appointmentType: group,
        });
      }
    }, [formik]);

    return (
      <Card title={formatMessage(GROUP_TRANSLATIONS.appointmentType)}>
        <SwitchSelector
          value={formik.values.group?.appointmentType || group}
          values={[
            {
              value: group,
              translation: formatMessage(
                isMobile ? GROUP_TRANSLATIONS.mobileCollectiveAppointment : GROUP_TRANSLATIONS.collectiveAppointment,
                { isSchoolTeacher },
              ),
            },
            {
              value: ConversationTypeKeyEnum.Individual,
              translation: formatMessage(
                isMobile ? GROUP_TRANSLATIONS.mobileIndividualAppointment : GROUP_TRANSLATIONS.individualAppointment,
              ),
            },
          ]}
          onSwitchChange={(v) => formik.setFieldValue('group.appointmentType', v)}
        />

        {formik.values.group?.appointmentType === group && cantContactDueToGroupPreference ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                margin: '64px 0',
                padding: 24,
                textAlign: 'center',
                fontSize: 12,
                color: '#C5254C',
                backgroundColor: '#FFE9E9',
                borderRadius: 4,
              }}
            >
              {formatMessage(TRANSLATIONS.notAcceptGroup)}
            </div>
            <h3 className="mjg-card__title">{formatMessage(TRANSLATIONS.suggestedProfiles)}</h3>
            <SimilarProsCarousel
              interlocutorUserId={formik.values.interlocutorId}
              acceptConversationTypes={[group]}
            />
          </div>
        ) : (
          <>
            <AnimatePresence>
              {formik.values.group?.appointmentType === group && (
                <motion.div
                  style={{ display: 'flex', flexDirection: 'column', rowGap: 16, overflow: animationOverflow }}
                  initial={{ marginTop: 0, marginBottom: 0, height: 0 }}
                  animate={{ marginTop: 16, marginBottom: 16, height: 'auto' }}
                  exit={{ marginTop: 0, marginBottom: 0, height: 0 }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: isMobile ? 'column' : 'row',
                      justifyContent: 'space-between',
                      columnGap: 16,
                      rowGap: 16,
                    }}
                  >
                    <TextInput
                      name="group.girlCount"
                      label={formatMessage(GROUP_TRANSLATIONS.girlCount, { isSchoolTeacher })}
                      type="number"
                      value={formik.values.group.girlCount}
                      onChange={formik.handleChange}
                      style={{ minWidth: 'inherit' }}
                    />
                    <TextInput
                      name="group.boyCount"
                      label={formatMessage(GROUP_TRANSLATIONS.boyCount, { isSchoolTeacher })}
                      type="number"
                      value={formik.values.group.boyCount}
                      onChange={formik.handleChange}
                      style={{ minWidth: 'inherit' }}
                    />
                    <TextInput
                      label="Total"
                      disabled
                      type="number"
                      value={(formik.values.group.girlCount || 0) + (formik.values.group.boyCount || 0)}
                      style={{ minWidth: 'inherit' }}
                    />
                  </div>

                  {isSchoolTeacher ? (
                    <StaticAutocompleteMultiSelectInput
                      name="group.accompaniedStudyLevels"
                      label={formatMessage(GROUP_TRANSLATIONS.level, { isSchoolTeacher })}
                      values={
                        currentUser?.accompaniedStudyLevels?.map(({ key, name }) => ({
                          value: key,
                          translation: name,
                        })) || []
                      }
                      selectedValues={formik.values.group.accompaniedStudyLevels || []}
                      onChange={(v) => {
                        if ((formik.values.group?.accompaniedStudyLevels || []).includes(v))
                          formik.setFieldValue(
                            'group.accompaniedStudyLevels',
                            formik.values.group!.accompaniedStudyLevels!.filter((value) => value !== v),
                          );
                        else
                          formik.setFieldValue('group.accompaniedStudyLevels', [
                            ...(formik.values.group?.accompaniedStudyLevels || []),
                            v,
                          ]);
                      }}
                      onFocus={() => setAnimationOverflow('inherit')}
                      onBlur={() => setAnimationOverflow('hidden')}
                      sort
                    />
                  ) : (
                    <StaticAutocompleteMultiSelectInput
                      name="group.supportPrograms"
                      label={formatMessage(GROUP_TRANSLATIONS.level, { isSchoolTeacher })}
                      values={
                        currentUser?.jobSeekerSupportPrograms?.map((program) => ({
                          value: program,
                          translation: JobSeekerSupportProgramKeyEnumTranslations[program]
                            ? formatMessage(JobSeekerSupportProgramKeyEnumTranslations[program])
                            : program,
                        })) || []
                      }
                      selectedValues={formik.values.group?.supportPrograms || []}
                      onChange={(v) => {
                        if ((formik.values.group?.supportPrograms || []).includes(v))
                          formik.setFieldValue(
                            'group.supportPrograms',
                            formik.values.group!.supportPrograms!.filter((value) => value !== v),
                          );
                        else
                          formik.setFieldValue('group.supportPrograms', [
                            ...(formik.values.group?.supportPrograms || []),
                            v,
                          ]);
                      }}
                      onFocus={() => setAnimationOverflow('inherit')}
                      onBlur={() => setAnimationOverflow('hidden')}
                      sort
                    />
                  )}
                </motion.div>
              )}
            </AnimatePresence>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
              <p style={{ marginTop: 32, marginBottom: 0, fontSize: 16, fontWeight: 500 }}>
                {formatMessage(TRANSLATIONS.messageCreationCardTitle)}
              </p>
              {MESSAGE_BLOCKS.map((label, index) => (
                <ExpandableCompletionBlock
                  key={label.id}
                  label={questions[index]}
                  checked={index < currentEditingBlockIndex}
                  expanded={index === currentEditingBlockIndex}
                  disabled={index > currentEditingBlockIndex}
                  initialValue={formik.values.messageParts[index]}
                  example={examples[index]}
                  onExpandClick={() => onExpandClick(index)}
                  onSubmit={onSubmit}
                />
              ))}
            </div>
          </>
        )}
      </Card>
    );
  },
);

function getRelativeSample(examples: any, primarySituation: PrimarySituationEnum): string[] {
  let presentation;
  let pitch;
  let explanation;
  let valediction;

  switch (primarySituation) {
    case PrimarySituationEnum.JobSeekerSupport:
      presentation = examples.presentationJobSeekerSupport;
      pitch = examples.pitchJobSeekerSupport;
      explanation = examples.explanationJobSeekerSupport;
      valediction = examples.valedictionJobSeekerSupport;
      break;
    case PrimarySituationEnum.ProfessionalAndSecondarySchoolStudentSupport:
      presentation = examples.presentationSchoolStudentSupport;
      pitch = examples.pitchSchoolStudentSupport;
      explanation = examples.explanationSchoolStudentSupport;
      valediction = examples.valedictionSchoolStudentSupport;
      break;
    default:
      presentation = examples.presentation;
      pitch = examples.pitch;
      explanation = examples.explanation;
      valediction = examples.valediction;
  }
  return [presentation, pitch, explanation, valediction];
}

function buildMessage(messages: string[], interlocutorFirstName?: string | null) {
  return [`Bonjour ${interlocutorFirstName},`, ...messages].map((str) => str.trim()).join('\n'.repeat(2));
}
