import React, { memo } from 'react';
import { PanelProps } from './Panel';
import { Layout } from './components/layout/Layout';
import StarRadioInput from '../../../../components/star-radio-input/StarRadioInput';
import { useReviewContext } from '../ReviewContext';
import { getQuestion } from '.';

export const FirstQuestionPanel: React.FC<PanelProps> = memo(({ appointment }) => {
  const { reviewFormik } = useReviewContext();

  return (
    <Layout title={getQuestion(appointment, 1)} alignment='center' style={{ paddingTop: 150, rowGap: 42 }}>
      <StarRadioInput value={reviewFormik.values.question1?.toString()} onSelection={(value) => reviewFormik.setFieldValue('question1', parseInt(value))} big />
    </Layout>
  );
});