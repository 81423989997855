import { gql } from '@apollo/client';

export const GET_CONVERSATION_MESSAGES = gql`
  query GET_CONVERSATION_MESSAGES($conversationId: ID!) {
    conversation(conversationId: $conversationId) {
      messages {
        id
        text
        createdAt
        seenAt
        user {
          id
          firstName
          lastName
          avatar {
            thumb {
              url
            }
          }
        }
        attachment {
          name
          url
          scannedByAntivirus
        }
      }
    }
  }
`;
