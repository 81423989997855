import { defineMessages } from 'react-intl';

export default defineMessages({
  mainText: {
    id: 'NoAppointment.mainText',
    defaultMessage: "Vous n'avez pas de rendez-vous",
  },
  subText: {
    id: 'NoAppointment.subText',
    defaultMessage: 'Pour le moment ...',
  },
  button: {
    id: 'NoAppointment.button',
    defaultMessage: 'Voir les profils',
  },
});
