import { defineMessages } from 'react-intl';

export default defineMessages({
  localTime: {
    id: 'ContactDatetime.localTime',
    defaultMessage: 'Heure locale',
    description: "Valeur par défault lorsque l'on vide la valeur de l'heure d'un RDV",
  },
  validate: {
    id: 'ContactDatetime.validate',
    defaultMessage: 'Valider',
  },
});
