import React from 'react';
import nl2br from 'react-nl2br';
import { Company } from '../../../../../../../@types/graphql.d';

import styles from './CompanyPresentationRead.module.scss';

interface CompanyPresentationReadProps {
  company?: Pick<Company, 'description'> | null;
}
function CompanyPresentationRead({ company }: CompanyPresentationReadProps) {
  return <p className={styles.companyPresentationRead}>{nl2br(company?.description)}</p>;
}

export default CompanyPresentationRead;
