import React from 'react';

import moment from 'moment';
import { useQuery } from '@apollo/client';
import { StudyLevelTranslations } from '../../../../../i18n/study-level.translations';
import GraduateHatIcon from '../../../../../icons/component-icons/GraduateHatIcon';
import {
  AmbassadorFurtherInformationsQuery,
  AmbassadorFurtherInformationsQueryVariables,
} from '../../../../../@types/graphql';
import Card, { CardLoader } from '../../../../../components/card/Card';
import { FURTHER_INFORMATIONS_QUERY } from './FurtherInformations.gql';
import './FurtherInformations.scss';
import { AmbassadorViewFurtherInformationsTranslations } from './FurtherInformations.translations';
import MessageDotsSquareIcon from '../../../../../icons/component-icons/MessageDotsSquareIcon';
import CertificateIcon from '../../../../../icons/component-icons/CertificateIcon';
import CheckSquareBrokenIcon from '../../../../../icons/component-icons/CheckSquareBrokenIcon';
import { LanguagesTranslations } from '../../../../../i18n/languages.translations';
import Skeleton from 'react-loading-skeleton';
import { useIntl } from 'react-intl';

type FurtherInformationsProps = {
  id: string;
};

export default function FurtherInformations({ id }: FurtherInformationsProps) {
  const intl = useIntl();
  const { data, loading } = useQuery<AmbassadorFurtherInformationsQuery, AmbassadorFurtherInformationsQueryVariables>(
    FURTHER_INFORMATIONS_QUERY,
    {
      variables: { id },
      fetchPolicy: 'network-only',
    },
  );

  if (loading) return <FurtherInformationsLoader />;
  if (!data?.getUser) return null;

  const { educations, languages, mandatorySkills, studyLevels } = data.getUser;

  return (
    <section className="ambassador-view-further-informations">
      {educations.length > 0 && (
        <Card
          icon={GraduateHatIcon}
          title={intl.formatMessage(AmbassadorViewFurtherInformationsTranslations.studiesTitle)}
          className="ambassador-view-further-informations__educations"
          highlight
        >
          {educations.map((education, key) => (
            <div
              key={education.diplomaTitle || key}
              className="ambassador-view-further-informations__educations__education"
            >
              <div className="ambassador-view-further-informations__educations__education__title">
                {education.diplomaTitle}
              </div>
              <div className="ambassador-view-further-informations__educations__education__school-name">
                {education.schoolName}
              </div>
              {education.endedAt && (
                <div className="ambassador-view-further-informations__educations__education__date">
                  {moment(education.endedAt).format('YYYY')}
                </div>
              )}
            </div>
          ))}
        </Card>
      )}

      {languages.length > 0 && (
        <Card
          icon={MessageDotsSquareIcon}
          title={intl.formatMessage(AmbassadorViewFurtherInformationsTranslations.languagesTitle)}
        >
          <div className="ambassador-view-further-informations__tags">
            {languages.map((language) => (
              <div
                key={language}
                className="ambassador-view-further-informations__tags__tag"
              >
                {intl.formatMessage(LanguagesTranslations[language]).toLowerCase()}
              </div>
            ))}
          </div>
        </Card>
      )}

      {studyLevels.length > 0 && (
        <Card
          icon={CertificateIcon}
          title={intl.formatMessage(AmbassadorViewFurtherInformationsTranslations.recommendedQualifications)}
        >
          <div className="ambassador-view-further-informations__tags">
            {studyLevels.map((studyLevel) => (
              <div
                key={studyLevel}
                className="ambassador-view-further-informations__tags__tag"
              >
                {intl.formatMessage(StudyLevelTranslations[studyLevel])}
              </div>
            ))}
          </div>
        </Card>
      )}

      {mandatorySkills.length > 0 && (
        <Card
          icon={CheckSquareBrokenIcon}
          title={intl.formatMessage(AmbassadorViewFurtherInformationsTranslations.recommendedSkills)}
        >
          <div className="ambassador-view-further-informations__tags">
            {mandatorySkills.map((mandatorySkill) => (
              <div
                key={mandatorySkill}
                className="ambassador-view-further-informations__tags__tag"
              >
                {mandatorySkill}
              </div>
            ))}
          </div>
        </Card>
      )}
    </section>
  );
}

const FurtherInformationsLoader = () => (
  <section className="ambassador-view-further-informations">
    <CardLoader className="ambassador-view-further-informations__educations">
      <Skeleton
        count={4}
        width="50%"
      />
      <Skeleton
        count={3}
        width="30%"
      />
    </CardLoader>

    <CardLoader>
      <div className="ambassador-view-further-informations__tags">
        <Skeleton
          width={64}
          height={32}
          borderRadius={8}
        />
        <Skeleton
          width={64}
          height={32}
          borderRadius={8}
        />
        <Skeleton
          width={64}
          height={32}
          borderRadius={8}
        />
      </div>
    </CardLoader>

    <CardLoader>
      <div className="ambassador-view-further-informations__tags">
        <Skeleton
          width={64}
          height={32}
          borderRadius={8}
        />
        <Skeleton
          width={64}
          height={32}
          borderRadius={8}
        />
      </div>
    </CardLoader>

    <CardLoader>
      <div className="ambassador-view-further-informations__tags">
        <Skeleton
          width={64}
          height={32}
          borderRadius={8}
        />
        <Skeleton
          width={64}
          height={32}
          borderRadius={8}
        />
      </div>
    </CardLoader>
  </section>
);
