import TextInput from '../../../../../components/text-input/TextInput';
import ChevronLeftIcon from '../../../../../icons/component-icons/ChevronLeftIcon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { FilterButtonTranslations } from '../../../../../scenes/search/filter-button/FilterButton.translations';
import { SearchFilter } from '../../../../../scenes/search/Search.model';
import { FacetTypeTranslations } from '../../../../../scenes/search/search.translations';
import { FacetKeyEnum } from '../../../../../@types/graphql.d';
import './LocationFiltersDropdownPanel.scss';
import { useIntl } from 'react-intl';

const GOOGLE_API_KEY = import.meta.env.REACT_APP_GOOGLE_API_BROWSER_KEY || '';

type PlacePrediction = {
  description: string;
  place_id: string;
};

type GetPlacePrediction = ({ input }: { input: string }) => void;

type LocationFiltersDropdownPanelProps = {
  filters?: SearchFilter;
  onFilterChange: (filter: SearchFilter) => void;
  onClose: () => void;
};

export default function LocationFiltersDropdownPanel({
  filters: parentFilters,
  onFilterChange,
  onClose,
}: LocationFiltersDropdownPanelProps) {
  const intl = useIntl();
  const [filters, setFilters] = useState<string[]>(parentFilters?.buckets || []);
  const defaultTerm = useMemo(() => {
    if (filters.length !== 1) return '';
    const { description } = JSON.parse(filters[0]);
    return description;
  }, [filters]);

  const facetType = FacetKeyEnum.Location;
  const [term, setTerm] = useState(defaultTerm);
  const [googlePlaceId, setGooglePlaceId] = useState<string>();

  const bannedResults = ['La Réunion, France'];
  const {
    placePredictions,
    getPlacePredictions,
  }: {
    placePredictions: PlacePrediction[];
    getPlacePredictions: GetPlacePrediction;
  } = usePlacesService({
    apiKey: GOOGLE_API_KEY,
    options: {
      types: ['locality', 'country', 'administrative_area_level_1', 'administrative_area_level_2'],
    },
  });

  useEffect(() => {
    if (term) getPlacePredictions({ input: term });
  }, [term]);

  useEffect(() => {
    setFilters(parentFilters?.buckets || []);
  }, [parentFilters?.buckets]);

  const onApply = useCallback(() => {
    onFilterChange({
      facetType,
      buckets: [
        JSON.stringify({
          description: term,
          placeId: googlePlaceId,
        }),
      ],
    });
    onClose();
  }, [filters]);

  const onClear = useCallback(() => {
    onFilterChange({ facetType, buckets: [] });
  }, [filters]);

  return (
    <div className="location-filters-dropdown-panel">
      <div className="location-filters-dropdown-panel__header">
        <button
          type="button"
          className="location-filters-dropdown-panel__header__title"
          onClick={onClose}
        >
          <ChevronLeftIcon className="location-filters-dropdown-panel__header__title__icon" />
          {intl.formatMessage(FacetTypeTranslations[facetType])}
        </button>
        <TextInput
          value={term}
          onChange={(e) => setTerm(e.target.value)}
          className="filter-button__dropdown__search__input"
          placeholder={intl.formatMessage(FilterButtonTranslations.search, {
            facet: intl.formatMessage(FacetTypeTranslations[facetType]).toLowerCase(),
          })}
        />

        {/* tmp deleted for now */}
        {/* <CheckboxInput
          name="largeZone"
          label="Étendre la zone de recherche"
          checked={largeZone}
          onChange={(e) => setLargeZone(e.target.checked)}
        /> */}
      </div>

      <div className="location-filters-dropdown-panel__items">
        {placePredictions
          .filter(({ description }) => bannedResults.indexOf(description) == -1)
          .map(({ description, place_id }) => (
            <div
              key={description}
              className="location-filter-button__dropdown__items__item"
            >
              <button
                type="button"
                className="location-filter-button__dropdown__items__item"
                onClick={async () => {
                  setTerm(description);
                  setGooglePlaceId(place_id);
                }}
              >
                {description}
              </button>
            </div>
          ))}
      </div>

      <div className="location-filters-dropdown-panel__buttons">
        <button
          type="button"
          className="filter-button__dropdown__buttons__clear"
          onClick={onClear}
        >
          {intl.formatMessage(FilterButtonTranslations.clear)}
        </button>
        <button
          type="button"
          className="filter-button__dropdown__buttons__apply"
          onClick={onApply}
        >
          {intl.formatMessage(FilterButtonTranslations.apply)}
        </button>
      </div>
    </div>
  );
}
