import { FallbackAvatar } from '@myjobglasses/dsm';
import CheckboxInput from '../../../../../../components/checkbox-input/CheckboxInput';
import React, { useContext, useEffect, useState } from 'react';
import { Employee } from '../EmployeeList.gql';

import classNames from 'classnames';
import { CurrentUserContext } from '../../../../../../contexts/current-user-context/CurrentUserContext';
import EyeIcon from '../../../../../../icons/component-icons/EyeIcon';
import PencilIcon from '../../../../../../icons/component-icons/PencilIcon';
import moment from 'moment';
import { generatePath, Link } from 'react-router';
import Routes from '../../../../../../routes';
import CompanyContext from '../../../../../../scenes/company-admin/ambassadors/context/CompanyContext';
import EmployeeStatusDisplayTranslations from '../../employee-status-display/EmployeeStatusDisplay.translations';
import { EmployeeListTranslations } from '../EmployeeList.translations';
import './EmployeeRow.scss';
import { useIntl } from 'react-intl';

type EmployeeRowProps = {
  employee: Employee;
  isSelected: boolean;
  onSelect: () => void;
  onEditionClick: () => void;
};

const ACCOUNT_EDIT_PROFILE_URL = `${import.meta.env.REACT_APP_ACCOUNT_FRONTEND_URL}/company-admin/profile/`;

export default function EmployeeRow({ employee, isSelected, onSelect, onEditionClick }: EmployeeRowProps) {
  const intl = useIntl();
  const { currentUser } = useContext(CurrentUserContext);
  const companyContext = useContext(CompanyContext);

  const [isEdited, setEdited] = useState(false);

  useEffect(() => {
    if (isEdited) onEditionClick();
  }, [isEdited]);

  return (
    <tr
      key={employee.id}
      className={classNames('employee-row', {
        'employee-row--edited': isEdited,
      })}
    >
      <td>
        <div className="employee-row__cell employee-row__cell-select">
          <CheckboxInput
            name={`select-${employee.id}`}
            checked={isSelected}
            onChange={onSelect}
          />
        </div>
      </td>
      <AvatarCell employee={employee} />
      <EmployeeDetailsCell employee={employee} />
      <StatusCell employee={employee} />
      <CompanyAttribute attribute={employee.companyAttribute1} />
      <CompanyAttribute attribute={employee.companyAttribute2} />
      <CompanyAttribute attribute={employee.companyAttribute3} />
      <td>
        <div className="employee-row__cell employee-row__cell-actions">
          <Link
            to={generatePath(
              currentUser?.isMjgAdmin ? Routes.mjgAdminCompaniesShowEmployee : Routes.companyAdminShowAmbassador,
              { companyid: companyContext.companyid ?? '', employeeid: employee.id },
            )}
            data-tooltip={intl.formatMessage(EmployeeListTranslations.seeUser)}
          >
            <EyeIcon />
          </Link>
          <a
            href={`${ACCOUNT_EDIT_PROFILE_URL}${employee.id}`}
            rel="noreferrer"
            target="_blank"
            onClick={() => setEdited(true)}
            data-tooltip={intl.formatMessage(EmployeeListTranslations.modifyUser)}
          >
            <PencilIcon />
          </a>
        </div>
      </td>
    </tr>
  );
}

function AvatarCell({ employee }: Pick<EmployeeRowProps, 'employee'>) {
  return (
    <td>
      <div className="employee-row__cell employee-row__cell-avatar">
        {employee.avatar ? <img src={employee.avatar} /> : <FallbackAvatar />}
      </div>
    </td>
  );
}

function EmployeeDetailsCell({ employee }: Pick<EmployeeRowProps, 'employee'>) {
  return (
    <td>
      <div className="employee-row__cell employee-row__cell-employee-details">
        <div className="employee-row__cell-employee-details__full-name">
          {employee.firstName} {employee.lastName.toUpperCase()}
        </div>
        <div>{employee.jobTitle}</div>
        <div>{employee.email}</div>
      </div>
    </td>
  );
}

function StatusCell({ employee }: Pick<EmployeeRowProps, 'employee'>) {
  const intl = useIntl();
  return (
    <td>
      <div className="employee-row__cell employee-row__cell-status">
        {employee.visibleInSearch ? 'Visible' : 'Non visible'}
        <div
          className={classNames(
            'employee-row__cell-status__status',
            `employee-row__cell-status__status--${employee.status}`,
          )}
        >
          {intl.formatMessage(EmployeeStatusDisplayTranslations[employee.status], {
            lastInvitationEmailSentAt: moment(employee.lastInvitationEmailSentAt).format('l'),
          })}
        </div>
      </div>
    </td>
  );
}

function CompanyAttribute({ attribute }: { attribute?: string | null }) {
  return (
    <td>
      <div className="employee-row__cell employee-row__cell-company-attribute">{attribute || '-'}</div>
    </td>
  );
}
