import { defineMessages } from 'react-intl';
import { UnbookAppointmentMutationErrorEnum } from '../../../../../@types/graphql.d';

export const errorTranslation = defineMessages({});

export default defineMessages({
  title: {
    id: 'DeleteAppointmentModal.title',
    defaultMessage: 'Supprimer le rendez-vous ?',
  },
  description: {
    id: 'DeleteAppointmentModal.description',
    defaultMessage:
      'En cliquant sur "Confirmer", le rendez-vous sera annulé et les participants seront notifiés par email',
  },
  toastrDeletedAppointmentProfessionnal: {
    id: 'DeleteAppointmentModal.toastrAppointmentDeletedByProfessionnal',
    defaultMessage:
      'Votre RDV a bien été supprimé et le Membre sera averti par email. N’oubliez pas de lui envoyer un message pour lui expliquer pourquoi vous avez annulé.',
  },
  toastrDeletedAppointmentStudent: {
    id: 'DeleteAppointmentModal.toastrAppointmentDeletedByStudent',
    defaultMessage:
      'Votre RDV a bien été annulé et votre Ambassadeur sera averti par email. N’oubliez pas de lui envoyer un message pour lui dire pourquoi vous avez annulé, et proposer une autre date de rencontre.',
  },
});

export const DeleteAppointmentErrors = {
  [UnbookAppointmentMutationErrorEnum.AlreadyCancelled]: {
    id: 'BookAppointmentMutationErrorEnum.AlreadyCancelled',
    defaultMessage: 'Le rendez-vous a déjà été annulé',
  },
  [UnbookAppointmentMutationErrorEnum.CannotCancelPastAppointment]: {
    id: 'BookAppointmentMutationErrorEnum.CannotCancelPastAppointment',
    defaultMessage: 'Un rendez-vous passé ne peut être annulé',
  },
  [UnbookAppointmentMutationErrorEnum.MustBeTheConversationRecipient]: {
    id: 'BookAppointmentMutationErrorEnum.MustBeTheConversationRecipient',
    defaultMessage: "Seule la personne ayant réservé le rendez-vous peut l'annuler",
  },
  default: {
    id: 'BookAppointmentMutationErrorEnum.default',
    defaultMessage: 'Une erreur a eu lieu',
  },
};
