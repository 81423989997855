import React from 'react';
import { useQuery } from '@apollo/client';
import CalendarIcon from '../../../../../icons/component-icons/CalendarIcon';
import MessageChatCircleIcon from '../../../../../icons/component-icons/MessageChatCircleIcon';
import {
  GetCompanyAuthorizationDashboardQuery,
  GetCompanyAuthorizationDashboardQueryVariables,
  Get_Company_Activity_ReportQuery,
  Get_Company_Activity_ReportQueryVariables,
} from '../../../../../@types/graphql.d';
import useCompanyUnique from '../../../hooks/useCompanyUnique/useCompanyUnique';
import { useCurrentRange } from '../../../hooks/useCurrentRange';
import CompanyAdminDashboardCount from '../company-admin-dashboard-count/CompanyAdminDashboardCount';
import CompanyAdminDashboardConversationRate from '../company-admin-dashboard-conversation-rate/CompanyAdminDashboardConversationRate';
import CompanyAdminDashboardAppointmentRate from '../company-admin-dashboard-appointment-rate/CompanyAdminDashboardAppointmentRate';
import {
  GET_COMPANY_ACTIVITY_REPORT,
  GET_COMPANY_AUTHORIZATION_DASHBOARD_QUERY,
} from './CompanyAdminDashboardActivityBloc.gql';
import translations from './CompanyAdminDashboardActivityBloc.translations';

import styles from './CompanyAdminDashboardActivityBloc.module.scss';
import { useIntl } from 'react-intl';

function CompanyAdminDashboardActivityBloc() {
  const intl = useIntl();
  const companyId = useCompanyUnique();
  const currentRange = useCurrentRange();
  const { data } = useQuery<Get_Company_Activity_ReportQuery, Get_Company_Activity_ReportQueryVariables>(
    GET_COMPANY_ACTIVITY_REPORT,
    {
      skip: !companyId,
      variables: {
        companyId: companyId || '',
        range: currentRange,
      },
      fetchPolicy: 'network-only',
    },
  );

  const { data: canAccess } = useQuery<
    GetCompanyAuthorizationDashboardQuery,
    GetCompanyAuthorizationDashboardQueryVariables
  >(GET_COMPANY_AUTHORIZATION_DASHBOARD_QUERY, {
    skip: !companyId,
    variables: { companyId: companyId! },
  });

  return (
    <div className={styles.activityBloc}>
      <CompanyAdminDashboardCount
        data-testid="conversation-count"
        title={intl.formatMessage(translations.conversationLabel)}
        tooltip={intl.formatMessage(translations.conversationTooltip)}
        picto={MessageChatCircleIcon}
        value={data?.companyReport?.newConversationCount || '-'}
        className={styles.conversationCount}
        blurred={!canAccess?.company?.canShowPartialDashboardStats}
      />
      <CompanyAdminDashboardConversationRate
        data-testid="response-rate"
        rate={data?.companyReport?.responseRate}
        value={data?.companyReport?.contactedEmployeesCount || '-'}
        companyName={data?.companyReport?.company?.name || ''}
        companyId={data?.companyReport?.companyId || ''}
        className={styles.responseRate}
        blurred={!canAccess?.company?.canShowFullDashboardStats}
      />
      <CompanyAdminDashboardCount
        data-testid="appointments-count"
        title={intl.formatMessage(translations.appointmentLabel)}
        tooltip={intl.formatMessage(translations.appointmentTooltip)}
        picto={CalendarIcon}
        value={data?.companyReport?.appointmentCount || '-'}
        className={styles.appointmentsCount}
        blurred={!canAccess?.company?.canShowPartialDashboardStats}
      />
      <CompanyAdminDashboardAppointmentRate
        data-testid="employee-with-appointments"
        rate={data?.companyReport?.publishedEmployeesWithAppointmentRate || 0}
        value={data?.companyReport?.publishedEmployeeCount || '-'}
        companyName={data?.companyReport?.company?.name || ''}
        companyId={data?.companyReport?.companyId || ''}
        className={styles.employeeWithAppointments}
        blurred={!canAccess?.company?.canShowFullDashboardStats}
      />
    </div>
  );
}

export default CompanyAdminDashboardActivityBloc;
