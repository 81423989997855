import classNames from 'classnames';
import React, { CSSProperties, Fragment, memo } from 'react';

import './Stepper.scss';
import Card from '../card/Card';

type StepperProps = {
  currentStep: number;
  steps: string[];
  style?: CSSProperties;
};

export const Stepper: React.FC<StepperProps> = memo(
  ({ currentStep, steps, style }) => (
    <Card
      className="stepper"
      style={{ ...style, width: '100%' }}
    >
      {steps.map((label, i) => (
        <Fragment key={label}>
          <Step
            page={i + 1}
            label={label}
            previous={i < currentStep}
            current={i == currentStep}
          />
          {i < steps.length - 1 && <div className="stepper__separator" />}
        </Fragment>
      ))}
    </Card>
  ),
  (prev, next) => prev.currentStep === next.currentStep,
);

type StepProps = {
  page: number;
  label: string;
  previous: boolean;
  current: boolean;
};

const Step: React.FC<StepProps> = memo(({ page, label, previous, current }) => (
  <div
    className={classNames('stepper-step', { 'stepper-step--previous': previous }, { 'stepper-step--current': current })}
  >
    <div className="stepper-step__page">{page}</div>
    <p className="stepper-step__label">{label}</p>
  </div>
));
