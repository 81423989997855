import { defineMessages } from 'react-intl';

export default defineMessages({
  employeePack: {
    id: 'CompanyPackStatistics.employeePack',
    defaultMessage: 'Ma communauté d’Ambassadeurs',
  },
  howDoesItWork: {
    id: 'CompanyPackStatistics.howDoesItWork',
    defaultMessage: 'Comment gérer mon pack ?',
  },
  profileStatus: {
    id: 'CompanyPackStatistics.profileStatus',
    defaultMessage: 'État des profils',
  },
  label: {
    id: 'CompanyPackStatistics.label',
    defaultMessage: 'Libellé n°{n}',
  },
  noData: {
    id: 'CompanyPackStatistics.noData',
    defaultMessage: 'Données indisponibles',
  },
});
