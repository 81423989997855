import React from 'react';
import { DonutChart } from '@myjobglasses/dsm';
import InfoCircleIcon from '../../../../../icons/component-icons/InfoCircleIcon';
import classnames from 'classnames';
import useCompanyAttributeSegment from './hooks/useCompanyAttributeSegment/useCompanyAttributeSegment';
import useStatusSegments from './hooks/useStatusSegments/useStatusSegments';
import translations from './CompanyPackStatistics.translations';

import styles from './CompanyPackStatistics.module.scss';
import { useIntl } from 'react-intl';

interface CompanyPackStatisticsProps {
  className?: string;
  isPremiumSchool: boolean;
}
function CompanyPackStatistics({ className, isPremiumSchool }: CompanyPackStatisticsProps): JSX.Element {
  const intl = useIntl();
  const statusSegments = useStatusSegments();
  const companyAttribute1Segments = useCompanyAttributeSegment(1);
  const companyAttribute2Segments = useCompanyAttributeSegment(2);
  const companyAttribute3Segments = useCompanyAttributeSegment(3);

  return (
    <div className={classnames(className, styles.companyPackStatistics)}>
      <div className={styles.header}>
        <p className={styles.title}>{intl.formatMessage(translations.employeePack)}</p>
        {
          //temporary remove this cta
          false && (
            <a
              // eslint-disable-next-line
              href={
                // prettier-ignore
                import.meta.env.REACT_APP_COMPANY_ADMIN_PACK_HOW_DOES_IT_WORK || ''
              }
              target="_blank"
              rel="noopener noreferrer"
              className={styles.howDoesItWork}
            >
              <InfoCircleIcon className={styles.icon} />
              {intl.formatMessage(translations.howDoesItWork)}
            </a>
          )
        }
      </div>
      <div className={styles.charts}>
        <div
          data-testid="donut-status"
          className={classnames(styles.donutWrapper, {
            [styles.noData]: !statusSegments.length,
          })}
        >
          <DonutChart
            segments={Object.values(statusSegments || {})}
            className={styles.donut}
          />
          {!statusSegments.length && <p className={styles.donutNoData}>{intl.formatMessage(translations.noData)}</p>}
          <p className={styles.donutTitle}>{intl.formatMessage(translations.profileStatus)}</p>
        </div>
        <div
          data-testid="company-attribute-1"
          className={classnames(styles.donutWrapper, {
            [styles.noData]: !companyAttribute1Segments?.segments?.length,
          })}
        >
          <DonutChart
            segments={companyAttribute1Segments?.segments || {}}
            className={styles.donut}
          />
          {!companyAttribute1Segments?.segments?.length && (
            <p className={styles.donutNoData}>{intl.formatMessage(translations.noData)}</p>
          )}
          <p className={styles.donutTitle}>
            {companyAttribute1Segments?.name || intl.formatMessage(translations.label, { n: 1 })}
          </p>
        </div>
        <div
          data-testid="company-attribute-2"
          className={classnames(styles.donutWrapper, {
            [styles.noData]: !companyAttribute2Segments?.segments?.length,
          })}
        >
          <DonutChart
            segments={companyAttribute2Segments?.segments || {}}
            className={styles.donut}
          />
          {!companyAttribute2Segments?.segments?.length && (
            <p className={styles.donutNoData}>{intl.formatMessage(translations.noData)}</p>
          )}
          <p className={styles.donutTitle}>
            {companyAttribute2Segments?.name || intl.formatMessage(translations.label, { n: 2 })}
          </p>
        </div>
        <div
          data-testid="company-attribute-3"
          className={classnames(styles.donutWrapper, {
            [styles.noData]: !companyAttribute3Segments?.segments?.length,
          })}
        >
          <DonutChart
            segments={companyAttribute3Segments?.segments || {}}
            className={styles.donut}
          />
          {!companyAttribute3Segments?.segments?.length && (
            <p className={styles.donutNoData}>{intl.formatMessage(translations.noData)}</p>
          )}
          <p className={styles.donutTitle}>
            {companyAttribute3Segments?.name || intl.formatMessage(translations.label, { n: 3 })}
          </p>
        </div>
      </div>
    </div>
  );
}

export default CompanyPackStatistics;
