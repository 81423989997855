import { gql } from '@apollo/client';

export const COMPANY_STYLING_QUERY = gql`
  query CompanyStyling($id: ID!) {
    company(companyId: $id) {
      name
      companyLogo
      cardBanner {
        url
      }
      desktopBanner {
        url
      }
      mobileBanner {
        url
      }
      canCustomBanners
    }
  }
`;

export const UPDATE_COMPANY_BANNERS_MUTATION = gql`
  mutation UpdateCompanyBanners(
    $companyId: ID!
    $backgroundCardUploadId: ID
    $profileBannerDesktopUploadId: ID
    $profileBannerMobileUploadId: ID
  ) {
    updateCompany(
      companyId: $companyId
      backgroundCardUploadId: $backgroundCardUploadId
      profileBannerDesktopUploadId: $profileBannerDesktopUploadId
      profileBannerMobileUploadId: $profileBannerMobileUploadId
    )
  }
`;

export const REMOVE_COMPANY_BANNER_MUTATION = gql`
  mutation RemoveCompanyBanner($companyId: ID!, $bannerType: String!) {
    removeCompanyBanner(companyId: $companyId, bannerType: $bannerType)
  }
`;