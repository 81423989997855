import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import { Navigate, generatePath, matchPath, useMatch } from 'react-router';
import { Get_ConversationQuery, Get_ConversationQueryVariables } from '../../../@types/graphql.d';
import { DownloadConversationAppointmentIcalendarParams } from '../../../@types/routes-params.d';
import { PageLoader } from '../../../components/loader/Loader';
import Routes from '../../../routes';
import NotFound from '../../../containers/not-found/NotFound';
import BackToConversationsListButton from './components/back-to-conversations-list-button/BackToConversationsListButton';
import ConversationThread from './components/conversation-thread/ConversationThread';
import InterlocutorPanel from './components/interlocutor-panel/InterlocutorPanel';
import SendMessage from './components/send-message/SendMessage';
import MisconductReportModal from './components/report-student-behavior-modal/MisconductReportModal';
import { ConversationViewContext } from './context/ConversationViewContext';

import useConversationIdFromRoute from './hooks/useConversationIdFromRoute';
import { GET_CONVERSATION } from './ConversationView.gql';

import './ConversationView.scss';
import InterlocutorMobileOverview from './components/interlocutor-mobile-overview/InterlocutorMobileOverview';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { breakpoints } from '../../../variables';

export default function ConversationView() {
  const conversationId = useConversationIdFromRoute();

  const { data, loading } = useQuery<Get_ConversationQuery, Get_ConversationQueryVariables>(GET_CONVERSATION, {
    skip: !conversationId,
    variables: { conversationId: conversationId || '' },
    fetchPolicy: 'network-only',
  });

  const conversation = data?.conversation;

  const [isMobileOpened, setIsMobileOpened] = useState(false);

  const { width } = useWindowSize();

  const match = useMatch(Routes.downloadConversationAppointmentIcalendar);
  if (match) {
    return <Navigate to={generatePath(Routes.conversation, { id: match.params.id || '' })} />;
  }
  if (loading || !conversationId) {
    return <PageLoader />;
  }
  if (!conversation) {
    return <NotFound />;
  }
  return (
    <ConversationViewContext.Provider
      value={{
        openMobileInterlocutorPanel: () => setIsMobileOpened(true),
        closeMobileInterlocutorPanel: () => setIsMobileOpened(false),
        conversation: conversation,
      }}
    >
      <div className="conversation-page">
        <main className="conversation-view">
          <section className="conversation-messages">
            {width < breakpoints.sm ? <InterlocutorMobileOverview /> : <BackToConversationsListButton />}
            <ConversationThread />
            <SendMessage />
          </section>
          <div
            className={classnames('interlocutor-panel-wrapper', {
              'interlocutor-panel-wrapper-mobile-opened': isMobileOpened,
            })}
          >
            <InterlocutorPanel />
          </div>
        </main>
      </div>
    </ConversationViewContext.Provider>
  );
}
