import { gql } from '@apollo/client';

export const GET_GLOBAL_COMPANY_REPORT = gql`
  query GET_GLOBAL_COMPANY_REPORT($range: CompanyReportRange!, $companyId: ID!) {
    companyReport(range: $range, companyId: $companyId) {
      companyId
      from
      to
      lastUpdatedAt
      newConversationCount
      contactedEmployeesCount
      responseRate
      appointmentCount
      publishedEmployeeCount
      publishedEmployeesWithAppointmentRate
      employeesViewCount
      uniqueProfileViewsFromStudents
      studentsSeeingThemselvesInTheSectorRate
      unfollowedConversationsCount
      wishCount
      likeCount
      company {
        name
      }
      bestProfiles {
        id
        avatar
        firstName
        lastName
        jobTitle
        appointmentCount
        medal
      }
      isPremiumSchool
    }
  }
`;

export const GET_COMPANY_AUTHORIZATION_TOP_EMPLOYEE_QUERY = gql`
  query GetCompanyAuthorizationTopEmployee($companyId: ID!) {
    company(companyId: $companyId) {
      canShowFullDashboardStats
      canShowDashboardEmployeesRanking
      name
    }
  }
`;
