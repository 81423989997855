import * as Sentry from '@sentry/react';
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';

import styles from './DownloadAppointmentReport.module.scss';

import {
  Get_Company_Created_AtQuery,
  Get_Company_Created_AtQueryVariables,
} from '../../../../../../../@types/graphql.d';
import { useLanguage } from '../../../../../../../hooks/useLanguages';
import useCompanyUnique from '../../../../../hooks/useCompanyUnique/useCompanyUnique';
import { GET_COMPANY_CREATED_AT } from '../../CompanyAdminAppointmentsHeader.gql';
import translations from './DownloadAppointmentReport.translations';
import { errorTracker } from '../../../../../../../error-tracker/error-tracker';

function DownloadAppointmentReport() {
  const intl = useIntl();
  const { language } = useLanguage();
  const [downloadStarted, setDownloadStarted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const companyId = useCompanyUnique();
  const { data } = useQuery<Get_Company_Created_AtQuery, Get_Company_Created_AtQueryVariables>(GET_COMPANY_CREATED_AT, {
    skip: !companyId,
    variables: {
      companyId: companyId || '',
    },
    fetchPolicy: 'cache-only',
  });
  const date = useMemo(() => moment().format('DD/MM/YYYY'), []);
  const reportFileName = useMemo(
    () => `${data?.company?.name.replace(/[ '/()&,’]+/gi, '-')}_${date}_appointments_list.xlsx`,
    [data?.company, date],
  );
  const headers = new Headers({
    'X-User-Language': language,
  });
  const onDownload = useCallback(async () => {
    setDownloadStarted(true);

    return fetch(`${import.meta.env.REACT_APP_DOWNLOAD_HOST}/companies/${companyId}/appointment_list`, {
      credentials: 'include',
      headers,
    })
      .then((response) => {
        if (response.ok) {
          enqueueSnackbar(intl.formatMessage(translations.dispatchedViaEmail), {
            autoHideDuration: 5000,
            variant: 'info',
          });
          setDownloadStarted(true);
          // If you want to show the email it is sent to :
          // return response.json();
          // et then data.email or something
        } else {
          enqueueSnackbar(intl.formatMessage(translations.error), {
            autoHideDuration: 5000,
            variant: 'error',
          });
          const error = new Error(`DownloadAppointmentReport: Error when downloading ${reportFileName} by email`);
          errorTracker.sendError('DownloadAppointmentReport', `Error when downloading ${reportFileName} by email`, {
            namespace: 'Frontend',
            payload: {
              reportFileName: reportFileName,
            },
            tags: {
              frontendError: JSON.stringify(error),
            },
          });
          useEffect(() => {
            Sentry.captureException(error);
          }, [error]);
        }
      })
      .catch((fetchErr) => {
        enqueueSnackbar(intl.formatMessage(translations.error), {
          autoHideDuration: 5000,
          variant: 'error',
        });
        useEffect(() => {
          Sentry.captureException(fetchErr);
        }, [fetchErr]);
      });
  }, [reportFileName]);

  return (
    <button
      type="button"
      className={styles.downloadButton}
      onClick={onDownload}
      disabled={downloadStarted}
    >
      {intl.formatMessage(translations.exportButton)}
    </button>
  );
}

export default DownloadAppointmentReport;
