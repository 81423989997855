import React from 'react';
import classnames from 'classnames';
import BounceLoader from 'react-spinners/BounceLoader';
import { MJG_BLUE } from '../../../utils/styled-components';

import styles from './InlineLoader.module.scss';

interface InlineLoaderProps {
  className?: string;
}
function InlineLoader({ className }: InlineLoaderProps) {
  return (
    <div className={classnames(styles.container, className)}>
      <BounceLoader color={MJG_BLUE} />
    </div>
  );
}

export default InlineLoader;
