import { defineMessages } from 'react-intl';
import { EmployeeStatus } from '../../../../../@types/graphql.d';

export default defineMessages({
  visibleInSearch: {
    id: 'EmployeeStatusDisplay.visibleInSearch',
    defaultMessage: 'Visible',
    description: 'Information, visible sur le moteur de recherche sur l Admin RH',
  },
  notVisibleInSearch: {
    id: 'EmployeeStatusDisplay.notVisibleInSearch',
    defaultMessage: 'Non visible',
    description: 'Information, non-visible sur le moteur de recherche sur l Admin RH',
  },
  // statuses
  [EmployeeStatus.Invited]: {
    id: 'EmployeeStatusDisplay.Invited',
    defaultMessage: 'Invité(e) le {lastInvitationEmailSentAt}',
    description: 'Status Profil invité dans l Admin RH',
  },
  [EmployeeStatus.InvitedMultipleTimes]: {
    id: 'EmployeeStatusDisplay.InvitedMultipleTimes',
    defaultMessage: 'Relancé(e) le {lastInvitationEmailSentAt}',
    description: 'Status Profil invité dans l Admin RH',
  },
  [EmployeeStatus.IncompleteProfile]: {
    id: 'EmployeeStatusDisplay.IncompleteProfile',
    defaultMessage: 'Profil incomplet',
    description: 'Status Profil incomplet dans l Admin RH',
  },
  [EmployeeStatus.CompleteProfile]: {
    id: 'EmployeeStatusDisplay.CompleteProfile',
    defaultMessage: 'Profil complet',
    description: 'Status Profil complet dans l Admin RH',
  },
  [EmployeeStatus.Approved]: {
    id: 'EmployeeStatusDisplay.Approved',
    defaultMessage: 'Profil approuvé',
    description: 'Status Profil approuvé dans l Admin RH',
  },
  [EmployeeStatus.Active]: {
    id: 'EmployeeStatusDisplay.Active',
    defaultMessage: 'Profil actif',
    description: 'Status Profil actif dans l Admin RH',
  },
  [EmployeeStatus.AtRisk]: {
    id: 'EmployeeStatusDisplay.AtRisk',
    defaultMessage: 'Profil à risque',
    description: 'Status Profil à risque dans l Admin RH',
  },
  [EmployeeStatus.Inactive]: {
    id: 'EmployeeStatusDisplay.Inactive',
    defaultMessage: 'Profil inactif',
    description: 'Status Profil inactif dans l Admin RH',
  },
  [EmployeeStatus.Hibernated]: {
    id: 'EmployeeStatusDisplay.Hibernated',
    defaultMessage: 'Profil hiberné',
    description: 'Status Profil hiberné dans l Admin RH',
  },
  [EmployeeStatus.Unpublished]: {
    id: 'EmployeeStatusDisplay.Unpublished',
    defaultMessage: 'Profil dépublié',
    description: 'Status Profil dépublié dans l Admin RH',
  },
  [EmployeeStatus.Archived]: {
    id: 'EmployeeStatusDisplay.Archived',
    defaultMessage: 'Profil archivé',
    description: 'Status Profil archivé dans l Admin RH',
  },
  [EmployeeStatus.Anonymized]: {
    id: 'EmployeeStatusDisplay.Anonymized',
    defaultMessage: 'Utilisateur supprimé',
  },
});
