import { FacetKeyEnum } from '../../@types/graphql.d';

export type SearchFilter = {
  facetType: FacetKeyEnum;
  buckets: string[];
};

export function getSearchValueFromSearchParams(): string {
  const params = new URLSearchParams(window.location.search);
  return params.get('q') || '';
}

export function getInitialSearchValueFromSearchParams(): string {
  const params = new URLSearchParams(window.location.search);
  return params.get('q') || params.get(`filters[${FacetKeyEnum.JobTitle.toLowerCase()}]`) || '';
}

export function initFiltersBasedOnSearchParams() {
  const filters = new Map<FacetKeyEnum, SearchFilter>();
  const params = new URLSearchParams(window.location.search);
  params.forEach((bucket, facetTypeFilter) => {
    if (facetTypeFilter === 'q') return;

    const facetType = facetTypeFilter.replace('filters[', '').replace(']', '').toUpperCase() as FacetKeyEnum;
    if (filters.has(facetType)) {
      filters.get(facetType)!.buckets.push(bucket);
    } else {
      filters.set(facetType, {
        facetType: facetType,
        buckets: [bucket],
      });
    }
  });
  return filters;
}
