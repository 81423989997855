import './error-tracker/error-tracker'
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import ahoy from 'ahoy.js';

import App from './App';

import './index.css';

ahoy.configure({
  visitsUrl: `${import.meta.env.REACT_APP_HERMES_URL}/ahoy/visits` || '/ahoy/visits',
  eventsUrl: `${import.meta.env.REACT_APP_HERMES_URL}/ahoy/events` || '/ahoy/events',
  cookieDomain: import.meta.env.REACT_APP_COOKIE_DOMAIN || '',
  withCredentials: true,
});

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
