import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import CheckboxInput from '../../../components/checkbox-input/CheckboxInput';
import TextInput from '../../../components/text-input/TextInput';
import ChevronDownIcon from '../../../icons/component-icons/ChevronDownIcon';
import classNames from 'classnames';
import useInputGesture from '../../../hooks/input-gesture.hook';
import { GetFacetsQuery, GetFacetsQueryVariables, Bucket } from '../../../@types/graphql.d';
import { GET_FACETS_QUERY } from './FilterButton.gql';
import { FacetTypeTranslations } from '../search.translations';
import { FilterButtonTranslations } from './FilterButton.translations';
import { FilterButtonProps } from './FilterButton.types';

import './FilterButton.scss';
import useDebounce from '../../../hooks/debounce.hook';
import { useIntl } from 'react-intl';

export default function FilterButton({
  searchType,
  facetType,
  buckets,
  filters: parentFilters,
  onFilterChange,
  withSearch,
  sort,
}: FilterButtonProps) {
  const intl = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [term, setTerm] = useState('');
  const [debouncedTerm] = useDebounce(term);
  const [filters, setFilters] = useState<string[]>(parentFilters?.buckets || []);

  const [bucketsSearch, setBucketsSearch] = useState<Bucket[]>([]);

  const { data: facets } = useQuery<GetFacetsQuery, GetFacetsQueryVariables>(GET_FACETS_QUERY, {
    variables: {
      searchQuery: debouncedTerm,
      searchMode: searchType,
      searchFacets: [{ key: facetType, values: [] }],
    },
    fetchPolicy: 'no-cache',
    skip: !debouncedTerm,
  });

  useEffect(() => {
    setFilters(parentFilters?.buckets || []);
  }, [parentFilters?.buckets]);

  useInputGesture(ref, {
    onExit: () => setDropdownOpen(false),
  });

  useEffect(() => {
    if (!dropdownRef.current || !isDropdownOpen) return;
    if (dropdownRef.current.getBoundingClientRect().right > document.body.clientWidth) {
      dropdownRef.current.classList.toggle('filter-button__dropdown--left');
    }
  }, [isDropdownOpen, dropdownRef.current, document.body.clientWidth]);

  const addFilter = useCallback(
    (bucket: string) => {
      setFilters((prev) => [...prev, bucket]);
    },
    [filters, setFilters],
  );

  const removeFilter = useCallback(
    (bucket: string) => {
      setFilters((prev) => prev.filter((b) => b !== bucket));
    },
    [filters],
  );

  const onApply = useCallback(() => {
    onFilterChange({ facetType, buckets: filters });
    setDropdownOpen(false);
  }, [filters, isDropdownOpen]);

  const onClear = useCallback(() => {
    onFilterChange({ facetType, buckets: [] });
  }, [filters]);

  const initialBuckets = sort
    ? [...buckets].sort((a, b) => (a.label || a.key).localeCompare(b.label || b.key))
    : buckets;

  useEffect(() => {
    let searchBuckets = facets?.searchFacets.find((facet) => facet.key === facetType)?.buckets || [];
    searchBuckets = sort
      ? [...searchBuckets].sort((a, b) => (a.label || a.key).localeCompare(b.label || b.key))
      : searchBuckets;
    setBucketsSearch(searchBuckets);
  }, [facets]);

  return (
    <div
      ref={ref}
      className="filter-button"
    >
      <button
        type="button"
        className={classNames(
          'filter-button__label',
          { 'filter-button__label--active': isDropdownOpen },
          { 'filter-button__label--has-filters': filters.length > 0 },
        )}
        onClick={() => setDropdownOpen((prev) => !prev)}
      >
        {FacetTypeTranslations[facetType] ? intl.formatMessage(FacetTypeTranslations[facetType]) : facetType}
        <ChevronDownIcon />
      </button>

      {filters.length > 0 && <div className="filter-button__filters-count">{filters.length}</div>}

      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className="filter-button__dropdown"
        >
          {withSearch && (
            <header className="filter-button__dropdown__search">
              <TextInput
                value={term}
                onChange={(e) => setTerm(e.target.value)}
                className="filter-button__dropdown__search__input"
                placeholder={intl.formatMessage(FilterButtonTranslations.search, {
                  facet: intl.formatMessage(FacetTypeTranslations[facetType]).toLowerCase(),
                })}
              />
            </header>
          )}

          <div
            className={classNames('filter-button__dropdown__items', {
              'filter-button__dropdown__items--with-search': withSearch,
            })}
          >
            {(bucketsSearch?.length > 0 ? bucketsSearch : initialBuckets)
              .filter(({ label, key }) => (label || key).toLowerCase().includes(term.toLowerCase()))
              .map(({ key, label }) => (
                <div
                  key={key}
                  className="filter-button__dropdown__items__item"
                >
                  <CheckboxInput
                    name={key}
                    label={label || key}
                    checked={filters.includes(key)}
                    onChange={() => {
                      if (filters.includes(key)) {
                        removeFilter(key);
                      } else {
                        addFilter(key);
                      }
                    }}
                  />
                </div>
              ))}
          </div>

          <footer className="filter-button__dropdown__buttons">
            <button
              type="button"
              className="filter-button__dropdown__buttons__clear"
              onClick={onClear}
            >
              {intl.formatMessage(FilterButtonTranslations.clear)}
            </button>
            <button
              type="button"
              className="filter-button__dropdown__buttons__apply"
              onClick={onApply}
            >
              {intl.formatMessage(FilterButtonTranslations.apply)}
            </button>
          </footer>
        </div>
      )}
    </div>
  );
}
