import React from 'react';
import { FormattedMessage } from 'react-intl';

import './AccountRestrictedView.scss';

const AccountRestrictedView = () => (
  <main className="account-restricted-view">
    <div className="account-restricted-view-wrapper">
      <img
        src="https://assets.myjobglasses.com/images/signin/account-restricted-header.svg"
        alt="Compte suspendu illustration"
      />
      <FormattedMessage
        id="AccountRestrictedView.title"
        defaultMessage="Votre compte<br/>a été temporairement suspendu"
        description="Titre de la page compte restreint"
      >
        {(__html) => (
          <h2
            className="account-restricted-view-title"
            dangerouslySetInnerHTML={{ __html }}
          />
        )}
      </FormattedMessage>
      <FormattedMessage
        id="AccountRestrictedView.description.part1"
        defaultMessage="Votre compte nous a été signalé comme possiblement usurpant une identité. Afin d'assurer la qualité des échanges entre Membres et professionnels sur notre plateforme, et en accord avec nos CGU, nous devons procéder à une vérification d'identité. Pourriez-vous donc nous fournir une pièce d'identité pour confirmer votre identité ?"
        description="Description de la page compte restreint (part 1)"
      >
        {(txt) => <p className="account-restricted-view-description">{txt}</p>}
      </FormattedMessage>
      <FormattedMessage
        id="AccountRestrictedView.description.part2"
        defaultMessage="Une fois votre identité confirmée par nos services, la suspension de votre compte sera levée : vous pourrez à nouveau y accéder sans problème."
        description="Description de la page compte restreint (part 2)"
      >
        {(txt) => <p className="account-restricted-view-description">{txt}</p>}
      </FormattedMessage>
      <div className="account-restricted-view-actions">
        <FormattedMessage
          id="AccountRestrictedView.CTA"
          defaultMessage="Confirmer mon identité"
          description="Bouton pour les comptes restreint (mailto)"
        >
          {(txt) => (
            <a
              href={`mailto:${import.meta.env.REACT_APP_CONTACT_EMAIL}`}
              className="account-restricted-view-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              {txt}
            </a>
          )}
        </FormattedMessage>
      </div>
    </div>
  </main>
);

export default AccountRestrictedView;
