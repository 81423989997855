import { IconProps } from '../../../../../icons/component-icons/Icon';
import React, { ComponentType, PropsWithChildren } from 'react';
import './DashboardCard.scss';
import Skeleton from 'react-loading-skeleton';

type DashboardCard = PropsWithChildren<{
  icon: ComponentType<IconProps>;
  title: string;
  style?: React.CSSProperties;
}>;

export default function DashboardCard({ icon: Icon, title, children, style }: DashboardCard) {
  return (
    <div className="dashboard-card" style={style}>
      <header>
        <Icon />
        <div>{title}</div>
      </header>

      <div className="dashboard-card__content">{children}</div>
    </div>
  );
}

export function DashboardCardLoader({ children }: PropsWithChildren<{}>) {
  return (
    <div className="dashboard-card">
      <header>
        <Skeleton
          width={18}
          height={18}
          circle
        />
        <Skeleton
          containerClassName="skeleton-container-flex-1"
          width="50%"
        />
      </header>

      <div className="dashboard-card__content">{children}</div>
    </div>
  );
}
