import React from 'react';

interface NoWishIllustrationProps {
  className?: string;
}
function NoWishIllustration({ className }: NoWishIllustrationProps) {
  return (
    <svg
      className={className}
      width="289px"
      height="263px"
      viewBox="0 0 289 263"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="star_thingy">
          <circle
            id="back_circle"
            fill="#F9FAFC"
            cx="131.5"
            cy="131.5"
            r="131.5"
          />
          <path
            d="M186.779836,22.4578998 L155.954095,82.0534557 C155.805677,82.3403914 155.527789,82.5379553 155.208021,82.5838738 L86.986499,92.380456 C86.4398219,92.4589586 86.0602916,92.9657669 86.1387941,93.5124439 C86.1707446,93.7349406 86.2767249,93.9401843 86.4396349,94.0950584 L135.602454,140.832803 C135.843467,141.061928 135.954814,141.395872 135.899535,141.72379 L124.70882,208.107195 C124.617013,208.651795 124.984074,209.167706 125.528675,209.259513 C125.739482,209.29505 125.95613,209.262094 126.146835,209.16548 L187.226659,178.221495 C187.513957,178.075946 187.853769,178.077675 188.13957,178.22614 L247.69309,209.162409 C248.183192,209.417003 248.786888,209.226085 249.041482,208.735983 C249.137941,208.550294 249.173722,208.339025 249.143785,208.13193 L239.541054,141.703333 C239.495275,141.386654 239.603803,141.067191 239.833008,140.843929 L287.830446,94.0911393 C288.226066,93.7057782 288.234382,93.0726678 287.849021,92.6770477 C287.695081,92.5190091 287.493199,92.4163143 287.274817,92.3849575 L219.025127,82.5851972 C218.700207,82.538543 218.418874,82.3353982 218.272374,82.0416507 L188.562934,22.4710265 C188.316448,21.9767965 187.71598,21.7759599 187.22175,22.0224454 C187.032005,22.117076 186.87725,22.2695689 186.779836,22.4578998 Z"
            id="star"
            fill="#F1CD5F"
          />
          <g
            id="cross_circle"
            transform="translate(148.000000, 140.000000)"
          >
            <circle
              id="Oval"
              fill="#52B476"
              cx="39.5"
              cy="39.5"
              r="39.5"
            />
            <g
              id="cross"
              transform="translate(18.000000, 18.000000)"
              stroke="#FEFFFD"
              strokeLinecap="round"
              strokeWidth="8"
            >
              <path
                d="M21.115073,0 L21.115073,42"
                id="Line-2"
              />
              <path
                d="M21.115073,0 L21.115073,42"
                id="Line-2-Copy"
                transform="translate(21.000000, 21.000000) rotate(90.000000) translate(-21.000000, -21.000000) "
              />
            </g>
          </g>
          <g
            id="left-lines"
            transform="translate(68.000000, 135.000000)"
            stroke="#D2D5D6"
            strokeLinecap="round"
            strokeWidth="7"
          >
            <path
              d="M0.5,0.5 L43.5,0.5"
              id="Line"
            />
            <path
              d="M7.5,15.5 L50.5,15.5"
              id="Line-Copy"
            />
            <path
              d="M3.5,31.5 L46.5,31.5"
              id="Line-Copy-2"
            />
            <path
              d="M0.5,45.5 L43.5,45.5"
              id="Line-Copy-3"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default NoWishIllustration;
