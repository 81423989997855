import { gql } from '@apollo/client';

export const GET_LAST_REPORT_DATA = gql`
  query GET_LAST_REPORT_DATA($companyId: ID!) {
    company(companyId: $companyId) {
      id
      name
      lastReportGeneratedAt
    }
  }
`;
