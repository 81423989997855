import React from 'react';
import { Link } from 'react-router';
import translations from './PresentationDisplay.translations';

import './PresentationDisplay.scss';
import { useIntl } from 'react-intl';

interface PresentationDisplayProps {
  text?: string | null;
  showMoreUrl?: string;
}

function PresentationDisplay({ text, showMoreUrl }: PresentationDisplayProps) {
  const intl = useIntl();
  if (!text) {
    return (
      <p className="presentation-display no-presentation">{intl.formatMessage(translations.noStudentPresentation)}</p>
    );
  }
  return (
    <p className="presentation-display">
      {text.slice(0, 350)}
      {text.length > 350 && '...'}
      {showMoreUrl && (
        <Link
          to={showMoreUrl}
          className="presentation-display-showmore"
        >
          {intl.formatMessage(translations.showMore)}
        </Link>
      )}
    </p>
  );
}

export default PresentationDisplay;
