import React from 'react';
import { Link } from 'react-router';
import { useIntl } from 'react-intl';
import buttonCommonTranslations from '../../../../../scenes/common/common-translations/button.translations';
import Routes from '../../../../../routes';
import useModal from '../../../../../components/modal/Modal.hook';
import ButtonsGroup from '../../../../../components/buttons-group/ButtonsGroup';
import PrimaryButton from '../../../../../components/primary-button/PrimaryButton';
import SecondaryButton from '../../../../../components/secondary-button/SecondaryButton';
import translations from './SoftDeletionHibernationReminder.translations';

import styles from './SoftDeletionHibernationReminder.module.scss';
import SoftDeletionReasonForm from '../soft-deletion-reason-form/SoftDeletionReasonForm';

function SoftDeletionHibernationReminder(): JSX.Element {
  const intl = useIntl();
  const [openModal, closeModal] = useModal();

  const openSoftDeletionFormModal = () => {
    openModal({
      title: intl.formatMessage(translations.softDeletionReasonFormModalTitle),
      content: <SoftDeletionReasonForm />,
    });
  };
  return (
    <div>
      <div className={styles.modalBody}>
        <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
        <Link
          to={Routes.settingsHibernation}
          className={styles.goToHibernationLink}
        >
          {intl.formatMessage(translations.hibernationLink)}
          &nbsp;&gt;
        </Link>
      </div>
      <ButtonsGroup>
        <SecondaryButton
          label={intl.formatMessage(buttonCommonTranslations.cancel)}
          onClick={closeModal}
        />
        <PrimaryButton
          label={intl.formatMessage(translations.submitButton)}
          onClick={openSoftDeletionFormModal}
        />
      </ButtonsGroup>
    </div>
  );
}

export default SoftDeletionHibernationReminder;
