import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { FormGroup, Col } from 'react-bootstrap';
import EditForm from '../../../hoc/form-section-wrapper';
import { useFormik } from 'formik';

import './AccountEditPassword.scss';
import CheckboxInput from '../../../components/checkbox-input/CheckboxInput';

const translations = defineMessages({
  changePassword: {
    id: 'AccountEditPassword.changePassword',
    defaultMessage: 'Je souhaite recevoir un lien par email pour modifier mon mot de passe',
    description: 'Page modification de compte - Modification du bloc Mot de passe',
  },
  title: {
    id: 'AccountEditPassword.title.password',
    defaultMessage: 'Changement de mot de passe',
    description: 'Page modification de compte - Modification du bloc Mot de passe - Titre',
  },
});

type Values = {
  get_email_link: boolean;
};

type AccountEditPasswordProps = {
  onUpdateValues: (values: Values) => void;
};

const AccountEditPassword = ({ onUpdateValues }: AccountEditPasswordProps) => {
  const [isEditable, setEditable] = useState(false);
  const intl = useIntl();

  const formik = useFormik<Values>({
    initialValues: {
      get_email_link: false,
    },
    onSubmit: (values) => {
      if (values.get_email_link) onUpdateValues(values);
    },
  });

  return (
    <EditForm
      className="account-edit-password"
      id="account-edit-password"
      title={intl.formatMessage(translations.title)}
      isEditable={isEditable}
      handleEditableChange={setEditable}
      handleSubmit={formik.handleSubmit}
    >
      <FormGroup>
        <Col xs={12}>
          <CheckboxInput
            name="get_email_link"
            label={intl.formatMessage(translations.changePassword)}
            checked={formik.values.get_email_link}
            onChange={formik.handleChange}
            disabled={!isEditable}
          />
        </Col>
      </FormGroup>
    </EditForm>
  );
};

export default AccountEditPassword;
