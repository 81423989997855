import React from 'react';
import { AppointmentReviewFromProfessional } from '../../../../../../../../../@types/graphql.d';
import AppointmentReviewDots from '../../../../../appointment-review-dots/AppointmentReviewDots';
import translations from './SidebarAppointmentProfessionalFeedback.translations';

import styles from '../../SidebarAppointmentSection.module.scss';
import { useIntl } from 'react-intl';

interface SidebarAppointmentProfessionalFeedbackProps {
  reviewFromProfessional?: Pick<
    AppointmentReviewFromProfessional,
    'answer1' | 'answer2' | 'answer3' | 'messageForHr'
  > | null;
  isPremiumSchool?: boolean;
}
function SidebarAppointmentProfessionalFeedback({
  reviewFromProfessional,
  isPremiumSchool,
}: SidebarAppointmentProfessionalFeedbackProps) {
  const intl = useIntl();
  return (
    <div className={styles.appointmentFeedback}>
      <h3 className={styles.subtitle}>{intl.formatMessage(translations.professionalFeedback)}</h3>

      <h4 className={styles.feedback}>{intl.formatMessage(translations.appointmentPreparation)}</h4>
      <AppointmentReviewDots score={reviewFromProfessional?.answer1} />

      <h4 className={styles.feedback}>
        {isPremiumSchool ? intl.formatMessage(translations.studyMatch) : intl.formatMessage(translations.jobMatch)}
      </h4>
      <AppointmentReviewDots score={reviewFromProfessional?.answer2} />

      <h4 className={styles.feedback}>
        {isPremiumSchool
          ? intl.formatMessage(translations.studyCooptation)
          : intl.formatMessage(translations.cooptation)}
      </h4>
      <AppointmentReviewDots score={reviewFromProfessional?.answer3} />

      <h4 className={styles.feedback}>{intl.formatMessage(translations.feedbackComment)}</h4>
      <p className={styles.feedbackComment}>{reviewFromProfessional?.messageForHr}</p>
    </div>
  );
}

export default SidebarAppointmentProfessionalFeedback;
