import React from 'react';
import classnames from 'classnames';

import styles from './CompanyAdminDashboardBloc.module.scss';
import LockIcon from '../../../../../icons/component-icons/LockIcon';

interface CompanyAdminDashboardBlocProps {
  title: string;
  className?: string;
  icon?: boolean;
  component: () => JSX.Element;
}
function CompanyAdminDashboardBloc({ title, className, icon, component: ChildComponent }: CompanyAdminDashboardBlocProps) {
  return (
    <section className={classnames(className, styles.bloc)}>
      <div className={styles.header}>
        {icon && <LockIcon />}
        <header className={styles.title}>{title}</header>
      </div>
      <div>
        <ChildComponent />
      </div>
    </section>
  );
}

export default CompanyAdminDashboardBloc;
