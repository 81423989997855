import { defineMessages } from 'react-intl';

export default defineMessages({
  yes: {
    id: 'CompanyAdminAppointmentsList.wantToBeRecontacted.yes',
    defaultMessage: 'Oui',
  },
  no: {
    id: 'CompanyAdminAppointmentsList.wantToBeRecontacted.no',
    defaultMessage: 'Non',
  },
});
