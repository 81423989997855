import { FormikErrors } from "formik";
import { createContext, useContext } from "react";
import { AppointmentDidNotTookPlaceReasonEnum, FranceLevel1AdministrativeAreasEnum, InternshipDuration, InternshipTypeEnum } from "../../../@types/graphql.d";

export type ReviewValues = {
  question1?: number;
  question2?: number;
  question3?: number;
  question4?: boolean;
  tooYoungToAnswer: boolean;
  shouldAnswerLike: boolean;
  messageForHR?: string;
  message?: string;
  wantsToBeRecontacted?: boolean;
  didNotTookPlaceReason?: AppointmentDidNotTookPlaceReasonEnum;
  didNotTookPlaceReasonDescription?: string;
  jobType?: InternshipTypeEnum[];
  duration?: InternshipDuration;
  workingArea?: string[];
  startDate?: Date;
  postalCode?: string;
  availability?: boolean;
  administrativeArea?: FranceLevel1AdministrativeAreasEnum;
  grade?: string;
  year?: string;
  perception?: Perception;
}

type Perception = {
  id: string;
  answers: { [key: string]: number };
}

type FormikType = {
  initialValues: ReviewValues,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<FormikErrors<ReviewValues>> | Promise<void>;
  values: ReviewValues;
}

type ReviewContextType = {
  reviewFormik: FormikType;
};

export const ReviewContext = createContext<ReviewContextType | undefined>(undefined);

export const useReviewContext = () => {
  const context = useContext(ReviewContext);
  if (!context) {
    throw new Error("Review context is called outside its range");
  }
  return context;
}