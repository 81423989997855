import React, { useContext } from 'react';
import AccordionMenu from '../accordion-menu/AccordionMenu';
import ArrowLeft from '../../../../../icons/component-icons/ArrowLeft';
import { ConversationViewContext } from '../../context/ConversationViewContext';
import ConversationInterlocutorDisplay from '../conversation-interlocutor-display/ConversationInterlocutorDisplay';

import './InterlocutorPanel.scss';
import { AppointmentBookingErrorEnum } from '../../../../../@types/graphql.d';

function InterlocutorPanel() {
  const { conversation, closeMobileInterlocutorPanel } = useContext(ConversationViewContext);

  if (!conversation.interlocutorV2) {
    return null;
  }

  return (
    <aside className="profile-panel">
      <button
        type="button"
        onClick={closeMobileInterlocutorPanel}
        className="profile-panel-close-button"
      >
        <ArrowLeft />
      </button>
      <ConversationInterlocutorDisplay
        interlocutor={conversation?.interlocutorV2}
        disabled={conversation?.disabled}
        showPhoneNumber={conversation.currentUserCanBookAppointmentErrors.includes(
          AppointmentBookingErrorEnum.AlreadyHasAFutureAppointment,
        )}
      />
      <AccordionMenu />
    </aside>
  );
}

export default InterlocutorPanel;
