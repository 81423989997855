import { defineMessages } from 'react-intl';

export default defineMessages({
  interval: {
    id: 'InternshipsDisplayListItem.interval',
    defaultMessage: "{type} d'une durée {duration} à partir de {month} {year}",
    description: 'interval',
  },
  month: {
    id: 'InternshipsDisplayListItem.month',
    defaultMessage: 'de {month} mois',
    description: 'durée de x mois',
  },
  undetermined: {
    id: 'InternshipsDisplayListItem.undeterminedDuration',
    defaultMessage: 'indéterminée',
    description: 'durée indéterminée',
  },
  noDescription: {
    id: 'InternshipsDisplayListItem.noDescription',
    defaultMessage: 'Aucune description ajoutée',
    description: 'aucune description',
  },
  internship: {
    id: 'InternshipsDisplayListItem.internship',
    defaultMessage: 'Stage',
    description: 'select stage',
  },
  apprenticeship: {
    id: 'InternshipsDisplayListItem.apprenticeship',
    defaultMessage: 'Apprentissage',
    description: 'select apprentissage',
  },
  vie: {
    id: 'InternshipsDisplayListItem.vie',
    defaultMessage: 'VIE',
    description: 'select vie',
  },
  via: {
    id: 'InternshipsDisplayListItem.via',
    defaultMessage: 'VIA',
    description: 'select via',
  },
  cdd: {
    id: 'InternshipsDisplayListItem.cdd',
    defaultMessage: 'CDD',
    description: 'select cdd',
  },
  cdi: {
    id: 'InternshipsDisplayListItem.cdi',
    defaultMessage: 'CDI',
    description: 'select cdi',
  },
});
