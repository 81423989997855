import React, { memo } from 'react';
import { PrimarySituationEnum } from '../../../../../../@types/graphql.d';
import { ReviewInterlocutor } from '../../../Review.gql';

import './InterlocutorDetails.scss';

type Props = {
  interlocutor: ReviewInterlocutor;
};

export const InterlocutorDetails: React.FC<Props> = memo(({ interlocutor }) => (
  <div className="interlocutor-details">
    <img src={interlocutor.avatar?.url || ''} />
    <SituationDetails interlocutor={interlocutor} />
  </div>
));

const SituationDetails: React.FC<Props> = memo(({ interlocutor }) => {
  const { currentSchoolName, position, companyName, primarySituation } = interlocutor;
  if (primarySituation?.key === PrimarySituationEnum.Student)
    return <p style={{ marginTop: 13 }}>{currentSchoolName}</p>;
  return (
    <>
      <p style={{ marginTop: 13 }}>{position}</p>
      <p style={{ marginTop: 6 }}>{companyName}</p>
    </>
  );
});
