import { CurrentUserContext } from '../../../contexts/current-user-context/CurrentUserContext';
import useUserLocalStorage from '../../../hooks/user-local-storage';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import './DashboardView.scss';
import { DashboardViewTranslations } from './DashboardView.translations';
import { DashboardMode } from './DashboardView.types';
import Activity from './sections/activity/Activity';
import DashboardTabs from './sections/dashboard-tabs/DashboardTabs';
import FollowUs from './sections/follow-us/FollowUs';
import ProfileInformations from './sections/profile-informations/ProfileInformations';
import Programs from './sections/programs/Programs';
import Progression from './sections/progression/Progression';
import Score from './sections/score/Score';
import UpcomingAppointments from './sections/upcoming-appointments/UpcomingAppointments';
import useIsTablet from '../../../hooks/useIsTablet';
import { useIntl } from 'react-intl';
import MeetingLimit from './sections/meeting-limit/MeetingLimit';
import Availability from './sections/availability/Availability';

const LS_DASHBOARD_MODE = 'dashboard_mode';

export default function DashboardView() {
  const { currentUser } = useContext(CurrentUserContext);
  const userLocalStorage = useUserLocalStorage();
  const isTablet = useIsTablet();

  const [dashboardMode, setDashboardModeState] = useState<DashboardMode>(
    currentUser?.isAmbassador ? 'ambassador' : 'member',
  );

  useEffect(() => {
    if (!userLocalStorage) return;
    const lsValue = userLocalStorage?.getItem(LS_DASHBOARD_MODE);
    if (lsValue) setDashboardModeState(lsValue as DashboardMode);
  }, [userLocalStorage]);

  const setDashboardMode = useCallback(
    (state: DashboardMode) => {
      if (!userLocalStorage) return;
      setDashboardModeState(state);
      userLocalStorage.setItem(LS_DASHBOARD_MODE, state);
    },
    [dashboardMode, userLocalStorage],
  );

  return isTablet ? (
    <DashboardViewMobile {...{ dashboardMode, setDashboardMode }} />
  ) : (
    <DashboardViewDesktop {...{ dashboardMode, setDashboardMode }} />
  );
}

type DashboardViewDeviceProps = {
  dashboardMode: DashboardMode;
  setDashboardMode: (dashboardMode: DashboardMode) => void;
};

function DashboardViewDesktop({ dashboardMode, setDashboardMode }: DashboardViewDeviceProps) {
  const intl = useIntl();
  const { currentUser } = useContext(CurrentUserContext);

  const greetingsTitle = useMemo(
    () =>
      intl.formatMessage(DashboardViewTranslations.greetings, {
        firstName: currentUser?.firstName,
      }),
    [currentUser?.firstName],
  );

  const isAmbassadorMode = useMemo(() => dashboardMode === 'ambassador', [dashboardMode]);

  return (
    <div className="dashboard-view">
      <aside className="dashboard-view__side">
        <h1 className="dashboard-view__side__title">{greetingsTitle}</h1>

        <ProfileInformations />
        <UpcomingAppointments />
        <FollowUs />
      </aside>

      <div className="dashboard-view__tabs">
        <DashboardTabs
          dashboardMode={dashboardMode}
          setDashboardMode={setDashboardMode}
        />

        <div className="dashboard-view__tabs__tab">
          <main className="dashboard-view__tabs__tab__main">
            <Activity dashboardMode={dashboardMode} />
            <Progression dashboardMode={dashboardMode} />
          </main>
          <aside className="dashboard-view__tabs__tab__side">
            {isAmbassadorMode && <Availability />}
            <MeetingLimit dashboardMode={dashboardMode} />
            {isAmbassadorMode && <Score />}
            {!isAmbassadorMode && <Programs />}
          </aside>
        </div>
      </div>
    </div>
  );
}

function DashboardViewMobile({ dashboardMode, setDashboardMode }: DashboardViewDeviceProps) {
  const isAmbassadorMode = useMemo(() => dashboardMode === 'ambassador', [dashboardMode]);

  return (
    <div className="dashboard-view">
      <UpcomingAppointments />
      <DashboardTabs
        dashboardMode={dashboardMode}
        setDashboardMode={setDashboardMode}
      />
      <Activity dashboardMode={dashboardMode} />
      {isAmbassadorMode && <Availability />}
      <MeetingLimit dashboardMode={dashboardMode} />
      <Progression dashboardMode={dashboardMode} />
      {isAmbassadorMode && <Score />}
      {!isAmbassadorMode && <Programs />}
      <FollowUs />
    </div>
  );
}
