import { useEffect, useRef, useState } from 'react';

/**
 * Check if the user click outside component
 * (to close select dropdown for example)
 * @param {Function} callback
 * @param {RefObject<HTMLElement>} ref - generated with useRef
 * @returns {undefined}
 * @url https://codesandbox.io/s/outside-alerter-hooks-lmr2y?module=%2Fsrc%2FOutsideAlerter.js
 */
import { RefObject } from 'react';

export function useOutsideClickEffect(callback: () => void, ref: RefObject<HTMLElement>) {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

/**
 * @returns {[
 *    import('react').MutableRefObject,
 *    () => void,
 *    () => void,
 *    { isActive: boolean, setIsActive: function }
 * ]}
 */
export function useDropdownFeature() {
  const ref = useRef();
  const [isActive, setIsActive] = useState(false);
  const toggle = () => setIsActive(!isActive);
  const close = () => setIsActive(false);
  useOutsideClickEffect(() => {
    setIsActive(false);
  }, ref);
  return [ref, toggle, close, { isActive, setIsActive }];
}
