import React from 'react';
import { useIntl } from 'react-intl';
import translations from '..//NoAppointment.translations';
import '../NoAppointment.scss';

type AmbassadorPleaseAnswerProps = {
  firstName?: string;
};

export default function AmbassadorPleaseAnswer({ firstName }: AmbassadorPleaseAnswerProps): JSX.Element {
  const intl = useIntl();

  return (
    <div className="conversation-no-appointment please-anwser-first">
      <p className="appointment-confirm-it-description">
        {intl.formatMessage(translations.pleaseAnwser, { firstName })}
      </p>
      <div className="actions">
        <button
          type="button"
          className="book-appointment-button"
          disabled
        >
          {intl.formatMessage(translations.createAppointmentButton)}
        </button>
      </div>
    </div>
  );
}
