import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import qs from 'querystring';
import React, { useContext } from 'react';
import { Link } from 'react-router';
import {
  Get_Company_Employee_PackQuery,
  Get_Company_Employee_PackQueryVariables,
} from '../../../../../@types/graphql.d';
import Routes from '../../../../../routes';
import InlineLoader from '../../../../common/inline-loader/InlineLoader';
import Tooltip from '../../../../common/tooltip/Tooltip';
import CompanyContext from '../../context/CompanyContext';
import { GET_COMPANY_EMPLOYEE_PACK } from './CompanyPackGauge.gql';
import translations from './CompanyPackGauge.translations';

import styles from './CompanyPackGauge.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';

interface CompanyPackGaugeProps {
  className?: string;
}
function CompanyPackGauge({ className }: CompanyPackGaugeProps) {
  const intl = useIntl();
  const { companyid } = useContext(CompanyContext);
  const { data, loading } = useQuery<Get_Company_Employee_PackQuery, Get_Company_Employee_PackQueryVariables>(
    GET_COMPANY_EMPLOYEE_PACK,
    {
      skip: !companyid,
      variables: {
        companyId: companyid || '',
      },
    },
  );

  if (loading) {
    return (
      <div className={classnames(styles.companyPackGauge, className)}>
        <InlineLoader />
      </div>
    );
  }

  return (
    <div className={classnames(styles.companyPackGauge, className)}>
      <h4 className={styles.title}>{intl.formatMessage(translations.title)}</h4>
      <Tooltip text={intl.formatMessage(translations.tooltip)} />
      <div className={styles.companyPackConsumation}>
        <p className={styles.publishedEmployeesCount}>{data?.company?.publishedEmployeesCount || '-'}</p>
        {data?.company?.employeePackSize && (
          <div className={styles.packSize}>
            <FormattedMessage
              id="CompanyPackGauge.packSize"
              defaultMessage="sur {packSize} Ambassadeurs"
              values={{
                packSize: <span className="highlight">{data?.company?.employeePackSize}</span>,
              }}
            />
          </div>
        )}
      </div>
      <footer className={styles.footer}>
        {(data?.company?.publishedEmployeesCount || 0) === 0 ? (
          <button
            type="button"
            className={styles.searchButton}
            disabled
          >
            {intl.formatMessage(translations.seeEmployees)}
          </button>
        ) : data?.company?.isPremiumSchool ? (
          <Link
            to={`${Routes.search}?${qs.stringify({
              'filters[current_school_name]': data?.company?.name,
            })}`}
            className={styles.searchButton}
          >
            {intl.formatMessage(translations.seeEmployees)}
          </Link>
        ) : (
          <Link
            to={`${Routes.search}?${qs.stringify({
              'filters[companies]': data?.company?.name,
              'filters[professional_types]': 'company/employee',
            })}`}
            className={styles.searchButton}
          >
            {intl.formatMessage(translations.seeEmployees)}
          </Link>
        )}
      </footer>
    </div>
  );
}

export default CompanyPackGauge;
