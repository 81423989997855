import React, { useContext } from 'react';
import translations from './StudentClaimAppointmentButton.translations';

import './StudentClaimAppointmentButton.scss';
import { ConversationViewContext } from '../../context/ConversationViewContext';
import useModal from '../../../../../components/modal/Modal.hook';
import { AppointmentClaimModalTranslations } from '../../../../../scenes/interactions/hooks/components/appointment-modal/AppointmentModal.translations';
import { ClaimAppointmentModal } from '../../../../../scenes/interactions/hooks/components/appointment-modal/AppointmentModal';
import ClockRewindIcon from '../../../../../icons/component-icons/ClockRewindIcon';
import { useIntl } from 'react-intl';

function StudentClaimAppointmentButton() {
  const intl = useIntl();
  const [openModal] = useModal();
  const { conversation } = useContext(ConversationViewContext);

  if (!conversation?.currentUserCanClaimAppointment) {
    return null;
  }

  const openClaimAppointmentModal = () =>
    openModal({
      title: intl.formatMessage(AppointmentClaimModalTranslations.title),
      content: <ClaimAppointmentModal conversation={conversation} />,
    });

  return (
    <button
      type="button"
      className="student-claim-appointment-button"
      onClick={openClaimAppointmentModal}
    >
      <span className="student-claim-appointment-button-label">{intl.formatMessage(translations.label)}</span>
      <span className="student-claim-appointment-button-label student-claim-appointment-button-label-hover">
        {intl.formatMessage(translations.labelHover)}
      </span>
      <ClockRewindIcon />
    </button>
  );
}

export default StudentClaimAppointmentButton;
