import React, { useState, PropsWithChildren } from 'react';
import ChevronDownIcon from '../../../../../../../icons/component-icons/ChevronDownIcon';
import classnames from 'classnames';

import styles from './AccordionSection.module.scss';

interface AccordionSectionProps {
  title: string;
  defaultOpen?: boolean;
  disabled?: boolean;
}
function AccordionSection({ title, defaultOpen, disabled, children }: PropsWithChildren<AccordionSectionProps>) {
  const [isShownDropdown, showDropdown] = useState(defaultOpen);
  const toggle = () => !disabled && showDropdown(!isShownDropdown);

  return (
    <article
      className={classnames(styles.accordionSection, {
        [styles.opened]: isShownDropdown,
        [styles.disabled]: disabled,
      })}
    >
      <header className={styles.header}>
        <button
          type="button"
          onClick={toggle}
          className={styles.toggle}
          disabled={disabled}
        >
          {title}
          <ChevronDownIcon />
        </button>
      </header>
      {isShownDropdown && children}
    </article>
  );
}

export default AccordionSection;
