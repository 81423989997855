import React from 'react';
import useModal from '../../../../../../../components/modal/Modal.hook';
import { RestrictedPackModalTranslations } from './RestrictedPackModal.translations';
import RestrictedPackModal from './RestrictedPackModal';
import { useIntl } from 'react-intl';

export function useRestrictedPackModal() {
  const [openModal] = useModal();
  const intl = useIntl();
  return () => {
    openModal({
      title: intl.formatMessage(RestrictedPackModalTranslations.title),
      content: <RestrictedPackModal />,
    });
  };
}
