import React from 'react';

function ForbiddenIcon() {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12s12-5.383,12-12S18.617,0,12,0z M1,12c0-2.853,1.101-5.447,2.889-7.404l15.515,15.515C17.447,21.899,14.853,23,12,23C5.935,23,1,18.065,1,12z M20.111,19.404L4.596,3.889C6.553,2.101,9.147,1,12,1c6.065,0,11,4.935,11,11C23,14.853,21.899,17.447,20.111,19.404z" />
    </svg>
  );
}

export default ForbiddenIcon;
