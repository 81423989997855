import { defineMessages } from 'react-intl';

export const AppointmentListTranslations = defineMessages({
  title: {
    id: 'AppointmentList.title',
    defaultMessage: 'Rendez-vous',
  },
  upcoming: {
    id: 'AppointmentList.upcoming',
    defaultMessage: 'À venir',
  },
  past: {
    id: 'AppointmentList.past',
    defaultMessage: 'Passés',
  },
});
