import { defineMessages } from 'react-intl';

export default defineMessages({
  dashboard: {
    id: 'CompanyAdminDashboardDriver.dashboard',
    defaultMessage: 'Dashboard',
  },
  generatedDateTime: {
    id: 'CompanyAdminDashboardDriver.generatedDateTime',
    defaultMessage: 'Dernière mise à jour effectuée le {date} à {hour}',
  },
});
