import React from 'react';
import { IconProps } from './Icon';

export default function LinkedInFilledIcon({ className }: IconProps) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1832_2226)">
        <path
          d="M15.3156 0H1.68125C1.02812 0 0.5 0.515625 0.5 1.15313V14.8438C0.5 15.4813 1.02812 16 1.68125 16H15.3156C15.9688 16 16.5 15.4813 16.5 14.8469V1.15313C16.5 0.515625 15.9688 0 15.3156 0ZM5.24687 13.6344H2.87188V5.99687H5.24687V13.6344ZM4.05938 4.95625C3.29688 4.95625 2.68125 4.34062 2.68125 3.58125C2.68125 2.82188 3.29688 2.20625 4.05938 2.20625C4.81875 2.20625 5.43437 2.82188 5.43437 3.58125C5.43437 4.3375 4.81875 4.95625 4.05938 4.95625ZM14.1344 13.6344H11.7625V9.92188C11.7625 9.0375 11.7469 7.89687 10.5281 7.89687C9.29375 7.89687 9.10625 8.8625 9.10625 9.85938V13.6344H6.7375V5.99687H9.0125V7.04063H9.04375C9.35937 6.44063 10.1344 5.80625 11.2875 5.80625C13.6906 5.80625 14.1344 7.3875 14.1344 9.44375V13.6344V13.6344Z"
          fill="#0B66C3"
        />
      </g>
      <defs>
        <clipPath id="clip0_1832_2226">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
