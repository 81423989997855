import classNames from 'classnames';
import TextInput from '../../../../components/text-input/TextInput';
import useInputGesture from '../../../../hooks/input-gesture.hook';
import ChevronDownIcon from '../../../../icons/component-icons/ChevronDownIcon';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { SearchFilter } from '../../../../scenes/search/Search.model';
import { FacetKeyEnum } from '../../../../@types/graphql.d';

import { FilterButtonTranslations } from '../FilterButton.translations';
import './LocationFilterButton.scss';
import { LocationFilterButtonTranslations } from './LocationFilterButton.translations';
import useDebounce from '../../../../hooks/debounce.hook';
import { useIntl } from 'react-intl';

const GOOGLE_API_KEY = import.meta.env.REACT_APP_GOOGLE_API_BROWSER_KEY || '';

type PlacePrediction = {
  description: string;
  place_id: string;
};

type GetPlacePrediction = ({ input }: { input: string }) => void;

type LocationFilterButtonProps = {
  filters?: SearchFilter;
  onFilterChange: (filter: SearchFilter) => void;
};

export default function LocationFilterButton({ filters: parentFilters, onFilterChange }: LocationFilterButtonProps) {
  const intl = useIntl();
  const facetType = FacetKeyEnum.Location;
  const ref = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [googlePlaceId, setGooglePlaceId] = useState<string>();

  const bannedResults = ['La Réunion, France'];
  const {
    placePredictions,
    getPlacePredictions,
  }: {
    placePredictions: PlacePrediction[];
    getPlacePredictions: GetPlacePrediction;
  } = usePlacesService({
    apiKey: GOOGLE_API_KEY,
    options: {
      types: ['locality', 'country', 'administrative_area_level_1', 'administrative_area_level_2'],
    },
  });

  const [filters, setFilters] = useState<string[]>(parentFilters?.buckets || []);
  const defaultTerm = useMemo(() => {
    if (filters.length !== 1) return '';
    const { description } = JSON.parse(filters[0]);
    return description;
  }, [filters]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [term, setTerm] = useState(defaultTerm);
  useEffect(() => {
    setTerm(defaultTerm);
  }, [defaultTerm]);
  const [debouncedTerm] = useDebounce(term);
  const [areOptionsVisible, setOptionsVisible] = useState(false);

  useEffect(() => {
    if (debouncedTerm) getPlacePredictions({ input: debouncedTerm });
    setOptionsVisible(true);
  }, [debouncedTerm]);

  useEffect(() => {
    setFilters(parentFilters?.buckets || []);
  }, [parentFilters?.buckets]);

  useInputGesture(ref, {
    onExit: () => setDropdownOpen(false),
  });

  useEffect(() => {
    if (!dropdownRef.current || !isDropdownOpen) return;
    if (dropdownRef.current.getBoundingClientRect().right > document.body.clientWidth) {
      dropdownRef.current.classList.toggle('location-filter-button__dropdown--left');
    }
  }, [isDropdownOpen, dropdownRef.current, document.body.clientWidth]);

  const onApply = useCallback(() => {
    if (!googlePlaceId && !term) {
      setDropdownOpen(false);
      return;
    }

    onFilterChange({
      facetType,
      buckets: [
        JSON.stringify({
          placeId: googlePlaceId,
          description: debouncedTerm,
        }),
      ],
    });
    setDropdownOpen(false);
  }, [filters, facetType, debouncedTerm, googlePlaceId, isDropdownOpen]);

  const onClear = useCallback(() => {
    if (!term) return;
    onFilterChange({ facetType, buckets: [] });
  }, [filters, term]);

  return (
    <div
      ref={ref}
      className="location-filter-button"
    >
      <button
        type="button"
        className={classNames(
          'location-filter-button__label',
          { 'location-filter-button__label--active': isDropdownOpen },
          { 'location-filter-button__label--has-filters': filters.length > 0 },
        )}
        onClick={() => setDropdownOpen((prev) => !prev)}
      >
        {intl.formatMessage(LocationFilterButtonTranslations.facetLabel)}
        <ChevronDownIcon />
      </button>

      {filters.length > 0 && <div className="location-filter-button__filters-count">{filters.length}</div>}

      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className="location-filter-button__dropdown"
        >
          <header className="location-filter-button__dropdown__search">
            <TextInput
              value={term}
              onChange={(e) => setTerm(e.target.value)}
              className="location-filter-button__dropdown__search__input"
              placeholder={intl.formatMessage(FilterButtonTranslations.search, {
                facet: intl.formatMessage(LocationFilterButtonTranslations.facetLabel).toLowerCase(),
              })}
            />
            {/* tmp deleted for now */}
            {/* <CheckboxInput
              name="largeZone"
              label="Étendre la zone de recherche"
              checked={largeZone}
              onChange={(e) => setLargeZone(e.target.checked)}
            /> */}
          </header>

          <div className="location-filter-button__dropdown__items">
            {areOptionsVisible &&
              placePredictions
                .filter(({ description }) => bannedResults.indexOf(description) == -1)
                .map(({ description, place_id }) => (
                  <div
                    key={description}
                    className="location-filter-button__dropdown__items__item"
                  >
                    <button
                      type="button"
                      className="location-filter-button__dropdown__items__item"
                      onClick={async () => {
                        setTerm(description);
                        setGooglePlaceId(place_id);
                        setOptionsVisible(false);
                      }}
                    >
                      {description}
                    </button>
                  </div>
                ))}
          </div>

          <footer className="location-filter-button__dropdown__buttons">
            <button
              type="button"
              className="location-filter-button__dropdown__buttons__clear"
              onClick={onClear}
            >
              {intl.formatMessage(FilterButtonTranslations.clear)}
            </button>
            <button
              type="button"
              className="location-filter-button__dropdown__buttons__apply"
              onClick={onApply}
            >
              {intl.formatMessage(FilterButtonTranslations.apply)}
            </button>
          </footer>
        </div>
      )}
    </div>
  );
}
