import React from 'react';
import { TableCellProps } from 'react-virtualized';
import { EmployeeStatus, StudentStatus } from '../../../../../../../@types/graphql.d';
import translations from './IdentityCellRenderer.translations';

import styles from './IdentityCellRenderer.module.scss';
import { IntlShape } from 'react-intl';

type IdentityCellRendererProps = TableCellProps & {
  intl?: IntlShape;
  isPremiumSchool?: boolean;
};

function IdentityCellRenderer({ cellData, dataKey, isPremiumSchool, intl }: IdentityCellRendererProps) {
  if (!cellData) {
    return <p>-</p>;
  }

  if (
    [EmployeeStatus.Anonymized, EmployeeStatus.Archived, StudentStatus.Anonymized, StudentStatus.SoftDeleted].includes(
      cellData.status,
    )
  ) {
    return (
      <p
        className={styles.identityCellRenderer}
        data-testid={dataKey}
      >
        <span className={styles.softDeletedUser}>{intl.formatMessage(translations.deletedUser)}</span>
      </p>
    );
  }

  return (
    <p
      className={styles.identityCellRenderer}
      data-testid={dataKey}
    >
      {isPremiumSchool ? (
        <span>{intl.formatMessage(translations.memberMeeting)}</span>
      ) : (
        <>
          <span>{cellData.firstName}</span>
          <span>{cellData.lastName}</span>
        </>
      )}
    </p>
  );
}

export default IdentityCellRenderer;
