import FiltersIcon from '../../../icons/component-icons/FiltersIcon';
import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import ChevronRightIcon from '../../../icons/component-icons/ChevronRightIcon';
import { FacetKeyEnum, SearchFacet, SearchTypeEnum } from '../../../@types/graphql.d';
import { SearchFilter } from '../Search.model';
import './FiltersDropdown.scss';
import { FacetTypeTranslations } from '../search.translations';
import { SEARCH_FACETS_MAP } from '../Search.constants';
import { useIntl } from 'react-intl';

type FiltersDropdownProps = {
  searchType: SearchTypeEnum;
  searchFacets: SearchFacet[];
  filters: Map<FacetKeyEnum, SearchFilter>;
  setFacetFilters: (searchFilter: SearchFilter) => void;
  onClose: () => void;
};

export default function FiltersDropdown({
  searchType,
  searchFacets,
  filters,
  setFacetFilters,
  onClose,
}: FiltersDropdownProps) {
  const intl = useIntl();
  const [selectedFacetIndex, setSelectedFacetIndex] = useState<number>();
  const selectedSearchFacet = useMemo(
    () => (selectedFacetIndex !== undefined ? SEARCH_FACETS_MAP[searchType][selectedFacetIndex] : undefined),
    [selectedFacetIndex],
  );

  return (
    <div className="filters-dropdown">
      <div className="filters-dropdown__header">
        <div className="filters-dropdown__header__title">
          <FiltersIcon className="filters-dropdown__header__title__icon" />
          Filtres
        </div>

        <button
          type="button"
          className="filters-dropdown__header__close-button"
          onClick={onClose}
        >
          fermer
        </button>
      </div>

      <div className="filters-dropdown__filters">
        {SEARCH_FACETS_MAP[searchType].map(({ type, uncomposeFilterFunction }, i) => {
          return (
            <button
              key={type}
              type="button"
              className={classNames('filters-dropdown__filters__facet', {
                'filters-dropdown__filters__facet--has-filters':
                  filters.get(type) && filters.get(type)!.buckets.length > 0,
              })}
              onClick={() => setSelectedFacetIndex(i)}
            >
              {filters.get(type) && filters.get(type)!.buckets.length > 0 && (
                <span className="filters-dropdown__filters__facet__badge">
                  {uncomposeFilterFunction?.(filters.get(type)!).buckets.length || filters.get(type)!.buckets.length}
                </span>
              )}
              {intl.formatMessage(FacetTypeTranslations[type])}
              <ChevronRightIcon className="filters-dropdown__filters__facet__icon" />
            </button>
          );
        })}

        {selectedSearchFacet !== undefined && (
          <selectedSearchFacet.mobileComponent
            searchType={searchType}
            facetType={selectedSearchFacet.type}
            buckets={
              searchFacets
                .find((f) => f.key === selectedSearchFacet.type)
                ?.buckets.sort((a, b) => (a.label || a.key).localeCompare(b.label || b.key)) || []
            }
            filters={filters.get(selectedSearchFacet.type)}
            onFilterChange={setFacetFilters}
            onClose={() => setSelectedFacetIndex(undefined)}
            withSearch={selectedSearchFacet.withSearch}
          />
        )}
      </div>
    </div>
  );
}
