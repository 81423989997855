import React, { memo, useMemo } from 'react';
import { Layout } from './components/layout/Layout';
import { defineMessages, useIntl } from 'react-intl';
import TextAreaInput from '../../../../components/text-area-input/TextAreaInput';
import ReviewRadioInput from './components/review-radio-input/ReviewRadioInput';
import { useReviewContext } from '../ReviewContext';
import { PanelProps } from './Panel';
import { getQuestion } from '.';
import { MESSAGES_MAX_LENGTH } from './validations';

const translations = defineMessages({
  title: {
    id: 'Review.RecommendPanel.title',
    defaultMessage:
      '{isMember, select, true {Envoyez un avis à l’Ambassadeur} other {Laissez un avis sur votre rendez-vous}}',
  },
  question: {
    id: 'Review.RecommendPanel.question',
    defaultMessage:
      '{isMember, select, true {Dîtes lui ce que vous avez apprécié dans votre échange et comment il/elle peut s’améliorer pour ses prochains rendez-vos} other {Votre avis sera visible par le Membre et par les autres Ambassadeurs qu’il a contactés.  Soyez objectif et factuel et évitez tous propos excessifs.}}',
  },
  placeholder: {
    id: 'Review.RecommendPanel.placeholder',
    defaultMessage: 'Votre message...',
  },
  yes: {
    id: 'Review.RecommendPanel.yes',
    defaultMessage: 'Oui',
  },
  no: {
    id: 'Review.RecommendPanel.no',
    defaultMessage: 'Non',
  },
});

export const RecommendPanel: React.FC<PanelProps> = memo(({ appointment }) => {
  const { reviewFormik } = useReviewContext();
  const { formatMessage } = useIntl();
  const question4Value = useMemo(() => {
    if (typeof reviewFormik.values.question4 !== 'boolean') return undefined;
    return reviewFormik.values.question4 ? 'y' : 'n';
  }, [reviewFormik.values.question4]);
  return (
    <Layout
      title={formatMessage(translations.title, { isMember: appointment.currentUserIsMember })}
      subQuestion={formatMessage(translations.question, { isMember: appointment.currentUserIsMember })}
      style={{ paddingTop: 88, maxWidth: 720, margin: '0 auto' }}
    >
      <TextAreaInput
        name="message"
        style={{ width: '100%' }}
        placeholder={formatMessage(translations.placeholder)}
        value={reviewFormik.values.message}
        onChange={(e) => reviewFormik.setFieldValue('message', e.target.value)}
        maxLength={MESSAGES_MAX_LENGTH}
      />

      {appointment.currentUserIsMember && (
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 8 }}>
          <p style={{ fontSize: 18, fontWeight: 500 }}>{getQuestion(appointment, 4)}</p>
          <ReviewRadioInput
            name="question4"
            value={question4Value}
            values={[
              { value: 'y', translation: formatMessage(translations.yes), emojiOnActive: '👍' },
              { value: 'n', translation: formatMessage(translations.no) },
            ]}
            onSelection={(v) => {
              reviewFormik.setFieldValue('question4', v === 'y');
            }}
            inline
          />
        </div>
      )}
    </Layout>
  );
});
