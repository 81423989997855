import React from 'react';
import { PageLoader } from '../../../../../components/loader/Loader';
import { useParams } from 'react-router';

import AmbassadorView from '../../../../../scenes/public/ambassador-view/AmbassadorView';
import EmployeeManageToolbox from '../employee-manage-toolbox/EmployeeManageToolbox';

function EmployeeProfileView() {
  const { employeeid } = useParams();
  if (!employeeid || employeeid === '') {
    return (
      <>
        <PageLoader />
      </>
    );
  }

  return (
    <>
      <EmployeeManageToolbox />
      <AmbassadorView
        companyAdminPreview
        professionalId={employeeid}
      />
    </>
  );
}

export const EmployeeProfileViewForMjgAdmin = EmployeeProfileView;
export default EmployeeProfileView;
