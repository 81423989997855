import { defineMessages } from 'react-intl';

export const StudyLevelTranslations = defineMessages<string>({
  BAC: {
    id: 'StudyLevelTranslations.BAC',
    defaultMessage: 'BAC',
  },
  BAC_2: {
    id: 'StudyLevelTranslations.BAC_2',
    defaultMessage: 'BAC +2',
  },
  BAC_3: {
    id: 'StudyLevelTranslations.BAC_3',
    defaultMessage: 'BAC +3',
  },
  BAC_4: {
    id: 'StudyLevelTranslations.BAC_4',
    defaultMessage: 'BAC +4',
  },
  BAC_5: {
    id: 'StudyLevelTranslations.BAC_5',
    defaultMessage: 'BAC +5',
  },
  BAC_SUPERIOR_5: {
    id: 'StudyLevelTranslations.BAC_SUPERIOR_5',
    defaultMessage: 'BAC >+5',
  },
  BEP_CAP: {
    id: 'StudyLevelTranslations.BEP_CAP',
    defaultMessage: 'BEP/CAP',
  },
  SPECIFIC_FORMATION: {
    id: 'StudyLevelTranslations.SPECIFIC_FORMATION',
    defaultMessage: 'Formation spécifique',
  },
  NO_STUDY_LEVEL_REQUIRED: {
    id: 'StudyLevelTranslations.NO_STUDY_LEVEL_REQUIRED',
    defaultMessage: 'Sans formation particulière',
  },
  NONE: {
    id: 'StudyLevelTranslations.NO_STUDY_LEVEL_REQUIRED',
    defaultMessage: 'Sans formation particulière',
  },
  BAC_GT: {
    id: 'StudyLevelTranslations.BacGt',
    defaultMessage: 'Bac (général ou technologique ou DAEU)',
  },
  BAC_PRO: {
    id: 'StudyLevelTranslations.BacPro',
    defaultMessage: 'Bac professionnel',
  },
});
