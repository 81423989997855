import React from 'react';
import { TextField } from '@myjobglasses/dsm';
import { Field, Formik } from 'formik';
import {
  Company,
  CustomAttributesSet,
  Update_Company_Custom_AttributesMutation,
  Update_Company_Custom_AttributesMutationVariables,
} from '../../../../../../../@types/graphql.d';
import { UpdateCustomAttributeMutationErrors } from '../../../../../../../@types/graphql-errors.d';
import useFormikMutation from '../../../../../../../utils/hooks/useFormikMutation';
import FormikFieldError from '../../../../../../common/formik/components/formik-field-error/FormikFieldError';
import FormikStatusError from '../../../../../../common/formik/components/formik-status-error/FormikStatusError';
import TagComponent from './components/tag-component/TagComponent';
import { UPDATE_COMPANY_CUSTOM_ATTRIBUTES } from './CompanyAttributesWrite.gql';
import translations, { errorTranslation } from './CompanyAttributesWrite.translations';

import styles from './CompanyAttributesWrite.module.scss';
import { useIntl } from 'react-intl';
import FormikTagField from '../../../../../../common/formik/components/formik-autocomplete-tag-field copy/FormikTagField';

type LabelKeys = Exclude<
  keyof CustomAttributesSet,
  'companyAttributeValues1' | 'companyAttributeValues2' | 'companyAttributeValues3' | '__typename'
>;
type ValuesKeys = Exclude<
  keyof CustomAttributesSet,
  'customEmployeeAttribute1' | 'customEmployeeAttribute2' | 'customEmployeeAttribute3' | '__typename'
>;
interface CompanyAttributesWriteProps {
  company?: Pick<Company, 'id' | 'customAttributesSet' | 'isPremiumSchool'> | null;
  number: number;
  finish: () => void;
}
function CompanyAttributesWrite({ company, number, finish }: CompanyAttributesWriteProps) {
  const intl = useIntl();
  const labelKey = `customEmployeeAttribute${number}` as LabelKeys;
  const valueKey = `companyAttributeValues${number}` as ValuesKeys;
  const [onSubmit] = useFormikMutation<
    Update_Company_Custom_AttributesMutation,
    Update_Company_Custom_AttributesMutationVariables
  >(UPDATE_COMPANY_CUSTOM_ATTRIBUTES, {
    refetchQueries: ['GET_COMPANY_CUSTOM_ATTRIBUTES'],
    onCompleted: (successData) => {
      if (successData?.updateCustomAttribute) {
        finish();
      }
    },
  });
  return (
    <Formik<FormikValues<Update_Company_Custom_AttributesMutationVariables>>
      initialValues={{
        companyId: company?.id,
        number,
        customEmployeeAttribute: company?.customAttributesSet[labelKey] || '',
        companyAttributeValues: company?.customAttributesSet[valueKey] || [],
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form
          className={styles.companyAttributesWrite}
          onSubmit={handleSubmit}
        >
          <Field
            name="customEmployeeAttribute"
            component={TextField}
            label={intl.formatMessage(translations.labelName)}
            maxLength={30}
            maxLengthDisplay={(current: number, max: number) =>
              intl.formatMessage(translations.counter, { current, max })
            }
          />
          <FormikFieldError<Update_Company_Custom_AttributesMutationVariables, UpdateCustomAttributeMutationErrors>
            name="customEmployeeAttribute"
            asyncFieldErrorTranslations={errorTranslation}
          />
          <h2 className={styles.listValues}>{intl.formatMessage(translations.listValues)}</h2>
          {!company?.isPremiumSchool && (
            <p className={styles.samples}>
              <span className={styles.sampleLabel}>{intl.formatMessage(translations.sample)}</span>
              <span className={styles.sample}>{intl.formatMessage(translations.marketing)}</span>
              <span className={styles.sample}>{intl.formatMessage(translations.finance)}</span>
              <span className={styles.sample}>{intl.formatMessage(translations.hr)}</span>
            </p>
          )}
          <Field
            name="companyAttributeValues"
            component={FormikTagField}
            label={intl.formatMessage(translations.possibleValue)}
            minLength={1}
            maxLength={50}
            tagComponent={TagComponent}
            helper={intl.formatMessage(translations.helper)}
          />
          <FormikFieldError<Update_Company_Custom_AttributesMutationVariables, UpdateCustomAttributeMutationErrors>
            name="companyAttributeValues"
            asyncFieldErrorTranslations={errorTranslation}
          />
          <FormikStatusError translations={errorTranslation} />
          <div className={styles.actions}>
            <button
              type="button"
              className={styles.cancelButton}
              onClick={finish}
            >
              {intl.formatMessage(translations.cancelButton)}
            </button>
            <button
              type="submit"
              className={styles.submitButton}
              disabled={isSubmitting}
            >
              {intl.formatMessage(translations.submitButton)}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default CompanyAttributesWrite;
