import { gql } from '@apollo/client';

export const GET_COMPANY_RECRUITING_WORKING_AREAS = gql`
  query GET_COMPANY_RECRUITING_WORKING_AREAS($companyId: ID!) {
    company(companyId: $companyId) {
      id
      recruitingWorkingAreas
      isPremiumSchool
    }
  }
`;
