import { useEffect, useState } from 'react';
import { mapStringsToOptions } from '../../../../../../utils/typed/formik';

interface UseOrderedOptionsValue {
  companyAttributeValues1: Array<string | null>;
  companyAttributeValues2: Array<string | null>;
  companyAttributeValues3: Array<string | null>;
}
export default function useOrderedOptions(customAttributeSet?: UseOrderedOptionsValue) {
  const [companyAttribute1Options, setCompanyAttribute1Options] = useState<SelectFieldOptions[]>([]);
  const [companyAttribute2Options, setCompanyAttribute2Options] = useState<SelectFieldOptions[]>([]);
  const [companyAttribute3Options, setCompanyAttribute3Options] = useState<SelectFieldOptions[]>([]);
  useEffect(() => {
    setCompanyAttribute1Options(
      mapStringsToOptions(customAttributeSet?.companyAttributeValues1, {
        order: 'alphabetic',
      }),
    );
    setCompanyAttribute2Options(
      mapStringsToOptions(customAttributeSet?.companyAttributeValues2, {
        order: 'alphabetic',
      }),
    );
    setCompanyAttribute3Options(
      mapStringsToOptions(customAttributeSet?.companyAttributeValues3, {
        order: 'alphabetic',
      }),
    );
  }, [customAttributeSet]);
  return [companyAttribute1Options, companyAttribute2Options, companyAttribute3Options];
}
