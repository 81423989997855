import React from 'react';
import { PublicProfessionalProfile, PublicProfessionalProfileStatus } from '../../../@types/graphql.d';
import ProfessionalAvailableFromRedCarpet from './components/professional-available-from-red-carpet/ProfessionalAvailableFromRedCarpet';
import ProfessionalAvailable from './components/professional-available/ProfessionalAvailable';
import ProfessionalUnavailable from './components/professional-unavailable/ProfessionalUnavailable';
import ProfessionalUnpublished from './components/professional-unpublished/ProfessionalUnpublished';

interface ProfessionalAvailabilityDisplayProps {
  professional: Pick<PublicProfessionalProfile, 'unavailableUntil' | 'status'>;
  className?: string;
  isMentor: boolean;
}
function ProfessionalAvailabilityDisplay({ professional, className, isMentor }: ProfessionalAvailabilityDisplayProps) {
  switch (professional?.status) {
    case PublicProfessionalProfileStatus.Published:
      return (
        <ProfessionalAvailable
          isMentor={isMentor}
          className={className}
        />
      );
    case PublicProfessionalProfileStatus.Unavailable:
    case PublicProfessionalProfileStatus.Hibernated:
      return (
        <ProfessionalUnavailable
          isMentor={isMentor}
          className={className}
        />
      );
    case PublicProfessionalProfileStatus.RedCarpetOnly:
      return <ProfessionalAvailableFromRedCarpet />;
    case PublicProfessionalProfileStatus.IcedUpOrRestricted:
    case PublicProfessionalProfileStatus.Unpublished:
      return <ProfessionalUnpublished />;
    case PublicProfessionalProfileStatus.SoftDeleted:
    case PublicProfessionalProfileStatus.NeverPublished:
    default:
      return null;
  }
}

export default ProfessionalAvailabilityDisplay;
