import React from 'react';
import translations from './CompanyAttributesEmpty.translations';

import styles from './CompanyAttributesEmpty.module.scss';
import { useIntl } from 'react-intl';

interface CompanyAttributesEmptyProps {
  edit: () => void;
  disabled?: boolean;
}
function CompanyAttributesEmpty({ edit, disabled }: CompanyAttributesEmptyProps) {
  const intl = useIntl();
  return (
    <div className={styles.companyAttributesEmpty}>
      <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
      <button
        type="button"
        onClick={edit}
        className={styles.addButton}
        disabled={disabled}
      >
        {intl.formatMessage(translations.editButton)}
      </button>
    </div>
  );
}

export default CompanyAttributesEmpty;
