import { gql } from '@apollo/client';

export const JOIN_PROGRAM = gql`
  mutation JOIN_PROGRAM($participationCode: String!) {
    createParticipation(input: { participationCode: $participationCode }) {
      id
      program {
        id
        name
        startAt
      }
    }
  }
`;
