import React from 'react';
import { TableHeaderProps } from 'react-virtualized';
import Help from '../../../../../../common/help/Help';

interface HeaderCellRendererWithTooltipProps extends TableHeaderProps {
  tooltipText: string;
}

function HeaderCellRendererWithTooltip({ label, dataKey, tooltipText }: HeaderCellRendererWithTooltipProps) {
  return (
    <span data-testid={dataKey}>
      {label}
      <Help
        text={tooltipText}
        variant="blue-dark"
      />
    </span>
  );
}

export default HeaderCellRendererWithTooltip;
