import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Waypoint } from 'react-waypoint';
import uniqid from 'uniqid';
import { ProfessionalCardReferrer } from '../../../@types/routes-params.d';
import { Get_Student_WishlistQuery, Get_Student_WishlistQueryVariables } from '../../../@types/graphql.d';
import GraphQLInfiniteScrollError from '../../common/graphql-infinite-scroll-error/GraphQLInfiniteScrollError';
import ProfessionalCard from '../../common/professional-card/ProfessionalCard';
import NoNetwork from '../../common/no-network/NoNetwork';
import NoWish from './components/no-wish/NoWish';
import { GET_STUDENT_WISHLIST } from './Wishlist.gql';
import translations from './Wishlist.translations';

import styles from './Wishlist.module.scss';
import { useIntl } from 'react-intl';

function Wishlist() {
  const intl = useIntl();
  const [refetchError, setRefetchError] = useState();
  const { data, loading, error, refetch, fetchMore } = useQuery<
    Get_Student_WishlistQuery,
    Get_Student_WishlistQueryVariables
  >(GET_STUDENT_WISHLIST, {
    notifyOnNetworkStatusChange: true,
  });
  if (error) {
    return <NoNetwork refetch={refetch} />;
  }
  if (data?.wishlist?.nodes.length === 0) {
    return <NoWish />;
  }
  function more() {
    setRefetchError(undefined);
    fetchMore({
      variables: {
        after: data?.wishlist?.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          wishlist: {
            ...prev.wishlist,
            pageInfo: fetchMoreResult.wishlist?.pageInfo || {
              __typename: 'PageInfo',
              hasNextPage: false,
              hasPreviousPage: false,
              endCursor: undefined,
              startCursor: undefined,
            },
            nodes: [...(prev.wishlist?.nodes || []), ...(fetchMoreResult.wishlist?.nodes || [])],
          },
        };
      },
    }).catch((newError) => {
      setRefetchError(newError);
    });
  }
  return (
    <div className={styles.wishlist}>
      <h2 className={styles.title}>{intl.formatMessage(translations.title)}</h2>
      <div className={styles.wishlistList}>
        {data?.wishlist?.nodes.map((professional: any) => (
          <ProfessionalCard
            key={professional.id}
            professional={professional}
            navigationReferrer={ProfessionalCardReferrer.Wishlist}
          />
        ))}
        {loading && (
          <>
            {Array.from({ length: 16 }).map(() => (
              <ProfessionalCard key={uniqid()} />
            ))}
          </>
        )}
      </div>
      {data?.wishlist?.pageInfo.hasNextPage && !loading && !refetchError && <Waypoint onEnter={more} />}
      <GraphQLInfiniteScrollError
        display={!!refetchError}
        retry={more}
      />
    </div>
  );
}

export default Wishlist;
