import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    id: 'TemplateListItemConfirmDeletion.label',
    defaultMessage: 'Supprimer ce template ?',
    description: 'Label de la suppression de template',
  },
  confirmButton: {
    id: 'TemplateListItemConfirmDeletion.confirmButton',
    defaultMessage: 'Oui',
    description: 'button to accept deletion',
  },
  cancelButton: {
    id: 'TemplateListItemConfirmDeletion.cancelButton',
    defaultMessage: 'Non',
    description: 'button to accept deletion',
  },
});
