import React from 'react';
import classNames from 'classnames';
import { FormattedTime } from 'react-intl';
import { compose, withState, withHandlers } from 'recompose';

import './FlatTimePicker.scss';
import ArrowRightIcon from '../../icons/component-icons/ArrowRightIcon';
import ArrowLeftIcon from '../../icons/component-icons/ArrowLeftIcon';

export type FlatTimePickerProps = {
  value: number;
  onChoose: (value: number) => void;
  offset: number;
  goNext: () => void;
  goPrevious: () => void;
};

const FlatTimePicker = ({ value, offset, onChoose, goNext, goPrevious }: FlatTimePickerProps) => (
  <div className="flat-time-picker">
    <button
      onClick={goPrevious}
      disabled={offset <= 0}
      className="nav-button"
    >
      <ArrowLeftIcon />
    </button>
    {new Array(5).fill(undefined).map((v, i) => (
      <button
        key={`hour-${i}`}
        onClick={() => onChoose(offset + i)}
        className={classNames({
          active: offset + i === value,
        })}
      >
        <FormattedTime
          value={new Date(Date.UTC(2017, 7, 1, offset + i, 0, 0))}
          timeZone="UTC"
        />
      </button>
    ))}
    <button
      onClick={goNext}
      disabled={offset >= 19}
      className="nav-button"
    >
      <ArrowRightIcon />
    </button>
  </div>
);

export default compose<FlatTimePickerProps, {}>(
  withState('offset', 'setOffset', ({ value }: { value: number }) => {
    if (value) {
      // prevent displaying values before 00:00
      if (value < 2) {
        return 0;
        // prevent displaying values after 23:00
      } else if (value > 21) {
        return 19;
        // zoom on current value
      } else {
        return value - 2;
      }
    }
    return 11;
  }),
  withHandlers({
    goNext:
      ({ setOffset, offset }: { setOffset: (offset: number) => void; offset: number }) =>
      () => {
        setOffset(offset + 1);
      },
    goPrevious:
      ({ setOffset, offset }: { setOffset: (offset: number) => void; offset: number }) =>
      () => {
        setOffset(offset - 1);
      },
  }),
)(FlatTimePicker);
