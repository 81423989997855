import React from 'react';
import { TableCellProps } from 'react-virtualized';
import { AppointmentStatus } from '../../../../../../../@types/graphql.d';
import AppointmentReviewDots from '../../../appointment-review-dots/AppointmentReviewDots';

interface ReviewCellRendererProps extends TableCellProps {
  answer: string;
  cellData?: {
    [key: string]: any;
  };
}

function ReviewCellRenderer({ cellData, answer }: ReviewCellRendererProps) {
  if (
    !cellData?.[answer] ||
    typeof cellData?.[answer] !== 'number' ||
    cellData?.[answer] < 0 ||
    cellData?.appointmentStatus !== AppointmentStatus.TookPlace
  ) {
    return <div>-</div>;
  }

  return <AppointmentReviewDots score={cellData[answer]} />;
}

export default ReviewCellRenderer;
