import classNames from 'classnames';
import BuildingIcon from '../../../icons/component-icons/BuildingIcon';
import MessageTextSquareIcon from '../../../icons/component-icons/MessageTextSquareIcon';
import ThumbsUpIcon from '../../../icons/component-icons/ThumbsUpIcon';
import React, { useMemo } from 'react';
import { Link, generatePath } from 'react-router';
import Routes from '../../../routes';
import { GetAmbassadorsQuery, UserProfile } from '../../../@types/graphql.d';
import { AmbassadorCardTranslations } from './AmbassadorCard.translations';

import './AmbassadorCard.scss';
import { useIntl } from 'react-intl';

type AmbassadorCardProps = {
  ambassador: GetAmbassadorsQuery['searchAmbassadors']['results'][0];
  size?: 'small' | 'large';
};

export default function AmbassadorCard({ ambassador, size = 'large' }: AmbassadorCardProps) {
  const intl = useIntl();
  const isInSchool = ambassador.profileType === UserProfile.InSchool;
  const isPreviouslyInOffice = ambassador.profileType === 'PREVIOUSLY_IN_OFFICE';

  return (
    <Link
      className={classNames('ambassador-card', {
        'ambassador-card--small': size === 'small',
      })}
      to={generatePath(Routes.professional, {
        id: ambassador.professionalId || '',
      })}
      data-cy="ambassador-card"
    >
      <header
        className={classNames('ambassador-card__header', {
          'ambassador-card__header--premium': ambassador.premium,
          'ambassador-card__header--custom': ambassador.banner?.url,
        })}
      >
        {ambassador.banner?.url && (
          <img
            className="ambassador-card__header--custom__banner"
            src={ambassador.banner?.url}
          />
        )}
      </header>
      <div className="ambassador-card__content">
        <img
          className="ambassador-card__content__avatar"
          src={ambassador.avatar?.url || ''}
          alt="Ambassador avatar"
        />
        <div className="ambassador-card__content__company-logo">
          {ambassador.companyLogo?.url ? (
            <img
              src={ambassador.companyLogo.url}
              alt="Ambassador company logo"
            />
          ) : (
            <BuildingIcon />
          )}
        </div>

        <div className="ambassador-card__content__name">
          {ambassador.firstName} {ambassador.publicLastName ? ambassador.lastName : null}
        </div>

        <div
          className="ambassador-card__content__company"
          data-cy="ambassador-card__company-name"
        >
          {isInSchool
            ? ambassador.currentSchoolName
            : isPreviouslyInOffice
              ? 'ex-' + ambassador.previousCompanyName
              : ambassador.companyName}
        </div>

        <div className="ambassador-card__content__position">
          {isInSchool
            ? ambassador.currentGradeOrDiploma
            : isPreviouslyInOffice
              ? ambassador.previousPosition
              : ambassador.position}
        </div>

        <footer className="ambassador-card__footer">
          <div
            className={classNames('ambassador-card__footer__availability', {
              'ambassador-card__footer__availability--premium': ambassador.premium,
            })}
          >
            <div
              className={classNames(
                'ambassador-card__footer__availability__icon',
                {
                  'ambassador-card__footer__availability__icon--premium': ambassador.premium,
                },
                {
                  'ambassador-card__footer__availability__icon--unavailable': !!ambassador.unavailableUntil,
                },
                {
                  'ambassador-card__footer__availability__icon--premium-unavailable':
                    !!ambassador.unavailableUntil && ambassador.premium,
                },
              )}
            >
              <MessageTextSquareIcon />
            </div>

            {intl.formatMessage(
              !ambassador.unavailableUntil
                ? AmbassadorCardTranslations.available
                : AmbassadorCardTranslations.unavailable,
            )}
          </div>

          {ambassador.premium && (
            <div className={classNames('ambassador-card__footer__likes')}>
              {ambassador.positiveLikeCount}

              <div className="ambassador-card__footer__likes__icon">
                <ThumbsUpIcon />
              </div>
            </div>
          )}
        </footer>
      </div>
    </Link>
  );
}
