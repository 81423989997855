import { CurrentUserContext } from '../../../../../../../contexts/current-user-context/CurrentUserContext';
import React, { memo, PropsWithChildren, useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import OffersType from '../offers-type/OffersType';

import './Layout.scss';

const translations = defineMessages({
  greetings: {
    id: 'CompanyHome.greetings',
    defaultMessage: 'Bonjour {name}',
  },
  subtitle: {
    id: 'CompanyHome.subtitle',
    defaultMessage:
      'Bienvenue sur votre espace entreprise. Retrouvez ici les informations relatives à votre entreprise et à votre offre actuelle sur My Job Glasses',
  },
});

export const Layout = memo(({ children }: PropsWithChildren<{}>) => {
  const intl = useIntl();
  const { currentUser } = useContext(CurrentUserContext);

  return (
    <div className="connect-company-home-layout">
      <header>
        <div>
          <h2>{intl.formatMessage(translations.greetings, { name: currentUser?.firstName })}</h2>
          <p>{intl.formatMessage(translations.subtitle)}</p>
        </div>
      </header>

      <main>{children}</main>

      <footer>
        <OffersType />
      </footer>
    </div>
  );
});
