import React, { useMemo } from 'react';
import './OffersType.scss';
import OfferCard from '../offer-card/OfferCard';
import {
  PremiumOfferPack0Translations,
  PremiumOfferPack1Translations,
  PremiumOfferPack2Translations,
  PremiumOfferPack3Translations,
} from '../../../../../../../scenes/common/common-translations/premium-offer.translations';
import { defineMessages, useIntl } from 'react-intl';
import { useConnectCompanyContext } from '../../ConnectCompanyContext';
import PolygonIcon from '../../../../../../../icons/component-icons/PolygonIcon';

const translations = defineMessages({
  title: {
    id: 'OffersType.title',
    defaultMessage: 'Découvrez nos offres entreprises',
  },
  subtitle: {
    id: 'OffersType.subtitle',
    defaultMessage:
      'Faites de vos collaborateurs vos meilleurs ambassadeurs et faites connaitre la diversité de vos métiers au plus grand nombre.',
  },
});

function OffersType() {
  const intl = useIntl();
  const { premiumPack } = useConnectCompanyContext();
  const offerPacks = useMemo(
    () => [
      { translations: PremiumOfferPack0Translations, id: 'PREMIUM_0' },
      { translations: PremiumOfferPack1Translations, id: 'PREMIUM_1' },
      { translations: PremiumOfferPack2Translations, id: 'PREMIUM_2' },
      { translations: PremiumOfferPack3Translations, id: 'PREMIUM_3' },
    ],
    [],
  );

  if (premiumPack === 'PREMIUM_3') return null;

  const processedOffers = useMemo(() => {
    const currentOfferIndex = offerPacks.findIndex((pack) => pack.id === premiumPack);

    return offerPacks
      .filter((_, index) => index >= currentOfferIndex)
      .map((pack, index) => ({
        ...pack,
        isSelected: pack.id === premiumPack,
        isNextOffer: index === 1,
        isUpcomingOffer: index > 1,
      }));
  }, [offerPacks, premiumPack]);

  const currentOfferTitle = offerPacks.find(pack => pack.id === premiumPack)?.translations.title;

  return (
    <div className="offers-type__container">
      <PolygonIcon className="offers-type__container__background__left" />
      <PolygonIcon className="offers-type__container__background__right" />
      <header className="offers-type__container__header">
        <h1>{intl.formatMessage(translations.title)}</h1>
        <p>{intl.formatMessage(translations.subtitle)}</p>
      </header>
      <div className="offers-type__container__cards">
        {processedOffers.map(({ translations, isSelected, isNextOffer, isUpcomingOffer }, index) => (
          <OfferCard
            key={index}
            title={intl.formatMessage(translations.title)}
            subtitle={intl.formatMessage(translations.subtitle)}
            mentions={Object.values(translations)
              .filter((_, keyIndex) => keyIndex > 1)
              .map((mention) => intl.formatMessage(mention))}
            isSelected={isSelected}
            isNextOffer={isNextOffer}
            isUpcomingOffer={isUpcomingOffer}
            currentOfferTitle={currentOfferTitle?.defaultMessage}
          />
        ))}
      </div>
    </div>
  );
}

export default OffersType;
