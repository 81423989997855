import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import CalendarIcon from '../../../../../icons/component-icons/CalendarIcon';
import { IconProps } from '../../../../../icons/component-icons/Icon';
import MessageTextCircleIcon from '../../../../../icons/component-icons/MessageTextCircleIcon';
import StarIcon from '../../../../../icons/component-icons/StarIcon';
import UsersIcon from '../../../../../icons/component-icons/UsersIcon';
import React, { ComponentType, useEffect, useMemo, useRef } from 'react';
import { BadgeEnum, Badget } from '../../../../../@types/graphql.d';
import { DashboardMode } from '../../DashboardView.types';
import DashboardCard, { DashboardCardLoader } from '../../components/dashboard-card/DashboardCard';
import { GET_AMBASSADOR_PROGRESSION_QUERY, GET_MEMBER_PROGRESSION_QUERY } from '../../DashboardView.gql';
import './Progression.scss';
import { ProgressionTranslations } from './Progression.translations';
import LineChartUpIcon from '../../../../../icons/component-icons/LineChartUpIcon';
import { BadgesTranslations } from '../../../../../i18n/badge_enum.translations';
import Skeleton from 'react-loading-skeleton';
import { useIntl } from 'react-intl';

const ICON_MAP: {
  [key: string]: { icon: ComponentType<IconProps>; count?: number };
} = {
  [BadgeEnum.Registration]: {
    icon: UsersIcon,
  },
  [BadgeEnum.AccountCreated]: {
    icon: UsersIcon,
  },
  [BadgeEnum.ProfileComplete]: {
    icon: UsersIcon,
  },
  [BadgeEnum.ProfileCompletedForTheFirstTime]: {
    icon: UsersIcon,
  },
  [BadgeEnum.FirstMessageSent]: {
    icon: MessageTextCircleIcon,
  },
  [BadgeEnum.FirstAppointmentOccurred]: {
    icon: CalendarIcon,
  },
  [BadgeEnum.FirstAppointmentReview]: {
    icon: StarIcon,
  },
  [BadgeEnum.FifthAppointmentOccurred]: {
    icon: CalendarIcon,
    count: 5,
  },
  [BadgeEnum.TwentiethAppointmentOccurred]: {
    icon: CalendarIcon,
    count: 20,
  },
  [BadgeEnum.FiftiethAppointmentOccurred]: {
    icon: CalendarIcon,
    count: 50,
  },
  [BadgeEnum.HundredthAppointmentOccurred]: {
    icon: CalendarIcon,
    count: 100,
  },
  [BadgeEnum.ProfilePublishedForTheFirstTime]: {
    icon: UsersIcon,
  },
  [BadgeEnum.FirstConversationReceived]: {
    icon: MessageTextCircleIcon,
  },
  [BadgeEnum.FirstConversationReplied]: {
    icon: MessageTextCircleIcon,
  },
  [BadgeEnum.AppointmentNo_1Occurred]: {
    icon: CalendarIcon,
    count: 1,
  },
  [BadgeEnum.AppointmentNo_1Reviewed]: {
    icon: StarIcon,
  },
  [BadgeEnum.AppointmentNo_3Occurred]: {
    icon: CalendarIcon,
    count: 3,
  },
  [BadgeEnum.ThirdAppointmentOccurred]: {
    icon: CalendarIcon,
    count: 3,
  },
  [BadgeEnum.AppointmentNo_5Occurred]: {
    icon: CalendarIcon,
    count: 5,
  },
  [BadgeEnum.AppointmentNo_10Occurred]: {
    icon: CalendarIcon,
    count: 10,
  },
  [BadgeEnum.TenthAppointmentOccurred]: {
    icon: CalendarIcon,
    count: 10,
  },
  [BadgeEnum.AppointmentNo_15Occurred]: {
    icon: CalendarIcon,
    count: 15,
  },
  [BadgeEnum.FifteenthAppointmentOccurred]: {
    icon: CalendarIcon,
    count: 15,
  },
  [BadgeEnum.AppointmentNo_20Occurred]: {
    icon: CalendarIcon,
    count: 20,
  },
  [BadgeEnum.AppointmentNo_30Occurred]: {
    icon: CalendarIcon,
    count: 30,
  },
  [BadgeEnum.ThirthiethAppointmentOccurred]: {
    icon: CalendarIcon,
    count: 30,
  },
  [BadgeEnum.AppointmentNo_50Occurred]: {
    icon: CalendarIcon,
    count: 50,
  },
  [BadgeEnum.AppointmentNo_75Occurred]: {
    icon: CalendarIcon,
    count: 75,
  },
  [BadgeEnum.SeventyFifthAppointmentOccurred]: {
    icon: CalendarIcon,
    count: 75,
  },
  [BadgeEnum.AppointmentNo_100Occurred]: {
    icon: CalendarIcon,
    count: 100,
  },
  [BadgeEnum.AppointmentNo_150Occurred]: {
    icon: CalendarIcon,
    count: 150,
  },
  [BadgeEnum.AppointmentNo_200Occurred]: {
    icon: CalendarIcon,
    count: 200,
  },
  [BadgeEnum.AppointmentNo_300Occurred]: {
    icon: CalendarIcon,
    count: 300,
  },
  [BadgeEnum.AppointmentNo_500Occurred]: {
    icon: CalendarIcon,
    count: 500,
  },
};

type ProgressionProps = {
  dashboardMode: DashboardMode;
};

export default function Progression({ dashboardMode }: ProgressionProps) {
  const intl = useIntl();
  const { data, loading } = useQuery(
    dashboardMode === 'member' ? GET_MEMBER_PROGRESSION_QUERY : GET_AMBASSADOR_PROGRESSION_QUERY,
    { fetchPolicy: 'network-only' },
  );

  const badges: Badget[] = useMemo(
    () => (dashboardMode === 'member' ? data?.progression?.memberBadges : data?.progression?.ambassadorBadges) || [],
    [data, dashboardMode],
  );

  const progressionScrollRef = useRef<HTMLDivElement>(null);

  const currentBadgeIndex = useMemo(() => badges.findIndex(({ completed }) => !completed) || 0, [badges]);

  useEffect(() => {
    if (progressionScrollRef.current) {
      const badge = progressionScrollRef.current.children[currentBadgeIndex];
      badge.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
        block: 'nearest',
      });
    }
  }, [progressionScrollRef, currentBadgeIndex]);

  if (loading) return <ProgressionLoader />;
  if (badges.length === 0) return null;

  return (
    <DashboardCard
      icon={LineChartUpIcon}
      title={intl.formatMessage(ProgressionTranslations.title)}
    >
      <div className="dashboard-progression">
        <div
          ref={progressionScrollRef}
          className="dashboard-progression__badges"
        >
          {badges.map((badge) => (
            <Badge
              key={badge.name}
              name={badge.name}
              completed={badge.completed}
            />
          ))}
        </div>
      </div>
    </DashboardCard>
  );
}

type BadgeProps = {
  name: BadgeEnum;
  completed: boolean;
};

function Badge({ name, completed }: BadgeProps) {
  const intl = useIntl();
  const icon = ICON_MAP[name];
  const Icon = icon.icon;
  return (
    <div
      className={classNames('dashboard-progression__badges__badge', {
        'dashboard-progression__badges__badge--completed': completed,
      })}
    >
      <Icon />
      {icon.count && <div className="dashboard-progression__badges__badge__count">{icon.count}</div>}
      <div
        className="dashboard-progression__badges__badge__text"
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage(BadgesTranslations[name]),
        }}
      />
    </div>
  );
}

const ProgressionLoader = () => (
  <DashboardCardLoader>
    <div className="dashboard-progression">
      <div className="dashboard-progression__badges">
        <Skeleton
          width={84}
          height={84}
          circle
        />
        <Skeleton
          width={84}
          height={84}
          circle
        />
        <Skeleton
          width={84}
          height={84}
          circle
        />
        <Skeleton
          width={84}
          height={84}
          circle
        />
        <Skeleton
          width={84}
          height={84}
          circle
        />
        <Skeleton
          width={84}
          height={84}
          circle
        />
      </div>
    </div>
  </DashboardCardLoader>
);
