import React, { ComponentType, useMemo } from 'react';
import classNames from 'classnames';
import { useState } from 'react';
import './ReviewRadioInput.scss';
import { IconProps } from '../../../../../../icons/component-icons/Icon';

type InputValue = {
  icon?: ComponentType<IconProps>;
  value: string;
  emojiOnActive?: string;
  translation: string;
};

type ReviewRadioInputProps = {
  label?: string;
  name: string;
  value?: string;
  values: InputValue[];
  error?: string;
  onSelection: (value: string) => void;
  inline?: boolean;
  dataCy?: string;
};

export default function ReviewRadioInput({
  label,
  name,
  error,
  value,
  values,
  onSelection,
  inline,
  dataCy,
}: ReviewRadioInputProps) {
  const valueIndex = useMemo(() => values.findIndex(({ value: v }) => value === v), [value, values]);

  return (
    <div className={classNames('review-radio-input', { 'review-radio-input--inline': inline })}>
      {label && (
        <label
          className="review-radio-input__label"
          htmlFor={name}
        >
          {label}
        </label>
      )}
      {values.map(({ translation, value, emojiOnActive }, index) => (
        <label
          data-cy={dataCy}
          key={value}
          htmlFor={value}
          className="review-radio-input__radio"
        >
          <input
            type="radio"
            name={name}
            id={value}
            value={value}
            checked={valueIndex === index}
            onChange={() => {
              onSelection(value);
            }}
          />
          <span className="review-radio-input__radio__checkmark" />
          {valueIndex === index && emojiOnActive && <span style={{ marginRight: 1 }}>{emojiOnActive}</span>}
          {translation}
        </label>
      ))}
      {error && <div className="review-radio-input__error">{error}</div>}
    </div>
  );
}
