import React from 'react';
import Linkify from 'react-linkify';

import './TextWithExternalLink.scss';

const TextWithExternalLink = ({
  text,
  onExternalLinkClick,
}: {
  text: string;
  onExternalLinkClick: (href: string) => void;
}) => {
  const ExternalLink = (decoratedHref: string, decoratedText: string) => (
    <button
      key={decoratedText}
      type="button"
      onClick={() => onExternalLinkClick(decoratedHref)}
      className="link-in-text"
    >
      {decoratedText}
    </button>
  );

  return <Linkify componentDecorator={ExternalLink}>{text}</Linkify>;
};

export default TextWithExternalLink;
