import { defineMessages } from 'react-intl';

export const ExternalLinkOpenModalTranslations = defineMessages({
  externalLinkModalTitle: {
    id: 'ExternalLinkOpenModalTranslations.externalLinkModalTitle',
    defaultMessage: "Ouverture d'un lien externe",
  },
  externalLinkModalSubtitle: {
    id: 'ExternalLinkOpenModalTranslations.externalLinkModaSubtitle',
    defaultMessage:
      'Ce lien ouvre une nouvelle fenêtre et accède à un site web externe qui ne fait pas partie de My Job Glasses. Pour y accéder, sélectionnez <strong>Continuer</strong>',
  },
  externalLinkModalContinueButton: {
    id: 'ExternalLinkOpenModalTranslations.externalLinkModalContinueButton',
    defaultMessage: 'Continuer',
  },
  externalLinkModalCancelButton: {
    id: 'ExternalLinkOpenModalTranslations.externalLinkModalCancelButton',
    defaultMessage: 'Annuler',
  },
});
