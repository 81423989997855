import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    id: 'CompanyAdminDashboardAppointmentRate.label',
    defaultMessage: "Taux d'Ambassadeurs ayant réalisé 1 RDV ou plus",
  },
  tooltip: {
    id: 'CompanyAdminDashboardAppointmentRate.tooltip',
    defaultMessage: "Nombre d'Ambassadeurs publiés et % d'entre eux ayant réalisé au moins 1 RDV sur la période",
  },
  downloadTooltip: {
    id: 'CompanyAdminDashboardAppointmentRate.downloadTooltip',
    defaultMessage: 'Télécharger la liste des rendez-vous',
  },
  subTitle: {
    id: 'CompanyAdminDashboardAppointmentRate.subTitle',
    defaultMessage: 'Calculé sur les {count} Ambassadeurs publiés',
  },
});
