import classNames from 'classnames';
import MessageTextSquareIcon from '../../../icons/component-icons/MessageTextSquareIcon';
import ThumbsUpIcon from '../../../icons/component-icons/ThumbsUpIcon';
import React from 'react';
import { AmbassadorCardTranslations } from './AmbassadorCard.translations';

import './AmbassadorCard.scss';
import { useIntl } from 'react-intl';

type StaticAmbassadorCardProps = {
  bannerUrl?: string | null;
  companyLogoUrl: string;
  companyName: string;
};

export default function StaticAmbassadorCard({ bannerUrl, companyLogoUrl, companyName }: StaticAmbassadorCardProps) {
  const intl = useIntl();
  return (
    <div
      className="ambassador-card ambassador-card--no-hover"
      style={{ pointerEvents: 'none' }}
    >
      <header
        className={classNames('ambassador-card__header', 'ambassador-card__header--premium', {
          'ambassador-card__header--custom': !!bannerUrl,
        })}
      >
        {bannerUrl && (
          <img
            className="ambassador-card__header--custom__banner"
            src={bannerUrl}
          />
        )}
      </header>
      <div className="ambassador-card__content">
        <img
          className="ambassador-card__content__avatar"
          src="https://assets.myjobglasses.com/images/avatar-placeholder.png"
          alt="Ambassador avatar"
        />

        <div className="ambassador-card__content__company-logo">
          <img
            src={companyLogoUrl}
            alt="Ambassador company logo"
          />
        </div>

        <div className="ambassador-card__content__name blurred">Firstname</div>

        <div
          className="ambassador-card__content__company match-blurred-margins"
          data-cy="ambassador-card__company-name"
        >
          {companyName}
        </div>

        <div className="ambassador-card__content__position blurred">current position</div>

        <footer className="ambassador-card__footer">
          <div
            className={classNames(
              'ambassador-card__footer__availability',
              'ambassador-card__footer__availability--premium',
            )}
          >
            <div
              className={classNames(
                'ambassador-card__footer__availability__icon',
                'ambassador-card__footer__availability__icon--premium',
              )}
            >
              <MessageTextSquareIcon />
            </div>

            {intl.formatMessage(AmbassadorCardTranslations.available)}
          </div>

          <div className={classNames('ambassador-card__footer__likes')}>
            6
            <div className="ambassador-card__footer__likes__icon">
              <ThumbsUpIcon />
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
