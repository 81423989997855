/* eslint-disable react/no-danger */
import React from 'react';
import classnames from 'classnames';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import styles from './FormikFieldError.module.scss';

interface FormikFieldErrorProps<K, AsyncErrors extends string> {
  name: keyof K;
  reserveSpace?: boolean;
  asyncFieldErrorTranslations?: ReactIntl.Messages<AsyncErrors>;
  className?: string;
}

function FormikFieldError<T extends { [key: string]: any }, AsyncErrors extends string = ''>({
  name,
  reserveSpace = false,
  asyncFieldErrorTranslations,
  className,
}: FormikFieldErrorProps<T, AsyncErrors>) {
  const intl = useIntl();
  const { errors, touched } = useFormikContext<T>();

  if (!(touched[name] && errors[name])) {
    return reserveSpace ? <div className={styles.reserveSpace} /> : null;
  }

  // @ts-ignore
  const fieldNameErrors: Array<keyof AsyncErrors> | AsyncErrors | string | undefined = errors[name];

  if (asyncFieldErrorTranslations) {
    const translationsMap = new Map<string, FormattedMessage.MessageDescriptor>(
      Object.entries(asyncFieldErrorTranslations),
    );

    if (fieldNameErrors instanceof Array) {
      const fieldFirstError = fieldNameErrors[0];
      if (typeof fieldFirstError === 'string' && translationsMap.has(fieldFirstError)) {
        const asyncTranslations = translationsMap.get(fieldFirstError);
        if (asyncTranslations) {
          return (
            <div
              className={classnames(styles.formikFieldError, className)}
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(asyncTranslations),
              }}
            />
          );
        }
      }
    }

    if (typeof fieldNameErrors === 'string' && translationsMap.has(fieldNameErrors)) {
      const translation = translationsMap.get(fieldNameErrors);
      if (translation) {
        return (
          <div
            className={classnames(styles.formikFieldError, className)}
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(translation),
            }}
          />
        );
      }
    }
  }

  return <div className={classnames(styles.formikFieldError, className)}>{fieldNameErrors}</div>;
}

export default FormikFieldError;
