import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmButton: {
    id: 'WithConfirm.confirmButton',
    defaultMessage: 'Confirmer',
    description: 'Bouton Confirmer',
  },
  cancelButton: {
    id: 'WithConfirm.cancelButton',
    defaultMessage: 'Annuler',
    description: 'Bouton Annuler',
  },
});
