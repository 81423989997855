import { gql } from '@apollo/client';

export const GET_COMPANY_REPORT_DATE_RANGE = gql`
  query GET_COMPANY_REPORT_DATE_RANGE($range: CompanyReportRange!, $companyId: ID!) {
    companyReport(range: $range, companyId: $companyId) {
      companyId
      from
      to
    }
  }
`;
