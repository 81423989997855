import React from 'react';
import { useState, useLayoutEffect, useRef, ChangeEvent, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import useModal from '../../../components/modal/Modal.hook';

import { UploadLogoInputTranslations } from './UploadLogoButton.translations';

import './UploadLogoButton.scss';
import AvatarCropModal from '../avatar-crop-modal/AvatarCropModal';
import { CREATE_UPLOAD_LINK_MUTATION } from './UploadLogoButton.gql';
import {
  Create_Upload_LinkMutation,
  Create_Upload_LinkMutationVariables,
  UploadFormatEnum,
  UploadLinkKindEnum,
} from '../../../@types/graphql.d';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { acceptedImageTypes } from '../../../utils/upload';
import { useIntl } from 'react-intl';

const MAX_FILE_SIZE = 5 * 1024 * 1024;

type UploadLogoButtonProps = {
  onChange: (uploadId: string) => void;
};

export default function UploadLogoButton({ onChange }: UploadLogoButtonProps) {
  const intl = useIntl();
  const [openModal, closeModal] = useModal();
  const inputRef = useRef<HTMLInputElement>(null);
  const [blob, setBlob] = useState<string>();
  const [error, setError] = useState<string | null>(null);

  const [createUploadLink] = useMutation<Create_Upload_LinkMutation, Create_Upload_LinkMutationVariables>(
    CREATE_UPLOAD_LINK_MUTATION,
  );

  useLayoutEffect(() => {
    if (!blob) return;
    openModal({
      title: intl.formatMessage(UploadLogoInputTranslations.modalTitle),
      subtitle: intl.formatMessage(UploadLogoInputTranslations.modalSubtitle),
      content: (
        <AvatarCropModal
          value={blob}
          onCropComplete={handleImageCrop}
        />
      ),
    });
  }, [blob]);

  const onImageUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target?.files?.[0];
      if (!file) return;

      const validImageTypes = acceptedImageTypes();

      if (!validImageTypes.includes(file.type)) {
        setError(intl.formatMessage(UploadLogoInputTranslations.invalidType));
        return;
      }

      if (file.size > MAX_FILE_SIZE) {
        setError(intl.formatMessage(UploadLogoInputTranslations.fileTooLarge));
        return;
      }

      setBlob(URL.createObjectURL(file));
      setError(null);
      event.target.value = '';
    },
    [setBlob],
  );

  async function handleImageCrop(logo: File) {
    const fileType = logo.type.split('/')[1].toUpperCase();
    try {
      const { data } = await createUploadLink({
        variables: {
          kind: UploadLinkKindEnum.CompanyCreationRequestCompanyLogo,
          format: fileType as UploadFormatEnum,
        },
      });

      if (!data?.createUploadLink?.awsUrl) {
        throw new Error(intl.formatMessage(UploadLogoInputTranslations.failedToGetLink));
      }

      await fetch(data.createUploadLink.awsUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': logo.type,
        },
        body: logo,
      });

      onChange(data.createUploadLink.id);
      setError(null);
    } catch (err) {
      console.error('Logo upload failed:', err);
      setError(intl.formatMessage(UploadLogoInputTranslations.uploadFailed));
    }
    closeModal();
  }

  function onUploadClick() {
    inputRef.current?.click();
  }

  return (
    <div className="upload-logo-button">
      <input
        className="upload-logo-button__file-input"
        ref={inputRef}
        type="file"
        accept={acceptedImageTypes().join(', ')}
        onChange={onImageUpload}
        style={{ display: 'none' }}
      />
      <PrimaryButton
        onClick={onUploadClick}
        smaller
        label={intl.formatMessage(UploadLogoInputTranslations.chooseButtom)}
      />
      {error && <p className="upload-logo-button__error">{error}</p>}
    </div>
  );
}
