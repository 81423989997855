import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import translations from './FormikTagField.translations';

import './FormikTagField.scss';
import { useIntl } from 'react-intl';

export type FormikTagFieldProps = {
  field: {
    name: string;
    value: string[];
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };
  label?: string;
  form: {
    touched: { [key: string]: boolean };
    errors: { [key: string]: string };
    submitForm: () => void;
  };
  disabled?: boolean;
  autofocus?: boolean;
  classes?: string;
  style?: { [key: string]: any };
  minLength?: number;
  maxLength?: number;
  maxCount?: number;
  helper?: string;
  tagComponent?: React.FC<{ value: string; onRemove: (value: string) => void }>;
};

const FormikTagField = ({
  field: { name, value = [], onChange },
  form,
  label,
  disabled,
  autofocus,
  classes,
  style,
  minLength = 3,
  maxLength,
  maxCount,
  helper,
  tagComponent: TagComponent = ({ value }) => <div>{value}</div>,
}: FormikTagFieldProps) => {
  const intl = useIntl();
  const inputRef = useRef<HTMLDivElement | null>(null);

  const [fieldValue, setFieldValue] = useState('');

  function arrayRemoveItemByIndex(value, indexOfValue) {
    if (indexOfValue === -1) {
      return value;
    }
    return value.slice(0, indexOfValue).concat(value.slice(indexOfValue + 1));
  }

  function handleChange(e) {
    setFieldValue(e.target.value.slice(0, maxLength));
  }

  function handleSelect(newValue) {
    if (newValue.length < minLength) {
      return;
    }
    const event = {
      target: {
        value: [...(value || []), newValue],
        name: name,
      },
    };
    onChange(event);
    setFieldValue('');
  }

  function handleRemove(oldValue) {
    const index = value.indexOf(oldValue);
    const event = {
      target: {
        value: arrayRemoveItemByIndex(value, index),
        name: name,
      },
    };
    onChange(event);
  }

  function handleKeyDown(e) {
    switch (e.keyCode) {
      case 13:
        e.preventDefault();
        handleSelect(fieldValue);
        break;
      default:
        break;
    }
  }

  return (
    <div className="formik-autocomplete-tag-field-wrapper">
      <div
        className={classNames(classes, {
          'formik-autocomplete-tag-field': true,
          [`formik-autocomplete-tag-field-${name}`]: true,
          'formik-autocomplete-tag-field-disabled': disabled || value.length >= maxCount,
          'formik-autocomplete-tag-field-valid': form?.touched?.[name] && !form?.errors?.[name],
          'formik-autocomplete-tag-field-invalid': form?.touched?.[name] && form?.errors?.[name],
        })}
        ref={inputRef}
      >
        <input
          type="text"
          id={name}
          name={name}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={fieldValue}
          disabled={disabled || value.length >= maxCount}
          autoFocus={autofocus}
          style={style}
          autoComplete="off"
        />
        {label && <label htmlFor={name}>{label}</label>}
      </div>
      {(helper || maxLength) && (
        <div className="formik-autocomplete-tag-field-aside">
          {helper && <div className="formik-autocomplete-tag-field-helper">{helper}</div>}
          {maxLength && (
            <div
              className={classNames('formik-autocomplete-tag-field-max-length', {
                'formik-autocomplete-tag-field-max-length-reached': fieldValue.length === maxLength,
              })}
            >
              {intl.formatMessage(translations.maxChars, {
                current: fieldValue.length,
                max: maxLength,
              })}
            </div>
          )}
        </div>
      )}
      <div className="formik-autocomplete-tag-field-tags">
        {value.map((item) => (
          <TagComponent
            key={item}
            value={item}
            onRemove={handleRemove}
          />
        ))}
      </div>
    </div>
  );
};

export default FormikTagField;
