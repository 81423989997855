import React from 'react';
import { Link } from 'react-router';
import Routes from '../../../../../routes';
import NoWishIllustration from '../../icons/NoWishIllustration';
import translations from './NoWish.translations';

import styles from './NoWish.module.scss';
import { useIntl } from 'react-intl';

function NoWish() {
  const intl = useIntl();
  return (
    <div className={styles.wishlistNoResult}>
      <h2 className={styles.title}>{intl.formatMessage(translations.title)}</h2>
      <NoWishIllustration className={styles.illustration} />
      <Link
        to={Routes.search}
        className={styles.button}
      >
        {intl.formatMessage(translations.viewProfileButton)}
      </Link>
    </div>
  );
}

export default NoWish;
