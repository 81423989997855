import { defineMessages } from 'react-intl';
import { GamificationScoreNameEnum } from '../@types/graphql.d';

export const GamificationScoreNameEnunTranslations = defineMessages<string>({
  [GamificationScoreNameEnum.Views]: {
    id: 'GamificationScoreNameEnun.Views',
    defaultMessage: 'Vues du profil',
  },
  [GamificationScoreNameEnum.Appointments]: {
    id: 'GamificationScoreNameEnun.Appointments',
    defaultMessage: 'RDV individuels',
  },
  [GamificationScoreNameEnum.Replies]: {
    id: 'GamificationScoreNameEnun.Replies',
    defaultMessage: 'Réponses',
  },
  [GamificationScoreNameEnum.Bonus]: {
    id: 'GamificationScoreNameEnun.Bonus',
    defaultMessage: 'Bonus',
  },
});
