import React, { memo, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { SectionCard } from '../../components/section-card/SectionCard';
import { useConnectCompanyContext } from '../../ConnectCompanyContext';
import { useQuery } from '@apollo/client';
import {
  GetCompanyRecruitingWorkingAreasQuery,
  GetCompanyRecruitingWorkingAreasQueryVariables,
} from '../../../../../../../@types/graphql.d';
import PrimaryButton from '../../../../../../../components/primary-button/PrimaryButton';
import { GET_COMPANY_RECRUITING_WORKING_AREAS_QUERY } from './RecruitingWorkingAreas.gql';
import RecruitingWorkingAreasUpdate from './recruiting-working-areas-update/RecruitingWorkingAreasUpdate';
import './RecruitingWorkingAreas.scss';

const translations = defineMessages({
  title: {
    id: 'ConnectCompany.RecruitingWorkingAreas.title',
    defaultMessage: '💼 Liste des domaines employables',
  },
  subtitle: {
    id: 'ConnectCompany.RecruitingWorkingAreas.subtitle',
    defaultMessage:
      'Ceci vous permet de promouvoir les domaines dans lesquels vous recherchez des profils. Après une rencontre entre l’un de vos collaborateurs et un talent, ce dernier pourra manifester son intérêt pour un ou plusieurs domaines d’emplois renseignés.',
  },
  edit: {
    id: 'ConnectCompany.RecruitingWorkingAreas.edit',
    defaultMessage: 'Modifier',
  },
});

export const RecruitingWorkingAreas = memo(() => {
  const intl = useIntl();
  const { id } = useConnectCompanyContext();
  const [isEditing, setIsEditing] = useState(false);

  const { data } = useQuery<GetCompanyRecruitingWorkingAreasQuery, GetCompanyRecruitingWorkingAreasQueryVariables>(
    GET_COMPANY_RECRUITING_WORKING_AREAS_QUERY,
    {
      variables: {
        companyId: id || '',
      },
      fetchPolicy: 'network-only',
    },
  );

  const [workingAreas, setWorkingAreas] = useState<string[]>([]);
  useEffect(() => {
    if (data?.company?.recruitingWorkingAreas) {
      setWorkingAreas(data.company.recruitingWorkingAreas);
    }
  }, [data?.company?.recruitingWorkingAreas]);
  const workingAreasList = workingAreas.length ? workingAreas : data?.company?.recruitingWorkingAreas || [];

  if (isEditing) {
    return (
      <SectionCard
        title={intl.formatMessage(translations.title)}
        subtitle={intl.formatMessage(translations.subtitle)}
      >
        <RecruitingWorkingAreasUpdate
          companyId={id}
          workingAreas={workingAreasList}
          setWorkingAreas={setWorkingAreas}
          finish={() => setIsEditing(false)}
        />
      </SectionCard>
    );
  }

  return (
    <SectionCard
      title={intl.formatMessage(translations.title)}
      subtitle={intl.formatMessage(translations.subtitle)}
    >
      <div className="recruiting-areas">
        {workingAreasList.length !== 0 && (
          <div className="recruiting-areas__list">
            {workingAreasList.map((workingArea) => (
              <span
                key={workingArea}
                className="recruiting-areas__list__item"
              >
                {workingArea}
              </span>
            ))}
          </div>
        )}
        <div className="recruiting-areas__btn">
          <PrimaryButton
            label={intl.formatMessage(translations.edit)}
            onClick={() => setIsEditing(true)}
            smaller
          />
        </div>
      </div>
    </SectionCard>
  );
});
