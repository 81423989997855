import React from 'react';
import { IconProps } from './Icon';

export default function PolygonIcon({ className }: IconProps) {
  return (
    <svg
      width="200"
      height="316"
      viewBox="0 0 200 316"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M149.779 12.882C164.724 -9.51 199.646 1.36556 199.234 28.2834L195.248 288.764C194.973 306.794 177.44 319.491 160.223 314.13L19.5926 270.334C2.37646 264.972 -4.84679 244.565 5.16304 229.566L149.779 12.882Z"
        fill="#FF3460"
      />
    </svg>
  );
}
