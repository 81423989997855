import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import classNames from 'classnames';
import CheckboxInput from '../../../../../components/checkbox-input/CheckboxInput';
import { PageLoader } from '../../../../../components/loader/Loader';
import TextInput from '../../../../../components/text-input/TextInput';
import { CurrentUserContext } from '../../../../../contexts/current-user-context/CurrentUserContext';
import useDebounce from '../../../../../hooks/debounce.hook';
import FileIcon from '../../../../../icons/component-icons/FileIcon';
import PlusIcon from '../../../../../icons/component-icons/PlusIcon';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from 'react-router';
import {
  Approve_EmployeeMutation,
  Approve_EmployeeMutationVariables,
  Archive_EmployeeMutation,
  Archive_EmployeeMutationVariables,
  CompanyEmployeesQueryFiltersCustomAttributeInput,
  CompanyEmployeesQueryFiltersHrTableStatusEnum,
  CompanyEmployeesQuerySortEnum,
  GetCompanyCustomAttributesQuery,
  GetCompanyCustomAttributesQueryVariables,
  GetEmployeeListQuery,
  GetEmployeeListQueryVariables,
  PublishEmployeeMutation,
  PublishEmployeeMutationVariables,
  SendEmployeeInvitationEmailMutation,
  SendEmployeeInvitationEmailMutationVariables,
  UnpublishEmployeeMutation,
  UnpublishEmployeeMutationVariables,
} from '../../../../../@types/graphql.d';
import CompanyContext from '../../context/CompanyContext';
import CategorizedMultiSelectInput from './categorized-multi-select-input/CategorizedMultiSelectInput';
import EmployeeRow from './employee-row/EmployeeRow';
import useModal from '../../../../../components/modal/Modal.hook';
import AddEmployeeModal from '../add-employee-modal/AddEmployeeModal';
import ImportEmployeesModal from '../import-employees-modal/ImportEmployeesModal';
import {
  APPROVE_EMPLOYEE_MUTATION,
  ARCHIVE_EMPLOYEE_MUTATION,
  Employee,
  GET_EMPLOYEE_LIST_QUERY,
  PUBLISH_EMPLOYEE_MUTATION,
  SEND_EMPLOYEE_INVITATION_EMAIL_MUTATION,
  UNPUBLISH_EMPLOYEE_MUTATION,
  GET_COMPANY_CUSTOM_ATTRIBUTES_QUERY,
} from './EmployeeList.gql';
import AddEmployeeModalTranslations from '../add-employee-modal/AddEmployeeModal.translations';
import ImportEmployeesModalTranslations from '../import-employees-modal/ImportEmployeesModal.translations';
import { EmployeeListTranslations } from './EmployeeList.translations';
import SortButtons from './sort-buttons/SortButtons';
import { useIntl } from 'react-intl';
import CheckCircleIcon from '../../../../../icons/component-icons/CheckCircleIcon';
import RefreshIcon from '../../../../../icons/component-icons/RefreshIcon';
import ArchiveIcon from '../../../../../icons/component-icons/ArchiveIcon';
import ArrowCircleBrokenUpIcon from '../../../../../icons/component-icons/ArrowCircleBrokenUpIcon';
import ArrowCircleBrokenDownIcon from '../../../../../icons/component-icons/ArrowCircleBrokenDownIcon';
import CompanyAttributeHub from '../../../configuration/components/company-attributes/CompanyAttributeHub';

import './EmployeeList.scss';

export default function EmployeeList() {
  const intl = useIntl();
  const companyContext = useContext(CompanyContext);
  if (!companyContext.companyid) return null;
  const userContext = useContext(CurrentUserContext);
  if (!userContext.currentUser) return null;

  const navigate = useNavigate();
  const location = useLocation();
  const [openModal] = useModal();
  const params = useMemo(() => new URLSearchParams(location.search), [location]);
  const { enqueueSnackbar } = useSnackbar();

  const [archiveTab, setArchiveTab] = useState(params.get('archived') === 'true');
  const [q, setQ] = useState(params.get('q') || '');
  const [debouncedQ] = useDebounce(q);
  const [sortedBy, setSortedBy] = useState(
    (params.get('sorted_by') as CompanyEmployeesQuerySortEnum) || CompanyEmployeesQuerySortEnum.FirstNameAsc
  );
  const [cursors, setCursors] = useState<string[]>([]);
  const lastCursor: string | undefined = useMemo(() => cursors[cursors.length - 1], [cursors]);
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
  const [refetchButtonVisible, setRefetchButtonVisible] = useState(false);
  const [hrTableStatusIn, setHRTableStatusIn] = useState<CompanyEmployeesQueryFiltersHrTableStatusEnum[]>(
    params.has('hr_table_status_in_filters') ? JSON.parse(params.get('hr_table_status_in_filters')!) : []
  );
  const [companyAttributeIn, setCompanyAttributeIn] = useState<CompanyEmployeesQueryFiltersCustomAttributeInput[]>(
    params.has('company_attribute_in_filters') ? JSON.parse(params.get('company_attribute_in_filters')!) : []
  );

  useEffect(() => {
    if (debouncedQ.length === 0) params.delete('q');
    else params.set('q', debouncedQ);
    navigate(`${location.pathname}?${params.toString()}`);
    setCursors([]);
  }, [debouncedQ]);

  useEffect(() => {
    if (archiveTab) params.set('archived', archiveTab ? 'true' : 'false');
    else params.delete('archived');
    navigate(`${location.pathname}?${params.toString()}`);
    setCursors([]);
  }, [archiveTab]);

  useEffect(() => {
    if (hrTableStatusIn.length === 0) params.delete('hr_table_status_in_filters');
    else params.set('hr_table_status_in_filters', JSON.stringify(hrTableStatusIn));
    navigate(`${location.pathname}?${params.toString()}`);
    setCursors([]);
  }, [hrTableStatusIn]);

  useEffect(() => {
    if (companyAttributeIn.length === 0) params.delete('company_attribute_in_filters');
    else params.set('company_attribute_in_filters', JSON.stringify(companyAttributeIn));
    navigate(`${location.pathname}?${params.toString()}`);
    setCursors([]);
  }, [companyAttributeIn]);

  // QUERY
  const { data, loading, refetch } = useQuery<GetEmployeeListQuery, GetEmployeeListQueryVariables>(
    GET_EMPLOYEE_LIST_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        companyId: companyContext.companyid,
        after: lastCursor,
        sort: [sortedBy],
        filters: {
          q: debouncedQ,
          archivedEq: archiveTab,
          hrTableStatusIn: hrTableStatusIn.length > 0 ? hrTableStatusIn : undefined,
          companyAttributeIn: companyAttributeIn.length > 0 ? companyAttributeIn : undefined,
        },
      },
    }
  );

  const inPageEveryEmployeesSelected = useMemo(
    () =>
      data?.companyEmployees?.nodes &&
      data.companyEmployees.nodes.length > 0 &&
      data?.companyEmployees?.nodes.every((employee) => selectedEmployees.includes(employee)),
    [data?.companyEmployees?.nodes, selectedEmployees]
  );

  const inPageSomeEmployeesSelected = useMemo(
    () =>
      data?.companyEmployees?.nodes &&
      data.companyEmployees.nodes.length > 0 &&
      data?.companyEmployees?.nodes.some((employee) => selectedEmployees.includes(employee)),
    [data?.companyEmployees?.nodes, selectedEmployees]
  );

  const handleSelectAllInput = useCallback(() => {
    if (inPageSomeEmployeesSelected || inPageEveryEmployeesSelected) {
      setSelectedEmployees([]);
    } else {
      const employees = data?.companyEmployees?.nodes;
      if (employees) setSelectedEmployees((prev) => [...prev, ...employees]);
    }
  }, [inPageEveryEmployeesSelected, inPageSomeEmployeesSelected, data?.companyEmployees?.nodes]);

  // MUTATIONS
  const [unpublishMutation] = useMutation<UnpublishEmployeeMutation, UnpublishEmployeeMutationVariables>(
    UNPUBLISH_EMPLOYEE_MUTATION,
    { refetchQueries: ['GetEmployeeList'] }
  );
  const [publishMutation] = useMutation<PublishEmployeeMutation, PublishEmployeeMutationVariables>(
    PUBLISH_EMPLOYEE_MUTATION,
    { refetchQueries: ['GetEmployeeList'] }
  );
  const [approveMutation] = useMutation<Approve_EmployeeMutation, Approve_EmployeeMutationVariables>(
    APPROVE_EMPLOYEE_MUTATION,
    { refetchQueries: ['GetEmployeeList'] }
  );

  const [archiveMutation] = useMutation<Archive_EmployeeMutation, Archive_EmployeeMutationVariables>(
    ARCHIVE_EMPLOYEE_MUTATION,
    { refetchQueries: ['GetEmployeeList'] }
  );
  const [sendEmployeeInvitationEmailMutation] = useMutation<
    SendEmployeeInvitationEmailMutation,
    SendEmployeeInvitationEmailMutationVariables
  >(SEND_EMPLOYEE_INVITATION_EMAIL_MUTATION, {
    refetchQueries: ['GetEmployeeList'],
  });

  const onSortChange = useCallback(
    (s: CompanyEmployeesQuerySortEnum) => {
      setSortedBy(s);
      params.set('sorted_by', s);
      navigate(`${location.pathname}?${params.toString()}`);
    },
    [sortedBy]
  );

  const unpublishEmployees = useCallback(async () => {
    await Promise.all(
      selectedEmployees.map(async ({ userId }) => await unpublishMutation({ variables: { userId: userId || '' } }))
    );
    enqueueSnackbar(
      intl.formatMessage(EmployeeListTranslations.successfullyUnpublished, {
        count: selectedEmployees.length,
      }),
      { variant: 'success', autoHideDuration: 2500 }
    );
    setSelectedEmployees([]);
  }, [selectedEmployees]);

  const publishEmployees = useCallback(async () => {
    await Promise.all(
      selectedEmployees.map(async ({ userId }) => await publishMutation({ variables: { userId: userId || '' } }))
    );
    enqueueSnackbar(
      intl.formatMessage(EmployeeListTranslations.successfullyPublished, {
        count: selectedEmployees.length,
      }),
      { variant: 'success', autoHideDuration: 2500 }
    );
    setSelectedEmployees([]);
  }, [selectedEmployees]);

  const approveEmployees = useCallback(async () => {
    await Promise.all(
      selectedEmployees.map(async ({ id }) => await approveMutation({ variables: { employeeId: id } }))
    );
    enqueueSnackbar(
      intl.formatMessage(EmployeeListTranslations.successfullyApproved, {
        count: selectedEmployees.length,
      }),
      { variant: 'success', autoHideDuration: 2500 }
    );
    setSelectedEmployees([]);
  }, [selectedEmployees]);

  const archiveEmployees = useCallback(async () => {
    await Promise.all(
      selectedEmployees.map(async ({ id }) => await archiveMutation({ variables: { employeeId: id } }))
    );
    enqueueSnackbar(
      intl.formatMessage(EmployeeListTranslations.successfullyArchived, {
        count: selectedEmployees.length,
      }),
      { variant: 'success', autoHideDuration: 2500 }
    );
    setSelectedEmployees([]);
  }, [selectedEmployees]);

  const resendEmployeesInvitations = useCallback(async () => {
    await Promise.all(
      selectedEmployees.map(
        async ({ id }) =>
          await sendEmployeeInvitationEmailMutation({
            variables: { employeeId: id },
          })
      )
    );
    enqueueSnackbar(
      intl.formatMessage(EmployeeListTranslations.successfullyResent, {
        count: selectedEmployees.length,
      }),
      { variant: 'success', autoHideDuration: 2500 }
    );
    setSelectedEmployees([]);
  }, [selectedEmployees]);

  const { data: dataCustomAttributes } = useQuery<
    GetCompanyCustomAttributesQuery,
    GetCompanyCustomAttributesQueryVariables
  >(GET_COMPANY_CUSTOM_ATTRIBUTES_QUERY, {
    skip: !companyContext.companyid,
    variables: {
      companyId: companyContext.companyid,
    },
  });

  const openAddEmployeeModal = () => {
    openModal({
      title: intl.formatMessage(AddEmployeeModalTranslations.title),
      content: <AddEmployeeModal companyid={companyContext.companyid || ''} />,
    });
  };

  const openImportEmployeesModal = () => {
    openModal({
      title: intl.formatMessage(ImportEmployeesModalTranslations.title),
      content: <ImportEmployeesModal companyid={companyContext.companyid || ''} />,
    });
  };

  return (
    <div className="ambassadors-view">
      <div className="ambassadors-view__employee-list">
        <div className="ambassadors-view__employee-list__header">
          <div className="ambassadors-view__employee-list__header__tabs">
            <button
              className={classNames('ambassadors-view__employee-list__header__tabs__button', {
                'ambassadors-view__employee-list__header__tabs__button--active': !archiveTab,
              })}
              onClick={() => setArchiveTab(false)}
            >
              {intl.formatMessage(EmployeeListTranslations.myEmployees)}
            </button>
            <div className="ambassadors-view__employee-list__header__tabs__separator" />
            <button
              className={classNames('ambassadors-view__employee-list__header__tabs__button', {
                'ambassadors-view__employee-list__header__tabs__button--active': archiveTab,
              })}
              onClick={() => setArchiveTab(true)}
            >
              {intl.formatMessage(EmployeeListTranslations.archivedEmployees)}
            </button>
          </div>
          <div className="ambassadors-view__employee-list__header__global-actions">
            <button onClick={openImportEmployeesModal}>
              <FileIcon />
            </button>
            <button onClick={openAddEmployeeModal}>
              <PlusIcon />
            </button>
          </div>
        </div>

        <div className="ambassadors-view__employee-list__actions-header">
          <div className="ambassadors-view__employee-list__actions-header__selected-count">
            <CheckboxInput
              name="select-all"
              label={`(${selectedEmployees.length})`}
              checked={inPageEveryEmployeesSelected}
              onChange={handleSelectAllInput}
              semiChecked={inPageSomeEmployeesSelected}
            />
          </div>

          <TextInput
            placeholder="Nom ou prénom ou email"
            value={q}
            onChange={(e) => setQ(e.target.value)}
            className="ambassadors-view__employee-list__actions-header__input"
          />

          <button
            className="ambassadors-view__employee-list__actions-header__action"
            onClick={resendEmployeesInvitations}
            disabled={
              selectedEmployees.length === 0 ||
              selectedEmployees.some(({ canReceiveInvitationReminder }) => !canReceiveInvitationReminder)
            }
            data-tooltip={intl.formatMessage(EmployeeListTranslations.resendAction)}
          >
            <RefreshIcon className="ambassadors-view__employee-list__actions-header__action__icon" />
          </button>
          <div className="ambassadors-view__employee-list__actions-header__separator" />
          <button
            className="ambassadors-view__employee-list__actions-header__action"
            onClick={approveEmployees}
            disabled={selectedEmployees.length === 0 || selectedEmployees.some(({ canApprove }) => !canApprove)}
            data-tooltip={intl.formatMessage(EmployeeListTranslations.approveAction)}
          >
            <CheckCircleIcon className="ambassadors-view__employee-list__actions-header__action__icon" />
          </button>
          <div className="ambassadors-view__employee-list__actions-header__separator" />
          <button
            className="ambassadors-view__employee-list__actions-header__action"
            onClick={publishEmployees}
            disabled={selectedEmployees.length === 0 || selectedEmployees.some(({ canPublish }) => !canPublish)}
            data-tooltip={intl.formatMessage(EmployeeListTranslations.publishAction)}
          >
            <ArrowCircleBrokenUpIcon className="ambassadors-view__employee-list__actions-header__action__icon" />
          </button>
          <button
            className="ambassadors-view__employee-list__actions-header__action"
            onClick={unpublishEmployees}
            disabled={selectedEmployees.length === 0 || selectedEmployees.some(({ canUnpublish }) => !canUnpublish)}
            data-tooltip={intl.formatMessage(EmployeeListTranslations.unpublishAction)}
          >
            <ArrowCircleBrokenDownIcon className="ambassadors-view__employee-list__actions-header__action__icon" />
          </button>
          <div className="ambassadors-view__employee-list__actions-header__separator" />
          <button
            className="ambassadors-view__employee-list__actions-header__action"
            onClick={archiveEmployees}
            disabled={selectedEmployees.length === 0 || selectedEmployees.some(({ canArchive }) => !canArchive)}
            data-tooltip={intl.formatMessage(EmployeeListTranslations.archiveAction)}
          >
            <ArchiveIcon className="ambassadors-view__employee-list__actions-header__action__icon" />
          </button>
          <div className="ambassadors-view__employee-list__actions-header__separator" />

          <CategorizedMultiSelectInput
            className="ambassadors-view__employee-list__actions-header__input"
            name="filter"
            placeholder="Filtres"
            attributesSet={data?.company?.customAttributesSet}
            hrTableStatusFilters={hrTableStatusIn}
            companyAttributeFilters={companyAttributeIn}
            onHrTableStatusChange={setHRTableStatusIn}
            onCompanyAttributeChange={setCompanyAttributeIn}
          />

          {refetchButtonVisible && (
            <>
              <div className="ambassadors-view__employee-list__actions-header__separator" />
              <button
                className="ambassadors-view__employee-list__actions-header__action"
                onClick={() => {
                  refetch();
                  setRefetchButtonVisible(false);
                }}
              >
                <RefreshIcon className="ambassadors-view__employee-list__actions-header__action__icon" />
                Mettre à jour les informations
              </button>
            </>
          )}
        </div>

        {loading ? (
          <PageLoader />
        ) : (
          <table className="ambassadors-view__employee-list__employees">
            <SortButtons
              customAttributesSet={data?.company?.customAttributesSet}
              sortedBy={sortedBy}
              onSortChange={onSortChange}
            />

            <tbody>
              {data?.companyEmployees?.nodes.map((employee) => (
                <EmployeeRow
                  key={employee.id}
                  employee={employee}
                  isSelected={selectedEmployees.includes(employee)}
                  onSelect={() => {
                    if (selectedEmployees.includes(employee)) {
                      setSelectedEmployees((p) => p.filter((v) => v !== employee));
                    } else {
                      setSelectedEmployees((p) => [...p, employee]);
                    }
                  }}
                  onEditionClick={() => setRefetchButtonVisible(true)}
                />
              ))}
            </tbody>
          </table>
        )}

        <div className="ambassadors-view__employee-list__pagination">
          <button
            className="ambassadors-view__employee-list__pagination__button"
            onClick={() => {
              setCursors((c) => {
                const copy = [...c];
                copy.pop();
                return copy;
              });
            }}
            disabled={!data?.companyEmployees?.pageInfo.hasPreviousPage}
          >
            {intl.formatMessage(EmployeeListTranslations.previous)}
          </button>
          <button
            className="ambassadors-view__employee-list__pagination__button"
            onClick={() => {
              setCursors((c) => [...c, data?.companyEmployees?.pageInfo?.endCursor || '']);
            }}
            disabled={!data?.companyEmployees?.pageInfo.hasNextPage}
          >
            {intl.formatMessage(EmployeeListTranslations.next)}
          </button>
        </div>
      </div>
      {!dataCustomAttributes?.company?.isPremiumSchool && (
        <div className="ambassadors-view__custom-attributes">
          <CompanyAttributeHub number={1} />
          <CompanyAttributeHub
            number={2}
            disabled={!dataCustomAttributes?.company?.customAttributesSet.customEmployeeAttribute1}
          />
          <CompanyAttributeHub
            number={3}
            disabled={!dataCustomAttributes?.company?.customAttributesSet.customEmployeeAttribute2}
          />
        </div>
      )}
    </div>
  );
}
