import React, { useContext } from 'react';
import RadioInput from '../../../../radio-input/RadioInput';
import { Field, Formik } from 'formik';
import { getAccountUrl } from '../../../../../utils/redirection.util';
import { scrollToTopOfPage } from '../../../../../utils/scroll-to-top';
import { useIntl } from 'react-intl';
import buttonCommonTranslations from '../../../../../scenes/common/common-translations/button.translations';
import FormikTextareaField from '../../../../../scenes/common/formik/components/formik-textarea-field/FormikTextareaField';
import { useSoftDeletionAnswerByProfileType } from './SoftDeletionReasonForm.hooks';
import translations from './SoftDeletionReasonForm.translations';
import { SoftDeletionReasonFormValidationSchema } from './SoftDeletionReasonForm.validation';
import useModal from '../../../../../components/modal/Modal.hook';
import ButtonsGroup from '../../../../../components/buttons-group/ButtonsGroup';
import PrimaryButton from '../../../../../components/primary-button/PrimaryButton';
import SecondaryButton from '../../../../../components/secondary-button/SecondaryButton';
import { useMutation } from '@apollo/client';
import { SOFT_DELETE_MUTATION } from './SoftDeletionReasonForm.gql';
import { SoftDeleteMutation, SoftDeleteMutationVariables } from '../../../../../@types/graphql.d';
import { CurrentUserContext } from '../../../../../contexts/current-user-context/CurrentUserContext';
import SoftDeletionNotAllowedUnknownReason from '../soft-deletion-not-allowed/components/soft-deletion-not-allowed-unknown-reason/SoftDeletionNotAllowedUnknownReason';

interface FormValues {
  reason: string;
  reason_text: string;
}

function SoftDeletionReasonForm(): JSX.Element {
  const intl = useIntl();
  const [openModal, closeModal] = useModal();
  const { currentUser } = useContext(CurrentUserContext);
  const isCurrentUserProfessional = currentUser?.isAmbassador === true;
  const options = useSoftDeletionAnswerByProfileType(isCurrentUserProfessional).map((option) => ({
    ...option,
    translation: intl.formatMessage(option.label),
  }));

  const [softDelete] = useMutation<SoftDeleteMutation, SoftDeleteMutationVariables>(SOFT_DELETE_MUTATION);

  function onSubmit(values: FormValues) {
    const reason = values.reason === 'other' ? values.reason_text : values.reason;
    if (!currentUser?.id) return;
    softDelete({ variables: { reason, userId: currentUser.id } })
      .then(() => {
        scrollToTopOfPage();
        window.location.href = getAccountUrl('/signout');
      })
      .catch(() => {
        openModal({
          title: intl.formatMessage(translations.errorModalTitle),
          content: <SoftDeletionNotAllowedUnknownReason />,
        });
      });
  }
  return (
    <Formik
      initialValues={{
        reason: '',
        reason_text: '',
      }}
      onSubmit={onSubmit}
      validationSchema={SoftDeletionReasonFormValidationSchema(intl)}
      validateOnMount
    >
      {({ handleSubmit, setFieldValue, values, isSubmitting, isValid }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="reason"
            component={RadioInput}
            onSelection={(value: string) => setFieldValue('reason', value)}
            values={options}
          />
          <Field
            name="reason_text"
            component={FormikTextareaField}
            disabled={values.reason !== 'other'}
            label={intl.formatMessage(translations.textareaLabel)}
          />
          <ButtonsGroup>
            <SecondaryButton
              label={intl.formatMessage(buttonCommonTranslations.cancel)}
              onClick={closeModal}
            />
            <PrimaryButton
              label={intl.formatMessage(buttonCommonTranslations.submit)}
              onClick={handleSubmit}
              disabled={isSubmitting || !isValid}
            />
          </ButtonsGroup>
        </form>
      )}
    </Formik>
  );
}

export default SoftDeletionReasonForm;
