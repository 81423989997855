import { gql } from '@apollo/client';

export const GET_CONVERSATION_PRO_PROFILE = gql`
  query GET_CONVERSATION_PRO_PROFILE($conversationId: ID!) {
    conversation(conversationId: $conversationId) {
      id
      professional {
        id
        status
        unavailableUntil
        meetingsLeft
      }
    }
  }
`;
