import { defineMessages } from 'react-intl';

export default defineMessages({
  validateTextRequired: {
    id: 'SendMessage.validateTextRequired',
    defaultMessage: 'Requis',
    description: 'Formulaire d envoi d un message',
  },
  replyTo: {
    id: 'SendMessageForm.replyTo',
    defaultMessage: 'Répondre à {recipientName}',
    description: "Formulaire d'envoi de messages",
  },
});
