import { memo, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useParams, useSearchParams } from 'react-router';

import { useSnackbar } from 'notistack';

const SNACKBAR_INTENT_KEY = 'snackbar-intent';

const TRANSLATIONS = defineMessages({
  signedUpAsMember: {
    id: 'LandingSnackbar.signedUpAsMember',
    defaultMessage: 'Votre compte Membre est complet. Maintenant, à vous de jouer !',
  },
  signedUpAsAmbassador: {
    id: 'LandingSnackbar.signedUpAsAmbassador',
    defaultMessage:
      'Votre profil Ambassadeur est complet. Il est désormais en modération. Vous recevrez un email dès qu’il sera publié par notre support.',
  },
});

export const useLandingSnackbar = () => {
  const { formatMessage } = useIntl();
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.has(SNACKBAR_INTENT_KEY)) return;

    switch (searchParams.get(SNACKBAR_INTENT_KEY)) {
      case 'signed_up_as_member':
        snackbar(formatMessage(TRANSLATIONS.signedUpAsMember), { variant: 'success' });
        break;
      case 'signed_up_as_ambassador':
        snackbar(formatMessage(TRANSLATIONS.signedUpAsAmbassador), { variant: 'success' });
        break;
    }

    setSearchParams((sb) => {
      sb.delete(SNACKBAR_INTENT_KEY);
      return sb;
    });
  }, []);
};

export const LandingSnackbarCaller = memo(() => {
  useLandingSnackbar();
  return null;
});
