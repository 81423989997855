import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { AmbassadorGalleryQuery, AmbassadorGalleryQueryVariables } from '../../../../../@types/graphql.d';
import Card, { CardLoader } from '../../../../../components/card/Card';
import { GALLERY_QUERY } from './Gallery.gql';
import './Gallery.scss';
import { AmbassadorViewGalleryTranslations } from './Gallery.translations';
import PhotoIcon from '../../../../../icons/component-icons/PhotoIcon';
import XCloseIcon from '../../../../../icons/component-icons/XCloseIcon';
import Skeleton from 'react-loading-skeleton';
import { useIntl } from 'react-intl';

type GalleryProps = {
  id: string;
};

export default function Gallery({ id }: GalleryProps) {
  const intl = useIntl();
  const { data, loading } = useQuery<AmbassadorGalleryQuery, AmbassadorGalleryQueryVariables>(GALLERY_QUERY, {
    variables: { id },
    fetchPolicy: 'network-only',
  });

  const [selectedImageUrl, setSelectedImageUrl] = useState<string>();

  if (loading) return <GalleryLoader />;
  if (!data?.getUser) return null;

  const { galleryImages } = data.getUser;

  if (!galleryImages.length) return null;

  return (
    <section className="ambassador-view-gallery">
      <Card
        icon={PhotoIcon}
        title={intl.formatMessage(AmbassadorViewGalleryTranslations.title)}
      >
        <div className="ambassador-view-gallery__grid">
          {galleryImages.map(({ imageUrl }, key) => (
            <img
              key={key}
              src={imageUrl}
              onClick={() => setSelectedImageUrl(imageUrl)}
            />
          ))}
        </div>
      </Card>

      {selectedImageUrl && (
        <div className="ambassador-view-gallery__previewed-image">
          <div className="ambassador-view-gallery__previewed-image__container">
            <button
              className="ambassador-view-gallery__previewed-image__container__close-button"
              onClick={() => setSelectedImageUrl(undefined)}
            >
              <XCloseIcon />
            </button>
            <img src={selectedImageUrl} />
          </div>
        </div>
      )}
    </section>
  );
}

const GalleryLoader = () => (
  <section className="ambassador-view-gallery">
    <CardLoader>
      <div className="ambassador-view-gallery__grid">
        <Skeleton
          width={128}
          height={128}
          borderRadius={8}
        />
        <Skeleton
          width={128}
          height={128}
          borderRadius={8}
        />
        <Skeleton
          width={128}
          height={128}
          borderRadius={8}
        />
        <Skeleton
          width={128}
          height={128}
          borderRadius={8}
        />
        <Skeleton
          width={128}
          height={128}
          borderRadius={8}
        />
      </div>
    </CardLoader>
  </section>
);
