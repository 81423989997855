import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { PublishMutation, PublishMutationVariables } from '../../../../../../../@types/graphql.d';
import translations from './usePublishAction.translations';
import { PUBLISH_EMPLOYEE } from './usePublishAction.gql';
import { useIntl } from 'react-intl';

export default function usePublishAction(userId: string) {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  // handle publish employee mutation
  const [publish] = useMutation<PublishMutation, PublishMutationVariables>(PUBLISH_EMPLOYEE, {
    refetchQueries: ['GET_ALLOWED_EMPLOYEE_ACTIONS_AND_STATUS'],
  });

  // submit function
  function publishEmployees() {
    return publish({ variables: { userId } })
      .then(() => {
        enqueueSnackbar(intl.formatMessage(translations.success), {
          variant: 'success',
          autoHideDuration: 10000,
        });
      })
      .catch(() => {
        enqueueSnackbar(intl.formatMessage(translations.oneProfileCannotPublished), {
          variant: 'error',
          autoHideDuration: 10000,
        });
      });
  }
  return [publishEmployees];
}
