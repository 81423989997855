import React, { useRef, useState, useEffect } from 'react';
import classnames from 'classnames';
import moment from 'moment-timezone';

import styles from './TimePicker.module.scss';

interface TimePickerProps {
  value: moment.Moment;
  onChange: (newtime: moment.Moment) => void;
  maxDate?: moment.Moment;
}

function TimePicker({ value, onChange, maxDate }: TimePickerProps) {
  // proccessed values
  const [currentHour, setCurrentHour] = useState(9);
  const [currentMinute, setCurrentMinute] = useState(0);
  useEffect(() => {
    if (value) {
      setCurrentHour(+moment(value).format('HH'));
      setCurrentMinute(+moment(value).format('mm'));
    }
  }, [value]);

  // handle autoscroll to current value
  const currentHourRef = useRef<HTMLButtonElement>(null);
  const currentMinuteRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    currentHourRef.current?.scrollIntoView?.();
    currentMinuteRef.current?.scrollIntoView?.();
  }, [currentHour, currentMinute]);

  function handleChange(hour?: number, minute?: number) {
    onChange(
      moment({
        h: typeof hour === 'number' ? hour : currentHour,
        m: typeof minute === 'number' ? minute : currentMinute,
      }),
    );
  }

  return (
    <div className={styles.timepicker}>
      <div className={styles.dropdown}>
        <ul className={styles.list}>
          {Array.from({ length: 24 }).map((_, key) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`hour${key}`}>
              <button
                type="button"
                className={classnames(styles.listItem, {
                  [styles.current]: currentHour === key,
                })}
                ref={currentHour === key ? currentHourRef : undefined}
                onClick={() => handleChange(key)}
                data-cy={key === 0 && 'midnight'}
                disabled={!!maxDate && value.dayOfYear() === maxDate.dayOfYear() && key > maxDate.hour()}
              >
                {`0${key}`.slice(-2)}
              </button>
            </li>
          ))}
        </ul>
        <ul className={styles.list}>
          {[0, 15, 30, 45].map((item) => (
            <li key={`minutes${item}`}>
              <button
                type="button"
                className={classnames(styles.listItem, {
                  [styles.current]: currentMinute === item,
                })}
                ref={currentMinute === item ? currentMinuteRef : undefined}
                onClick={() => handleChange(undefined, item)}
                disabled={
                  !!maxDate &&
                  value.dayOfYear() === maxDate.dayOfYear() &&
                  currentHour === maxDate.hour() &&
                  item > maxDate.minute()
                }
              >
                {`0${item}`.slice(-2)}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default TimePicker;
