import { useMutation } from '@apollo/client';
import { TextField } from '@myjobglasses/dsm';
import React, { useEffect } from 'react';
import { Field, Formik } from 'formik';
import useFormikMutation from '../../../../../../../utils/hooks/useFormikMutation';
import { AddCompanyRecruitingWorkAreaMutationErrors } from '../../../../../../../@types/graphql-errors.d';
import {
  Add_Company_Recruiting_AreaMutation,
  Add_Company_Recruiting_AreaMutationVariables,
  Company,
  Remove_Company_Recruiting_AreaMutation,
  Remove_Company_Recruiting_AreaMutationVariables,
} from '../../../../../../../@types/graphql.d';
import FormikFieldError from '../../../../../../common/formik/components/formik-field-error/FormikFieldError';
import {
  ADD_COMPANY_RECRUITING_WORKING_AREA,
  REMOVE_COMPANY_RECRUITING_WORKING_AREA,
} from './RecruitingWorkingAreasWrite.gql';
import translations, { errorTranslation } from './RecruitingWorkingAreasWrite.translations';

import styles from './RecruitingWorkingAreasWrite.module.scss';
import { useIntl } from 'react-intl';

interface RecruitingWorkingAreasWriteProps {
  companyId: string;
  workingAreas: Array<string>;
  setWorkingAreas: React.Dispatch<React.SetStateAction<string[]>>;
  finish: () => void;
  company?: Pick<Company, 'isPremiumSchool'>;
}

function RecruitingWorkingAreasWrite({
  companyId,
  workingAreas,
  setWorkingAreas,
  finish,
  company,
}: RecruitingWorkingAreasWriteProps) {
  const intl = useIntl();
  const [onAdd] = useFormikMutation<Add_Company_Recruiting_AreaMutation, Add_Company_Recruiting_AreaMutationVariables>(
    ADD_COMPANY_RECRUITING_WORKING_AREA,
  );

  const onAddComplete = (
    res: {
      data: Add_Company_Recruiting_AreaMutation;
    },
    resetForm: () => void,
  ) => {
    if (!res?.data?.addCompanyRecruitingWorkArea?.workingAreas) return;
    setWorkingAreas(res.data.addCompanyRecruitingWorkArea.workingAreas);
    resetForm();
  };

  const [removeArea, { data: removeAreaData }] = useMutation<
    Remove_Company_Recruiting_AreaMutation,
    Remove_Company_Recruiting_AreaMutationVariables
  >(REMOVE_COMPANY_RECRUITING_WORKING_AREA);

  const handleRemoveArea = (workingArea: string) => {
    removeArea({
      variables: {
        companyId,
        workingArea,
      },
    });
  };

  useEffect(() => {
    if (!removeAreaData?.removeCompanyRecruitingWorkArea?.workingAreas) return;
    setWorkingAreas(removeAreaData.removeCompanyRecruitingWorkArea.workingAreas);
  }, [removeAreaData?.removeCompanyRecruitingWorkArea?.workingAreas]);

  return (
    <div>
      <Formik<FormikValues<Add_Company_Recruiting_AreaMutationVariables>>
        enableReinitialize
        initialValues={{
          companyId,
          workingArea: '',
        }}
        onSubmit={(values, formikHelpers) =>
          onAdd(values, formikHelpers).then((res) => onAddComplete(res, formikHelpers.resetForm))
        }
      >
        {({ handleSubmit, isSubmitting }) => (
          <form
            className={styles.companyAttributesWrite}
            onSubmit={handleSubmit}
          >
            <h2 className={styles.listValues}>
              {company?.isPremiumSchool
                ? intl.formatMessage(translations.listFormationsValues)
                : intl.formatMessage(translations.listValues)}
            </h2>
            {!company?.isPremiumSchool && (
              <div className={styles.samples}>
                <span className={styles.sampleLabel}>{intl.formatMessage(translations.sample)}</span>
                <span className={styles.sample}>{intl.formatMessage(translations.marketing)}</span>
                <span className={styles.sample}>{intl.formatMessage(translations.finance)}</span>
                <span className={styles.sample}>{intl.formatMessage(translations.hr)}</span>
              </div>
            )}
            <Field
              name="workingArea"
              component={TextField}
              label={
                company?.isPremiumSchool
                  ? intl.formatMessage(translations.labelFormationName)
                  : intl.formatMessage(translations.labelName)
              }
              maxLength={30}
              helper={intl.formatMessage(translations.helper)}
              disabled={isSubmitting}
              maxLengthDisplay={(current: number, max: number) =>
                intl.formatMessage(translations.counter, { current, max })
              }
            />
            <FormikFieldError<Add_Company_Recruiting_AreaMutationVariables, AddCompanyRecruitingWorkAreaMutationErrors>
              name="workingArea"
              asyncFieldErrorTranslations={errorTranslation}
            />
          </form>
        )}
      </Formik>
      <div className={styles.workingAreas}>
        {workingAreas.map((workingArea) => (
          <div
            className={styles.workingAreaBox}
            key={workingArea}
          >
            <div
              className={styles.removeWorkingArea}
              onClick={() => {
                handleRemoveArea(workingArea);
              }}
              aria-hidden="true"
            >
              x
            </div>
            <span
              key={workingArea}
              className={styles.workingArea}
            >
              {workingArea}
            </span>
          </div>
        ))}
      </div>
      <div className={styles.actions}>
        <button
          type="submit"
          className={styles.submitButton}
          onClick={finish}
        >
          {intl.formatMessage(translations.finishButton)}
        </button>
      </div>
    </div>
  );
}

export default RecruitingWorkingAreasWrite;
