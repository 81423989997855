import React, { memo, PropsWithChildren } from 'react';

import './SectionCard.scss';

type SectionCardProps = PropsWithChildren<{
  title: string;
  subtitle: string;
  className?: string;
}>;

export const SectionCard = memo(({ title, subtitle, className, children }: SectionCardProps) => (
  <section className="section-card">
    <header className="section-card__header">
      <div className="section-card__header__title">{title}</div>
      <p className="section-card__header__subtitle">{subtitle}</p>
    </header>

    <main className={className}>{children}</main>
  </section>
));
