import { gql } from '@apollo/client';

export const GET_PROFILE_INFORMATIONS_QUERY = gql`
  query GetProfileInformations {
    currentUserV2 {
      firstName
      lastName
      avatars {
        url
      }
      isAmbassador
      unavailableUntil
      createdAt
      likesReceivedCount
      publicationStatus
      professionalId
      canShare
      secondarySituation {
        key
      }
    }
  }
`;

export const GET_UPCOMING_APPOINTMENTS_QUERY = gql`
  query GetUpcomingAppointments($now: ISO8601DateTime) {
    upcomingAppointments: appointments(filters: { startAtGt: $now, cancelled: false }, sort: [FROM_ASC]) {
      nodes {
        startAt
        interlocutor {
          avatars {
            url
          }
          firstName
          companyLogo {
            url
          }
          profileType
          currentSchoolName
          companyName
        }
        conversation {
          id
        }
      }
    }
  }
`;

export const GET_ACTIVITY_STATS_QUERY = gql`
  query GetActivityStats {
    situation: currentUserV2 {
      primarySituation { key }
    }
    statistics: currentUserV2 {
      conversationsAsAmbassadorCount
      conversationsAsMemberCount
      ambassadorProfileViewsCount
      seenProfilesCount
      wishesReceivedCount
      wishesCount
      meetingsAsAmbassadorCount
      collectiveMeetingsAsAmbassadorCount
      impactedMembersAsAmbassadorCount
      meetingsAsMemberCount
      collectiveMeetingsAsMemberCount
      likesReceivedCount
      responseRateAsAmbassador
      responseRateAsMember
    }
  }
`;

export const GET_AMBASSADOR_PROGRESSION_QUERY = gql`
  query GetAmbassadorProgression {
    progression: currentUserV2 {
      ambassadorBadges {
        name
        completed
      }
    }
  }
`;

export const GET_MEMBER_PROGRESSION_QUERY = gql`
  query GetMemberProgression {
    progression: currentUserV2 {
      memberBadges {
        name
        completed
      }
    }
  }
`;

export const GET_SCORE_QUERY = gql`
  query GetScore {
    score: currentUserV2 {
      gamificationScore
      gamificationRank
      gamificationScoreDetails {
        name
        total
        formula
      }
    }
  }
`;

export const GET_AVAILABILITY_QUERY = gql`
  query GetAvailability {
    availability: currentUserV2 {
      isInModeration
      unavailableUntil
      publicationStatus
      ambassadorReachedConversationQuota
      remainingMeetingsAsAmbassadorThisMonth
      currentHibernation { 
        id
      }
      responseRateAsAmbassador
    }
  }
`;


export const GET_MEETING_LIMIT_QUERY = gql`
  query GetMeetingLimit {
    meetingLimit: currentUserV2 {
      remainingMeetingsAsAmbassadorThisMonth
      maxMeetingsAsAmbassadorPerMonth
      remainingContactsCount
      maxContactsPerWeek
    }
  }
`;

export const GET_PROGRAMS_QUERY = gql`
  query GetPrograms {
    programs: currentUserV2 {
      participations {
        id
        confirmedMeetingCount
        program {
          name
          meetingsQuantity
          startAt
          endAt
          organizationName
          author {
            firstName
            lastName
          }
          description
        }
        certificationStatus
        contacts {
          conversationId
          avatarUrl
          firstName
          state
        }
      }
    }
  }
`;
