import React, { useMemo } from 'react';

import MarkerPinIcon from '../../../../../icons/component-icons/MarkerPinIcon';

import classNames from 'classnames';
import EyeIcon from '../../../../../icons/component-icons/EyeIcon';
import ThumbsUpIcon from '../../../../../icons/component-icons/ThumbsUpIcon';
import StarIconFatBorder from '../../../../../icons/component-icons/StarIconFatBorder';
import Card from '../../../../../components/card/Card';
import './Identity.scss';
import { AmbassadorViewIdentityTranslations } from './Identity.translations';
import { useIntl } from 'react-intl';

type StaticIdentityProps = {
  isMobile?: boolean;
  desktopBannerUrl?: string | null;
  mobileBannerUrl?: string | null;
  companyLogoUrl: string;
  companyName: string;
};

export default function StaticIdentity({
  isMobile,
  desktopBannerUrl,
  mobileBannerUrl,
  companyLogoUrl,
  companyName,
}: StaticIdentityProps) {
  const intl = useIntl();
  const companyHasSetBanners = useMemo(() => desktopBannerUrl || mobileBannerUrl, [desktopBannerUrl, mobileBannerUrl]);

  const bannerUrl = useMemo<string | undefined | null>(() => {
    if (!companyHasSetBanners) return undefined;
    return isMobile ? mobileBannerUrl : desktopBannerUrl;
  }, [isMobile, companyHasSetBanners, mobileBannerUrl, desktopBannerUrl]);

  return (
    <section
      className="ambassador-view-identity"
      style={{ flex: 2 }}
    >
      <Card noPadding>
        <header
          className={classNames('ambassador-view-identity__banner', {
            'ambassador-view-identity__banner--premium': !companyHasSetBanners,
            'ambassador-view-identity__banner--custom': companyHasSetBanners,
          })}
        >
          {companyHasSetBanners && bannerUrl && (
            <img
              className="ambassador-view-identity__banner--custom__image"
              src={bannerUrl}
            />
          )}
          <img
            className="ambassador-view-identity__banner__avatar"
            src="https://assets.myjobglasses.com/images/avatar-placeholder.png"
          />
          <img
            className="ambassador-view-identity__banner__company-logo"
            src={companyLogoUrl}
          />
        </header>

        <div className="ambassador-view-identity__content">
          <div className="ambassador-view-identity__content__first-row">
            <div className="ambassador-view-identity__content__first-row__name blurred">Firstname</div>
            <StarIconFatBorder
              className={classNames(
                'ambassador-view-identity__content__first-row__star-icon',
                'ambassador-view-identity__content__first-row__star-icon--empty',
              )}
            />
          </div>
          <div className="ambassador-view-identity__content__position blurred">Current position</div>
          <div className="ambassador-view-identity__content__company-name">{companyName}</div>
          <div className="ambassador-view-identity__content__location">
            <MarkerPinIcon /> <span className="blurred">Current meeting place</span>
          </div>

          <div className="ambassador-view-identity__content__stats ambassador-view-identity__content__stats--fake-mobile">
            <div className="ambassador-view-identity__content__stats__like-count">
              <ThumbsUpIcon /> <span className="blurred">5 {intl.formatMessage(AmbassadorViewIdentityTranslations.likes)}</span>
            </div>
            <div className="ambassador-view-identity__content__stats__seen-count">
              <EyeIcon /> <span className="blurred">50 {intl.formatMessage(AmbassadorViewIdentityTranslations.seen)}</span>
            </div>
            <div className={classNames('ambassador-view-identity__content__stats__availability')}>
              {intl.formatMessage(AmbassadorViewIdentityTranslations.available)}
            </div>
          </div>
        </div>
      </Card>
    </section>
  );
}
