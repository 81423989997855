import React from 'react';
import { Organization, Participation, Program, ProgramTemplate, User } from '../../../../../@types/backend-api';
import { useIntl } from 'react-intl';
import CertificationDisplay from '../certification-display/CertificationDisplay';
import translations from './AffiliationItem.translations';

import styles from './AffiliationItem.module.scss';

interface AffiliationItemProps {
  participation: Pick<Participation, 'id' | 'certificationStatus' | 'unfollowedConversationCount' | 'reportCount'> & {
    program: Pick<Program, 'id' | 'name' | 'meetingsQuantity' | 'description' | 'startAt' | 'endAt'> & {
      programTemplate?: Pick<ProgramTemplate, 'displayedName'>;
      project?: {
        organization: Pick<Organization, 'name'>;
      };
      collaborations?: {
        nodes: Array<{
          user: Pick<User, 'firstName' | 'lastName'>;
        }>;
      } | null;
    };
  };
}
export default function AffiliationItem({ participation }: AffiliationItemProps) {
  const intl = useIntl();
  return (
    <article className={styles.affiliationItem}>
      <h2 className={styles.title}>
        {intl.formatMessage(translations.programTemplate, {
          name: participation.program.programTemplate?.displayedName,
        })}
      </h2>

      <h3 className={styles.apptCount}>
        {intl.formatMessage(translations.apptCount, {
          n: participation.program.meetingsQuantity,
        })}
      </h3>

      <p className={styles.dates}>
        {intl.formatMessage(translations.fromto, {
          start: intl.formatDate(participation.program.startAt, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
          end: intl.formatDate(participation.program.endAt, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
        })}
      </p>

      <h4 className={styles.sectionTitle}>{intl.formatMessage(translations.organization)}</h4>
      <p className={styles.sectionDescription}>{participation.program.project?.organization.name}</p>

      <h4 className={styles.sectionTitle}>{intl.formatMessage(translations.managers)}</h4>
      <p className={styles.sectionDescription}>
        {participation.program.collaborations?.nodes.map(({ user }) => `${user.firstName} ${user.lastName}`).join(', ')}
      </p>

      <h4 className={styles.sectionTitle}>{intl.formatMessage(translations.description)}</h4>
      <p className={styles.sectionDescription}>{participation.program.description}</p>

      <h4 className={styles.sectionTitle}>{intl.formatMessage(translations.certification)}</h4>
      <p className={styles.sectionDescription}>
        <a
          href="https://help.myjobglasses.com/fr/articles/109067-qu-est-ce-que-le-certificat-d-orientation-my-job-glasses"
          className={styles.certificationFAQLink}
          target="__blank"
          rel="noopener noreferrer"
        >
          {intl.formatMessage(translations.certificationFAQ)}
        </a>
      </p>
      <CertificationDisplay participation={participation} />
    </article>
  );
}
