import React from 'react';
import CheckCircleIcon from '../../../../../../../icons/component-icons/CheckCircleIcon';
import ArchiveIcon from '../../../../../../../icons/component-icons/ArchiveIcon';
import ArrowCircleBrokenDownIcon from '../../../../../../../icons/component-icons/ArrowCircleBrokenDownIcon';
import ArrowCircleBrokenUpIcon from '../../../../../../../icons/component-icons/ArrowCircleBrokenUpIcon';
import classnames from 'classnames';
import { CompanyEmployee } from '../../../../../../../@types/graphql.d';
import EmployeeActionButton from '../../../employee-action-button/EmployeeActionButton';
import useArchiveAction from '../../hooks/useArchiveAction/useArchiveAction';
import useApproveAction from '../../hooks/useApproveAction/useApproveAction';
import usePublishAction from '../../hooks/usePublishAction/usePublishAction';
import useUnpublishAction from '../../hooks/useUnpublishAction/useUnpublishAction';
import translations from './EmployeeManageToolboxActions.translations';
import styles from './EmployeeManageToolboxActions.module.scss';
import { useIntl } from 'react-intl';

interface EmployeeManageToolboxActionsProps {
  userId: string | null | undefined;
  employeeid: string;
  authorizedActions?: Pick<CompanyEmployee, 'canPublish' | 'canApprove' | 'canArchive' | 'canUnpublish'> | null;
  className?: string;
}

function EmployeeManageToolboxActions({
  userId,
  employeeid,
  authorizedActions,
  className,
}: EmployeeManageToolboxActionsProps) {
  const intl = useIntl();
  const [archiveEmployees] = useArchiveAction(employeeid);
  const [approveEmployee] = useApproveAction(employeeid);
  const [publishEmployee] = usePublishAction(userId!);
  const [unpublishEmployee] = useUnpublishAction(userId!);
  return (
    <div className={classnames(styles.actions, className)}>
      <EmployeeActionButton
        data-testid="approve-button"
        actionName={intl.formatMessage(translations.approveAction)}
        confirmationMessage={intl.formatMessage(translations.approveConfirmationMessage)}
        className={styles.actionButton}
        iconComponent={CheckCircleIcon}
        iconClassName={styles.icon}
        onConfirm={approveEmployee}
        disabled={!authorizedActions?.canApprove}
      />
      <span className={styles.separator} />
      <EmployeeActionButton
        data-testid="publish-button"
        actionName={intl.formatMessage(translations.publishAction)}
        confirmationMessage={intl.formatMessage(translations.publishConfirmationMessage)}
        className={styles.actionButton}
        iconComponent={ArrowCircleBrokenUpIcon}
        iconClassName={styles.icon}
        onConfirm={publishEmployee}
        disabled={!authorizedActions?.canPublish}
      />
      <EmployeeActionButton
        data-testid="unpublish-button"
        actionName={intl.formatMessage(translations.unpublishAction)}
        confirmationMessage={intl.formatMessage(translations.unpublishConfirmationMessage)}
        className={styles.actionButton}
        iconComponent={ArrowCircleBrokenDownIcon}
        iconClassName={styles.icon}
        onConfirm={unpublishEmployee}
        disabled={!authorizedActions?.canUnpublish}
      />
      <span className={styles.separator} />
      <EmployeeActionButton
        data-testid="archive-button"
        actionName={intl.formatMessage(translations.archiveAction)}
        confirmationMessage={intl.formatMessage(translations.archiveConfirmationMessage)}
        className={styles.actionButton}
        iconComponent={ArchiveIcon}
        iconClassName={styles.icon}
        onConfirm={archiveEmployees}
        disabled={!authorizedActions?.canArchive}
      />
    </div>
  );
}

export default EmployeeManageToolboxActions;
