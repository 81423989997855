import React from 'react';
import translations from './CompanyPresentationEmpty.translations';

import styles from './CompanyPresentationEmpty.module.scss';
import { useIntl } from 'react-intl';

interface CompanyPresentationEmptyProps {
  edit: () => void;
}
function CompanyPresentationEmpty({ edit }: CompanyPresentationEmptyProps) {
  const intl = useIntl();
  return (
    <div className={styles.companyPresentationEmpty}>
      <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
      <button
        type="button"
        className={styles.addButton}
        onClick={edit}
      >
        {intl.formatMessage(translations.addButton)}
      </button>
    </div>
  );
}

export default CompanyPresentationEmpty;
