import React from 'react';
import './loader.scss';
import classNames from 'classnames';

type LoaderProps = {
  className?: string;
  color?: string;
};

export default function Loader({ className, color }: LoaderProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={classNames('spin-loader', className)}
    >
      <path
        id="branch1"
        className="spin-loader-path"
        d="M12 2V6"
        stroke={color || '#B3B8C2'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="branch2"
        className="spin-loader-path"
        d="M19.0784 4.99994L16.25 7.82837"
        stroke={color || '#B3B8C2'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="branch3"
        className="spin-loader-path"
        d="M22 12H18"
        stroke={color || '#B3B8C2'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="branch4"
        className="spin-loader-path"
        d="M19.0784 19.0784L16.25 16.25"
        stroke={color || '#B3B8C2'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="branch5"
        className="spin-loader-path"
        d="M12 18V22"
        stroke={color || '#B3B8C2'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="branch6"
        className="spin-loader-path"
        d="M4.92157 19.0784L7.75 16.25"
        stroke={color || '#B3B8C2'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="branch7"
        className="spin-loader-path"
        d="M6 12H2"
        stroke={color || '#B3B8C2'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="branch8"
        className="spin-loader-path"
        d="M4.92157 4.99994L7.75 7.82837"
        stroke={color || '#B3B8C2'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
