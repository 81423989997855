import { AnimatePresence, motion } from 'motion/react';
import React, { memo, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { generatePath, Link } from 'react-router';

import classNames from 'classnames';

import './InterlocutorSection.scss';

import Card from '../../../../../components/card/Card';
import PrimaryButton from '../../../../../components/primary-button/PrimaryButton';
import { Tag } from '../../../../../components/tag/Tag';
import useIsMobile from '../../../../../hooks/useIsMobile';
import CalendarIcon from '../../../../../icons/component-icons/CalendarIcon';
import CheckIcon from '../../../../../icons/component-icons/CheckIcon';
import ChevronDownIcon from '../../../../../icons/component-icons/ChevronDownIcon';
import CrossIcon from '../../../../../icons/component-icons/CrossIcon';
import Routes from '../../../../../routes';
import buttonCommonTranslations from '../../../../common/common-translations/button.translations';
import { NewConversationInterlocutor } from '../../NewConversation.gql';

type Props = {
  interlocutor: NewConversationInterlocutor;
  className?: string;
  userId: string;
  submitDisabled: boolean;
  confirm: boolean;
  onSubmit: () => void;
  fromWidget?: boolean;
};

const TRANSLATIONS = defineMessages({
  remainingAppointmentCount: {
    id: 'InterlocutorSection.remainingAppointmentCount',
    defaultMessage: '{count} rendez-vous {count, plural, =1 {restant} other {restants}} ce mois-ci',
  },
  presentationTitle: {
    id: 'InterlocutorSection.presentationTitle',
    defaultMessage: 'Présentation de l’Ambassadeur',
  },
  meetingPreferencesTitle: {
    id: 'InterlocutorSection.meetingPreferencesTitle',
    defaultMessage: 'Profils qu’il accepte de rencontrer',
  },
  seeProfile: {
    id: 'InterlocutorSection.seeProfile',
    defaultMessage: 'Voir le profil',
  },
  previewAndSend: {
    id: 'InterlocutorSection.previewAndSend',
    defaultMessage: "Prévisualiser et finaliser l'envoi du message",
  },
  groupMeetingPreference: {
    id: 'InterlocutorSection.groupMeetingPreference',
    defaultMessage: 'Groupes d’élèves ou de demandeurs d’emploi',
  },
  sendMessage: {
    id: 'InterlocutorSection.sendMessage',
    defaultMessage: 'Envoyer le message',
  },
  createForSend: {
    id: 'InterlocutorSection.createForSend',
    defaultMessage: 'Créer mon compte pour envoyer mon message',
  },
});

export const InterlocutorSection: React.FC<Props> = memo((props) => {
  const isMobile = useIsMobile();
  if (isMobile) return <Mobile {...props} />;
  else return <Desktop {...props} />;
});

const Desktop: React.FC<Props> = memo(({ interlocutor, className, submitDisabled, confirm, fromWidget, onSubmit }) => {
  const { formatMessage } = useIntl();
  const buttonTranslation = useMemo(() => {
    if (confirm) return TRANSLATIONS.sendMessage;
    return fromWidget ? TRANSLATIONS.createForSend : TRANSLATIONS.previewAndSend;
  }, [confirm, fromWidget]);
  return (
    <Card className={classNames('interlocutor-section', className)}>
      <Tag
        style={{ alignSelf: 'flex-start' }}
        icon={CalendarIcon}
        label={formatMessage(TRANSLATIONS.remainingAppointmentCount, {
          count: interlocutor.remainingMeetingsAsAmbassadorThisMonth,
        })}
        type={(interlocutor.remainingMeetingsAsAmbassadorThisMonth || 0) > 1 ? 'success' : 'error'}
      />

      <div className="interlocutor-section__interlocutor">
        <div className="interlocutor-section__interlocutor__avatar">
          <img
            className="interlocutor-section__interlocutor__avatar__user"
            src={interlocutor.avatar.url || ''}
          />
          <img
            className="interlocutor-section__interlocutor__avatar__company"
            src={interlocutor.companyLogo?.url || ''}
          />
        </div>
        <div className="interlocutor-section__interlocutor__details">
          <p className="interlocutor-section__interlocutor__details__name">{interlocutor.firstName}</p>
          <p className="interlocutor-section__interlocutor__details__position">{interlocutor.position}</p>
          <p className="interlocutor-section__interlocutor__details__company-name">{interlocutor.companyName}</p>
        </div>
      </div>

      <div className="interlocutor-section__presentation">
        <h4>{formatMessage(TRANSLATIONS.presentationTitle)}</h4>
        <div className="interlocutor-section__presentation__content">
          {interlocutor.mergedAmbassadorPresentation.split('\n').map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
        <Link
          className="interlocutor-section__presentation__see-profile"
          to={generatePath(Routes.professional, { id: interlocutor.id })}
          target="_blank"
        >
          {formatMessage(TRANSLATIONS.seeProfile)}
        </Link>
      </div>

      <div className="interlocutor-section__meeting-preferences">
        <h4>{formatMessage(TRANSLATIONS.meetingPreferencesTitle)}</h4>

        {interlocutor.meetingPreferences?.map(({ name, value }) => (
          <div
            key={name}
            className={classNames('interlocutor-section__meeting-preferences__preference', {
              'interlocutor-section__meeting-preferences__preference--accept': value,
            })}
          >
            {value ? <CheckIcon /> : <CrossIcon />}
            <span>{name}</span>
          </div>
        ))}
        <div
          className={classNames('interlocutor-section__meeting-preferences__preference', {
            'interlocutor-section__meeting-preferences__preference--accept': interlocutor.acceptGroupConversations,
          })}
        >
          {interlocutor.acceptGroupConversations ? <CheckIcon /> : <CrossIcon />}
          <span>{formatMessage(TRANSLATIONS.groupMeetingPreference)}</span>
        </div>
      </div>

      <PrimaryButton
        style={{ marginTop: 8 }}
        label={formatMessage(buttonTranslation)}
        disabled={submitDisabled}
        onClick={onSubmit}
      />
    </Card>
  );
});

const Mobile: React.FC<Props> = memo(({ interlocutor, submitDisabled, confirm, fromWidget, onSubmit }) => {
  const { formatMessage } = useIntl();
  const [isExpanded, setExpanded] = useState(false);
  const buttonTranslation = useMemo(() => {
    if (fromWidget) return TRANSLATIONS.createForSend;
    return confirm ? TRANSLATIONS.sendMessage : TRANSLATIONS.previewAndSend;
  }, [confirm, fromWidget]);

  return (
    <>
      <div className="interlocutor-section interlocutor-section--mobile">
        <Tag
          style={{ alignSelf: 'center' }}
          icon={CalendarIcon}
          label={formatMessage(TRANSLATIONS.remainingAppointmentCount, {
            count: interlocutor.remainingMeetingsAsAmbassadorThisMonth,
          })}
          type={(interlocutor.remainingMeetingsAsAmbassadorThisMonth || 0) > 1 ? 'success' : 'error'}
        />

        <div className="interlocutor-section__interlocutor">
          <div className="interlocutor-section__interlocutor__avatar">
            <img
              className="interlocutor-section__interlocutor__avatar__user"
              src={interlocutor.avatar.url || ''}
            />
            <img
              className="interlocutor-section__interlocutor__avatar__company"
              src={interlocutor.companyLogo?.url || ''}
            />
          </div>
          <div className="interlocutor-section__interlocutor__details">
            <p className="interlocutor-section__interlocutor__details__name">{interlocutor.firstName}</p>
            <p className="interlocutor-section__interlocutor__details__position">{interlocutor.position}</p>
            <p className="interlocutor-section__interlocutor__details__company-name">{interlocutor.companyName}</p>
          </div>
        </div>

        <button
          className="interlocutor-section__interlocutor__see-more"
          onClick={() => {
            setExpanded((e) => !e);
          }}
        >
          <ChevronDownIcon className={isExpanded ? 'expanded' : undefined} />
          {formatMessage(buttonCommonTranslations.seeMore)}
        </button>

        <AnimatePresence>
          {isExpanded && (
            <motion.div
              style={{ overflowY: 'hidden' }}
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              exit={{ height: 0 }}
            >
              <div className="interlocutor-section__presentation">
                <h4>{formatMessage(TRANSLATIONS.presentationTitle)}</h4>
                <div className="interlocutor-section__presentation__content">
                  {interlocutor.mergedAmbassadorPresentation.split('\n').map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                  ))}
                </div>
                <Link
                  className="interlocutor-section__presentation__see-profile"
                  to={generatePath(Routes.professional, { id: interlocutor.id })}
                >
                  {formatMessage(TRANSLATIONS.seeProfile)}
                </Link>
              </div>

              <div className="interlocutor-section__meeting-preferences">
                <h4>{formatMessage(TRANSLATIONS.meetingPreferencesTitle)}</h4>

                {interlocutor.meetingPreferences?.map(({ name, value }) => (
                  <div
                    key={name}
                    className={classNames('interlocutor-section__meeting-preferences__preference', {
                      'interlocutor-section__meeting-preferences__preference--accept': value,
                    })}
                  >
                    {value ? <CheckIcon /> : <CrossIcon />}
                    <span>{name}</span>
                  </div>
                ))}
                <div
                  className={classNames('interlocutor-section__meeting-preferences__preference', {
                    'interlocutor-section__meeting-preferences__preference--accept':
                      interlocutor.acceptGroupConversations,
                  })}
                >
                  {interlocutor.acceptGroupConversations ? <CheckIcon /> : <CrossIcon />}
                  <span>{formatMessage(TRANSLATIONS.groupMeetingPreference)}</span>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <div className="interlocutor-section--mobile__footer">
        <PrimaryButton
          label={formatMessage(buttonTranslation)}
          disabled={submitDisabled}
          onClick={onSubmit}
        />
      </div>
    </>
  );
});
