import React from 'react';
import classnames from 'classnames';
import { AntivirusStatus, Message } from '../../../../../../../../../@types/graphql.d';
import AttachmentIcon from '../../../../../../../../../icons/component-icons/AttachmentIcon';
import { attachmentName } from '../../utils/attachment';
import translations from './MessageFooter.translations';

import './MessageFooter.scss';
import { useIntl } from 'react-intl';

export type MessageFooterProps = Pick<Message, 'createdAt' | 'attachment' | 'seenAt'> & {
  isLastSentMessage?: boolean;
};

function MessageFooter({ createdAt, attachment, seenAt, isLastSentMessage }: MessageFooterProps) {
  const intl = useIntl();
  const isFileRejected = attachment?.scannedByAntivirus === AntivirusStatus.AttachmentRejected;

  return (
    <footer className="message-footer">
      {attachment?.url && (
        <div
          data-testid="message-attachment"
          className="message-footer__attachment"
        >
          <a
            className={classnames({
              disabled: isFileRejected,
            })}
            href={attachment?.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            <AttachmentIcon className="message-footer__attachment__icon" />
            {attachmentName(attachment)}
          </a>
          {isFileRejected && (
            <p className="message-footer__attachment__scanned-by-antivirus">
              {intl.formatMessage(translations.attachmentRejected)}
            </p>
          )}
        </div>
      )}

      <div className="message-footer__time-informations">
        <div className="message-footer__time-informations__created-at">
          <div>
            {intl.formatDate(createdAt, {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
            })}
          </div>
          <div>•</div>
          <div>
            {intl.formatDate(createdAt, {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </div>
        </div>

        {isLastSentMessage && seenAt && (
          <div className="message-footer__time-informations__seen">
            <span className="message-footer__time-informations__seen__check">✓</span>
            {intl.formatMessage(translations.seen)}
          </div>
        )}
      </div>
    </footer>
  );
}

export default MessageFooter;
