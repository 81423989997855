import { gql } from '@apollo/client';

export const AUTOCOMPLETE_AMBASSADORS_QUERY = gql`
  query AutocompleteAmbassadors($term: [String!]!, $searchMode: SearchTypeEnum!) {
    autocompleteAmbassadors(term: $term, searchMode: $searchMode) {
      type
      matchingResults {
        text
        bold {
          offset
          length
        }
      }
    }
  }
`;

export const GET_SEARCH_FACETS_QUERY = gql`
  query GetSearchFacets($searchMode: SearchTypeEnum!) {
    searchFacets(searchQuery: "", searchMode: $searchMode) {
      key
      buckets {
        key
        label
        docCount
      }
    }
  }
`;

export const SEARCH_SCHOOL_NAMES_FACET = gql`
  query searchSchoolNamesFacet(
    $searchMode: SearchTypeEnum!,
    $searchFacets: [SearchFacetInput!],
    $facetQuery: String,
  ) {
    searchSchoolNamesFacet(
      searchMode: $searchMode,
      searchFacets: $searchFacets
      facetQuery: $facetQuery,
    ) {
      key
      count
    }
  }
`;

export const GET_AMBASSADORS_QUERY = gql`
  query GetAmbassadors(
    $searchQuery: String
    $page: Int
    $searchMode: SearchTypeEnum
    $searchFacets: [SearchFacetInput!]
    $searchGooglePlaceId: String
    $availableAmbassadorsOnly: Boolean
    $availableFirst: Boolean
    $acceptConversationTypes: [ConversationTypeKeyEnum!]
  ) {
    searchAmbassadors(
      page: $page
      perPage: 16
      searchQuery: $searchQuery
      searchMode: $searchMode
      searchFacets: $searchFacets
      searchGooglePlaceId: $searchGooglePlaceId
      availableAmbassadors: $availableAmbassadorsOnly
      availableFirst: $availableFirst
      acceptConversationTypes: $acceptConversationTypes
    ) {
      total
      results {
        profileType
        premium
        avatar {
          url
        }
        firstName
        lastName
        publicLastName
        companyLogo {
          url
        }
        banner {
          url
        }
        companyName
        previousCompanyName
        previousPosition
        position
        positiveLikeCount
        unavailableUntil
        professionalId
        currentSchoolName
        currentGradeOrDiploma
        currentSchoolLogo {
          url
        }
      }
    }
  }
`;
