import { defineMessages } from 'react-intl';

export default defineMessages({
  description: {
    id: 'CompanyAttributesEmpty.description',
    defaultMessage: 'Libellé non configuré',
  },
  editButton: {
    id: 'CompanyAttributesEmpty.editButton',
    defaultMessage: 'Configurer',
  },
});
