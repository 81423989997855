import React from 'react';
import CheckIcon from '../../../../../icons/component-icons/CheckIcon';
import CheckCircleIcon from '../../../../../icons/component-icons/CheckCircleIcon';
import translations from './ProfessionalAvailableFromRedCarpet.translations';

import './ProfessionalAvailableFromRedCarpet.scss';
import { useIntl } from 'react-intl';

function ProfessionalAvailableFromRedCarpet() {
  const intl = useIntl();
  return (
    <div className={'professional-available-from-red-carpet'}>
      <CheckCircleIcon className="professional-available-from-red-carpet__icon" />
      <span className={'professional-available-from-red-carpet__text'}>
        {intl.formatMessage(translations.redCarpetOnly)}
      </span>
    </div>
  );
}

export default ProfessionalAvailableFromRedCarpet;
