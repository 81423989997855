import React from 'react';
import ButtonsGroup from '../../../../../../../components/buttons-group/ButtonsGroup';
import PrimaryButton from '../../../../../../../components/primary-button/PrimaryButton';
import { goToConnectWebsite } from '../../../../../../../utils/redirection.util';
import { defineMessages, useIntl } from 'react-intl';
import SecondaryButton from '../../../../../../../components/secondary-button/SecondaryButton';
import useModal from '../../../../../../../components/modal/Modal.hook';

const TRANSLATIONS = defineMessages({
  body: {
    id: 'DeleteBannerModal.body',
    defaultMessage: 'Êtes-vous sûr de vouloir supprimer cette image ?',
  },
	primaryButton: {
    id: 'DeleteBannerModal.primaryButton',
    defaultMessage: 'Confirmer',
  },
	cancelButton: {
		id: 'DeleteBannerModal.cancelButton',
		defaultMessage: 'Annuler',
	},
});

export default function DeleteBannerModal({ onConfirm }: { onConfirm: () => void }) {
  const intl = useIntl();
	const [, closeModal] = useModal();
  return (
    <div>
      <p>{intl.formatMessage(TRANSLATIONS.body)}</p>
      <ButtonsGroup>
        <PrimaryButton
          label={intl.formatMessage(TRANSLATIONS.primaryButton)}
          onClick={onConfirm}
        />
				<SecondaryButton
					label={intl.formatMessage(TRANSLATIONS.cancelButton)}
					onClick={closeModal}
				/>
      </ButtonsGroup>
    </div>
  );
}