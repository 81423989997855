import { defineMessages } from 'react-intl';

export const AmbassadorViewFurtherInformationsTranslations = defineMessages({
  studiesTitle: {
    id: 'AmbassadorViewFurtherInformations.studiesTitle',
    defaultMessage: 'Parcours',
  },
  languagesTitle: {
    id: 'AmbassadorViewFurtherInformations.languagesTitle',
    defaultMessage: 'Langues',
  },
  recommendedQualifications: {
    id: 'AmbassadorViewFurtherInformations.recommendedQualifications',
    defaultMessage: 'Formation conseillée',
  },
  recommendedSkills: {
    id: 'AmbassadorViewFurtherInformations.recommendedSkills',
    defaultMessage: 'Compétences recommandées',
  },
});
