import React from 'react';
import { Company, CustomAttributesSet } from '../../../../../../../@types/graphql.d';
import translations from './CompanyAttributesRead.translations';

import styles from './CompanyAttributesRead.module.scss';
import { useIntl } from 'react-intl';

type LabelKeys = Exclude<
  keyof CustomAttributesSet,
  'companyAttributeValues1' | 'companyAttributeValues2' | 'companyAttributeValues3' | '__typename'
>;
type ValuesKeys = Exclude<
  keyof CustomAttributesSet,
  'customEmployeeAttribute1' | 'customEmployeeAttribute2' | 'customEmployeeAttribute3' | '__typename'
>;
interface CompanyAttributesReadProps {
  number: 1 | 2 | 3;
  company?: Pick<Company, 'customAttributesSet'> | null;
}
function CompanyAttributesRead({ number, company }: CompanyAttributesReadProps) {
  const intl = useIntl();
  const labelKey = `customEmployeeAttribute${number}` as LabelKeys;
  const valueKey = `companyAttributeValues${number}` as ValuesKeys;
  return (
    <div>
      <h2 className={styles.title}>{intl.formatMessage(translations.labelName)}</h2>
      <p className={styles.labelValue}>{company?.customAttributesSet[labelKey]}</p>
      <h2 className={styles.title}>{intl.formatMessage(translations.values)}</h2>
      <p className={styles.values}>
        {company?.customAttributesSet[valueKey].map((value) => (
          <span
            key={value}
            className={styles.value}
          >
            {value}
          </span>
        ))}
      </p>
    </div>
  );
}

export default CompanyAttributesRead;
