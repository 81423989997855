import { defineMessages } from 'react-intl';

export default defineMessages({
  companyName: {
    id: 'CompanyGeneralInformationsRead.companyName',
    defaultMessage: "Nom de l'entreprise",
  },
  schoolName: {
    id: 'CompanyGeneralInformationsRead.schoolName',
    defaultMessage: "Nom de l'établissement",
  },
  companySector: {
    id: 'CompanyGeneralInformationsRead.companySector',
    defaultMessage: "Secteur d'activité",
  },
  companySize: {
    id: 'CompanyGeneralInformationsRead.companySize',
    defaultMessage: "Taille d'entreprise",
  },
  etablishmentType: {
    id: 'CompanyGeneralInformationsRead.etablishmentType',
    defaultMessage: "Type d'établissement",
  },
});
