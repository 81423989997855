import React from 'react';
import { Conversation } from '../../../../../../../@types/graphql.d';
import translations from './InterlocutorMeetingPlace.translations';

import styles from './InterlocutorMeetingPlace.module.scss';
import { useIntl } from 'react-intl';

interface InterlocutorMeetingPlaceProps {
  interlocutor?: NonNullable<Conversation>['interlocutorV2'];
}
function InterlocutorMeetingPlace({ interlocutor }: InterlocutorMeetingPlaceProps) {
  const intl = useIntl();
  if (!interlocutor?.meetingPlace) {
    return <p className={styles.interlocutorMeetingPlace}>{intl.formatMessage(translations.placeholder)}</p>;
  }
  return <p className={styles.interlocutorMeetingPlace}>{interlocutor?.meetingPlace}</p>;
}
export default InterlocutorMeetingPlace;
