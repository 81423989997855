import React, { useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Container, Col } from 'react-bootstrap';
import 'react-dates/initialize';
import { DayPickerRangeController } from 'react-dates';
import { FormattedMessage, defineMessages, FormattedDate, FormattedTime, useIntl } from 'react-intl';
import moment from 'moment-timezone';
import { breakpoints } from '../../../variables';
import FlatTimePicker from '../../flat-time-picker/FlatTimePicker';

import './AccountHibernation.scss';
import { isNextOrCurrentHiberation } from './AccountHibernation.utils';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { useSnackbar } from 'notistack';
import { CurrentUserContext } from '../../../contexts/current-user-context/CurrentUserContext';
import AutosubmitSection from '../../../hoc/autosubmit-section';
import CalendarIcon from '../../../icons/component-icons/CalendarIcon';
import { AccountHibernationTranslations } from './AccountHibernation.translations';

type HibernationDatetimeInputProps = {
  datetime: any;
  toggleCalendar: () => void;
};

const HibernationDatetimeInput = ({ datetime, toggleCalendar }: HibernationDatetimeInputProps) => (
  <div
    className="hibernation-date-input"
    onClick={toggleCalendar}
  >
    {datetime && (
      <FormattedDate
        value={datetime}
        year="numeric"
        month="short"
        day="2-digit"
      >
        {(txt: string) => <span className="hibernation-date">{txt}</span>}
      </FormattedDate>
    )}
    {datetime && (
      <FormattedTime value={datetime}>{(txt: string) => <span className="hibernation-time">{txt}</span>}</FormattedTime>
    )}
    <span className="toggle-hibernation-calendar">
      <CalendarIcon />
    </span>
  </div>
);

type HibernationMobileDatetimeInputProps = {
  date: any;
  hour: any;
  active: boolean;
};

const HibernationMobileDatetimeInput = ({ date, hour, active }: HibernationMobileDatetimeInputProps) => (
  <span
    className={classNames({
      'mobile-input': true,
      active: active,
    })}
  >
    {(date && <FormattedDate value={date} />) || (
      <FormattedMessage
        id="AccountHibernation.placeholderMobileDatePicker"
        defaultMessage="--/--/----"
        description="Placeholder lorsque l utilisateur n a pas choisi de date en format mobile"
      />
    )}
    &nbsp; &nbsp;
    {(hour && <FormattedTime value={moment().hour(hour).minute(0).toDate()} />) || '--:--'}
  </span>
);

type AccountHibernationProps = {
  createHibernation: any;
  destroyHibernation: any;
};

const AccountHibernation = ({ createHibernation, destroyHibernation }: AccountHibernationProps) => {
  const screen = useWindowSize();
  const width = screen.width || 0;
  const snackbar = useSnackbar();
  const intl = useIntl();

  const { currentUser } = useContext(CurrentUserContext);

  const [startDate, setStartDate] = useState<any>(moment());
  const [endDate, setEndDate] = useState<any>(moment());
  const [startHour, setStartHour] = useState<any>(null);
  const [endHour, setEndHour] = useState<any>(null);
  const [focusedInput, setFocusedInput] = useState<any>('startDate');
  const [isCalendarOpened, setIsCalendarOpened] = useState<any>(false);

  const hibernation = useMemo(
    () => currentUser?.hibernations?.find(isNextOrCurrentHiberation) || null,
    [currentUser?.hibernations],
  );
  const profileStartAt = useMemo(() => hibernation && moment(hibernation.scheduledStartAt), [hibernation]);
  const profileEndAt = useMemo(() => hibernation && moment(hibernation.requestedWakeAt), [hibernation]);

  const toggleFocusedInput = (inputFocusing: any) => {
    if (width <= breakpoints.sm) {
      if (inputFocusing === 'endDate' && startDate && startHour) {
        setFocusedInput('endDate');
      } else if ((!inputFocusing || inputFocusing === 'startDate') && endDate && endHour) {
        setFocusedInput('startDate');
      }
      return;
    }
    setFocusedInput(inputFocusing || 'startDate');
  };

  const reset = () => {
    setStartDate(null);
    setEndDate(null);
    setStartHour(null);
    setEndHour(null);
    setFocusedInput('startDate');
  };

  const setRange = ({ startDate, endDate }: any) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const isOutsideRange = (day: any) => {
    return (
      moment(day).hour(0).minute(0).diff(moment(), 'days') < 0 ||
      moment(day).hour(0).minutes(0).diff(startDate, 'days') >= 60
    );
  };

  const onChooseStartHour = (hour: any) => {
    setStartHour(hour);
    setTimeout(() => {
      toggleFocusedInput('endDate');
    }, 0);
  };

  const onChooseEndHour = (hour: any) => {
    setEndHour(hour);
    setTimeout(() => {
      toggleFocusedInput('startDate');
    }, 0);
  };

  const validate = () => {
    if (!(startDate && startDate._isAMomentObject && endDate && endDate._isAMomentObject && startHour && endHour)) {
      snackbar.enqueueSnackbar(intl.formatMessage(AccountHibernationTranslations.errorNotAllFilledField), {
        variant: 'error',
      });
      return;
    }

    createHibernation({
      variables: {
        startAt: startDate.set('hour', startHour).format(),
        endAt: endDate.set('hour', endHour).format(),
      },
    })
      .then(() => {
        setIsCalendarOpened(false);
        reset();
        window.location.reload();
      })
      .catch((errors: any) => {
        switch (errors?.[0]?.meta?.code) {
          case 'hibernation_too_broad_error':
            snackbar.enqueueSnackbar(intl.formatMessage(AccountHibernationTranslations.tooBroadError), {
              variant: 'error',
            });
            break;
          case 'iced_up_professionals_cannot_hibernate_error':
            snackbar.enqueueSnackbar(intl.formatMessage(AccountHibernationTranslations.icedUpProfessionalError), {
              variant: 'error',
            });
            break;
          case 'unpublished_professionals_cannot_hibernate_error':
            snackbar.enqueueSnackbar(intl.formatMessage(AccountHibernationTranslations.unpublishedError), {
              variant: 'error',
            });
            break;
          case 'incoherent_timeline':
            snackbar.enqueueSnackbar(intl.formatMessage(AccountHibernationTranslations.incoherentTimeline), {
              variant: 'error',
            });
            break;
          default:
            snackbar.enqueueSnackbar(intl.formatMessage(AccountHibernationTranslations.unknown), {
              variant: 'error',
            });
            break;
        }
      });
  };

  const undoHibernation = () => {
    setIsCalendarOpened(false);
    setStartDate(null);
    setEndDate(null);
    setStartHour(null);
    setEndHour(null);
    setFocusedInput('startDate');
  };

  const awakeFromHibernation = async () => {
    if (!hibernation?.id) return;
    await destroyHibernation({ variables: { id: hibernation.id } });
    window.location.reload();
  };

  const toggleCalendar = () => {
    if (hibernation) return;
    setIsCalendarOpened(!isCalendarOpened);
  };

  return (
    <AutosubmitSection
      title={intl.formatMessage(AccountHibernationTranslations.sectionTitle)}
      description={intl.formatMessage(AccountHibernationTranslations.sectionDescription)}
      fieldsetName="account-hibernation"
    >
      <div className="account-hibernation">
        <Container fluid>
          <Col md={5}>
            <label>{intl.formatMessage(AccountHibernationTranslations.startDatetimeLabel)}</label>
            <HibernationDatetimeInput
              datetime={profileStartAt}
              toggleCalendar={toggleCalendar}
            />
          </Col>
          <Col md={5}>
            <label>{intl.formatMessage(AccountHibernationTranslations.endDatetimeLabel)}</label>
            <HibernationDatetimeInput
              datetime={profileEndAt}
              toggleCalendar={toggleCalendar}
            />
          </Col>
          {hibernation && (
            <Col md={1}>
              <button
                className="hibernation-delete-button"
                onClick={awakeFromHibernation}
              >
                X
              </button>
            </Col>
          )}
        </Container>

        {isCalendarOpened && (
          <div className="account-hibernation-calendar">
            {width < breakpoints.sm && (
              <div className="mobile-input-preview">
                <HibernationMobileDatetimeInput
                  date={startDate}
                  hour={startHour}
                  active={focusedInput === 'startDate'}
                />
                <HibernationMobileDatetimeInput
                  date={endDate}
                  hour={endHour}
                  active={focusedInput === 'endDate'}
                />
              </div>
            )}
            <DayPickerRangeController
              startDate={startDate}
              endDate={endDate}
              onDatesChange={setRange}
              focusedInput={focusedInput}
              onFocusChange={toggleFocusedInput}
              isOutsideRange={isOutsideRange}
              numberOfMonths={width > 769 ? 2 : 1}
              hideKeyboardShortcutsPanel
              noBorder
              initialVisibleMonth={() => moment()}
            />
            <div className="time-pickers">
              {width < breakpoints.sm && focusedInput === 'startDate' && (
                <label>{intl.formatMessage(AccountHibernationTranslations.startHourInputLabel)}</label>
              )}
              {(width > breakpoints.sm || focusedInput === 'startDate') && (
                <FlatTimePicker
                  value={startHour}
                  onChoose={onChooseStartHour}
                />
              )}
              {width < breakpoints.sm && focusedInput === 'endDate' && (
                <label>{intl.formatMessage(AccountHibernationTranslations.endHourInputLabel)}</label>
              )}
              {(width > breakpoints.sm || focusedInput === 'endDate') && (
                <FlatTimePicker
                  value={endHour}
                  onChoose={onChooseEndHour}
                />
              )}
            </div>

            <button
              onClick={undoHibernation}
              className="cancel-hibernation-button"
            >
              {intl.formatMessage(AccountHibernationTranslations.cancelButton)}
            </button>

            <button
              onClick={validate}
              className="submit-hibernation-button"
            >
              {intl.formatMessage(AccountHibernationTranslations.submitButton)}
            </button>
          </div>
        )}
      </div>
    </AutosubmitSection>
  );
};

export default AccountHibernation;
