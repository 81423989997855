import { defineMessages } from 'react-intl';

export default defineMessages({
  success: {
    id: 'employeeShow.useArchiveAction.success',
    defaultMessage: '1 profil archivé',
    description: 'success archivage des X profils',
  },
  oneProfileCannotArchived: {
    id: 'employeeShow.useArchiveAction.oneProfileCannotArchived',
    defaultMessage: "Désolé, le profil n'a pas pu être archivé",
    description: 'Un profil n a pas pu être archivé',
  },
});
