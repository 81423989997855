import { useEffect, useState } from 'react';
import Storage from '../enums/storage';
import { getStorageItem } from '../utils/storage';
import fr from '../i18n/fr.json';
import en from '../i18n/en.json';

export type Languages = 'fr' | 'en';

/* istanbul ignore next */
function loadLocaleData(locale: string) {
  switch (locale) {
    case 'en':
      return en;
    default:
      return fr;
  }
}

export function useLanguage() {
  const [language, setLanguage] = useState<Languages>((getStorageItem(Storage.LANGUAGE, 'fr') as Languages) || 'fr'); // no choice
  const [loading, setLoading] = useState(true);
  const [translations, setTranslations] = useState({});
  useEffect(() => {
    async function load() {
      if (import.meta.env.NODE_ENV === 'test') return; // avoid useless action on test
      setTranslations(await loadLocaleData(language));
      setLoading(false);
    }

    load();
  }, [language]);
  return {
    loading,
    translations,
    language: ['fr', 'en'].includes(language) ? language : 'fr',
    setLanguage,
  };
}
