import React, { useRef, useState } from 'react';
import ChevronDownIcon from '../../../../../../../icons/component-icons/ChevronDownIcon';
import CalendarPlusIcon from '../../../../../../../icons/component-icons/CalendarPlusIcon';
import classnames from 'classnames';
import { Link, useLocation } from 'react-router';
import { CompanyReportRange } from '../../../../../../../@types/graphql.d';
import { useOutsideClickEffect } from '../../../../../../../utils/hooks/useOutsideClickEffect';
import { useCurrentRange } from '../../../../../hooks/useCurrentRange';
import translations from './CompanyReportRangeSelector.translations';

import styles from './CompanyReportRangeSelector.module.scss';
import { useIntl } from 'react-intl';

function CompanyReportRangeSelector() {
  const intl = useIntl();
  const currentLocation = useLocation();
  const currentRange = useCurrentRange();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  function toggleDropdown() {
    setDropdownOpen(!isDropdownOpen);
  }
  function closeDropdown() {
    setDropdownOpen(false);
  }
  useOutsideClickEffect(closeDropdown, dropdownRef);
  return (
    <div
      ref={dropdownRef}
      className={classnames(styles.rangeSelector, {
        [styles.open]: isDropdownOpen,
      })}
    >
      <button
        data-testid="company-report-range-selector"
        type="button"
        onClick={toggleDropdown}
        className={styles.button}
      >
        <CalendarPlusIcon className={styles.icon} />
        {intl.formatMessage(translations[currentRange])}
        <ChevronDownIcon className={styles.caret} />
      </button>
      {isDropdownOpen && (
        <div
          data-testid="company-report-range-dropdown"
          className={styles.dropdown}
        >
          <ul>
            {Object.values(CompanyReportRange).map((range) => (
              <li
                key={range}
                className={styles.dropdownOption}
              >
                <Link
                  to={`${currentLocation.pathname}?range=${range}`}
                  onClick={closeDropdown}
                >
                  {intl.formatMessage(translations[range])}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default CompanyReportRangeSelector;
