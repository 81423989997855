import React from 'react';
import classnames from 'classnames';

import styles from './CompanyAdminDashboardContact.module.scss';
import translations from './CompanyAdminDashboardContact.translations';
import { FormattedMessage, useIntl } from 'react-intl';
import { goToSalesCalendar } from '../../../../../utils/redirection.util';
import ahoy from 'ahoy.js';

interface CompanyAdminDashboardContactProps {
  className?: string;
}
function CompanyAdminDashboardContact({ className }: CompanyAdminDashboardContactProps) {
  const intl = useIntl();

  const handleLinkClick = () => {
    ahoy.track('b2b_connect_asked_demo');
    goToSalesCalendar();
  };

  return (
    <main className={classnames(className, styles.contact)}>
      <h1>
        <FormattedMessage
          id={translations.title.id}
          values={{
            span: (chunks) => <span style={{ color: '#D1AD47' }}>{chunks}</span>,
          }}
        />
      </h1>
      <a
        onClick={handleLinkClick}
        className={styles.link}
      >
        {intl.formatMessage(translations.link)}
      </a>
    </main>
  );
}

export default CompanyAdminDashboardContact;
