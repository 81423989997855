import { defineMessages } from 'react-intl';

export const ActivityTranslations = defineMessages({
  title: {
    id: 'Activity.title',
    defaultMessage: 'Activité',
  },
  conversations: {
    id: 'Activity.conversations',
    defaultMessage: '{count, plural, one {Conversation} other {Conversations}}',
  },
  profileViews: {
    id: 'Activity.profileViews',
    defaultMessage: '{count, plural, one {Vue du profil} other {Vues du profil}}',
  },
  profileVisited: {
    id: 'Activity.profileVisited',
    defaultMessage: '{count, plural, one {Profil visité} other {Profils visités}}',
  },
  wishes: {
    id: 'Activity.wishes',
    defaultMessage: '{count, plural, one {Favori} other {Favoris}}',
  },
  wishesReceived: {
    id: 'Activity.wishesReceived',
    defaultMessage: '{count, plural, one {Fois en favori} other {Fois en favoris}}',
  },
  individualAppointments: {
    id: 'Activity.individualAppointments',
    defaultMessage: '{count, plural, one {RDV individuel} other {RDV individuels}}',
  },
  collectiveAppointments: {
    id: 'Activity.collectiveAppointments',
    defaultMessage: '{count, plural, one {RDV collectif} other {RDV collectifs}}',
  },
  impactedMembers: {
    id: 'Activity.impactedMembers',
    defaultMessage: '{count, plural, one {Membre impacté} other {Membres impactés}}',
  },
  likes: {
    id: 'Activity.likes',
    defaultMessage: '{count, plural, one {Like} other {Likes}}',
  },
  responseRate: {
    id: 'Activity.responseRate',
    defaultMessage: 'Taux de réponse',
  },
});
