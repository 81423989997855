import { defineMessages } from 'react-intl';

export const SearchInputTranslations = defineMessages({
  placeholderProfessional: {
    id: 'SearchInput.placeholderProfessional',
    defaultMessage: 'Mot-clé, poste, entreprise...',
  },
  placeholderStudent: {
    id: 'SearchInput.placeholderStudent',
    defaultMessage: 'Mot-clé, formation, établissement...',
  },
  featuredFacet: {
    id: 'SearchInput.featuredFacet',
    defaultMessage: 'Vous êtes plutôt ?',
  },
});
