import { defineMessages } from 'react-intl';

export default defineMessages({
  mention: {
    id: 'SoftDeletionAppointmentReminder.mention',
    defaultMessage: 'Vous ne pouvez pas supprimer votre compte car vous avez un rendez-vous prévu dans le futur.',
  },
  description: {
    id: 'SoftDeletionAppointmentReminder.description',
    defaultMessage: "Vous devez soit annuler ce rendez-vous, soit l'honorer avant de pouvoir supprimer votre compte",
  },
  submitButton: {
    id: 'SoftDeletionAppointmentReminder.submitButton',
    defaultMessage: 'Voir mes rendez-vous',
  },
});
