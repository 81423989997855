import { defineMessages } from 'react-intl';

export default defineMessages({
  workingAreaNameTitle: {
    id: 'RecruitingWorkingAreasRead.workingAreaNameTitle',
    defaultMessage: 'Liste des domaines employables',
  },
  workingAreas: {
    id: 'RecruitingWorkingAreasRead.values',
    defaultMessage: "Liste des domaines d'emploi",
  },
  formationsList: {
    id: 'RecruitingWorkingAreasRead.formationsList',
    defaultMessage: 'Liste des formations',
  },
  emptyWorkingAreas: {
    id: 'RecruitingWorkingAreasRead.emptyWorkingAreas',
    defaultMessage: "Aucun domaines d'emploi promu",
  },
  emptyFormationAreas: {
    id: 'RecruitingWorkingAreasRead.emptyFormationAreas',
    defaultMessage: 'Aucune formation promu',
  },
});
