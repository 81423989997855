import { defineMessages } from 'react-intl';

export default defineMessages({
  firstName: {
    id: 'ImportEmployeesModal.firstName',
    defaultMessage: 'Prénom',
  },
  lastName: {
    id: 'ImportEmployeesModal.lastName',
    defaultMessage: 'Nom',
  },
  position: {
    id: 'ImportEmployeesModal.position',
    defaultMessage: 'Position',
  },
  selectFile: {
    id: 'ImportEmployeesModal.selectFile',
    defaultMessage: 'Sélectionner un fichier',
  },
  description: {
    id: 'ImportEmployeesModal.description',
    defaultMessage:
      'Vous pouvez ici télécharger la liste des Ambassadeurs sur la plateforme. En suivant les étapes suivantes vous pourrez également changer les différents attributs correspondants à chacun des Ambassadeurs.',
  },
  title: {
    id: 'ImportEmployeesModal.title',
    defaultMessage: 'Télécharger la liste des Ambassadeurs /\n Modifier les attributs des Ambassadeurs',
  },
  downloadError: {
    id: 'ImportEmployeesModal.downloadError',
    defaultMessage: "Erreur : le fichier n'a pas pu être téléchargé. Réessayez plus tard.",
  },
  uploadError: {
    id: 'ImportEmployeesModal.uploadError',
    defaultMessage: "Erreur : le fichier n'a pas pu être envoye",
  },
  confirmError: {
    id: 'ImportEmployeesModal.confirmError',
    defaultMessage: "Erreur : les Ambassadeurs n'ont pas pu etre importe",
  },
  importSuccess: {
    id: 'ImportEmployeesModal.importSuccess',
    defaultMessage: 'Les Ambassadeurs ont été mis a jour',
  },
  cancelText: {
    id: 'ImportEmployeesModal.cancelText',
    defaultMessage: 'Annuler',
  },
  submitText: {
    id: 'ImportEmployeesModal.submitText',
    defaultMessage: 'Confirmer',
  },
  displayChangesStep: {
    id: 'ImportEmployeesModal.displayChangesStep',
    defaultMessage: 'Vérifier les modifications ci-dessous une fois le document importé',
  },
  sendEmployeesList: {
    id: 'ImportEmployeesModal.sendEmployeesList',
    defaultMessage:
      'Importer de nouveau la liste des Ambassadeurs avec les changements des attributs (pensez à enregistrer vos changements) :',
  },
  downloadEmployeesList: {
    id: 'ImportEmployeesModal.downloadEmployeesList',
    defaultMessage: 'Télécharger la liste des employés en cliquant sur le lien ci-dessous :',
  },
  confirmEdition: {
    id: 'ImportEmployeesModal.confirmEdition',
    defaultMessage: 'Confirmer les modifications',
  },
  updateEmployees: {
    id: 'ImportEmployeesModal.updateEmployees',
    defaultMessage:
      'Mettez à jour les attributs des Ambassadeurs que vous souhaitez changer directement sur votre fichier',
  },
  noDocument: {
    id: 'ImportEmployeesModal.noDocument',
    defaultMessage: 'Aucun document envoyé',
  },
  noChange: {
    id: 'ImportEmployeesModal.noChange',
    defaultMessage: 'Aucun changement detecté',
  },
});
