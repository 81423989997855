import classNames from 'classnames';
import React, { memo, PropsWithChildren } from 'react';

import './Callout.scss';

type Variant = 'default' | 'warning';

type CalloutProps = PropsWithChildren<{
  title: string;
  variant?: Variant;
}>;

export const Callout = memo(({ title, variant = 'default', children }: CalloutProps) => (
  <div className={classNames('callout', `callout--${variant}`)}>
    <div className="callout__title">{title}</div>
    {children}
  </div>
));
