import { defineMessages } from 'react-intl';

export default defineMessages({
  activity: {
    id: 'CompanyAdminDashboardView.activity',
    defaultMessage: 'Activité',
  },
  attractiveness: {
    id: 'CompanyAdminDashboardView.attractiveness',
    defaultMessage: 'Attractivité',
  },
  topEmployees: {
    id: 'CompanyAdminDashboardView.topEmployees',
    defaultMessage: 'Top 3 Ambassadeurs',
  },
  upgradeYourPack: {
    id: 'CompanyAdminDashboardView.upgradeYourPack',
    defaultMessage: 'Données sur l’activité de vos ambassadeurs',
  },
  lockedData: {
    id: 'CompanyAdminDashboardView.lockedData',
    defaultMessage: 'Cette donnée est accessible avec l\'offre communauté',
  },
});
