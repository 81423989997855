import { useQuery } from '@apollo/client';
import { ConnectCompanyContextQuery, ConnectCompanyContextQueryVariables } from '../../../../../@types/graphql.d';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { Layout } from './components/layout/Layout';
import { ConnectCompanyContext } from './ConnectCompanyContext';
import { CONNECT_COMPANY_CONTEXT_QUERY } from './gqls/ConnectCompanyContext.gql';
import { CompanyPresentation } from './sections/company-presentation/CompanyPresentation';
import { CompanyStyling } from './sections/company-styling/CompanyStyling';
import { EmployeesSignup } from './sections/employees-signup/EmployeesSignup';
import { GeneralInformations } from './sections/general-informations/GeneralInformations';
import { MarketingCommunication } from './sections/marketing-communication/MarketingCommunication';
import { RecruitingWorkingAreas } from './sections/recruiting-working-areas/RecruitingWorkingAreas';
import useCompanyUnique from '../../../../company-admin/hooks/useCompanyUnique/useCompanyUnique';
import Routes from '../../../../../routes';
import ahoy from 'ahoy.js';

const translations = defineMessages({
  companyNotFound: {
    id: 'ConnectCompanyHome.companyNotFound',
    defaultMessage: "Cette entreprise n'existe pas.",
  },
});

export default function ConnectCompanyHome() {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const id = useCompanyUnique() ?? '';
  const intl = useIntl();

  const { data } = useQuery<ConnectCompanyContextQuery, ConnectCompanyContextQueryVariables>(
    CONNECT_COMPANY_CONTEXT_QUERY,
    {
      skip: !id,
      variables: { id },
      onError: () => {
        snackbar.enqueueSnackbar(intl.formatMessage(translations.companyNotFound), { variant: 'error' });
        navigate(Routes.dashboard);
      },
    }
  );

  useEffect(() => {
    if (data?.company?.name) {
      ahoy.track('b2b_connect_viewed_homepage', {
        companyId: id,
        companyName: data.company.name,
      });
    }
  }, [id, data?.company?.name]);

  //TODO add skeleton loader
  if (!data) return null;

  return (
    <ConnectCompanyContext.Provider value={data?.company}>
      <Layout>
        <GeneralInformations />
        <CompanyPresentation />
        <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
          <RecruitingWorkingAreas />
        </div>
        <EmployeesSignup />
        <MarketingCommunication />
        <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
          <CompanyStyling />
        </div>
      </Layout>
    </ConnectCompanyContext.Provider>
  );
}
