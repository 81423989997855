import { gql } from '@apollo/client';

export const IDENTITY_QUERY = gql`
  query AmbassadorIdentity($id: ID!) {
    getUser(id: $id) {
      profileType
      profileBannerDesktop {
        url
      }
      profileBannerMobile {
        url
      }
      avatars {
        url
      }
      companyLogo {
        url
      }
      isPremiumAmbassador
      firstName
      lastName
      publicLastName
      position
      companyName
      meetingPlace
      likesReceivedCount
      seenProfilesCount
      unavailableUntil
      ambassadorProfileViewsCount
      currentGrade
      currentSchoolName
      preparedDiplomaTitle
      previousCompanyLogo {
        url
      }
      previousCompanyName
      previousPosition
    }
  }
`;
