import React from 'react';
import translations from './StudentFeedback.translations';

import './StudentFeedback.scss';
import { useIntl } from 'react-intl';
import { MJGFormattedMessage } from '../../../../../../../components/mjg-formatted-message/MJGFormattedMessage';

type StudentFeedbackProps = {
  reviewerOrganizationName: string;
  reviewerTitle: string;
  feedback: string;
};
function StudentFeedback({ reviewerOrganizationName, reviewerTitle, feedback }: StudentFeedbackProps) {
  const intl = useIntl();
  return (
    <div className="student-feedback">
      <MJGFormattedMessage
        translation={translations.description}
        values={{ title: reviewerTitle, organization: reviewerOrganizationName }}
      />
      <p>{`"${feedback}"`}</p>
    </div>
  );
}

export default StudentFeedback;
