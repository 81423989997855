import { LandingPanel } from './LandingPanel';
import { FirstQuestionPanel } from './FirstQuestionPanel';
import { SecondThirdQuestionsPanel } from './SecondThirdQuestionsPanel';
import { DidNotShowUpPanel } from './DidNotShowUpPanel';
import { RecommendPanel } from './RecommendPanel';
import { WantsToRecontactPanel } from './WantsToRecontactPanel';
import { CompanyAspirationPanel } from './CompanyAspirationPanel';
import { ReviewPanel } from './Panel';
import { SubmittingPanel } from './SubmittingPanel';
import { RecommendHRPanel } from './RecommendHRPanel';
import { Appointment } from '../Review.gql';
import {
  firstQuestionPaneValidationSchema,
  secondThirdQuestionsPaneValidationSchema,
  recommendHRPaneValidationSchema,
  recommendPaneValidationSchema,
  wantsToRecontactPaneValidationSchema,
  didNotShowUpPaneValidationSchema,
  companyAspirationPaneValidationSchema,
  schoolAspirationPaneValidationSchema,
} from './validations';
import { SchoolAspirationPanel } from './SchoolAspirationPanel';
import { PerceptionPanel } from './PerceptionPanel';

const ok = (...conditions: boolean[]) => conditions.every((c) => c === true);
const nok = (...conditions: boolean[]) => conditions.every((c) => c === false);

/* eslint-disable */
export const REVIEW_PANELS: ReviewPanel[] = [
  { panel: LandingPanel },
  { panel: FirstQuestionPanel, condition: (c) => ok(c.didShowUp), validationSchema: firstQuestionPaneValidationSchema },
  { panel: SecondThirdQuestionsPanel, condition: (c) => ok(c.didShowUp), validationSchema: secondThirdQuestionsPaneValidationSchema },
  { panel: PerceptionPanel, condition: (c) => ok(c.didShowUp, c.asMember, c.activePerceptionPanel) },
  { panel: RecommendHRPanel, condition: (c) => ok(c.didShowUp, c.asAmbassador, c.messageForHRRequired), validationSchema: recommendHRPaneValidationSchema },
  { panel: RecommendPanel, condition: (c) => ok(c.didShowUp), validationSchema: recommendPaneValidationSchema },
  { panel: WantsToRecontactPanel, condition: (c) => ok(c.didShowUp, c.asMember, c.canBeRecontacted), validationSchema: wantsToRecontactPaneValidationSchema },
  { panel: CompanyAspirationPanel, condition: (c) => ok(c.didShowUp, c.asMember, c.canBeRecontacted, c.wantsToBeRecontacted, c.shouldFillCompanyAspirationPanel), validationSchema: companyAspirationPaneValidationSchema },
  { panel: SchoolAspirationPanel, condition: (c) => ok(c.didShowUp, c.asMember, c.canBeRecontacted, c.wantsToBeRecontacted, c.shouldFillSchoolAspirationPanel), validationSchema: schoolAspirationPaneValidationSchema },
  { panel: DidNotShowUpPanel, condition: (c) => nok(c.didShowUp), validationSchema: didNotShowUpPaneValidationSchema },
  { panel: SubmittingPanel, isSubmittingPanel: true },
];
/* eslint-enable */

export const getQuestion = (appointment: Appointment, questionNumber: number): string | undefined => {
  if (appointment.currentUserIsAmbassador)
    return (appointment.ambassadorReview as any)[`reviewQuestion${questionNumber}`];
  return (appointment.memberReview as any)[`reviewQuestion${questionNumber}`];
};
