import { gql } from '@apollo/client';

export const GET_COMPANY_INFORMATION_FOR_DASHBOARD = gql`
  query GET_COMPANY_INFORMATION_FOR_DASHBOARD($companyId: ID!) {
    company(companyId: $companyId) {
      id
      name
      companyLogo
      publishedEmployeesCount
      employeePackSize
      activeProfilePercentage
      lastReportGeneratedAt
      isPremiumSchool
    }
  }
`;
