import { defineMessages } from 'react-intl';

export default defineMessages({
  impossibleAppointment: {
    id: 'ProfessionalSoftDeleted.impossibleAppointment',
    defaultMessage: 'rendez-vous impossible',
    description: 'status rdv impossible',
  },
  inactiveProfile: {
    id: 'ProfessionalSoftDeleted.inactiveProfile',
    defaultMessage: 'Profil supprimé',
    description: 'profile supprimé car soft deleted',
  },
});
