import React from 'react';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Illu404 from '../../icons/illu_404.svg';
import Routes from '../../routes';

import './NotFound.scss';

const NotFound = () => (
  <div className="not-found">
    <img
      src={Illu404}
      alt="not-found"
    />

    <FormattedMessage
      id="NotFound.title"
      defaultMessage="Désolé, aucune page à l'horizon ..."
      description="titre page 404"
    >
      {(txt) => <p>{txt}</p>}
    </FormattedMessage>

    <FormattedMessage
      id="NotFound.button"
      defaultMessage="retour à l'accueil"
      description="titre page 404"
    >
      {(txt) => (
        <Link
          to={Routes.home}
          className="not-found-button"
        >
          {txt}
        </Link>
      )}
    </FormattedMessage>
  </div>
);

export default NotFound;
