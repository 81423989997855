import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import {
  GetCompanyAuthorizationTopEmployeeQuery,
  GetCompanyAuthorizationTopEmployeeQueryVariables,
  Get_Global_Company_ReportQuery,
  Get_Global_Company_ReportQueryVariables,
} from '../../../@types/graphql.d';
import { PageLoader } from '../../../components/loader/Loader';
import NoNetwork from '../../common/no-network/NoNetwork';
import { useCurrentRange } from '../hooks/useCurrentRange';
import useCompanyUnique from '../hooks/useCompanyUnique/useCompanyUnique';
import CompanyAdminDashboardActivityBloc from './components/company-admin-dashboard-activity-bloc/CompanyAdminDashboardActivityBloc';
import CompanyAdminDashboardAttractivenessBloc from './components/company-admin-dashboard-attractiveness-bloc/CompanyAdminDashboardAttractivenessBloc';
import CompanyAdminDashboardBloc from './components/company-admin-dashboard-bloc/CompanyAdminDashboardBloc';
import CompanyAdminDashboardDriver from './components/company-admin-dashboard-driver/CompanyAdminDashboardDriver';
import CompanyAdminDashboardFeedback from './components/company-admin-dashboard-feedback/CompanyAdminDashboardFeedback';
import CompanyAdminDashboardHeader from './components/company-admin-dashboard-header/CompanyAdminDashboardHeader';
import CompanyAdminDashboardTopEmployees from './components/company-admin-dashboard-top-employees/CompanyAdminDashboardTopEmployees';
import {
  GET_COMPANY_AUTHORIZATION_TOP_EMPLOYEE_QUERY,
  GET_GLOBAL_COMPANY_REPORT,
} from './CompanyAdminDashboardView.gql';
import translations from './CompanyAdminDashboardView.translations';
import ahoy from 'ahoy.js';

import styles from './CompanyAdminDashboardView.module.scss';
import { useIntl } from 'react-intl';
import CompanyAdminDashboardContact from './components/company-admin-dashboard-contact/CompanyAdminDashboardContact';

export default function CompanyAdminDashboardView() {
  const intl = useIntl();
  const companyId = useCompanyUnique();
  const currentRange = useCurrentRange();

  const { error, refetch } = useQuery<Get_Global_Company_ReportQuery, Get_Global_Company_ReportQueryVariables>(
    GET_GLOBAL_COMPANY_REPORT,
    {
      skip: !companyId,
      variables: {
        companyId: companyId || '',
        range: currentRange,
      },
    },
  );

  const { data: canAccess } = useQuery<
    GetCompanyAuthorizationTopEmployeeQuery,
    GetCompanyAuthorizationTopEmployeeQueryVariables
  >(GET_COMPANY_AUTHORIZATION_TOP_EMPLOYEE_QUERY, {
    skip: !companyId,
    variables: { companyId: companyId! },
    fetchPolicy: 'network-only',
  });

  if (!companyId) {
    return <PageLoader />;
  }

  useEffect(() => {
    if (companyId && canAccess?.company?.name) {
      ahoy.track('b2b_connect_viewed_dashboard_page', {
        companyId: companyId,
        companyName: canAccess.company.name,
      });
    }
  }, [companyId, canAccess?.company?.name]);

  if (error) {
    return (
      <main className={styles.companyAdminDashboard}>
        <CompanyAdminDashboardHeader className={styles.header} />
        <NoNetwork refetch={refetch} />
      </main>
    );
  }
  return (
    <main className={styles.companyAdminDashboard}>
      <CompanyAdminDashboardHeader className={styles.header} />
      <CompanyAdminDashboardDriver />

      <div className={styles.blocContainer}>
        <CompanyAdminDashboardBloc
          title={intl.formatMessage(translations.activity)}
          className={styles.activity}
          icon={!canAccess?.company?.canShowFullDashboardStats}
          component={CompanyAdminDashboardActivityBloc}
        />
        <CompanyAdminDashboardBloc
          title={intl.formatMessage(translations.attractiveness)}
          className={styles.attractiveness}
          icon={!canAccess?.company?.canShowFullDashboardStats}
          component={CompanyAdminDashboardAttractivenessBloc}
        />
        <CompanyAdminDashboardBloc
          title={intl.formatMessage(translations.topEmployees)}
          className={styles.topEmployees}
          icon={!canAccess?.company?.canShowDashboardEmployeesRanking}
          component={CompanyAdminDashboardTopEmployees}
        />
        {canAccess?.company?.canShowFullDashboardStats ? (
          <CompanyAdminDashboardFeedback className={styles.feedback} />
        ) : <CompanyAdminDashboardContact className={styles.feedback} />}
      </div>
    </main>
  );
}
