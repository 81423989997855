import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Field, Formik, FormikHelpers } from 'formik';
import { CustomSelectField } from '@myjobglasses/dsm';
import {
  Company,
  CompanySize,
  Get_Company_SectorsQuery,
  Get_Company_SectorsQueryVariables,
  SecondarySituationEnum,
  Update_Company_General_InformationsMutation,
  Update_Company_General_InformationsMutationVariables,
} from '../../../../../../../@types/graphql.d';
import useFormikMutation from '../../../../../../../utils/hooks/useFormikMutation';
import FormikStatusError from '../../../../../../common/formik/components/formik-status-error/FormikStatusError';
import { GET_COMPANY_SECTORS, UPDATE_COMPANY_GENERAL_INFORMATIONS } from './CompanyGeneralInformationsWrite.gql';
import translations, { errorTranslations } from './CompanyGeneralInformationsWrite.translations';

import styles from './CompanyGeneralInformationsWrite.module.scss';
import { SettingsContext } from '../../../../../../../contexts/settings-context/SettingsContext';
import { useIntl } from 'react-intl';
import TextInput from '../../../../../../../components/text-input/TextInput';

interface FormValues {
  companyId: string;
  name: string | undefined;
  size?: CompanySize | undefined;
  sectorId?: string | undefined;
  establishmentType?: SecondarySituationEnum | undefined;
}
interface CompanyGeneralInformationsWriteProps {
  company?: Pick<
    Company,
    'id' | 'name' | 'companyLogo' | 'companySector' | 'companySize' | 'isPremiumSchool' | 'establishmentType'
  >;
  finish: () => void;
}
function CompanyGeneralInformationsWrite({ company, finish }: CompanyGeneralInformationsWriteProps) {
  const intl = useIntl();
  const { data } = useQuery<Get_Company_SectorsQuery, Get_Company_SectorsQueryVariables>(GET_COMPANY_SECTORS);

  const [mutate] = useFormikMutation<
    Update_Company_General_InformationsMutation,
    Update_Company_General_InformationsMutationVariables
  >(UPDATE_COMPANY_GENERAL_INFORMATIONS, {
    refetchQueries: ['GET_COMPANY_GENERAL_INFORMATIONS'],
  });

  const settingsContext = useContext(SettingsContext);
  const secondarySituations = settingsContext?.settings?.primarySituations[0].secondarySituations;

  // handle submit
  function onSubmit(values: FormValues, formikBag: FormikHelpers<FormValues>) {
    if (company?.id) {
      const newSector = data?.companySectors?.find(({ name }) => name === values.sectorId);
      return mutate(
        {
          companyId: company.id,
          size: values.size,
          sectorId: newSector?.id,
          establishmentType: values.establishmentType,
        },
        formikBag as FormikHelpers<{
          [K in keyof Update_Company_General_InformationsMutationVariables]?: any;
        }>,
      ).then((result) => {
        if (result.data?.updateCompany) {
          finish();
        }
      });
    }
    return Promise.reject(new Error('Missing company id'));
  }

  return (
    <Formik
      initialValues={{
        companyId: company?.id || '',
        name: company?.name,
        sectorId: company?.companySector ?? undefined,
        size: company?.companySize ?? undefined,
        establishmentType: company?.establishmentType?.key ?? undefined,
      }}
      onSubmit={(values, formikBag: FormikHelpers<FormValues>) => onSubmit(values, formikBag)}
    >
      {({ handleSubmit, isSubmitting }) => (
        <div className={styles.companyGeneralInformationsWrite}>
          <div className={styles.avatarWrapper}>
            <img
              src={company?.companyLogo || 'https://assets.myjobglasses.com/images/fallback-company.png'}
              alt=""
            />
          </div>
          <form
            className={styles.form}
            onSubmit={handleSubmit}
          >
            <Field
              name="name"
              component={TextInput}
              placeholder={intl.formatMessage(translations.companyNameLabel)}
              className={styles.field}
              disabled
            />
            {!company?.isPremiumSchool && (
              <Field
                name="sectorId"
                component={CustomSelectField}
                label={intl.formatMessage(translations.companySectorLabel)}
                className={styles.field}
                data={[...(data?.companySectors || [])]
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((sector) => ({
                    value: sector.name,
                    label: sector.name,
                  }))}
              />
            )}
            {!company?.isPremiumSchool ? (
              <Field
                name="sectorId"
                component={CustomSelectField}
                label={intl.formatMessage(translations.companySectorLabel)}
                className={styles.field}
                data={[...(data?.companySectors || [])]
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((sector) => ({
                    value: sector.name,
                    label: sector.name,
                  }))}
              />
            ) : (
              <Field
                name="establishmentType"
                component={CustomSelectField}
                label={intl.formatMessage(translations.establishmentType)}
                className={styles.field}
                data={secondarySituations?.map((situation) => ({
                  value: situation.key,
                  label: situation.name,
                }))}
              />
            )}
            <FormikStatusError
              className={styles.error}
              translations={errorTranslations}
            />
            <div className={styles.actions}>
              <button
                type="button"
                onClick={finish}
                className={styles.cancelButton}
              >
                {intl.formatMessage(translations.cancelButton)}
              </button>
              <button
                type="submit"
                className={styles.submitButton}
                disabled={isSubmitting}
              >
                {intl.formatMessage(translations.submitButton)}
              </button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
}

export default CompanyGeneralInformationsWrite;
