import { defineMessages } from 'react-intl';

export default defineMessages({
  appointmentTypePhysical: {
    id: 'AppointmentDisplayType.appointmentTypePhysical',
    defaultMessage: 'Rendez-vous physique',
    description: 'type RDV physique',
  },
  appointmentTypePhone: {
    id: 'AppointmentDisplayType.appointmentTypePhone',
    defaultMessage: 'Rendez-vous téléphonique',
    description: 'type RDV téléphonique',
  },
  appointmentTypeVideo: {
    id: 'AppointmentDisplayType.appointmentTypeVideo',
    defaultMessage: 'Rendez-vous vidéo',
    description: 'type RDV vidéo',
  },
});
