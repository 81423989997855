import React from 'react';
import translations from './NoJoinedPrograms.translations';

import styles from './NoJoinedPrograms.module.scss';
import { useIntl } from 'react-intl';
import { useLanguage } from '../../../../../hooks/useLanguages';

export default function NoJoinedPrograms() {
  const intl = useIntl();
  const { language } = useLanguage();
  return (
    <div className={styles.noJoinedPrograms}>
      <img
        src={`https://assets.myjobglasses.com/images/programs/no-programs-${language}.png`}
        className={styles.illustration}
        alt={intl.formatMessage(translations.title)}
      />
      <a
        href="https://help.myjobglasses.com/fr/articles/109073-comment-rejoindre-un-programme"
        className={styles.dashboardLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        {intl.formatMessage(translations.button)}
      </a>
    </div>
  );
}
