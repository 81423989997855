import { defineMessages } from 'react-intl';

export const AmbassadorCardTranslations = defineMessages({
  available: {
    id: 'AmbassadorCard.available',
    defaultMessage: 'Disponible',
  },
  unavailable: {
    id: 'AmbassadorCard.unavailable',
    defaultMessage: 'Indisponible',
  },
});
