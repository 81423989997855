import React from 'react';
import CompanyAdminDashboardCountBlue from './components/company-admin-dashboard-count-blue/CompanyAdminDashboardCountBlue';
import CompanyAdminDashboardCountGrey from './components/company-admin-dashboard-count-grey/CompanyAdminDashboardCountGrey';
import CompanyAdminDashboardCountWithIcon from './components/company-admin-dashboard-count-with-icon/CompanyAdminDashboardCountWithIcon';

interface CompanyAdminDashboardCountProps {
  title: string;
  tooltip?: string;
  picto?: (props: { className?: string }) => JSX.Element;
  value: number | '-';
  className?: string;
  variant?: 'grey' | 'blue';
  blurred?: boolean;
}
function CompanyAdminDashboardCount({
  title,
  tooltip,
  picto,
  value,
  className,
  variant,
  blurred,
}: CompanyAdminDashboardCountProps) {
  switch (variant) {
    case 'blue':
      return (
        <CompanyAdminDashboardCountBlue
          title={title}
          tooltip={tooltip}
          value={value}
          className={className}
          blurred={blurred}
        />
      );
    case 'grey':
      return (
        <CompanyAdminDashboardCountGrey
          title={title}
          tooltip={tooltip}
          value={value}
          className={className}
          blurred={blurred}
        />
      );
    default:
      return (
        <CompanyAdminDashboardCountWithIcon
          title={title}
          tooltip={tooltip}
          picto={picto}
          value={value}
          className={className}
          blurred={blurred}
        />
      );
  }
}

export default CompanyAdminDashboardCount;
