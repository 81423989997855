export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  Upload: any;
};

export type AdminAccreditation = {
  __typename?: 'AdminAccreditation';
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
  userId: Scalars['ID'];
};

export type AuthorizationResult = {
  __typename?: 'AuthorizationResult';
  /** Human-readable error message */
  message?: Maybe<Scalars['String']>;
  /** Reasons of check failure */
  reasons?: Maybe<FailureReasons>;
  /** Result of applying a policy rule */
  value: Scalars['Boolean'];
};

/** The certification_status of a Participation */
export enum CertificationStatus {
  CertificateDelivered = 'certificate_delivered',
  CertificateFailed = 'certificate_failed',
  CertificateUnavailable = 'certificate_unavailable',
}

export type Collaboration = {
  __typename?: 'Collaboration';
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  program: Program;
  programId: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
  userId: Scalars['ID'];
};

/** The connection type for Collaboration. */
export type CollaborationConnection = {
  __typename?: 'CollaborationConnection';
  edges: Array<CollaborationEdge>;
  nodes: Array<Collaboration>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type CollaborationEdge = {
  __typename?: 'CollaborationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Collaboration>;
};

export enum CollaborationFields {
  ArchivedAt = 'archivedAt',
  CreatedAt = 'createdAt',
  Id = 'id',
  ProgramId = 'programId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

/** Input for filtering a list of collaborations through queries */
export type CollaborationsFilterInput = {
  query?: Maybe<Array<CollaborationsQueryFilterInput>>;
};

/** Input for filtering a list of collaborations through queries, by a specific attribute */
export type CollaborationsQueryFilterInput = {
  filterBy: CollaborationFields;
  operator: QueryFilterOperator;
  value: Scalars['String'];
};

/** Input for sorting a list of collaborations through queries */
export type CollaborationsSortInput = {
  order: SortDirection;
  orderBy: CollaborationFields;
};

export type CompanySector = {
  __typename?: 'CompanySector';
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  company: Organization;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  sector: Sector;
  sectorId: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for CompanySector. */
export type CompanySectorConnection = {
  __typename?: 'CompanySectorConnection';
  edges: Array<CompanySectorEdge>;
  nodes: Array<CompanySector>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type CompanySectorEdge = {
  __typename?: 'CompanySectorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CompanySector>;
};

export type Conversation = {
  __typename?: 'Conversation';
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  meetings?: Maybe<MeetingConnection>;
  messages?: Maybe<MessageConnection>;
  participationConversations?: Maybe<ParticipationConversationConnection>;
  participations?: Maybe<ParticipationConnection>;
  updatedAt: Scalars['ISO8601DateTime'];
  userConversations?: Maybe<UserConversationConnection>;
  users?: Maybe<UserConnection>;
};

export type ConversationMeetingsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ConversationMessagesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ConversationParticipationConversationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ConversationParticipationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ConversationUserConversationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ConversationUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Input for creating a Designation */
export type CreateDesignationInput = {
  /** ID of the Program */
  programId: Scalars['ID'];
  /** ID of the User */
  userId: Scalars['ID'];
};

/** Input for creating a Group */
export type CreateGroupInput = {
  /** Name of the Group */
  name: Scalars['String'];
  /** ID of the parent Group */
  parentId?: Maybe<Scalars['ID']>;
  /** ID of the Project */
  projectId: Scalars['ID'];
};

/** Input for creating an Invitation */
export type CreateInvitationInput = {
  /** email */
  email: Scalars['String'];
  /** ID of the Project */
  projectId: Scalars['ID'];
  /** Role of the user in the project */
  role: InvitationRole;
};

/** Input for creating a Participation */
export type CreateParticipationInput = {
  participationCode: Scalars['String'];
};

/** Input for creating a Program */
export type CreateProgramInput = {
  /** Description of the Program */
  description?: Maybe<Scalars['String']>;
  /** ID of the Project */
  groupId?: Maybe<Scalars['ID']>;
  individualSupport: Scalars['Boolean'];
  /** Name of the Program */
  name?: Maybe<Scalars['String']>;
  /** ID of the Program Template */
  programTemplateId: Scalars['ID'];
  /** ID of the Project */
  projectId: Scalars['ID'];
};

/** Input for creating a Project */
export type CreateProjectInput = {
  /** Name of the Project */
  name: Scalars['String'];
  /** Name of the Organization */
  organizationName: Scalars['String'];
};

/** Input for creating a User */
export type CreateUserInput = {
  /** Email of the User */
  email: Scalars['String'];
  /** First name of the User */
  firstName: Scalars['String'];
  /** Last name of the User */
  lastName: Scalars['String'];
};

export type Designation = {
  __typename?: 'Designation';
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  program: Program;
  programId: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
  userId: Scalars['ID'];
};

/** The connection type for Designation. */
export type DesignationConnection = {
  __typename?: 'DesignationConnection';
  edges: Array<DesignationEdge>;
  nodes: Array<Designation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type DesignationEdge = {
  __typename?: 'DesignationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Designation>;
};

export enum DesignationFields {
  ArchivedAt = 'archivedAt',
  CreatedAt = 'createdAt',
  Id = 'id',
  ProgramId = 'programId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

/** Input for filtering a list of designations through queries */
export type DesignationsFilterInput = {
  query?: Maybe<Array<DesignationsQueryFilterInput>>;
};

/** Input for filtering a list of designations through queries, by a specific attribute */
export type DesignationsQueryFilterInput = {
  filterBy: DesignationFields;
  operator: QueryFilterOperator;
  value: Scalars['String'];
};

/** Input for sorting a list of designations through queries */
export type DesignationsSortInput = {
  order: SortDirection;
  orderBy: DesignationFields;
};

export type FailureReasons = {
  __typename?: 'FailureReasons';
  /** JSON-encoded map of reasons */
  details: Scalars['String'];
  /** Human-readable errors */
  fullMessages: Array<Scalars['String']>;
};

export type Feedback = {
  __typename?: 'Feedback';
  author: User;
  authorId: Scalars['ID'];
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  content: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  meeting: User;
  meetingId: Scalars['ID'];
  recipient: User;
  recipientId: Scalars['ID'];
  score: FeedbackScore;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for Feedback. */
export type FeedbackConnection = {
  __typename?: 'FeedbackConnection';
  edges: Array<FeedbackEdge>;
  nodes: Array<Feedback>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type FeedbackEdge = {
  __typename?: 'FeedbackEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Feedback>;
};

/** The score of a Feedback - between 1..5 */
export enum FeedbackScore {
  FeedbackScore_1 = 'FEEDBACK_SCORE_1',
  FeedbackScore_2 = 'FEEDBACK_SCORE_2',
  FeedbackScore_3 = 'FEEDBACK_SCORE_3',
  FeedbackScore_4 = 'FEEDBACK_SCORE_4',
  FeedbackScore_5 = 'FEEDBACK_SCORE_5',
}

export type Group = {
  __typename?: 'Group';
  canCreateGroup: AuthorizationResult;
  canCreateProgram: AuthorizationResult;
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  groups?: Maybe<GroupConnection>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
  organizationId: Scalars['ID'];
  parent?: Maybe<Group>;
  parentId?: Maybe<Scalars['ID']>;
  position: Scalars['Int'];
  programs?: Maybe<ProgramConnection>;
  programsStatistics: ProgramsStatistics;
  project: Project;
  projectId: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type GroupGroupsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type GroupProgramsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ProgramsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ProgramsSortInput>>;
};

/** The connection type for Group. */
export type GroupConnection = {
  __typename?: 'GroupConnection';
  edges: Array<GroupEdge>;
  nodes: Array<Group>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type GroupEdge = {
  __typename?: 'GroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Group>;
};

export type GroupFavorite = {
  __typename?: 'GroupFavorite';
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  favoriteGroupId: Scalars['ID'];
  group: Group;
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
  userId: Scalars['ID'];
};

export enum GroupFields {
  ArchivedAt = 'archivedAt',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  ParentId = 'parentId',
  Position = 'position',
  ProjectId = 'projectId',
  UpdatedAt = 'updatedAt',
}

/** Input for filtering a list of groups through queries */
export type GroupsFilterInput = {
  query?: Maybe<Array<GroupsQueryFilterInput>>;
  search?: Maybe<Scalars['String']>;
};

/** Input for filtering a list of groups through queries, by a specific attribute */
export type GroupsQueryFilterInput = {
  filterBy: GroupFields;
  operator: QueryFilterOperator;
  value: Scalars['String'];
};

/** Input for sorting a list of groups through queries */
export type GroupsSortInput = {
  order: SortDirection;
  orderBy: GroupFields;
};

export type HermesContact = {
  __typename?: 'HermesContact';
  anonymized: Scalars['Boolean'];
  appointmentContactMethod?: Maybe<HermesContactAppointmentContactMethod>;
  appointmentDate?: Maybe<Scalars['ISO8601DateTime']>;
  avatarUrl?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  companySector: Scalars['String'];
  conversationId: Scalars['String'];
  jobTitle: Scalars['String'];
  professionalFirstName: Scalars['String'];
  professionalUrl: Scalars['String'];
  state: HermesContactState;
};

export enum HermesContactAppointmentContactMethod {
  Phone = 'PHONE',
  Physical = 'PHYSICAL',
  Video = 'VIDEO',
}

export enum HermesContactState {
  InvalidatedAppointment = 'INVALIDATED_APPOINTMENT',
  NoScheduledAppointment = 'NO_SCHEDULED_APPOINTMENT',
  PastAndConfirmed = 'PAST_AND_CONFIRMED',
  PastAwaitingConfirmation = 'PAST_AWAITING_CONFIRMATION',
  ProfileReportedByTheProfessional = 'PROFILE_REPORTED_BY_THE_PROFESSIONAL',
  UnfollowedConversationWithThisProfessional = 'UNFOLLOWED_CONVERSATION_WITH_THIS_PROFESSIONAL',
  Upcoming = 'UPCOMING',
}

export type Invitation = {
  __typename?: 'Invitation';
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  author: User;
  authorId: Scalars['ID'];
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  invitationStatus: InvitationStatus;
  project: Project;
  projectId: Scalars['ID'];
  role: InvitationRole;
  target: User;
  targetId: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for Invitation. */
export type InvitationConnection = {
  __typename?: 'InvitationConnection';
  edges: Array<InvitationEdge>;
  nodes: Array<Invitation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type InvitationEdge = {
  __typename?: 'InvitationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Invitation>;
};

export enum InvitationFields {
  ArchivedAt = 'archivedAt',
  AuthorId = 'authorId',
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
  InvitationStatus = 'invitationStatus',
  ProjectId = 'projectId',
  Role = 'role',
  TargetId = 'targetId',
  UpdatedAt = 'updatedAt',
}

/** The role of one future User in Project - Invitation context */
export enum InvitationRole {
  Admin = 'admin',
  Companion = 'companion',
  Guest = 'guest',
}

/** Input for filtering a list of invitations through queries */
export type InvitationsFilterInput = {
  query?: Maybe<Array<InvitationsQueryFilterInput>>;
};

/** Input for filtering a list of invitations through queries, by a specific attribute */
export type InvitationsQueryFilterInput = {
  filterBy: InvitationFields;
  operator: QueryFilterOperator;
  value: Scalars['String'];
};

/** Input for sorting a list of invitations through queries */
export type InvitationsSortInput = {
  order: SortDirection;
  orderBy: InvitationFields;
};

export enum InvitationStatus {
  Accepted = 'accepted',
  Declined = 'declined',
  Sent = 'sent',
}

export type JobTitle = {
  __typename?: 'JobTitle';
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  companies?: Maybe<OrganizationConnection>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  userCompanies?: Maybe<UserCompanyConnection>;
  userCompanyJobs?: Maybe<UserCompanyJobConnection>;
};

export type JobTitleCompaniesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type JobTitleUserCompaniesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type JobTitleUserCompanyJobsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for JobTitle. */
export type JobTitleConnection = {
  __typename?: 'JobTitleConnection';
  edges: Array<JobTitleEdge>;
  nodes: Array<JobTitle>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type JobTitleEdge = {
  __typename?: 'JobTitleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<JobTitle>;
};

export type Meeting = {
  __typename?: 'Meeting';
  author: User;
  authorId: Scalars['ID'];
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  conversation: Conversation;
  conversationId: Scalars['ID'];
  createdAt: Scalars['ISO8601DateTime'];
  feedbacks?: Maybe<FeedbackConnection>;
  id: Scalars['ID'];
  meetingDatetime: Scalars['ISO8601DateTime'];
  meetingStatus: MeetingStatus;
  name: Scalars['String'];
  participationMeetings?: Maybe<ParticipationMeetingConnection>;
  participations?: Maybe<ParticipationConnection>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  userMeetings?: Maybe<UserMeetingConnection>;
  users?: Maybe<UserConnection>;
};

export type MeetingFeedbacksArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MeetingParticipationMeetingsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MeetingParticipationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MeetingUserMeetingsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MeetingUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Meeting. */
export type MeetingConnection = {
  __typename?: 'MeetingConnection';
  edges: Array<MeetingEdge>;
  nodes: Array<Meeting>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type MeetingEdge = {
  __typename?: 'MeetingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Meeting>;
};

export type MeetingReview = {
  __typename?: 'MeetingReview';
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  meetingReviewStatus: MeetingReviewStatus;
  questions?: Maybe<QuestionConnection>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type MeetingReviewQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The meeting_review_statuses of a MeetingReview */
export enum MeetingReviewStatus {
  Expired = 'expired',
  Happened = 'happened',
  Pending = 'pending',
}

/** The meeting_statuses of a Meeting */
export enum MeetingStatus {
  Cancelled = 'cancelled',
  Finished = 'finished',
  Upcoming = 'upcoming',
}

export type Membership = {
  __typename?: 'Membership';
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  isMe?: Maybe<Scalars['Boolean']>;
  project: Project;
  projectId: Scalars['ID'];
  role: Role;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
  userEmail: Scalars['String'];
  userFirstName: Scalars['String'];
  userId: Scalars['ID'];
  userLastName: Scalars['String'];
};

/** The connection type for Membership. */
export type MembershipConnection = {
  __typename?: 'MembershipConnection';
  edges: Array<MembershipEdge>;
  nodes: Array<Membership>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type MembershipEdge = {
  __typename?: 'MembershipEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Membership>;
};

export enum MembershipFields {
  ArchivedAt = 'archivedAt',
  CreatedAt = 'createdAt',
  Id = 'id',
  ProjectId = 'projectId',
  Role = 'role',
  UpdatedAt = 'updatedAt',
  UserEmail = 'userEmail',
  UserFirstName = 'userFirstName',
  UserId = 'userId',
  UserLastName = 'userLastName',
}

/** Input for filtering a list of memberships through queries */
export type MembershipsFilterInput = {
  query?: Maybe<Array<MembershipsQueryFilterInput>>;
  search?: Maybe<Scalars['String']>;
};

/** Input for filtering a list of memberships through queries, by a specific attribute */
export type MembershipsQueryFilterInput = {
  filterBy: MembershipFields;
  operator: QueryFilterOperator;
  value: Scalars['String'];
};

/** Input for sorting a list of memberships through queries */
export type MembershipsSortInput = {
  order: SortDirection;
  orderBy: MembershipFields;
};

export type Message = {
  __typename?: 'Message';
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  content: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  seenMessages?: Maybe<SeenMessageConnection>;
  updatedAt: Scalars['ISO8601DateTime'];
  userConversationAuthor: UserConversation;
  userConversationAuthorId: Scalars['ID'];
};

export type MessageSeenMessagesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Message. */
export type MessageConnection = {
  __typename?: 'MessageConnection';
  edges: Array<MessageEdge>;
  nodes: Array<Message>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type MessageEdge = {
  __typename?: 'MessageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Message>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Accept invitation to a program
   *
   *         Errors : UNAUTHORIZED, NOT_FOUND, ALREADY_CLAIMED, ALREADY_ACCEPTED
   */
  acceptInvitationToProgram: Scalars['Boolean'];
  /**
   * Accept an Invitation to join a Project
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED,
   *
   *         BAD_USER_INPUT: INVITATION_INVITATION_STATUS_ALREADY_SET,
   *
   *           INVITATION_TARGET_REQUIRED, INVITATION_MEMBERSHIP_ALREADY_EXISTS
   */
  acceptInvitationToProject: Invitation;
  /**
   * Archive a Program
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED, BAD_USER_INPUT
   */
  archiveProgram: Program;
  /**
   * Archive a Project
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED, BAD_USER_INPUT
   */
  archiveProject: Project;
  /**
   * Send an email to the participant containing a participation certificate as attachment
   *
   *         Errors: NOT_FOUND, UNAUTHORIZED
   *
   *         BAD_USER_INPUT: PARTICIPATION_CERTIFICATION_STATUS_CANNOT_CLAIM_CERTIFICATE
   */
  claimCertificate: Participation;
  /**
   * Create a Designation
   *
   *         Errors : UNAUTHORIZED,
   *
   *         BAD_USER_INPUT : DESIGNATION_USER_ID_ALREADY_COLLABORATOR
   */
  createDesignation: Designation;
  /**
   * Create a Group
   *
   *         Errors : UNAUTHORIZED, BAD_USER_INPUT: GROUP_NAME_TAKEN
   */
  createGroup: Group;
  /**
   * Create an Invitation to join a project from an email
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED,
   *
   *         BAD_USER_INPUT: INVITEEMAILTOPROJECTSERVICE_EMAIL_TAKEN,
   *
   *           INVITEEMAILTOPROJECTSERVICE_EMAIL_INVALID,
   *
   *           INVITEEMAILTOPROJECTSERVICE_AUTHOR_BLANK,
   *
   *           INVITEEMAILTOPROJECTSERVICE_MEMBERSHIP_ALREADY_EXISTS
   */
  createInvitation: Invitation;
  /**
   * Create a Participation
   *
   *         Errors: UNAUTHORIZED,
   *
   *         BAD_USER_INPUT: PARTICIPATION_PROGRAM_NOT_FOUND, PARTICIPATION_USER_ID_TAKEN
   *
   *         PARTICIPATION_PROGRAM_REACHED_PARTICIPATION_LIMIT
   */
  createParticipation: Participation;
  /**
   * Create a Program
   *
   *         Errors : UNAUTHORIZED, BAD_USER_INPUT: PROGRAM_NAME_TAKEN,
   *
   *         PROGRAM_PROGRAM_OPTIONS_UNKNOWN_PARAM
   */
  createProgram: Program;
  /**
   * Create a Project
   *
   *         Errors : UNAUTHORIZED, BAD_USER_INPUT: PROJECT_NAME_TAKEN, PROJECT_ORGANIZATION_ID_BLANK
   */
  createProject: Project;
  /**
   * Create a User
   *
   *         Errors : UNAUTHORIZED,
   *
   *         BAD_USER_INPUT: USER_EMAIL_TAKEN, USER_EMAIL_BLANK, USER_FIRST_NAME_BLANK, USER_LAST_NAME_BLANK
   */
  createUser: User;
  /**
   * Decline an Invitation to join a Project
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED, BAD_USER_INPUT: INVITATION_INVITATION_STATUS_ALREADY_SET
   */
  declineInvitationToProject: Invitation;
  /**
   * Delegate a Membership -
   *
   *         gives 'owner' role to :id & convert current_user's membership's role to 'admin'
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED, BAD_USER_INPUT
   */
  delegateMembership: Membership;
  /**
   * Delete a Collaboration
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED, BAD_USER_INPUT,
   *
   *         COLLABORATION_PROGRAM_PROGRAM_WITHOUT_COLLABORATOR
   */
  deleteCollaboration: Collaboration;
  /**
   * Delete a Group
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED, BAD_USER_INPUT
   */
  deleteGroup: Group;
  /**
   * Delete an Invitation
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED, BAD_USER_INPUT
   */
  deleteInvitation: Invitation;
  /**
   * Delete a Membership
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED,
   *
   *         BAD_USER_INPUT: MEMBERSHIP_ROLE_PROJECT_OWNER_CANNOT_LEAVE
   */
  deleteMembership: Membership;
  /**
   * Delete a Participation
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED, BAD_USER_INPUT
   */
  deleteParticipation: Participation;
  /**
   * Delete a Program
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED, BAD_USER_INPUT
   */
  deleteProgram: Program;
  /**
   * Delete a Project
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED, BAD_USER_INPUT
   */
  deleteProject: Project;
  /**
   * Delete a User
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED, BAD_USER_INPUT
   */
  deleteUser: User;
  /**
   * Duplicate a Program
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED, BAD_USER_INPUT
   */
  duplicateProgram: Program;
  /**
   * Entrust a Membership - gives 'owner' role to :id & destroy previous owner
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED, BAD_USER_INPUT
   */
  entrustMembership: Membership;
  /**
   * Import xlsx file as program invitations
   *
   *         Errors : UNAUTHORIZED, PROGRAM_IS_ALREADY_PROCESSING_AN_IMPORT, FILE_IS_MISSING_HEADERS, CANNOT_PARSE_FILE
   */
  importInvitationsToProgram: ProgramInvitationImport;
  /**
   * Publish a Program
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED,
   *
   *         BAD_USER_INPUT: PROGRAM_PROGRAM_PUBLICATION_STATUS_ALREADY_PUBLISHED
   *
   *         PROGRAM_DESCRIPTION_BLANK
   *
   *         PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_START_AT
   *
   *         PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_END_AT
   *
   *         PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_EXPECTED_PARTICIPATIONS
   *
   *         PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_MEETING_PROGRAM
   *
   *         PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_MEETINGS_QUANTITY
   *
   *         PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_MINIMAL_DAYS_DURATION
   */
  publishProgram: Program;
  /**
   * Recover a Program
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED, BAD_USER_INPUT
   */
  recoverProgram: Program;
  /**
   * Recover a Project
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED, BAD_USER_INPUT
   */
  recoverProject: Project;
  /**
   * Resend invitaiton to a participant
   *
   *         Errors : UNAUTHORIZED
   */
  resendInvitationToProgram: Scalars['Boolean'];
  /**
   * Resend invitaiton to multiple participants
   *
   *         Errors : UNAUTHORIZED
   */
  resendInvitationToPrograms: Scalars['Int'];
  /**
   * Update a Group
   *
   *         Errors:
   *           NOT_FOUND,
   *
   *           UNAUTHORIZED,
   *
   *           BAD_USER_INPUT:
   *
   *           GROUP_PARENT_CANNOT_PARENT_TO_SELF
   *
   *           GROUP_PARENT_CANNOT_PARENT_TO_CHILD
   *
   *           GROUP_NAME_TAKEN
   */
  updateGroup: Group;
  /**
   * Update an Invitation role
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED,
   *
   *         BAD_USER_INPUT: INVITATION_INVITATION_STATUS_CANNOT_UPDATE_ACCEPTED
   */
  updateInvitation: Invitation;
  /**
   * Update a Membership
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED,
   *
   *         BAD_USER_INPUT: MEMBERSHIP_ROLE_PROJECT_WITHOUT_OWNER
   */
  updateMembership: Membership;
  /**
   * Update a Program
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED,
   *
   *         BAD_USER_INPUT: PROGRAM_NAME_TAKEN, PROGRAM_GROUP_IS_FROM_ANOTHER_PROJECT,
   *
   *           PROGRAM_NAME_BLANK,
   *
   *           PROGRAM_PROGRAM_OPTIONS_UNKNOWN_PARAM,
   *
   *           PROGRAM_MINIMAL_DAYS_DURATION_BELOW_MINIMAL_DAYS_DURATION,
   *
   *           PROGRAM_DESCRIPTION_BLANK,
   *
   *           PROGRAM_START_AT_CANNOT_BACKDATE
   *
   *           PROGRAM_START_AT_START_AT_AFTER_END_AT, PROGRAM_END_AT_END_AT_BEFORE_START_AT
   */
  updateProgram: Program;
  /**
   * Update a Project
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED,
   *
   *         BAD_USER_INPUT: PROJECT_ORGANIZATION_ID_BLANK, PROJECT_NAME_BLANK,
   *
   *         PROJECT_NAME_TAKEN
   */
  updateProject: Project;
  /**
   * Update a User
   *
   *         Errors : NOT_FOUND, UNAUTHORIZED, BAD_USER_INPUT
   */
  updateUser: User;
};

export type MutationAcceptInvitationToProgramArgs = {
  id: Scalars['ID'];
};

export type MutationAcceptInvitationToProjectArgs = {
  id: Scalars['ID'];
};

export type MutationArchiveProgramArgs = {
  id: Scalars['ID'];
};

export type MutationArchiveProjectArgs = {
  id: Scalars['ID'];
};

export type MutationClaimCertificateArgs = {
  id: Scalars['ID'];
};

export type MutationCreateDesignationArgs = {
  input: CreateDesignationInput;
};

export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};

export type MutationCreateInvitationArgs = {
  input: CreateInvitationInput;
};

export type MutationCreateParticipationArgs = {
  input: CreateParticipationInput;
};

export type MutationCreateProgramArgs = {
  input: CreateProgramInput;
};

export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDeclineInvitationToProjectArgs = {
  id: Scalars['ID'];
};

export type MutationDelegateMembershipArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCollaborationArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteGroupArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteInvitationArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteMembershipArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteParticipationArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteProgramArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteProjectArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};

export type MutationDuplicateProgramArgs = {
  id: Scalars['ID'];
};

export type MutationEntrustMembershipArgs = {
  id: Scalars['ID'];
};

export type MutationImportInvitationsToProgramArgs = {
  file: Scalars['Upload'];
  message?: Maybe<Scalars['String']>;
  programId: Scalars['ID'];
};

export type MutationPublishProgramArgs = {
  id: Scalars['ID'];
};

export type MutationRecoverProgramArgs = {
  id: Scalars['ID'];
};

export type MutationRecoverProjectArgs = {
  id: Scalars['ID'];
};

export type MutationResendInvitationToProgramArgs = {
  id: Scalars['ID'];
};

export type MutationResendInvitationToProgramsArgs = {
  filter?: Maybe<ProgramInvitationsFilterInput>;
  programId: Scalars['ID'];
};

export type MutationUpdateGroupArgs = {
  id: Scalars['ID'];
  input: UpdateGroupInput;
};

export type MutationUpdateInvitationArgs = {
  id: Scalars['ID'];
  input: UpdateInvitationInput;
};

export type MutationUpdateMembershipArgs = {
  id: Scalars['ID'];
  input: UpdateMembershipInput;
};

export type MutationUpdateProgramArgs = {
  id: Scalars['ID'];
  input: UpdateProgramInput;
};

export type MutationUpdateProjectArgs = {
  id: Scalars['ID'];
  input: UpdateProjectInput;
};

export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  input: UpdateUserInput;
};

export type Organization = {
  __typename?: 'Organization';
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  groups?: Maybe<GroupConnection>;
  id: Scalars['ID'];
  name: Scalars['String'];
  projects?: Maybe<ProjectConnection>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type OrganizationGroupsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type OrganizationProjectsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Organization. */
export type OrganizationConnection = {
  __typename?: 'OrganizationConnection';
  edges: Array<OrganizationEdge>;
  nodes: Array<Organization>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type OrganizationEdge = {
  __typename?: 'OrganizationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Organization>;
};

export enum OrganizationFields {
  CollaborationsCount = 'collaborationsCount',
  CreatedAt = 'createdAt',
  GroupsCount = 'groupsCount',
  Id = 'id',
  MembershipsCount = 'membershipsCount',
  Name = 'name',
  ParticipationsCount = 'participationsCount',
  ProgramsCount = 'programsCount',
  ProjectsCount = 'projectsCount',
  Size = 'size',
  UpdatedAt = 'updatedAt',
}

/** Input for filtering a list of organizations through queries */
export type OrganizationsFilterInput = {
  query?: Maybe<Array<OrganizationsQueryFilterInput>>;
  search?: Maybe<Scalars['String']>;
};

/** Input for filtering a list of organizations through queries, by a specific attribute */
export type OrganizationsQueryFilterInput = {
  filterBy: OrganizationFields;
  operator: QueryFilterOperator;
  value: Scalars['String'];
};

/** Input for sorting a list of organizations through queries */
export type OrganizationsSortInput = {
  order: SortDirection;
  orderBy: OrganizationFields;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Participation = {
  __typename?: 'Participation';
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  bookmarkedProfessionalsCount?: Maybe<Scalars['Int']>;
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  certificateUrl?: Maybe<Scalars['String']>;
  certificationStatus: CertificationStatus;
  confirmedMeetingCount?: Maybe<Scalars['Int']>;
  conversationsCount?: Maybe<Scalars['Int']>;
  createdAt: Scalars['ISO8601DateTime'];
  hermesContacts: Array<HermesContact>;
  id: Scalars['ID'];
  incomingMeetingCount?: Maybe<Scalars['Int']>;
  program: Program;
  programId: Scalars['ID'];
  remainingContactCount?: Maybe<Scalars['Int']>;
  reportCount?: Maybe<Scalars['Int']>;
  unfollowedConversationCount?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
  userEmail: Scalars['String'];
  userFirstName: Scalars['String'];
  userId: Scalars['ID'];
  userLastName: Scalars['String'];
  visitsCount?: Maybe<Scalars['Int']>;
  waitingForConfirmationMeetingCount?: Maybe<Scalars['Int']>;
};

export enum ParticipationAndInvitationFields {
  ArchivedAt = 'archivedAt',
  CertificateNumber = 'certificateNumber',
  CertificateUrl = 'certificateUrl',
  CertificationStatus = 'certificationStatus',
  ConfirmedMeetingCount = 'confirmedMeetingCount',
  ConversationsCount = 'conversationsCount',
  CreatedAt = 'createdAt',
  FirstContactAt = 'firstContactAt',
  HermesBookmarkedProfessionalsCount = 'hermesBookmarkedProfessionalsCount',
  HermesConfirmedAppointmentsCount = 'hermesConfirmedAppointmentsCount',
  HermesConversationsCount = 'hermesConversationsCount',
  HermesFutureAppointmentsCount = 'hermesFutureAppointmentsCount',
  HermesRemainingContactCount = 'hermesRemainingContactCount',
  HermesReportCount = 'hermesReportCount',
  HermesUnfollowedConversationCount = 'hermesUnfollowedConversationCount',
  HermesUnresolvedClaimCount = 'hermesUnresolvedClaimCount',
  HermesVisitedProfessionalCount = 'hermesVisitedProfessionalCount',
  HermesWaitingForConfirmationMeetingCount = 'hermesWaitingForConfirmationMeetingCount',
  Id = 'id',
  IncomingMeetingCount = 'incomingMeetingCount',
  InvitationStatus = 'invitationStatus',
  ProgramId = 'programId',
  ProgramsEndAt = 'programs_end_at',
  ProgramsStartAt = 'programs_start_at',
  SynchronizedAt = 'synchronizedAt',
  UpdatedAt = 'updatedAt',
  UserEmail = 'userEmail',
  UserFirstName = 'userFirstName',
  UserId = 'userId',
  UserLastName = 'userLastName',
  VisitsCount = 'visitsCount',
}

/** The connection type for Participation. */
export type ParticipationConnection = {
  __typename?: 'ParticipationConnection';
  edges: Array<ParticipationEdge>;
  nodes: Array<Participation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type ParticipationConversation = {
  __typename?: 'ParticipationConversation';
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  conversation: Conversation;
  conversationId: Scalars['ID'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  participation: Participation;
  participationId: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for ParticipationConversation. */
export type ParticipationConversationConnection = {
  __typename?: 'ParticipationConversationConnection';
  edges: Array<ParticipationConversationEdge>;
  nodes: Array<ParticipationConversation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type ParticipationConversationEdge = {
  __typename?: 'ParticipationConversationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ParticipationConversation>;
};

/** An edge in a connection. */
export type ParticipationEdge = {
  __typename?: 'ParticipationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Participation>;
};

export enum ParticipationFields {
  ArchivedAt = 'archivedAt',
  CertificateNumber = 'certificateNumber',
  CertificateUrl = 'certificateUrl',
  CertificationStatus = 'certificationStatus',
  ConfirmedMeetingCount = 'confirmedMeetingCount',
  ConversationsCount = 'conversationsCount',
  CreatedAt = 'createdAt',
  FirstContactAt = 'firstContactAt',
  HermesBookmarkedProfessionalsCount = 'hermesBookmarkedProfessionalsCount',
  HermesConfirmedAppointmentsCount = 'hermesConfirmedAppointmentsCount',
  HermesConversationsCount = 'hermesConversationsCount',
  HermesFutureAppointmentsCount = 'hermesFutureAppointmentsCount',
  HermesRemainingContactCount = 'hermesRemainingContactCount',
  HermesReportCount = 'hermesReportCount',
  HermesUnfollowedConversationCount = 'hermesUnfollowedConversationCount',
  HermesUnresolvedClaimCount = 'hermesUnresolvedClaimCount',
  HermesVisitedProfessionalCount = 'hermesVisitedProfessionalCount',
  HermesWaitingForConfirmationMeetingCount = 'hermesWaitingForConfirmationMeetingCount',
  Id = 'id',
  IncomingMeetingCount = 'incomingMeetingCount',
  ProgramId = 'programId',
  ProgramsEndAt = 'programs_end_at',
  ProgramsStartAt = 'programs_start_at',
  SynchronizedAt = 'synchronizedAt',
  UpdatedAt = 'updatedAt',
  UserEmail = 'userEmail',
  UserFirstName = 'userFirstName',
  UserId = 'userId',
  UserLastName = 'userLastName',
  VisitsCount = 'visitsCount',
}

export type ParticipationMeeting = {
  __typename?: 'ParticipationMeeting';
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  meeting: Meeting;
  meetingId: Scalars['ID'];
  participation: Participation;
  participationId: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for ParticipationMeeting. */
export type ParticipationMeetingConnection = {
  __typename?: 'ParticipationMeetingConnection';
  edges: Array<ParticipationMeetingEdge>;
  nodes: Array<ParticipationMeeting>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type ParticipationMeetingEdge = {
  __typename?: 'ParticipationMeetingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ParticipationMeeting>;
};

export type ParticipationOrInvitation = {
  __typename?: 'ParticipationOrInvitation';
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  bookmarkedProfessionalsCount?: Maybe<Scalars['Int']>;
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  certificateUrl?: Maybe<Scalars['String']>;
  certificationStatus: CertificationStatus;
  confirmedMeetingCount?: Maybe<Scalars['Int']>;
  conversationsCount?: Maybe<Scalars['Int']>;
  createdAt: Scalars['ISO8601DateTime'];
  hermesContacts: Array<HermesContact>;
  id: Scalars['ID'];
  incomingMeetingCount?: Maybe<Scalars['Int']>;
  invitationStatus: ProgramInvitationStatus;
  program: Program;
  programId: Scalars['ID'];
  remainingContactCount?: Maybe<Scalars['Int']>;
  reportCount?: Maybe<Scalars['Int']>;
  unfollowedConversationCount?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
  userEmail: Scalars['String'];
  userFirstName: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
  userLastName: Scalars['String'];
  visitsCount?: Maybe<Scalars['Int']>;
  waitingForConfirmationMeetingCount?: Maybe<Scalars['Int']>;
};

/** The connection type for ParticipationOrInvitation. */
export type ParticipationOrInvitationConnection = {
  __typename?: 'ParticipationOrInvitationConnection';
  edges: Array<ParticipationOrInvitationEdge>;
  nodes: Array<ParticipationOrInvitation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type ParticipationOrInvitationEdge = {
  __typename?: 'ParticipationOrInvitationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ParticipationOrInvitation>;
};

/** Input for filtering a list of participations and invitations through queries */
export type ParticipationsAndInvitationsFilterInput = {
  query?: Maybe<Array<ParticipationsAndInvitationsQueryFilterInput>>;
  search?: Maybe<Scalars['String']>;
};

/** Input for filtering a list of participations through queries, by a specific attribute */
export type ParticipationsAndInvitationsQueryFilterInput = {
  filterBy: ParticipationAndInvitationFields;
  operator: QueryFilterOperator;
  value: Scalars['String'];
};

/** Input for sorting a list of participations through queries */
export type ParticipationsAndInvitationsSortInput = {
  order: SortDirection;
  orderBy: ParticipationAndInvitationFields;
};

/** Input for filtering a list of participations through queries */
export type ParticipationsFilterInput = {
  query?: Maybe<Array<ParticipationsQueryFilterInput>>;
  search?: Maybe<Scalars['String']>;
};

/** Input for filtering a list of participations through queries, by a specific attribute */
export type ParticipationsQueryFilterInput = {
  filterBy: ParticipationFields;
  operator: QueryFilterOperator;
  value: Scalars['String'];
};

/** Input for sorting a list of participations through queries */
export type ParticipationsSortInput = {
  order: SortDirection;
  orderBy: ParticipationFields;
};

export type Program = {
  __typename?: 'Program';
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  author: User;
  authorId: Scalars['ID'];
  canArchive: AuthorizationResult;
  canDelete: AuthorizationResult;
  canRecover: AuthorizationResult;
  canShow: AuthorizationResult;
  canUpdate: AuthorizationResult;
  collaborations?: Maybe<CollaborationConnection>;
  completedParticipationsCount: Scalars['Int'];
  confirmedMeetingsCount: Scalars['Int'];
  conversationsCount: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['ISO8601DateTime']>;
  expectedParticipations?: Maybe<Scalars['Int']>;
  group?: Maybe<Group>;
  groupId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  individualSupport: Scalars['Boolean'];
  meetingProgram?: Maybe<Scalars['Boolean']>;
  meetingsQuantity?: Maybe<Scalars['Int']>;
  minimalDaysDuration?: Maybe<ProgramMinimalDaysDuration>;
  name: Scalars['String'];
  participationCode?: Maybe<Scalars['String']>;
  participations?: Maybe<ParticipationConnection>;
  participationsCount: Scalars['Int'];
  programInvitationImports: ProgramInvitationImportConnection;
  programInvitations: ProgramInvitationConnection;
  programNotificationFrequency?: Maybe<ProgramNotificationFrequency>;
  programProgressionStatus: ProgramProgressionStatus;
  programPublicationStatus: ProgramPublicationStatus;
  programTemplate: ProgramTemplate;
  programTemplateId: Scalars['ID'];
  programsStatistics: ProgramsStatistics;
  project: Project;
  projectId: Scalars['ID'];
  startAt?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ProgramCollaborationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ProgramParticipationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ParticipationsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ParticipationsSortInput>>;
};

export type ProgramProgramInvitationImportsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ProgramInvitationImportsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ProgramInvitationImportsSortInput>>;
};

export type ProgramProgramInvitationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ProgramInvitationsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ProgramInvitationsSortInput>>;
};

/** The connection type for Program. */
export type ProgramConnection = {
  __typename?: 'ProgramConnection';
  edges: Array<ProgramEdge>;
  nodes: Array<Program>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type ProgramEdge = {
  __typename?: 'ProgramEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Program>;
};

export type ProgramFavorite = {
  __typename?: 'ProgramFavorite';
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  favoriteProgramId: Scalars['ID'];
  id: Scalars['ID'];
  program: Program;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
  userId: Scalars['ID'];
};

export enum ProgramFields {
  ArchivedAt = 'archivedAt',
  AuthorId = 'authorId',
  CollaborationsCount = 'collaborationsCount',
  CreatedAt = 'createdAt',
  Description = 'description',
  GroupId = 'groupId',
  Id = 'id',
  IndividualSupport = 'individualSupport',
  Name = 'name',
  ParticipationCode = 'participationCode',
  ParticipationsCount = 'participationsCount',
  ProgramProgressionStatus = 'programProgressionStatus',
  ProgramPublicationStatus = 'programPublicationStatus',
  ProgramTemplateId = 'programTemplateId',
  ProjectId = 'projectId',
  UpdatedAt = 'updatedAt',
}

export type ProgramInvitation = {
  __typename?: 'ProgramInvitation';
  adminFirstName: Scalars['String'];
  adminLastName: Scalars['String'];
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  participation?: Maybe<Participation>;
  program: Program;
  programId: Scalars['ID'];
  programInvitationImport?: Maybe<ProgramInvitationImport>;
  status: ProgramInvitationStatus;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for ProgramInvitation. */
export type ProgramInvitationConnection = {
  __typename?: 'ProgramInvitationConnection';
  edges: Array<ProgramInvitationEdge>;
  nodes: Array<ProgramInvitation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type ProgramInvitationEdge = {
  __typename?: 'ProgramInvitationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ProgramInvitation>;
};

export enum ProgramInvitationFields {
  CreatedAt = 'createdAt',
  Email = 'email',
  FirstName = 'firstName',
  Id = 'id',
  InviterId = 'inviterId',
  LastName = 'lastName',
  LastSentAt = 'lastSentAt',
  Message = 'message',
  ParticipationId = 'participationId',
  ProgramId = 'programId',
  ProgramInvitationImportId = 'programInvitationImportId',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UsersId = 'usersId',
}

export type ProgramInvitationImport = {
  __typename?: 'ProgramInvitationImport';
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  participations?: Maybe<ParticipationConnection>;
  program: Program;
  programId: Scalars['ID'];
  programInvitations: ProgramInvitationConnection;
  resultFileUrl?: Maybe<Scalars['String']>;
  status: ProgramInvitationImportStatus;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ProgramInvitationImportParticipationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ParticipationsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ParticipationsSortInput>>;
};

export type ProgramInvitationImportProgramInvitationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ProgramInvitationsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ProgramInvitationsSortInput>>;
};

/** The connection type for ProgramInvitationImport. */
export type ProgramInvitationImportConnection = {
  __typename?: 'ProgramInvitationImportConnection';
  edges: Array<ProgramInvitationImportEdge>;
  nodes: Array<ProgramInvitationImport>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type ProgramInvitationImportEdge = {
  __typename?: 'ProgramInvitationImportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ProgramInvitationImport>;
};

export enum ProgramInvitationImportFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  InviterId = 'inviterId',
  Message = 'message',
  ProgramId = 'programId',
  ResultFile = 'resultFile',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UsersId = 'usersId',
  XlsxFile = 'xlsxFile',
}

/** Input for filtering a list of program invitation imports through queries */
export type ProgramInvitationImportsFilterInput = {
  query?: Maybe<Array<ProgramInvitationImportsQueryFilterInput>>;
};

/** Input for filtering a list of program invitation imports through queries, by a specific attribute */
export type ProgramInvitationImportsQueryFilterInput = {
  filterBy: ProgramInvitationImportFields;
  operator: QueryFilterOperator;
  value: Scalars['String'];
};

/** Input for sorting a list of program invitation imports through queries */
export type ProgramInvitationImportsSortInput = {
  order: SortDirection;
  orderBy: ProgramInvitationImportFields;
};

export enum ProgramInvitationImportStatus {
  Failed = 'failed',
  Finished = 'finished',
  Processing = 'processing',
}

/** Input for filtering a list of program invitation through queries */
export type ProgramInvitationsFilterInput = {
  query?: Maybe<Array<ProgramInvitationsQueryFilterInput>>;
  search?: Maybe<Scalars['String']>;
};

/** Input for filtering a list of program invitation through queries, by a specific attribute */
export type ProgramInvitationsQueryFilterInput = {
  filterBy: ProgramInvitationFields;
  operator: QueryFilterOperator;
  value: Scalars['String'];
};

/** Input for sorting a list of program invitation through queries */
export type ProgramInvitationsSortInput = {
  order: SortDirection;
  orderBy: ProgramInvitationFields;
};

/** The statuses of a ProgramInvitation */
export enum ProgramInvitationStatus {
  Accepted = 'accepted',
  Processing = 'processing',
  Rejected = 'rejected',
  Sent = 'sent',
}

/**
 * The minimal days duration of a Program
 *
 *         - minimal duration between program's start_at & end_at
 */
export enum ProgramMinimalDaysDuration {
  /** 120 days */
  FourMonths = 'FOUR_MONTHS',
  /** 30 days */
  OneMonth = 'ONE_MONTH',
  /** 90 days */
  ThreeMonths = 'THREE_MONTHS',
}

/**
 * The program_notification_frequency of a Program
 *
 *         - which monday an email will be sent to program's participants
 */
export enum ProgramNotificationFrequency {
  FirstMonday = 'FIRST_MONDAY',
  FourthMonday = 'FOURTH_MONDAY',
  SecondMonday = 'SECOND_MONDAY',
  ThirdMonday = 'THIRD_MONDAY',
}

/** The option of a TemplateOption or ProgramOption */
export enum ProgramOption {
  EndAt = 'end_at',
  ExpectedParticipations = 'expected_participations',
  MeetingProgram = 'meeting_program',
  MeetingsQuantity = 'meetings_quantity',
  MinimalDaysDuration = 'minimal_days_duration',
  ProgramNotificationFrequency = 'program_notification_frequency',
  StartAt = 'start_at',
}

/** The program_progression_status of a Program */
export enum ProgramProgressionStatus {
  Finished = 'finished',
  Ongoing = 'ongoing',
  Upcoming = 'upcoming',
}

/** The program_publication_status of a Program */
export enum ProgramPublicationStatus {
  Draft = 'draft',
  Published = 'published',
}

/** Input for filtering a list of programs through queries */
export type ProgramsFilterInput = {
  query?: Maybe<Array<ProgramsQueryFilterInput>>;
  search?: Maybe<Scalars['String']>;
};

/** Input for filtering a list of programs through queries, by a specific attribute */
export type ProgramsQueryFilterInput = {
  filterBy: ProgramFields;
  operator: QueryFilterOperator;
  value: Scalars['String'];
};

/** Input for sorting a list of programs through queries */
export type ProgramsSortInput = {
  order: SortDirection;
  orderBy: ProgramFields;
};

export type ProgramsStatistics = {
  __typename?: 'ProgramsStatistics';
  companyTopFive: Array<RankingNode>;
  confirmedAppointmentsCount: Scalars['Int'];
  conversationsCount: Scalars['Int'];
  deliveredCertificatesPourcentage?: Maybe<Scalars['Int']>;
  delivredCertificates: Scalars['Int'];
  expectedCertificates: Scalars['Int'];
  jobTopFive: Array<RankingNode>;
  metProfessionalsCount: Scalars['Int'];
  participantsWithAppointmentsPourcentage?: Maybe<Scalars['Int']>;
  participantsWithConversationsPourcentage?: Maybe<Scalars['Int']>;
  participationsCount: Scalars['Int'];
  professionalEvaluations: Array<ReviewDistributionNode>;
  programsCompletionsPourcentage?: Maybe<Scalars['Int']>;
  programsCount: Scalars['Int'];
  sectorTopFive: Array<RankingNode>;
  studentEvaluations: Array<ReviewDistributionNode>;
};

export type ProgramTemplate = {
  __typename?: 'ProgramTemplate';
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  displayedName: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  programs?: Maybe<ProgramConnection>;
  templateOptions?: Maybe<Array<TemplateOption>>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ProgramTemplateProgramsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for ProgramTemplate. */
export type ProgramTemplateConnection = {
  __typename?: 'ProgramTemplateConnection';
  edges: Array<ProgramTemplateEdge>;
  nodes: Array<ProgramTemplate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type ProgramTemplateEdge = {
  __typename?: 'ProgramTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ProgramTemplate>;
};

export enum ProgramTemplateFields {
  CertificateDisplayedName = 'certificateDisplayedName',
  CreatedAt = 'createdAt',
  Description = 'description',
  DisplayedName = 'displayedName',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt',
}

/** Input for filtering a list of program templates through queries */
export type ProgramTemplatesFilterInput = {
  query?: Maybe<Array<ProgramTemplatesQueryFilterInput>>;
  search?: Maybe<Scalars['String']>;
};

/** Input for filtering a list of programs through queries, by a specific attribute */
export type ProgramTemplatesQueryFilterInput = {
  filterBy: ProgramTemplateFields;
  operator: QueryFilterOperator;
  value: Scalars['String'];
};

/** Input for sorting a list of programs through queries */
export type ProgramTemplatesSortInput = {
  order: SortDirection;
  orderBy: ProgramTemplateFields;
};

export type Project = {
  __typename?: 'Project';
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  archivedMemberships?: Maybe<MembershipConnection>;
  archivedPrograms?: Maybe<ProgramConnection>;
  author: User;
  authorId: Scalars['ID'];
  canArchive: AuthorizationResult;
  canCreateGroup: AuthorizationResult;
  canCreateInvitation: AuthorizationResult;
  canCreateProgram: AuthorizationResult;
  canDelete: AuthorizationResult;
  canRecover: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  directGroups?: Maybe<GroupConnection>;
  directPrograms?: Maybe<ProgramConnection>;
  groups?: Maybe<GroupConnection>;
  id: Scalars['ID'];
  invitations?: Maybe<InvitationConnection>;
  memberships?: Maybe<MembershipConnection>;
  myRole?: Maybe<Role>;
  name: Scalars['String'];
  organization: Organization;
  organizationId: Scalars['ID'];
  programs?: Maybe<ProgramConnection>;
  programsStatistics: ProgramsStatistics;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ProjectArchivedMembershipsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<MembershipsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<MembershipsSortInput>>;
};

export type ProjectArchivedProgramsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ProgramsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ProgramsSortInput>>;
};

export type ProjectDirectGroupsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<GroupsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupsSortInput>>;
};

export type ProjectDirectProgramsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ProgramsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ProgramsSortInput>>;
};

export type ProjectGroupsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ProjectInvitationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<InvitationsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<InvitationsSortInput>>;
};

export type ProjectMembershipsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<MembershipsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<MembershipsSortInput>>;
};

export type ProjectProgramsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ProgramsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ProgramsSortInput>>;
};

export type ProjectProgramsStatisticsArgs = {
  programIds?: Maybe<Array<Scalars['String']>>;
};

/** The connection type for Project. */
export type ProjectConnection = {
  __typename?: 'ProjectConnection';
  edges: Array<ProjectEdge>;
  nodes: Array<Project>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type ProjectEdge = {
  __typename?: 'ProjectEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Project>;
};

export type ProjectFavorite = {
  __typename?: 'ProjectFavorite';
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  favoriteProjectId: Scalars['ID'];
  id: Scalars['ID'];
  project: Project;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
  userId: Scalars['ID'];
};

export enum ProjectFields {
  ArchivedAt = 'archivedAt',
  AuthorId = 'authorId',
  CollaborationsCount = 'collaborationsCount',
  CreatedAt = 'createdAt',
  DraftsCount = 'draftsCount',
  GroupsCount = 'groupsCount',
  Id = 'id',
  MembershipsCount = 'membershipsCount',
  Name = 'name',
  OrganizationId = 'organizationId',
  ParticipationsCount = 'participationsCount',
  ProgramsCount = 'programsCount',
  PublishedsCount = 'publishedsCount',
  UpdatedAt = 'updatedAt',
}

/** Input for filtering a list of projects through queries */
export type ProjectsFilterInput = {
  query?: Maybe<Array<ProjectsQueryFilterInput>>;
  search?: Maybe<Scalars['String']>;
};

/** Input for filtering a list of projects through queries, by a specific attribute */
export type ProjectsQueryFilterInput = {
  filterBy: ProjectFields;
  operator: QueryFilterOperator;
  value: Scalars['String'];
};

/** Input for sorting a list of projects through queries */
export type ProjectsSortInput = {
  order: SortDirection;
  orderBy: ProjectFields;
};

export type Query = {
  __typename?: 'Query';
  /**
   * An AdminAccreditation - grants admin access to an User
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  adminAccreditation?: Maybe<AdminAccreditation>;
  /**
   * A list of archived Programs - having a archived_at value
   *
   *         Errors: UNAUTHORIZED
   */
  archivedPrograms?: Maybe<ProgramConnection>;
  /**
   * A list of archived Projects - having a archived_at value
   *
   *         Errors: UNAUTHORIZED
   */
  archivedProjects?: Maybe<ProjectConnection>;
  /**
   * A Collaboration - User (manager) >< Program
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  collaboration?: Maybe<Collaboration>;
  /**
   * A list of Collaborations - User (manager) >< Program
   *
   *         Errors: UNAUTHORIZED
   */
  collaborations?: Maybe<CollaborationConnection>;
  /**
   * A CompanySector - relation between Company (Organization) and Sector
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  companySector?: Maybe<CompanySector>;
  /**
   * A Conversation
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  conversation?: Maybe<Conversation>;
  /**
   * A Designation - User (invitation to manage) >< Program
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  designation?: Maybe<Designation>;
  /**
   * A list of Designations - User (invitation to manage) >< Program
   *
   *         Errors: UNAUTHORIZED
   */
  designations?: Maybe<DesignationConnection>;
  /**
   * A Feedback - public verbatims, differ from MeetingReview
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  feedback?: Maybe<Feedback>;
  /**
   * A Group - container for Program
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  group?: Maybe<Group>;
  /**
   * A GroupFavorite
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  groupFavorite?: Maybe<GroupFavorite>;
  /**
   * A list of Groups
   *
   *         Errors: UNAUTHORIZED
   */
  groups?: Maybe<GroupConnection>;
  /**
   * An Invitation - User >< Project
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  invitation?: Maybe<Invitation>;
  /**
   * A list of Invitations - User >< Project
   *
   *         Errors: UNAUTHORIZED
   */
  invitations?: Maybe<InvitationConnection>;
  /**
   * A JobTitle
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  jobTitle?: Maybe<JobTitle>;
  /**
   * The current_user - User
   *
   *         Errors: UNAUTHENTICATED
   */
  me?: Maybe<User>;
  /**
   * A Meeting
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  meeting?: Maybe<Meeting>;
  /**
   * A MeetingReview
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  meetingReview?: Maybe<MeetingReview>;
  /**
   * A Membership - User >< Project
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  membership?: Maybe<Membership>;
  /**
   * A list of Memberships - User >< Project
   *
   *         Errors: UNAUTHORIZED
   */
  memberships?: Maybe<MembershipConnection>;
  /**
   * A Message
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  message?: Maybe<Message>;
  /**
   * An Organization
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  organization?: Maybe<Organization>;
  /**
   * A list of Organizations
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  organizations?: Maybe<OrganizationConnection>;
  /**
   * A Participation - User (consumer) >< Program
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  participation?: Maybe<Participation>;
  /**
   * A ParticipationConversation
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  participationConversation?: Maybe<ParticipationConversation>;
  /**
   * A ParticipationMeeting
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  participationMeeting?: Maybe<ParticipationMeeting>;
  /**
   * A list of Participations - User (student) >< Program
   *
   *         Errors: UNAUTHORIZED
   */
  participations?: Maybe<ParticipationConnection>;
  /**
   * A list of Participations and Invitations
   *
   *         Errors: UNAUTHORIZED
   */
  participationsAndInvitations?: Maybe<ParticipationOrInvitationConnection>;
  /**
   * A Program
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  program?: Maybe<Program>;
  /**
   * A ProgramFavorite
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  programFavorite?: Maybe<ProgramFavorite>;
  /**
   * A ProgramInvitation
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  programInvitation?: Maybe<ProgramInvitation>;
  /**
   * A ProgramInvitationImport
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  programInvitationImport?: Maybe<ProgramInvitationImport>;
  /**
   * A ProgramInvitationImports
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  programInvitationImports?: Maybe<ProgramInvitationImportConnection>;
  /**
   * A ProgramInvitations
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  programInvitations?: Maybe<ProgramInvitationConnection>;
  /**
   * A ProgramTemplate
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  programTemplate?: Maybe<ProgramTemplate>;
  /**
   * A list of ProgramTemplates
   *
   *         Errors: UNAUTHORIZED
   */
  programTemplates?: Maybe<ProgramTemplateConnection>;
  /**
   * A list of Programs
   *
   *         Errors: UNAUTHORIZED
   */
  programs?: Maybe<ProgramConnection>;
  /**
   * A Project
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  project?: Maybe<Project>;
  /**
   * A ProjectFavorite
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  projectFavorite?: Maybe<ProjectFavorite>;
  /**
   * A list of Projects
   *
   *         Errors: UNAUTHORIZED
   */
  projects?: Maybe<ProjectConnection>;
  /**
   * A Question
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  question?: Maybe<Question>;
  /**
   * A Sector
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  sector?: Maybe<Sector>;
  /**
   * A SeenMessage
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  seenMessage?: Maybe<SeenMessage>;
  /**
   * A User
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  user?: Maybe<User>;
  /**
   * An UserActivity
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  userActivity?: Maybe<UserActivity>;
  /**
   * An UserCompany
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  userCompany?: Maybe<UserCompany>;
  /**
   * An UserCompanyJob
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  userCompanyJob?: Maybe<UserCompanyJob>;
  /**
   * An UserConversation
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  userConversation?: Maybe<UserConversation>;
  /**
   * An UserFavorite
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  userFavorite?: Maybe<UserFavorite>;
  /**
   * An UserMeeting
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  userMeeting?: Maybe<UserMeeting>;
  /**
   * A UserSchool
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  userSchool?: Maybe<UserSchool>;
  /**
   * A UserVisit
   *
   *         Errors: NOT_FOUND
   *
   *           UNAUTHORIZED
   */
  userVisit?: Maybe<UserVisit>;
  /**
   * A list of Users
   *
   *         Errors: UNAUTHORIZED
   */
  users?: Maybe<UserConnection>;
};

export type QueryAdminAccreditationArgs = {
  id: Scalars['ID'];
};

export type QueryArchivedProgramsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ProgramsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ProgramsSortInput>>;
};

export type QueryArchivedProjectsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ProjectsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ProjectsSortInput>>;
};

export type QueryCollaborationArgs = {
  id: Scalars['ID'];
};

export type QueryCollaborationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CollaborationsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CollaborationsSortInput>>;
};

export type QueryCompanySectorArgs = {
  id: Scalars['ID'];
};

export type QueryConversationArgs = {
  id: Scalars['ID'];
};

export type QueryDesignationArgs = {
  id: Scalars['ID'];
};

export type QueryDesignationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<DesignationsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<DesignationsSortInput>>;
};

export type QueryFeedbackArgs = {
  id: Scalars['ID'];
};

export type QueryGroupArgs = {
  id: Scalars['ID'];
};

export type QueryGroupFavoriteArgs = {
  id: Scalars['ID'];
};

export type QueryGroupsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<GroupsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupsSortInput>>;
};

export type QueryInvitationArgs = {
  id: Scalars['ID'];
};

export type QueryInvitationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<InvitationsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<InvitationsSortInput>>;
};

export type QueryJobTitleArgs = {
  id: Scalars['ID'];
};

export type QueryMeetingArgs = {
  id: Scalars['ID'];
};

export type QueryMeetingReviewArgs = {
  id: Scalars['ID'];
};

export type QueryMembershipArgs = {
  id: Scalars['ID'];
};

export type QueryMembershipsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<MembershipsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<MembershipsSortInput>>;
};

export type QueryMessageArgs = {
  id: Scalars['ID'];
};

export type QueryOrganizationArgs = {
  id: Scalars['ID'];
};

export type QueryOrganizationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<OrganizationsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<OrganizationsSortInput>>;
};

export type QueryParticipationArgs = {
  id: Scalars['ID'];
};

export type QueryParticipationConversationArgs = {
  id: Scalars['ID'];
};

export type QueryParticipationMeetingArgs = {
  id: Scalars['ID'];
};

export type QueryParticipationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ParticipationsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ParticipationsSortInput>>;
};

export type QueryParticipationsAndInvitationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ParticipationsAndInvitationsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  programId: Scalars['ID'];
  sort?: Maybe<Array<ParticipationsAndInvitationsSortInput>>;
};

export type QueryProgramArgs = {
  id: Scalars['ID'];
};

export type QueryProgramFavoriteArgs = {
  id: Scalars['ID'];
};

export type QueryProgramInvitationArgs = {
  id: Scalars['ID'];
};

export type QueryProgramInvitationImportArgs = {
  id: Scalars['ID'];
};

export type QueryProgramInvitationImportsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ProgramInvitationImportsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ProgramInvitationImportsSortInput>>;
};

export type QueryProgramInvitationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ProgramInvitationsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ProgramInvitationsSortInput>>;
};

export type QueryProgramTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryProgramTemplatesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ProgramTemplatesFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ProgramTemplatesSortInput>>;
};

export type QueryProgramsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ProgramsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ProgramsSortInput>>;
};

export type QueryProjectArgs = {
  id: Scalars['ID'];
};

export type QueryProjectFavoriteArgs = {
  id: Scalars['ID'];
};

export type QueryProjectsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ProjectsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ProjectsSortInput>>;
};

export type QueryQuestionArgs = {
  id: Scalars['ID'];
};

export type QuerySectorArgs = {
  id: Scalars['ID'];
};

export type QuerySeenMessageArgs = {
  id: Scalars['ID'];
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryUserActivityArgs = {
  id: Scalars['ID'];
};

export type QueryUserCompanyArgs = {
  id: Scalars['ID'];
};

export type QueryUserCompanyJobArgs = {
  id: Scalars['ID'];
};

export type QueryUserConversationArgs = {
  id: Scalars['ID'];
};

export type QueryUserFavoriteArgs = {
  id: Scalars['ID'];
};

export type QueryUserMeetingArgs = {
  id: Scalars['ID'];
};

export type QueryUserSchoolArgs = {
  id: Scalars['ID'];
};

export type QueryUserVisitArgs = {
  id: Scalars['ID'];
};

export type QueryUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<UsersFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<UsersSortInput>>;
};

/** The operator of a query filter */
export enum QueryFilterOperator {
  /** The filter_by must match value */
  Eq = 'eq',
  /** The filter_by must be greater than value */
  Gt = 'gt',
  /** The filter_by must be greater or equal to value */
  Gte = 'gte',
  /** The filter_by must be lower than value */
  Lt = 'lt',
  /** The filter_by must be lower or equal to value */
  Lte = 'lte',
  /** The filter_by must differ from value */
  Neq = 'neq',
}

export type Question = {
  __typename?: 'Question';
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  meetingReview: MeetingReview;
  meetingReviewId: Scalars['ID'];
  title: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for Question. */
export type QuestionConnection = {
  __typename?: 'QuestionConnection';
  edges: Array<QuestionEdge>;
  nodes: Array<Question>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type QuestionEdge = {
  __typename?: 'QuestionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Question>;
};

export type RankingNode = {
  __typename?: 'RankingNode';
  count?: Maybe<Scalars['Int']>;
  identifier: Scalars['String'];
  rank: Scalars['Int'];
};

export type ReviewDistributionNode = {
  __typename?: 'ReviewDistributionNode';
  count: Scalars['Int'];
  key: ReviewNotation;
};

export enum ReviewNotation {
  ReviewNotation_1 = 'REVIEW_NOTATION_1',
  ReviewNotation_2 = 'REVIEW_NOTATION_2',
  ReviewNotation_3 = 'REVIEW_NOTATION_3',
  ReviewNotation_4 = 'REVIEW_NOTATION_4',
  ReviewNotation_5 = 'REVIEW_NOTATION_5',
}

/** The role of one User in Project - Membership context */
export enum Role {
  Admin = 'admin',
  Companion = 'companion',
  Guest = 'guest',
  Owner = 'owner',
}

export type Sector = {
  __typename?: 'Sector';
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  companies?: Maybe<OrganizationConnection>;
  companySectors?: Maybe<CompanySectorConnection>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type SectorCompaniesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type SectorCompanySectorsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type SeenMessage = {
  __typename?: 'SeenMessage';
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  message: Message;
  messageId: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
  userConversationReader: UserConversation;
  userConversationReaderId: Scalars['ID'];
};

/** The connection type for SeenMessage. */
export type SeenMessageConnection = {
  __typename?: 'SeenMessageConnection';
  edges: Array<SeenMessageEdge>;
  nodes: Array<SeenMessage>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type SeenMessageEdge = {
  __typename?: 'SeenMessageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<SeenMessage>;
};

/** The direction of a sort query */
export enum SortDirection {
  /** Sorting by ascending value */
  Asc = 'ASC',
  /** Sorting by descending value */
  Desc = 'DESC',
}

export type TemplateOption = {
  __typename?: 'TemplateOption';
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  programOption: ProgramOption;
  updatedAt: Scalars['ISO8601DateTime'];
  value?: Maybe<Scalars['String']>;
};

/** Input for updating a Group */
export type UpdateGroupInput = {
  /** Name of the Group */
  name?: Maybe<Scalars['String']>;
  /** ID of the parent Group */
  parentId?: Maybe<Scalars['ID']>;
};

/** Input for updating an Invitation */
export type UpdateInvitationInput = {
  /** Role of the user in the project */
  role: InvitationRole;
};

/** Input for updating a Membership */
export type UpdateMembershipInput = {
  /** Role of the User in the Project */
  role: Role;
};

/** Input for updating a Program */
export type UpdateProgramInput = {
  /** ID of the User author of the Program */
  authorId?: Maybe<Scalars['ID']>;
  /** Array of user_id which correspond to actual members of the project */
  collaboratorsIds?: Maybe<Array<Scalars['ID']>>;
  /** Description of the Program */
  description?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['ISO8601DateTime']>;
  expectedParticipations?: Maybe<Scalars['Int']>;
  /** ID of the Group */
  groupId?: Maybe<Scalars['ID']>;
  individualSupport?: Maybe<Scalars['Boolean']>;
  meetingProgram?: Maybe<Scalars['Boolean']>;
  meetingsQuantity?: Maybe<Scalars['Int']>;
  minimalDaysDuration?: Maybe<ProgramMinimalDaysDuration>;
  /** Name of the Program */
  name?: Maybe<Scalars['String']>;
  programNotificationFrequency?: Maybe<ProgramNotificationFrequency>;
  /** ID of the Project */
  projectId?: Maybe<Scalars['ID']>;
  startAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Input for updating a Project */
export type UpdateProjectInput = {
  /** Name of the Project */
  name: Scalars['String'];
  /** Name of the Organization */
  organizationName: Scalars['String'];
};

/** Input for updating a User */
export type UpdateUserInput = {
  /** Email of the User */
  email?: Maybe<Scalars['String']>;
  /** First name of the User */
  firstName?: Maybe<Scalars['String']>;
  /** Last name of the User */
  lastName?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  archivedProjects?: Maybe<ProjectConnection>;
  authoredInvitations?: Maybe<InvitationConnection>;
  authoredPrograms?: Maybe<ProgramConnection>;
  avatarUrl?: Maybe<Scalars['String']>;
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  collaborations?: Maybe<CollaborationConnection>;
  createdAt: Scalars['ISO8601DateTime'];
  designations?: Maybe<DesignationConnection>;
  email: Scalars['String'];
  favoriteProfessionalCount: Scalars['Int'];
  firstName: Scalars['String'];
  hermesUserId: Scalars['String'];
  id: Scalars['ID'];
  intercomUserHash?: Maybe<Scalars['String']>;
  invitations?: Maybe<InvitationConnection>;
  invitedPrograms?: Maybe<ProgramConnection>;
  invitedProjects?: Maybe<ProjectConnection>;
  lastName: Scalars['String'];
  managedPrograms?: Maybe<ProgramConnection>;
  memberships?: Maybe<MembershipConnection>;
  participations?: Maybe<ParticipationConnection>;
  profileCompletionPourcentage: Scalars['Int'];
  programs?: Maybe<ProgramConnection>;
  projects?: Maybe<ProjectConnection>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type UserArchivedProjectsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ProjectsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ProjectsSortInput>>;
};

export type UserAuthoredInvitationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserAuthoredProgramsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserCollaborationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserDesignationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserInvitationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<InvitationsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<InvitationsSortInput>>;
};

export type UserInvitedProgramsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserInvitedProjectsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserManagedProgramsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserMembershipsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserParticipationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ParticipationsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ParticipationsSortInput>>;
};

export type UserProgramsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserProjectsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserActivity = {
  __typename?: 'UserActivity';
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
  userActivityType: UserActivityEnum;
  userId: Scalars['ID'];
};

/** The user_activity_types of an UserActivity */
export enum UserActivityEnum {
  UserAcceptedDesignation = 'user_accepted_designation',
  UserAcceptedInvitation = 'user_accepted_invitation',
  UserAcceptedMeeting = 'user_accepted_meeting',
  UserAcceptedProgramInvitation = 'user_accepted_program_invitation',
  UserArchivedProgram = 'user_archived_program',
  UserArchivedProject = 'user_archived_project',
  UserClaimedCertificate = 'user_claimed_certificate',
  UserCreatedDesignation = 'user_created_designation',
  UserCreatedGroup = 'user_created_group',
  UserCreatedInvitation = 'user_created_invitation',
  UserCreatedOrganization = 'user_created_organization',
  UserCreatedParticipation = 'user_created_participation',
  UserCreatedProgram = 'user_created_program',
  UserCreatedProject = 'user_created_project',
  UserDeclinedDesignation = 'user_declined_designation',
  UserDeclinedInvitation = 'user_declined_invitation',
  UserDeclinedMeeting = 'user_declined_meeting',
  UserDelegateMembership = 'user_delegate_membership',
  UserDeletedCollaborationOther = 'user_deleted_collaboration_other',
  UserDeletedCollaborationSelf = 'user_deleted_collaboration_self',
  UserDeletedGroup = 'user_deleted_group',
  UserDeletedInvitation = 'user_deleted_invitation',
  UserDeletedMembershipOther = 'user_deleted_membership_other',
  UserDeletedMembershipSelf = 'user_deleted_membership_self',
  UserDeletedParticipationOther = 'user_deleted_participation_other',
  UserDeletedParticipationSelf = 'user_deleted_participation_self',
  UserDeletedProgram = 'user_deleted_program',
  UserDeletedProject = 'user_deleted_project',
  UserDisfavoredProgram = 'user_disfavored_program',
  UserDuplicatedProgram = 'user_duplicated_program',
  UserEntrustedMembership = 'user_entrusted_membership',
  UserFavoredProgram = 'user_favored_program',
  UserImportedProgramInvitations = 'user_imported_program_invitations',
  UserPublishedProgram = 'user_published_program',
  UserRecoveredProgram = 'user_recovered_program',
  UserRecoveredProject = 'user_recovered_project',
  UserResentProgramInvitaiton = 'user_resent_program_invitaiton',
  UserResentProgramInvitaitonList = 'user_resent_program_invitaiton_list',
  UserUpdatedGroupName = 'user_updated_group_name',
  UserUpdatedGroupPosition = 'user_updated_group_position',
  UserUpdatedInvitation = 'user_updated_invitation',
  UserUpdatedMembershipOther = 'user_updated_membership_other',
  UserUpdatedMembershipSelf = 'user_updated_membership_self',
  UserUpdatedProgram = 'user_updated_program',
  UserUpdatedProgramPosition = 'user_updated_program_position',
  UserUpdatedProjectName = 'user_updated_project_name',
  UserUpdatedProjectOrganization = 'user_updated_project_organization',
}

export type UserCompany = {
  __typename?: 'UserCompany';
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  company: Organization;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  jobTitles?: Maybe<JobTitleConnection>;
  organizationId: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
  userCompanyJobs?: Maybe<UserCompanyJobConnection>;
  userId: Scalars['ID'];
};

export type UserCompanyJobTitlesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserCompanyUserCompanyJobsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for UserCompany. */
export type UserCompanyConnection = {
  __typename?: 'UserCompanyConnection';
  edges: Array<UserCompanyEdge>;
  nodes: Array<UserCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type UserCompanyEdge = {
  __typename?: 'UserCompanyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<UserCompany>;
};

export type UserCompanyJob = {
  __typename?: 'UserCompanyJob';
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  current: Scalars['Boolean'];
  id: Scalars['ID'];
  jobTitle: JobTitle;
  jobTitleId: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
  userCompany: UserCompany;
  userCompanyId: Scalars['ID'];
};

/** The connection type for UserCompanyJob. */
export type UserCompanyJobConnection = {
  __typename?: 'UserCompanyJobConnection';
  edges: Array<UserCompanyJobEdge>;
  nodes: Array<UserCompanyJob>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type UserCompanyJobEdge = {
  __typename?: 'UserCompanyJobEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<UserCompanyJob>;
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Array<UserEdge>;
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type UserConversation = {
  __typename?: 'UserConversation';
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  conversation: Conversation;
  conversationId: Scalars['ID'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  messages?: Maybe<MessageConnection>;
  seenMessages?: Maybe<SeenMessageConnection>;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
  userId: Scalars['ID'];
};

export type UserConversationMessagesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserConversationSeenMessagesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for UserConversation. */
export type UserConversationConnection = {
  __typename?: 'UserConversationConnection';
  edges: Array<UserConversationEdge>;
  nodes: Array<UserConversation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type UserConversationEdge = {
  __typename?: 'UserConversationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<UserConversation>;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

export type UserFavorite = {
  __typename?: 'UserFavorite';
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  favoriteUser: User;
  favoriteUserId: Scalars['ID'];
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
  userId: Scalars['ID'];
};

export enum UserFields {
  AdminAccreditation = 'adminAccreditation',
  CollaborationsCount = 'collaborationsCount',
  CreatedAt = 'createdAt',
  Email = 'email',
  FirstName = 'firstName',
  HermesUserId = 'hermesUserId',
  Id = 'id',
  LastName = 'lastName',
  MembershipsCount = 'membershipsCount',
  ParticipationsCount = 'participationsCount',
  UpdatedAt = 'updatedAt',
}

export type UserMeeting = {
  __typename?: 'UserMeeting';
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  meeting: Meeting;
  meetingId: Scalars['ID'];
  meetingReview: MeetingReview;
  meetingReviewId: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
  userId: Scalars['ID'];
  userMeetingStatus: UserMeetingStatus;
};

/** The connection type for UserMeeting. */
export type UserMeetingConnection = {
  __typename?: 'UserMeetingConnection';
  edges: Array<UserMeetingEdge>;
  nodes: Array<UserMeeting>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type UserMeetingEdge = {
  __typename?: 'UserMeetingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<UserMeeting>;
};

/** The user_meeting_statuses of an UserMeeting */
export enum UserMeetingStatus {
  Accepted = 'accepted',
  Declined = 'declined',
  Invited = 'invited',
}

export type UserSchool = {
  __typename?: 'UserSchool';
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  school: Organization;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
  userId: Scalars['ID'];
};

/** Input for filtering a list of users through queries */
export type UsersFilterInput = {
  query?: Maybe<Array<UsersQueryFilterInput>>;
  search?: Maybe<Scalars['String']>;
};

/** Input for filtering a list of users through queries, by a specific attribute */
export type UsersQueryFilterInput = {
  filterBy: UserFields;
  operator: QueryFilterOperator;
  value: Scalars['String'];
};

/** Input for sorting a list of users through queries */
export type UsersSortInput = {
  order: SortDirection;
  orderBy: UserFields;
};

export type UserVisit = {
  __typename?: 'UserVisit';
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  seen: User;
  seenId: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
  visitor: User;
  visitorId: Scalars['ID'];
};

export type Get_My_Participations_ListQueryVariables = Exact<{ [key: string]: never }>;

export type Get_My_Participations_ListQuery = { __typename?: 'Query' } & {
  me?: Maybe<
    { __typename?: 'User' } & {
      participations?: Maybe<
        { __typename?: 'ParticipationConnection' } & {
          nodes: Array<
            { __typename?: 'Participation' } & Pick<
              Participation,
              'createdAt' | 'id' | 'certificationStatus' | 'unfollowedConversationCount' | 'reportCount'
            > & {
                program: { __typename?: 'Program' } & Pick<
                  Program,
                  'id' | 'name' | 'startAt' | 'endAt' | 'description' | 'meetingsQuantity'
                > & {
                    programTemplate: { __typename?: 'ProgramTemplate' } & Pick<ProgramTemplate, 'id' | 'displayedName'>;
                    project: { __typename?: 'Project' } & Pick<Project, 'id'> & {
                        organization: { __typename?: 'Organization' } & Pick<Organization, 'id' | 'name'>;
                      };
                    collaborations?: Maybe<
                      { __typename?: 'CollaborationConnection' } & {
                        nodes: Array<
                          { __typename?: 'Collaboration' } & Pick<Collaboration, 'id'> & {
                              user: { __typename?: 'User' } & Pick<User, 'id' | 'firstName' | 'lastName'>;
                            }
                        >;
                      }
                    >;
                  };
              }
          >;
        }
      >;
    }
  >;
};

export type Claim_CertificateMutationVariables = Exact<{
  participationId: Scalars['ID'];
}>;

export type Claim_CertificateMutation = { __typename?: 'Mutation' } & {
  claimCertificate: { __typename?: 'Participation' } & Pick<Participation, 'id'>;
};

export type Join_ProgramMutationVariables = Exact<{
  participationCode: Scalars['String'];
}>;

export type Join_ProgramMutation = { __typename?: 'Mutation' } & {
  createParticipation: { __typename?: 'Participation' } & Pick<Participation, 'id'> & {
      program: { __typename?: 'Program' } & Pick<Program, 'id' | 'name' | 'startAt'>;
    };
};

export type ClaimCertificateMutationVariables = Exact<{
  participationId: Scalars['ID'];
}>;

export type ClaimCertificateMutation = { __typename?: 'Mutation' } & {
  claimCertificate: { __typename?: 'Participation' } & Pick<Participation, 'id'>;
};
