import React, { useEffect, useRef, useContext, useMemo } from 'react';
import MessageDisplay from './components/message/MessageDisplay';
import { useQuery } from '@apollo/client';
import { ConversationViewContext } from '../../context/ConversationViewContext';
import { GET_CONVERSATION_MESSAGES } from './ConversationThread.gql';
import { PageLoader } from '../../../../../components/loader/Loader';
import {
  Get_Conversation_MessagesQuery,
  Get_Conversation_MessagesQueryVariables,
} from '../../../../../@types/graphql.d';

import './ConversationThread.scss';
import { CurrentUserContext } from '../../../../../contexts/current-user-context/CurrentUserContext';

type Conversation = NonNullable<Get_Conversation_MessagesQuery['conversation']>;
type Messages = NonNullable<Conversation['messages']>;

function ConversationThread() {
  const { currentUser } = useContext(CurrentUserContext);
  const { conversation } = useContext(ConversationViewContext);
  const conversationThread = useRef<HTMLDivElement>(null);
  const { data, loading, error } = useQuery<Get_Conversation_MessagesQuery, Get_Conversation_MessagesQueryVariables>(
    GET_CONVERSATION_MESSAGES,
    {
      variables: {
        conversationId: conversation.id,
      },
    },
  );

  const { messages, lastSentMessageId } = useMemo(() => {
    const messages = data?.conversation?.messages || ([] as Messages);
    const lastSentMessageId = messages.filter((message) => message.user.id === currentUser?.id).pop()?.id;
    return { messages, lastSentMessageId };
  }, [data?.conversation?.messages]);

  function scrollToBottomConversation() {
    if (conversationThread.current) {
      conversationThread.current.scrollTop = conversationThread.current?.scrollHeight;
    }
  }

  useEffect(() => {
    if (conversationThread.current) {
      Array.prototype.slice.call(conversationThread.current.querySelectorAll('img')).forEach((el) => {
        el.addEventListener('load', scrollToBottomConversation, false);
        el.addEventListener('error', scrollToBottomConversation, false);
      });
    }
  }, [conversationThread]);

  useEffect(scrollToBottomConversation, [messages]);

  if (!messages || loading || error) {
    return <PageLoader />;
  }

  return (
    <div
      className="conversation-thread"
      ref={conversationThread}
    >
      {messages.map((message) => (
        <MessageDisplay
          key={message.id}
          message={message}
          isLastSentMessage={message.id === lastSentMessageId}
        />
      ))}
    </div>
  );
}

export default ConversationThread;
