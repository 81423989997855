import { gql } from '@apollo/client';

export const GET_COMPANY_FOR_CONFIGURATION = gql`
  query GET_COMPANY_FOR_CONFIGURATION($companyId: ID!) {
    company(companyId: $companyId) {
      id
      name
      description
      companySize
      companySector
      companyLogo
      customAttributesSet {
        customEmployeeAttribute1
        companyAttributeValues1
        customEmployeeAttribute2
        companyAttributeValues2
        customEmployeeAttribute3
        companyAttributeValues3
      }
      recruitingWorkingAreas
    }
    companySectors {
      id
      name
    }
  }
`;
