import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'CompanyPackGauge.title',
    defaultMessage: 'Profils publiés',
    description: 'titre du nombre d Ambassadeurs publiés',
  },
  tooltip: {
    id: 'CompanyPackGauge.tooltip',
    defaultMessage:
      '<span class="highlight">Les profils publiés sont la somme des profils visibles et des profils inactifs.</span> Ils occupent une place dans votre pack Ambassadeurs.<br /><br />Une fois la limite du pack atteinte, vous ne pouvez plus publier de nouveau profil. Il faut dépublier un profil publié ou augmenter la taille du pack.',
  },
  seeEmployees: {
    id: 'CompanyPackGauge.seeEmployees',
    defaultMessage: 'Voir les Ambassadeurs',
  },
});
