import { defineMessages } from 'react-intl';

export const AmbassadorViewIdentityTranslations = defineMessages({
  likes: {
    id: 'AmbassadorViewIdentity.likes',
    defaultMessage: 'likes',
  },
  seen: {
    id: 'AmbassadorViewIdentity.seen',
    defaultMessage: 'vues',
  },
  available: {
    id: 'AmbassadorViewIdentity.available',
    defaultMessage: 'Disponible',
  },
  unavailable: {
    id: 'AmbassadorViewIdentity.unavailable',
    defaultMessage: 'Indisponible',
  },
});
