import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import SendMessageContext, { SendMessageContextInsertTemplateFunctionParams } from '../context/SendMessageContext';

interface ChildrenParams {
  toggleMessageTemplatesManager: () => void;
  isShownMessageTemplateManager: boolean;
}
interface WithMessageTemplatesProps {
  children: (params: ChildrenParams) => JSX.Element;
}
function WithMessageTemplates({ children }: WithMessageTemplatesProps) {
  const { values, setFieldValue } = useFormikContext<{ text: string }>();
  // message templates
  const [isRequestNewTemplate, setRequestNewTemplate] = useState(false);
  const [requestCloseMessageTemplate, setRequestCloseMessageTemplate] = useState(false);
  const [messageTemplateCannotBeClosed, setMessageTemplateCannotBeClosed] = useState(false);
  const [isShownMessageTemplateManager, setIsShownMessageTemplateManager] = useState(false);
  function toggleMessageTemplatesManager() {
    setIsShownMessageTemplateManager(!isShownMessageTemplateManager);
  }
  function closeMessageTemplatesManager() {
    if (messageTemplateCannotBeClosed) {
      setRequestCloseMessageTemplate(true);
      return false;
    }
    setIsShownMessageTemplateManager(false);
    return true;
  }
  function forceCloseMessageTemplate() {
    setIsShownMessageTemplateManager(false);
  }
  function insertTemplate({ id, text }: SendMessageContextInsertTemplateFunctionParams) {
    closeMessageTemplatesManager();
    setFieldValue('fromMessageTemplateId', id);
    setFieldValue('text', `${values.text}\n${text}`);
  }
  // reset on close
  useEffect(() => {
    if (!isShownMessageTemplateManager) {
      setMessageTemplateCannotBeClosed(false);
      setRequestCloseMessageTemplate(false);
    }
  }, [isShownMessageTemplateManager]);
  return (
    <SendMessageContext.Provider
      value={{
        insertTemplate,
        closeMessageTemplatesManager,
        messageTemplateCannotBeClosed,
        setMessageTemplateCannotBeClosed,
        requestCloseMessageTemplate,
        setRequestCloseMessageTemplate,
        forceCloseMessageTemplate,
        isRequestNewTemplate,
        setRequestNewTemplate,
      }}
    >
      {children({
        toggleMessageTemplatesManager,
        isShownMessageTemplateManager,
      })}
    </SendMessageContext.Provider>
  );
}

export default WithMessageTemplates;
