import React, { useEffect, useMemo } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import TextInput from '../../../../../../components/text-input/TextInput';
import ButtonsGroup from '../../../../../../components/buttons-group/ButtonsGroup';
import PrimaryButton from '../../../../../../components/primary-button/PrimaryButton';
import SecondaryButton from '../../../../../../components/secondary-button/SecondaryButton';
import useModal from '../../../../../../components/modal/Modal.hook';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import moment from 'moment-timezone';
import { useLocation } from 'react-router';
import { Join_ProgramMutation, Join_ProgramMutationVariables } from '../../../../../../@types/backend-api';
import { CreateParticipationError } from '../../../../../../@types/backend-api-error';
import buttonCommonTranslations from '../../../../../common/common-translations/button.translations';
import commonErrorTranslations from '../../../../../common/common-translations/errors.translations';
import { JOIN_PROGRAM } from './ProgramJoinModal.gql-backend-api';
import translations, { errorTranslation } from './ProgramJoinModal.translations';
import { ProgramJoinModalValidationSchema } from './ProgramJoinModal.validations';

import styles from './ProgramJoinModal.module.scss';
import { useIntl } from 'react-intl';

export default function ProgramJoinModal() {
  const intl = useIntl();
  const snackbar = useSnackbar();
  const location = useLocation();
  const [, closeModal] = useModal();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const [joinProgram] = useMutation<Join_ProgramMutation, Join_ProgramMutationVariables>(JOIN_PROGRAM, {
    refetchQueries: ['GetPrograms'],
    context: {
      clientName: 'backend-api',
    },
  });

  function onSubmit(values: { participationCode: string }) {
    return joinProgram({
      variables: {
        participationCode: values.participationCode,
      },
    })
      .then(({ data }) => {
        if (moment(data?.createParticipation.program.startAt).isBefore(moment())) {
          snackbar.enqueueSnackbar(
            intl.formatMessage(translations.success, {
              programName: data?.createParticipation.program.name,
            }),
            {
              variant: 'success',
              autoHideDuration: 5000,
            },
          );
        } else {
          snackbar.enqueueSnackbar(
            intl.formatMessage(translations.notStartProgram, {
              programName: data?.createParticipation.program.name,
            }),
            {
              variant: 'warning',
              autoHideDuration: 5000,
            },
          );
        }
        closeModal();
      })
      .catch((error: ApolloError) => {
        const firstError = error.graphQLErrors?.[0];

        if (
          firstError?.extensions?.validationErrors?.user_id.includes(
            CreateParticipationError.PARTICIPATION_USER_ID_TAKEN,
          )
        ) {
          snackbar.enqueueSnackbar(intl.formatMessage(errorTranslation.cannotJoinIndividualProgram), {
            variant: 'error',
            autoHideDuration: 5000,
          });
          return;
        }

        if (
          firstError?.extensions?.validationErrors?.participationCode.includes(
            CreateParticipationError.PARTICIPATION_PROGRAM_NOT_FOUND,
          )
        ) {
          snackbar.enqueueSnackbar(intl.formatMessage(errorTranslation.invalidCode), {
            variant: 'error',
            autoHideDuration: 5000,
          });
          return;
        }

        if (
          firstError?.extensions?.validationErrors?.participationCode.includes(
            CreateParticipationError.PARTICIPATION_PROGRAM_REACHED_PARTICIPATION_LIMIT,
          )
        ) {
          snackbar.enqueueSnackbar(intl.formatMessage(errorTranslation.cannotJoinIndividualProgram), {
            variant: 'error',
            autoHideDuration: 5000,
          });
          return;
        }

        snackbar.enqueueSnackbar(intl.formatMessage(commonErrorTranslations.unknown), {
          variant: 'error',
          autoHideDuration: 5000,
        });
      });
  }

  useEffect(() => {
    const code = searchParams.get('code');
    if (!code) return;
    onSubmit({ participationCode: code });
  }, [searchParams]);

  const formik = useFormik({
    initialValues: {
      participationCode: searchParams.get('code') || '',
    },
    onSubmit,
    validationSchema: ProgramJoinModalValidationSchema(intl),
    validateOnMount: true,
  });

  return (
    <form>
      <p
        className={styles.description}
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage(translations.description),
        }}
      />
      <TextInput 
        name="participationCode"
        value={formik.values.participationCode}
        onChange={formik.handleChange}
        error={formik.touched.participationCode ? formik.errors.participationCode : undefined} 
      />
      <ButtonsGroup>
        <SecondaryButton
          label={intl.formatMessage(buttonCommonTranslations.cancel)}
          onClick={closeModal}
        />
        <PrimaryButton
          label={intl.formatMessage(translations.participeButton)}
          disabled={!formik.isValid}
          onClick={formik.handleSubmit}
        />
      </ButtonsGroup>
    </form>
  );
}
