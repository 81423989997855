import React from 'react';
import CrossIcon from '../../../../../../../icons/component-icons/CrossIcon';
import translations from './ProfessionalFrozen.translations';
import { useIntl } from 'react-intl';

import './ProfessionalFrozen.scss';

function ProfessionalFrozen() {
  const intl = useIntl();
  return (
    <div className="professional-frozen">
      <aside className="professional-frozen-mark">
        <div className="icon-wrapper">
          <CrossIcon />
        </div>
      </aside>
      <div className="professional-frozen-content">
        <span className="professional-frozen-status">{intl.formatMessage(translations.impossibleAppointment)}</span>
        <span className="professional-frozen-inactive">{intl.formatMessage(translations.inactiveProfile)}</span>
      </div>
    </div>
  );
}

export default ProfessionalFrozen;
