import React, { useEffect, useRef, useState } from 'react';
import { generatePath, useNavigate, useMatch, useParams, useLocation, matchRoutes } from 'react-router';
import classnames from 'classnames';
import { useQueryWithError } from '../../../../../utils/graphql';
import { CompanyAdminAppointmentDetailsParams } from '../../../../../@types/routes-params.d';
import { CompanyEmployeeAppointmentErrors } from '../../../../../@types/graphql-errors.d';
import {
  AppointmentInterlocutor,
  Get_Company_Employee_AppointmentQuery,
  Get_Company_Employee_AppointmentQueryVariables,
  Get_Company_TypeQuery,
  Get_Company_TypeQueryVariables,
} from '../../../../../@types/graphql.d';
import Routes from '../../../../../routes';
import InlineLoader from '../../../../common/inline-loader/InlineLoader';
import NotFound from '../../../../../containers/not-found/NotFound';
import NoNetwork from '../../../../common/no-network/NoNetwork';
import SidebarHeader from './components/sidebar-header/SidebarHeader';
import SidebarStudentContactInformationsSection from './components/sidebar-student-contact-informations-section/SidebarStudentContactInformationsSection';
import SidebarStudentDescriptionSection from './components/sidebar-student-description-section/SidebarStudentDescriptionSection';
import SidebarStudentEducationsSection from './components/sidebar-student-educations-section/SidebarStudentEducationsSection';
import SidebarAppointmentSection from './components/sidebar-appointment-section/SidebarAppointmentSection';
import { GET_COMPANY_EMPLOYEE_APPOINTMENT } from './CompanyAdminAppointmentSidebar.gql';

import styles from './CompanyAdminAppointmentSidebar.module.scss';
import { useQuery } from '@apollo/client';
import { GET_COMPANY_TYPE } from '../../CompanyAdminAppointmentsView.gql';

function CompanyAdminAppointmentSidebar() {
  const navigate = useNavigate();
  const { appointmentid, companyid } = useParams();
  const isMjgAdmin = !!useMatch(Routes.mjgAdminCompaniesAppointmentsDetails);
  const location = useLocation();
  const matches = matchRoutes(
    [{ path: Routes.companyAdminAppointmentsDetails }, { path: Routes.mjgAdminCompaniesAppointmentsDetails }],
    location,
  );
  const isExact = !!matches;

  const [opened, setOpened] = useState<boolean | undefined>(isExact);
  useEffect(() => {
    setOpened(isExact);
  }, [isExact]);

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (typeof ref?.current?.scroll === 'function') {
      ref.current?.scroll(0, 0);
    }
  }, [appointmentid]);

  function closeSidebar() {
    setOpened(false);
    setTimeout(() => {
      navigate(
        generatePath(isMjgAdmin ? Routes.mjgAdminCompaniesAppointmentsWithId : Routes.companyAdminAppointmentsWithId, {
          companyid: companyid,
        }),
      );
    }, 300); // panel closing transition is 300ms
  }

  const { data, loading, error, refetch } = useQueryWithError<
    Get_Company_Employee_AppointmentQuery,
    Get_Company_Employee_AppointmentQueryVariables,
    CompanyEmployeeAppointmentErrors
  >(GET_COMPANY_EMPLOYEE_APPOINTMENT, {
    skip: !appointmentid,
    variables: {
      appointmentId: appointmentid || '',
    },
  });

  const { data: typeOfCompany } = useQuery<Get_Company_TypeQuery, Get_Company_TypeQueryVariables>(GET_COMPANY_TYPE, {
    skip: !companyid,
    variables: {
      companyId: companyid || '',
    },
  });

  const isPremiumSchool = typeOfCompany?.company?.isPremiumSchool;

  if (loading) {
    return (
      <div
        className={classnames(styles.companyAdminAppointmentSidebar, {
          [styles.open]: opened,
          [styles.loading]: loading,
        })}
      >
        <InlineLoader />
      </div>
    );
  }

  return (
    <div
      ref={ref}
      className={classnames(styles.companyAdminAppointmentSidebar, {
        [styles.open]: opened,
        [styles.loading]: loading,
      })}
    >
      <SidebarHeader close={closeSidebar} />

      {error?.networkError && <NoNetwork refetch={refetch} />}

      {error?.graphQLErrors?.length && <NotFound />}

      {!!data?.companyEmployeeAppointment && (
        <div className={styles.companyAdminAppointmentSidebarContent}>
          <SidebarStudentContactInformationsSection
            publicProfile={data.companyEmployeeAppointment.member as AppointmentInterlocutor}
            isPremiumSchool={isPremiumSchool}
          />
          <SidebarStudentDescriptionSection
            publicProfile={data.companyEmployeeAppointment.member as AppointmentInterlocutor}
            isPremiumSchool={isPremiumSchool}
          />
          <SidebarStudentEducationsSection
            publicProfile={data.companyEmployeeAppointment.member as AppointmentInterlocutor}
            isPremiumSchool={isPremiumSchool}
          />
          <SidebarAppointmentSection
            companyEmployeeAppointment={data.companyEmployeeAppointment}
            isPremiumSchool={isPremiumSchool}
          />
        </div>
      )}
    </div>
  );
}

export default CompanyAdminAppointmentSidebar;
