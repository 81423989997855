import React from 'react';
import classnames from 'classnames';
import { generatePath, Link } from 'react-router';
import { CompanyLinkMenuItemProps } from '../../../../@types/MainMenu';
import { useQuery } from '@apollo/client';
import {
  GetCompanyNavbarAuthorizationsQuery,
  GetCompanyNavbarAuthorizationsQueryVariables,
} from '../../../../../../../@types/graphql.d';
import { GET_COMPANY_NAVBAR_AUTHORIZATIONS_QUERY } from '../../../../config/connect/connectAuthorizations.gql';
import { useRestrictedPackModal } from '../../../../../../connect/pages/company/home/components/restricted-pack-modal/RestrictedPackModal.hook';
import useMatchedRoute from '../../../../../../../hooks/useMatchedRoute.hook';
import Routes, { CompanyAdminRoutes, MJGAdminRoutes } from '../../../../../../../routes';

const MainMenuItemCompany = ({
  to,
  className,
  label,
  icon: IconComponent,
  active,
  closeMainMenuMenu,
  isRestricted,
  authorizationKey,
}: CompanyLinkMenuItemProps) => {
  const openRestrictedPackageModal = useRestrictedPackModal();

  const match = useMatchedRoute(CompanyAdminRoutes as unknown as Routes) || useMatchedRoute(MJGAdminRoutes as unknown as Routes);
  const id = match && match ? match.params.companyid || match.params.id : null;

  if (!to || !id) {
    return null;
  }

  const paramKey = to.includes('companyid') ? 'companyid' : 'id';

  const { data } = useQuery<GetCompanyNavbarAuthorizationsQuery, GetCompanyNavbarAuthorizationsQueryVariables>(
    GET_COMPANY_NAVBAR_AUTHORIZATIONS_QUERY,
    {
      variables: { companyId: id! },
      skip: !id,
      fetchPolicy: 'network-only',
    }
  );

  if (isRestricted) {
    return (
      <div
        role='button'
        className={classnames(className, {
          'main-menu-item-link': true,
          active,
        })}
        data-label={label}
        onClick={openRestrictedPackageModal}
      >
        {IconComponent && <IconComponent />}
        <span className="main-menu-item-label">{label}</span>
      </div>
    );
  }

  if (data?.company?.isPremiumSchool && authorizationKey === 'canHomeAccess')
    return null;

  return (
    <Link
      to={generatePath(to, { [paramKey]: id })}
      className={classnames(className, {
        'main-menu-item-link': true,
        active,
      })}
      data-label={label}
      onClick={closeMainMenuMenu}
    >
      {IconComponent && <IconComponent />}
      <span className="main-menu-item-label">{label}</span>
    </Link>
  );
};

export default MainMenuItemCompany;
