import { gql } from '@apollo/client';

export const GET_CONVERSATION = gql`
  query GET_CONVERSATION($conversationId: ID!) {
    conversation(conversationId: $conversationId) {
      id
      createdAt
      lastMessage {
        seenAt
        sender {
          id
        }
      }
      messages {
        id
        text
        createdAt
        user {
          id
          firstName
          lastName
          avatar {
            thumb {
              url
            }
          }
        }
        attachment {
          name
          url
          scannedByAntivirus
        }
      }
      disabled
      currentUserIsInitiator
      currentUserCanClaimAppointment
      currentUserCanBookAppointment
      currentUserCanBookAppointmentErrors
      conversationType
      interlocutorV2 {
        id
        firstName
        lastName
        avatar {
          url
        }
        normalizedPhone
        profileType
        position
        companyName
        currentGradeOrDiploma
        currentSchoolName
        previousCompanyName
        previousPosition
        meetingPlace
        memberPresentation
        ambassadorPresentation
        acquiredDiplomaLevel {
          name
        }
        acquiredDiplomaTitle
        acquiredDiplomaSchoolName
        acquiredDiplomaBeginYear
        acquiredDiplomaAcquisitionYear
        softDeleted
        minor
        superMinor
      }
    }
  }
`;
