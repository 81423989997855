import React, { memo } from 'react';
import { Layout } from './components/layout/Layout';
import { defineMessages, useIntl } from 'react-intl';
import ReviewRadioInput from './components/review-radio-input/ReviewRadioInput';
import { useReviewContext } from '../ReviewContext';
import { AppointmentDidNotTookPlaceReasonEnum } from '../../../../@types/graphql.d';
import { AppointmentDidNotTookPlaceReasonTranslations } from '../../../../i18n/appointment-did-not-took-place-reason.translations';
import { PanelProps } from './Panel';
import TextAreaInput from '../../../../components/text-area-input/TextAreaInput';

const ORDERED_DID_NOT_TOOK_PLACE_REASON_ENUM = [
  AppointmentDidNotTookPlaceReasonEnum.DidNotShowUp,
  AppointmentDidNotTookPlaceReasonEnum.Cancelled,
  AppointmentDidNotTookPlaceReasonEnum.Rescheduled,
  AppointmentDidNotTookPlaceReasonEnum.WasNotScheduled,
  AppointmentDidNotTookPlaceReasonEnum.ForgotToGo,
  AppointmentDidNotTookPlaceReasonEnum.Other,
];

const translations = defineMessages({
  title: {
    id: 'Review.DidNotShowUpPanel.title',
    defaultMessage: 'Dîtes-nous pourquoi le rendez-vous n’a pas eu lieu',
  },
  reasonDescription: {
    id: 'Review.DidNotShowUpPanel.reasonDescription',
    defaultMessage: 'Indiquez la raison',
  },
});

export const DidNotShowUpPanel: React.FC<PanelProps> = memo(({ appointment }) => {
  const { reviewFormik } = useReviewContext();
  const { formatMessage } = useIntl();

  return (
    <Layout
      title={formatMessage(translations.title)}
      style={{ paddingTop: 88 }}
    >
      <ReviewRadioInput
        name="did-not-took-place-reason"
        value={reviewFormik.values.didNotTookPlaceReason}
        values={ORDERED_DID_NOT_TOOK_PLACE_REASON_ENUM.map((e) => ({
          value: e,
          translation: formatMessage(AppointmentDidNotTookPlaceReasonTranslations[e], {
            asMember: appointment.currentUserIsMember,
          }),
        }))}
        onSelection={(v) => {
          reviewFormik.setFieldValue('didNotTookPlaceReason', v);
        }}
      />

      {reviewFormik.values.didNotTookPlaceReason === AppointmentDidNotTookPlaceReasonEnum.Other && (
        <TextAreaInput
          label={formatMessage(translations.reasonDescription)}
          style={{ width: '100%' }}
          inputStyle={{ height: 64 }}
          value={reviewFormik.values.didNotTookPlaceReasonDescription}
          onChange={(e) => reviewFormik.setFieldValue('didNotTookPlaceReasonDescription', e.target.value)}
        />
      )}
    </Layout>
  );
});
