import { gql } from '@apollo/client';

export const GET_USER_ID_FROM_PROFESSIONAL_QUERY = gql`
  query GetUserIdFromProfessional($professionalId: ID!) {
    professionalProfile(professionalId: $professionalId) {
      userId
      user { currentUserAuthorizations { canViewAmbassadorPage } }
      unavailableUntil
      published
    }
  }
`;
