import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import {
  EmployeeStatus,
  Get_Company_Employee_Status_DistributionQuery,
  Get_Company_Employee_Status_DistributionQueryVariables,
} from '../../../../../../../@types/graphql.d';
import { GET_COMPANY_EMPLOYEE_STATUS_DISTRIBUTION } from './useStatusSegments.gql';
import getStatusColor from '../../../../../utils/colors';
import CompanyContext from '../../../../context/CompanyContext';
import translations from './useStatusSegments.translations';
import { useIntl } from 'react-intl';

export default function useStatusSegments() {
  const intl = useIntl();
  const { companyid } = useContext(CompanyContext);

  if (!companyid) {
    return [];
  }
  const { data } = useQuery<
    Get_Company_Employee_Status_DistributionQuery,
    Get_Company_Employee_Status_DistributionQueryVariables
  >(GET_COMPANY_EMPLOYEE_STATUS_DISTRIBUTION, {
    variables: {
      companyId: companyid,
    },
  });

  if (!data?.company) {
    return [];
  }

  const total =
    data.company.employeesVisibleCount +
    data.company.employeesInCrisisCount +
    data.company.employeesIcedUpCount +
    data.company.employeesAvailablePlacesCount;

  if (total === 0) {
    return [];
  }

  const activeRate = (100 * data.company.employeesVisibleCount) / total;
  const atRikRate = (100 * data.company.employeesInCrisisCount) / total;
  const inactiveRate = (100 * data.company.employeesIcedUpCount) / total;
  const availablePlacesRate = (100 * data.company.employeesAvailablePlacesCount) / total;

  const result = [
    {
      key: EmployeeStatus.Active,
      color: getStatusColor(EmployeeStatus.Active),
      percentage: activeRate,
      tooltip: intl.formatMessage(translations.activeRate, {
        rate: Math.round(activeRate),
        count: data.company.employeesVisibleCount,
      }),
    },
    {
      key: EmployeeStatus.AtRisk,
      color: getStatusColor(EmployeeStatus.AtRisk),
      percentage: atRikRate,
      tooltip: intl.formatMessage(translations.atRikRate, {
        rate: Math.round(atRikRate),
        count: data.company.employeesInCrisisCount,
      }),
    },
    {
      key: EmployeeStatus.Inactive,
      color: getStatusColor(EmployeeStatus.Inactive),
      percentage: inactiveRate,
      tooltip: intl.formatMessage(translations.inactiveRate, {
        rate: Math.round(inactiveRate),
        count: data.company.employeesIcedUpCount,
      }),
    },
    {
      key: 'Available',
      percentage: availablePlacesRate,
      color: '#f5f5f5',
      tooltip: intl.formatMessage(translations.availablePlacesRate, {
        rate: Math.round(availablePlacesRate),
        count: data.company.employeesAvailablePlacesCount,
      }),
    },
  ];

  let totalPercentageDiff = 100 - result.map((item) => item.percentage).reduce((a, b) => a + b);
  if (totalPercentageDiff < result.length) {
    for (let i = 0; totalPercentageDiff > 0; i += 1) {
      const add = Math.max(totalPercentageDiff, 1);
      totalPercentageDiff -= add;
      result[i % result.length].percentage += add;
    }
  }

  return result;
}
