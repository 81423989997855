import React from 'react';
import { Link } from 'react-router';
import ArrowLeft from '../../../../../icons/component-icons/ArrowLeft';
import Routes from '../../../../../routes';
import { MJG_BLUE } from '../../../../../utils/styled-components';
import translations from './BackToConversationsListButton.translations';

import './BackToConversationsListButton.scss';
import { useIntl } from 'react-intl';

export default function BackToConversationsListButton() {
  const intl = useIntl();

  return (
    <Link
      to={Routes.conversations}
      className="back-to-conversations-list-button"
    >
      <ArrowLeft color={MJG_BLUE} />
      {intl.formatMessage(translations.label)}
    </Link>
  );
}
