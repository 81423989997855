import { defineMessages } from 'react-intl';

export const errorTranslations = defineMessages({
  toastrYouNeedToChooseReason: {
    id: 'SoftDeletionReasonForm.error.reasonRequired',
    defaultMessage: 'Vous devez choisir une raison de suppression de votre compte',
    description: 'Toastr si l utilisateur reussi à soumettre de le formulaire de raison ... sans raison',
  },
});

export const studentAnswers = defineMessages({
  studentFoundHisCalling: {
    id: 'SoftDeletionReasonForm.student.option.foundHisCalling',
    defaultMessage: "J'ai trouvé ma voie grâce à mes rencontres My Job Glasses",
    description: 'option du formulaire de demande de suppression douce',
  },
  studentCannotDoMeetings: {
    id: 'SoftDeletionReasonForm.student.option.cannotDoMeetings',
    defaultMessage: "Je n'ai pas réussi à faire des rencontres via My Job Glasses",
    description: 'option du formulaire de demande de suppression douce',
  },
  studentDoesNotHaveEnoughTime: {
    id: 'SoftDeletionReasonForm.student.option.doesNotHaveEnoughTime',
    defaultMessage: "Je n'ai plus suffisamment de temps pour faire des rencontres",
    description: 'option du formulaire de demande de suppression douce',
  },
  studentOther: {
    id: 'SoftDeletionReasonForm.student.option.other',
    defaultMessage: 'Autre (préciser)',
    description: 'option du formulaire de demande de suppression douce',
  },
});

export const professionalAnswers = defineMessages({
  professionalDoesNotHaveEnoughTime: {
    id: 'SoftDeletionReasonForm.professional.option.doesNotHaveEnoughTime',
    defaultMessage: "Je n'ai plus suffisamment de temps à accorder aux Membres",
    description: 'option du formulaire de demande de suppression douce',
  },
  professionalNotQualiMeetings: {
    id: 'SoftDeletionReasonForm.professional.option.notQualitativeMeetings',
    defaultMessage: "Les rencontres réalisées n'étaient pas qualitatives",
    description: 'option du formulaire de demande de suppression douce',
  },
  professionalOther: {
    id: 'SoftDeletionReasonForm.professional.option.other',
    defaultMessage: 'Les rencontres effectuées ne correspondaient pas à mes attentes (préciser)',
    description: 'option du formulaire de demande de suppression douce',
  },
});

export default defineMessages({
  errorModalTitle: {
    id: 'SoftDeletionReasonForm.errorModalTitle',
    defaultMessage: 'Suppression impossible',
  },
  textareaLabel: {
    id: 'SoftDeletionReasonForm.textareaLabel',
    defaultMessage: 'Commentaire',
  },
});
