import { gql } from '@apollo/client';

import { GetMessageCreationInterlocutorDetailsQuery, GetPerceptionQuestionQuery } from '../../../@types/graphql.d';

export type NewConversationInterlocutor = NonNullable<GetMessageCreationInterlocutorDetailsQuery['interlocutor']>;
export type PerceptionQuestion = NonNullable<GetPerceptionQuestionQuery['perceptionQuestions']>;

export const GET_MESSAGE_CREATION_INTERLOCUTOR_DETAILS = gql`
  query GetMessageCreationInterlocutorDetails($id: ID!) {
    interlocutor: getUser(id: $id) {
      id
      firstName
      lastName
      avatar {
        url
      }
      remainingMeetingsAsAmbassadorThisMonth
      companyLogo {
        url
      }
      companyName
      mergedAmbassadorPresentation
      position
      meetingPreferences {
        name
        value
      }
      acceptGroupConversations
    }
  }
`;

export const CREATE_CONVERSATION = gql`
  mutation CreateConversation(
    $userId: ID!
    $message: String!
    $draftInput: ConversationDraftInput
    $conversationType: ConversationTypeKeyEnum
    $groupSessionInput: GroupSessionInput
  ) {
    createConversation(
      userId: $userId
      message: $message
      draftInput: $draftInput
      conversationType: $conversationType
      groupSessionInput: $groupSessionInput
    ) {
      id
    }
  }
`;

export const CREATE_CONVERSATION_DRAFT = gql`
  mutation CreateConversationDraft($interlocutorUserId: ID!, $message: String!) {
    createConversationDraft(interlocutorUserId: $interlocutorUserId, message: $message) {
      attributionToken
    }
  }
`;

export const GET_PERCEPTION_QUESTION = gql`
  query GetPerceptionQuestion($professionalId: ID!) {
    perceptionQuestions(professionalId: $professionalId) {
      id
      label
      isActive
      interactionType
      questionAttributes {
        id
        labelFr
        labelEn
        identifier
        order
        isActive
        description
      }
    }
  }
`;

export const CREATE_PERCEPTION_ANSWERS = gql`
  mutation CreatePerceptionAnswers(
    $conversationId: ID!
    $perceptionQuestionId: ID!
    $answers: [PerceptionAnswerInput!]!
  ) {
    createPerceptionAnswers(
      conversationId: $conversationId
      perceptionQuestionId: $perceptionQuestionId
      answers: $answers
    ) {
      id
      conversation {
        id
      }
    }
  }
`;

export const GET_DRAFTS = gql`
  query GetConversationsDrafts($interlocutorId: ID!) {
    conversationsDrafts(filters: { recipientEq: $interlocutorId }) {
      nodes {
        message
        initiator {
          primarySituation {
            name
          }
        }
        recipient {
          currentUserCanContact
          currentUserCanContactErrors
          unavailableUntil
        }
      }
    }
  }
`;
