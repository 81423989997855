import React, { useState } from 'react';
import classNames from 'classnames';
import translations from './FormikTextareaField.translations';

import './FormikTextareaField.scss';
import { useIntl } from 'react-intl';

export type FormikTextareaFieldProps = {
  field: {
    value: any;
    name: string;
    onBlur: () => void;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };
  form: {
    errors: { [key: string]: string };
    touched: { [key: string]: boolean };
  };
  label?: string;
  rows?: number;
  maxCharCount?: number;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
};

const FormikTextareaField = ({
  field,
  form,
  label,
  rows = 2,
  maxCharCount,
  className,
  disabled = false,
  placeholder,
}: FormikTextareaFieldProps) => {
  const intl = useIntl();
  const [value, setValue] = useState(field.value);
  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const newValue = maxCharCount ? e.target.value.slice(0, maxCharCount) : e.target.value;
    setValue(newValue);
    field.onChange({
      target: {
        name: field.name,
        value: newValue,
      },
    });
  }
  return (
    <div className="formik-textarea-field-wrapper">
      <div
        className={classNames('formik-textarea-field', `formik-textarea-field-${field.name}`, className, {
          'formik-textarea-field-invalid': form?.touched?.[field.name] && form?.errors?.[field.name],
          'formik-textarea-field-filled': !!field?.value,
          'formik-textarea-field-disabled': disabled,
        })}
      >
        <textarea
          id={field.name}
          rows={rows}
          value={value}
          name={field.name}
          onChange={handleChange}
          onBlur={field.onBlur}
          disabled={disabled}
          placeholder={placeholder}
        />
        {label && <label htmlFor={field.name}>{label}</label>}
      </div>
      {maxCharCount && (
        <div
          className={classNames('textarea-counter', {
            'textarea-counter-error': value && value.length >= maxCharCount,
          })}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(translations.charCounter, {
              currentLength: value ? value.length : 0,
              maxLength: maxCharCount,
            }),
          }}
        />
      )}
    </div>
  );
};

export default FormikTextareaField;
