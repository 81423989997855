import { gql } from '@apollo/client';

export const GET_COMPANY_GENERAL_INFORMATIONS_QUERY = gql`
  query GetCompanyGeneralInformations($companyId: ID!) {
    company(companyId: $companyId) {
      id
      name
      companySize
      companySector
      companyLogo
    }
  }
`;

export const UPDATE_COMPANY_LOGO_MUTATION = gql`
  mutation UpdateCompanyLogo($companyId: ID!, $companyLogoUploadId: ID) {
    updateCompany(companyId: $companyId, companyLogoUploadId: $companyLogoUploadId)
  }
`;
