import React, { useContext } from 'react';
import classNames from 'classnames';
import { Formik, Field } from 'formik';
import { CurrentUserContext } from '../../../../../contexts/current-user-context/CurrentUserContext';
import { ConversationViewContext } from '../../context/ConversationViewContext';
import Avatar from '../../../../../components/avatar/Avatar';
import { useSendMessageAction } from '../../hooks/useSendMessageAction';
import StudentClaimAppointmentButton from '../student-claim-appointment-button/StudentClaimAppointmentButton';
import SendMessageActions from '../send-message-actions/SendMessageActions';
import SendMessageField from '../send-message-field/SendMessageField';
import TemplatesManager from './components/templates-manager/TemplatesManager';
import WithMessageTemplates from './hoc/WithMessageTemplates';
import translations from './SendMessage.translations';
import { SendMessageValidationSchema } from './SendMessage.validations';

import './SendMessage.scss';
import { useIntl } from 'react-intl';

function SendMessage() {
  const intl = useIntl();
  const { currentUser } = useContext(CurrentUserContext);
  const { conversation } = useContext(ConversationViewContext);
  const [sendMessage] = useSendMessageAction();

  const isAmbassador = !conversation?.currentUserIsInitiator;

  return (
    <Formik
      initialValues={{
        conversationId: conversation.id,
        text: '',
      }}
      onSubmit={(values, { resetForm }) => {
        sendMessage({ variables: values }).then(() => resetForm());
      }}
      validationSchema={SendMessageValidationSchema(intl)}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <WithMessageTemplates>
          {({ toggleMessageTemplatesManager, isShownMessageTemplateManager }) => (
            <div
              className={classNames({
                'send-message': true,
                'send-message-disabled': conversation?.disabled,
                'send-message-template-manager-mode': isShownMessageTemplateManager,
              })}
            >
              <div className="top-actions">
                <StudentClaimAppointmentButton />
              </div>
              {isAmbassador && isShownMessageTemplateManager && (
                <div className="template-manage-wrapper">
                  <TemplatesManager />
                  <Avatar
                    avatar={currentUser?.avatars}
                    size="small"
                    className="avatar"
                  />
                </div>
              )}

              <form onSubmit={handleSubmit}>
                <div className="send-message-form">
                  <Field
                    name="text"
                    component={SendMessageField}
                    placeholder={intl.formatMessage(translations.replyTo, {
                      recipientName: conversation?.interlocutorV2?.firstName,
                    })}
                    disabled={conversation?.disabled}
                    rows={6}
                  />
                  <Avatar
                    avatar={currentUser?.avatars}
                    size="small"
                    className="avatar"
                  />
                </div>
                <SendMessageActions
                  isManagingTemplates={isShownMessageTemplateManager}
                  toggleMessageTemplatesManager={toggleMessageTemplatesManager}
                  isAmbassador={isAmbassador}
                  disabled={conversation?.disabled}
                />
              </form>
            </div>
          )}
        </WithMessageTemplates>
      )}
    </Formik>
  );
}

export default SendMessage;
