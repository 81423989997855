import { defineMessages } from 'react-intl';

export const RestrictedPackModalTranslations = defineMessages({
  title: {
    id: 'ConnectCompanyModal.title',
    defaultMessage: 'Fonctionnalité Premium',
  },
  body: {
    id: 'ConnectCompanyModal.body',
    defaultMessage: 'Cette fonctionnalité est réservée aux entreprises bénéficiant d’une offre de niveau supérieur.',
  },
  primaryButton: {
    id: 'ConnectCompanyModal.primaryButton',
    defaultMessage: 'Découvrir nos offres',
  },
});
