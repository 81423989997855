import { gql } from '@apollo/client';

export const ADD_COMPANY_RECRUITING_WORKING_AREA_MUTATION = gql`
  mutation AddCompanyRecruitingWorkingArea($companyId: ID!, $workingArea: String!) {
    addCompanyRecruitingWorkArea(companyId: $companyId, workingArea: $workingArea) {
      workingAreas
    }
  }
`;

export const REMOVE_COMPANY_RECRUITING_WORKING_AREA_MUTATION = gql`
  mutation RemoveCompanyRecruitingWorkingArea($companyId: ID!, $workingArea: String!) {
    removeCompanyRecruitingWorkArea(companyId: $companyId, workingArea: $workingArea) {
      workingAreas
    }
  }
`;
