import { gql } from '@apollo/client';

export const GET_COMPANY_ATTRACTIVENESS_REPORT = gql`
  query GET_COMPANY_ATTRACTIVENESS_REPORT($range: CompanyReportRange!, $companyId: ID!) {
    companyReport(range: $range, companyId: $companyId) {
      companyId
      employeesViewCount
      uniqueProfileViewsFromStudents
      wishCount
      likeCount
      studentsSeeingThemselvesInTheSectorRate
      isPremiumSchool
    }
  }
`;

export const GET_COMPANY_AUTHORIZATION_DASHBOARD_QUERY = gql`
  query GetCompanyAuthorizationDashboard($companyId: ID!) {
    company(companyId: $companyId) {
      canShowFullDashboardStats
      canShowPartialDashboardStats
    }
  }
`;
