import React, { memo, useEffect } from 'react';
import * as Sentry from "@sentry/react";
import { useReviewContext } from '../ReviewContext';
import { useMutation } from '@apollo/client';
import {
  CREATE_PERCEPTION_ANSWERS,
  REPORT_APPOINTMENT_DID_NOT_TOOK_PLACE,
  REVIEW_APPOINTMENT_AS_AMBASSADOR,
  REVIEW_APPOINTMENT_AS_MEMBER,
} from '../Review.gql';
import {
  CreatePostAppointmentPerceptionAnswersMutation,
  CreatePostAppointmentPerceptionAnswersMutationVariables,
  InternshipDuration,
  InternshipTypeEnum,
  ReportAppointmentDidNotTookPlaceMutation,
  ReportAppointmentDidNotTookPlaceMutationVariables,
  ReviewAppointmentAsAmbassadorMutation,
  ReviewAppointmentAsAmbassadorMutationVariables,
  ReviewAppointmentAsMemberMutation,
  ReviewAppointmentAsMemberMutationErrorEnum,
  ReviewAppointmentAsMemberMutationVariables,
} from '../../../../@types/graphql.d';
import { PanelProps } from './Panel';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import Routes from '../../../../routes';
import { defineMessages, useIntl } from 'react-intl';
import moment from 'moment';
import { errorTracker } from '../../../../error-tracker/error-tracker';

const translations = defineMessages({
  reviewSuccess: {
    id: 'Review.SubmittingPanel.reviewSuccess',
    defaultMessage: "Merci d'avoir complété votre évaluation !",
  },
  reviewError: {
    id: 'Review.SubmittingPanel.reviewError',
    defaultMessage: "Une erreur est survenue lors de l'envoi de votre évaluation",
  },
});

const errorTranslations = defineMessages<string>({
  [ReviewAppointmentAsMemberMutationErrorEnum.CurrentUserAlreadyReviewed]: {
    id: 'Review.SubmittingPanel.errorTranslations.CurrentUserAlreadyReviewed',
    defaultMessage: 'Vous avez déjà évalué ce rendez-vous',
  },
  other: {
    id: 'Review.SubmittingPanel.errorTranslations.UnexpectedValidationError',
    defaultMessage: 'Une erreur inattendue est survenue. Veuillez prendre contacte avec nos équipes support.',
  },
});

export const SubmittingPanel: React.FC<PanelProps> = memo(({ appointment, back }) => {
  const { formatMessage } = useIntl();
  const { reviewFormik } = useReviewContext();
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const navigate = useNavigate();

  const [createPerceptionAnswers] = useMutation<CreatePostAppointmentPerceptionAnswersMutation, CreatePostAppointmentPerceptionAnswersMutationVariables>(CREATE_PERCEPTION_ANSWERS);

  const [reviewAsMember] = useMutation<ReviewAppointmentAsMemberMutation, ReviewAppointmentAsMemberMutationVariables>(
    REVIEW_APPOINTMENT_AS_MEMBER,
    {
      variables: {
        id: appointment!.id,
        question1: reviewFormik.values.question1!,
        question2: reviewFormik.values.question2!,
        question3: reviewFormik.values.question3!,
        question4: reviewFormik.values.question4!,
        message: reviewFormik.values.message!,
        companyAspiration:
          reviewFormik.values.wantsToBeRecontacted && appointment?.memberReview?.canFillCompanyAspiration
            ? {
                internshipType: reviewFormik.values.jobType![0],
                // internshipType: reviewFormik.values.jobType!, TODO replace the line above with this when the back match
                workingAreas: reviewFormik.values.workingArea,
                duration: reviewFormik.values.jobType?.every((jt) => jt === InternshipTypeEnum.Cdi)
                  ? InternshipDuration.InternshipDurationUndetermined
                  : reviewFormik.values.duration!,
                startAt: moment(reviewFormik.values.startDate!).endOf('month').toISOString(),
                postalCode: reviewFormik.values.postalCode!,
                franceLevel1AdministrativeAreas: reviewFormik.values.administrativeArea
                  ? [reviewFormik.values.administrativeArea]
                  : undefined,
              }
            : undefined,
        schoolAspiration:
          reviewFormik.values.wantsToBeRecontacted && appointment?.memberReview?.canFillSchoolAspiration
            ? {
                grade: reviewFormik.values.grade!,
                year: reviewFormik.values.year!,
              }
            : undefined,
      },
      refetchQueries: ['GetFirstAppointmentToForceReview'],
      awaitRefetchQueries: true,
      onCompleted: () => {
        snackbar(formatMessage(translations.reviewSuccess), { variant: 'success' });
        navigate(Routes.home);
      },
      onError: (err) => {
        if (err.graphQLErrors.length < 1) return;
        const firstErr = err.graphQLErrors[0].extensions?.code as string;
        const translation = errorTranslations[firstErr] || errorTranslations.other;
        errorTracker.sendError('Could not submit review', 'As member', {
          namespace: 'GraphQL',
          payload: {
            q1: reviewFormik.values.question1,
            q2: reviewFormik.values.question2,
            q3: reviewFormik.values.question3,
            q4: reviewFormik.values.question4,
            wtbr: reviewFormik.values.wantsToBeRecontacted,
            jt: reviewFormik.values.jobType,
            du: reviewFormik.values.duration,
            wa: reviewFormik.values.workingArea,
            sd: reviewFormik.values.startDate,
            av: reviewFormik.values.availability,
            aa: reviewFormik.values.administrativeArea,
            pc: reviewFormik.values.postalCode,
          },
          tags: {
            graphqlError: JSON.stringify(firstErr),
          },
        });
        snackbar(formatMessage(translation), { variant: 'error' });
        back();
      },
    },
  );

  const [reviewAsAmbassador] = useMutation<
    ReviewAppointmentAsAmbassadorMutation,
    ReviewAppointmentAsAmbassadorMutationVariables
  >(REVIEW_APPOINTMENT_AS_AMBASSADOR, {
    variables: {
      id: appointment!.id,
      question1: reviewFormik.values.question1!,
      question2: reviewFormik.values.question2!,
      question3: reviewFormik.values.question3!,
      message: reviewFormik.values.message!,
      messageForHr: reviewFormik.values.messageForHR,
    },
    refetchQueries: ['GetFirstAppointmentToForceReview'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      snackbar(formatMessage(translations.reviewSuccess), { variant: 'success' });
      navigate(Routes.home);
    },
    onError: (err) => {
      if (err.graphQLErrors.length < 1) return;
      const firstErr = err.graphQLErrors[0].extensions?.code as string;
      const translation = errorTranslations[firstErr] || errorTranslations.other;
      useEffect(() => {Sentry.captureException(firstErr)}, [firstErr]);
      snackbar(formatMessage(translation), { variant: 'error' });
      back();
    },
  });

  const [reportAppointmentDidNotTookPlace] = useMutation<
    ReportAppointmentDidNotTookPlaceMutation,
    ReportAppointmentDidNotTookPlaceMutationVariables
  >(REPORT_APPOINTMENT_DID_NOT_TOOK_PLACE, {
    variables: {
      id: appointment!.id,
      reason: reviewFormik.values.didNotTookPlaceReason!,
      description: reviewFormik.values.didNotTookPlaceReasonDescription,
    },
    refetchQueries: ['GetFirstAppointmentToForceReview'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      snackbar(formatMessage(translations.reviewSuccess), { variant: 'success' });
      navigate(Routes.home);
    },
    onError: (err) => {
      if (err.graphQLErrors.length < 1) return;
      const firstErr = err.graphQLErrors[0].extensions?.code as string;
      const translation = errorTranslations[firstErr] || errorTranslations.other;
      useEffect(() => {Sentry.captureException(firstErr)}, [firstErr]);
      snackbar(formatMessage(translation), { variant: 'error' });
      back();
    },
  });

  useEffect(() => {
    if (reviewFormik.values.didNotTookPlaceReason) {
      reportAppointmentDidNotTookPlace();
      return;
    }

    if (appointment?.currentUserIsMember) reviewAsMember();

    if (appointment?.currentUserIsAmbassador) reviewAsAmbassador();
    if (reviewFormik.values.perception) {
      createPerceptionAnswers({
        variables: {
          conversationId: appointment.conversationId,
          appointmentId: appointment!.id,
          perceptionQuestionId: reviewFormik.values.perception.id,
          answers: Object.entries(reviewFormik.values.perception.answers).map(([attributeId, rating]) => ({
            perceptionQuestionAttributeId: attributeId,
            rating,
          })),
        },
      });
    }
  }, []);

  return <></>;
});
