import { gql } from '@apollo/client';

export const GET_COMPANY_SECTORS = gql`
  query GET_COMPANY_SECTORS {
    companySectors {
      id
      name
    }
  }
`;

export const UPDATE_COMPANY_GENERAL_INFORMATIONS = gql`
  mutation UPDATE_COMPANY_GENERAL_INFORMATIONS(
    $companyId: ID!
    $sectorId: ID
    $size: CompanySize
    $establishmentType: SecondarySituationEnum
  ) {
    updateCompany(companyId: $companyId, sectorId: $sectorId, size: $size, establishmentType: $establishmentType)
  }
`;
