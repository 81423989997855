import { defineMessages } from 'react-intl';

export default defineMessages({
  beFirst: {
    id: 'NoStudentFeedbacks.beFirst',
    defaultMessage: 'Soyez le premier pro à laisser un avis :',
    description: 'Message insitant le pro à rencontrer le Membre',
  },
  purposeAppointment: {
    id: 'NoStudentFeedbacks.purposeAppointment',
    defaultMessage: 'proposez un rendez-vous au Membre !',
    description: 'Message demandant au pro de proposer un RDV au pro',
  },
});
