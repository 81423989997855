import { defineMessages } from 'react-intl';

export const NoResultTranslations = defineMessages({
  title: {
    id: 'NoResult.title',
    defaultMessage: 'Oups !',
  },
  subtitle: {
    id: 'NoResult.subtitle',
    defaultMessage: 'On dirait qu’aucun Ambassadeur ne correspond à votre recherche.',
  },
  tipsLabel: {
    id: 'NoResult.tipsLabel',
    defaultMessage: "Pour augmenter vos chances d'obtenir des résultats, vous pouvez :",
  },
  tip1: {
    id: 'NoResult.tip1',
    defaultMessage: "Vérifier qu'il n'y a pas de fautes de frappe dans votre recherche",
  },
  tip2: {
    id: 'NoResult.tip2',
    defaultMessage: 'Rechercher des synonymes',
  },
  tip3: {
    id: 'NoResult.tip3',
    defaultMessage: 'Faire une recherche moins précise, avec moins de mots-clés',
  },
  tip4: {
    id: 'NoResult.tip4',
    defaultMessage: "Essayer notre outil de recherche par style, pour trouver de l'inspiration",
  },
});
