import { defineMessages } from 'react-intl';

export default defineMessages({
  employeePlaceholder: {
    id: 'TopEmployeeItem.employeePlaceholder',
    defaultMessage: 'Ambassadeur/e n°{index}',
  },
  meeting: {
    id: 'TopEmployeeItem.meeting',
    defaultMessage: 'RDV',
  },
});
