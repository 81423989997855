import React from 'react';
import translations from './GraphQLInfiniteScrollError.translations';

import styles from './GraphQLInfiniteScrollError.module.scss';
import { useIntl } from 'react-intl';

interface GraphQLInfiniteScrollErrorProps {
  display: boolean;
  retry: () => void;
}
function GraphQLInfiniteScrollError({ display, retry }: GraphQLInfiniteScrollErrorProps) {
  const intl = useIntl();
  if (!display) {
    return null;
  }
  return (
    <div className={styles.graphQLInfiniteScrollError}>
      <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
      <button
        type="button"
        onClick={retry}
        className={styles.button}
      >
        {intl.formatMessage(translations.button)}
      </button>
    </div>
  );
}

export default GraphQLInfiniteScrollError;
