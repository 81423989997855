import React from 'react';
import MessageNotificationCircleIcon from '../../../icons/component-icons/MessageNotificationCircleIcon';
import MessageAlertCircleIcon from '../../../icons/component-icons/MessageAlertCircle';
import MarkerPinIcon from '../../../icons/component-icons/MarkerPinIcon';
import PhoneCallIcon from '../../../icons/component-icons/PhoneCallIcon';
import WebcamIcon from '../../../icons/component-icons/WebcamIcon';
import translations from './Legend.translations';

import './Legend.scss';
import { useIntl } from 'react-intl';

type LegendType = 'conversations' | 'appointments';

type LegendProps = {
  type: LegendType;
};

export default function Legend({ type }: LegendProps) {
  const intl = useIntl();
  return (
    <div className="legend">
      <div className="legend__as-member">{intl.formatMessage(translations.asMember)}</div>
      <div className="legend__as-ambassador">{intl.formatMessage(translations.asAmbassador)}</div>
      {type === 'conversations' ? (
        <>
          <div className="legend__unseen">
            <MessageNotificationCircleIcon />
            {intl.formatMessage(translations.unseen)}
          </div>
          <div className="legend__need-action">
            <MessageAlertCircleIcon />
            {intl.formatMessage(translations.needAction)}
          </div>
        </>
      ) : (
        <>
          <div className="legend__physical">
            <MarkerPinIcon />
            {intl.formatMessage(translations.physical)}
          </div>
          <div className="legend__phone">
            <PhoneCallIcon />
            {intl.formatMessage(translations.phoneCall)}
          </div>
          <div className="legend__visio">
            <WebcamIcon />
            {intl.formatMessage(translations.visio)}
          </div>
        </>
      )}
    </div>
  );
}
