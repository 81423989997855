import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'TemplatesManager.title',
    defaultMessage: 'Mes <strong>templates</strong>',
    description: 'Titre du manager de templates',
  },
  closeButton: {
    id: 'TemplatesManager.closeButton',
    defaultMessage: 'Fermer',
    description: 'Bouton pour fermer le manager de templates',
  },
  description: {
    id: 'TemplatesManager.description',
    defaultMessage:
      'Rédigez ici un message pré-défini que vous pourrez utiliser pour répondre plus facilement à vos messages.',
    description: 'Description du manager de templates',
  },
  createButton: {
    id: 'TemplatesManager.createButton',
    defaultMessage: 'Créer un nouveau template',
    description: 'Bouton pour créer un nouveau template',
  },
});
