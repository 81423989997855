import { useMutation } from '@apollo/client';
import { Send_MessageMutation, Send_MessageMutationVariables } from '../../../../@types/graphql.d';
import { SEND_MESSAGE } from './useSendMessageAction.gql';
import { GET_CONVERSATION_MESSAGES } from '../../../../scenes/interactions/conversations/components/conversation-thread/ConversationThread.gql';
import { GET_CONVERSATION_MESSAGES_AND_INTERLOCUTORS } from '../../../../scenes/interactions/conversations/components/send-message/SendMessage.gql';

export function useSendMessageAction() {
  const [sendMessage] = useMutation<Send_MessageMutation, Send_MessageMutationVariables>(SEND_MESSAGE);

  function handleSendMessage({ variables }: { variables: Send_MessageMutationVariables }) {
    return sendMessage({
      variables,
      refetchQueries: [
        { query: GET_CONVERSATION_MESSAGES, variables: { conversationId: variables.conversationId } },
        { query: GET_CONVERSATION_MESSAGES_AND_INTERLOCUTORS, variables: { conversationId: variables.conversationId } },
      ],
    });
  }
  return [handleSendMessage];
}
