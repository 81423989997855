import { gql } from '@apollo/client';

export const GET_FIRST_APPOINTMENT_CLAIM_TO_FORCE_REVIEW_QUERY = gql`
  query GetFirstAppointmentClaimToForceReview {
    appointmentClaims(first: 1, filters: { currentUserMustReview: true }) {
      nodes {
        id
        currentUserMustReview
      }
    }
  }
`;
