import { defineMessages } from 'react-intl';
import { JobSeekerSupportProgramKeyEnum } from '../@types/graphql.d';

export const JobSeekerSupportProgramKeyEnumTranslations = defineMessages<string>({
  [JobSeekerSupportProgramKeyEnum.AccompagnementGlobal]: {
    id: 'JobSeekerSupportProgramKeyEnum.ActivEmploi',
    defaultMessage: "Activ'Emploi",
  },
  [JobSeekerSupportProgramKeyEnum.Csp]: {
    id: 'JobSeekerSupportProgramKeyEnum.Csp',
    defaultMessage: 'Contrat de Sécurisation Professionnelle',
  },
  [JobSeekerSupportProgramKeyEnum.Aij]: {
    id: 'JobSeekerSupportProgramKeyEnum.Aij',
    defaultMessage: 'AIJ (Accompagnement Intensif Jeunes)',
  },
  [JobSeekerSupportProgramKeyEnum.DynamicSeniors]: {
    id: 'JobSeekerSupportProgramKeyEnum.DynamicSeniors',
    defaultMessage: 'Dynamic Séniors',
  },
  [JobSeekerSupportProgramKeyEnum.Cej]: {
    id: 'JobSeekerSupportProgramKeyEnum.Cej',
    defaultMessage: 'CEJ (Contrat Engagement Jeune)',
  },
  [JobSeekerSupportProgramKeyEnum.EquipEmploi]: {
    id: 'JobSeekerSupportProgramKeyEnum.EquipEmploi',
    defaultMessage: "Equip'emploi",
  },
  [JobSeekerSupportProgramKeyEnum.EquipRecrut]: {
    id: 'JobSeekerSupportProgramKeyEnum.EquipRecrut',
    defaultMessage: "Equip'recrut",
  },
  [JobSeekerSupportProgramKeyEnum.Mobilite]: {
    id: 'JobSeekerSupportProgramKeyEnum.Mobilite',
    defaultMessage: 'Mobilité',
  },
  [JobSeekerSupportProgramKeyEnum.PortefeuilleGuide]: {
    id: 'JobSeekerSupportProgramKeyEnum.PortefeuilleGuide',
    defaultMessage: 'Portefeuille guidé',
  },
  [JobSeekerSupportProgramKeyEnum.PortefeuilleRenforce]: {
    id: 'JobSeekerSupportProgramKeyEnum.PortefeuilleRenforce',
    defaultMessage: 'Portefeuille renforcé',
  },
  [JobSeekerSupportProgramKeyEnum.PortefeuilleSuivi]: {
    id: 'JobSeekerSupportProgramKeyEnum.PortefeuilleSuivi',
    defaultMessage: 'Portefeuille suivi',
  },
  [JobSeekerSupportProgramKeyEnum.Other]: {
    id: 'JobSeekerSupportProgramKeyEnum.Other',
    defaultMessage: 'Autre',
  },
});
