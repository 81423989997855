import React, { useCallback, useEffect, useState } from 'react';
import * as Sentry from "@sentry/react";
import classnames from 'classnames';
import { useSnackbar } from 'notistack';

import DownloadAppointmentReportTranslations from '../../../scenes/company-admin/appointments/components/company-admin-appointments-header/components/download-appointment-report/DownloadAppointmentReport.translations';
import WithTooltip from '../with-tooltip/WithTooltip';

import styles from './DownloadWithTooltip.module.scss';

import { useIntl } from 'react-intl';
import { useLanguage } from '../../../hooks/useLanguages';
import { errorTracker } from '../../../error-tracker/error-tracker';

export interface DownloadWithTooltipProps {
  text: string;
  url: string;
  filename: string;
  mimeType?: string;
  buttonClassName?: string;
  position?: 'bottom' | 'bottom-left' | 'bottom-right';
  variant?: 'blue' | 'blue-dark';
  disabled?: boolean;
}

function DownloadWithTooltip({
  text,
  disabled,
  buttonClassName,
  url,
  filename,
  position = 'bottom',
  variant = 'blue',
}: DownloadWithTooltipProps): JSX.Element {
  const intl = useIntl();
  const { language } = useLanguage();
  const [downloadStarted, setDownloadStarted] = useState(false);
  const snackbar = useSnackbar();

  const headers = new Headers({
    'X-User-Language': language,
  });
  const onDownload = useCallback(async () => {
    setDownloadStarted(true);
    return fetch(url, {
      credentials: 'include',
      headers,
    })
      .then((response) => {
        if (response.ok) {
          snackbar.enqueueSnackbar(intl.formatMessage(DownloadAppointmentReportTranslations.dispatchedViaEmail), {
            autoHideDuration: 5000,
            variant: 'info',
          });
          setDownloadStarted(true);
        } else {
          snackbar.enqueueSnackbar(intl.formatMessage(DownloadAppointmentReportTranslations.error), {
            autoHideDuration: 5000,
            variant: 'error',
          });
          const error = new Error(`DownloadWithTooltip: Error when downloading ${filename} by email`);
          errorTracker.sendError('DownloadWithTooltip', `Error when downloading ${filename} by email`, {
            namespace: 'Frontend',
            payload: {
              filename: filename,
            },
            tags: {
              frontendError: JSON.stringify(error),
            },
          });
          useEffect(() => {Sentry.captureException(error)}, [error]);
        }
      })
      .catch((fetchErr) => {
        snackbar.enqueueSnackbar(intl.formatMessage(DownloadAppointmentReportTranslations.error), {
          autoHideDuration: 5000,
          variant: 'error',
        });
        useEffect(() => {Sentry.captureException(fetchErr)}, [fetchErr]);
      });
  }, [filename]);

  return (
    <WithTooltip
      text={text}
      className={styles.tooltipWrapper}
      tooltipClassName={classnames(styles.tooltip, {
        [styles.tooltipBottomLeft]: position === 'bottom-left',
        [styles.tooltipBottom]: position === 'bottom',
        [styles.tooltipBottomRight]: position === 'bottom-right',
        [styles.blueVariant]: variant === 'blue',
        [styles.blueDarkVariant]: variant === 'blue-dark',
      })}
    >
      {({ openTooltip, closeTooltip }) => (
        <button
          data-testid="download-tooltip"
          type="submit"
          disabled={downloadStarted}
          onClick={onDownload}
          onMouseEnter={openTooltip}
          onFocus={openTooltip}
          onMouseLeave={closeTooltip}
          onBlur={closeTooltip}
          className={classnames(styles.downloadButton, buttonClassName, {
            [styles.enabled]: !disabled,
          })}
        >
          ↓
        </button>
      )}
    </WithTooltip>
  );
}

export default DownloadWithTooltip;
