import React from 'react';
import { generatePath, useNavigate } from 'react-router';

import classNames from 'classnames';

import './DraftListItem.scss';

import { PrimarySituationEnum } from '../../../../../@types/graphql.d';
import BriefcaseIcon from '../../../../../icons/component-icons/BriefcaseIcon';
import BuildingIcon from '../../../../../icons/component-icons/BuildingIcon';
import GraduateHatIcon from '../../../../../icons/component-icons/GraduateHatIcon';
import Routes from '../../../../../routes';
import { Draft } from '../../DraftList.gql';

type DraftListItemProps = {
  draft: Draft;
};

export default function DraftListItem({ draft }: DraftListItemProps) {
  const navigate = useNavigate();

  const getStatusDetailsFromInterlocutor = () => {
    switch (draft.recipient.primarySituation?.key) {
      case PrimarySituationEnum.Student:
        return (
          <div>
            <GraduateHatIcon />
            {draft.recipient.currentSchoolName}
          </div>
        );
      case PrimarySituationEnum.Worker:
        return (
          <>
            <div>
              <BuildingIcon />
              {draft.recipient.companyName}
            </div>
            <div>
              <BriefcaseIcon />
              {draft.recipient.jobTitle}
            </div>
          </>
        );
      case PrimarySituationEnum.JobSeeker:
      case PrimarySituationEnum.Retired:
        return draft.recipient?.companyName ? (
          <>
            <div>
              <BuildingIcon />
              {'ex-' + draft.recipient.companyName}
            </div>
            <div>
              <BriefcaseIcon />
              {'ex-' + draft.recipient.jobTitle}
            </div>
          </>
        ) : null;
      default:
        return null;
    }
  };

  function onClick() {
    navigate(generatePath(Routes.conversationCreation, { id: draft.recipient.id }) + '?step=1');
  }

  return (
    <div
      onClick={onClick}
      className={classNames('draft-list-item')}
      data-cy="draft-list-item"
    >
      <img
        className="draft-list-item__avatar"
        src={draft.recipient.avatars.url!}
        alt=""
      />
      <div className="draft-list-item__infos">
        <div className="draft-list-item__infos__head">
          <div className="draft-list-item__infos__head__interlocutor">
            <div className="draft-list-item__infos__head__interlocutor__name">{draft.recipient.firstName}</div>
            <div className="draft-list-item__infos__head__interlocutor__status">
              {getStatusDetailsFromInterlocutor()}
            </div>
          </div>
        </div>
        <div className="draft-list-item__infos__last-message">{draft.message}</div>
      </div>
    </div>
  );
}
