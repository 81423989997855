import * as Sentry from '@sentry/react';
import React from 'react';
import { Outlet } from 'react-router';

import { SnackbarProvider } from 'notistack';

import ModalContextProvider from '../components/modal/Modal.context';
import OptionalIntercomProvider from '../containers/intercom/IntercomContainer';
import Layout from '../containers/layout/Layout';
import ForceAppointmentReview from '../containers/review/ForceAppointmentReview';
import { CurrentUserContextProvider } from '../contexts/current-user-context/CurrentUserContext';
import { SettingsContextProvider } from '../contexts/settings-context/SettingsContext';

export const ConnectedRouterWrapper = () => (
  <OptionalIntercomProvider>
    <SettingsContextProvider>
      <CurrentUserContextProvider>
        <ModalContextProvider>
          <Layout connected>
            <SnackbarProvider
              maxSnack={5}
              hideIconVariant
              anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
              <ForceAppointmentReview>
                <Outlet />
              </ForceAppointmentReview>
            </SnackbarProvider>
          </Layout>
        </ModalContextProvider>
      </CurrentUserContextProvider>
    </SettingsContextProvider>
  </OptionalIntercomProvider>
);
