import { defineMessages } from 'react-intl';

export default defineMessages({
  asMember: {
    id: 'Legend.asMember',
    defaultMessage: 'En tant que Membre',
  },
  asAmbassador: {
    id: 'Legend.asAmbassador',
    defaultMessage: "En tant qu'Ambassadeur",
  },
  unseen: {
    id: 'Legend.unseen',
    defaultMessage: 'Nouveau message',
  },
  needAction: {
    id: 'Legend.needAction',
    defaultMessage: "En attente d'une réponse",
  },
  physical: {
    id: 'Legend.physical',
    defaultMessage: 'Rendez-vous physique',
  },
  phoneCall: {
    id: 'Legend.phoneCall',
    defaultMessage: 'Appel téléphonique',
  },
  visio: {
    id: 'Legend.visio',
    defaultMessage: 'Visioconférence',
  },
});
