import ButtonsGroup from '../../../../../components/buttons-group/ButtonsGroup';
import CheckboxInput from '../../../../../components/checkbox-input/CheckboxInput';
import useModal from '../../../../../components/modal/Modal.hook';
import PrimaryButton from '../../../../../components/primary-button/PrimaryButton';
import SecondaryButton from '../../../../../components/secondary-button/SecondaryButton';
import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import buttonCommonTranslations from '../../../../../scenes/common/common-translations/button.translations';

const translations = defineMessages({
  conditionAggrement: {
    id: 'FrenchRestrictionModal.conditionAggrement',
    defaultMessage:
      'J’ai bien pris connaissance de cette information importante et je confirme que je suis de nationalité française.',
  },
  contact: {
    id: 'FrenchRestrictionModal.contact',
    defaultMessage: 'Contacter',
  },
});

type FrenchRestrictionModalProps = {
  restrictionContent: string;
  onSubmit: () => void;
};

export default function FrenchRestrictionModal({ restrictionContent, onSubmit }: FrenchRestrictionModalProps) {
  const [, closeModal] = useModal();
  const intl = useIntl();
  const [confirm, setConfirm] = useState(false);

  return (
    <div>
      <p>{restrictionContent}</p>
      <CheckboxInput
        name="condition-aggrement"
        label={intl.formatMessage(translations.conditionAggrement)}
        checked={confirm}
        onChange={(e) => setConfirm(e.target.checked)}
      />
      <ButtonsGroup>
        <PrimaryButton
          label={intl.formatMessage(translations.contact)}
          onClick={onSubmit}
          disabled={!confirm}
        />
        <SecondaryButton
          label={intl.formatMessage(buttonCommonTranslations.cancel)}
          onClick={closeModal}
        />
      </ButtonsGroup>
    </div>
  );
}
