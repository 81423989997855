import { gql } from '@apollo/client';

export const GET_COMPANY_EMPLOYEE_STATUS_DISTRIBUTION = gql`
  query GET_COMPANY_EMPLOYEE_STATUS_DISTRIBUTION($companyId: ID!) {
    company(companyId: $companyId) {
      id
      employeePackSize
      employeesVisibleCount
      employeesInCrisisCount
      employeesIcedUpCount
      employeesAvailablePlacesCount
    }
  }
`;
