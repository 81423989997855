import { useQuery } from '@apollo/client';
import React, { useContext } from 'react';
import useModal from '../../../../../components/modal/Modal.hook';
import { GetProgramsQuery, PrimarySituationEnum, SecondarySituationEnum } from '../../../../../@types/graphql.d';
import DashboardCard, { DashboardCardLoader } from '../../components/dashboard-card/DashboardCard';
import { GET_PROGRAMS_QUERY } from '../../DashboardView.gql';
import Program from './program/Program';
import './Programs.scss';
import { ProgramsTranslations } from './Programs.translations';
import { CurrentUserContext } from '../../../../../contexts/current-user-context/CurrentUserContext';
import FileIcon from '../../../../../icons/component-icons/FileIcon';
import Skeleton from 'react-loading-skeleton';
import ProgramJoinModal from './program-join-modal/ProgramJoinModal';
import { useIntl } from 'react-intl';

const SUPPORT_KEYS = [
  PrimarySituationEnum.ProfessionalAndSecondarySchoolStudentSupport,
  PrimarySituationEnum.PostBacStudentSupport,
  PrimarySituationEnum.JobSeekerSupport,
];

const VOCATION_URL = import.meta.env.REACT_APP_SELF_SERVICE_FRONTEND_URL || 'http://localhost:4002';

export default function Programs() {
  const intl = useIntl();
  const { currentUser } = useContext(CurrentUserContext);
  const [openModal] = useModal();

  const { data, loading } = useQuery<GetProgramsQuery>(GET_PROGRAMS_QUERY, {
    fetchPolicy: 'network-only',
  });

  const openJoinProgramModal = () => {
    openModal({
      title: intl.formatMessage(ProgramsTranslations.joinProgramModaltitle),
      content: <ProgramJoinModal />,
    });
  };

  if (loading) return <ProgramsLoader />;

  if (SUPPORT_KEYS.includes(currentUser?.primarySituation?.key || PrimarySituationEnum.Other)) {
    if (currentUser?.secondarySituation?.key === SecondarySituationEnum.College) return null;
    return <VocationAccess />;
  }

  if (!data?.programs?.participations || data?.programs?.participations.length === 0) return <NoProgram />;

  const {
    programs: { participations },
  } = data;

  return (
    <DashboardCard
      icon={FileIcon}
      title={intl.formatMessage(ProgramsTranslations.title)}
    >
      <Program
        key={participations[0].id}
        participation={participations[0]}
      />

      <button
        className="dashboard-view-programs__button"
        onClick={openJoinProgramModal}
      >
        {intl.formatMessage(ProgramsTranslations.joinNewProgramButton)}
      </button>
    </DashboardCard>
  );
}

function NoProgram() {
  const intl = useIntl();
  const [openModal] = useModal();

  const openJoinProgramModal = () => {
    openModal({
      title: intl.formatMessage(ProgramsTranslations.joinProgramModaltitle),
      content: <ProgramJoinModal />,
    });
  };

  return (
    <DashboardCard
      icon={FileIcon}
      title={intl.formatMessage(ProgramsTranslations.title)}
    >
      <div className="dashboard-view-programs__subtitle">
        {intl.formatMessage(ProgramsTranslations.noProgramSubtitle)}
      </div>
      <div className="dashboard-view-programs__text">{intl.formatMessage(ProgramsTranslations.noProgramText)}</div>
      <button
        className="dashboard-view-programs__button"
        onClick={openJoinProgramModal}
      >
        {intl.formatMessage(ProgramsTranslations.joinProgramButton)}
      </button>
    </DashboardCard>
  );
}

function VocationAccess() {
  const intl = useIntl();
  return (
    <DashboardCard
      icon={FileIcon}
      title={intl.formatMessage(ProgramsTranslations.title)}
    >
      <div className="dashboard-view-programs__text">{intl.formatMessage(ProgramsTranslations.vocationAccessText)}</div>
      <a
        href={VOCATION_URL}
        className="dashboard-view-programs__button"
      >
        {intl.formatMessage(ProgramsTranslations.vocationAccessButton)}
      </a>
    </DashboardCard>
  );
}

export const ProgramsLoader = () => (
  <DashboardCardLoader>
    <Skeleton width="50%" />
    <Skeleton height={8} />
    <Skeleton width="80%" />
    <Skeleton count={2} />
    <Skeleton count={2} />
    <Skeleton count={4} />
  </DashboardCardLoader>
);
