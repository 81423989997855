import { defineMessages } from 'react-intl';

export default defineMessages({
  programTemplate: {
    id: 'AffiliationItem.programTemplate',
    defaultMessage: 'PROGRAMME {name}',
  },
  apptCount: {
    id: 'AffiliationItem.apptCount',
    defaultMessage: '{n} rendez-vous',
  },
  fromto: {
    id: 'AffiliationItem.fromto',
    defaultMessage: 'Du {start} au {end}',
  },
  organization: {
    id: 'AffiliationItem.organization',
    defaultMessage: 'Organisation',
  },
  managers: {
    id: 'AffiliationItem.managers',
    defaultMessage: 'Responsable(s) du programme',
  },
  description: {
    id: 'AffiliationItem.description',
    defaultMessage: 'Modalités du programme',
  },
  certification: {
    id: 'AffiliationItem.certification',
    defaultMessage: 'Certification',
  },
  certificationFAQ: {
    id: 'AffiliationItem.certificationFAQ',
    defaultMessage: 'En savoir plus >',
  },
});
