import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'RecruitingWorkingAreas.workingAreaNameTitle',
    defaultMessage: 'Liste des domaines employables',
  },
  schoolTitle: {
    id: 'RecruitingWorkingAreas.schoolTitle',
    defaultMessage: 'Liste des formations',
  },
  description: {
    id: 'RecruitingWorkingAreas.description',
    defaultMessage:
      'Ceci permettra de promouvoir des domaines d’emplois sur lesquelles vous recherchez des talents. Si un de vos collaborateurs rencontre un jeune talent, celui-ci pour manifester son intérêt pour un des domaines d’emplois renseignés.',
  },
  schoolDescription: {
    id: 'RecruitingWorkingAreas.schoolDescription',
    defaultMessage:
      'Ceci permettra de cibler les formations que les talents cherchent à intégrer. Si l’un de vos Ambassadeurs rencontre un jeune talent, celui-ci pourra manifester son intérêt pour l’une de ces formations.',
  },
});
