import { useState, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { CURRENT_USER_WISHED_AMBASSADOR_QUERY, UNWISH_AMBASSADOR, WISH_AMBASSADOR } from './useWish.gql';
import {
  CurrentUserWishedAmbassadorQuery,
  CurrentUserWishedAmbassadorQueryVariables,
  WishAmbassadorMutation,
  WishAmbassadorMutationVariables,
  UnwishAmbassadorMutation,
  UnwishAmbassadorMutationVariables,
} from '../../../../../../@types/graphql.d';

type useWishProps = {
  ambassadorId: string;
};

export function useWish({ ambassadorId }: useWishProps) {
  const [wished, setWished] = useState<boolean>(false);

  const { loading: currentUserWishedAmbassadorLoading } = useQuery<
    CurrentUserWishedAmbassadorQuery,
    CurrentUserWishedAmbassadorQueryVariables
  >(CURRENT_USER_WISHED_AMBASSADOR_QUERY, {
    variables: { ambassadorId },
    onCompleted: (data) => {
      setWished(data.getUser.currentUserWishedAmbassador);
    },
    onError: (errors) => {
      console.error(errors);
    },
    fetchPolicy: 'network-only',
  });

  const [unwish, { loading: unwishAmbassadorLoading }] = useMutation<
    UnwishAmbassadorMutation,
    UnwishAmbassadorMutationVariables
  >(UNWISH_AMBASSADOR, {
    variables: { ambassadorId },
    onCompleted: (data) => {
      if (data.unwishAmbassador) {
        setWished(false);
      }
    },
    onError: (errors) => {
      console.error(errors);
    },
  });

  const [wish, { loading: wishAmbassadorLoading }] = useMutation<
    WishAmbassadorMutation,
    WishAmbassadorMutationVariables
  >(WISH_AMBASSADOR, {
    variables: { ambassadorId },
    onCompleted: (data) => {
      if (data.wishAmbassador) {
        setWished(true);
      }
    },
    onError: (errors) => {
      console.error(errors);
    },
  });

  const toggleWish = useCallback(() => {
    wished ? unwish() : wish();
  }, [wished, wish, unwish]);

  return {
    wished,
    toggleWish,
    loading: wishAmbassadorLoading || unwishAmbassadorLoading || currentUserWishedAmbassadorLoading || false,
  };
}
