import React from 'react';

import Card from '../../../../../components/card/Card';
import './FurtherInformations.scss';
import { AmbassadorViewFurtherInformationsTranslations } from './FurtherInformations.translations';
import CheckSquareBrokenIcon from '../../../../../icons/component-icons/CheckSquareBrokenIcon';
import { useIntl } from 'react-intl';

export default function StaticFurtherInformations() {
  const intl = useIntl();
  return (
    <section
      className="ambassador-view-further-informations"
      style={{ flex: 1 }}
    >
      <Card
        icon={CheckSquareBrokenIcon}
        title={intl.formatMessage(AmbassadorViewFurtherInformationsTranslations.recommendedSkills)}
      >
        <div className="ambassador-view-further-informations__tags">
          <div className="ambassador-view-further-informations__tags__tag blurred">MandatorySkill</div>
          <div className="ambassador-view-further-informations__tags__tag blurred">MandatorySkill</div>
          <div className="ambassador-view-further-informations__tags__tag blurred">MandatorySkill</div>
        </div>
      </Card>
    </section>
  );
}
