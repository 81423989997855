import { defineMessages } from 'react-intl';

export const FilterButtonTranslations = defineMessages({
  search: {
    id: 'FilterButton.search',
    defaultMessage: 'Recherche par {facet}',
  },
  clear: {
    id: 'FilterButton.clear',
    defaultMessage: 'Effacer',
  },
  apply: {
    id: 'FilterButton.apply',
    defaultMessage: 'Appliquer',
  },
});
