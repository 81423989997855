import { gql } from '@apollo/client';

export const GET_CONVERSATIONS_LIST = gql`
  query GET_CONVERSATIONS_LIST($before: String, $after: String) {
    conversations(first: 8, before: $before, after: $after) {
      nodes {
        id
        currentUserAnswered
        currentUserAcknowledged
        currentUserIsInitiator
        interlocutor {
          id
          firstName
          avatars {
            url
          }
          primarySituation {
            key
            name
          }
          secondarySituation {
            key
            name
          }
          companyName
          previousCompanyName
          jobTitle
          previousPosition
          currentSchoolName
        }
        lastMessage {
          createdAt
          text
          seenAt
          sender {
            id
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        startCursor
        hasPreviousPage
      }
    }
  }
`;
