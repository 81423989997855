import classNames from 'classnames';
import ArrowDownIcon from '../../../../../../icons/component-icons/ArrowDownIcon';
import ArrowUpIcon from '../../../../../../icons/component-icons/ArrowUpIcon';
import SwitchVerticalIcon from '../../../../../../icons/component-icons/SwitchVerticalIcon';
import React, { useCallback } from 'react';
import { CompanyEmployeesQuerySortEnum } from '../../../../../../@types/graphql.d';
import { CompanyAttributeSet } from '../EmployeeList.gql';
import { EmployeeListTranslations } from '../EmployeeList.translations';
import { useIntl } from 'react-intl';

type SortButtonsProps = {
  customAttributesSet?: CompanyAttributeSet;
  sortedBy: CompanyEmployeesQuerySortEnum;
  onSortChange: (s: CompanyEmployeesQuerySortEnum) => void;
};

export default function SortButtons({ customAttributesSet, sortedBy, onSortChange }: SortButtonsProps) {
  const intl = useIntl();
  return (
    <thead>
      <tr>
        <th></th>
        <th></th>
        <th>
          <SortButton
            label={intl.formatMessage(EmployeeListTranslations.name)}
            sortingEnums={[CompanyEmployeesQuerySortEnum.FirstNameAsc, CompanyEmployeesQuerySortEnum.FirstNameDesc]}
            sortedBy={sortedBy}
            onSortChange={onSortChange}
          />
        </th>
        <th>
          <SortButton
            label={intl.formatMessage(EmployeeListTranslations.status)}
            sortingEnums={[CompanyEmployeesQuerySortEnum.StatusAsc, CompanyEmployeesQuerySortEnum.StatusDesc]}
            sortedBy={sortedBy}
            onSortChange={onSortChange}
          />
        </th>
        <th>
          <SortButton
            label={
              customAttributesSet?.customEmployeeAttribute1 ||
              intl.formatMessage(EmployeeListTranslations.defaultCompanyAttribute, {
                number: 1,
              })
            }
            sortingEnums={[]}
            sortedBy={sortedBy}
            onSortChange={onSortChange}
          />
        </th>
        <th>
          <SortButton
            label={
              customAttributesSet?.customEmployeeAttribute2 ||
              intl.formatMessage(EmployeeListTranslations.defaultCompanyAttribute, {
                number: 2,
              })
            }
            sortingEnums={[]}
            sortedBy={sortedBy}
            onSortChange={onSortChange}
          />
        </th>
        <th>
          <SortButton
            label={
              customAttributesSet?.customEmployeeAttribute3 ||
              intl.formatMessage(EmployeeListTranslations.defaultCompanyAttribute, {
                number: 3,
              })
            }
            sortingEnums={[]}
            sortedBy={sortedBy}
            onSortChange={onSortChange}
          />
        </th>
      </tr>
    </thead>
  );
}

type SortButtonProps = {
  label: string;
  sortingEnums: CompanyEmployeesQuerySortEnum[];
  sortedBy: CompanyEmployeesQuerySortEnum;
  onSortChange: (s: CompanyEmployeesQuerySortEnum) => void;
};

function SortButton({ label, sortingEnums, sortedBy, onSortChange }: SortButtonProps) {
  const onClick = useCallback(() => {
    if (sortingEnums.includes(sortedBy)) {
      if (sortedBy === sortingEnums[0]) {
        onSortChange(sortingEnums[1]);
      } else {
        onSortChange(sortingEnums[0]);
      }
    } else {
      onSortChange(sortingEnums[0]);
    }
  }, [sortingEnums, sortedBy]);

  return (
    <button
      className={classNames('employee-list__employees__sort-button', {
        'employee-list__employees__sort-button--sorting': sortingEnums.includes(sortedBy),
      })}
      onClick={onClick}
    >
      {label}
      {sortingEnums.length == 2 && (
        <>
          {!sortingEnums.includes(sortedBy) && <SwitchVerticalIcon />}
          {sortedBy === sortingEnums[0] && <ArrowUpIcon />}
          {sortedBy === sortingEnums[1] && <ArrowDownIcon />}
        </>
      )}
    </button>
  );
}
