import React from 'react';
import { Company, CompanySize } from '../../../../../../../@types/graphql.d';
import { companySizeTranslations } from '../../../../../../../enums/companySizes';
import translations from './CompanyGeneralInformationsRead.translations';

import styles from './CompanyGeneralInformationsRead.module.scss';
import { useIntl } from 'react-intl';

interface CompanyGeneralInformationsReadProps {
  company?: Pick<
    Company,
    'name' | 'companyLogo' | 'companySector' | 'companySize' | 'isPremiumSchool' | 'establishmentType'
  >;
}
function CompanyGeneralInformationsRead({ company }: CompanyGeneralInformationsReadProps) {
  const intl = useIntl();
  return (
    <div className={styles.companyGeneralInformationsRead}>
      <div className={styles.companyLogoWrapper}>
        <img
          src={company?.companyLogo || 'https://assets.myjobglasses.com/images/fallback-company.png'}
          className={styles.companyLogo}
          alt=""
        />
      </div>
      <div className={styles.infos}>
        <div className={styles.infoBLoc}>
          <h2 className={styles.infoTitle}>
            {company?.isPremiumSchool
              ? intl.formatMessage(translations.schoolName)
              : intl.formatMessage(translations.companyName)}
          </h2>
          <p className={styles.infoValue}>{company?.name}</p>
        </div>
        <>
          <div className={styles.infoBLoc}>
            <h2 className={styles.infoTitle}>
              {company?.isPremiumSchool
                ? intl.formatMessage(translations.etablishmentType)
                : intl.formatMessage(translations.companySector)}
            </h2>
            <p className={styles.infoValue}>
              {company?.isPremiumSchool ? company?.establishmentType?.name : company?.companySector}
            </p>
          </div>
          {!company?.isPremiumSchool && (
            <div className={styles.infoBLoc}>
              <h2 className={styles.infoTitle}>{intl.formatMessage(translations.companySize)}</h2>
              <p className={styles.infoValue}>
                {company?.companySize
                  ? intl.formatMessage(companySizeTranslations[company.companySize])
                  : intl.formatMessage(companySizeTranslations[CompanySize.Unknown])}
              </p>
            </div>
          )}
        </>
      </div>
    </div>
  );
}

export default CompanyGeneralInformationsRead;
