import { defineMessages } from 'react-intl';

export default defineMessages({
  professionalFeedback: {
    id: 'CompanyAdminAppointmentSidebar.appointment.professionalFeedback',
    defaultMessage: "Feedback de l'Ambassadeur",
  },
  appointmentPreparation: {
    id: 'CompanyAdminAppointmentSidebar.appointment.appointmentPreparation',
    defaultMessage: 'Préparation du RDV',
  },
  jobMatch: {
    id: 'CompanyAdminAppointmentSidebar.appointment.jobMatchProfessional',
    defaultMessage: 'Projection dans le métier',
  },
  studyMatch: {
    id: 'CompanyAdminAppointmentSidebar.appointment.studyMatchProfessional',
    defaultMessage: 'Projection dans le domaine d’études',
  },
  cooptation: {
    id: 'CompanyAdminAppointmentSidebar.appointment.cooptation',
    defaultMessage: "Cooptation / recommandation dans l'entreprise",
  },
  studyCooptation: {
    id: 'CompanyAdminAppointmentSidebar.appointment.studyCooptation',
    defaultMessage: "Cooptation / recommandation dans l'établissement",
  },
  feedbackComment: {
    id: 'CompanyAdminAppointmentSidebar.appointment.feedbackComment',
    defaultMessage: 'Commentaire',
  },
});
