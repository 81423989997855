import { defineMessages } from 'react-intl';

export default defineMessages({
  labelName: {
    id: 'CompanyAttributesRead.labelName',
    defaultMessage: 'Nom du libellé',
  },
  values: {
    id: 'CompanyAttributesRead.values',
    defaultMessage: 'Liste des valeurs possibles',
  },
});
