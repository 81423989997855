import * as Yup from 'yup';
import { IntlShape } from 'react-intl';
import { errorTranslations } from './SoftDeletionReasonForm.translations';

export const SoftDeletionReasonFormValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    reason: Yup.string().ensure().required(intl.formatMessage(errorTranslations.toastrYouNeedToChooseReason)),
    reason_text: Yup.mixed().when('reason', {
      is: 'other',
      then: (schema) => schema.required(intl.formatMessage(errorTranslations.toastrYouNeedToChooseReason)),
      otherwise: (schema) => schema,
    }),
  });
