import { defineMessages } from 'react-intl';

export default defineMessages({
  appointmentNotReviewed: {
    id: 'CompanyAdminAppointmentSidebar.appointment.appointmentNotReviewedByStudent',
    defaultMessage: "Le Membre n'a pas encore évalué le RDV",
  },
  appointmentDidNotHappen: {
    id: 'CompanyAdminAppointmentSidebar.appointment.appointmentDidNotHappen',
    defaultMessage: "Le Membre a indiqué que le RDV n'avait pas eu lieu",
  },
  studentFeedback: {
    id: 'CompanyAdminAppointmentSidebar.appointment.studentFeedback',
    defaultMessage: 'Feedback de le Membre',
  },
  appointmentUsefulness: {
    id: 'CompanyAdminAppointmentSidebar.appointment.appointmentUsefulness',
    defaultMessage: 'Utilité du RDV',
  },
  industryProjection: {
    id: 'CompanyAdminAppointmentSidebar.appointment.industryProjection',
    defaultMessage: "Projection dans le secteur d'activité",
  },
  studyProjection: {
    id: 'CompanyAdminAppointmentSidebar.appointment.studyProjection',
    defaultMessage: "Projection dans le même type d'établissement",
  },
  jobMatch: {
    id: 'CompanyAdminAppointmentSidebar.appointment.jobMatchStudent',
    defaultMessage: 'Projection dans le métier',
  },
  studyMatch: {
    id: 'CompanyAdminAppointmentSidebar.appointment.studyMatch',
    defaultMessage: 'Projection dans le domaine d’études',
  },
});
