import { gql } from '@apollo/client';

export const GET_INTERLOCUTOR_RECEIVED_REVIEW_AS_MEMBERS_QUERY = gql`
  query GET_INTERLOCUTOR_RECEIVED_REVIEW_AS_MEMBERS_QUERY($id: ID!) {
    conversation(conversationId: $id) {
      interlocutorV2 {
        receivedReviewAsMembers {
          reviewerTitle
          reviewerOrganizationName
          feedback
        }
      }
    }
  }
`;
