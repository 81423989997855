import { defineMessages } from 'react-intl';
import { CompanySize } from '../../../../@types/graphql.d';

export const CompanySizesFilterButtonTranslations = defineMessages<string>({
  [CompanySize.Under_50]: {
    id: 'CompanySizesFilterButton.under50',
    defaultMessage: 'Entre 1 et 50 employés',
  },
  [CompanySize.Under_1000]: {
    id: 'CompanySizesFilterButton.under1000',
    defaultMessage: 'Entre 51 et 1000 employés',
  },
  [CompanySize.Over_10000]: {
    id: 'CompanySizesFilterButton.over1000',
    defaultMessage: '+ de 1000 employés',
  },
});
