import React, { useContext, useEffect, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useMatch } from 'react-router';
import { scroller } from 'react-scroll';
import AccountEditLogin from '../../components/account/account-edit-login/AccountEditLogin';
import AccountEditPassword from '../../components/account/account-edit-password/AccountEditPassword';
import AccountHibernation from '../../components/account/account-hibernation/AccountHibernation';
import AccountSoftDeletion from '../../components/account/account-soft-deletion/AccountSoftDeletion';
import Routes from '../../routes';
import { useIntl } from 'react-intl';
import translations from './Account.translations';
import { CurrentUserContext } from '../../contexts/current-user-context/CurrentUserContext';
import {
  ChangeEmailMutation,
  ChangeEmailMutationVariables,
  CreateHibernationMutation,
  CreateHibernationMutationVariables,
  DestroyHibernationMutation,
  DestroyHibernationMutationVariables,
  PublicationStatuses,
  RequestNewPasswordMutation,
  RequestNewPasswordMutationVariables,
} from '../../@types/graphql.d';

import './Account.scss';
import { useMutation } from '@apollo/client';
import {
  CHANGE_EMAIL_MUTATION,
  CREATE_HIBERNATION_MUTATION,
  DESTROY_HIBERNATION_MUTATION,
  REQUEST_NEW_PASSWORD_MUTATION,
} from './AccountSettings.gql';
import { useSnackbar } from 'notistack';

const HEADER_MARGIN_OFFSET = -80;

export default function Account() {
  const intl = useIntl();
  const snackbar = useSnackbar();
  const { currentUser } = useContext(CurrentUserContext);

  const published = useMemo(
    () => currentUser?.publicationStatus === PublicationStatuses.Published,
    [currentUser?.publicationStatus],
  );

  const matchHibernation = useMatch({
    path: Routes.settingsHibernation,
  });

  useEffect(() => {
    if (matchHibernation) {
      scroller.scrollTo('account-hibernation', {
        smooth: true,
        offset: HEADER_MARGIN_OFFSET,
      });
    }
  }, [matchHibernation]);

  const [changeEmail] = useMutation<ChangeEmailMutation, ChangeEmailMutationVariables>(CHANGE_EMAIL_MUTATION, {
    onCompleted: () => {
      snackbar.enqueueSnackbar(intl.formatMessage(translations.checkEmail), {
        autoHideDuration: 2500,
        variant: 'success',
      });
    },
    onError: (errors) => {
      const graphqlError = errors.graphQLErrors[0];
      if (!graphqlError?.extensions?.code) return;

      switch (graphqlError.extensions.code) {
        case 'CURRENT_PASSWORD_DOES_NOT_MATCH':
          snackbar.enqueueSnackbar(intl.formatMessage(translations.CURRENT_PASSWORD_DOES_NOT_MATCH), {
            autoHideDuration: 2500,
            variant: 'error',
          });
      }
    },
  });

  const [requestNewPassword] = useMutation<RequestNewPasswordMutation, RequestNewPasswordMutationVariables>(
    REQUEST_NEW_PASSWORD_MUTATION,
    {
      onCompleted: () => {
        snackbar.enqueueSnackbar(intl.formatMessage(translations.accountInformationsUpdated), {
          autoHideDuration: 2500,
          variant: 'success',
        });
      },
    },
  );

  const [createHibernation] = useMutation<CreateHibernationMutation, CreateHibernationMutationVariables>(
    CREATE_HIBERNATION_MUTATION,
  );

  const [destroyHibernation] = useMutation<DestroyHibernationMutation, DestroyHibernationMutationVariables>(
    DESTROY_HIBERNATION_MUTATION,
  );

  async function handleUpdateEmail(values: { email: string; current_password: string }) {
    if (!currentUser?.id) return;
    await changeEmail({
      variables: {
        userId: currentUser.id,
        email: values.email,
        currentPassword: values.current_password,
      },
    });
  }

  async function handleUpdatePassword() {
    if (!currentUser?.email) return;
    await requestNewPassword({ variables: { email: currentUser.email } });
  }

  return (
    <div className="login account">
      <Container fluid>
        <Row>
          <Col>
            <h2 className="title">{intl.formatMessage(translations.title)}</h2>
          </Col>
        </Row>

        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <AccountEditLogin onUpdateValues={handleUpdateEmail} />
          </Col>
        </Row>

        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <AccountEditPassword onUpdateValues={handleUpdatePassword} />
          </Col>
        </Row>

        {/* TODO waiting for the backend */}
        {/* {!currentUser?.isCoach &&
          !(
            currentUser?.companyAdminProfiles &&
            currentUser.companyAdminProfiles.length > 0 &&
            !currentUser?.isAmbassador
          ) && (
            <Row>
              <Col
                md={8}
                mdOffset={2}
              >
                <AccountEditNotifications
                  user={currentUser}
                  onUpdateValues={handleUpdateNotifications}
                />
              </Col>
            </Row>
          )} */}

        {published && (
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <AccountHibernation
                createHibernation={createHibernation}
                destroyHibernation={destroyHibernation}
              />
            </Col>
          </Row>
        )}

        {!(
          currentUser?.companyAdminProfiles &&
          currentUser.companyAdminProfiles.length > 0 &&
          !currentUser?.isAmbassador
        ) && (
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <AccountSoftDeletion />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}
