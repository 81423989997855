import React, { useCallback } from 'react';
import { Header } from './components/header/Header';
import { generatePath, useNavigate, useParams } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { APPROVE_CLAIM, DENY_CLAIM, GET_CLAIM_TO_REVIEW } from './Review.gql';
import {
  AppointmentDidNotTookPlaceReasonEnum,
  ApproveClaimMutation,
  ApproveClaimMutationVariables,
  DenyAppointmentClaimMutation,
  DenyAppointmentClaimMutationVariables,
  GetClaimToReviewQuery,
  GetClaimToReviewQueryVariables,
} from '../../../@types/graphql.d';
import { Footer } from './components/footer/Footer';

import './Review.scss';
import Routes from '../../../routes';
import { useSnackbar } from 'notistack';
import { defineMessages, useIntl } from 'react-intl';
import { FormikErrors, useFormik } from 'formik';
import { boolean, mixed, object, string } from 'yup';
import { ClaimLandingPanel, DenyClaimReasonPanel } from './panels/ClaimPanel';

type ClaimFormikValues = {
  isDenied: boolean;
  reason?: AppointmentDidNotTookPlaceReasonEnum;
  description?: string;
};

export type ClaimFormikType = {
  initialValues: ClaimFormikValues,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<FormikErrors<ClaimFormikValues>> | Promise<void>;
  values: ClaimFormikValues;
  handleSubmit: () => void;
}

const errorTranslations = defineMessages({
  other: {
    id: 'Review.ClaimPanel.errorTranslations.UnexpectedValidationError',
    defaultMessage: 'Une erreur inattendue est survenue. Veuillez prendre contacte avec nos équipes support.',
  },
});

const validationSchema = object().shape({
  isDenied: boolean().required(),
  reason: mixed<AppointmentDidNotTookPlaceReasonEnum>().when('isDenied', {
    is: true,
    then: (schema) => schema.required().oneOf(Object.values(AppointmentDidNotTookPlaceReasonEnum)),
  }),
  description: string().when('reason', {
    is: 'OTHER',
    then: (schema) => schema.required(),
  }),
});

const Claim: React.FC = () => {
  const { claimId } = useParams<{ claimId?: string }>();
  const navigate = useNavigate();
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  const { data } = useQuery<GetClaimToReviewQuery, GetClaimToReviewQueryVariables>(GET_CLAIM_TO_REVIEW, {
    variables: {
      id: claimId!,
    },
    skip: !claimId,
  });

  const [approveClaim] = useMutation<ApproveClaimMutation, ApproveClaimMutationVariables>(APPROVE_CLAIM, {
    variables: {
      id: claimId!,
    },
    refetchQueries: ['GetFirstAppointmentClaimToForceReview'],
    awaitRefetchQueries: true,
    onCompleted: (d) => {
      navigate(generatePath(Routes.review, { appointmentId: d.approveAppointmentClaim.appointment?.id || '' }) + '?claimApproved=true');
    },
    onError: (err) => {
      if (err.graphQLErrors.length < 1) return;
      snackbar(formatMessage(errorTranslations.other), { variant: 'error' });
      navigate(Routes.home);
    },
  });

  const [denyClaim] = useMutation<DenyAppointmentClaimMutation, DenyAppointmentClaimMutationVariables>(DENY_CLAIM, {
    refetchQueries: ['GetFirstAppointmentClaimToForceReview'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      navigate(Routes.home);
    },
    onError: (err) => {
      if (err.graphQLErrors.length < 1) return;
      snackbar(formatMessage(errorTranslations.other), { variant: 'error' });
      navigate(Routes.home);
    },
  });

  const formik = useFormik<ClaimFormikValues>({
    initialValues: {
      isDenied: false,
    },
    validationSchema,
    onSubmit: ({ isDenied, reason, description }) => {
      if (!isDenied) {
        approveClaim();
      } else {
        denyClaim({
          variables: {
            id: claimId!,
            reason: reason!,
            description: description,
          },
        });
      }
    },
  });

  if (!data?.claim) return;

  return (
    <main className="review-view">
      <div className="review-view__container">
        <Header appointment={data.claim} upcomingReviewCount={0} isClaim />

        {formik.values.isDenied ? (
          <DenyClaimReasonPanel formik={formik} />
        ) : (
          <ClaimLandingPanel
            interlocutor={data.claim.interlocutor}
            claim={data.claim}
            formik={formik}
          />
        )}

        <Footer
          onBack={formik.resetForm}
          onNext={formik.handleSubmit}
          canGoNext={formik.isValid}
          progression={formik.values.isDenied ? 50 : 0}
          isVisible={formik.values.isDenied}
          isLastPanel={true}
        />
      </div>
    </main>
  );
};

export default Claim;
