import { defineMessages } from 'react-intl';

export default defineMessages({
  softDeletionReasonFormModalTitle: {
    id: 'SoftDeletionHibernationReminder.softDeletionReasonFormModalTitle',
    defaultMessage: 'Pour quelle raison voulez-vous supprimer votre compte ?',
  },
  description: {
    id: 'SoftDeletionHibernationReminder.description',
    defaultMessage:
      'Le saviez-vous ? Vous pouvez rendre votre profil temporairement indisponible avec la fonctionnalité d’hibernation.',
  },
  hibernationLink: {
    id: 'SoftDeletionHibernationReminder.hibernationLink',
    defaultMessage: 'Programmer une hibernation',
  },
  submitButton: {
    id: 'SoftDeletionHibernationReminder.submitButton',
    defaultMessage: 'Supprimer le compte',
  },
});
