import { createContext } from 'react';

export interface SendMessageContextInsertTemplateFunctionParams {
  id?: string;
  text: string;
}
export interface SendMessageContextValues {
  insertTemplate: (template: SendMessageContextInsertTemplateFunctionParams) => void;
  closeMessageTemplatesManager: () => void;
  messageTemplateCannotBeClosed: boolean;
  setMessageTemplateCannotBeClosed: (value: boolean) => void;
  requestCloseMessageTemplate: boolean;
  setRequestCloseMessageTemplate: (value: boolean) => void;
  forceCloseMessageTemplate: () => void;
  isRequestNewTemplate: boolean;
  setRequestNewTemplate: (value: boolean) => void;
}

// @ts-ignore initialization is done on Provider call
export default createContext<SendMessageContextValues>({});
