import React, { useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { matchPath, useMatch } from 'react-router';

import classnames from 'classnames';
import { C } from 'vitest/dist/chunks/reporters.6vxQttCV';

import styles from './Header.module.scss';

import useIsMobile from '../../../hooks/useIsMobile';
import Routes, { CompanyAdminRoutes, MJGAdminRoutes } from '../../../routes';
import LogoZone from './components/logo-zone/LogoZone';
import MainMenuMobileToggle from './components/main-menu-mobile-toggle/MainMenuMobileToggle';
import MainMenu from './components/main-menu/MainMenu';
import UsernavConnected from './components/usernav-connected/UsernavConnected';
import getConnectConfig from './config/connect/connect';
import getMarketplaceConfig from './config/marketplace/marketplace';
import MainMenuMobileContext from './context/MainMenuMobileContext';

type Props = {
  connected: boolean;
};

const Header: React.FC<Props> = ({ connected }) => {
  const intl = useIntl();
  const pathname = window.location.pathname;
  const isMobile = useIsMobile();

  // Use route match for company-specific routes
  const match = [...Object.values(CompanyAdminRoutes), ...Object.values(MJGAdminRoutes)]
    .map((path) => useMatch(path))
    .find((match) => match !== null);

  // Determine the header logo, variant, and configuration based on the current route
  const { headerLogo, headerVariant, config, companyId } = useMemo(() => {
    const isCompanyRoute = !!match;
    return {
      headerLogo: isCompanyRoute ? ('connect' as const) : ('defaultLogo' as const),
      headerVariant: isCompanyRoute ? styles.blueVariant : '',
      config: isCompanyRoute ? getConnectConfig(intl) : getMarketplaceConfig(intl),
      companyId: match?.params.id || match?.params.companyid,
    };
  }, [match]);

  // Manage the state of the mobile main menu
  const [isMainMenuMobileOpened, setMainMenuMobileOpened] = useState(false);
  const toggleMainMenuMobile = () => setMainMenuMobileOpened((prev) => !prev);

  // Don't display header on mobile for specific routes
  const hideOnMobile = useMemo(() => {
    return isMobile && matchPath(pathname, Routes.conversation);
  }, [isMobile, pathname]);

  if (hideOnMobile) {
    return null;
  }

  return (
    <MainMenuMobileContext.Provider value={{ isMainMenuMobileOpened, setMainMenuMobileOpened }}>
      <header
        id="header"
        className={classnames(styles.header, headerVariant)}
      >
        <MainMenuMobileToggle
          className={styles.mainMenuMobileToggle}
          onClick={toggleMainMenuMobile}
        />
        <LogoZone
          className={styles.logoZone}
          variant={headerLogo}
        />
        {connected && (
          <div className={styles.rowMenu}>
            <UsernavConnected config={config.connected} />
            <MainMenu
              config={config.main}
              companyId={companyId}
            />
          </div>
        )}
      </header>
    </MainMenuMobileContext.Provider>
  );
};

export default Header;
