import { defineMessages } from 'react-intl';

export const errorTranslation = defineMessages({
  invalidCode: {
    id: 'ProgramJoinModal.invalidCode',
    defaultMessage: 'Ce code de participation est invalide. Veuillez réessayer.',
  },
  cannotJoinIndividualProgram: {
    id: 'ProgramJoinModal.cannotJoinIndividualProgram',
    defaultMessage:
      'Vous ne pouvez pas rejoindre ce programme individuel. Veuillez contacter le responsable du programme.',
  },
});

export default defineMessages({
  description: {
    id: 'ProgramJoinModal.description',
    defaultMessage:
      'Pour rejoindre un programme, renseigne <span class="highlight">le code de participation</span> qui t’a été transmis par le(s) responsable(s) du programme.',
  },
  codeLabel: {
    id: 'ProgramJoinModal.codeLabel',
    defaultMessage: 'Code',
  },
  participeButton: {
    id: 'ProgramJoinModal.participeButton',
    defaultMessage: 'Participer',
  },
  notStartProgram: {
    id: 'ProgramJoinModal.notStartProgram',
    defaultMessage:
      'Vous avez bien rejoint le programme {programName} ! Votre activité n’est comptabilisée que pendant les dates du programme.',
  },
  success: {
    id: 'ProgramJoinModal.success',
    defaultMessage: 'Vous avez bien rejoint le programme {programName} !',
  },
});
