import { defineMessages } from 'react-intl';

export const AppointmentReviewDotsTranslations = defineMessages({
  na: {
    id: 'AppointmentReviewDotsTranslations.na',
    defaultMessage: 'NSP',
  },
  naTooltip: {
    id: 'AppointmentReviewDotsTranslations.naTooltip',
    defaultMessage: 'Le membre est trop jeune pour se prononcer',
  },
});
