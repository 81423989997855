import { gql } from '@apollo/client';

export const GET_FACETS_QUERY = gql`
  query getFacets($searchQuery: String, $searchMode: SearchTypeEnum!, $searchFacets: [SearchFacetInput!]) {
    searchFacets(searchQuery: $searchQuery, searchMode: $searchMode, searchFacets: $searchFacets) {
      key
      buckets {
        key
        label
        docCount
      }
    }
  }
`;
