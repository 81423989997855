import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { useQuery } from '@apollo/client';

import './ConversationList.scss';

import { GetDraftListQuery, GetDraftListQueryVariables } from '../../../@types/graphql.d';
import EditIcon from '../../../icons/component-icons/EditIcon';
import MessageChatCircleIcon from '../../../icons/component-icons/MessageChatCircleIcon';
import GraphqlCursorPagination from '../../common/graphql-cursor-pagination/GraphqlCursorPagination';
import PageLoader from '../../common/page-loader/PageLoader';
import Legend from '../components/Legend';
import translations from './ConversationList.translations';
import { GET_DRAFT_LIST } from './DraftList.gql';
import DraftListItem from './components/draft-list-item/DraftListItem';
import NoMessages from './components/no-messages/NoMessages';

function DraftList() {
  const intl = useIntl();

  const [{ after, before }, setCurrentCursor] = useState<{
    after?: string | null;
    before?: string | null;
  }>({
    after: undefined,
    before: undefined,
  });
  const { data, loading } = useQuery<GetDraftListQuery, GetDraftListQueryVariables>(GET_DRAFT_LIST, {
    variables: {
      before,
      after,
    },
  });

  if (loading) return <PageLoader />;

  return (
    <main className="conversations">
      <h2 className="conversations__title">
        <EditIcon className="conversations__title__icon" />
        {intl.formatMessage(translations.draftsTitle)}
      </h2>
      <div className="conversations__items">
        {(data?.conversationsDrafts?.nodes?.length || 0) > 0 ? (
          data?.conversationsDrafts?.nodes?.map((draft) => (
            <DraftListItem
              key={draft.id}
              draft={draft}
            />
          ))
        ) : (
          <NoMessages />
        )}
      </div>
      {(data?.conversationsDrafts?.nodes?.length || 0) > 0 && (
        <GraphqlCursorPagination
          pageInfo={data?.conversationsDrafts?.pageInfo}
          onNextCursor={setCurrentCursor}
          onPreviousCursor={setCurrentCursor}
        />
      )}
    </main>
  );
}

export default DraftList;
