import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'NoWish.title',
    defaultMessage: 'Favoris',
  },
  viewProfileButton: {
    id: 'NoWish.viewProfileButton',
    defaultMessage: 'Voir les profils',
  },
});
