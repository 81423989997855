import React from 'react';

export type VideoIconProps = {
  className?: string;
};

function VideoIcon({ className }: VideoIconProps) {
  return (
    <svg
      viewBox="0 0 16 16"
      className={className}
    >
      <path d="M15.782,5.305C15.603,5.115,15.331,5.004,15.038,5c-0.135-0.003-0.226,0.013-0.306,0.035L11,5.875V5.277C11,4.573,10.368,4,9.591,4H1.409C0.632,4,0,4.573,0,5.277v5.445C0,11.427,0.632,12,1.409,12h8.182C10.368,12,11,11.427,11,10.723v-0.598l3.71,0.834c0.47,0.125,0.979-0.054,1.187-0.415C15.964,10.43,16,10.3,16,10.169V5.855C16.006,5.653,15.929,5.458,15.782,5.305z M10,10.723C10,10.854,9.832,11,9.591,11H1.409C1.168,11,1,10.854,1,10.723V5.277C1,5.146,1.168,5,1.409,5h8.182C9.832,5,10,5.146,10,5.277V10.723z M14.951,9.988L11,9.1V6.9l3.951-0.889L15,9.998C14.985,9.996,14.969,9.993,14.951,9.988z" />
    </svg>
  );
}

export default VideoIcon;
