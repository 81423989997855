import React from 'react';
import classnames from 'classnames';
import Help from '../../../../../../common/help/Help';

import styles from './CompanyAdminDashboardCountGrey.module.scss';

interface CompanyAdminDashboardCountGreyProps {
  title: string;
  tooltip?: string;
  value: number | '-';
  className?: string;
  blurred?: boolean;
}
function CompanyAdminDashboardCountGrey({
  title,
  tooltip,
  value,
  className,
  blurred
}: CompanyAdminDashboardCountGreyProps): JSX.Element {
  return (
    <div className={classnames(className, styles.companyAdminDashboardCountGrey)}>
      <div className={styles.titleWithTooltip}>
        <span className={styles.title}>{title}</span>
        {tooltip && (
          <Help
            text={tooltip}
            variant="blue-dark"
          />
        )}
      </div>
      <p className={classnames(styles.value, { blurred })}>{value}</p>
    </div>
  );
}

export default CompanyAdminDashboardCountGrey;
