import { useQuery } from '@apollo/client';
import { GET_CONVERSATION_APPOINTMENTS } from './useConversationAppointments.gql';
import {
  Get_Conversation_AppointmentsQuery,
  Get_Conversation_AppointmentsQueryVariables,
  AppointmentsSortFieldsEnum,
} from '../../../../../../@types/graphql.d';

export type Appointment = NonNullable<Get_Conversation_AppointmentsQuery['appointments']>['nodes'][0];
export function useConversationAppointments({ conversationId }: { conversationId: string }) {
  const { data, loading, error } = useQuery<
    Get_Conversation_AppointmentsQuery,
    Get_Conversation_AppointmentsQueryVariables
  >(GET_CONVERSATION_APPOINTMENTS, {
    variables: {
      filters: { conversationIdEq: conversationId, cancelled: false },
      sort: [AppointmentsSortFieldsEnum.StartAtDesc],
    },
    fetchPolicy: 'network-only',
  });

  return { appointments: data?.appointments?.nodes, loading, error };
}
