import React from 'react';
import { ComponentType } from 'react';
import './SecondaryButton.scss';
import classNames from 'classnames';
import { IconProps } from '../../icons/component-icons/Icon';

type SecondaryButtonProps = {
  label?: string;
  leftIcon?: ComponentType<IconProps>;
  rightIcon?: ComponentType<IconProps>;
  onClick: () => void;
  iconButtonWithText?: boolean;
  danger?: boolean;
  smaller?: boolean;
  disabled?: boolean;
  smallerDisabled?: boolean;
};

export default function SecondaryButton({
  label,
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  onClick,
  iconButtonWithText,
  danger,
  smaller,
  disabled,
  smallerDisabled,
}: SecondaryButtonProps) {
  return (
    <button
      className={classNames(
        'secondary-button',
        { 'secondary-button--only-icon': !label },
        { 'secondary-button--icon-button-with-text': iconButtonWithText },
        { 'secondary-button--danger': danger },
        { 'secondary-button--disabled': disabled },
        { 'secondary-button--smaller': smaller },
        { 'secondary-button--smaller--disabled': smallerDisabled },
      )}
      type="button"
      onClick={onClick}
    >
      {LeftIcon && <LeftIcon className="secondary-button__icon" />}
      {label}
      {RightIcon && <RightIcon className="secondary-button__icon" />}
    </button>
  );
}
