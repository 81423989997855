import React from 'react';
import { IconProps } from './Icon';

export default function HomeIcon({ className }: IconProps) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 19.2502V12.4668C8.25 11.9535 8.25 11.6968 8.34991 11.5007C8.43779 11.3282 8.57803 11.188 8.75051 11.1001C8.94659 11.0002 9.20329 11.0002 9.71667 11.0002H12.2833C12.7967 11.0002 13.0534 11.0002 13.2495 11.1001C13.422 11.188 13.5622 11.3282 13.6501 11.5007C13.75 11.6968 13.75 11.9535 13.75 12.4668V19.2502M10.0996 2.53385L3.88244 7.36938C3.46685 7.69262 3.25906 7.85423 3.10936 8.05663C2.97675 8.23592 2.87797 8.4379 2.81786 8.65265C2.75 8.89508 2.75 9.15832 2.75 9.68481V16.3168C2.75 17.3436 2.75 17.857 2.94982 18.2492C3.12559 18.5941 3.40605 18.8746 3.75102 19.0503C4.14319 19.2502 4.65657 19.2502 5.68333 19.2502H16.3167C17.3434 19.2502 17.8568 19.2502 18.249 19.0503C18.5939 18.8746 18.8744 18.5941 19.0502 18.2492C19.25 17.857 19.25 17.3436 19.25 16.3168V9.68481C19.25 9.15832 19.25 8.89508 19.1821 8.65265C19.122 8.4379 19.0232 8.23592 18.8906 8.05663C18.7409 7.85423 18.5331 7.69262 18.1176 7.36938L11.9004 2.53385C11.5784 2.28337 11.4174 2.15813 11.2396 2.10998C11.0827 2.06751 10.9173 2.06751 10.7604 2.10998C10.5826 2.15813 10.4216 2.28337 10.0996 2.53385Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
