import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'CompanyAdminAppointmentSidebar.education.title',
    defaultMessage: 'Formation',
  },
  fromTo: {
    id: 'CompanyAdminAppointmentSidebar.education.fromTo',
    defaultMessage: '{from} - {to}',
  },
  school: {
    id: 'CompanyAdminAppointmentSidebar.education.school',
    defaultMessage: 'à <span class="bold">{school}</span>',
  },
  endYearOnly: {
    id: 'CompanyAdminAppointmentSidebar.education.endYearOnly',
    defaultMessage: 'Termine ses études en {year}',
  },
});
