import React, { useEffect, useState } from 'react';
import { Company, PublicProfessionalProfile } from '../../../@types/graphql.d';
import defaultPlaceholderUrl from '../../../icons/company-logo-placeholder.svg';

interface CompanyLogoDisplayProps {
  className?: string;
  // professional could be undefined to display placeholder
  ressource?: Pick<PublicProfessionalProfile, 'companyLogo'> | Pick<Company, 'companyLogo'> | null;
}
function CompanyLogoDisplay({ className, ressource }: CompanyLogoDisplayProps) {
  const [url, setUrl] = useState<string>(ressource?.companyLogo);
  function handleError() {
    setUrl(defaultPlaceholderUrl);
  }
  useEffect(() => {
    if (ressource?.companyLogo) {
      setUrl(ressource.companyLogo);
    } else {
      setUrl(defaultPlaceholderUrl);
    }
  }, [ressource?.companyLogo]);

  return (
    <div className={className}>
      <img
        src={url}
        alt=""
        onError={handleError}
      />
    </div>
  );
}

export default CompanyLogoDisplay;
