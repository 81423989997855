import { gql } from '@apollo/client';

export const GET_EMPLOYEE_USER_ID = gql`
  query GET_EMPLOYEE_USER_ID($id: ID!) {
    companyEmployee(employeeId: $id) {
      ambivalent
      userId
    }
  }
`;
