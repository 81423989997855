interface MapStringsToOptionsParams {
  order?: 'alphabetic';
}

export function mapStringsToOptions(
  data?: Array<string | null>,
  options?: MapStringsToOptionsParams,
): Array<{ value: string; translation: string }> {
  if (!Array.isArray(data)) {
    return [];
  }
  let processedData = data
    .filter((value): value is string => typeof value === 'string')
    .map((value) => ({
      value,
      translation: value,
    }));

  if (options?.order === 'alphabetic') {
    processedData = processedData.sort(({ translation: labelA }, { translation: labelB }) =>
      labelA.toLowerCase() < labelB.toLowerCase() ? -1 : 1,
    );
  }

  return processedData;
}
