import React, { CSSProperties, memo, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { PanelProps } from './Panel';
import ButtonsGroup from '../../../../components/buttons-group/ButtonsGroup';
import PrimaryButton from '../../../../components/primary-button/PrimaryButton';
import { InterlocutorDetails } from './components/interlocutor-details/InterlocutorDetails';
import { AppointmentDate } from './components/appointment-date/AppointmentDate';
import { Layout } from './components/layout/Layout';
import useIsMobile from '../../../../hooks/useIsMobile';

const translations = defineMessages({
  title: {
    id: 'Review.LandingPanel.title',
    defaultMessage: 'Évaluez votre rendez-vous avec {firstName}',
  },
  didShowUp: {
    id: 'Review.LandingPanel.didShowUp',
    defaultMessage: 'Le rendez-vous a eu lieu',
  },
  didNotShowUp: {
    id: 'Review.LandingPanel.didNotShowUp',
    defaultMessage: "Le rendez-vous n'a pas eu lieu",
  },
});

export const LandingPanel = memo(({ interlocutor, appointment, onSubmit }: PanelProps) => {
  const { formatMessage } = useIntl();
  const isMobile = useIsMobile();

  return (
    <Layout title={formatMessage(translations.title, { firstName: interlocutor.firstName })} alignment='center' style={{ paddingTop: 100 }}>
      <InterlocutorDetails interlocutor={interlocutor} />
      <AppointmentDate date={appointment.from} />

      <ButtonsGroup style={{ marginTop: isMobile ? 32 : 64, columnGap: 23, width: isMobile ? undefined : 512 }} column={isMobile}>
        <PrimaryButton label={formatMessage(translations.didShowUp)} onClick={() => onSubmit('didShowUp')}/>
        <PrimaryButton label={formatMessage(translations.didNotShowUp)} outlined onClick={() => onSubmit('didNotShowUp')} />
      </ButtonsGroup>
    </Layout>
  );
});
