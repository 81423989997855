import React from 'react';
import { useIntl } from 'react-intl';
import commonErrorTranslations from '../../../../../../../scenes/common/common-translations/errors.translations';

import styles from '../../SoftDeletionNotAllowed.module.scss';

function SoftDeletionNotAllowedUnknownReason(): JSX.Element {
  const intl = useIntl();
  return <p className={styles.description}>{intl.formatMessage(commonErrorTranslations.unknown)}</p>;
}

export default SoftDeletionNotAllowedUnknownReason;
