import React from 'react';
import LockIcon from '../../../../../../../icons/component-icons/LockIcon';
import classnames from 'classnames';
import AccessibleTooltip from '../../../../../../common/accessible-tooltip/AccessibleTooltip';
import translations from './InterlocutorPhoneDisplay.translations';

import styles from './InterlocutorPhoneDisplay.module.scss';
import { Conversation } from '../../../../../../../@types/graphql.d';
import { useIntl } from 'react-intl';

type NonNullableConversation = NonNullable<Conversation>;
type NonNullableInterlocutor = NonNullable<NonNullableConversation['interlocutorV2']>;
interface InterlocutorPhoneDisplayProps {
  phone?: NonNullableInterlocutor['normalizedPhone'];
}

function InterlocutorPhoneDisplay({ phone }: InterlocutorPhoneDisplayProps) {
  const intl = useIntl();
  if (!phone) {
    return (
      <div className={classnames(styles.interlocutorPhoneDisplay, styles.noPhone)}>
        <AccessibleTooltip
          name="no-phone-tooltip"
          tooltip={intl.formatMessage(translations.tooltip)}
        >
          <LockIcon />
          {intl.formatMessage(translations.placeholder)}
        </AccessibleTooltip>
      </div>
    );
  }
  return <p className={styles.interlocutorPhoneDisplay}>+{phone}</p>;
}

export default InterlocutorPhoneDisplay;
