import React, { useEffect, useState } from 'react';

import { FallbackAvatar } from '@myjobglasses/dsm';
import classnames from 'classnames';

import styles from './PublicProfileAvatarDisplay.module.scss';

import {
  CompanyEmployee,
  CompanyReportTopEmployee,
  EmployeeStatus,
  PublicStudentProfile,
  PublicProfessionalProfile,
  PublicProfessionalProfileStatus,
  StudentStatus,
  PrivateProfile,
  Conversation,
  AppointmentInterlocutor,
} from '../../../@types/graphql.d';

interface PublicProfileAvatarDisplayProps {
  // professional could be undefined to display placeholder
  publicProfile?:
    | Pick<PublicStudentProfile, 'avatar' | 'status'>
    | (Pick<PrivateProfile, 'avatar'> & { status?: undefined })
    | Pick<PublicProfessionalProfile, 'avatar' | 'status'>
    | (Pick<CompanyReportTopEmployee, 'avatar'> & { status?: undefined })
    | Pick<CompanyEmployee, 'avatar' | 'status'>
    | null;
  interlocutor?: NonNullable<Conversation>['interlocutorV2'];
  className?: string;
  appointmentInterlocutor?: AppointmentInterlocutor;
  placeholder?: boolean;
}
function PublicProfileAvatarDisplay({
  publicProfile,
  className,
  interlocutor,
  appointmentInterlocutor,
  placeholder,
}: PublicProfileAvatarDisplayProps) {
  const [showFallback, setShowFallback] = useState<boolean>(true);
  const avatarUrl = appointmentInterlocutor?.avatar.url || publicProfile?.avatar || interlocutor?.avatar.url;

  useEffect(() => {
    if (
      (publicProfile &&
        publicProfile?.status &&
        [
          PublicProfessionalProfileStatus.SoftDeleted,
          PublicProfessionalProfileStatus.Anonymized,
          EmployeeStatus.Anonymized,
          StudentStatus.SoftDeleted,
          StudentStatus.Anonymized,
        ].includes(publicProfile.status)) ||
      placeholder
    ) {
      setShowFallback(true);
    } else {
      setShowFallback(!avatarUrl);
    }
  }, [avatarUrl]);
  function handleError() {
    setShowFallback(true);
  }
  if (showFallback) {
    return (
      <span className={classnames(className, styles.avatar, styles.fallback)}>
        <FallbackAvatar />
      </span>
    );
  }
  return (
    <span className={classnames(className, styles.avatar)}>
      <img
        src={avatarUrl}
        alt="avatar"
        onError={handleError}
      />
    </span>
  );
}

export default PublicProfileAvatarDisplay;
