import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useMatch, useParams } from 'react-router';
import { CompanyAdminRoutes, MJGAdminRoutes } from '../../../routes';
import {
  Get_Company_For_ConfigurationQuery,
  Get_Company_For_ConfigurationQueryVariables,
} from '../../../@types/graphql.d';
import { CompanyViewParams } from '../../../@types/routes-params.d';
import { PageLoader } from '../../../components/loader/Loader';
import NotFound from '../../../containers/not-found/NotFound';
import NoNetwork from '../../common/no-network/NoNetwork';
import CompanyAttributeHub from './components/company-attributes/CompanyAttributeHub';
import CompanyGeneralInformationsHub from './components/company-general-informations/CompanyGeneralInformationsHub';
import CompanyPresentationHub from './components/company-presentation/CompanyPresentationHub';
import RecruitingWorkingAreas from './components/recruiting-working-areas/RecruitingWorkingAreas';
import { GET_COMPANY_FOR_CONFIGURATION } from './CompanyAdminConfigurationView.gql';

import styles from './CompanyAdminConfigurationView.module.scss';
import { CurrentUserContext } from '../../../contexts/current-user-context/CurrentUserContext';

function CompanyAdminConfigurationView() {
  const { currentUser } = useContext(CurrentUserContext);
  const editedByAdmin = currentUser?.isMjgAdmin;
  const { companyid } = useParams();

  const { data, error, loading, refetch } = useQuery<
    Get_Company_For_ConfigurationQuery,
    Get_Company_For_ConfigurationQueryVariables
  >(GET_COMPANY_FOR_CONFIGURATION, {
    skip: !companyid,
    variables: {
      companyId: companyid || '',
    },
  });
  if (!companyid) {
    return <NotFound />;
  }
  if (error) {
    return <NoNetwork refetch={refetch} />;
  }
  if (loading) {
    return <PageLoader />;
  }
  return (
    <main className={styles.companyAdminConfigurationView}>
      <div className={styles.container}>
        <CompanyGeneralInformationsHub className={editedByAdmin ? styles.smallTopLeft : styles.bigTopLeft} />
        {editedByAdmin && <RecruitingWorkingAreas className={styles.recruitingWorkingAreasHub} />}
        <CompanyPresentationHub className={styles.companyPresentationHub} />
        <CompanyAttributeHub
          number={1}
          className={styles.companyAttributeHub1}
        />
        <CompanyAttributeHub
          number={2}
          className={styles.companyAttributeHub2}
          disabled={!data?.company?.customAttributesSet.customEmployeeAttribute1}
        />
        <CompanyAttributeHub
          number={3}
          className={styles.companyAttributeHub3}
          disabled={!data?.company?.customAttributesSet.customEmployeeAttribute2}
        />
      </div>
    </main>
  );
}

export const CompanyAdminConfigurationViewForMjgAdmin = CompanyAdminConfigurationView;
export default CompanyAdminConfigurationView;
