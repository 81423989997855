import React from 'react';
import { useQuery } from '@apollo/client';
import uniqid from 'uniqid';
import {
  Get_Company_Report_Top_EmployeesQuery,
  Get_Company_Report_Top_EmployeesQueryVariables,
} from '../../../../../@types/graphql.d';
import useCompanyUnique from '../../../hooks/useCompanyUnique/useCompanyUnique';
import { useCurrentRange } from '../../../hooks/useCurrentRange';
import { GET_COMPANY_REPORT_TOP_EMPLOYEES } from './CompanyAdminDashboardTopEmployees.gql';
import TopEmployeeItem from './components/top-employee-item/TopEmployeeItem';
import LockIcon from '../../../../../icons/component-icons/LockIcon';

import styles from './CompanyAdminDashboardTopEmployees.module.scss';
import CompanyAdminDashboardViewTranslations from '../../CompanyAdminDashboardView.translations';
import { useIntl } from 'react-intl';

function CompanyAdminDashboardTopEmployees() {
  const intl = useIntl();
  const companyId = useCompanyUnique();
  const currentRange = useCurrentRange();
  const { data } = useQuery<Get_Company_Report_Top_EmployeesQuery, Get_Company_Report_Top_EmployeesQueryVariables>(
    GET_COMPANY_REPORT_TOP_EMPLOYEES,
    {
      skip: !companyId,
      variables: {
        companyId: companyId || '',
        range: currentRange,
      },
      fetchPolicy: 'network-only',
    },
  );

  if (!data?.companyReport?.company.canShowDashboardEmployeesRanking) {
    return (
      <>
        <ul className={styles.topEmployees}>
          {Array.from({ length: 3 }).map((_, index) => (
            <li key={uniqid()}>
              <TopEmployeeItem
                skeleton={!data?.companyReport}
                placeholder={!!data?.companyReport}
                index={index}
                blurred
              />
            </li>
          ))}
        </ul>
        <div className={styles.lockedData}>
          <LockIcon />
          <p>{intl.formatMessage(CompanyAdminDashboardViewTranslations.lockedData)}</p>
        </div>
      </>
    );
  }

  if (!data?.companyReport?.bestProfiles.length) {
    return (
      <ul className={styles.topEmployees}>
        {Array.from({ length: 3 }).map((_, index) => (
          <li key={uniqid()}>
            <TopEmployeeItem
              skeleton={!data?.companyReport}
              placeholder={!!data?.companyReport}
              index={index}
            />
          </li>
        ))}
      </ul>
    );
  }

  return (
    <ul className={styles.topEmployees}>
      {data?.companyReport?.bestProfiles.map((profile) => (
        <li key={profile.id}>
          <TopEmployeeItem profile={profile} />
        </li>
      ))}
    </ul>
  );
}

export default CompanyAdminDashboardTopEmployees;
