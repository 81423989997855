import { defineMessages } from 'react-intl';

export default defineMessages({
  success: {
    id: 'employeeShow.useApproveAction.success',
    defaultMessage: '1 profil approuvé',
    description: 'success message on approve',
  },
  oneProfileCannotApproved: {
    id: 'employeeShow.useApproveAction.oneProfileCannotApproved',
    defaultMessage: "Désolé, le profil n'a pas pu être approuvé",
    description: '1 profil n a pas pu être approuvé',
  },
});
