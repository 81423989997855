import React from 'react';
import classNames from 'classnames';
import { CSSProperties, PropsWithChildren } from 'react';
import './ButtonsGroup.scss';

type ButtonsGroupsProps = {
  style?: CSSProperties;
  className?: string;
  column?: boolean;
};

export default function ButtonsGroup({ children, className, style, column }: PropsWithChildren<ButtonsGroupsProps>) {
  return (
    <div
      className={classNames('buttons-group', {'buttons-group--column': column }, className)}
      style={style}
    >
      {children}
    </div>
  );
}
