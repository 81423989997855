import React from 'react';
import { useNavigate } from 'react-router';
import buttonCommonTranslations from '../../../../../scenes/common/common-translations/button.translations';
import Routes from '../../../../../routes';
import { scrollToTopOfPage } from '../../../../../utils/scroll-to-top';
import useModal from '../../../../../components/modal/Modal.hook';
import ButtonsGroup from '../../../../../components/buttons-group/ButtonsGroup';
import PrimaryButton from '../../../../../components/primary-button/PrimaryButton';
import SecondaryButton from '../../../../../components/secondary-button/SecondaryButton';
import translations from './SoftDeletionAppointmentReminder.translations';

import styles from './SoftDeletionAppointmentReminder.module.scss';
import { useIntl } from 'react-intl';

function SoftDeletionAppointmentReminder(): JSX.Element {
  const intl = useIntl();
  const navigate = useNavigate();
  const [, closeModal] = useModal();
  function goToAppointmentsList() {
    scrollToTopOfPage();
    navigate(Routes.appointments);
  }
  return (
    <div>
      <div className={styles.modalBody}>
        <p className={styles.mention}>{intl.formatMessage(translations.mention)}</p>
        <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
      </div>
      <ButtonsGroup>
        <SecondaryButton
          label={intl.formatMessage(buttonCommonTranslations.cancel)}
          onClick={closeModal}
        />
        <PrimaryButton
          label={intl.formatMessage(translations.submitButton)}
          onClick={goToAppointmentsList}
        />
      </ButtonsGroup>
    </div>
  );
}

export default SoftDeletionAppointmentReminder;
