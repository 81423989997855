import { defineMessages } from 'react-intl';
import { CompanySize } from '../@types/graphql.d';

export const companySizeTranslations = defineMessages({
  [CompanySize.OnlyOne]: {
    id: 'enums.companySizes.OnlyOne',
    defaultMessage: '👩‍💼',
  },
  [CompanySize.Under_10]: {
    id: 'enums.companySizes.Under_10',
    defaultMessage: '1 - 10 employés',
  },
  [CompanySize.Under_50]: {
    id: 'enums.companySizes.Under_50',
    defaultMessage: '11 - 50 employés',
  },
  [CompanySize.Under_200]: {
    id: 'enums.companySizes.Under_200',
    defaultMessage: '51 - 200 employés',
  },
  [CompanySize.Under_500]: {
    id: 'enums.companySizes.Under_500',
    defaultMessage: '201 - 500 employés',
  },
  [CompanySize.Under_1000]: {
    id: 'enums.companySizes.Under_1000',
    defaultMessage: '501 - 1 000 employés',
  },
  [CompanySize.Under_5000]: {
    id: 'enums.companySizes.Under_5000',
    defaultMessage: '1 001 - 5 000 employés',
  },
  [CompanySize.Under_10000]: {
    id: 'enums.companySizes.Under_10000',
    defaultMessage: '5 001 - 10 000 employés',
  },
  [CompanySize.Over_10000]: {
    id: 'enums.companySizes.Over_10000',
    defaultMessage: '+ de 10 000 employés',
  },
  [CompanySize.Unknown]: {
    id: 'enums.companySizes.Unknown',
    defaultMessage: 'Inconnu',
  },
});
