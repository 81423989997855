import React from 'react';
import classnames from 'classnames';
import { generatePath, Link } from 'react-router';
import {
  ProfessionalProfileType,
  PublicProfessionalProfile,
  PublicProfessionalProfileStatus,
  OrganizationCardBanner,
} from '../../../@types/graphql.d';
import { ProfessionalCardReferrer } from '../../../@types/routes-params.d';
import Routes from '../../../routes';
import CompanyLogoDisplay from '../company-logo-display/CompanyLogoDisplay';
import ProfessionalAvailabilityDisplay from '../professional-availability-display/ProfessionalAvailabilityDisplay';
import PublicProfileAvatarDisplay from '../public-profile-avatar-display/PublicProfileAvatarDisplay';
import ProfessionalCardSkeleton from './components/professional-card-skeleton/ProfessionalCardSkeleton';
import ProfessionalCardSoftDeleted from './components/professional-card-soft-deleted/ProfessionalCardSoftDeleted';

import styles from './ProfessionalCard.module.scss';

interface ProfessionalCardProps {
  professional?: Pick<
    PublicProfessionalProfile,
    | 'id'
    | 'firstName'
    | 'lastName'
    | 'avatar'
    | 'companyName'
    | 'companyLogo'
    | 'status'
    | 'profileType'
    | 'jobTitle'
    | 'likeCount'
    | 'userId'
  > & {
    background: PublicProfessionalProfile['avatar'];
  } & { banner?: { __typename?: 'OrganizationCardBanner' } & Pick<OrganizationCardBanner, 'url'> };
  navigationReferrer?: ProfessionalCardReferrer;
}
function ProfessionalCard({ professional, navigationReferrer }: ProfessionalCardProps) {
  if (!professional) {
    return <ProfessionalCardSkeleton />;
  }
  if (
    [PublicProfessionalProfileStatus.SoftDeleted, PublicProfessionalProfileStatus.Anonymized].includes(
      professional.status,
    )
  ) {
    return (
      <ProfessionalCardSoftDeleted
        className={styles.professionalCard}
        userId={professional.userId}
      />
    );
  }
  return (
    <Link
      to={{
        pathname: generatePath(Routes.professional, {
          id: professional.id,
        }),
      }}
      state={navigationReferrer}
      className={styles.professionalCardLink}
    >
      <div className={styles.professionalCard}>
        <header
          className={classnames(styles.header, {
            [styles.mentorBackground]: professional.profileType === ProfessionalProfileType.Mentor,
            [styles.proBackground]: professional.profileType === ProfessionalProfileType.Employee,
            [styles.customProBackground]: professional.banner?.url,
          })}
        >
          {professional.banner?.url && (
            <img
              className={styles.customBanner}
              src={professional.banner?.url}
            />
          )}
          <div
            className={classnames(styles.headerContent, {
              [styles.mentor]: professional.profileType === ProfessionalProfileType.Mentor,
              [styles.employee]: professional.profileType === ProfessionalProfileType.Employee,
            })}
          >
            <PublicProfileAvatarDisplay
              publicProfile={professional}
              className={styles.avatar}
            />
            <CompanyLogoDisplay
              ressource={professional}
              className={styles.companyLogo}
            />
          </div>
        </header>
        <div className={styles.content}>
          <div className={styles.firstName}>{`${professional.firstName} ${professional.lastName}`}</div>
          <div className={styles.companyName}>{professional.companyName}</div>
          <p className={styles.jobTitle}>{`${professional.jobTitle}`}</p>
        </div>

        <div className={styles.footer}>
          <ProfessionalAvailabilityDisplay
            isMentor={professional.profileType === ProfessionalProfileType.Mentor}
            professional={professional}
          />
          {professional.likeCount &&
          professional.likeCount > 0 &&
          professional.profileType === ProfessionalProfileType.Employee ? (
            <div className={styles.proLike}>
              <span className="professionalLikeCount">{professional.likeCount}</span>
              <span className={styles.proLikeIcon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  viewBox="0 0 24 24"
                  x="0px"
                  y="0px"
                  className="mjg-svgs-defs"
                >
                  <path
                    fill="#d3a836"
                    d="M22.773,7.721A4.994,4.994,0,0,0,19,6H15.011l.336-2.041A3.037,3.037,0,0,0,9.626,2.122L8,5.417V21H18.3a5.024,5.024,0,0,0,4.951-4.3l.705-5A4.994,4.994,0,0,0,22.773,7.721Z"
                  >
                    .
                  </path>
                  <path
                    fill="#d3a836"
                    d="M0,11v5a5.006,5.006,0,0,0,5,5H6V6H5A5.006,5.006,0,0,0,0,11Z"
                  >
                    .
                  </path>
                </svg>
              </span>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </Link>
  );
}

export default ProfessionalCard;
