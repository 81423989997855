import React, { useContext, useState } from 'react';
import { ValuesType } from 'utility-types';
import classNames from 'classnames';

import './AppointmentListItem.scss';
import BuildingIcon from '../../../../../icons/component-icons/BuildingIcon';
import BriefcaseIcon from '../../../../../icons/component-icons/BriefcaseIcon';
import { CurrentUserContext } from '../../../../../contexts/current-user-context/CurrentUserContext';
import GraduateHatIcon from '../../../../../icons/component-icons/GraduateHatIcon';
import Routes from '../../../../../routes';
import { Link, generatePath } from 'react-router';
import moment from 'moment';
import MessageChatCircleIcon from '../../../../../icons/component-icons/MessageChatCircleIcon';
import CalendarPlusIcon from '../../../../../icons/component-icons/CalendarPlusIcon';
import EditIcon from '../../../../../icons/component-icons/EditIcon';
import SlashCircleIcon from '../../../../../icons/component-icons/SlashCircleIcon';
import PhoneCallIcon from '../../../../../icons/component-icons/PhoneCallIcon';
import WebcamIcon from '../../../../../icons/component-icons/WebcamIcon';
import MarkerPinIcon from '../../../../../icons/component-icons/MarkerPinIcon';
import StarIcon from '../../../../../icons/component-icons/StarIcon';
import MessageTextCircleIcon from '../../../../../icons/component-icons/MessageTextCircleIcon';
import XCircleIcon from '../../../../../icons/component-icons/XCircleIcon';
import { AppointmentTypesEnum, Get_Appointment_ListQuery, PrimarySituationEnum } from '../../../../../@types/graphql.d';
import translations from './AppointmentListItem.translations';
import useModal from '../../../../../components/modal/Modal.hook';
import DeleteAppointmentModalTranslations from '../../../../../scenes/interactions/hooks/components/delete-appointment-modal/DeleteAppointmentModal.translations';
import DeleteAppointmentModal from '../../../../../scenes/interactions/hooks/components/delete-appointment-modal/DeleteAppointmentModal';
import { AppointmentUpdateModalTranslations } from '../../../../../scenes/interactions/hooks/components/appointment-modal/AppointmentModal.translations';
import { MoveAppointmentModal } from '../../../../../scenes/interactions/hooks/components/appointment-modal/AppointmentModal';
import { useIntl } from 'react-intl';

type AppointmentListItemProps = {
  appointment: NonNullable<
    ValuesType<NonNullable<NonNullable<Get_Appointment_ListQuery['appointments']>['edges']>>['node']
  >;
  isPast: boolean;
};

function AppointmentTypeIcon({ type }: { type: AppointmentTypesEnum }) {
  switch (type) {
    case AppointmentTypesEnum.Phone:
      return <PhoneCallIcon className="appointment-list-item__appointment__type__icon" />;
    case AppointmentTypesEnum.Video:
      return <WebcamIcon className="appointment-list-item__appointment__type__icon" />;
    case AppointmentTypesEnum.Physical:
      return <MarkerPinIcon className="appointment-list-item__appointment__type__icon" />;
    default:
      return null;
  }
}

export default function AppointmentListItem({ appointment, isPast }: AppointmentListItemProps) {
  const intl = useIntl();
  const [openModal] = useModal();
  const { currentUser } = useContext(CurrentUserContext);
  const {
    id,
    type,
    from,
    currentUserInterlocutor,
    conversation,
    googleCalendarUrl,
    yahooCalendarUrl,
    meetingPlace,
    currentUserInterlocutorReviewMessage,
    currentUserReviewMessage,
    currentUserCanReview,
    currentUserRecommendInterlocutor,
    currentUserRecommendedByInterlocutor,
  } = appointment;

  const [showReviews, setShowReviews] = useState(false);
  const {
    currentUserInterlocutor: {
      primarySituation,
      companyName,
      previousCompanyName,
      jobTitle,
      previousPosition,
      schoolName,
    },
  } = appointment;

  const getStatusDetailsFromInterlocutor = () => {
    switch (primarySituation?.key) {
      case PrimarySituationEnum.Student:
        return (
          <div>
            <GraduateHatIcon />
            {schoolName}
          </div>
        );
      case PrimarySituationEnum.Worker:
        return (
          <>
            <div>
              <BuildingIcon />
              {companyName}
            </div>
            <div>
              <BriefcaseIcon />
              {jobTitle}
            </div>
          </>
        );
      case PrimarySituationEnum.JobSeeker:
      case PrimarySituationEnum.Retired:
        return previousCompanyName ? (
          <>
            <div>
              <BuildingIcon />
              {'ex-' + previousCompanyName}
            </div>
            <div>
              <BriefcaseIcon />
              {'ex-' + previousPosition}
            </div>
          </>
        ) : null;
      default:
        return null;
    }
  };

  const openMoveModal = () =>
    openModal({
      title: intl.formatMessage(AppointmentUpdateModalTranslations.title),
      content: <MoveAppointmentModal appointment={appointment} />,
    });

  const openDeleteModal = () =>
    openModal({
      title: intl.formatMessage(DeleteAppointmentModalTranslations.title),
      content: <DeleteAppointmentModal appointment={appointment} />,
    });

  return (
    <div
      className={classNames(
        'appointment-list-item',
        {
          'appointment-list-item--as-member': conversation.currentUserIsInitiator,
        },
        { 'appointment-list-item--with-reviews': isPast },
      )}
    >
      <div className="appointment-list-item__interlocutor">
        <img
          className="appointment-list-item__interlocutor__avatar"
          src={currentUserInterlocutor.avatars.small.url!}
          alt=""
        />

        <div className="appointment-list-item__interlocutor__infos">
          <div className="appointment-list-item__interlocutor__infos__head">
            <div className="appointment-list-item__interlocutor__infos__head__interlocutor">
              <div className="appointment-list-item__interlocutor__infos__head__interlocutor__name">
                {appointment.currentUserInterlocutor.firstName}
              </div>
              <div className="appointment-list-item__interlocutor__infos__head__interlocutor__status">
                {getStatusDetailsFromInterlocutor()}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="appointment-list-item__separator">
        <AppointmentTypeIcon type={type} />
      </div>

      <div className="appointment-list-item__appointment">
        <div className="appointment-list-item__appointment__type">
          {type === AppointmentTypesEnum.Physical ? (
            <>
              <a
                href={`tel:+${currentUserInterlocutor.normalizedPhone}`}
                rel="noreferrer"
                target="_blank"
              >{`+${currentUserInterlocutor.normalizedPhone}`}</a>
              <a
                href={`https://maps.google.com?q=${meetingPlace}`}
                rel="noreferrer"
                target="_blank"
              >
                {meetingPlace}
              </a>
            </>
          ) : (
            <a
              href={`tel:+${currentUserInterlocutor.normalizedPhone}`}
              rel="noreferrer"
              target="_blank"
            >{`+${currentUserInterlocutor.normalizedPhone}`}</a>
          )}
        </div>
        <div className="appointment-list-item__appointment__day">{moment(from).format('ll')}</div>
        <div className="appointment-list-item__appointment__separator" />
        <span className="appointment-list-item__appointment__time">{moment(from).format('LT')}</span>
      </div>

      <ul className="appointment-list-item__actions">
        <li className="appointment-list-item__actions__item">
          <Link to={generatePath(Routes.conversation, { id: conversation.id })}>
            <MessageChatCircleIcon />
          </Link>
          <div className="appointment-list-item__actions__item__tooltip">
            {intl.formatMessage(translations.seeMessages)}
          </div>
        </li>
        <li
          className={classNames('appointment-list-item__actions__item', {
            'appointment-list-item__actions__item--disabled': isPast,
          })}
        >
          <Link
            to={generatePath(Routes.conversation, { id: conversation.id })}
            target="_blank"
          >
            <CalendarPlusIcon />
          </Link>
          <div
            className={classNames(
              'appointment-list-item__actions__item__tooltip',
              'appointment-list-item__actions__item__tooltip--calendar',
            )}
          >
            <div className="appointment-list-item__actions__item__tooltip--calendar__title">
              {intl.formatMessage(translations.addToCalendar)}
            </div>
            <ul className="appointment-list-item__actions__item__tooltip--calendar__calendars">
              <li>
                <a
                  href={googleCalendarUrl}
                  rel="noreferrer"
                  target="_blank"
                >
                  Google
                </a>
              </li>
              <li>
                {}
                <Link
                  to={generatePath(Routes.downloadConversationAppointmentIcalendar, {
                    id: conversation.id,
                    appointment_id: id,
                  })}
                >
                  Apple
                </Link>
              </li>
              <li>
                {}
                <Link
                  to={generatePath(Routes.downloadConversationAppointmentIcalendar, {
                    id: conversation.id,
                    appointment_id: id,
                  })}
                >
                  Outlook
                </Link>
              </li>
              <li>
                <a
                  href={yahooCalendarUrl}
                  rel="noreferrer"
                  target="_blank"
                >
                  Yahoo
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li
          className={classNames('appointment-list-item__actions__item', {
            'appointment-list-item__actions__item--disabled': isPast,
          })}
        >
          <a onClick={openMoveModal}>
            <EditIcon />
          </a>
          <div className="appointment-list-item__actions__item__tooltip">
            {intl.formatMessage(translations.updateAppointment)}
          </div>
        </li>
        <li
          className={classNames(
            'appointment-list-item__actions__item',
            'appointment-list-item__actions__item--delete',
            { 'appointment-list-item__actions__item--disabled': isPast },
          )}
        >
          <a onClick={openDeleteModal}>
            <SlashCircleIcon />
          </a>
          <div className="appointment-list-item__actions__item__tooltip">
            {intl.formatMessage(translations.deleteAppointment)}
          </div>
        </li>
      </ul>

      {isPast && (
        <div className="appointment-list-item__reviews">
          {currentUserCanReview && (
            <Link
              to={generatePath(Routes.review, { appointmentId: id })}
              className="appointment-list-item__reviews__link"
            >
              <StarIcon />
              {intl.formatMessage(translations.reviewAppointment)}
            </Link>
          )}
          <button
            type="button"
            onClick={() => setShowReviews(true)}
          >
            <MessageTextCircleIcon />
            {intl.formatMessage(translations.seeReviews)}
          </button>

          {showReviews && (
            <div className="appointment-list-item__reviews__popover">
              <div className="appointment-list-item__reviews__popover__reviews">
                <div className="appointment-list-item__reviews__popover__reviews__interlocutor">
                  {currentUserRecommendInterlocutor && (
                    <div className="appointment-list-item__reviews__popover__reviews__interlocutor__recommended">
                      {intl.formatMessage(translations.interlocutorRecommended)}
                    </div>
                  )}
                  <div className="appointment-list-item__reviews__popover__reviews__interlocutor__name">
                    {currentUserInterlocutor.firstName}
                  </div>
                  <div className="appointment-list-item__reviews__popover__reviews__interlocutor__text">
                    {currentUserInterlocutorReviewMessage || intl.formatMessage(translations.noReview)}
                  </div>
                </div>
                <div className="appointment-list-item__reviews__popover__reviews__current-user">
                  {currentUserRecommendedByInterlocutor && (
                    <div className="appointment-list-item__reviews__popover__reviews__current-user__recommended">
                      {intl.formatMessage(translations.recommendedByInterlocutor)}
                    </div>
                  )}
                  <div className="appointment-list-item__reviews__popover__reviews__current-user__name">
                    {currentUser?.firstName}
                  </div>
                  <div className="appointment-list-item__reviews__popover__reviews__current-user__text">
                    {currentUserReviewMessage || intl.formatMessage(translations.noReview)}
                  </div>
                </div>
              </div>

              <button
                type="button"
                className="appointment-list-item__reviews__popover__close-button"
                onClick={() => setShowReviews(false)}
              >
                <XCircleIcon />
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
