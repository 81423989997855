import React, { memo, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import './SigninZone.scss';

import UserIcon from '../../../../../icons/component-icons/UserIcon';
import { getAccountUrlWithRedirection } from '../../../../../utils/redirection.util';

const TRANSLATIONS = defineMessages({
  signin: {
    id: 'SigninZone.signin',
    defaultMessage: 'Connectez-vous',
  },
  message: {
    id: 'SigninZone.message',
    defaultMessage: 'pour envoyer votre message en utilisant les informations que vous avez déjà enregistrées.',
  },
});

export const SigninZone: React.FC = memo(() => {
  const { formatMessage } = useIntl();
  const redirectionUrl = useMemo(() => window.location.href.replace('/from-widget', ''), []);
  return (
    <div className="signin-zone">
      <UserIcon />
      <p>
        <a href={getAccountUrlWithRedirection('/', { redirectionUrl })}>{formatMessage(TRANSLATIONS.signin)}</a>
        {formatMessage(TRANSLATIONS.message)}
      </p>
    </div>
  );
});
