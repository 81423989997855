import React from 'react';
import Help from '../../../../../../common/help/Help';

import styles from './CompanyAdminDashboardCountWithIcon.module.scss';
import classnames from 'classnames';

interface CompanyAdminDashboardCountWithIconProps {
  title: string;
  tooltip?: string;
  picto?: (props: { className?: string }) => JSX.Element;
  value: number | '-';
  className?: string;
  blurred?: boolean;
}
function CompanyAdminDashboardCountWithIcon({
  title,
  tooltip,
  picto: Picto,
  value,
  className,
  blurred
}: CompanyAdminDashboardCountWithIconProps): JSX.Element {
  return (
    <div className={className}>
      <div className={styles.titleWithTooltip}>
        <span className={styles.title}>{title}</span>
        {tooltip && (
          <Help
            text={tooltip}
            variant="blue-dark"
          />
        )}
      </div>
      <div className={styles.value}>
        {Picto && <Picto className={styles.picto} />}
        <p className={classnames({'blurred': blurred})}>{value}</p>
      </div>
    </div>
  );
}

export default CompanyAdminDashboardCountWithIcon;
