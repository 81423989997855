import { defineMessages } from 'react-intl';
import { AddCompanyRecruitingWorkAreaMutationErrors } from '../../../../../../../@types/graphql-errors.d';

export const errorTranslation = defineMessages({
  [AddCompanyRecruitingWorkAreaMutationErrors.WORKING_AREA_ALREADY_EXISTS]: {
    id: 'RecruitingWorkingAreasWrite.WORKING_AREA_ALREADY_EXISTS',
    defaultMessage: 'Nom invalide : ce domaine existe déjà',
  },
  [AddCompanyRecruitingWorkAreaMutationErrors.WORKING_AREA_EMPTY]: {
    id: 'RecruitingWorkingAreasWrite.WORKING_AREA_EMPTY',
    defaultMessage: 'Nom invalide : doit être rempli',
  },
});

export default defineMessages({
  labelName: {
    id: 'RecruitingWorkingAreasWrite.labelName',
    defaultMessage: "Nom du domaine d'emploi",
  },
  labelFormationName: {
    id: 'RecruitingWorkingAreasWrite.labelFormationName',
    defaultMessage: 'Nom de la formation',
  },
  counter: {
    id: 'RecruitingWorkingAreasWrite.counter',
    defaultMessage: '<span class="highlight">{current}</span>/{max} caractères',
  },
  listValues: {
    id: 'RecruitingWorkingAreasWrite.listValues',
    defaultMessage: 'Listez des domaines d’emplois à promouvoir',
  },
  listFormationsValues: {
    id: 'RecruitingWorkingAreasWrite.listFormationsValues',
    defaultMessage: 'Listez des formations à promouvoir',
  },
  sample: {
    id: 'RecruitingWorkingAreasWrite.sample',
    defaultMessage: 'Ex :',
  },
  marketing: {
    id: 'RecruitingWorkingAreasWrite.marketing',
    defaultMessage: 'marketing',
  },
  finance: {
    id: 'RecruitingWorkingAreasWrite.finance',
    defaultMessage: 'finance',
  },
  hr: {
    id: 'RecruitingWorkingAreasWrite.hr',
    defaultMessage: 'RH',
  },
  helper: {
    id: 'RecruitingWorkingAreasWrite.helper',
    defaultMessage: 'Pour ajouter cette valeur, appuyez sur "Entrée" (⏎)',
  },
  finishButton: {
    id: 'RecruitingWorkingAreasWrite.finishButton',
    defaultMessage: 'Terminer',
  },
});
