import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import { useSnackbar } from 'notistack';
import moment from 'moment-timezone';
import { Get_Last_Report_DataQuery, Get_Last_Report_DataQueryVariables } from '../../../../../../../@types/graphql.d';
import useDownloadAction from '../../../../../../../utils/hooks/useDownloadAction';
import Help from '../../../../../../common/help/Help';
import useCompanyUnique from '../../../../../hooks/useCompanyUnique/useCompanyUnique';
import { GET_LAST_REPORT_DATA } from './CompanyReportDownload.gql';
import translations from './CompanyReportDownload.translations';

import styles from './CompanyReportDownload.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import BarChartIcon from '../../../../../../../icons/component-icons/BarChartIcon';

interface CompanyReportDownloadProps {
  className?: string;
}
function CompanyReportDownload({ className }: CompanyReportDownloadProps) {
  const intl = useIntl();
  const [downloadStarted, setDownloadStarted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const companyId = useCompanyUnique();
  const { data } = useQuery<Get_Last_Report_DataQuery, Get_Last_Report_DataQueryVariables>(GET_LAST_REPORT_DATA, {
    skip: !companyId,
    variables: {
      companyId: companyId || '',
    },
    fetchPolicy: 'network-only',
  });
  const date = moment(data?.company?.lastReportGeneratedAt);
  const reportFileName = useMemo(
    () => `${data?.company?.name.replace(/[ '/()&,’]+/gi, '-')}_${date.format('YYYY-MM')}_report.xlsx`,
    [data?.company, date],
  );
  const onDownload = useDownloadAction(
    `/companies/${companyId}/report`,
    reportFileName,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    () => setDownloadStarted(true),
    () => undefined,
    () => {
      enqueueSnackbar(intl.formatMessage(translations.error), {
        autoHideDuration: 5000,
        variant: 'error',
      });
    },
  );
  return (
    <div className={classnames(styles.companyReportDownload, className)}>
      <BarChartIcon className={styles.icon} />
      <div className={styles.generationDateAndTooltip}>
        {data?.company?.lastReportGeneratedAt ? (
          <span className={styles.text}>
            <FormattedMessage
              {...translations.reportDate}
              values={{
                date: intl.formatDate(date.toISOString(), {
                  month: 'long',
                  year: 'numeric',
                }),
                span: (chunks) => <span className="highlight">{chunks}</span>,
              }}
            />
          </span>
        ) : (
          <span className={styles.text}>{intl.formatMessage(translations.noReport)}</span>
        )}
        <Help
          text={intl.formatMessage(translations.tooltip)}
          variant="blue-dark"
        />
      </div>

      <button
        type="button"
        className={styles.downloadButton}
        onClick={onDownload}
        disabled={!data?.company?.lastReportGeneratedAt || downloadStarted}
      >
        {intl.formatMessage(translations.downloadButton)}
      </button>
    </div>
  );
}

export default CompanyReportDownload;
