import { defineMessages } from 'react-intl';

export const MeetTypeTranslations = defineMessages({
  professionals: {
    id: 'MeetType.professionals',
    defaultMessage: 'Rencontrer des professionnels',
  },
  students: {
    id: 'MeetType.students',
    defaultMessage: 'Rencontrer des lycéens/étudiants',
  },
  mobileProfessionals: {
    id: 'MeetType.mobileProfessionals',
    defaultMessage: 'Professionnels',
  },
  mobileStudents: {
    id: 'MeetType.mobileStudents',
    defaultMessage: 'Lycéens - étudiants',
  },
});
