import { defineMessages } from 'react-intl';

export default defineMessages({
  opportunityTitle: {
    id: 'CompanyAdminAppointmentSidebar.appointment.opportunityTitle',
    defaultMessage: 'Opportunité',
  },
  interestTitle: {
    id: 'CompanyAdminAppointmentSidebar.appointment.interestTitle',
    defaultMessage: 'Intérêt',
  },
  yes: {
    id: 'CompanyAdminAppointmentSidebar.appointment.yes',
    defaultMessage: 'Oui',
  },
  no: {
    id: 'CompanyAdminAppointmentSidebar.appointment.no',
    defaultMessage: 'Non',
  },
  opportunity: {
    id: 'CompanyAdminAppointmentSidebar.appointment.opportunity',
    defaultMessage: "{type} d'une durée {duration} à partir de {month} {year} ({zipCode})",
  },
});
