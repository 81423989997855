import React from 'react';
import { IconProps } from './Icon';

export default function TrophyIcon({ className }: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.00016 10.0002C5.79102 10.0002 4.00016 8.2093 4.00016 6.00016V2.29646C4.00016 2.02056 4.00016 1.88261 4.04037 1.77215C4.10777 1.58697 4.25364 1.4411 4.43882 1.3737C4.54928 1.3335 4.68723 1.3335 4.96313 1.3335H11.0372C11.3131 1.3335 11.451 1.3335 11.5615 1.3737C11.7467 1.4411 11.8926 1.58697 11.96 1.77215C12.0002 1.88261 12.0002 2.02056 12.0002 2.29646V6.00016C12.0002 8.2093 10.2093 10.0002 8.00016 10.0002ZM8.00016 10.0002V12.0002M12.0002 2.66683H13.6668C13.9775 2.66683 14.1328 2.66683 14.2553 2.71758C14.4186 2.78524 14.5484 2.91502 14.6161 3.07837C14.6668 3.20089 14.6668 3.3562 14.6668 3.66683V4.00016C14.6668 4.62014 14.6668 4.93013 14.5987 5.18447C14.4137 5.87465 13.8747 6.41375 13.1845 6.59868C12.9301 6.66683 12.6201 6.66683 12.0002 6.66683M4.00016 2.66683H2.3335C2.02287 2.66683 1.86755 2.66683 1.74504 2.71758C1.58169 2.78524 1.45191 2.91502 1.38424 3.07837C1.3335 3.20089 1.3335 3.3562 1.3335 3.66683V4.00016C1.3335 4.62014 1.3335 4.93013 1.40164 5.18447C1.58658 5.87465 2.12567 6.41375 2.81586 6.59868C3.07019 6.66683 3.38018 6.66683 4.00016 6.66683M4.96313 14.6668H11.0372C11.2008 14.6668 11.3335 14.5342 11.3335 14.3705C11.3335 13.0614 10.2722 12.0002 8.96313 12.0002H7.0372C5.72808 12.0002 4.66683 13.0614 4.66683 14.3705C4.66683 14.5342 4.79949 14.6668 4.96313 14.6668Z"
        stroke="#09193B"
        strokeWidth="1.5"
      />
    </svg>
  );
}
