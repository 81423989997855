import React from 'react';
import classnames from 'classnames';
import WithConfirm from '../../../../common/with-confirm/WithConfirm';
import WithTooltip from '../../../../common/with-tooltip/WithTooltip';

import styles from './EmployeeActionButton.module.scss';

interface IconComponentProps {
  className: string;
}

interface EmployeeActionButtonProps {
  iconComponent: React.ComponentType<IconComponentProps>;
  iconClassName: string;
  className?: string;
  disabled?: boolean;
  actionName: string;
  confirmationMessage: string;
  onConfirm: () => void;
}

function EmployeeActionButton({
  iconComponent: Component,
  iconClassName,
  className,
  disabled,
  actionName,
  confirmationMessage,
  onConfirm,
}: EmployeeActionButtonProps) {
  return (
    <WithTooltip
      text={actionName}
      className={styles.tooltipWrapper}
    >
      {({ closeTooltip, openTooltip }) => (
        <WithConfirm
          title={actionName}
          text={confirmationMessage}
          onConfirm={onConfirm}
        >
          {({ openConfirmationBox }) => (
            <button
              type="button"
              className={classnames(className, styles.employeeActionButton)}
              disabled={disabled}
              onMouseOver={openTooltip}
              onFocus={openTooltip}
              onMouseOut={closeTooltip}
              onBlur={closeTooltip}
              onClick={openConfirmationBox}
              onKeyPress={openConfirmationBox}
            >
              <Component className={iconClassName} />
            </button>
          )}
        </WithConfirm>
      )}
    </WithTooltip>
  );
}

export default EmployeeActionButton;
