import { gql } from '@apollo/client';

export const GET_COMPANY_REGISTRATION_TOKEN_QUERY = gql`
  query GetCompanyRegistrationToken($companyId: ID!) {
    company(companyId: $companyId) {
      id
      registrationToken
      name
    }
  }
`;
