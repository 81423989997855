import React, { memo, useMemo } from 'react';
import { Layout } from './components/layout/Layout';
import { defineMessages, useIntl } from 'react-intl';
import { PanelProps } from './Panel';
import ReviewRadioInput from './components/review-radio-input/ReviewRadioInput';
import { useReviewContext } from '../ReviewContext';

const translations = defineMessages({
  yes: {
    id: 'Review.WantsToRecontactPanel.yes',
    defaultMessage: "J'accepte",
  },
  no: {
    id: 'Review.WantsToRecontactPanel.no',
    defaultMessage: 'Je refuse',
  },
});

export const WantsToRecontactPanel: React.FC<PanelProps> = memo(({ appointment }) => {
  const { reviewFormik } = useReviewContext();
  const { formatMessage } = useIntl();
  const value = useMemo(() => {
    if (typeof reviewFormik.values.wantsToBeRecontacted !== 'boolean') return undefined;
    return reviewFormik.values.wantsToBeRecontacted ? 'y' : 'n';
  }, [reviewFormik.values.wantsToBeRecontacted]);
  return (
    <Layout
      title={
        appointment?.memberReview?.canFillCompanyAspiration
          ? appointment?.memberReview?.companyContactPreferenceQuestion
          : appointment?.memberReview?.schoolContactPreferenceQuestion
      }
      style={{ paddingTop: 80 }}
    >
      <ReviewRadioInput
        name="wants-to-be-recontacted"
        value={value}
        values={[
          { value: 'y', translation: formatMessage(translations.yes) },
          { value: 'n', translation: formatMessage(translations.no) },
        ]}
        onSelection={(v) => {
          reviewFormik.setFieldValue('wantsToBeRecontacted', v === 'y');
        }}
      />
    </Layout>
  );
});
