import React, { memo, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { redirect, useSearchParams } from 'react-router';

import { useMutation } from '@apollo/client';
import { FormikProps } from 'formik';

import { CreateConversationDraftMutation, CreateConversationDraftMutationVariables } from '../../../../@types/graphql';
import Card from '../../../../components/card/Card';
import { redirectToAccountWithRedirection } from '../../../../utils/redirection.util';
import { CREATE_CONVERSATION_DRAFT } from '../NewConversation.gql';
import { StepProps } from '../Step';

const TRANSLATIONS = defineMessages({
  title: {
    id: 'NewConversation.SignUpSection.title',
    defaultMessage: 'Créez votre compte',
  },
});

export const SignUpSection: React.FC<StepProps> = memo(({ formik, interlocutor, step }) => {
  const { formatMessage } = useIntl();

  const [createConversationDraft] = useMutation<
    CreateConversationDraftMutation,
    CreateConversationDraftMutationVariables
  >(CREATE_CONVERSATION_DRAFT, {
    variables: {
      interlocutorUserId: interlocutor.id,
      message: formik.values.message,
    },
    onCompleted: (data) => {
      if (data.createConversationDraft) {
        redirectToAccountWithRedirection('/signup', {
          redirectionUrl: window.location.href + `?step=${step + 1}`, // set redirection url to the next step
          searchParams: [{ key: 'draft_token', value: data.createConversationDraft.attributionToken }],
        });
      }
    },
  });

  // Save draft
  useEffect(() => {
    createConversationDraft();
  }, []);

  return <Card title={formatMessage(TRANSLATIONS.title)}></Card>;
});
