import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import MarkerPinIcon from '../../../../../icons/component-icons/MarkerPinIcon';
import {
  AmbassadorIdentityQuery,
  AmbassadorIdentityQueryVariables,
  UserProfile,
} from '../../../../../@types/graphql.d';
import { IDENTITY_QUERY } from './Identity.gql';

import classNames from 'classnames';
import useIsMobile from '../../../../../hooks/useIsMobile';
import EyeIcon from '../../../../../icons/component-icons/EyeIcon';
import ThumbsUpIcon from '../../../../../icons/component-icons/ThumbsUpIcon';
import StarIconFatBorder from '../../../../../icons/component-icons/StarIconFatBorder';
import Card, { CardLoader } from '../../../../../components/card/Card';
import './Identity.scss';
import { AmbassadorViewIdentityTranslations } from './Identity.translations';
import { useWish } from './hooks/useWish';
import Skeleton from 'react-loading-skeleton';
import { GradeEnumTranslations } from '../../../../../i18n/grade.translations';
import defaultPlaceholderUrl from '../../../../../icons/company-logo-placeholder.svg';
import { useIntl } from 'react-intl';

type IdentityProps = {
  id: string;
};

export default function Identity({ id }: IdentityProps) {
  const intl = useIntl();
  const { wished, toggleWish, loading: wishLoading } = useWish({ ambassadorId: id });
  const { data, loading } = useQuery<AmbassadorIdentityQuery, AmbassadorIdentityQueryVariables>(IDENTITY_QUERY, {
    variables: { id },
    fetchPolicy: 'network-only',
  });

  const isMobile = useIsMobile();

  const companyHasSetBanners = useMemo(
    () => data?.getUser.profileBannerDesktop?.url && data?.getUser.profileBannerMobile?.url,
    [data?.getUser.profileBannerDesktop, data?.getUser.profileBannerMobile],
  );

  const bannerUrl = useMemo<string | undefined | null>(() => {
    if (!data?.getUser || !companyHasSetBanners) return undefined;
    const { profileBannerMobile, profileBannerDesktop } = data.getUser;
    return isMobile ? profileBannerMobile!.url : profileBannerDesktop!.url;
  }, [
    isMobile,
    data?.getUser,
    companyHasSetBanners,
    data?.getUser.profileBannerDesktop,
    data?.getUser.profileBannerMobile,
  ]);

  if (loading) return <IdentityLoader />;
  if (!data?.getUser) return null;

  const {
    firstName,
    lastName,
    publicLastName,
    position,
    companyName,
    meetingPlace,
    avatars,
    companyLogo,
    likesReceivedCount,
    ambassadorProfileViewsCount,
    unavailableUntil,
    isPremiumAmbassador,
    profileType,
    currentGrade,
    currentSchoolName,
    preparedDiplomaTitle,
    previousCompanyLogo,
    previousCompanyName,
    previousPosition,
  } = data!.getUser;

  const isStudent = profileType === UserProfile.InSchool;
  const isExWorker = profileType === UserProfile.PreviouslyInOffice;
  const isWorker = !isStudent && !isExWorker;

  return (
    <section className="ambassador-view-identity">
      <Card noPadding>
        <header
          className={classNames('ambassador-view-identity__banner', {
            'ambassador-view-identity__banner--premium': isPremiumAmbassador && !companyHasSetBanners,
            'ambassador-view-identity__banner--custom': companyHasSetBanners,
          })}
        >
          {companyHasSetBanners && bannerUrl && (
            <img
              className="ambassador-view-identity__banner--custom__image"
              src={bannerUrl}
            />
          )}
          <img
            className="ambassador-view-identity__banner__avatar"
            src={avatars.url || ''}
          />
          <img
            className="ambassador-view-identity__banner__company-logo"
            src={companyLogo?.url || previousCompanyLogo?.url || defaultPlaceholderUrl}
          />
        </header>

        <div className="ambassador-view-identity__content">
          <div className="ambassador-view-identity__content__first-row">
            <div className="ambassador-view-identity__content__first-row__name">
              {firstName} {publicLastName ? lastName : null}
            </div>
            <StarIconFatBorder
              className={classNames('ambassador-view-identity__content__first-row__star-icon', {
                'ambassador-view-identity__content__first-row__star-icon--loading': wishLoading,
                'ambassador-view-identity__content__first-row__star-icon--filled': !wishLoading && wished,
                'ambassador-view-identity__content__first-row__star-icon--empty': !wishLoading && !wished,
              })}
              onClick={!wishLoading ? toggleWish : undefined}
            />
          </div>
          <div className="ambassador-view-identity__content__position">
            {isWorker && position}
            {isExWorker && `ex - ${previousPosition}`}
            {isStudent && currentGrade && intl.formatMessage(GradeEnumTranslations[currentGrade])}
            {isStudent && preparedDiplomaTitle && ` - ${preparedDiplomaTitle}`}
          </div>
          <div className="ambassador-view-identity__content__company-name">
            {isStudent && currentSchoolName}
            {isWorker && companyName}
            {isExWorker && previousCompanyName}
          </div>
          <div className="ambassador-view-identity__content__location">
            <MarkerPinIcon /> {meetingPlace}
          </div>

          <div className="ambassador-view-identity__content__stats">
            <div className="ambassador-view-identity__content__stats__like-count">
              <ThumbsUpIcon /> {likesReceivedCount} {intl.formatMessage(AmbassadorViewIdentityTranslations.likes)}
            </div>
            <div className="ambassador-view-identity__content__stats__seen-count">
              <EyeIcon /> {ambassadorProfileViewsCount} {intl.formatMessage(AmbassadorViewIdentityTranslations.seen)}
            </div>
            <div
              className={classNames('ambassador-view-identity__content__stats__availability', {
                'ambassador-view-identity__content__stats__availability--unavailable': !!unavailableUntil,
              })}
            >
              {intl.formatMessage(
                unavailableUntil
                  ? AmbassadorViewIdentityTranslations.unavailable
                  : AmbassadorViewIdentityTranslations.available,
              )}
            </div>
          </div>
        </div>
      </Card>
    </section>
  );
}

const IdentityLoader = () => (
  <section className="ambassador-view-identity">
    <CardLoader
      noPadding
      noHeader
    >
      <header
        className={classNames('ambassador-view-identity__banner', {
          'ambassador-view-identity__banner--custom': true,
        })}
      >
        <Skeleton className="ambassador-view-identity__banner--custom__image" />
        <Skeleton
          className="ambassador-view-identity__banner__avatar"
          style={{ backgroundColor: '#f3f3f3', border: 'none', position: 'absolute' }}
          circle
          width={128}
          height={128}
        />
        <Skeleton
          className="ambassador-view-identity__banner__company-logo"
          style={{ backgroundColor: '#f3f3f3', border: 'none', position: 'absolute' }}
          circle
          width={64}
          height={64}
        />
      </header>

      <div className="ambassador-view-identity__content">
        <Skeleton width="20%" />
        <Skeleton width="40%" />
        <Skeleton width="30%" />
        <div className="ambassador-view-identity__content__location">
          <Skeleton
            width={24}
            height={24}
            circle
          />
          <Skeleton
            containerClassName="skeleton-container-flex-1"
            width="20%"
          />
        </div>

        <div className="ambassador-view-identity__content__stats">
          <div style={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
            <Skeleton
              width={24}
              height={24}
              circle
            />
            <Skeleton width={64} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
            <Skeleton
              width={24}
              height={24}
              circle
            />
            <Skeleton width={64} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
            <Skeleton
              width={24}
              height={24}
              circle
            />
            <Skeleton width={64} />
          </div>
        </div>
      </div>
    </CardLoader>
  </section>
);
