import React, { useRef, useState } from 'react';
import ClockIcon from '../../../../../../../icons/component-icons/ClockIcon';
import { FieldProps } from 'formik';
import { useIntl } from 'react-intl';
import moment from 'moment-timezone';
import TimePicker from '../../../../../../common/time-picker/TimePicker';
import { useOutsideClickEffect } from '../../../../../../../utils/hooks/useOutsideClickEffect';
import ContactDateButton from '../contact-date-button/ContactDateButton';
import translations from './ContactDatetime.translations';

import styles from './ContactDatetime.module.scss';

interface ContactDatetimeProps extends FieldProps {
  maxDate?: moment.Moment;
}

function ContactDatetime({ field, maxDate }: ContactDatetimeProps) {
  const intl = useIntl();
  const [isPopperVisible, setPopperVisibility] = useState(false);
  function handleChangeTime(newtime: moment.Moment) {
    field.onChange({
      target: {
        name: field.name,
        value: field.value.clone().hours(newtime.hours()).minutes(newtime.minutes()),
      },
    });
  }
  function handleClick() {
    setPopperVisibility(!isPopperVisible);
  }
  function handleClose() {
    setPopperVisibility(false);
  }
  const popperRef = useRef(null);
  useOutsideClickEffect(handleClose, popperRef);
  return (
    <div
      className={styles.contactDatetime}
      ref={popperRef}
    >
      <ContactDateButton
        datetime={field.value}
        icon={ClockIcon}
        format="hour minute"
        onClick={handleClick}
        title={intl.formatMessage(translations.localTime)}
      />
      {isPopperVisible && (
        <div className={styles.popper}>
          <TimePicker
            onChange={handleChangeTime}
            value={field.value}
            maxDate={maxDate}
          />
        </div>
      )}
    </div>
  );
}

export default ContactDatetime;
