import React, { useEffect } from 'react';
import { Navigate, generatePath, matchRoutes, useLocation, useMatch } from 'react-router';
import { CompanyQueryErrors } from '../../../@types/graphql-errors.d';
import { Company, Get_CompanyQuery, Get_CompanyQueryVariables } from '../../../@types/graphql.d';
import NotFound from '../../../containers/not-found/NotFound';
import Routes from '../../../routes';
import { useQueryWithError } from '../../../utils/graphql';
import NoNetwork from '../../common/no-network/NoNetwork';
import PageLoader from '../../common/page-loader/PageLoader';
import { GET_COMPANY } from './CompanyView.gql';
import CompanyOverview from './components/company-overview/CompanyOverview';
import EmployeeList from './components/employee-list/EmployeeList';
import CompanyContext from './context/CompanyContext';
import withCompaniesData from './hooks/withCompaniesData/withCompaniesData';

import styles from './CompanyView.module.scss';
import ahoy from 'ahoy.js';

interface CompanyViewProps {
  companies?: Company[];
}

function CompanyView({ companies }: CompanyViewProps) {
  const routes = [
    { path: Routes.companyAdminDashboardWithId },
    { path: Routes.companyAdminAmbassadors },
    { path: Routes.companyAdminAmbassadorsArchived },
    { path: Routes.companyAdminAmbassadorsAdd },
    { path: Routes.companyAdminImportEmployees },
    { path: Routes.companyAdminConfiguration },
    { path: Routes.companyAdminShowAmbassador },
    { path: Routes.companyAdminEditAmbassador },
    { path: Routes.companyAdminEditAmbassadorAvatar },
    { path: Routes.companyAdminEditDescription },
    { path: Routes.companyAdminAppointmentsWithId },
    { path: Routes.companyAdminAppointmentsDetails },
  ];

  const match = matchRoutes(routes, useLocation().pathname);

  const isMjgAdmin = !!useMatch(Routes.mjgAdminBase);
  const companyId = match ? match[0].params?.companyid : null;
  const { loading, error, refetch, data } = useQueryWithError<
    Get_CompanyQuery,
    Get_CompanyQueryVariables,
    CompanyQueryErrors
  >(GET_COMPANY, {
    skip: !companyId,
    variables: {
      companyId: companyId || '',
    },
  });

  if (!companyId) {
    return (
      <Navigate
        to={generatePath(isMjgAdmin ? Routes.mjgAdminCompaniesShow : Routes.companyAdminAmbassadors, {
          companyid: companies && companies.length > 0 ? companies[0].id : '',
        })}
      />
    );
  }

  useEffect(() => {
    if (companyId && data?.company?.name) {
      ahoy.track('b2b_connect_viewed_ambassadors_page', {
        companyId: companyId,
        companyName: data.company.name,
      });
    }
  }, [companyId, data?.company?.name]);

  if (loading) {
    return <PageLoader />;
  }

  if (error?.networkError) {
    return <NoNetwork refetch={refetch} />;
  }

  if (
    error?.graphQLErrors?.[0]?.extensions?.code &&
    [CompanyQueryErrors.COMPANY_UNAUTHORIZED, CompanyQueryErrors.COMPANY_COMPANY_NOT_FOUND].includes(
      error?.graphQLErrors?.[0]?.extensions?.code,
    )
  ) {
    return <NotFound />;
  }

  return (
    <CompanyContext.Provider
      value={{
        companyid: companyId,
      }}
    >
      <main className={styles.companyView}>
        <div className={styles.container}>
          <CompanyOverview isPremiumSchool={data?.company?.isPremiumSchool || false} />
          <EmployeeList />
        </div>
      </main>
    </CompanyContext.Provider>
  );
}

export const CompanyViewForMjgAdmin = withCompaniesData<CompanyViewProps>(CompanyView);
export default withCompaniesData<CompanyViewProps>(CompanyView);
