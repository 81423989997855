import React from 'react';
import ForbiddenIcon from '../../../../../../../icons/component-icons/ForbiddenIcon';
import translations from './ProfessionalSoftDeleted.translations';

import './ProfessionalSoftDeleted.scss';
import { useIntl } from 'react-intl';

function ProfessionalSoftDeleted() {
  const intl = useIntl();
  return (
    <div className="professional-soft-deleted">
      <aside className="professional-soft-deleted-mark">
        <div className="icon-wrapper">
          <ForbiddenIcon />
        </div>
      </aside>
      <div className="professional-soft-deleted-content">
        <span className="professional-soft-deleted-status">
          {intl.formatMessage(translations.impossibleAppointment)}
        </span>
        <span className="professional-soft-deleted-inactive">{intl.formatMessage(translations.inactiveProfile)}</span>
      </div>
    </div>
  );
}

export default ProfessionalSoftDeleted;
