import { gql } from '@apollo/client';

export const GET_MY_PARTICIPATIONS_LIST = gql`
  query GET_MY_PARTICIPATIONS_LIST {
    me {
      participations(sort: { orderBy: createdAt, order: DESC }) {
        nodes {
          createdAt
          id
          certificationStatus
          unfollowedConversationCount
          reportCount
          program {
            id
            name
            startAt
            endAt
            description
            meetingsQuantity
            programTemplate {
              id
              displayedName
            }
            project {
              id
              organization {
                id
                name
              }
            }
            collaborations {
              nodes {
                id
                user {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    }
  }
`;
