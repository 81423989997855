import { defineMessages } from 'react-intl';

export default defineMessages({
  generalInformationTitle: {
    id: 'CompanyGeneralInformationsHub.generalInformationTitle',
    defaultMessage: 'Informations générales',
  },
  generalInformationDescription: {
    id: 'CompanyGeneralInformationsHub.generalInformationDescription',
    defaultMessage: 'Ceci définira la manière dont votre entreprise apparaîtra dans les résultats de recherche.',
  },
  generalInformationSchoolDescription: {
    id: 'CompanyGeneralInformationsHub.generalInformationSchoolDescription',
    defaultMessage: 'Ceci définira la manière dont votre établissement apparaitra dans les résultats de recherche',
  },
});
