import { defineMessages } from 'react-intl';

export default defineMessages({
  description: {
    id: 'GraphQLInfiniteScrollError.description',
    defaultMessage: "Une erreur s'est produite lors de la récupération de plus d'éléments",
  },
  button: {
    id: 'GraphQLInfiniteScrollError.button',
    defaultMessage: 'retenter',
  },
});
