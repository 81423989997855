import React from 'react';
import { FormattedDate } from 'react-intl';

interface DayMonthYearFormatProps {
  datetime: string;
}
function DayMonthYearFormat({ datetime }: DayMonthYearFormatProps) {
  return (
    <FormattedDate
      day="2-digit"
      month="2-digit"
      year="numeric"
      value={datetime}
    />
  );
}

export default DayMonthYearFormat;
