import { gql } from '@apollo/client';

export const GET_FUTURE_APPOINTMENTS_QUERY = gql`
  query GetFutureAppointments($filters: AppointmentsFilter) {
    appointments(filters: $filters) {
      edges {
        node {
          id
        }
      }
    }
  }
`;
