import { defineMessages } from 'react-intl';

export const GeneralTranslations = defineMessages({
  beginning: {
    id: 'GeneralTranslations.beginning',
    defaultMessage: 'Début',
  },
  end: {
    id: 'GeneralTranslations.end',
    defaultMessage: 'Fin',
  },
  next: {
    id: 'GeneralTranslations.next',
    defaultMessage: 'Suivant',
  },
  previous: {
    id: 'GeneralTranslations.previous',
    defaultMessage: 'Précédent',
  },
  cancel: {
    id: 'GeneralTranslations.cancel',
    defaultMessage: 'Annuler',
  },
  confirm: {
    id: 'GeneralTranslations.confirm',
    defaultMessage: 'Confirmer',
  },
});
