import React from 'react';
import classnames from 'classnames';

import './AccessibleTooltip.scss';

export type AccessibleTooltipProps = {
  name: string;
  tooltip: string;
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
};

function AccessibleTooltip({
  name,
  tooltip,
  children,
  onClick = undefined,
  className = undefined,
}: AccessibleTooltipProps) {
  return (
    <div className={classnames('accessible-tooltip-wrapper', className)}>
      <button
        type="button"
        aria-describedby={name}
        className="accessible-tooltip-button"
        onClick={onClick}
      >
        {children}
      </button>
      <div
        id={name}
        role="tooltip"
        className="accessible-tooltip"
      >
        {tooltip}
      </div>
    </div>
  );
}

export default AccessibleTooltip;
