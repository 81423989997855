import { useQuery } from '@apollo/client';
import { GET_CONVERSATION_UNRESOLVED_APPOINTMENT_CLAIMS } from './useConversationAppointmentClaims.gql';
import {
  Get_Conversation_Unresolved_Appointment_ClaimsQuery,
  Get_Conversation_Unresolved_Appointment_ClaimsQueryVariables,
} from '../../../../../../@types/graphql.d';

export type AppointmentClaim = NonNullable<
  Get_Conversation_Unresolved_Appointment_ClaimsQuery['appointmentClaims']
>['nodes'][0];
export function useConversationAppointmentClaims({ conversationId }: { conversationId: string }) {
  const { data, loading, error } = useQuery<
    Get_Conversation_Unresolved_Appointment_ClaimsQuery,
    Get_Conversation_Unresolved_Appointment_ClaimsQueryVariables
  >(GET_CONVERSATION_UNRESOLVED_APPOINTMENT_CLAIMS, { variables: { conversationId } });

  return { claims: data?.appointmentClaims?.nodes, loading, error };
}
