import React from 'react';
import { useQuery } from '@apollo/client';
import EyeIcon from '../../../../../icons/component-icons/EyeIcon';
import classnames from 'classnames';
import {
  Get_Company_Attractiveness_ReportQuery,
  Get_Company_Attractiveness_ReportQueryVariables,
  GetCompanyAuthorizationDashboardQuery,
  GetCompanyAuthorizationDashboardQueryVariables,
} from '../../../../../@types/graphql.d';
import useCompanyUnique from '../../../hooks/useCompanyUnique/useCompanyUnique';
import { useCurrentRange } from '../../../hooks/useCurrentRange';
import CompanyAdminDashboardDonut from '../company-admin-dashboard-donut/CompanyAdminDashboardDonut';
import CompanyAdminDashboardCount from '../company-admin-dashboard-count/CompanyAdminDashboardCount';
import { GET_COMPANY_ATTRACTIVENESS_REPORT } from './CompanyAdminDashboardAttractivenessBloc.gql';
import translations from './CompanyAdminDashboardAttractivenessBloc.translations';

import styles from './CompanyAdminDashboardAttractivenessBloc.module.scss';
import { useIntl } from 'react-intl';
import { GET_COMPANY_AUTHORIZATION_DASHBOARD_QUERY } from '../company-admin-dashboard-activity-bloc/CompanyAdminDashboardActivityBloc.gql';

function CompanyAdminDashboardAttractivenessBloc(): JSX.Element {
  const intl = useIntl();
  const companyId = useCompanyUnique();
  const currentRange = useCurrentRange();
  const { data } = useQuery<Get_Company_Attractiveness_ReportQuery, Get_Company_Attractiveness_ReportQueryVariables>(
    GET_COMPANY_ATTRACTIVENESS_REPORT,
    {
      skip: !companyId,
      variables: {
        companyId: companyId || '',
        range: currentRange,
      },
      fetchPolicy: 'network-only',
    },
  );

  const { data: canAccess } = useQuery<
    GetCompanyAuthorizationDashboardQuery,
    GetCompanyAuthorizationDashboardQueryVariables
  >(GET_COMPANY_AUTHORIZATION_DASHBOARD_QUERY, {
    skip: !companyId,
    variables: { companyId: companyId! },
  });

  const isPremiumSchool = data?.companyReport?.isPremiumSchool;

  return (
    <div className={styles.attractivenessBloc}>
      <CompanyAdminDashboardCount
        data-testid="view-count"
        title={intl.formatMessage(translations.viewCountLabel)}
        tooltip={intl.formatMessage(translations.viewCountTooltip)}
        picto={({ className }) => (
          <EyeIcon
            className={classnames(styles.viewCountPicto, className)}
            visible={false}
          />
        )}
        value={data?.companyReport?.employeesViewCount || '-'}
        blurred={!canAccess?.company?.canShowPartialDashboardStats}
        className={styles.viewCount}
      />
      <CompanyAdminDashboardCount
        data-testid="unique-student-view-count"
        title={intl.formatMessage(translations.withUniqueStudent)}
        tooltip={intl.formatMessage(translations.withUniqueStudentTooltip)}
        value={data?.companyReport?.uniqueProfileViewsFromStudents || '-'}
        variant="grey"
        blurred={!canAccess?.company?.canShowPartialDashboardStats}
        className={styles.uniqueProfileViewsFromStudents}
      />

      <div className={styles.keyFiguresTitle}>{intl.formatMessage(translations.keyFigures)}</div>
      <CompanyAdminDashboardDonut
        data-testid="activity-sector"
        title={
          isPremiumSchool
            ? intl.formatMessage(translations.activitySchoolTypeLabel)
            : intl.formatMessage(translations.activitySectorLabel)
        }
        tooltip={
          isPremiumSchool
            ? intl.formatMessage(translations.activitySchoolTypeTooltip)
            : intl.formatMessage(translations.activitySectorTooltip)
        }
        value={data?.companyReport?.studentsSeeingThemselvesInTheSectorRate}
        blurred={!canAccess?.company?.canShowFullDashboardStats}
        className={styles.donut}
      />
      <CompanyAdminDashboardCount
        data-testid="wish-count"
        title={intl.formatMessage(translations.wishLabel)}
        tooltip={intl.formatMessage(translations.wishTooltip)}
        value={data?.companyReport?.wishCount || '-'}
        variant="blue"
        blurred={!canAccess?.company?.canShowFullDashboardStats}
        className={styles.wishCount}
      />
      <CompanyAdminDashboardCount
        data-testid="like-count"
        title={intl.formatMessage(translations.likeLabel)}
        tooltip={intl.formatMessage(translations.likeTooltip)}
        value={data?.companyReport?.likeCount || '-'}
        variant="blue"
        blurred={!canAccess?.company?.canShowFullDashboardStats}
        className={styles.likeCount}
      />
    </div>
  );
}

export default CompanyAdminDashboardAttractivenessBloc;
