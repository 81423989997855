import React from 'react';
import classnames from 'classnames';
import { CompanyEmployee, EmployeeStatus } from '../../../../../@types/graphql.d';
import translations from './EmployeeStatusDisplay.translations';
import styles from './EmployeeStatusDisplay.module.scss';
import { useIntl } from 'react-intl';

interface EmployeeStatusDisplayProps {
  testid: string;
  employee?: Pick<CompanyEmployee, 'lastInvitationEmailSentAt' | 'status' | 'visibleInSearch'>;
  className?: string;
}

function EmployeeStatusDisplay({ testid, employee, className }: EmployeeStatusDisplayProps) {
  const intl = useIntl();
  return (
    <p
      className={classnames(styles.employeeStatusDisplay, className)}
      data-testid={testid}
    >
      <span className={styles.searchVisibility}>
        {employee?.visibleInSearch
          ? intl.formatMessage(translations.visibleInSearch)
          : intl.formatMessage(translations.notVisibleInSearch)}
      </span>
      <span
        className={classnames(styles.status, {
          [styles[`${employee?.status.toLowerCase()}`]]: true,
        })}
      >
        <span className={styles.dot} />
        {intl.formatMessage(translations[employee?.status || EmployeeStatus.Invited], {
          lastInvitationEmailSentAt:
            employee?.lastInvitationEmailSentAt && intl.formatDate(employee?.lastInvitationEmailSentAt),
        })}
      </span>
    </p>
  );
}

export default EmployeeStatusDisplay;
