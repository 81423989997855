import React from 'react';
import SearchZoomInIcon from '../../../icons/component-icons/SearchZoomIn';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router';
import Routes from '../../../routes';
import { Get_My_Participations_ListQuery, Get_My_Participations_ListQueryVariables } from '../../../@types/backend-api';
import PageLoader from '../../common/page-loader/PageLoader';
import AffiliationItem from './component/affiliation-item/AffiliationItem';
import NoJoinedPrograms from './component/no-joined-programs/NoJoinedPrograms';
import { AffiliationListTranslations } from './StudentAffiliationList.translations';
import { GET_MY_PARTICIPATIONS_LIST } from './StudentAffiliationList.gql-backend-api';

import styles from './StudentAffiliationList.module.scss';
import { useIntl } from 'react-intl';

export default function StudentAffiliationList() {
  const intl = useIntl();
  const { data, loading } = useQuery<Get_My_Participations_ListQuery, Get_My_Participations_ListQueryVariables>(
    GET_MY_PARTICIPATIONS_LIST,
    {
      context: {
        clientName: 'backend-api',
      },
    },
  );

  if (!loading && !data?.me?.participations?.nodes.length) {
    return <NoJoinedPrograms />;
  }

  return (
    <main className={styles.main}>
      <section className={styles.studentAffiliationList}>
        {loading ? (
          <PageLoader />
        ) : (
          data?.me?.participations?.nodes.map((participation) => (
            <AffiliationItem
              key={participation.id}
              participation={participation}
            />
          ))
        )}
      </section>

      <Link
        className={styles.searchButton}
        to={Routes.search}
      >
        <SearchZoomInIcon />
        {intl.formatMessage(AffiliationListTranslations.search)}
      </Link>
    </main>
  );
}
