import React from 'react';
import EyeIcon from '../../../../../../../icons/component-icons/EyeIcon';
import PencilIcon from '../../../../../../../icons/component-icons/PencilIcon';
import classnames from 'classnames';
import { Link, generatePath, useMatch, useLocation } from 'react-router';
import Routes from '../../../../../../../routes';
import translations from './EmployeeVisualisationMode.translations';
import styles from './EmployeeVisualisationMode.module.scss';
import { useIntl } from 'react-intl';

const ACCOUNT_EDIT_PROFILE_URL = `${import.meta.env.REACT_APP_ACCOUNT_FRONTEND_URL}/company-admin/profile/`;

interface EmployeeVisualisationModeProps {
  companyid: string;
  employeeid: string;
  className?: string;
}

function EmployeeVisualisationMode({ companyid, employeeid, className }: EmployeeVisualisationModeProps) {
  const intl = useIntl();
  const isMjgAdmin: boolean = !!useMatch(Routes.mjgAdminBase);
  const { pathname } = useLocation();
  const isEditing: boolean =
    pathname === Routes.companyAdminEditAmbassador || pathname === Routes.mjgAdminCompaniesEditEmployee;
  if (isEditing) {
    return (
      <Link
        to={generatePath(isMjgAdmin ? Routes.mjgAdminCompaniesShowEmployee : Routes.companyAdminShowAmbassador, {
          companyid,
          employeeid,
        })}
        className={classnames(styles.previewLink, className)}
      >
        <EyeIcon />
        {intl.formatMessage(translations.previewTheProfile)}
      </Link>
    );
  }
  return (
    <a
      href={`${ACCOUNT_EDIT_PROFILE_URL}${employeeid}`}
      className={classnames(styles.editLink, className)}
    >
      <PencilIcon />
      {intl.formatMessage(translations.editTheProfile)}
    </a>
  );
}

export default EmployeeVisualisationMode;
