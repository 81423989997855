import { useIntl } from 'react-intl';
import translations from './InternshipsDisplayListItem.translations';

/**
 * Return month trad or undefined
 * @param {*} value
 * @return {String}
 */
export const getMonth = (value) => {
  const intl = useIntl();
  if (!value) {
    return null;
  }
  const monthNumber = /^([0-9]{1,2})/.exec(value);
  return monthNumber
    ? intl.formatMessage(translations.month, { month: monthNumber[0] })
    : intl.formatMessage(translations.undetermined);
};
