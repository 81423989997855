import { ComponentType } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';

import { FormikProps } from 'formik';

import { ConversationTypeKeyEnum, GradeKeyEnum } from '../../../@types/graphql.d';
import { NewConversationInterlocutor, PerceptionQuestion } from './NewConversation.gql';
import { MessageCreationSection } from './sections/MessageCreationSection';
import { MessagePreviewWithPerceptionSection } from './sections/MessagePreviewWithPerceptionSection';
import { SignUpSection } from './sections/SignUpSection';

export const STEPS_TRANSLATIONS = defineMessages({
  writeMessage: {
    id: 'NewConversation.Step.writeMessage',
    defaultMessage: 'Écrire mon message',
  },
  previewAndSendMessage: {
    id: 'NewConversation.Step.previewAndSendMessage',
    defaultMessage: 'Prévisualiser et finaliser l’envoi',
  },
  sendMessage: {
    id: 'NewConversation.Step.sendMessage',
    defaultMessage: 'Envoyer mon message',
  },
  signIn: {
    id: 'NewConversation.Step.signIn',
    defaultMessage: 'Me connecter',
  },
});

export type GroupOptions = {
  appointmentType: ConversationTypeKeyEnum;
  girlCount?: number;
  boyCount?: number;
  accompaniedStudyLevels?: GradeKeyEnum[];
  supportPrograms?: string[];
};

export type FormikValues = {
  interlocutorId: string;
  messageParts: string[];
  message: string;
  group?: GroupOptions;
  cantContactError?: string;
};

export type StepProps = {
  step: number;
  formik: FormikProps<FormikValues>;
  cantContactDueToGroupPreference: boolean;
  fromWidget?: boolean;
  interlocutor: NewConversationInterlocutor;
  perception?: PerceptionQuestion | null;
  setAnswers: (a: any) => void;
  nextStep: () => void;
};

type Flow = 'normal' | 'widget';

type Step = {
  [key in Flow]: {
    title: MessageDescriptor;
    component: ComponentType<StepProps>;
  }[];
};

// prettier-ignore
export const STEPS: Step = {
  'normal': [
    { title: STEPS_TRANSLATIONS.writeMessage, component: MessageCreationSection  },
    { title: STEPS_TRANSLATIONS.previewAndSendMessage, component: MessagePreviewWithPerceptionSection }
  ],
  'widget': [
    { title: STEPS_TRANSLATIONS.writeMessage, component: MessageCreationSection  },
    { title: STEPS_TRANSLATIONS.signIn, component: SignUpSection },
    { title: STEPS_TRANSLATIONS.previewAndSendMessage, component: MessagePreviewWithPerceptionSection }
  ]
}
