import { gql } from '@apollo/client';

export const GET_ALLOWED_EMPLOYEE_ACTIONS_AND_STATUS = gql`
  query GET_ALLOWED_EMPLOYEE_ACTIONS_AND_STATUS($employeeId: ID!) {
    companyEmployee(employeeId: $employeeId) {
      userId
      canPublish
      canApprove
      canUnpublish
      canArchive
      lastInvitationEmailSentAt
      visibleInSearch
      status
    }
  }
`;
