import { defineMessages } from 'react-intl';
import { Month } from '../utils/date';

const MonthsTranslations = defineMessages({
  JANUARY: {
    id: 'MonthsTranslations.January',
    defaultMessage: 'Janvier',
  },
  FEBRUARY: {
    id: 'MonthsTranslations.February',
    defaultMessage: 'Février',
  },
  MARCH: {
    id: 'MonthsTranslations.March',
    defaultMessage: 'Mars',
  },
  APRIL: {
    id: 'MonthsTranslations.April',
    defaultMessage: 'Avril',
  },
  MAY: {
    id: 'MonthsTranslations.May',
    defaultMessage: 'Mai',
  },
  JUNE: {
    id: 'MonthsTranslations.June',
    defaultMessage: 'Juin',
  },
  JULY: {
    id: 'MonthsTranslations.July',
    defaultMessage: 'Juillet',
  },
  AUGUST: {
    id: 'MonthsTranslations.August',
    defaultMessage: 'Août',
  },
  SEPTEMBER: {
    id: 'MonthsTranslations.September',
    defaultMessage: 'Septembre',
  },
  OCTOBER: {
    id: 'MonthsTranslations.October',
    defaultMessage: 'Octobre',
  },
  NOVEMBER: {
    id: 'MonthsTranslations.November',
    defaultMessage: 'Novembre',
  },
  DECEMBER: {
    id: 'MonthsTranslations.December',
    defaultMessage: 'Décembre',
  },
});

function getMonthTranslationIdFromEnumIndex(month: number) {
  return (
    Object.values(MonthsTranslations).find(
      (value) => value.id === `MonthsTranslations.${Object.values(Month)[month]}`,
    ) || MonthsTranslations.JANUARY
  );
}

export { MonthsTranslations, getMonthTranslationIdFromEnumIndex };
