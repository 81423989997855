import React from 'react';
import { Outlet } from 'react-router';

import { SnackbarProvider } from 'notistack';

import ModalContextProvider from '../components/modal/Modal.context';
import Layout from '../containers/layout/Layout';

export const UnconnectedRouterWrapper = () => (
  <ModalContextProvider>
    <Layout connected={false}>
      <SnackbarProvider
        maxSnack={5}
        hideIconVariant
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <Outlet />
      </SnackbarProvider>
    </Layout>
  </ModalContextProvider>
);
