import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'CompanyAdminAppointmentsHeader.title',
    defaultMessage: 'Rendez-vous ({total})',
  },
  subtitle: {
    id: 'CompanyAdminAppointmentsHeader.subtitle',
    defaultMessage:
      "Les rendez-vous réalisés par vos Ambassadeurs apparaissent dans ce tableau dès qu'ils ont été évalués. Le nombre affiché ici peut donc différer du nombre de RDV réalisés affiché sur le dashboard.",
  },
  fromTo: {
    id: 'CompanyAdminAppointmentsHeader.fromTo',
    defaultMessage: 'Du {from} au {to}',
  },
});
