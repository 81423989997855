import { defineMessages } from 'react-intl';

export const AvailabilityTranslations = defineMessages({
  title: {
    id: 'Availability.title',
    defaultMessage: 'Visibilité de votre profil',
  },
  titleMember: {
    id: 'Availability.titleMember',
    defaultMessage: 'Prises de contact restantes',
  },
  remainingAppointments: {
    id: 'Availability.remainingAppointments',
    defaultMessage: 'Rendez-vous restants',
  },
  available: {
    id: 'Availability.available',
    defaultMessage: 'Disponible',
  },
  availableDescription: {
    id: 'Availability.availableDescription',
    defaultMessage: 'Votre profil est actuellement <strong>disponible</strong>.',
  },
  unavailable: {
    id: 'Availability.unavailable',
    defaultMessage: 'Indisponible',
  },
  unpublished: {
    id: 'Availability.unpublished',
    defaultMessage: 'Votre profil est dépublié',
  },
  unpublishedDescription: {
    id: 'Availability.unpublishedDescription',
    defaultMessage: 'Pour que votre profil soit à nouveau visible et accessible aux membres, vous devez vous rendre dans votre profil et cliquer sur le bouton <strong>"Demander la publication"</strong>. Une fois votre demande envoyée, votre profil sera revérifié avant d’être publié.'
  },
  underModeration: {
    id: 'Availability.underModeration',
    defaultMessage: 'Profil en cours de modération',
  },
  underModerationDescription: {
    id: 'Availability.underModerationDescription',
    defaultMessage: 'Votre profil est actuellement en cours de modération par nos équipes support. Vous recevrez un email dès qu’il sera publié !',
  },
  noMeetingsLeftDescription: {
    id: 'Availability.noMeetingsLeftDescription',
    defaultMessage: 'Votre profil est actuellement <strong>indisponible jusqu\'au {date}</strong> car vous avez atteint votre limite de rendez-vous.',
  },
  noContactsLeftDescription: {
    id: 'Availability.noContactsLeftDescription',
    defaultMessage: 'Votre profil est actuellement <strong>indisponible jusqu\'au {date}</strong> car vous avez atteint votre quota de sollicitations. Ce nombre est calculé en fonction de la limite de rendez-vous que vous avez définie par mois.',
  },
  inHibernationDescription: {
    id: 'Availability.inHibernationDescription',
    defaultMessage: 'Votre profil est actuellement <strong>indisponible jusqu\'au {date}</strong> car vous êtes en hibernation',
  },
  lowResponseRateDescription: {
    id: 'Availability.lowResponseRateDescription',
    defaultMessage: 'Votre profil est actuellement <strong>indisponible</strong> car votre taux de réponse aux messages est en dessous de 50%. Afin de redevenir disponible, vous devez répondre à vos messages',
  },
  learnMore: {
    id: 'Availability.learnMore',
    defaultMessage: 'En savoir plus sur les disponibilités',
  },
  editHibernation: {
    id: 'Availability.editHibernation',
    defaultMessage: 'Modifier mon hibernation',
  },
  answerMessages: {
    id: 'Availability.answerMessages',
    defaultMessage: 'Répondre à mes messages',
  },
});
