import { gql } from '@apollo/client';

import { GetDraftListQuery } from '../../../@types/graphql';

export type Draft = NonNullable<NonNullable<GetDraftListQuery['conversationsDrafts']>['nodes'][0]>;

export const GET_DRAFT_LIST = gql`
  query GetDraftList($before: String, $after: String) {
    conversationsDrafts(first: 8, before: $before, after: $after) {
      nodes {
        id
        message
        recipient {
          id
          firstName
          avatars {
            url
          }
          primarySituation {
            key
            name
          }
          secondarySituation {
            key
            name
          }
          companyName
          jobTitle
          currentSchoolName
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        startCursor
        hasPreviousPage
      }
    }
  }
`;
