import React from 'react';
import { IconProps } from './Icon';

export default function SwitchIcon({ className }: IconProps) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      className={className}
    >
      <path
        strokeWidth="0.1"
        d="M15.6419268,8.2451172l-1.7675781-2c-0.0032959-0.0036621-0.0083008-0.0047607-0.0117188-0.0083008
        c-0.0911865-0.0977173-0.2188721-0.1606445-0.3632813-0.1606445s-0.2720947,0.0629272-0.3632813,0.1606445
        c-0.003418,0.00354-0.0084229,0.0046387-0.0117188,0.0083008l-1.7675781,2
        c-0.1826172,0.2070313-0.1630859,0.5224609,0.0439453,0.7060547c0.2050781,0.1816406,0.5205078,0.1640625,0.7060547-0.0439453
        l0.8925781-1.0102539V12.75c0,1.2402344-1.0097656,2.25-2.25,2.25c-1.197876,0-2.1716309-0.944458-2.2375488-2.1262207
        L8.4993486,3.25c0-1.7919922-1.4570308-3.25-3.249023-3.25S2.0003254,1.4580078,2.0003254,3.2539063l0.0285645,3.9642334
        L1.1458334,6.21875C0.9602864,6.0097656,0.6448568,5.9921875,0.4397787,6.1748047
        C0.2327474,6.3583984,0.2132161,6.6738281,0.3958333,6.8808594l1.767578,2
        C2.1670735,8.8850708,2.1728108,8.8865967,2.176595,8.890625c0.0332031,0.0351563,0.0737305,0.06073,0.1157227,0.085144
        C2.3086751,8.9851685,2.3216145,8.9993286,2.3389485,9.006897c0.0612793,0.0268555,0.1282959,0.0429077,0.1994629,0.0429077
        c0.0009766,0,0.0019531,0,0.0039063,0c0.0705566-0.0004883,0.137207-0.0164185,0.197876-0.043396
        c0.013916-0.0061646,0.024292-0.0183105,0.0374756-0.0256958C2.8233235,8.9552612,2.8666584,8.927124,2.9019368,8.8892212
        c0.003418-0.00354,0.0083008-0.0047607,0.0114746-0.0083618l1.7685549-2
        C4.8645835,6.6738281,4.8450522,6.3583984,4.638021,6.1748047C4.4329429,5.9921875,4.1165366,6.0097656,3.9319661,6.21875
        L3.029012,7.2400513L3.0003254,3.25c0-1.2402344,1.0097659-2.25,2.2500002-2.25s2.2490234,1.0097656,2.2490234,2.2509766
        l0.012207,9.4380493c-0.0026855,0.0209351,0.0093389,0.2387943,0.0257568,0.4364014
        C7.7261558,14.7390747,9.0858965,16,10.7493486,16c1.7919922,0,3.25-1.4580078,3.25-3.25V7.8969727l0.8925781,1.0102539
        c0.0996094,0.1123047,0.2373047,0.1689453,0.375,0.1689453c0.1181641,0,0.2363281-0.0410156,0.3310547-0.125
        C15.8050127,8.7675781,15.824544,8.4521484,15.6419268,8.2451172z"
      />
    </svg>
  );
}
