import { defineMessages } from 'react-intl';

export const DashboardTabsTranslations = defineMessages({
  member: {
    id: 'DashboardTabs.member',
    defaultMessage: 'Vue Membre',
  },
  ambassador: {
    id: 'DashboardTabs.ambassador',
    defaultMessage: 'Vue Ambassadeur',
  },
});
