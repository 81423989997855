import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Element } from 'react-scroll';

import './autosubmit-section.scss';

type AutosubmitSectionProps = PropsWithChildren<{
  title?: string;
  description?: string;
  className?: string;
  fieldsetName: string;
  valid?: boolean;
  invalid?: boolean;
}>;

const AutosubmitSection = ({
  title,
  description,
  children,
  className,
  fieldsetName,
  valid,
  invalid,
}: AutosubmitSectionProps) => (
  <Element name={fieldsetName}>
    <section
      className={classNames(
        {
          'section-valid': valid,
          'section-error': invalid,
          'autosubmit-section': true,
        },
        className,
      )}
      id={fieldsetName}
    >
      <header>
        <h2>{title}</h2>
        {description && <p className="help-info">{description}</p>}
      </header>
      <article>{children}</article>
    </section>
  </Element>
);

export default AutosubmitSection;
