import React, { useContext, useState } from 'react';
import { defineMessages } from 'react-intl';
import EditForm from '../../../hoc/form-section-wrapper';
import { useIntl } from 'react-intl';

import './AccountEditLogin.scss';
import TextInput from '../../../components/text-input/TextInput';
import { useFormik } from 'formik';
import { CurrentUserContext } from '../../../contexts/current-user-context/CurrentUserContext';

const translations = defineMessages({
  email: {
    id: 'AccountEditLogin.email',
    defaultMessage: 'E-mail',
    description: 'Page modification de compte - Modification du bloc connexion',
  },
  confirmPassword: {
    id: 'AccountEditLogin.confirmPassword',
    defaultMessage: 'Entrez votre mot de passe pour pouvoir changer votre adresse mail',
    description: 'Page modification de compte - Modification du bloc connexion',
  },
  title: {
    id: 'AccountEditLogin.title.connexion',
    defaultMessage: "Changement d'adresse mail",
    description: 'Page modification de compte - Modification du bloc connexion',
  },
});

type Values = {
  email: string;
  current_password: string;
};

type AccountEditLoginProps = {
  onUpdateValues: (values: Values) => void;
};

const AccountEditLogin = ({ onUpdateValues }: AccountEditLoginProps) => {
  const intl = useIntl();
  const { currentUser } = useContext(CurrentUserContext);

  const [isEditable, setEditable] = useState(false);

  const formik = useFormik<Values>({
    initialValues: {
      email: currentUser?.email || '',
      current_password: '',
    },
    onSubmit: onUpdateValues,
  });

  return (
    <EditForm
      id="account-edit-login"
      title={intl.formatMessage(translations.title)}
      isEditable={isEditable}
      handleEditableChange={setEditable}
      handleSubmit={formik.handleSubmit}
      className="account-edit-login"
    >
      <div className="account-edit-login-wrapper">
        <div className="field">
          <TextInput
            name="email"
            label={intl.formatMessage(translations.email)}
            placeholder="joel@gmail.com"
            disabled={!isEditable}
            value={formik.values.email}
            onChange={formik.handleChange}
          />
        </div>
        <div className="field">
          <TextInput
            name="current_password"
            type="password"
            label={intl.formatMessage(translations.confirmPassword)}
            placeholder="*****"
            disabled={!isEditable}
            value={formik.values.current_password}
            onChange={formik.handleChange}
          />
        </div>
      </div>
    </EditForm>
  );
};

export default AccountEditLogin;
