import React from 'react';
import classnames from 'classnames';
import translations from './ProfessionalAvailable.translations';

import styles from './ProfessionalAvailable.module.scss';
import { useIntl } from 'react-intl';

interface ProfessionalAvailableProps {
  className?: string;
  isMentor?: boolean;
}
function ProfessionalAvailable({ className, isMentor }: ProfessionalAvailableProps) {
  const intl = useIntl();
  return (
    <div className={`${className} ${isMentor ? styles.mentorAvailable : styles.professionalAvailable}`}>
      <span className={classnames(styles.illustration)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 24 24"
          x="0px"
          y="0px"
          className="mjg-svgs-defs"
        >
          <path
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            d="M20,0H4A4,4,0,0,0,0,4V16a4,4,0,0,0,4,4H6.9l4.451,3.763a1,1,0,0,0,1.292,0L17.1,20H20a4,4,0,0,0,4-4V4A4,4,0,0,0,20,0ZM7,5h5a1,1,0,0,1,0,2H7A1,1,0,0,1,7,5ZM17,15H7a1,1,0,0,1,0-2H17a1,1,0,0,1,0,2Zm0-4H7A1,1,0,0,1,7,9H17a1,1,0,0,1,0,2Z"
          />
        </svg>
      </span>
      <span className={styles.text}>{intl.formatMessage(translations.available)}</span>
    </div>
  );
}

export default ProfessionalAvailable;
