import React from 'react';

import { useQuery } from '@apollo/client';
import UserIcon from '../../../../../icons/component-icons/UserIcon';
import { AmbassadorPresentationQuery, AmbassadorPresentationQueryVariables } from '../../../../../@types/graphql.d';
import Card, { CardLoader } from '../../../../../components/card/Card';
import { PRESENTATION_QUERY } from './Presentation.gql';
import './Presentation.scss';
import { AmbassadorViewPresentationTranslations } from './Presentation.translations';
import Skeleton from 'react-loading-skeleton';
import AlertCircleIcon from '../../../../../icons/component-icons/AlertCircleIcon';
import { useIntl } from 'react-intl';

type PresentationProps = {
  id: string;
};

export default function Presentation({ id }: PresentationProps) {
  const intl = useIntl();
  const { data, loading } = useQuery<AmbassadorPresentationQuery, AmbassadorPresentationQueryVariables>(
    PRESENTATION_QUERY,
    { variables: { id }, fetchPolicy: 'network-only' },
  );

  if (loading) return <PresentationLoader />;
  if (!data?.getUser) return null;

  const {
    ambassadorPresentation,
    interestsPresentation,
    aspirationPresentation,
    expertisePresentation,
    onlyFrenchWarning,
    onlyFrenchWarningText,
  } = data.getUser;

  const displayedPresentation = [
    ambassadorPresentation,
    interestsPresentation,
    aspirationPresentation,
    expertisePresentation,
  ]
    .filter((e) => e?.length)
    .join('\n\n');

  return (
    <section className="ambassador-view-presentation">
      <Card
        icon={UserIcon}
        title={intl.formatMessage(AmbassadorViewPresentationTranslations.title)}
      >
        {onlyFrenchWarning && (
          <div className="ambassador-view-presentation__only-french-warning">
            <AlertCircleIcon className="ambassador-view-presentation__only-french-warning__icon" />
            <p>{onlyFrenchWarningText}</p>
          </div>
        )}
        <p className="ambassador-view-presentation__presentation-paragraph">{displayedPresentation}</p>
      </Card>
    </section>
  );
}

export const PresentationLoader = () => (
  <section className="ambassador-view-presentation">
    <CardLoader>
      <Skeleton count={12} />
    </CardLoader>
  </section>
);
