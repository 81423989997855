import { defineMessages } from 'react-intl';

export default defineMessages({
  titlePlaceholder: {
    id: 'EditTemplateForm.titlePlaceholder',
    defaultMessage: 'Nom du nouveau template',
    description: 'placeholder du champs de titre de template',
  },
  requiredTitle: {
    id: 'EditTemplateForm.requiredTitle',
    defaultMessage: 'Vous devez compléter le titre du template pour valider.',
    description: 'error du champs de titre de template',
  },
  descriptionPlaceholder: {
    id: 'EditTemplateForm.descriptionPlaceholder',
    defaultMessage: 'Écrivez votre message ici.',
    description: 'placeholder du champs de titre de template',
  },
  requiredDescription: {
    id: 'EditTemplateForm.requiredDescription',
    defaultMessage: 'Vous devez écrire un message pour valider.',
    description: 'error du champs de titre de template',
  },
  saveButton: {
    id: 'EditTemplateForm.saveButton',
    defaultMessage: 'Enregistrer',
    description: 'save button on template form',
  },
  insertButton: {
    id: 'EditTemplateForm.insertButton',
    defaultMessage: 'Utiliser',
    description: 'save button on template form',
  },
});
