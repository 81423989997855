import { useLocation, matchPath } from 'react-router';
import Routes from '../../../../routes';

export default function useConversationIdFromRoute(): string | undefined {
  const location = useLocation();

  const routes = [
    Routes.downloadConversationAppointmentIcalendar,
    Routes.bookAppointment,
    Routes.claimAppointment,
    Routes.updateAppointment,
    Routes.deleteAppointment,
    Routes.conversationReportInterlocutor,
    Routes.conversation,
  ];

  for (const path of routes) {
    const match = matchPath({ path, end: true }, location.pathname);
    if (match?.params?.id) {
      return match.params.id;
    }
  }

  return undefined;
}
