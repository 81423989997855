import React, { useCallback, useEffect, useState } from 'react';
import ChevronLeftIcon from '../../../../../icons/component-icons/ChevronLeftIcon';
import { SearchFilter } from '../../../../../scenes/search/Search.model';
import { FacetTypeTranslations } from '../../../../../scenes/search/search.translations';
import { FilterButtonTranslations } from '../../../../../scenes/search/filter-button/FilterButton.translations';
import TextInput from '../../../../../components/text-input/TextInput';
import CheckboxInput from '../../../../../components/checkbox-input/CheckboxInput';
import {
  Bucket,
  FacetKeyEnum,
  SearchSchoolNamesFacetQuery,
  SearchSchoolNamesFacetQueryVariables,
  SearchTypeEnum,
} from '../../../../../@types/graphql.d';
import '../FiltersDropdownPanel.scss';
import { useQuery } from '@apollo/client';
import { SEARCH_SCHOOL_NAMES_FACET } from '../../../search.gql';
import { useIntl } from 'react-intl';
import useDebounce from '../../../../../hooks/debounce.hook';

type FiltersDropdownPanelProps = {
  searchType: SearchTypeEnum;
  facetType: FacetKeyEnum;
  buckets: Bucket[];
  filters?: SearchFilter;
  onFilterChange: (filter: SearchFilter) => void;
  onClose: () => void;
  withSearch?: boolean;
  sort?: boolean;
};

export default function FiltersDropdownPanel({
  searchType,
  facetType,
  buckets,
  filters: parentFilters,
  onFilterChange,
  onClose,
  withSearch,
  sort,
}: FiltersDropdownPanelProps) {
  const intl = useIntl();
  const [term, setTerm] = useState('');
  const [debouncedTerm] = useDebounce(term);
  const [filters, setFilters] = useState<string[]>(parentFilters?.buckets || []);

  const [bucketsSearch, setBucketsSearch] = useState<Bucket[]>([]);

  const { data: facets } = useQuery<SearchSchoolNamesFacetQuery, SearchSchoolNamesFacetQueryVariables>(SEARCH_SCHOOL_NAMES_FACET, {
    variables: {
      facetQuery: debouncedTerm,
      searchMode: searchType,
    },
    fetchPolicy: 'no-cache',
    skip: !debouncedTerm,
  });

  useEffect(() => {
    setFilters(parentFilters?.buckets || []);
  }, [parentFilters?.buckets]);

  const addFilter = useCallback(
    (bucket: string) => {
      setFilters((prev) => [...prev, bucket]);
    },
    [filters, setFilters],
  );

  const removeFilter = useCallback(
    (bucket: string) => {
      setFilters((prev) => prev.filter((b) => b !== bucket));
    },
    [filters],
  );

  const onApply = useCallback(() => {
    onFilterChange({ facetType, buckets: filters });
    onClose();
  }, [filters]);

  const onClear = useCallback(() => {
    onFilterChange({ facetType, buckets: [] });
  }, [filters]);

  let initialBuckets = sort
    ? [...buckets].sort((a, b) => (a.label || a.key).localeCompare(b.label || b.key))
    : buckets;

  initialBuckets = [
    ...initialBuckets.filter(b => filters.includes(b.key)),
    ...initialBuckets.filter(b => !filters.includes(b.key)),
  ]

  useEffect(() => {
    let searchBuckets = facets?.searchSchoolNamesFacet || [];
    searchBuckets = sort
      ? [...searchBuckets].sort((a, b) => b.count - a.count)
      : searchBuckets;
    searchBuckets = [
      ...searchBuckets.filter(b => filters.includes(b.key)),
      ...searchBuckets.filter(b => !filters.includes(b.key)),
    ]
    setBucketsSearch(searchBuckets.map(b => ({ key: b.key, docCount: b.count })));
  }, [facets, filters]);

  return (
    <div className="filters-dropdown-panel">
      <div className="filters-dropdown-panel__header">
        <button
          type="button"
          className="filters-dropdown-panel__header__title"
          onClick={onClose}
        >
          <ChevronLeftIcon className="filters-dropdown-panel__header__title__icon" />
          {intl.formatMessage(FacetTypeTranslations[facetType])}
        </button>
        {withSearch && (
          <TextInput
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            className="filter-button__dropdown__search__input"
            placeholder={intl.formatMessage(FilterButtonTranslations.search, {
              facet: intl.formatMessage(FacetTypeTranslations[facetType]).toLowerCase(),
            })}
          />
        )}
      </div>

      <div className="filters-dropdown-panel__items">
        {(term.length > 0 ? bucketsSearch || initialBuckets : initialBuckets)
          .map(({ key, label }) => (
            <div
              key={key}
              className="filters-dropdown-panel__items__item"
            >
              <CheckboxInput
                name={key}
                label={label || key}
                checked={filters.includes(key)}
                onChange={() => {
                  if (filters.includes(key)) {
                    removeFilter(key);
                  } else {
                    addFilter(key);
                  }
                }}
              />
            </div>
          ))}
      </div>

      <div className="filters-dropdown-panel__buttons">
        <button
          type="button"
          className="filter-button__dropdown__buttons__clear"
          onClick={onClear}
        >
          {intl.formatMessage(FilterButtonTranslations.clear)}
        </button>
        <button
          type="button"
          className="filter-button__dropdown__buttons__apply"
          onClick={onApply}
        >
          {intl.formatMessage(FilterButtonTranslations.apply)}
        </button>
      </div>
    </div>
  );
}
