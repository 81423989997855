import React from 'react';
import moment from 'moment-timezone';
import TickIcon from '../../../../../../../icons/component-icons/TickIcon';
import translations from './ProfessionalAvailable.translations';

import './ProfessionalAvailable.scss';
import { useIntl } from 'react-intl';
import CheckCircleIcon from '../../../../../../../icons/component-icons/CheckCircleIcon';

export type ProfessionalAvailableProps = {
  interlocutor: {
    meetingsLeft: number;
  };
};

function ProfessionalAvailable({ interlocutor }: ProfessionalAvailableProps) {
  const intl = useIntl();
  return (
    <div className="professional-available">
      <aside className="professional-avaible-mark">
        <CheckCircleIcon />
      </aside>
      <div className="professional-available-content">
        <span className="professional-available-status">{intl.formatMessage(translations.status)}</span>
        <span className="professional-available-date">
          {intl.formatMessage(translations.datePartial, {
            month: intl.formatDate(moment(), { month: 'long' }),
          })}
        </span>
      </div>
      <footer className="professional-available-footer">
        {intl.formatMessage(translations.meetingsLeft, {
          amount: interlocutor?.meetingsLeft,
        })}
      </footer>
    </div>
  );
}

export default ProfessionalAvailable;
