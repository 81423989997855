import React, { memo, useContext } from 'react';
import { Navigate } from 'react-router';

import { useQuery } from '@apollo/client';

import './AmbassadorView.scss';

import { GetUserIdFromProfessionalQuery, GetUserIdFromProfessionalQueryVariables } from '../../../@types/graphql.d';
import { CurrentUserContext } from '../../../contexts/current-user-context/CurrentUserContext';
import { useResponsiveComponent } from '../../../hooks/responsive-component.hook';
import Routes from '../../../routes';
import PageLoader from '../../../scenes/common/page-loader/PageLoader';
import { GET_USER_ID_FROM_PROFESSIONAL_QUERY } from './AmbassadorView.gql';
import CompanyPresentation from './sections/company-presentation/CompanyPresentation';
import Contact from './sections/contact/Contact';
import FurtherInformations from './sections/further-informations/FurtherInformations';
import Gallery from './sections/gallery/Gallery';
import Identity from './sections/identity/Identity';
import Presentation from './sections/presentation/Presentation';
import SimilarPros from './sections/similar-pros/SimilarPros';

type AmbassadorViewProps = {
  professionalId: string;
  companyAdminPreview?: boolean;
};

export default function AmbassadorView({ professionalId }: AmbassadorViewProps) {
  const ResponsiveComponent = useResponsiveComponent({
    desktop: Desktop,
    mobile: Mobile,
  });

  const { data, loading } = useQuery<GetUserIdFromProfessionalQuery, GetUserIdFromProfessionalQueryVariables>(
    GET_USER_ID_FROM_PROFESSIONAL_QUERY,
    { variables: { professionalId } },
  );

  if (loading) return <PageLoader />;
  if (!data?.professionalProfile?.userId) return null;
  // Prevent access to unpublished profiles expect for previewing current user ambassador view
  if (!data?.professionalProfile?.user?.currentUserAuthorizations.canViewAmbassadorPage)
    return <Navigate to={Routes.dashboard} />;

  const { userId, unavailableUntil } = data?.professionalProfile;

  return (
    <ResponsiveComponent
      userId={userId}
      professionalId={professionalId}
      isUnavailable={!!unavailableUntil}
    />
  );
}

type PlatformViewProps = {
  userId: string;
  professionalId: string;
  isUnavailable: boolean;
};

const Desktop = memo(({ userId, professionalId, isUnavailable }: PlatformViewProps) => (
  <div className="ambassador-view">
    <Identity id={userId} />
    <Contact id={userId} />
    <div className="ambassador-view__presentations">
      <Presentation id={userId} />
      <CompanyPresentation id={userId} />
      <Gallery id={userId} />
    </div>
    <FurtherInformations id={userId} />
    {isUnavailable && <SimilarPros userId={userId} />}
  </div>
));

const Mobile = memo(({ userId, professionalId, isUnavailable }: PlatformViewProps) => (
  <div className="ambassador-view">
    <Identity id={userId} />
    <Contact id={userId} />
    <Presentation id={userId} />
    <FurtherInformations id={userId} />
    <CompanyPresentation id={userId} />
    <Gallery id={userId} />
    {isUnavailable && <SimilarPros userId={userId} />}
  </div>
));
