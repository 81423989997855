import React from 'react';
import classnames from 'classnames';

import styles from '../../ProfessionalCard.module.scss';

function ProfessionalCardSkeleton() {
  return (
    <div className={classnames(styles.professionalCard, styles.professionalCardSkeleton)}>
      <div className={styles.header}>
        <div className={styles.avatarBackgroundWrapper}>
          <div className={styles.avatarBackground} />
        </div>
        <div className={styles.headerContent}>
          <div className={styles.avatar} />
          <div className={styles.firstName} />
          <div className={styles.companyName} />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.jobTitle} />
        <div className={styles.companyLogo} />
      </div>
    </div>
  );
}

export default ProfessionalCardSkeleton;
