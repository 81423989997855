import { defineMessages } from 'react-intl';

export const UpcomingAppointmentsTranslations = defineMessages({
  title: {
    id: 'UpcomingAppointments.title',
    defaultMessage: 'Prochains rendez-vous',
  },
  noAppointment: {
    id: 'UpcomingAppointments.noAppointment',
    defaultMessage: 'Aucun rendez-vous prévu pour l’instant',
  },
});
