import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'NoJoinedPrograms.title',
    defaultMessage: 'Aucun programme rejoint',
  },
  button: {
    id: 'NoJoinedPrograms.button',
    defaultMessage: 'Découvrir les programmes d’orientation',
  },
});
