import { createContext } from 'react';

interface AppointmentContextValues {
  canGoBack: boolean;
  canGoFurther: boolean;
  tableNavigation: (operand: number) => void;
}
export default createContext<AppointmentContextValues>({
  canGoBack: false,
  canGoFurther: false,
  tableNavigation: () => null,
});
