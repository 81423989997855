import { defineMessages } from 'react-intl';

export const AccountHibernationTranslations = defineMessages({
  sectionTitle: {
    id: 'AccountHibernation.sectionTitle',
    defaultMessage: 'Hibernation',
    description: 'Titre de la section hibernation',
  },
  errorNotAllFilledField: {
    id: 'AccountHibernation.errorNotAllFilledField',
    defaultMessage: 'Merci d’ajouter la date et l’heure souhaitées pour le début et la fin de l’hibernation',
    description: 'Toastr d erreur à la tentative de création d hibernation sans tous les champs',
  },
  sectionDescription: {
    id: 'AccountHibernation.sectionDescription',
    defaultMessage:
      "En faisant entrer votre profil en hibernation (pour une période maximum de 2 mois), vous ne serez plus contactable par de nouveaux Membres sur la plateforme. Vous pourrez toujours recevoir des messages d'anciens Membres avec qui vous avez échangé dans le passé.",
    description: 'Description en header de la section hibernation',
  },
  tooBroadError: {
    id: 'AccountHibernation.toastr.tooBroadError',
    defaultMessage: 'Désolé, vous ne pouvez pas hiberner pour une durée supérieure à 2 mois.',
    description: 'Erreur lorsque l hibernation est trop longue (>60jours)',
  },
  incoherentTimeline: {
    id: 'AccountHibernation.toastr.incoherentTimeline',
    defaultMessage: 'Veuillez choisir des dates dans le futur, avec une date de début précédant la date de fin',
    description: 'Erreur lorsque l hibernation est trop longue (>60jours)',
  },
  icedUpProfessionalError: {
    id: 'AccountHibernation.toastr.icedUpProfessionalError',
    defaultMessage:
      'Désolé, vous ne pouvez pas hiberner car votre profil a été dépublié. Répondez aux Membres pour être re-publié et pouvoir hiberner.',
    description: 'Erreur de tentative d hibernation en etant gelée',
  },
  unpublishedError: {
    id: 'AccountHibernation.toastr.unpublishedError',
    defaultMessage:
      'Désolé, vous ne pouvez pas hiberner car votre profil a été dépublié. Votre profil doit être re-publié pour pouvoir hiberner.',
    description: 'Erreur de tentative d hibernation en étant supprimé',
  },
  unknown: {
    id: 'AccountHibernation.toastr.unknown',
    defaultMessage: 'Désolé, une erreur est survenue. Merci de réessayer.',
    description: 'Erreur de tentative d hibernation en étant supprimé',
  },
  startDatetimeLabel: {
    id: 'AccountHibernation.startDatetimeLabel',
    defaultMessage: 'Date de début',
    description: "Label pour la date de début d'hibernation",
  },
  endDatetimeLabel: {
    id: 'AccountHibernation.endDatetimeLabel',
    defaultMessage: 'Date de fin',
    description: "Label pour la date de fin d'hibernation",
  },
  startHourInputLabel: {
    id: 'AccountHibernation.startHourInputLabel',
    defaultMessage: 'Heure de début',
    description: "Label pour le timepicker de l'heure de début",
  },
  endHourInputLabel: {
    id: 'AccountHibernation.endHourInputLabel',
    defaultMessage: 'Heure de fin',
    description: "Label pour le timepicker de l'heure de fin",
  },
  cancelButton: {
    id: 'AccountHibernation.cancelButton',
    defaultMessage: 'Annuler',
    description: "Bouton d'annulation de la période d'hibernation",
  },
  submitButton: {
    id: 'AccountHibernation.submitButton',
    defaultMessage: 'Validation',
    description: "Bouton de soumission de la période d'hibernation",
  },
});
