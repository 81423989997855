import React from 'react';
import { Field, Formik } from 'formik';
import {
  Company,
  Update_Company_DescriptionMutation,
  Update_Company_DescriptionMutationVariables,
} from '../../../../../../../@types/graphql.d';
import {
  UpdateCompanyDescriptionMutationErrors,
  UpdateCompanyMutationErrors,
} from '../../../../../../../@types/graphql-errors.d';
import useFormikMutation from '../../../../../../../utils/hooks/useFormikMutation';
import FormikFieldError from '../../../../../../common/formik/components/formik-field-error/FormikFieldError';
import FormikStatusError from '../../../../../../common/formik/components/formik-status-error/FormikStatusError';
import FormikTextareaField from '../../../../../../common/formik/components/formik-textarea-field/FormikTextareaField';
import { UPDATE_COMPANY_DESCRIPTION } from './CompanyPresentationWrite.gql';
import translations, { errorStatusTranslations } from './CompanyPresentationWrite.translations';
import { useIntl } from 'react-intl';

import styles from './CompanyPresentationWrite.module.scss';

interface CompanyPresentationWriteProps {
  company?: Pick<Company, 'id' | 'description'> | null;
  finish: () => void;
}
function CompanyPresentationWrite({ company, finish }: CompanyPresentationWriteProps) {
  const intl = useIntl();
  const [onSubmit] = useFormikMutation<Update_Company_DescriptionMutation, Update_Company_DescriptionMutationVariables>(
    UPDATE_COMPANY_DESCRIPTION,
    {
      refetchQueries: ['GET_COMPANY_PRESENTATION'],
      onCompleted: (successData) => {
        if (successData?.updateCompany) {
          finish();
        }
      },
    },
  );
  return (
    <Formik<FormikValues<Update_Company_DescriptionMutationVariables>>
      initialValues={{
        companyId: company?.id,
        description: company?.description,
      }}
      onSubmit={onSubmit}
      validateOnMount
    >
      {({ handleSubmit, isSubmitting }) => (
        <form
          onSubmit={handleSubmit}
          className={styles.wrapper}
        >
          <Field
            name="description"
            component={FormikTextareaField}
            rows={7}
          />
          <FormikFieldError<
            Update_Company_DescriptionMutationVariables,
            UpdateCompanyDescriptionMutationErrors & UpdateCompanyMutationErrors
          >
            name="description"
            asyncFieldErrorTranslations={errorStatusTranslations}
            className={styles.errorField}
            reserveSpace
          />

          <FormikStatusError translations={errorStatusTranslations} />
          <div className={styles.actions}>
            <button
              type="button"
              onClick={finish}
              className={styles.cancelButton}
            >
              {intl.formatMessage(translations.cancelButton)}
            </button>
            <button
              type="submit"
              className={styles.submitButton}
              disabled={isSubmitting}
            >
              {intl.formatMessage(translations.submitButton)}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default CompanyPresentationWrite;
