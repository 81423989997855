import { createContext } from 'react';
import { Get_ConversationQuery } from '../../../../@types/graphql.d';

export type ConversationViewContextDef = {
  conversation: NonNullable<Get_ConversationQuery['conversation']>;
  openMobileInterlocutorPanel: () => void;
  closeMobileInterlocutorPanel: () => void;
};

export const ConversationViewContext = createContext<ConversationViewContextDef>({} as ConversationViewContextDef);
