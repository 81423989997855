import React from 'react';
import { Link } from 'react-router';
import { getWidthHeightAvatar } from '../../utils/avatar-sizes';

export type avatarProps = {
  className?: string;
  avatar?: {
    url: string;
  };
  type?: string;
  size?: string | { width: number; height: number };
  fallback?: string;
};

export type avatarLinkProps = {
  href: string;
};

const getSizeProps = (size: string | { width: number; height: number }) =>
  size instanceof Object ? size : getWidthHeightAvatar(size);

const Avatar = ({ className, avatar, size }: avatarProps) => {
  const sizeProps = size ? getSizeProps(size) : {};
  return (
    <img
      alt={'avatar'}
      className={className}
      src={avatar?.url || 'https://assets.myjobglasses.com/images/avatar-placeholder.png'}
      style={{ ...sizeProps, borderRadius: '50%' }}
    />
  );
};

export const AvatarWithLink = ({ href, ...props }: avatarLinkProps) => (
  <Link to={href}>
    <Avatar {...props} />
  </Link>
);

export default Avatar;
