import * as Yup from 'yup';
import translations from './AppointmentModal.translations';
import { AppointmentTypesEnum } from '../../../../../@types/graphql.d';
import { IntlShape } from 'react-intl';

export const AppointmentModalValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    startAt: Yup.mixed().required(intl.formatMessage(translations.validateFromDateInvalide)),
    meeting_place: Yup.mixed(),
    videoconference_link: Yup.string(),
    type: Yup.string()
      .oneOf([AppointmentTypesEnum.Physical, AppointmentTypesEnum.Phone, AppointmentTypesEnum.Video])
      .required(),
  });
