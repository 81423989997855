import { gql } from '@apollo/client';

export const PRESENTATION_QUERY = gql`
  query AmbassadorPresentation($id: ID!) {
    getUser(id: $id) {
      ambassadorPresentation
      expertisePresentation
      aspirationPresentation
      interestsPresentation
      onlyFrenchWarning
      onlyFrenchWarningText
      onlyFrenchWarningConfirmText
    }
  }
`;
