import React, { useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';
import MessageTemplatesContext from '../../../../../../context/MessageTemplatesContext';
import SendMessageContext from '../../../../context/SendMessageContext';
import translations from './UnsavedMessageTemplateChangesWarning.translations';

import styles from './UnsavedMessageTemplateChangesWarning.module.scss';
import { useIntl } from 'react-intl';

function UnsavedMessageTemplateChangesWarning() {
  const intl = useIntl();
  const {
    requestCloseMessageTemplate,
    setRequestCloseMessageTemplate,
    setMessageTemplateCannotBeClosed,
    forceCloseMessageTemplate,
    isRequestNewTemplate,
    setRequestNewTemplate,
  } = useContext(SendMessageContext);
  const { setEditingItem } = useContext(MessageTemplatesContext);
  const { dirty, submitForm, resetForm } = useFormikContext();
  // inform template manager that EditTemplateForm being edited
  useEffect(() => {
    setMessageTemplateCannotBeClosed(dirty);
  }, [dirty]);
  // handle reset form (for new template)
  useEffect(() => {
    if (dirty && isRequestNewTemplate) {
      setRequestCloseMessageTemplate(true);
    } else if (isRequestNewTemplate) {
      resetForm({});
      setEditingItem(undefined);
      setRequestNewTemplate(false);
    }
  }, [dirty, isRequestNewTemplate]);
  if (!requestCloseMessageTemplate) {
    return null;
  }
  function proceed() {
    if (isRequestNewTemplate) {
      resetForm({});
      setRequestNewTemplate(false);
      setEditingItem(undefined);
    } else {
      forceCloseMessageTemplate();
    }
    setRequestCloseMessageTemplate(false);
  }
  function confirm() {
    submitForm().then(proceed);
  }
  return (
    <div className={styles.confirmClosingWithoutSave}>
      <div className={styles.content}>
        <span className={styles.message}>{intl.formatMessage(translations.confirmMessage)}</span>
        <button
          type="button"
          onClick={confirm}
          className={styles.actionButton}
        >
          {intl.formatMessage(translations.yes)}
        </button>
        <button
          type="button"
          onClick={proceed}
          className={styles.actionButton}
        >
          {intl.formatMessage(translations.no)}
        </button>
      </div>
    </div>
  );
}

export default UnsavedMessageTemplateChangesWarning;
