import React, { useContext, useRef } from 'react';
import classnames from 'classnames';
import { matchPath, useLocation } from 'react-router';
import Avatar from '../../../../../components/avatar/Avatar';
import { useOutsideClickEffect } from '../../../../../utils/hooks/useOutsideClickEffect';
import MainMenuMobileContext from '../../context/MainMenuMobileContext.js';

import './MainMenu.scss';
import { CurrentUserContext } from '../../../../../contexts/current-user-context/CurrentUserContext';
import XCloseIcon from '../../../../../icons/component-icons/XCloseIcon';
import { GetCompanyNavbarAuthorizationsQuery, GetCompanyNavbarAuthorizationsQueryVariables } from '../../../../../@types/graphql';
import { useQuery } from '@apollo/client';
import { GET_COMPANY_NAVBAR_AUTHORIZATIONS_QUERY } from '../../config/connect/connectAuthorizations.gql';

interface MainMenuProps {
  config?: any;
  shouldShowSwitch?: boolean;
  companyId?: string;
}

const MainMenu = ({ config, companyId }: MainMenuProps) => {
  const { currentUser } = useContext(CurrentUserContext);
  const pathname = useLocation().pathname;

  // mobile feature
  const { isMainMenuMobileOpened, setMainMenuMobileOpened } = useContext(MainMenuMobileContext);
  const ref = useRef(null);
  useOutsideClickEffect(() => {
    setMainMenuMobileOpened(false);
  }, ref);
  function closeMainMenuMenu() {
    setMainMenuMobileOpened(false);
  }

  const { data } = useQuery<GetCompanyNavbarAuthorizationsQuery, GetCompanyNavbarAuthorizationsQueryVariables>(
    GET_COMPANY_NAVBAR_AUTHORIZATIONS_QUERY,
    {
      variables: { companyId: companyId! },
      skip: !companyId,
      fetchPolicy: 'network-only',
    },
  );

  return (
    <nav
      className={classnames('main-menu', {
        'main-menu-mobile-opened': isMainMenuMobileOpened,
      })}
      ref={ref}
    >
      <div className="mobile-heading">
        <button
          type="button"
          onClick={closeMainMenuMenu}
          className="mobile-heading-close"
        >
          <XCloseIcon className="mobile-heading-close__icon" />
        </button>
        <Avatar
          avatar={currentUser?.avatars}
          className="avatar"
        />
        <p className="firstname">{currentUser?.firstName}</p>
      </div>

      {Array.isArray(config) && (
        <ul>
          {config.map(
            ({
              component: WrappedComponent,
              condition,
              type,
              className,
              label,
              to,
              key,
              onClick,
              icon,
              notificationKey,
              authorizationKey,
            }) =>
              (!condition || condition({ currentUser })) && !currentUser?.mustConfirmEmail ? (
                <li
                  className="main-menu-item"
                  key={key}
                >
                  <WrappedComponent
                    type={type}
                    data-testid={key}
                    className={className}
                    label={label}
                    to={to}
                    onClick={onClick}
                    icon={icon}
                    active={!!(pathname && to && matchPath({ path: to, end: true }, pathname))}
                    currentUser={currentUser}
                    closeMainMenuMenu={closeMainMenuMenu}
                    notificationKey={notificationKey}
                    authorizationKey={authorizationKey}
                    // @ts-ignore
                    isRestricted={data?.company[authorizationKey] === false}
                  />
                </li>
              ) : null,
          )}
        </ul>
      )}
    </nav>
  );
};

export default MainMenu;
