import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Location, useLocation, useParams, useSearchParams } from 'react-router';
import { useQuery } from '@apollo/client';
import { GetPerceptionQuestionForReviewQuery, GetPerceptionQuestionForReviewQueryVariables, GetPerceptionQuestionQuery, GetPerceptionQuestionQueryVariables, Get_Appointment_To_ReviewQuery, Get_Appointment_To_ReviewQueryVariables } from '../../../@types/graphql';
import { GET_APPOINTMENT_TO_REVIEW, GET_PERCEPTION_QUESTION_FOR_REVIEW } from './Review.gql';
import PageLoader from '../../common/page-loader/PageLoader';
import { PanelSegmentation } from './panels/Panel';
import { Header } from './components/header/Header';
import { Footer } from './components/footer/Footer';
import { ReviewContext, ReviewValues } from './ReviewContext';
import { useFormik } from 'formik';

import './Review.scss';
import { REVIEW_PANELS } from './panels';
import { useSnackbar } from 'notistack';
import { defineMessages, useIntl } from 'react-intl';
import { StringUtil } from '../../../utils/string.util';

const ERROR_TRANSLATIONS = defineMessages({
  blankMessage: {
    id: 'Review.error.blankMessage',
    defaultMessage: 'Vous devez laisser un avis sur votre rendez-vous',
  },
});

const useAppointmentToReview = (appointmentId?: string) =>
  useQuery<Get_Appointment_To_ReviewQuery, Get_Appointment_To_ReviewQueryVariables>(GET_APPOINTMENT_TO_REVIEW, {
    variables: {
      id: appointmentId!,
    },
    skip: !appointmentId,
  });

const usePerceptionQuestion = (interlocutorId?: string) =>
  useQuery<GetPerceptionQuestionForReviewQuery, GetPerceptionQuestionForReviewQueryVariables>(GET_PERCEPTION_QUESTION_FOR_REVIEW, {
    variables: {
      professionalId: interlocutorId || '',
    },
    skip: !interlocutorId,
  });

const Review: React.FC = () => {
  const { formatMessage } = useIntl();
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const { appointmentId } = useParams<{ appointmentId?: string }>();
  const [searchParams] = useSearchParams();
  const location: Location<{ upcomingReviewCount: number }> = useLocation();

  const [panelIndex, setPanelIndex] = useState(0);
  const [indexesStack, setIndexesStack] = useState<number[]>([]);
  const [segmentations, setSegmentations] = useState<PanelSegmentation[]>([]);

  const { data, loading } = useAppointmentToReview(appointmentId);
  const appointment = data?.appointment;
  const { data: perceptionData } = usePerceptionQuestion(appointment?.interlocutor.id);
  const activePerceptionPanel = !!perceptionData?.perceptionQuestions;

  const eligiblePanelCount = useMemo(() => {
    if (!appointment) return 0;
    return REVIEW_PANELS.reduce((count, p) => {
      if (
        !p.condition ||
        p.condition({
          didShowUp: segmentations.includes('didShowUp'),
          messageForHRRequired: appointment.ambassadorReview?.messageForHrRequired || false,
          canBeRecontacted:
            !!appointment.memberReview?.canFillCompanyAspiration || !!appointment.memberReview?.canFillSchoolAspiration,
          asMember: appointment.currentUserIsMember,
          asAmbassador: appointment.currentUserIsAmbassador,
          activePerceptionPanel: activePerceptionPanel,
          wantsToBeRecontacted: true, // Count this panel every time to avoid a rollback effect on the progress bar
          shouldFillSchoolAspirationPanel: appointment.memberReview?.canFillSchoolAspiration || false,
          shouldFillCompanyAspirationPanel: appointment.memberReview?.canFillCompanyAspiration || false,
        })
      )
        return p.isSubmittingPanel ? count : count + 1;
      else return count;
    }, 0);
  }, [REVIEW_PANELS, segmentations, appointment]);

  const Panel = REVIEW_PANELS[panelIndex].panel;

  const formik = useFormik<ReviewValues>({
    initialValues: {
      jobType: [],
      workingArea: [],
      tooYoungToAnswer: false,
      shouldAnswerLike: appointment?.currentUserIsMember || false,
    },
    validationSchema: REVIEW_PANELS[panelIndex].validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: () => {},
  });

  const isLastPanel = useMemo(() => {
    if (!appointment) return true;
    for (let i = panelIndex + 1; i < REVIEW_PANELS.length; i++) {
      const panel = REVIEW_PANELS[i];
      if (
        !panel.condition ||
        panel.condition({
          didShowUp: segmentations.includes('didShowUp'),
          messageForHRRequired: appointment.ambassadorReview?.messageForHrRequired || false,
          canBeRecontacted:
            !!appointment.memberReview?.canFillCompanyAspiration || !!appointment.memberReview?.canFillSchoolAspiration,
          asMember: appointment.currentUserIsMember,
          asAmbassador: appointment.currentUserIsAmbassador,
          activePerceptionPanel: activePerceptionPanel,
          wantsToBeRecontacted: formik.values.wantsToBeRecontacted || false,
          shouldFillSchoolAspirationPanel: appointment.memberReview?.canFillSchoolAspiration || false,
          shouldFillCompanyAspirationPanel: appointment.memberReview?.canFillCompanyAspiration || false,
        })
      ) {
        return panel.isSubmittingPanel || false;
      }
    }
    return true;
  }, [panelIndex, appointment, segmentations, formik]);

  const nextPanel = useCallback(
    (segmentation?: PanelSegmentation) => {
      if (!appointment) return;

      if (StringUtil.isFilledWithBlankSpaces(formik.values.message) || StringUtil.isFilledWithBlankSpaces(formik.values.messageForHR)) {
        snackbar(formatMessage(ERROR_TRANSLATIONS.blankMessage), { variant: 'error' })
        return;
      }

      const segms = [...segmentations, segmentation || '-'];
      setSegmentations(segms);

      for (let i = panelIndex + 1; i < REVIEW_PANELS.length; i++) {
        const panel = REVIEW_PANELS[i];
        if (
          !panel.condition ||
          panel.condition({
            didShowUp: segms.includes('didShowUp'),
            messageForHRRequired: appointment.ambassadorReview?.messageForHrRequired || false,
            canBeRecontacted:
              !!appointment.memberReview?.canFillCompanyAspiration ||
              !!appointment.memberReview?.canFillSchoolAspiration,
            wantsToBeRecontacted: formik.values.wantsToBeRecontacted || false,
            asMember: appointment.currentUserIsMember,
            asAmbassador: appointment.currentUserIsAmbassador,
            activePerceptionPanel: activePerceptionPanel,
            shouldFillSchoolAspirationPanel: appointment.memberReview?.canFillSchoolAspiration || false,
            shouldFillCompanyAspirationPanel: appointment.memberReview?.canFillCompanyAspiration || false,
          })
        ) {
          setPanelIndex((p) => {
            setIndexesStack((is) => {
              if (!is.includes(p)) return [...is, p];
              return is;
            });
            return i;
          });
          return;
        }
      }
    },
    [panelIndex, appointment, segmentations, formik],
  );

  const previousPanel = useCallback(() => {
    if (!appointment) return;
    setSegmentations(segmentations.slice(0, -1));
    setIndexesStack((is) => {
      setPanelIndex(is[is.length - 1] || 0);
      return is.slice(0, -1);
    });
  }, [appointment, segmentations]);

  useEffect(() => {
    if (!appointment) return;
    formik.setFieldValue('shouldAnswerLike', appointment.currentUserIsMember);
  }, [appointment]);

  useEffect(() => {
    formik.validateForm(); // Revalidate the form when the validationSchema change
  }, [panelIndex, indexesStack, formik.values]);

  if (loading) return <PageLoader />;
  if (!appointment) throw Error(`Could not find appointment : ${appointmentId}`);

  if (searchParams.has('claimApproved', 'true') && panelIndex === 0) nextPanel('didShowUp');

  return (
    <ReviewContext.Provider value={{ reviewFormik: formik }}>
      <main className="review-view">
        <Header
          appointment={appointment}
          interlocutor={panelIndex > 0 ? appointment.interlocutor : undefined}
          upcomingReviewCount={location.state?.upcomingReviewCount || 1}
        />

        <div className="review-view__container">
          <Panel
            interlocutor={appointment.interlocutor}
            appointment={appointment}
            onSubmit={nextPanel}
            back={previousPanel}
          />
        </div>

        <Footer
          onBack={previousPanel}
          onNext={nextPanel}
          isLastPanel={isLastPanel}
          canGoNext={formik.isValid}
          progression={(indexesStack.length / eligiblePanelCount) * 100}
          isVisible={panelIndex !== 0}
        />
      </main>
    </ReviewContext.Provider>
  );
};

export default Review;