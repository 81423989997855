import classNames from 'classnames';
import React, { CSSProperties, memo } from 'react';

import './BadgeToggleButton.scss';

type Props = {
  label: string;
  toggled: boolean;
  onToggleChange: () => void;
  style?: CSSProperties;
};

export const BadgeToggleButton: React.FC<Props> = memo(({ label, style, toggled, onToggleChange }) => {
  return (
    <button
      className={classNames('badge-toggle-button', { 'badge-toggle-button--toggled': toggled })}
      style={style}
      onClick={onToggleChange}
    >
      {label}
    </button>
  );
});
