import { gql } from '@apollo/client';

export const GET_COMPANY_EMPLOYEE_APPOINTMENT = gql`
  query GET_COMPANY_EMPLOYEE_APPOINTMENT($appointmentId: ID!) {
    companyEmployeeAppointment(appointmentId: $appointmentId) {
      id
      from
      professional {
        id
        firstName
        lastName
        email
        avatar
        status
        jobTitle
        companyAttribute1
        companyAttribute2
        companyAttribute3
      }
      member {
        firstName
        lastName
        email
        gender
        phoneNumber
        avatar {
          url
        }
        memberPresentation
        meetingPlace
        secondarySituation {
          key
          name
        }
        currentGradeOrDiploma
        anonymizedOrDeleted
        acquiredDiplomaTitle
        acquiredDiplomaLevel {
          key
          name
        }
        acquiredDiplomaBeginYear
        acquiredDiplomaAcquisitionYear
        acquiredDiplomaSchoolName
      }
      reviewFromProfessional {
        appointmentStatus
        answer1
        answer2
        answer3
        messageForHr
      }
      reviewFromStudent {
        appointmentStatus
        answer1
        answer2
        answer3
        wantToBeRecontacted
        wantsToBeRecontactedBySchool
        aspiration {
          type
          duration
          startsAtMonth
          startsAtYear
          postalCode
        }
      }
    }
  }
`;
