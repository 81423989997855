import { gql } from '@apollo/client';

export const GET_CONVERSATION_UNRESOLVED_APPOINTMENT_CLAIMS = gql`
  query GET_CONVERSATION_UNRESOLVED_APPOINTMENT_CLAIMS($conversationId: ID!) {
    appointmentClaims(
      first: 1
      filters: { resolved: false, conversationIdEq: $conversationId }
      sort: [START_AT_DESC]
    ) {
      nodes {
        id
        type
        startAt
        resolvedAt
        currentUserIsAmbassador
        currentUserCanReview
        meetingPlace
      }
    }
  }
`;
