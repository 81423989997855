import { useSnackbar } from 'notistack';
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import TextInput from '../../../../../components/text-input/TextInput';
import { Field, Formik } from 'formik';
import { useIntl } from 'react-intl';
import GooglePlaceInput from '../../../../../components/google-place-input /GooglePlaceInput';
import FormikRadioField from '../../../../common/formik/components/formik-radio-field/FormikRadioField';
import ContactDatepicker from './components/contact-datepicker/ContactDatepicker';
import ContactDatetime from './components/contact-datetime/ContactDatetime';
import {
  AppointmentTypesEnum,
  BookAppointmentMutation,
  BookAppointmentMutationVariables,
  MoveAppointmentMutation,
  MoveAppointmentMutationVariables,
  ClaimAppointmentMutation,
  ClaimAppointmentMutationVariables,
  BookAppointmentMutationErrorEnum,
  Get_ConversationQuery,
  Get_ConversationQueryVariables,
  Appointment,
} from '../../../../../@types/graphql.d';
import {
  BOOK_APPOINTMENT_MUTATION,
  MOVE_APPOINTMENT_MUTATION,
  CLAIM_APPOINTMENT_MUTATION,
} from './AppointmentModal.gql';
import { GET_CONVERSATION } from '../../../../../scenes/interactions/conversations/ConversationView.gql';
import { AppointmentModalValidationSchema } from './AppointmentModal.validations';
import translations, {
  BookAppointmentErrors,
  AppointmentCreateModalTranslations,
} from './AppointmentModal.translations';
import { GET_CONVERSATION_APPOINTMENTS } from '../../../../../scenes/interactions/conversations/components/appointment-hub/hooks/useConversationAppointments.gql';
import { GET_CONVERSATION_UNRESOLVED_APPOINTMENT_CLAIMS } from '../../../../../scenes/interactions/conversations/components/appointment-hub/hooks/useConversationAppointmentClaims.gql';
import { GET_APPOINTMENT_LIST } from '../../../../../scenes/interactions/appointments/AppointmentList.gql';
import { GET_FIRST_APPOINTMENT_TO_FORCE_REVIEW_QUERY } from '../../../../../containers/review/AppointmentsToReview.gql';

import styles from './AppointmentModal.module.scss';
import useModal from '../../../../../components/modal/Modal.hook';
import ButtonsGroup from '../../../../../components/buttons-group/ButtonsGroup';
import PrimaryButton from '../../../../../components/primary-button/PrimaryButton';
import buttonCommonTranslations from '../../../../../scenes/common/common-translations/button.translations';
import SecondaryButton from '../../../../../components/secondary-button/SecondaryButton';
import LoadingIcon from '../../../../../icons/component-icons/LoadingIcon';
import { generatePath } from 'react-router';
import Routes from '../../../../../routes';

export type AppointmentFormProps = Pick<
  Appointment,
  'id' | 'type' | 'meetingPlace' | 'videoconferenceLink' | 'startAt' | 'googlePlaceId' | 'conversationId'
>;

type FormikValues = {
  startAt: moment.Moment;
  meeting_place?: { address?: string; google_place_id?: string };
  videoconference_link?: string;
  type: AppointmentTypesEnum;
  conversationId: string;
};

type AppointmentFormModalProps = {
  formTranslations: ReactIntl.Messages<'title' | 'description'>;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  onSubmit: (arg0: FormikValues) => void;
  initialValues: FormikValues;
  loading: boolean;
};

function AppointmentFormModal({
  onSubmit,
  initialValues,
  formTranslations,
  minDate,
  maxDate,
  loading,
}: AppointmentFormModalProps) {
  const intl = useIntl();
  const [, closeModal] = useModal();
  const conversation = useConversation(initialValues.conversationId);

  const isCollectiveMeeting = conversation?.conversationType != 'individual';
  const interlocutor = conversation?.interlocutorV2;
  if (!interlocutor) {
    return null;
  }

  const availableAppointmentTypes = [AppointmentTypesEnum.Video];
  if (!interlocutor.superMinor && !isCollectiveMeeting) {
    availableAppointmentTypes.push(AppointmentTypesEnum.Phone);
  }
  if (!interlocutor.minor) {
    availableAppointmentTypes.push(AppointmentTypesEnum.Physical);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={AppointmentModalValidationSchema(intl)}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <p className={styles.description}>{intl.formatMessage(formTranslations.description)}</p>
          <section className={styles.appointmentFromDate}>
            <Field
              name="startAt"
              component={ContactDatepicker}
              value={formik.values.startAt}
              minDate={minDate}
              maxDate={maxDate}
            />
            <Field
              name="startAt"
              component={ContactDatetime}
              value={formik.values.startAt}
              maxDate={maxDate}
            />
          </section>
          <section className={styles.typeFields}>
            <Field
              name="type"
              value={AppointmentTypesEnum.Video}
              component={FormikRadioField}
              label={intl.formatMessage(translations.videoAppointment)}
              selected={true}
            />
            {availableAppointmentTypes.includes(AppointmentTypesEnum.Phone) && (
              <Field
                name="type"
                value={AppointmentTypesEnum.Phone}
                component={FormikRadioField}
                label={intl.formatMessage(translations.phoneAppointment)}
              />
            )}
            {availableAppointmentTypes.includes(AppointmentTypesEnum.Physical) && (
              <Field
                name="type"
                value={AppointmentTypesEnum.Physical}
                component={FormikRadioField}
                label={intl.formatMessage(translations.physicalAppointment)}
              />
            )}
          </section>
          <section className={styles.meetingPlace}>
            {formik.values.type === AppointmentTypesEnum.Physical && (
              <Field
                name="meeting_place"
                placeholder={intl.formatMessage(translations.appointmentPlace)}
                component={GooglePlaceInput}
                onChange={(e) => {
                  formik.setFieldValue('meeting_place', {
                    googlePlaceId: e.place_id,
                    address: e.description,
                  });
                }}
              />
            )}
            {formik.values.type === AppointmentTypesEnum.Video && (
              <Field
                name="videoconference_link"
                placeholder={intl.formatMessage(translations.videoLink)}
                value={formik.values.videoconference_link || ""}
                component={TextInput}
                onChange={(e) => formik.setFieldValue('videoconference_link', e.target.value)}
              />
            )}
          </section>

          <ButtonsGroup>
            <SecondaryButton
              label={intl.formatMessage(buttonCommonTranslations.cancel)}
              onClick={closeModal}
            />
            <PrimaryButton
              label={intl.formatMessage(buttonCommonTranslations.confirm)}
              submit
              icon={loading ? LoadingIcon : undefined}
            />
          </ButtonsGroup>
        </form>
      )}
    </Formik>
  );
}

type CreateAppointmentModalProps = {
  conversation: NonNullable<Get_ConversationQuery['conversation']>;
};

export function BookAppointmentModal({ conversation }: CreateAppointmentModalProps) {
  const [, closeModal] = useModal();
  const [createAppointment, { loading }] = useMutation<BookAppointmentMutation, BookAppointmentMutationVariables>(
    BOOK_APPOINTMENT_MUTATION,
    {
      refetchQueries: [
        { query: GET_CONVERSATION_APPOINTMENTS, variables: { conversationId: conversation.id } },
        { query: GET_FIRST_APPOINTMENT_TO_FORCE_REVIEW_QUERY },
        { query: GET_APPOINTMENT_LIST, variables: { filters: { past: false, cancelled: false }, sort: 'FROM_DESC' } },
      ],
    },
  );
  const interlocutor = conversation?.interlocutorV2;

  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const initialValues = {
    startAt: moment().hours(9).minutes(0).add(1, 'day').tz(moment.tz.guess()),
    type: AppointmentTypesEnum.Video,
    conversationId: conversation.id,
    videoconferenceLink: '',
    meeting_place: undefined,
  };
  const onSubmit = (values: FormikValues) => {
    if (!interlocutor) {
      return;
    }

    createAppointment({
      variables: {
        startAt: values.startAt.toISOString(),
        userId: interlocutor.id,
        type: values.type,
        videoconferenceLink: values.videoconference_link,
        meetingPlace: values.meeting_place?.address,
      },
    })
      .then((_data) => {
        window.location.href = generatePath(Routes.conversation, {
          id: conversation.id,
        });
      })
      .catch((error) => {
        if (!error.graphQLErrors) {
          return;
        }
        if (!error.graphQLErrors.length) {
          return;
        }

        const errors: [BookAppointmentMutationErrorEnum] = error.graphQLErrors.map((e: any) => e?.extensions?.code);
        errors.forEach((error) => {
          const message = intl.formatMessage(BookAppointmentErrors[error] || BookAppointmentErrors['default']);
          enqueueSnackbar(message, { variant: 'error', autoHideDuration: 10000 });
        });

        closeModal();
      });
  };

  return (
    <AppointmentFormModal
      formTranslations={AppointmentCreateModalTranslations}
      onSubmit={onSubmit}
      initialValues={initialValues}
      loading={loading}
    />
  );
}

const useConversation = (conversationId: string) => {
  const { data } = useQuery<Get_ConversationQuery, Get_ConversationQueryVariables>(GET_CONVERSATION, {
    variables: { conversationId: conversationId },
    fetchPolicy: 'network-only',
  });
  return data?.conversation;
};

export function MoveAppointmentModal({ appointment }: { appointment: AppointmentFormProps }) {
  const [, closeModal] = useModal();

  const [moveAppointment, { loading }] = useMutation<MoveAppointmentMutation, MoveAppointmentMutationVariables>(
    MOVE_APPOINTMENT_MUTATION,
    {
      refetchQueries: [
        { query: GET_CONVERSATION_APPOINTMENTS, variables: { conversationId: appointment.conversationId } },
        { query: GET_APPOINTMENT_LIST, variables: { filters: { past: false, cancelled: false }, sort: 'FROM_DESC' } },
      ],
    },
  );
  const conversation = useConversation(appointment.conversationId);
  const interlocutor = conversation?.interlocutorV2;

  if (!interlocutor) {
    return null;
  }

  const initialValues = {
    startAt: moment(appointment.startAt),
    type: appointment.type,
    videoconference_link: appointment?.videoconferenceLink || undefined,
    meeting_place: appointment.meetingPlace
      ? { address: appointment.meetingPlace || undefined, google_place_id: appointment.googlePlaceId || undefined }
      : undefined,
    conversationId: appointment.conversationId,
  };
  const onSubmit = (values: FormikValues) => {
    if (!interlocutor) {
      return;
    }

    moveAppointment({
      variables: {
        id: appointment.id,
        startAt: values.startAt.toISOString(),
        type: values.type,
        videoconferenceLink: values.videoconference_link,
        meetingPlace: values.meeting_place?.address,
      },
    }).then(() => {
      closeModal();
    });
  };

  return (
    <AppointmentFormModal
      initialValues={initialValues}
      onSubmit={onSubmit}
      formTranslations={AppointmentCreateModalTranslations}
      minDate={moment()}
      loading={loading}
    />
  );
}

export function ClaimAppointmentModal({ conversation }: CreateAppointmentModalProps) {
  const [, closeModal] = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [createClaim, { loading }] = useMutation<ClaimAppointmentMutation, ClaimAppointmentMutationVariables>(
    CLAIM_APPOINTMENT_MUTATION,
    {
      refetchQueries: [
        { query: GET_CONVERSATION_UNRESOLVED_APPOINTMENT_CLAIMS, variables: { conversationId: conversation.id } },
        { query: GET_CONVERSATION, variables: { conversationId: conversation.id } },
      ],
    },
  );
  const interlocutor = conversation.interlocutorV2;
  const maxDate = moment().subtract(1, 'hour');
  const intl = useIntl();

  const initialValues = {
    startAt: maxDate.clone().hours(9).minutes(0).tz(moment.tz.guess()),
    type: AppointmentTypesEnum.Video,
    conversationId: conversation.id,
  };
  const onSubmit = (values: FormikValues) => {
    if (!interlocutor) {
      return;
    }

    createClaim({
      variables: {
        conversationId: conversation.id,
        startAt: values.startAt.toISOString(),
        userId: interlocutor.id,
        type: values.type,
        meetingPlace: values.meeting_place?.address,
      },
    })
      .then((_data) => {
        window.location.href = generatePath(Routes.conversation, {
          id: conversation.id,
        });
      })
      .catch((error) => {
        if (!error.graphQLErrors) {
          return;
        }
        if (!error.graphQLErrors.length) {
          return;
        }

        const errors: [BookAppointmentMutationErrorEnum] = error.graphQLErrors.map((e: any) => e?.extensions?.code);
        errors.forEach((error) => {
          const message = intl.formatMessage(BookAppointmentErrors[error] || BookAppointmentErrors['default']);
          enqueueSnackbar(message, { variant: 'error', autoHideDuration: 10000 });
        });

        closeModal();
      });
  };

  return (
    <AppointmentFormModal
      onSubmit={onSubmit}
      initialValues={initialValues}
      formTranslations={AppointmentCreateModalTranslations}
      maxDate={maxDate}
      loading={loading}
    />
  );
}
