import { defineMessages } from 'react-intl';

export const LocationFilterButtonTranslations = defineMessages({
  facetLabel: {
    id: 'LocationFilterButton.facetLabel',
    defaultMessage: 'Lieu',
  },
  hint: {
    id: 'LocationFilterButton.hint',
    defaultMessage: "Vous ne pouvez choisir qu'un lien à la fois",
  },
});
