import { defineMessages } from 'react-intl';

export const ProgramTranslations = defineMessages({
  appointmentsMade: {
    id: 'DashboardView.Program.appointmentsMade',
    defaultMessage: '{made}/{total} rendez-vous réalisés',
  },
  organization: {
    id: 'DashboardView.Program.organization',
    defaultMessage: 'Organisation',
  },
  programManager: {
    id: 'DashboardView.Program.programManager',
    defaultMessage: 'Responsable du programme',
  },
  programDescription: {
    id: 'DashboardView.Program.programDescription',
    defaultMessage: 'Description du programme',
  },
  certificate: {
    id: 'DashboardView.Program.certificate',
    defaultMessage: 'Certification',
  },
  receiveMyCertificate: {
    id: 'DashboardView.Program.receiveMyCertificate',
    defaultMessage: 'Recevoir mon certificat par mail',
  },
  ambassadorsMet: {
    id: 'DashboardView.Program.ambassadorsMet',
    defaultMessage: 'Ambassadeurs rencontrés',
  },
  waitingReview: {
    id: 'DashboardView.Program.waitingReview',
    defaultMessage: "En attente d'évaluation",
  },
  certificate_failedReason: {
    id: 'DashboardView.Program.certificate_failedReason',
    defaultMessage: "L'objectif de rendez-vous de votre programme n’a pas été atteint",
  },
  certificateClaimSuccess: {
    id: 'DashboardView.Program.certificateClaimSuccess',
    defaultMessage: 'Votre certificat a bien été envoyé par mail',
  },
});

export const ProgramCertificateTranslations = defineMessages<string>({
  certificate_delivered: {
    id: 'DashboardView.Program.certificate_delivered',
    defaultMessage: 'Confirmé',
  },
  certificate_failed: {
    id: 'DashboardView.Program.certificate_failed',
    defaultMessage: 'Non obtenue',
  },
  certificate_unavailable: {
    id: 'DashboardView.Program.certificate_unavailable',
    defaultMessage: 'Non disponible',
  },
});
