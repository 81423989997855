import classNames from 'classnames';
import CheckIcon from '../../../../../../icons/component-icons/CheckIcon';
import ChevronDownIcon from '../../../../../../icons/component-icons/ChevronDownIcon';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import {
  CompanyEmployeesQueryFiltersCustomAttributeInput,
  CompanyEmployeesQueryFiltersHrTableStatusEnum,
} from '../../../../../../@types/graphql.d';
import { CompanyAttributeSet } from '../EmployeeList.gql';
import {
  CompanyEmployeesQueryFiltersHrTableStatusEnumTranslations,
  EmployeeListTranslations,
} from '../EmployeeList.translations';
import './CategorizedMultiSelectInput.scss';
import { useIntl } from 'react-intl';

type CategorizedMultiSelectInputProps = {
  name: string;
  label?: string;
  placeholder?: string;
  attributesSet?: CompanyAttributeSet;
  hrTableStatusFilters: CompanyEmployeesQueryFiltersHrTableStatusEnum[];
  companyAttributeFilters: CompanyEmployeesQueryFiltersCustomAttributeInput[];
  onHrTableStatusChange: (filters: CompanyEmployeesQueryFiltersHrTableStatusEnum[]) => void;
  onCompanyAttributeChange: (filters: CompanyEmployeesQueryFiltersCustomAttributeInput[]) => void;
  error?: string;
  className?: string;
};

export default function CategorizedMultiSelectInput<T>({
  name,
  label,
  attributesSet,
  placeholder,
  error,
  onHrTableStatusChange,
  onCompanyAttributeChange,
  className,
  hrTableStatusFilters,
  companyAttributeFilters,
}: CategorizedMultiSelectInputProps) {
  const intl = useIntl();
  const componentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const values = useMemo(
    () => [
      {
        categoryKey: 'status',
        categoryTranslation: intl.formatMessage(EmployeeListTranslations.status),
        values: Object.values(CompanyEmployeesQueryFiltersHrTableStatusEnum).map((v) => ({
          value: v,
          translation: intl.formatMessage(CompanyEmployeesQueryFiltersHrTableStatusEnumTranslations[v]),
        })),
      },
      {
        categoryKey: attributesSet?.customEmployeeAttribute1NormalizedName || '1',
        categoryTranslation: attributesSet?.customEmployeeAttribute1 || '1',
        values: (attributesSet?.companyAttributeValues1 || []).map((v) => ({
          value: v,
          translation: v,
        })),
      },
      {
        categoryKey: attributesSet?.customEmployeeAttribute2NormalizedName || '2',
        categoryTranslation: attributesSet?.customEmployeeAttribute2 || '2',
        values: (attributesSet?.companyAttributeValues2 || []).map((v) => ({
          value: v,
          translation: v,
        })),
      },
      {
        categoryKey: attributesSet?.customEmployeeAttribute3NormalizedName || '3',
        categoryTranslation: attributesSet?.customEmployeeAttribute3 || '3',
        values: (attributesSet?.companyAttributeValues3 || []).map((v) => ({
          value: v,
          translation: v,
        })),
      },
    ],
    [attributesSet],
  );
  const [hrTableStatusIn, setHRTableStatusIn] =
    useState<CompanyEmployeesQueryFiltersHrTableStatusEnum[]>(hrTableStatusFilters);
  const [companyAttributeIn, setCompanyAttributeIn] =
    useState<CompanyEmployeesQueryFiltersCustomAttributeInput[]>(companyAttributeFilters);
  const filtersCount = useMemo(
    () => hrTableStatusIn.length + companyAttributeIn.reduce((acc, curr) => acc + curr.values.length, 0),
    [hrTableStatusIn, companyAttributeIn],
  );

  useEffect(() => {
    onHrTableStatusChange(hrTableStatusIn);
  }, [hrTableStatusIn]);

  useEffect(() => {
    onCompanyAttributeChange(companyAttributeIn);
  }, [companyAttributeIn]);

  function handleComponentClick() {
    setDropdownOpen((prev) => {
      const mirrorValue = !prev;

      if (mirrorValue === true) {
        inputRef.current?.focus();
      } else {
        inputRef.current?.blur();
      }

      return mirrorValue;
    });
  }

  function handleSelection(category: string, value: string) {
    type T = CompanyEmployeesQueryFiltersHrTableStatusEnum;
    if (category === 'status') {
      setHRTableStatusIn((prev) =>
        prev.includes(value as T) ? prev.filter((f) => f !== (value as T)) : [...prev, value as T],
      );
    } else {
      setCompanyAttributeIn((prev) => {
        const index = prev.findIndex((v) => v.key == category);
        if (index === -1) {
          return [...prev, { key: category, values: [value] }];
        } else {
          const updatedValues = prev[index].values.includes(value)
            ? prev[index].values.filter((v) => v !== value)
            : [...prev[index].values, value];

          if (updatedValues.length <= 0) {
            return [...prev].filter((_, i) => i !== index);
          }

          const updatedAttributes = [...prev];
          updatedAttributes[index].values = updatedValues;
          return updatedAttributes;
        }
      });
    }
  }

  function handleFiltersClear() {
    setHRTableStatusIn([]);
    setCompanyAttributeIn([]);
  }

  useEffect(() => {
    document.addEventListener('mousedown', (ev) => {
      if (componentRef.current && !componentRef.current.contains(ev.target as Node)) {
        setDropdownOpen(false);
      }
    });

    document.addEventListener('keydown', (ev) => {
      if (isDropdownOpen) {
        switch (ev.key) {
          case 'Escape':
            ev.preventDefault();
            setDropdownOpen(false);
            break;
        }
      }
    });
  }, [isDropdownOpen, values]);

  return (
    <div
      ref={componentRef}
      className="select-input"
    >
      {label && (
        <label
          htmlFor={name}
          className={classNames({ error: error })}
        >
          {label}
        </label>
      )}

      {filtersCount > 0 && (
        <button
          className="categorized-multi-select-input__clear"
          onClick={handleFiltersClear}
        >
          Effacer les filtres
        </button>
      )}

      <div
        className={classNames(
          'select-input__main',
          { 'select-input__main--focused': isDropdownOpen },
          { 'select-input__main--error': error },
          className,
        )}
        onClick={handleComponentClick}
      >
        <div>{placeholder}</div>

        <label htmlFor={name}>
          <ChevronDownIcon
            className={classNames('select-input__main__icon', {
              'select-input__main__icon--turn': isDropdownOpen,
            })}
          />
        </label>

        {filtersCount > 0 && <div className="categorized-multi-select-input__count">{filtersCount}</div>}
      </div>

      {isDropdownOpen && values.length > 0 && (
        <ul className="select-input__dropdown">
          {values.map((v) => (
            <Fragment key={v.categoryKey}>
              <li
                className={classNames(
                  'select-input__dropdown__option',
                  'categorized-multi-select-input__dropdown__category',
                )}
              >
                {v.categoryTranslation}
              </li>
              {v.values.map((value) => {
                let isSelected = false;
                if (v.categoryKey === 'status') {
                  isSelected = hrTableStatusIn.includes(value.value as CompanyEmployeesQueryFiltersHrTableStatusEnum);
                } else {
                  const index = companyAttributeIn.findIndex(
                    (f) => f.key === v.categoryKey && f.values.includes(value.value),
                  );
                  isSelected = !(index == -1);
                }
                return (
                  <li
                    key={value.translation}
                    className={classNames('select-input__dropdown__option', {
                      'select-input__dropdown__option--selected': isSelected,
                    })}
                  >
                    <button
                      type="button"
                      onClick={() => handleSelection(v.categoryKey, value.value)}
                    >
                      <CheckIcon />
                      {value.translation}
                    </button>
                  </li>
                );
              })}
            </Fragment>
          ))}
        </ul>
      )}

      {error && <span className="select-input__error">{error}</span>}
    </div>
  );
}
