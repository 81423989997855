import React, { useState } from 'react';
import translations from './ConversationAddAppointmentToCalendar.translations';
import { Appointment } from '../../hooks/useConversationAppointments';

import './ConversationAddAppointmentToCalendar.scss';
import { useIntl } from 'react-intl';

function ConversationAddAppointmentToCalendar({ appointment }: { appointment: Appointment }) {
  const intl = useIntl();
  const downloadUrl = formatDownloadURL(
    `${import.meta.env.REACT_APP_HERMES_URL}/download/messaging/appointments/${appointment.id}/icalendar.ics`,
  );

  const [isCalendarsListVisible, setCalendarsListVisibility] = useState(false);
  function toggleCalendarVisibility() {
    setCalendarsListVisibility(!isCalendarsListVisible);
  }
  return (
    <div className="conversation-add-appointment-to-calendar">
      <button
        type="button"
        onClick={toggleCalendarVisibility}
        className="conversation-add-appointment-to-calendar-button"
      >
        {intl.formatMessage(translations.addToCalendar)}
      </button>
      {isCalendarsListVisible && (
        <div className="calendars">
          <a
            href={appointment?.googleCalendarUrl}
            data-calendar-type="google"
            target="_blank"
            rel="noreferrer noopener"
          >
            Google
          </a>
          <a
            href={downloadUrl}
            data-calendar-type="apple"
          >
            Apple
          </a>
          <a
            href={downloadUrl}
            data-calendar-type="windows"
          >
            Outlook
          </a>
          <a
            href={appointment?.yahooCalendarUrl}
            data-calendar-type="yahoo"
            target="_blank"
            rel="noreferrer noopener"
          >
            Yahoo
          </a>
        </div>
      )}
    </div>
  );
}

export default ConversationAddAppointmentToCalendar;

function formatDownloadURL(url: string): string {
  if (url.startsWith('https://')) {
    url.replace('https://', 'welcal://');
  } else if (!url.startsWith('http://')) {
    url = 'welcal://' + url;
  }
  return url;
}
