import { CurrentUserContext } from '../contexts/current-user-context/CurrentUserContext';
import { useContext } from 'react';

export default function useUserLocalStorage() {
  const { currentUser } = useContext(CurrentUserContext);
  if (!currentUser?.id) return undefined;

  return {
    setItem: (key: string, value: string) => {
      const composedKey = composeKey(currentUser.id, key);
      localStorage.setItem(composedKey, value);
    },
    getItem: (key: string) => {
      const composedKey = composeKey(currentUser.id, key);
      return localStorage.getItem(composedKey);
    },
  };
}

function composeKey(currentUserId: string, key: string) {
  return `${key}_${currentUserId}`;
}
