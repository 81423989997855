import { gql } from '@apollo/client';

export const GET_COMPANY_NAVBAR_AUTHORIZATIONS_QUERY = gql`
  query GetCompanyNavbarAuthorizations($companyId: ID!) {
    company(companyId: $companyId) {
      id
      canAmbassadorsListAccess
      canAppointmentsListAccess
      isPremiumSchool
    }
  }
`;
