import React from 'react';
import './ProgressBar.scss';

type ProgressBarProps = {
  percentage: number;
  color?: string;
};

export default function ProgressBar({ percentage, color }: ProgressBarProps) {
  return (
    <div className="mjg-progress-bar">
      <div
        className="mjg-progress-bar__value"
        style={{ width: `min(${percentage}%, 100%)`, backgroundColor: color }}
      />
    </div>
  );
}
