import { studentAnswers, professionalAnswers } from './SoftDeletionReasonForm.translations';

export const studentOptions = [
  {
    value: studentAnswers.studentFoundHisCalling.defaultMessage,
    label: studentAnswers.studentFoundHisCalling,
  },
  {
    value: studentAnswers.studentCannotDoMeetings.defaultMessage,
    label: studentAnswers.studentCannotDoMeetings,
  },
  {
    value: studentAnswers.studentDoesNotHaveEnoughTime.defaultMessage,
    label: studentAnswers.studentDoesNotHaveEnoughTime,
  },
  {
    value: 'other',
    label: studentAnswers.studentOther,
  },
];

export const professionalOptions = [
  {
    value: professionalAnswers.professionalDoesNotHaveEnoughTime.defaultMessage,
    label: professionalAnswers.professionalDoesNotHaveEnoughTime,
  },
  {
    value: professionalAnswers.professionalNotQualiMeetings.defaultMessage,
    label: professionalAnswers.professionalNotQualiMeetings,
  },
  {
    value: 'other',
    label: professionalAnswers.professionalOther,
  },
];

export function useSoftDeletionAnswerByProfileType(isProfessional: boolean) {
  if (isProfessional) {
    return professionalOptions;
  }
  return studentOptions;
}
