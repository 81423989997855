import { defineMessages } from 'react-intl';

export default defineMessages({
  conversationsTitle: {
    id: 'Conversations.title',
    defaultMessage: 'Conversations',
  },
  draftsTitle: {
    id: 'Drafts.title',
    defaultMessage: 'Brouillons',
  },
});
