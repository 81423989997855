import classNames from 'classnames';
import React, { DetailedHTMLProps, ReactNode } from 'react';
import './CheckboxInput.scss';

type DefaultInputProps = Pick<
  DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'checked'
>;
type CheckboxInputProps = DefaultInputProps & {
  label?: string | ReactNode;
  name: string;
  error?: string;
  semiChecked?: boolean;
  disabled?: boolean;
};

export default function CheckboxInput({
  label,
  name,
  error,
  checked,
  onChange,
  semiChecked,
  disabled,
}: CheckboxInputProps) {
  return (
    <div className="checkbox-input">
      <label
        htmlFor={name}
        className={classNames('checkbox-input__checkbox', { 'checkbox-input__checkbox--disabled': disabled })}
      >
        <input
          type="checkbox"
          name={name}
          id={name}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <span
          className={classNames('checkbox-input__checkbox__checkmark', {
            'checkbox-input__checkbox__checkmark--semi': semiChecked,
          })}
        />
        {label && <span>{label}</span>}
      </label>
      {error && <div className="checkbox-input__error">{error}</div>}
    </div>
  );
}
