import React from 'react';

type ThumbIconProps = {
  className: string;
  reverse: boolean;
};

function ThumbIcon({ className, reverse }: ThumbIconProps) {
  return (
    <svg
      className={className}
      viewBox="0 0 16 16"
    >
      {reverse ? (
        <path d="M15.139,3.256l-0.779-1.333C14.028,1.354,13.415,1,12.757,1H7.37C7.02,1,6.668,1.082,6.393,1.22L4.835,1.832C4.588,1.341,4.084,1,3.497,1H1.503C0.674,1,0,1.674,0,2.501v5.998C0,9.326,0.674,10,1.503,10h1.994c0.001,0,0.001,0,0.002,0c0,0,0.001,0,0.001,0h1.902c0.332,0,0.637,0.142,0.813,0.379c0.614,0.825,1.072,1.745,1.359,2.733l0.17,0.586C7.968,14.465,8.681,15,9.479,15h0.276c0.993,0,1.801-0.799,1.801-1.78v-0.507c0-0.958-0.132-1.766-0.456-2.712l2.622,0.002c1.304,0,2.278-1.322,2.278-2.504V6.027C16,4.689,15.676,4.15,15.139,3.256z M1,8.499V2.501C1,2.225,1.226,2,1.503,2h1.994C3.774,2,4,2.225,4,2.501v5.998C4,8.775,3.774,9,3.497,9H1.503C1.226,9,1,8.775,1,8.499z M15,7.499c0,0.682-0.57,1.504-1.278,1.504L10.378,9c-0.166,0-0.321,0.083-0.415,0.221C9.87,9.358,9.852,9.534,9.915,9.688c0.461,1.136,0.641,1.983,0.641,3.024v0.507c0,0.431-0.359,0.78-0.801,0.78H9.479c-0.356,0-0.675-0.239-0.773-0.581l-0.17-0.586c-0.321-1.104-0.832-2.13-1.518-3.052C6.653,9.292,6.05,9,5.402,9H4.907C4.964,8.843,5,8.675,5,8.499V2.841l1.796-0.708C6.973,2.046,7.172,2,7.37,2h5.387c0.303,0,0.585,0.163,0.737,0.425l0.787,1.346C14.79,4.617,15,4.967,15,6.027V7.499z" />
      ) : (
        <path d="M13.722,5.997L11.1,5.999c0.324-0.946,0.456-1.754,0.456-2.712V2.78c0-0.981-0.808-1.78-1.801-1.78H9.479C8.681,1,7.968,1.535,7.744,2.302l-0.17,0.586C7.287,3.876,6.829,4.796,6.215,5.621C6.039,5.858,5.734,6,5.402,6H3.5c0,0-0.001,0-0.001,0C3.498,6,3.498,6,3.497,6H1.503C0.674,6,0,6.674,0,7.501v5.998C0,14.326,0.674,15,1.503,15h1.994c0.587,0,1.091-0.341,1.339-0.833l1.519,0.596C6.668,14.918,7.02,15,7.37,15h5.387c0.658,0,1.271-0.354,1.603-0.923l0.779-1.333C15.676,11.85,16,11.311,16,9.973V8.501C16,7.319,15.025,5.997,13.722,5.997z M4,13.499C4,13.775,3.774,14,3.497,14H1.503C1.226,14,1,13.775,1,13.499V7.501C1,7.225,1.226,7,1.503,7h1.994C3.774,7,4,7.225,4,7.501V13.499z M15,9.973c0,1.061-0.21,1.41-0.719,2.257l-0.787,1.346C13.342,13.837,13.06,14,12.757,14H7.37c-0.198,0-0.397-0.046-0.612-0.149L5,13.159V7.501C5,7.325,4.964,7.157,4.907,7h0.495C6.05,7,6.653,6.708,7.018,6.219c0.686-0.922,1.196-1.948,1.518-3.052l0.17-0.586C8.804,2.239,9.122,2,9.479,2h0.276c0.441,0,0.801,0.35,0.801,0.78v0.507c0,1.041-0.18,1.889-0.641,3.024C9.852,6.466,9.87,6.642,9.963,6.779C10.057,6.917,10.212,7,10.378,7l3.344-0.003C14.43,6.997,15,7.819,15,8.501V9.973z" />
      )}
    </svg>
  );
}

export default ThumbIcon;
