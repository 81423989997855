import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import {
  CustomAttributesSet,
  Get_Company_Custom_AttributesQuery,
  Get_Company_Custom_AttributesQueryVariables,
} from '../../../../../@types/graphql.d';
import ConfigurationBloc from '../configuration-bloc/ConfigurationBloc';
import CompanyAttributesEmpty from './components/company-attributes-empty/CompanyAttributesEmpty';
import CompanyAttributesRead from './components/company-attributes-read/CompanyAttributesRead';
import CompanyAttributesWrite from './components/company-attributes-write/CompanyAttributesWrite';
import translations from './CompanyAttributeHub.translations';
import { GET_COMPANY_CUSTOM_ATTRIBUTES } from './CompanyAttributeHub.gql';
import { useIntl } from 'react-intl';
import CompanyContext from 'scenes/company-admin/ambassadors/context/CompanyContext';

interface CompanyAttributeHubProps {
  className?: string;
  number: 1 | 2 | 3;
  disabled?: boolean;
}
function CompanyAttributeHub({ className, number, disabled }: CompanyAttributeHubProps) {
  const intl = useIntl();
  const titleKey = `companyAttribute${number}` as keyof typeof translations;
  const { companyid } = useParams();
  const { data } = useQuery<Get_Company_Custom_AttributesQuery, Get_Company_Custom_AttributesQueryVariables>(
    GET_COMPANY_CUSTOM_ATTRIBUTES,
    {
      fetchPolicy: 'network-only',
      skip: !companyid,
      variables: {
        companyId: companyid || '',
      },
    },
  );

  const valueKey = `customEmployeeAttribute${number}` as keyof CustomAttributesSet;
  return (
    <ConfigurationBloc
      title={intl.formatMessage(translations[titleKey])}
      description={intl.formatMessage(translations.companyAttributeDescription)}
      className={className}
      readComponent={CompanyAttributesRead}
      writeComponent={CompanyAttributesWrite}
      emptyComponent={CompanyAttributesEmpty}
      props={{
        number,
        company: data?.company,
        disabled,
      }}
      isEmpty={!data?.company?.customAttributesSet?.[valueKey]}
    />
  );
}

export default CompanyAttributeHub;
