import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import {
  CertificationStatus,
  Claim_CertificateMutation,
  Claim_CertificateMutationVariables,
  Participation,
} from '../../../../../@types/backend-api';
import commonErrorTranslations from '../../../../common/common-translations/errors.translations';
import { CLAIM_CERTIFICATE } from './CertificationDisplay.gql-backend-api';
import translations from './CertificationDisplay.translations';

import styles from './CertificationDisplay.module.scss';
import { useIntl } from 'react-intl';

interface CertificationDisplayProps {
  participation: Pick<Participation, 'id' | 'certificationStatus' | 'unfollowedConversationCount' | 'reportCount'>;
}
export default function CertificationDisplay({ participation }: CertificationDisplayProps) {
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const [claimCertificate] = useMutation<Claim_CertificateMutation, Claim_CertificateMutationVariables>(
    CLAIM_CERTIFICATE,
    {
      context: {
        clientName: 'backend-api',
      },
      variables: {
        participationId: participation.id,
      },
    },
  );

  const [hasAlreadyClicked, setHasAlreadyClicked] = useState(false);
  const onClickClaimCertificate = useCallback(() => {
    setHasAlreadyClicked(true);
    claimCertificate()
      .then(() => {
        enqueueSnackbar(intl.formatMessage(translations.success), {
          variant: 'success',
          autoHideDuration: 5000,
        });
      })
      .catch(() => {
        setHasAlreadyClicked(false);
        enqueueSnackbar(intl.formatMessage(commonErrorTranslations.unknown), {
          variant: 'error',
          autoHideDuration: 5000,
        });
      });
  }, [claimCertificate]);

  switch (participation.certificationStatus) {
    case CertificationStatus.CertificateUnavailable:
      return (
        <>
          <p className={styles.unavailable}>
            <span className={styles.status}>{intl.formatMessage(translations.unavailableStatus)}</span>
            &nbsp;:&nbsp;
            {intl.formatMessage(translations.unavailableDescription)}
          </p>
          {(participation.unfollowedConversationCount || 0) > 0 && (
            <p
              className={styles.unfollowedConversationCount}
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(translations.unfollowedConversation, {
                  x: participation.unfollowedConversationCount,
                }),
              }}
            ></p>
          )}
          {(participation.reportCount || 0) > 0 && (
            <p
              className={styles.reported}
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(translations.report),
              }}
            />
          )}
        </>
      );

    case CertificationStatus.CertificateFailed:
      return (
        <p className={styles.failed}>
          <span className={styles.status}>{intl.formatMessage(translations.failedStatus)}</span>
          &nbsp;:&nbsp;
          {intl.formatMessage(translations.failedDescription)}
        </p>
      );
    case CertificationStatus.CertificateDelivered:
      return (
        <p className={styles.delivered}>
          <span className={styles.status}>{intl.formatMessage(translations.deliveredStatus)}</span>
          &nbsp;:&nbsp;
          <button
            type="button"
            className={styles.requestCertificateLink}
            onClick={onClickClaimCertificate}
            disabled={hasAlreadyClicked}
          >
            {intl.formatMessage(translations.deliveredRequestLink)}
          </button>
        </p>
      );
    default:
      return null;
  }
}
