import React from 'react';
import './OfferCard.scss';
import CheckIcon from '../../../../../../../icons/component-icons/CheckIcon';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';
import { goToSalesCalendar } from '../../../../../../../utils/redirection.util';
import ahoy from 'ahoy.js';

type OfferCardProps = {
  title: string;
  subtitle: string;
  mentions: string[];
  isSelected?: boolean;
  isNextOffer?: boolean;
  isUpcomingOffer?: boolean;
  currentOfferTitle?: string;
};

const translations = defineMessages({
  selected: {
    id: 'OfferCard.selected',
    defaultMessage: 'Votre offre actuelle',
  },
  subscribe: {
    id: 'OfferCard.subscribe',
    defaultMessage: 'Demander une démo',
  },
});

function OfferCard({ title, subtitle, mentions, isSelected, isNextOffer, isUpcomingOffer, currentOfferTitle }: OfferCardProps) {
  const intl = useIntl();
  const clickedOffer = isNextOffer || isUpcomingOffer ? title : undefined;

  const handleButtonClick = () => {
    ahoy.track('b2b_connect_upsell_asked_demo', {
      current_offer: currentOfferTitle,
      clicked_offer: clickedOffer,
    });
    goToSalesCalendar();
  };
  return (
    <div
      className={classNames(
        'offer-card__container',
        { 'offer-card__selected': isSelected },
        { 'offer-card__next-offer': isNextOffer },
      )}
    >
      <header className="offer-card__header">
        {isSelected && <div className="offer-card__badge">{intl.formatMessage(translations.selected)}</div>}
        <h1 className="offer-card__title">{title}</h1>
        <div className="offer-card__subtitle">{subtitle}</div>
      </header>
      <ul className="offer-card__features">
        {mentions.map((mention, index) => (
          <li key={index}>
            <div className="icon">
              <CheckIcon />
            </div>
            {mention}
          </li>
        ))}
      </ul>
      <div className="offer-card__button">
        {(isNextOffer || isUpcomingOffer) && (
          <button
            className={isNextOffer ? 'offer-card__button__next' : 'offer-card__button__upcoming'}
            onClick={handleButtonClick}
          >
            {intl.formatMessage(translations.subscribe)}
          </button>
        )}
      </div>
    </div>
  );
}

export default OfferCard;
