import React from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import buttonTranslations from '../../../../common/common-translations/button.translations';
import translations from './DeleteAppointmentModal.translations';
import { DELETE_APPOINTMENT_MUTATION } from './DeleteAppointmentModal.gql';
import {
  UnbookAppointmentMutation,
  UnbookAppointmentMutationVariables,
  Appointment,
} from '../../../../../@types/graphql.d';
import { DeleteAppointmentErrors } from './DeleteAppointmentModal.translations';
import { GET_CONVERSATION_APPOINTMENTS } from '../../../../../scenes/interactions/conversations/components/appointment-hub/hooks/useConversationAppointments.gql';
import { GET_APPOINTMENT_LIST } from '../../../../../scenes/interactions/appointments/AppointmentList.gql';
import useModal from '../../../../../components/modal/Modal.hook';
import ButtonsGroup from '../../../../../components/buttons-group/ButtonsGroup';
import SecondaryButton from '../../../../../components/secondary-button/SecondaryButton';
import PrimaryButton from '../../../../../components/primary-button/PrimaryButton';
import LoadingIcon from '../../../../../icons/component-icons/LoadingIcon';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router';
import Routes from '../../../../../routes';

function DeleteAppointmentModal({
  appointment,
}: {
  appointment: Pick<Appointment, 'id' | 'conversationId'>;
}): JSX.Element {
  const intl = useIntl();
  const [, closeModal] = useModal();
  const snackbar = useSnackbar();
  const [deleteAppointment, { loading }] = useMutation<UnbookAppointmentMutation, UnbookAppointmentMutationVariables>(
    DELETE_APPOINTMENT_MUTATION,
    {
      refetchQueries: [
        { query: GET_CONVERSATION_APPOINTMENTS, variables: { conversationId: appointment.conversationId } },
        { query: GET_APPOINTMENT_LIST, variables: { filters: { past: false, cancelled: false }, sort: 'FROM_DESC' } },
      ],
    },
  );

  const onDelete = async () => {
    await deleteAppointment({
      variables: {
        id: appointment.id,
      },
    })
      .then(() => {
        window.location.href = generatePath(Routes.conversation, {
          id: appointment.conversationId,
        });
      })
      .catch((error) => {
        const errorKey = error.graphQLErrors[0].extensions?.code;
        const errorMessage = DeleteAppointmentErrors[errorKey as keyof typeof DeleteAppointmentErrors];
        if (errorMessage) {
          enqueueSnackbar(intl.formatMessage(errorMessage), {
            variant: 'error',
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(intl.formatMessage(DeleteAppointmentErrors.default), {
            variant: 'error',
            autoHideDuration: 5000,
          });
        }
      });
  };

  return (
    <div>
      <p>{intl.formatMessage(translations.description)}</p>

      <ButtonsGroup>
        <SecondaryButton
          label={intl.formatMessage(buttonTranslations.cancel)}
          onClick={closeModal}
        />
        <PrimaryButton
          label={intl.formatMessage(buttonTranslations.confirm)}
          onClick={onDelete}
          icon={loading ? LoadingIcon : undefined} // ternary condition with undefined on else to avoid an miss typing
        />
      </ButtonsGroup>
    </div>
  );
}

export default DeleteAppointmentModal;
