import React from 'react';
import classNames from 'classnames';
import { Languages, useLanguage } from '../../../../../hooks/useLanguages';
import { useIntl } from 'react-intl';
import translations from './LanguageSelectorSimple.translations';
import Storage from '../../../../../enums/storage';
import { setStorageItem } from '../../../../../utils/storage';

import './LanguageSelectorSimple.scss';


const LanguageSelectorSimple = () => {
  const intl = useIntl();
  const { language, setLanguage } = useLanguage();

  function changeLanguage(lang: Languages) {
    setStorageItem(Storage.LANGUAGE, lang);
    setLanguage(lang);
    window.location.reload();
  }

  return (
    <div
      className="language-selector-simple"
    >
      <button
        type="button"
        className={`language-selector-option ${language === 'fr' ? 'selected' : ''}`}
        onClick={() => changeLanguage('fr')}
        disabled={language === 'fr'}
      >
        {intl.formatMessage(translations.frLanguage)}
      </button>
      |
      <button
        type="button"
        className={`language-selector-option ${language === 'en' ? 'selected' : ''}`}
        onClick={() => changeLanguage('en')}
        disabled={language === 'en'}
      >
        {intl.formatMessage(translations.enLanguage)}
      </button>
    </div>
  );
};

export default LanguageSelectorSimple;
