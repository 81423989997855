
      (function() {
        if (import.meta.env.REACT_APP_COOKIEBOT_ID) {
          var cookiebotScript = document.createElement('script');
          cookiebotScript.id = 'Cookiebot';
          cookiebotScript.src = 'https://consent.cookiebot.com/uc.js';
          cookiebotScript.setAttribute('data-cbid', import.meta.env.REACT_APP_COOKIEBOT_ID); // Correctly set attribute
          cookiebotScript.setAttribute('data-blockingmode', 'auto');
          cookiebotScript.type = 'text/javascript';
          document.head.appendChild(cookiebotScript); // Or document.body, depending on where it needs to go.
        } else {
          // Handle the case where the configuration isn't loaded yet.
            console.error("Cookiebot ID not found in runtime configuration.");
            let retries = 5;
            const intervalId = setInterval(() => {
                if(import.meta.env.REACT_APP_COOKIEBOT_ID){
                    clearInterval(intervalId);
                    var cookiebotScript = document.createElement('script');
                      cookiebotScript.id = 'Cookiebot';
                      cookiebotScript.src = 'https://consent.cookiebot.com/uc.js';
                      cookiebotScript.setAttribute('data-cbid', import.meta.env.REACT_APP_COOKIEBOT_ID); // Correctly set attribute
                      cookiebotScript.setAttribute('data-blockingmode', 'auto');
                      cookiebotScript.type = 'text/javascript';
                      document.head.appendChild(cookiebotScript);
                }
                else{
                  retries--;
                  if(retries === 0){
                    clearInterval(intervalId);
                    console.error("Cookiebot ID not found after multiple retries.");
                  }
                }
            }, 200)
        }
      })();
    