import React from 'react';
import ButtonsGroup from '../../../../../../../components/buttons-group/ButtonsGroup';
import PrimaryButton from '../../../../../../../components/primary-button/PrimaryButton';
import { goToConnectWebsite } from '../../../../../../../utils/redirection.util';
import { RestrictedPackModalTranslations } from './RestrictedPackModal.translations';
import { useIntl } from 'react-intl';

export default function RestrictedPackModal() {
  const intl = useIntl();
  return (
    <div>
      <p>{intl.formatMessage(RestrictedPackModalTranslations.body)}</p>
      <ButtonsGroup>
        <PrimaryButton
          label={intl.formatMessage(RestrictedPackModalTranslations.primaryButton)}
          onClick={goToConnectWebsite}
        />
      </ButtonsGroup>
    </div>
  );
}
