import { gql } from '@apollo/client';
import { GetEmployeeListQuery } from '../../../../../@types/graphql.d';

export const GET_EMPLOYEE_LIST_QUERY = gql`
  query GetEmployeeList(
    $companyId: ID!
    $after: String
    $before: String
    $sort: [CompanyEmployeesQuerySortEnum!]
    $filters: CompenyEmployeesQueryFilter
  ) {
    company(companyId: $companyId) {
      customAttributesSet {
        companyAttributeValues1
        companyAttributeValues2
        companyAttributeValues3
        customEmployeeAttribute1
        customEmployeeAttribute2
        customEmployeeAttribute3
        customEmployeeAttribute1NormalizedName
        customEmployeeAttribute2NormalizedName
        customEmployeeAttribute3NormalizedName
      }
    }
    companyEmployees(companyId: $companyId, after: $after, before: $before, first: 20, sort: $sort, filters: $filters) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        id
        avatar
        firstName
        lastName
        jobTitle
        preparedDiplomaTitle
        email
        status
        lastInvitationEmailSentAt
        visibleInSearch
        companyAttribute1
        companyAttribute2
        companyAttribute3
        userId
        canApprove
        canArchive
        canPublish
        canUnpublish
        canReceiveInvitationReminder
      }
    }
  }
`;

export const UNPUBLISH_EMPLOYEE_MUTATION = gql`
  mutation UnpublishEmployee($userId: ID!) {
    unpublish(userId: $userId)
  }
`;
export const PUBLISH_EMPLOYEE_MUTATION = gql`
  mutation PublishEmployee($userId: ID!) {
    publish(userId: $userId)
  }
`;
export const APPROVE_EMPLOYEE_MUTATION = gql`
  mutation ApproveEmployee($employeeId: ID!) {
    approveEmployee(employeeId: $employeeId)
  }
`;
export const ARCHIVE_EMPLOYEE_MUTATION = gql`
  mutation ArchiveEmployee($employeeId: ID!) {
    archiveEmployee(employeeId: $employeeId)
  }
`;
export const SEND_EMPLOYEE_INVITATION_EMAIL_MUTATION = gql`
  mutation SendEmployeeInvitationEmail($employeeId: ID!) {
    sendEmployeeInvitationEmail(employeeId: $employeeId)
  }
`;

export const GET_COMPANY_CUSTOM_ATTRIBUTES_QUERY = gql`
  query GetCompanyCustomAttributes($companyId: ID!) {
    company(companyId: $companyId) {
      id
      isPremiumSchool
      customAttributesSet {
        customEmployeeAttribute1
        companyAttributeValues1
        customEmployeeAttribute2
        companyAttributeValues2
        customEmployeeAttribute3
        companyAttributeValues3
      }
      recruitingWorkingAreas
    }
  }
`;

type NonNullableEmployees = NonNullable<GetEmployeeListQuery['companyEmployees']>;
type NonNullableCompany = NonNullable<GetEmployeeListQuery['company']>;
export type Employee = NonNullableEmployees['nodes'][0];
export type CompanyAttributeSet = NonNullableCompany['customAttributesSet'];
