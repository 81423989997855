import React, { memo, useMemo } from 'react';
import { PanelProps } from './Panel';
import { Layout } from './components/layout/Layout';
import SelectInput from '../../../../components/select-input/SelectInput';
import { defineMessages, useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';
import { GetAspirationsValuesQuery } from '../../../../@types/graphql';
import { GET_ASPIRATIONS_VALUES } from '../Review.gql';
import { useReviewContext } from '../ReviewContext';

const translations = defineMessages({
  title: {
    id: 'Review.SchoolAspirationPanel.title',
    defaultMessage: 'Dîtes-nous en plus sur ce qui vous intéresse',
  },
});

export const SchoolAspirationPanel: React.FC<PanelProps> = memo(({ appointment }) => {
  const { formatMessage } = useIntl();
  const { memberReview } = appointment;
  const { reviewFormik } = useReviewContext();

  const { data } = useQuery<GetAspirationsValuesQuery>(GET_ASPIRATIONS_VALUES);

  const degreeLevels = useMemo(
    () =>
      (data?.settings?.degreeLevels || []).map(({ key, name }) => ({
        value: name,
        translation: name,
      })),
    [data?.settings?.degreeLevels],
  );

  const academicYears = useMemo(
    () =>
      (data?.settings?.academicYears || []).map(({ key, name }) => ({
        value: name,
        translation: name,
      })),
    [data?.settings?.academicYears],
  );

  return (
    <Layout
      title={formatMessage(translations.title)}
      style={{ paddingTop: 80 }}
    >
      {memberReview?.schoolInterestLevelQuestion && (
        <SelectInput
          name="level"
          label={memberReview.schoolInterestLevelQuestion}
          value={reviewFormik.values.grade}
          values={degreeLevels}
          onChange={(e) => reviewFormik.setFieldValue('grade', e)}
        />
      )}
      {memberReview?.schoolInterestYearQuestion && (
        <SelectInput
          name="years"
          label={memberReview.schoolInterestYearQuestion}
          value={reviewFormik.values.year}
          values={academicYears}
          onChange={(e) => reviewFormik.setFieldValue('year', e)}
        />
      )}
    </Layout>
  );
});
