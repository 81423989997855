import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Approve_EmployeeMutation, Approve_EmployeeMutationVariables } from '../../../../../../../@types/graphql.d';
import translations from './useApproveAction.translations';
import { APPROVE_EMPLOYEE } from './useApproveAction.gql';
import { useIntl } from 'react-intl';

export default function useApproveAction(employeeId: string) {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  // handle approve employee mutation
  const [approve] = useMutation<Approve_EmployeeMutation, Approve_EmployeeMutationVariables>(APPROVE_EMPLOYEE, {
    refetchQueries: ['GET_ALLOWED_EMPLOYEE_ACTIONS_AND_STATUS'],
  });

  // submit function
  function approveEmployees() {
    return approve({ variables: { employeeId } })
      .then(() => {
        enqueueSnackbar(intl.formatMessage(translations.success), {
          variant: 'success',
          autoHideDuration: 10000,
        });
      })
      .catch(() => {
        enqueueSnackbar(intl.formatMessage(translations.oneProfileCannotApproved), {
          variant: 'error',
          autoHideDuration: 10000,
        });
      });
  }
  return [approveEmployees];
}
