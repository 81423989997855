import { boolean, mixed, object, number, string, date, array } from 'yup';
import { AppointmentDidNotTookPlaceReasonEnum, InternshipDuration } from '../../../../@types/graphql.d';

export const MESSAGES_MAX_LENGTH = 400;

export const firstQuestionPaneValidationSchema = object().shape({
  question1: number().required(),
});

export const secondThirdQuestionsPaneValidationSchema = object().shape({
  question1: number().required(),
  question2: number().when('tooYoungToAnswer', {
    is: false,
    then: (schema) => schema.required(),
  }),
  question3: number().when('tooYoungToAnswer', {
    is: false,
    then: (schema) => schema.required(),
  }),
});

export const recommendHRPaneValidationSchema = object().shape({
  question1: number().required(),
  question2: number().when('tooYoungToAnswer', {
    is: false,
    then: (schema) => schema.required(),
  }),
  question3: number().when('tooYoungToAnswer', {
    is: false,
    then: (schema) => schema.required(),
  }),
  messageForHR: string().required().max(MESSAGES_MAX_LENGTH),
});

export const recommendPaneValidationSchema = object().shape({
  question1: number().required(),
  question2: number().when('tooYoungToAnswer', {
    is: false,
    then: (schema) => schema.required(),
  }),
  question3: number().when('tooYoungToAnswer', {
    is: false,
    then: (schema) => schema.required(),
  }),
  question4: boolean().when('shouldAnswerLike', {
    is: true,
    then: (schema) => schema.required(),
  }),
  message: string().required().max(MESSAGES_MAX_LENGTH),
});

export const wantsToRecontactPaneValidationSchema = object().shape({
  question1: number().required(),
  question2: number().when('tooYoungToAnswer', {
    is: false,
    then: (schema) => schema.required(),
  }),
  question3: number().when('tooYoungToAnswer', {
    is: false,
    then: (schema) => schema.required(),
  }),
  message: string().required().max(MESSAGES_MAX_LENGTH),
  question4: boolean().when('shouldAnswerLike', {
    is: true,
    then: (schema) => schema.required(),
  }),
  wantsToBeRecontacted: boolean().required(),
});

export const didNotShowUpPaneValidationSchema = object().shape({
  didNotTookPlaceReason: mixed<AppointmentDidNotTookPlaceReasonEnum>()
    .oneOf(Object.values(AppointmentDidNotTookPlaceReasonEnum))
    .required(),
  didNotTookPlaceReasonDescription: string().when('didNotTookPlaceReason', {
    is: AppointmentDidNotTookPlaceReasonEnum.Other,
    then: (schema) => schema.required(),
  }),
});

export const companyAspirationPaneValidationSchema = object().shape({
  jobType: array().of(string()).min(1),
  duration: mixed<InternshipDuration>().when('jobType', {
    is: (v: string[]) => v.every((jt: string) => jt === 'CDI'),
    otherwise: (schema) => schema.required().oneOf(Object.values(InternshipDuration)),
  }),
  startDate: date().required(),
  postalCode: string().required().length(5),
  availability: boolean().required(),
  administrativeArea: string().when('availability', {
    is: true,
    then: (schema) => schema.required(),
  }),
});

export const schoolAspirationPaneValidationSchema = object().shape({
  grade: string().required(),
  year: string().required(),
});
