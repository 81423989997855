import { IconProps } from '../../icons/component-icons/Icon';
import { ComponentType } from 'react';

export type InputValue<T> = {
  icon?: ComponentType<IconProps>;
  value: T;
  translation: string;
};

export type CategorizedInputValue<T> = {
  icon?: ComponentType<IconProps>;
  categoryKey: string;
  categoryTranslation?: string;
  values: InputValue<T>[];
};

export function sortByTranslation<T>(values: InputValue<T>[]): InputValue<T>[] {
  return values.sort((a, b) => a.translation.localeCompare(b.translation));
}
