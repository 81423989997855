import { gql } from '@apollo/client';

export const FURTHER_INFORMATIONS_QUERY = gql`
  query AmbassadorFurtherInformations($id: ID!) {
    getUser(id: $id) {
      educations {
        diplomaTitle
        schoolName
        endedAt
      }

      languages

      studyLevels

      mandatorySkills
    }
  }
`;
