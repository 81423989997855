import { defineMessages } from 'react-intl';

export default defineMessages({
  unavailableStatus: {
    id: 'CertificationDisplay.unavailableStatus',
    defaultMessage: 'Indisponible',
  },
  unavailableDescription: {
    id: 'CertificationDisplay.unavailableDescription',
    defaultMessage:
      'Vous pourrez récupérer votre certificat d’orientation à la fin de votre programme si vous avez atteint l’objectif de rendez-vous fixé',
  },
  failedStatus: {
    id: 'CertificationDisplay.failedStatus',
    defaultMessage: 'Non obtenue',
  },
  failedDescription: {
    id: 'CertificationDisplay.failedDescription',
    defaultMessage: 'l’objectif de rendez-vous de votre programme n’a pas été atteint',
  },
  deliveredStatus: {
    id: 'CertificationDisplay.deliveredStatus',
    defaultMessage: 'Délivrée',
  },
  deliveredRequestLink: {
    id: 'CertificationDisplay.deliveredRequestLink',
    defaultMessage: 'cliquer ici pour recevoir le certificat par email',
  },
  unfollowedConversation: {
    id: 'CertificationDisplay.unfollowedConversation',
    defaultMessage:
      '<span class="bold">{x, plural, =0 {# conversation non suivie} =1 {# conversation non suivie} other {# conversations non suivies}} :</span> répondez aux professionnels qui ont répondu à votre premier message pour débloquer l’obtention de votre certificat',
  },
  report: {
    id: 'CertificationDisplay.report',
    defaultMessage:
      '<span class="bold">Profil signalé par un professionnel :</span> contactez le support My Job Glasses pour débloquer l’obtention de votre certificat',
  },
  success: {
    id: 'CertificationDisplay.success',
    defaultMessage: 'Votre certificat a été expédié !',
  },
});
