import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Account.title',
    defaultMessage: 'Paramètres',
    description: 'Page compte',
  },
  confirmationEmailSent: {
    id: 'Account.toastr.confirmationEmailSent',
    defaultMessage: 'Un E-mail de confirmation vous a été envoyé, merci de valider votre nouvel E-mail !',
    description: 'Mon compte',
  },
  accountInformationsUpdated: {
    id: 'Account.toastr.accountInformationsUpdated',
    defaultMessage: 'Le lien vous a été envoyé par email. Suivez les instructions pour modifier votre mot de passe',
    description: 'Envoi du lien de modification de mdp',
  },
  accountInformationsError: {
    id: 'Account.toastr.accountInformationsError',
    defaultMessage: "Le lien n'a pas pu vous être envoyé par email. Réessayez",
    description: "Erreur d'envoi du lien de modification de mdp",
  },
  profileInformationsUpdated: {
    id: 'Account.toastr.profileInformationsUpdated',
    defaultMessage: 'Merci, vos informations ont bien été mises à jour',
    description: 'Mon compte',
  },
  notificationsPreferencesUpdated: {
    id: 'Account.toastr.notificationsPreferencesUpdated',
    defaultMessage: 'Merci, vos informations ont bien été mises à jour',
    description: 'Mon compte',
  },
  CURRENT_PASSWORD_DOES_NOT_MATCH: {
    id: 'Account.toastr.currentPasswordDoesNotMatch',
    defaultMessage: 'Le mot de passe est invalide.',
    description: 'Mon compte',
  },
  checkEmail: {
    id: 'Account.toastr.checkEmail',
    defaultMessage: 'Un email de confirmation a été envoyé sur votre nouvelle adresse mail.',
    description: 'Mon compte',
  },
});
