import { defineMessages } from 'react-intl';

export default defineMessages({
  conversationLabel: {
    id: 'CompanyAdminDashboardActivityBloc.conversationLabel',
    defaultMessage: 'Conversations',
  },
  conversationTooltip: {
    id: 'CompanyAdminDashboardActivityBloc.conversationTooltip',
    defaultMessage: 'Somme des nouvelles conversations démarrées par des Membres avec vos Ambassadeurs sur la période',
  },
  appointmentLabel: {
    id: 'CompanyAdminDashboardActivityBloc.appointmentLabel',
    defaultMessage: 'Rendez-vous',
  },
  appointmentTooltip: {
    id: 'CompanyAdminDashboardActivityBloc.appointmentTooltip',
    defaultMessage: 'Somme des rendez-vous fixés par vos Ambassadeurs sur la période',
  },
});
