import React, { memo, useMemo, useRef } from 'react';

import { useQuery } from '@apollo/client';

import './SimilarProsCarousel.scss';

import {
  ConversationTypeKeyEnum,
  FacetKeyEnum,
  GetAmbassadorsQuery,
  GetAmbassadorsQueryVariables,
  GetInterlocutorSimilarSearchDataQuery,
  GetInterlocutorSimilarSearchDataQueryVariables,
} from '../../@types/graphql.d';
import ChevronRightIcon from '../../icons/component-icons/ChevronRightIcon';
import AmbassadorCard from '../../scenes/search/ambassador-card/AmbassadorCard';
import { GET_AMBASSADORS_QUERY } from '../../scenes/search/search.gql';
import { GET_INTERLOCUTOR_SIMILAR_SEARCH_DATA_QUERY } from './SimilarProsCarousel.gql';

type Props = {
  interlocutorUserId: string;
  companySimilar?: boolean;
  acceptConversationTypes?: ConversationTypeKeyEnum[];
};

export const SimilarProsCarousel: React.FC<Props> = memo(
  ({ interlocutorUserId, companySimilar, acceptConversationTypes }) => {
    const carouselRef = useRef(null);

    const { data: interlocutor, loading: l1 } = useQuery<
      GetInterlocutorSimilarSearchDataQuery,
      GetInterlocutorSimilarSearchDataQueryVariables
    >(GET_INTERLOCUTOR_SIMILAR_SEARCH_DATA_QUERY, {
      variables: { userId: interlocutorUserId },
    });

    const filter = useMemo(() => {
      if (companySimilar && interlocutor?.user.premiumAmbassador)
        return ['facets', [{ key: FacetKeyEnum.Companies, values: [interlocutor.user.companyName] }]];
      return ['query', interlocutor?.user.position];
    }, [interlocutor, companySimilar]);

    const { data, loading: l2 } = useQuery<GetAmbassadorsQuery, GetAmbassadorsQueryVariables>(GET_AMBASSADORS_QUERY, {
      variables: {
        searchQuery: filter[0] === 'query' ? (filter[1] as string) : undefined,
        searchFacets: filter[0] === 'facets' ? (filter[1] as GetAmbassadorsQueryVariables['searchFacets']) : undefined,
        availableFirst: true,
        availableAmbassadorsOnly: true,
        acceptConversationTypes: acceptConversationTypes,
      },
      skip: !interlocutor,
    });

    const scrollRight = () => {
      if (carouselRef.current) {
        (carouselRef.current as HTMLDivElement).scrollBy({
          left: 300,
          behavior: 'smooth',
        });
      }
    };

    if (l1 || l2 || !data) return null;

    return (
      <div className="similar-pros-carousel">
        <div
          ref={carouselRef}
          className="similar-pros-carousel__cards"
        >
          {data.searchAmbassadors.results.map((amb) => {
            if (amb.professionalId !== interlocutor?.user.professionalId)
              return (
                <AmbassadorCard
                  key={amb.professionalId}
                  ambassador={amb}
                  size="small"
                />
              );
          })}
        </div>

        <button
          className="similar-pros-carousel__btn"
          onClick={scrollRight}
        >
          <ChevronRightIcon />
        </button>
      </div>
    );
  },
);
