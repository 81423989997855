import { useEffect } from 'react';

const SCROLL_TRIGGER_OFFSET = 250;

type UseInfiniteScrollLoadingOptions = {
  canLoadMore: boolean;
  isLoading: boolean;
  callback: () => void;
  scrollTriggerOffset?: number;
};

export default function useInfiniteScrollLoading(options: UseInfiniteScrollLoadingOptions) {
  useEffect(() => {
    function handleScroll() {
      const bottomWindowPoint = window.innerHeight + window.scrollY;
      const bottomBodyPoint = document.body.offsetHeight - (options.scrollTriggerOffset || SCROLL_TRIGGER_OFFSET);

      if (options.canLoadMore && !options.isLoading && bottomWindowPoint >= bottomBodyPoint) {
        options.callback();
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [options.isLoading, options.canLoadMore, options.callback, options.scrollTriggerOffset]);
}
