import React, { useState, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';

import './AccountSoftDeletion.scss';
import { useFormik } from 'formik';
import EditForm from '../../../hoc/form-section-wrapper';
import useModal from '../../../components/modal/Modal.hook';
import SoftDeletionAppointmentReminder from './components/soft-deletion-appointment-reminder/SoftDeletionAppointmentReminder';
import SoftDeletionHibernationReminder from './components/soft-deletion-hibernation-reminder/SoftDeletionHibernationReminder';
import PageLoader from '../../../scenes/common/page-loader/PageLoader';
import CheckboxInput from '../../../components/checkbox-input/CheckboxInput';
import { GetFutureAppointmentsQuery, GetFutureAppointmentsQueryVariables } from '../../../@types/graphql.d';
import { GET_FUTURE_APPOINTMENTS_QUERY } from './AccountSoftDeletion.gql';
import { CurrentUserContext } from '../../../contexts/current-user-context/CurrentUserContext';
import translations from './AccountSoftDeletion.translations';
import SoftDeletionReasonForm from './components/soft-deletion-reason-form/SoftDeletionReasonForm';

type Values = {
  soft_delete_my_account: boolean;
};

const AccountSoftDeletion = () => {
  const intl = useIntl();
  const [isEditable, setEditable] = useState(false);
  const { currentUser } = useContext(CurrentUserContext);
  const [openModal] = useModal();

  const isCurrentUserProfessional = currentUser?.isAmbassador;

  const { data, loading } = useQuery<GetFutureAppointmentsQuery, GetFutureAppointmentsQueryVariables>(
    GET_FUTURE_APPOINTMENTS_QUERY,
    {
      variables: {
        filters: {
          past: false,
          cancelled: false,
        },
      },
    },
  );

  const remainingAppointmentInFuture = data?.appointments?.edges || [];

  const modalContent = () => {
    if (loading) {
      return '';
    } else if (remainingAppointmentInFuture.length > 0) {
      return <SoftDeletionAppointmentReminder />;
    } else if (isCurrentUserProfessional || false) {
      return <SoftDeletionHibernationReminder />;
    } else return <SoftDeletionReasonForm />;
  };

  const modalTitle = () => {
    if (loading) {
      return '';
    } else if (remainingAppointmentInFuture.length > 0) {
      return intl.formatMessage(translations.appointmentReminderModalTitle);
    } else if (isCurrentUserProfessional || false) {
      return intl.formatMessage(translations.hibernationReminderModalTitle);
    } else return intl.formatMessage(translations.title);
  };

  const formik = useFormik<Values>({
    initialValues: { soft_delete_my_account: false },
    onSubmit: (values) => {
      if (values.soft_delete_my_account) {
        openModal({
          title: modalTitle(),
          content: modalContent(),
        });
      }
    },
  });

  return (
    <EditForm
      id="account-soft-deletion"
      className="account-soft-deletion"
      title={intl.formatMessage(translations.title)}
      isEditable={isEditable}
      handleEditableChange={setEditable}
      handleSubmit={formik.handleSubmit}
    >
      <CheckboxInput
        name="soft_delete_my_account"
        label={intl.formatMessage(translations.label)}
        checked={formik.values.soft_delete_my_account}
        onChange={formik.handleChange}
        disabled={!isEditable}
      />
    </EditForm>
  );
};

export default AccountSoftDeletion;
