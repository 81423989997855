import { gql } from '@apollo/client';

export const GET_LAST_GENERATED_DATETIME = gql`
  query GET_LAST_GENERATED_DATETIME($range: CompanyReportRange!, $companyId: ID!) {
    companyReport(range: $range, companyId: $companyId) {
      companyId
      lastUpdatedAt
    }
  }
`;
