import { defineMessages } from 'react-intl';

export const AmbassadorViewContactTranslations = defineMessages({
  title: {
    id: 'AmbassadorViewContact.title',
    defaultMessage: 'Disponibilité',
  },
  remaining: {
    id: 'AmbassadorViewContact.remaining',
    defaultMessage: 'rendez-vous{br}restant(s) en {month}',
  },
  responseRate: {
    id: 'AmbassadorViewContact.responseRate',
    defaultMessage: 'Taux<br/>de réponse',
  },
  responseTime: {
    id: 'AmbassadorViewContact.responseTime',
    defaultMessage: 'Temps<br/>de réponse',
  },
  contact: {
    id: 'AmbassadorViewContact.contact',
    defaultMessage: 'Contacter',
  },
  ambassadorReachedContactLimit: {
    id: 'AmbassadorViewContact.ambassadorReachedContactLimit',
    defaultMessage: 'Cet Ambassadeur a atteint son quota de prises de contact pour le moment.',
  },
  unavailableUntil: {
    id: 'AmbassadorViewContact.unavailableUntil',
    defaultMessage: 'Disponible à partir du <strong>{unavailableUntil}</strong>',
  },
  ambassadorMeetingPreferencesPreventsContact: {
    id: 'AmbassadorViewContact.ambassadorMeetingPreferencesPreventsContact',
    defaultMessage: "L'ambassadeur ne souhaite pas échanger avec votre profil.",
  },
  ambassadorUnavailable: {
    id: 'AmbassadorViewContact.ambassadorUnavailable',
    defaultMessage: "L'ambassadeur est actuellement indisponible.",
  },
  noRemainingContact: {
    id: 'AmbassadorViewContact.noRemainingContact',
    defaultMessage: "Vous n'avez plus de contacts disponibles.",
  },
  userInteractionGroupPreventsContact: {
    id: 'AmbassadorViewContact.userInteractionGroupPreventsContact',
    defaultMessage: "Vous n'avez pas le droit de contacter cet ambassadeur.",
  },
  similarPros: {
    id: 'AmbassadorViewContact.similarPros',
    defaultMessage: 'Voir des profils similaires',
  },
});
