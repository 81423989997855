import React from 'react';

export type MarkerIconProps = {
  className?: string;
};

function MarkerIcon({ className }: MarkerIconProps) {
  return (
    <svg
      viewBox="0 0 16 16"
      className={className}
    >
      <path d="M8,7.909c1.466,0,2.659-1.193,2.659-2.659S9.466,2.591,8,2.591S5.341,3.784,5.341,5.25S6.534,7.909,8,7.909z M8,3.591c0.915,0,1.659,0.744,1.659,1.659S8.915,6.909,8,6.909S6.341,6.165,6.341,5.25S7.085,3.591,8,3.591z" />
      <path d="M7.193,13.509c0.17,0.279,0.472,0.446,0.807,0.446s0.637-0.167,0.807-0.446l3.797-6.247c0.423-0.719,0.646-1.534,0.646-2.359C13.25,2.199,10.895,0,8,0S2.75,2.199,2.75,4.902c0,0.813,0.218,1.618,0.595,2.256L7.193,13.509z M8,1c2.344,0,4.25,1.751,4.25,3.902c0,0.646-0.176,1.287-0.527,1.889l-3.676,6.198L4.245,6.732C3.921,6.172,3.75,5.539,3.75,4.902C3.75,2.751,5.656,1,8,1z" />
      <path d="M11.588,11.238c-0.278-0.031-0.526,0.152-0.565,0.425c-0.039,0.273,0.151,0.526,0.425,0.565C14.158,12.613,15,13.295,15,13.5c0,0.448-2.382,1.5-7,1.5s-7-1.052-7-1.5c0-0.207,0.852-0.895,3.593-1.277c0.273-0.038,0.464-0.291,0.426-0.564c-0.038-0.274-0.299-0.461-0.564-0.426C2.791,11.465,0,12.068,0,13.5C0,15.218,4.146,16,8,16s8-0.782,8-2.5C16,12.076,13.235,11.472,11.588,11.238z" />
    </svg>
  );
}

export default MarkerIcon;
