import React from 'react';
import { FormattedDate } from 'react-intl';
import { TableCellProps } from 'react-virtualized';

import styles from './DateCellRenderer.module.scss';

function DateCellRenderer({ cellData }: TableCellProps) {
  if (typeof cellData === 'undefined') {
    return (
      <p
        className={styles.date}
        data-testid="empty-date"
      >
        -
      </p>
    );
  }
  return (
    <p
      className={styles.date}
      data-testid={cellData}
    >
      <FormattedDate value={new Date(cellData)} />
    </p>
  );
}

export default DateCellRenderer;
