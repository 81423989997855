import { defineMessages } from 'react-intl';

export default defineMessages({
  status: {
    id: 'ProfessionalAvailable.status',
    defaultMessage: 'Rendez-vous possible',
    description: 'status rdv available',
  },
  datePartial: {
    id: 'ProfessionalAvailable.datePartial',
    defaultMessage: 'en {month}',
    description: 'affichage du mois courrant',
  },
  meetingsLeft: {
    id: 'ProfessionalAvailable.meetingsLeft',
    defaultMessage: '{amount} rendez-vous {amount, plural, zero {restant} one {restant} other {restants}}',
    description: '',
  },
});
