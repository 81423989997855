import React from 'react';
import { DiplomaEnum, InterlocutorInterface } from '../../../../../../../@types/graphql.d';
import diplomaTypeTranslations from '../../../../../../common/common-translations/diplomaType.translations';
import translations from './SidebarStudentEducationsSection.translations';

import styles from './SidebarStudentEducationsSection.module.scss';
import { useIntl } from 'react-intl';

interface SidebarStudentEducationsSectionProp {
  publicProfile?: InterlocutorInterface;
  isPremiumSchool?: boolean;
}
function SidebarStudentEducationsSection({ publicProfile, isPremiumSchool }: SidebarStudentEducationsSectionProp) {
  const intl = useIntl();
  return (
    <section className={styles.SidebarStudentEducationsSection}>
      <h2 className={styles.title}>{intl.formatMessage(translations.title)}</h2>
      <div className={styles.education}>
        {publicProfile?.acquiredDiplomaLevel && (
          <>
            <p className={styles.dateRange}>
              {intl.formatMessage(translations.fromTo, {
                from: publicProfile?.acquiredDiplomaBeginYear
                  ? intl.formatDate(publicProfile?.acquiredDiplomaBeginYear, {
                      month: '2-digit',
                      year: 'numeric',
                    })
                  : '-',
                to: publicProfile?.acquiredDiplomaAcquisitionYear
                  ? intl.formatDate(publicProfile?.acquiredDiplomaAcquisitionYear, {
                      month: '2-digit',
                      year: 'numeric',
                    })
                  : '-',
              })}
            </p>

            <p className={styles.diplomaType}>
              {publicProfile?.acquiredDiplomaLevel.name === DiplomaEnum.None
                ? intl.formatMessage(translations.endYearOnly, {
                    year: publicProfile.acquiredDiplomaAcquisitionYear
                      ? intl.formatDate(publicProfile.acquiredDiplomaAcquisitionYear, {
                          year: 'numeric',
                        })
                      : '-',
                  })
                : intl.formatMessage(
                    diplomaTypeTranslations[publicProfile?.acquiredDiplomaLevel?.key || DiplomaEnum.None],
                  )}
            </p>
          </>
        )}

        <p className={styles.diplomaTitle}>
          {isPremiumSchool
            ? `${publicProfile?.secondarySituation?.name || ''} ${publicProfile?.currentGradeOrDiploma || ''}`
            : publicProfile?.acquiredDiplomaTitle}
        </p>

        {publicProfile?.acquiredDiplomaSchoolName && (
          <p
            className={styles.school}
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(translations.school, {
                school: publicProfile?.acquiredDiplomaSchoolName || '-',
              }),
            }}
          />
        )}
      </div>
    </section>
  );
}

export default SidebarStudentEducationsSection;
