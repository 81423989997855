import { IntlShape } from 'react-intl';

import BarChartIcon from '../../../../../icons/component-icons/BarChartIcon';
import CalendarIcon from '../../../../../icons/component-icons/CalendarIcon';
import EditIcon from '../../../../../icons/component-icons/EditIcon';
import LockIcon from '../../../../../icons/component-icons/LockIcon';
import MailIcon from '../../../../../icons/component-icons/MailIcon';
import PowerIcon from '../../../../../icons/component-icons/PowerIcon';
import SearchRefractionIcon from '../../../../../icons/component-icons/SearchRefractionIcon';
import SettingsIcon from '../../../../../icons/component-icons/SettingsIcon';
import StarIcon from '../../../../../icons/component-icons/StarIcon';
import UserIcon from '../../../../../icons/component-icons/UserIcon';
import Routes from '../../../../../routes';
import { getAccountUrl } from '../../../../../utils/redirection.util';
import MainMenuItem from '../../components/main-menu-item/MainMenuItem';
import UsernavItem from '../../components/usernav-item/UsernavItem';
import translations from './marketplace.translations';

const accountEndpoint = import.meta.env.REACT_APP_ACCOUNT_FRONTEND_URL || 'http://localhost:4003';
const hermesEndpoint = import.meta.env.REACT_APP_HERMES_URL || 'http://localhost:3000';
const vocationEndpoint = import.meta.env.REACT_APP_SELF_SERVICE_FRONTEND_URL || 'http://localhost:4002';

export const getMarketplaceConfig = (intl: IntlShape): { main: any[]; connected: any[] } => ({
  main: [
    {
      component: MainMenuItem,
      type: 'link',
      key: 'dashboard',
      to: Routes.dashboard,
      label: intl.formatMessage(translations.dashboard),
      icon: BarChartIcon,
    },
    {
      component: MainMenuItem,
      type: 'link',
      key: 'search',
      to: Routes.search,
      label: intl.formatMessage(translations.search),
      icon: SearchRefractionIcon,
    },
    {
      component: MainMenuItem,
      type: 'link',
      key: 'wishlist',
      to: Routes.studentWishlist,
      label: intl.formatMessage(translations.favorites),
      icon: StarIcon,
      notificationKey: 'wishedProfessionalsCount',
    },
    {
      component: MainMenuItem,
      type: 'link',
      key: 'conversations',
      to: Routes.conversations,
      label: intl.formatMessage(translations.conversations),
      icon: MailIcon,
      notificationKey: 'unreadMessageCount',
    },
    {
      component: MainMenuItem,
      type: 'link',
      key: 'drafts',
      to: Routes.drafts,
      label: intl.formatMessage(translations.drafts),
      icon: EditIcon,
      condition: ({ currentUser }) => currentUser && currentUser.draftsCount > 0,
      notificationKey: 'draftsCount',
    },
    {
      component: MainMenuItem,
      type: 'link',
      key: 'appointments',
      to: Routes.appointments,
      label: intl.formatMessage(translations.appointments),
      icon: CalendarIcon,
    },
  ],
  connected: [
    {
      component: UsernavItem,
      type: 'external-link',
      key: 'profile',
      to: getAccountUrl('/profile'),
      label: intl.formatMessage(translations.profile),
      icon: UserIcon,
    },
    {
      component: UsernavItem,
      type: 'link',
      key: 'settings',
      to: Routes.settings,
      label: intl.formatMessage(translations.settings),
      icon: SettingsIcon,
    },
    {
      component: UsernavItem,
      type: 'external-link',
      key: 'mjg-admin-new-bo',
      to: `${hermesEndpoint}/admin`,
      label: intl.formatMessage(translations.mjgNewBO),
      condition: ({ currentUser }) => !!currentUser && currentUser.isMjgAdmin,
      icon: LockIcon,
    },
    {
      component: UsernavItem,
      type: 'external-link',
      key: 'logout',
      to: `${accountEndpoint}/signout`,
      label: intl.formatMessage(translations.logout),
      icon: PowerIcon,
    },
    {
      component: UsernavItem,
      type: 'external-switch',
      key: 'switch-vocation',
      to: vocationEndpoint,
      label: intl.formatMessage(translations.schoolCoachMode),
      condition: ({ currentUser }) => !!currentUser && currentUser.isCoach,
    },
  ],
});

export default getMarketplaceConfig;
