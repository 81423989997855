import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'CompanyAdminDashboardFeedback.title',
    defaultMessage: 'Votre avis nous intéresse !',
  },
  description: {
    id: 'CompanyAdminDashboardFeedback.description',
    defaultMessage: "Qu'aimeriez-vous voir de plus sur votre dashboard ? Écrivez-nous à :",
  },
});
